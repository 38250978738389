import React, { FunctionComponent, useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Container, Box, Grid, Typography, useMediaQuery, Select, MenuItem, FormControl, InputLabel, SvgIconProps, Tooltip, Paper } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { fontOptions } from '../../../theme';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import SingleQuestion from '../components/singleQuestion'
import MultipleQuestion from '../components/multipleQuestion'
import NumericQuestion from '../components/numericQuestion'
import MatchQuestion from '../components/matchQuestion'
import ComprehensiveQuestion from '../components/comprehensiveQuestion'
import { TreeItem, TreeItemProps, TreeView } from '@material-ui/lab';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import BookIcon from '@material-ui/icons/Book';
import ConfirmModal from '../components/addChapterSubtopicModal';

import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import ClearIcon from '@material-ui/icons/Clear';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';

//@ts-ignore
import { parse, HtmlGenerator } from 'latex.js'

import Button from '../../common/components/form_elements/button';
import { QuestionBank, ChoiceQuesType, NumericQuesType, MatchQuesType, QuestionBankWithSelect } from '../contracts/qb_interface';
import ConfirmationModal from '../../common/components/confirmation_modal';
import EditChapterSubtpoicModal from '../components/editChapterSubtopicModal';
import { QB_PATTERN } from '../../common/validations/patterns';
import MiniDrawer from '../../common/components/SideDrawer';
import { isEqual } from 'lodash';

declare module 'csstype' {
  interface Properties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
};

const containsObject = (obj: any, list: any[]) => {
    return list.some(elem => isEqual(elem, obj))
}

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fofontSize: '16px',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontSize: '16px',
      flexGrow: 1,
    },
  }),
);

function StyledTreeItem(props: any) {
  const classes = useTreeItemStyles();
  const { labelText, thistreetype, mainClickEvent, currentActive, clickEvent, addClickEvent, editClickEvent, deleteClickEvent, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  let operation;
  if(thistreetype === 'chapter') {
    operation = (
      <span>
        <Tooltip title="Edit Chaper"><CreateIcon onClick={editClickEvent} style={{color: '#9D9D9D', fontSize: fontOptions.size.small, marginTop: '5px', marginLeft:"5px"}} /></Tooltip>
        <Tooltip title="Delete Chapter"><ClearIcon onClick={deleteClickEvent} style={{color: '#9D9D9D', fontSize: fontOptions.size.small, marginTop: '5px', marginLeft:"5px"}}/></Tooltip>
        <Tooltip title="Add Subtopic"><AddIcon onClick={addClickEvent} style={{color: '#9D9D9D', fontSize: fontOptions.size.small, marginTop: '5px', marginLeft:"5px"}}/></Tooltip>
        <span style={{marginLeft: '5px'}}>{labelInfo}</span>
      </span>
    )
  } else {
    operation = (
      <span>
        <Tooltip title="Edit Subtopic"><CreateIcon onClick={editClickEvent} style={{color: '#9D9D9D', fontSize: fontOptions.size.small, marginTop: '5px', marginLeft:"5px"}} /></Tooltip>
        <Tooltip title="Delete Subtopic"><ClearIcon onClick={deleteClickEvent} style={{color: '#9D9D9D', fontSize: fontOptions.size.small, marginTop: '5px', marginLeft:"5px"}}/></Tooltip>
        <span style={{marginLeft: '5px'}}>{labelInfo}</span>
      </span>
    )
  }


  return (
    <TreeItem
      onClick={e => {
        if(thistreetype === 'chapter' && currentActive) {
          
        }
      }}
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} onClick={mainClickEvent} />
          <Typography variant="body2" className={classes.labelText} onClick={mainClickEvent}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {operation}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: '100%',
    },
    addBtn: {
      color: '#EBF2FF', 
      background: '#4C8BF5', 
      border: 'none',
      '&:hover': {
        cursor: 'pointer',
      }
    },
    label: {
      color: '#4C8BF5'
    },
    graylabel: {
      color: '#3D3D3D'
    },
    addAssi: {
      '& button': {
        padding: '10px 30px 10px 30px',
      },
    }
  }),
);

interface FormData {
  qbName: string;
  description: string;
  boardName: string;
  className: string;
  subject: string;
  availStudent: boolean;
}

interface AllChapter {
  chaptername: string,
  subtoptics: string[]
}

interface SubtopicTBD {
  chaptername: string,
  subtoptics: string
}

interface Props {
  location: {
    state: {
      basicData: FormData,
      allChapter?: AllChapter[],
      structuredQB?: QuestionBankWithSelect[]
    }
  },
}

const QBQuestions: FunctionComponent<Props> = ({location}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { errors, setError, clearError } = useForm<FormData>();
  const [clearQval, setClearQval] = useState(false);

  const [basicData, setBasicData] = useState<FormData>()
  const [questionType, setquestionType] = useState<""|"single"|"multiple"|"numeric"|"match"|"comprehensive">('')
  const [questionComplexity, setquestionComplexity] = useState<""|"easy"|"high"|"medium">('')

  const [allChapter, setAllChapter] = useState<AllChapter[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [currentChapter, setCurrentChapter] = useState('')
  const [currentSubTopic, setCurrentSubTopic] = useState('')

  const [structuredQB, setStructuredQB] = useState<QuestionBankWithSelect[]>([])

  const [editMode, setEditMode] = useState(false)
  const [qTBEStateC, setQTBEStateC] = useState<ChoiceQuesType | null>(null)
  const [qTBEStateN, setQTBEStateN] = useState<NumericQuesType | null>(null)
  const [qTBEStateM, setQTBEStateM] = useState<MatchQuesType | null>(null)
  const [qTBEStateCo, setQTBEStateCo] = useState<QuestionBankWithSelect[] | null>(null)

  const [chapterTBD, setChapterTBD] = useState('')
  const [subtopicTBD, setSubtopicTBD] = useState<SubtopicTBD>({chaptername: '', subtoptics: ''})
  const [chapterTBE, setChapterTBE] = useState('')
  const [subtopicTBE, setSubtopicTBE] = useState<SubtopicTBD>({chaptername: '', subtoptics: ''})
  const [editType, setEditType] = useState('')
  const [openConfirmationModalC, setOpenConfirmationModalC] = useState(false);
  const [openConfirmationModalS, setOpenConfirmationModalS] = useState(false);
  const [openEditModal, setEditModal] = useState(false);

  const [isapiLoad, setisapiLoad] = useState(false)

  const [redirectTo, setRedirectTo] = useState('');
  let history = useHistory();
  
  useEffect(() => {
    setisapiLoad(true)
    if(location.state && location.state.basicData && location.state.basicData.qbName){
      setBasicData(location.state.basicData)
      window.scroll(0,0)
    }
    if(location.state.allChapter && location.state.allChapter.length > 0) {
      setAllChapter(location.state.allChapter)
      setCurrentChapter(location.state.allChapter[0].chaptername)
      setCurrentSubTopic(location.state.allChapter[0].subtoptics[0])
    } else {
      setAllChapter([{chaptername: 'Chapter 1', subtoptics: ['SubTopic 1']}])
      setCurrentChapter('Chapter 1')
      setCurrentSubTopic('SubTopic 1')
    }
    if(location.state.structuredQB && location.state.structuredQB.length > 0) {
      setStructuredQB(location.state.structuredQB)
    }

    const params = new URLSearchParams(window.location.search);
    const qIndex = params.get('index');
    if(qIndex && location.state.structuredQB) {
      setEditMode(true)
      
      const qTBE = location.state.structuredQB[Number(qIndex)]

      if(qTBE.commonQuestionPart && qTBE.commonQuestionPart.length > 0) {
        const allQ: QuestionBankWithSelect[] = []
        location.state.structuredQB.forEach(tb => {
          if(tb.commonQuestionPart === qTBE.commonQuestionPart) {
            const newtb = {...tb}
            if(newtb.option3 === '') {
              newtb.option3 = undefined
            }
            if(newtb.option4 === '') {
              newtb.option4 = undefined
            }
            if(newtb.option5 === '') {
              newtb.option5 = undefined
            }
            if(newtb.option6 === '') {
              newtb.option6 = undefined
            }

            allQ.push(newtb)
          }
        })

        setQTBEStateCo(allQ)
        setquestionType('comprehensive')
        setCurrentChapter(qTBE.chaptername)
        setCurrentSubTopic(qTBE.subTopic)
      } else {
        setquestionType(qTBE.type)
        setquestionComplexity(qTBE.complexity)
        setCurrentChapter(qTBE.chaptername)
        setCurrentSubTopic(qTBE.subTopic)
  
        if(qTBE.type === 'single' || qTBE.type === 'multiple') {
          const opts = [qTBE.option1 as string, qTBE.option2 as string]
          if(qTBE.option3) {opts.push(qTBE.option3 as string)}
          if(qTBE.option4) {opts.push(qTBE.option4 as string)}
          if(qTBE.option5) {opts.push(qTBE.option5 as string)}
          if(qTBE.option6) {opts.push(qTBE.option6 as string)}
  
          const qTBEVal: ChoiceQuesType = {
            question: qTBE.questionDescription,
            solution: qTBE.answerDescription,
            option: opts,
            solutionlink: qTBE.solnVideoRef ? qTBE.solnVideoRef : '',
            answer: qTBE.answer,
            imageLinks: qTBE.imageLinks
          }
  
          setQTBEStateC(qTBEVal)
        } else if(qTBE.type === 'match' && qTBE.list1 && qTBE.list2 ) {
          const opts = [qTBE.option1 as string, qTBE.option2 as string]
          if(qTBE.option3) {opts.push(qTBE.option3 as string)}
          if(qTBE.option4) {opts.push(qTBE.option4 as string)}
          if(qTBE.option5) {opts.push(qTBE.option5 as string)}
          if(qTBE.option6) {opts.push(qTBE.option6 as string)}
  
          const qTBEVal: MatchQuesType = {
            question: qTBE.questionDescription,
            solution: qTBE.answerDescription,
            option: opts,
            list1: qTBE.list1,
            list2: qTBE.list2,
            solutionlink: qTBE.solnVideoRef ? qTBE.solnVideoRef : '',
            answer: qTBE.answer,
            imageLinks: qTBE.imageLinks
          }
  
          setQTBEStateM(qTBEVal)
        } else if(qTBE.type === 'numeric') {
          const qTBEVal: NumericQuesType = {
            question: qTBE.questionDescription,
            solution: qTBE.answerDescription,
            solutionlink: qTBE.solnVideoRef ? qTBE.solnVideoRef : '',
            answer: qTBE.answer,
            imageLinks: qTBE.imageLinks
          }
  
          setQTBEStateN(qTBEVal)
        } else {}
      }

    }

    setisapiLoad(false)
  }, [location]);

  const latexParserCheck =  (latexString:String) =>{
    let generator = new HtmlGenerator({ hyphenate: false })
    let doc = parse(latexString, { generator: generator }).htmlDocument()
    return doc.documentElement.outerHTML
  }

  const ifValidLatex = (latexString:string) => {
    let result;
    try {
      const parser = latexParserCheck(latexString);
      result = true
    } catch (error) {
      result = false
    }
    return result
  }


  const addChoiceQuestionToList = (ques: ChoiceQuesType) => {
    if(basicData && questionType !== 'comprehensive') {
      if(questionType === '') {
        enqueueSnackbar('Please Select Question Type', {variant: 'warning'});
      } else if(questionComplexity === '') {
        enqueueSnackbar('Please Select Question Complexity', {variant: 'warning'});
      } else {
        const nowQB = [...structuredQB]
  
        const optList = ['option1', 'option2', 'option3', 'option4', 'option5', 'option6']
    
        let newQuest: QuestionBankWithSelect = {
          srno: Number(nowQB.length + 1),
          questionBankFriendlyName: basicData.qbName,
          description: basicData.description,
          boardname: basicData.boardName,
          classname: basicData.className,
          subjectname: basicData.subject,
          chaptername: currentChapter,
          subTopic: currentSubTopic,
          type: questionType,
          complexity: questionComplexity,
          availableForMockTest: basicData.availStudent,
          commonQuestionPart: undefined,
          questionDescription: !ifValidLatex(ques.question) ? ('$' + ques.question + '$') : ques.question,
          answer: ques.answer,
          answerDescription: (ques.solution && ques.solution.length > 0) ? (!ifValidLatex(ques.solution) ? ('$' + ques.solution + '$') : ques.solution) : '',
          solnVideoRef: (ques.solutionlink && ques.solutionlink.length > 0) ? ques.solutionlink : undefined,
          imageLinks: ques.imageLinks
        }
  
        ques.option.forEach((opt, ind) => {
          newQuest = {...newQuest, ...{[optList[ind]]: !ifValidLatex(opt) ? ('$' + opt + '$') : opt}}
        })

        const {answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type } = {...newQuest}
        const compareObj = { answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type }

        if(containsObject(compareObj, (nowQB.filter(nqb => nqb.selected !== 0)).map(nq => {
          const {answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type } = {...nq}
          const thisnq = { answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type }
          if(thisnq.commonQuestionPart === "") {
            thisnq.commonQuestionPart = undefined
          }
          if(thisnq.imageLinks?.length === 0) {
            thisnq.imageLinks = undefined
          }
          if(thisnq.list1?.some(ls => ls === "")) {
            thisnq.list1 = undefined
          }
          if(thisnq.list2?.some(ls => ls === "")) {
            thisnq.list2 = undefined
          }
          if(thisnq.option1 === "") {
            thisnq.option1 = undefined
          }
          if(thisnq.option2 === "") {
            thisnq.option2 = undefined
          }
          if(thisnq.option3 === "") {
            thisnq.option3 = undefined
          }
          if(thisnq.option4 === "") {
            thisnq.option4 = undefined
          }
          if(thisnq.option5 === "") {
            thisnq.option5 = undefined
          }
          if(thisnq.option6 === "") {
            thisnq.option6 = undefined
          }
          if(thisnq.solnVideoRef === null) {
            thisnq.solnVideoRef = undefined
          }
          return thisnq
        }))) {
          enqueueSnackbar('Same Question Set is Added to the List', {variant: 'warning'});
        } else {
          nowQB.push(newQuest)
          setStructuredQB(nowQB)
          setquestionType('')
          setquestionComplexity('')
          setClearQval(prev => !prev)
          enqueueSnackbar('Added Question to List', {variant: 'success'});
          window.scroll(0,0)
        }
      }
    }
  }

  const addNumericQuestionToList = (ques: NumericQuesType) => {
    if(basicData && questionType !== 'comprehensive') {
      if(questionType === '') {
        enqueueSnackbar('Please Select Question Type', {variant: 'warning'});
      } else if(questionComplexity === '') {
        enqueueSnackbar('Please Select Question Complexity', {variant: 'warning'});
      } else {
        const nowQB = [...structuredQB]
        let newQuest: QuestionBankWithSelect = {
          srno: Number(nowQB.length + 1),
          questionBankFriendlyName: basicData.qbName,
          description: basicData.description,
          boardname: basicData.boardName,
          classname: basicData.className,
          subjectname: basicData.subject,
          chaptername: currentChapter,
          subTopic: currentSubTopic,
          type: questionType,
          complexity: questionComplexity,
          availableForMockTest: basicData.availStudent,
          commonQuestionPart: undefined,
          questionDescription: !ifValidLatex(ques.question) ? ('$' + ques.question + '$') : ques.question,
          answer: ques.answer,
          answerDescription: (ques.solution && ques.solution.length > 0) ? (!ifValidLatex(ques.solution) ? ('$' + ques.solution + '$') : ques.solution) : '',
          solnVideoRef: (ques.solutionlink && ques.solutionlink.length > 0) ? ques.solutionlink : undefined,
          imageLinks: ques.imageLinks
        }

        const {answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type } = {...newQuest}
        const compareObj = { answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type }

        if(containsObject(compareObj, (nowQB.filter(nqb => nqb.selected !== 0)).map(nq => {
          const {answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type } = {...nq}
          const thisnq = { answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type }
          if(thisnq.commonQuestionPart === "") {
            thisnq.commonQuestionPart = undefined
          }
          if(thisnq.imageLinks?.length === 0) {
            thisnq.imageLinks = undefined
          }
          if(thisnq.list1?.some(ls => ls === "")) {
            thisnq.list1 = undefined
          }
          if(thisnq.list2?.some(ls => ls === "")) {
            thisnq.list2 = undefined
          }
          if(thisnq.option1 === "") {
            thisnq.option1 = undefined
          }
          if(thisnq.option2 === "") {
            thisnq.option2 = undefined
          }
          if(thisnq.option3 === "") {
            thisnq.option3 = undefined
          }
          if(thisnq.option4 === "") {
            thisnq.option4 = undefined
          }
          if(thisnq.option5 === "") {
            thisnq.option5 = undefined
          }
          if(thisnq.option6 === "") {
            thisnq.option6 = undefined
          }
          if(thisnq.solnVideoRef === null) {
            thisnq.solnVideoRef = undefined
          }
          return thisnq
        }))) {
          enqueueSnackbar('Same Question Set is Added to the List', {variant: 'warning'});
        } else {
          nowQB.push(newQuest)
          setStructuredQB(nowQB)
          setquestionType('')
          setquestionComplexity('')
          setClearQval(prev => !prev)
          enqueueSnackbar('Added Question to List', {variant: 'success'});
          window.scroll(0,0)
        }
      }
    }
  }

  const addMatchQuestionToList = (ques: MatchQuesType) => {
    if(basicData && questionType !== 'comprehensive') {
      if(questionType === '') {
        enqueueSnackbar('Please Select Question Type', {variant: 'warning'});
      } else if(questionComplexity === '') {
        enqueueSnackbar('Please Select Question Complexity', {variant: 'warning'});
      } else {
        const nowQB = [...structuredQB]
  
        const optList = ['option1', 'option2', 'option3', 'option4', 'option5', 'option6']
    
        let newQuest: QuestionBankWithSelect = {
          srno: Number(nowQB.length + 1),
          questionBankFriendlyName: basicData.qbName,
          description: basicData.description,
          boardname: basicData.boardName,
          classname: basicData.className,
          subjectname: basicData.subject,
          chaptername: currentChapter,
          subTopic: currentSubTopic,
          type: questionType,
          complexity: questionComplexity,
          list1: ques.list1.map(l1 => !ifValidLatex(l1) ? ('$' + l1 + '$') : l1),
          list2: ques.list2.map(l2 => !ifValidLatex(l2) ? ('$' + l2 + '$') : l2),
          availableForMockTest: basicData.availStudent,
          commonQuestionPart: undefined,
          questionDescription: !ifValidLatex(ques.question) ? ('$' + ques.question + '$') : ques.question,
          answer: ques.answer,
          answerDescription: (ques.solution && ques.solution.length > 0) ? (!ifValidLatex(ques.solution) ? ('$' + ques.solution + '$') : ques.solution) : '',
          solnVideoRef: (ques.solutionlink && ques.solutionlink.length > 0) ? ques.solutionlink : undefined,
          imageLinks: ques.imageLinks
        }
  
        ques.option.forEach((opt, ind) => {
          newQuest = {...newQuest, ...{[optList[ind]]: !ifValidLatex(opt) ? ('$' + opt + '$') : opt}}
        })

        const {answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type } = {...newQuest}
        const compareObj = { answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type }

        if(containsObject(compareObj, (nowQB.filter(nqb => nqb.selected !== 0)).map(nq => {
          const {answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type } = {...nq}
          const thisnq = { answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type }
          if(thisnq.commonQuestionPart === "") {
            thisnq.commonQuestionPart = undefined
          }
          if(thisnq.imageLinks?.length === 0) {
            thisnq.imageLinks = undefined
          }
          if(thisnq.list1?.some(ls => ls === "")) {
            thisnq.list1 = undefined
          }
          if(thisnq.list2?.some(ls => ls === "")) {
            thisnq.list2 = undefined
          }
          if(thisnq.option1 === "") {
            thisnq.option1 = undefined
          }
          if(thisnq.option2 === "") {
            thisnq.option2 = undefined
          }
          if(thisnq.option3 === "") {
            thisnq.option3 = undefined
          }
          if(thisnq.option4 === "") {
            thisnq.option4 = undefined
          }
          if(thisnq.option5 === "") {
            thisnq.option5 = undefined
          }
          if(thisnq.option6 === "") {
            thisnq.option6 = undefined
          }
          if(thisnq.solnVideoRef === null) {
            thisnq.solnVideoRef = undefined
          }
          return thisnq
        }))) {
          enqueueSnackbar('Same Question Set is Added to the List', {variant: 'warning'});
        } else {
          nowQB.push(newQuest)
          setStructuredQB(nowQB)
          setquestionType('')
          setquestionComplexity('')
          setClearQval(prev => !prev)
          enqueueSnackbar('Added Question to List', {variant: 'success'});
          window.scroll(0,0)
        }
      }
    }
  }

  const addComprehensiveQuestionToList = (ques: QuestionBankWithSelect[], sbQ?: QuestionBankWithSelect[]) => {
    const newQues = ques.map(qs => {
      return {
        ...qs,
        ...{
          chaptername: currentChapter,
          subTopic: currentSubTopic
        }
      }
    })

    for(let qin=0; qin<newQues.length; qin++) {
      const nq = newQues[qin]

      const {answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type } = {...nq}
      const compareObj = { answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type }

      const newques = sbQ ? [...sbQ] : [...structuredQB]

      if(containsObject(compareObj, (newques.filter(nqb => nqb.selected !== 0)).map(nq => {
        const {answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type } = {...nq}
        const thisnq = { answer, answerDescription, availableForMockTest, boardname, chaptername, classname, commonQuestionPart, complexity, description, imageLinks, list1, list2, option1, option2, option3, option4, option5, option6, questionBankFriendlyName, questionDescription, solnVideoRef, subTopic, subjectname, type }
        if(thisnq.commonQuestionPart === "") {
          thisnq.commonQuestionPart = undefined
        }
        if(thisnq.list1?.some(ls => ls === "")) {
          thisnq.list1 = undefined
        }
        if(thisnq.list2?.some(ls => ls === "")) {
          thisnq.list2 = undefined
        }
        if(thisnq.option1 === "") {
          thisnq.option1 = undefined
        }
        if(thisnq.option2 === "") {
          thisnq.option2 = undefined
        }
        if(thisnq.option3 === "") {
          thisnq.option3 = undefined
        }
        if(thisnq.option4 === "") {
          thisnq.option4 = undefined
        }
        if(thisnq.option5 === "") {
          thisnq.option5 = undefined
        }
        if(thisnq.option6 === "") {
          thisnq.option6 = undefined
        }
        if(thisnq.solnVideoRef === null) {
          thisnq.solnVideoRef = undefined
        }
        return thisnq
      }))) {
        enqueueSnackbar('One or many Questions in this Comprehensive Question is already added to the List', {variant: 'warning'});
        return;
      }
    }

    const nowQB = sbQ ? [...sbQ, ...newQues] : [...structuredQB, ...newQues]
    setStructuredQB(nowQB)
    setquestionType('')
    setquestionComplexity('')
    window.scroll(0,0)

    if(sbQ) {
      const params = new URLSearchParams(window.location.search);
      const modeParam = params.get('mode');
      const searchParam = (modeParam === 'edit') ? `?mode=edit` : ``
      history.push({pathname: '/edumaticaQB/qlist', search: searchParam, state: {basicData: basicData, allChapter: allChapter, structuredQB: nowQB }})
    }
  }

  const updateChoiceQuestionToList = (ques: ChoiceQuesType) => {
    if(basicData && questionType !== 'comprehensive') {
      if(questionType === '') {
        enqueueSnackbar('Please Select Question Type', {variant: 'warning'});
      } else if(questionComplexity === '') {
        enqueueSnackbar('Please Select Question Complexity', {variant: 'warning'});
      } else {
        const nowQB = [...structuredQB]
  
        const optList = ['option1', 'option2', 'option3', 'option4', 'option5', 'option6']
    
        let newQuest: QuestionBankWithSelect = {
          srno: Number(nowQB.length + 1),
          questionBankFriendlyName: basicData.qbName,
          description: basicData.description,
          boardname: basicData.boardName,
          classname: basicData.className,
          subjectname: basicData.subject,
          chaptername: currentChapter,
          subTopic: currentSubTopic,
          type: questionType,
          complexity: questionComplexity,
          availableForMockTest: basicData.availStudent,
          commonQuestionPart: undefined,
          questionDescription: !ifValidLatex(ques.question) ? ('$' + ques.question + '$') : ques.question,
          answer: ques.answer,
          answerDescription: (ques.solution && ques.solution.length > 0) ? (!ifValidLatex(ques.solution) ? ('$' + ques.solution + '$') : ques.solution) : '',
          solnVideoRef: (ques.solutionlink && ques.solutionlink.length > 0) ? ques.solutionlink : undefined,
          imageLinks: ques.imageLinks
        }
  
        ques.option.forEach((opt, ind) => {
          newQuest = {...newQuest, ...{[optList[ind]]: !ifValidLatex(opt) ? ('$' + opt + '$') : opt}}
        })
  
        const params = new URLSearchParams(window.location.search);
        const qIndex = params.get('index');
        if(qIndex) {
          const sqbTBU = [...structuredQB]
          const params = new URLSearchParams(window.location.search);
          const modeParam = params.get('mode');
  
          if(modeParam === 'edit') {
            newQuest = {...newQuest, ...{_id: sqbTBU[Number(qIndex)]._id}}
          }
  
          sqbTBU[Number(qIndex)] = newQuest
          
          const searchParam = (modeParam === 'edit') ? `?mode=edit` : ``
  
          history.push({pathname: '/edumaticaQB/qlist', search: searchParam, state: {basicData: basicData, allChapter: allChapter, structuredQB: sqbTBU }})
        }
      }
    }
  }

  const updateNumericQuestionToList = (ques: NumericQuesType) => {
    if(basicData && questionType !== 'comprehensive') {
      if(questionType === '') {
        enqueueSnackbar('Please Select Question Type', {variant: 'warning'});
      } else if(questionComplexity === '') {
        enqueueSnackbar('Please Select Question Complexity', {variant: 'warning'});
      } else {
        const nowQB = [...structuredQB]
    
        let newQuest: QuestionBankWithSelect = {
          srno: Number(nowQB.length + 1),
          questionBankFriendlyName: basicData.qbName,
          description: basicData.description,
          boardname: basicData.boardName,
          classname: basicData.className,
          subjectname: basicData.subject,
          chaptername: currentChapter,
          subTopic: currentSubTopic,
          type: questionType,
          complexity: questionComplexity,
          availableForMockTest: basicData.availStudent,
          commonQuestionPart: undefined,
          questionDescription: !ifValidLatex(ques.question) ? ('$' + ques.question + '$') : ques.question,
          answer: ques.answer,
          answerDescription: (ques.solution && ques.solution.length > 0) ? (!ifValidLatex(ques.solution) ? ('$' + ques.solution + '$') : ques.solution) : '',
          solnVideoRef: (ques.solutionlink && ques.solutionlink.length > 0) ? ques.solutionlink : undefined,
          imageLinks: ques.imageLinks
        }
  
        const params = new URLSearchParams(window.location.search);
        const qIndex = params.get('index');
        if(qIndex) {
          const sqbTBU = [...structuredQB]
  
          const params = new URLSearchParams(window.location.search);
          const modeParam = params.get('mode');
  
          if(modeParam === 'edit') {
            newQuest = {...newQuest, ...{_id: sqbTBU[Number(qIndex)]._id}}
          }
  
          sqbTBU[Number(qIndex)] = newQuest
  
          const searchParam = (modeParam === 'edit') ? `?mode=edit` : ``
  
          history.push({pathname: '/edumaticaQB/qlist', search: searchParam, state: {basicData: basicData, allChapter: allChapter, structuredQB: sqbTBU }})
        }
      }
    }
  }

  const updateMatchQuestionToList = (ques: MatchQuesType) => {
    if(basicData && questionType !== 'comprehensive') {
      if(questionType === '') {
        enqueueSnackbar('Please Select Question Type', {variant: 'warning'});
      } else if(questionComplexity === '') {
        enqueueSnackbar('Please Select Question Complexity', {variant: 'warning'});
      } else {
        const nowQB = [...structuredQB]
  
        const optList = ['option1', 'option2', 'option3', 'option4', 'option5', 'option6']
    
        let newQuest: QuestionBankWithSelect = {
          srno: Number(nowQB.length + 1),
          questionBankFriendlyName: basicData.qbName,
          description: basicData.description,
          boardname: basicData.boardName,
          classname: basicData.className,
          subjectname: basicData.subject,
          chaptername: currentChapter,
          subTopic: currentSubTopic,
          type: questionType,
          complexity: questionComplexity,
          list1: ques.list1.map(l1 => !ifValidLatex(l1) ? ('$' + l1 + '$') : l1),
          list2: ques.list2.map(l2 => !ifValidLatex(l2) ? ('$' + l2 + '$') : l2),
          availableForMockTest: basicData.availStudent,
          commonQuestionPart: undefined,
          questionDescription: !ifValidLatex(ques.question) ? ('$' + ques.question + '$') : ques.question,
          answer: ques.answer,
          answerDescription: (ques.solution && ques.solution.length > 0) ? (!ifValidLatex(ques.solution) ? ('$' + ques.solution + '$') : ques.solution) : '',
          solnVideoRef: (ques.solutionlink && ques.solutionlink.length > 0) ? ques.solutionlink : undefined,
          imageLinks: ques.imageLinks
        }
  
        ques.option.forEach((opt, ind) => {
          newQuest = {...newQuest, ...{[optList[ind]]: !ifValidLatex(opt) ? ('$' + opt + '$') : opt}}
        })
  
        const params = new URLSearchParams(window.location.search);
        const qIndex = params.get('index');
        if(qIndex) {
          const sqbTBU = [...structuredQB]
  
          const params = new URLSearchParams(window.location.search);
          const modeParam = params.get('mode');
  
          if(modeParam === 'edit') {
            newQuest = {...newQuest, ...{_id: sqbTBU[Number(qIndex)]._id}}
          }
  
          sqbTBU[Number(qIndex)] = newQuest
  
          const searchParam = (modeParam === 'edit') ? `?mode=edit` : ``
  
          history.push({pathname: '/edumaticaQB/qlist', search: searchParam, state: {basicData: basicData, allChapter: allChapter, structuredQB: sqbTBU }})
        }
      }
    }
  }

  const moveToNext = () => {
    if(structuredQB.length === 0) {
      enqueueSnackbar('Please Add atleast One Question', {variant: 'warning'});
    } else {
      const params = new URLSearchParams(window.location.search);
      const modeParam = params.get('mode');
      const searchParam = (modeParam === 'edit') ? `?mode=edit` : ``

      history.push({pathname: '/edumaticaQB/qlist', search: searchParam, state: {basicData: basicData, allChapter: allChapter, structuredQB: structuredQB }})
    }
  }

  const moveBack = () => {
    history.push({pathname: '/edumaticaQB/create', state: {basicData: basicData, allChapter: allChapter, structuredQB: structuredQB }})
  }

  const addNewChap = () => {
    const chapLength = allChapter.length

    let chapterName = 'Chapter ' + String(chapLength + 1)
    let nextPiece = chapLength + 2;
    const allChapterName = allChapter.map(ac => ac.chaptername.toLowerCase())

    let loop = true
    while (loop) {
      if(allChapterName.includes(chapterName.toLowerCase())) {
        chapterName = chapterName.substring(0, chapterName.length - 1) + (String(nextPiece))
        nextPiece = nextPiece + 1
      } else {
        loop = false
      }
    }

    const nowChap = [...allChapter]
    nowChap.push({chaptername: chapterName, subtoptics: ['SubTopic 1']})
    setAllChapter(nowChap)
  }

  const addSubtopic = (ind: number) => {
    const nowChap = [...allChapter][ind]

    const subTopicLength = nowChap.subtoptics.length
    let subtopicName = 'SubTopic ' + String(subTopicLength + 1)
    let nextPiece = subTopicLength + 2;

    let loop = true
    while (loop) {
      if((nowChap.subtoptics).map(sb => sb.toLowerCase()).includes(subtopicName.toLowerCase())) {
        subtopicName = subtopicName.substring(0, subtopicName.length - 1) + (String(nextPiece))
        nextPiece = nextPiece + 1
      } else {
        loop = false
      }
    }

    nowChap.subtoptics.push(subtopicName)

    const nowChapter = [...allChapter]
    nowChapter[ind].subtoptics = nowChap.subtoptics
    setAllChapter(nowChapter)
  }

  const deleteChapter = (name: string) => {
    if(allChapter.length < 2) {
      enqueueSnackbar('There should be atleast one Chapter in the List', {variant: 'warning'});
    } else {
      if([...structuredQB].filter(sqb => sqb.chaptername === name).length > 0) {
        setChapterTBD(name)
        setOpenConfirmationModalC(true)
      } else {
        const thisChapterSet = [...allChapter].filter(ac => ac.chaptername !== name)
        setAllChapter(thisChapterSet)
        const newSQB = [...structuredQB].filter(sqb => sqb.chaptername !== name)
        setStructuredQB(newSQB)
        setCurrentChapter(allChapter[0].chaptername)
        setCurrentSubTopic(allChapter[0].subtoptics[0])
      }
    }
  }

  const deleteChapterOnConfirm = (name: string) => {
    const thisChapterSet = [...allChapter].filter(ac => ac.chaptername !== name)
    setAllChapter(thisChapterSet)
    let newSQB = [...structuredQB].filter(sqb => (sqb._id || sqb.chaptername !== name))
    newSQB = newSQB.map(sb => {
      if(sb._id && sb.chaptername === name) {
        return {
          ...sb,
          ...{status: 0}
        }
      } else {
        return sb
      }
    })
    setStructuredQB(newSQB)
    setCurrentChapter(allChapter[0].chaptername)
    setCurrentSubTopic(allChapter[0].subtoptics[0])
    setOpenConfirmationModalC(false)
  }

  const deleteSubtopic = (name: string, subname: string) => {
    const thischap = allChapter.findIndex(ac => ac.chaptername === name)
    if(thischap > -1) {
      const thissub = allChapter[thischap].subtoptics
      if(thissub.length < 2) {
        enqueueSnackbar('There should be atleast one Subtopic in the List', {variant: 'warning'});
      } else {
        if([...structuredQB].filter(sqb => sqb.chaptername === allChapter[thischap].chaptername && sqb.subTopic === subname).length > 0) {
          setSubtopicTBD({chaptername: name, subtoptics: subname})
          setOpenConfirmationModalS(true)
        } else {
          const updatedsubt = allChapter[thischap].subtoptics.filter(fl => fl !== subname)
          const updatedChap = [...allChapter]
          updatedChap[thischap].subtoptics = updatedsubt
          setAllChapter(updatedChap)
  
          const newSQB = [...structuredQB].filter(sqb => sqb.chaptername !== allChapter[thischap].chaptername && sqb.subTopic !== subname)
          setStructuredQB(newSQB)

          setCurrentChapter(allChapter[0].chaptername)
          setCurrentSubTopic(allChapter[0].subtoptics[0])
        }
      }
    }
  }

  const deleteSubtopicOnConfirm = (name: string, subname: string) => {
    const thischap = allChapter.findIndex(ac => ac.chaptername === name)
    const updatedsubt = allChapter[thischap].subtoptics.filter(fl => fl !== subname)
    const updatedChap = [...allChapter]
    updatedChap[thischap].subtoptics = updatedsubt
    setAllChapter(updatedChap)

    let newSQB = [...structuredQB].filter(sqb => (sqb._id || (sqb.chaptername !== allChapter[thischap].chaptername && sqb.subTopic !== subname)))
    newSQB = newSQB.map(sb => {
      if(sb._id && sb.chaptername === allChapter[thischap].chaptername && sb.subTopic === subname) {
        return {
          ...sb,
          ...{status: 0}
        }
      } else {
        return sb
      }
    })
    
    
    setStructuredQB(newSQB)
    setCurrentChapter(allChapter[0].chaptername)
    setCurrentSubTopic(allChapter[0].subtoptics[0])
    setOpenConfirmationModalS(false)
  }

  const editChapter = (name: string) => {
    setChapterTBE(name)
    setEditType('Chapter')
    setEditModal(true)
  }

  const editSubtopic = (name: string, subname: string) => {
    setSubtopicTBE({chaptername: name, subtoptics: subname})
    setEditType('Subtopic')
    setEditModal(true)
  }

  const editChapterCompl = (newname: string) => {
    const allChapterName = allChapter.map(ac => ac.chaptername.toLowerCase())
    if(allChapterName.includes(newname.toLowerCase())) {
      enqueueSnackbar('This Chapter is already Added', {variant: 'warning'});
    } else if(newname && (newname.replace(/ /g, "").length < 3)) {
      enqueueSnackbar('Chapter name should have atleast 3 characters excluding spaces', {variant: 'warning'});
    } else if(newname && (newname.length > 75)) {
      enqueueSnackbar('Chapter name should not have more than 75 characters', {variant: 'warning'});
    } else if(newname && newname.trim() && (!QB_PATTERN.test(newname.trim()))) {
      enqueueSnackbar('Invalid Chapter name', {variant: 'warning'});
    } else {
      const chapInd = allChapter.findIndex(ac => ac.chaptername === chapterTBE)
      const thisChapters = [...allChapter].map(ac => {
        if(ac.chaptername === chapterTBE) {
          return {
            chaptername: newname.trim(),
            subtoptics: ac.subtoptics
          }
        } else {
          return ac
        }
      })
      const firstSubtopic = allChapter[chapInd].subtoptics[0]
      setAllChapter(thisChapters)
      setCurrentChapter(newname.trim())
      setCurrentSubTopic(firstSubtopic)

      const thisQBs = [...structuredQB].map(sqb => {
        if(sqb.chaptername === chapterTBE) {
          return {
            ...sqb,
            ...{chaptername: newname.trim()}
          }
        } else {
          return sqb
        }
      })

      setStructuredQB(thisQBs)

      setEditModal(false)
      setEditType('')
      setSubtopicTBE({chaptername: '', subtoptics: ''})
      setChapterTBE('')
    }
  }

  const editSubtopicCompl = (newsubname: string) => {
    const thischap = allChapter.findIndex(ac => ac.chaptername === subtopicTBE.chaptername)
    if((allChapter[thischap].subtoptics).map(st => st.toLowerCase()).includes(newsubname.toLowerCase())) {
      enqueueSnackbar('This Subtopic is already Added', {variant: 'warning'});
    } else if(newsubname && (newsubname.replace(/ /g, "").length < 3)) {
      enqueueSnackbar('Subtopic name should have atleast 3 characters excluding spaces', {variant: 'warning'});
    } else if(newsubname && (newsubname.length > 75)) {
      enqueueSnackbar('Subtopic name should not have more than 75 characters', {variant: 'warning'});
    } else if(newsubname && newsubname.trim() && (!QB_PATTERN.test(newsubname.trim()))) {
      enqueueSnackbar('Invalid Subtopic name', {variant: 'warning'});
    } else {
      const updatedsubt = allChapter[thischap].subtoptics.map(st => {
        if(st === subtopicTBE.subtoptics) {
          return newsubname.trim()
        } else {
          return st
        }
      })

      const thisChaps = [...allChapter]
      thisChaps[thischap].subtoptics = updatedsubt
      setAllChapter(thisChaps)
      setCurrentChapter(subtopicTBE.chaptername)
      setCurrentSubTopic(newsubname.trim())

      const thisQBs = [...structuredQB].map(sqb => {
        if(sqb.chaptername === subtopicTBE.chaptername && sqb.subTopic === subtopicTBE.subtoptics) {
          return {
            ...sqb,
            ...{subTopic: newsubname.trim()}
          }
        } else {
          return sqb
        }
      })

      setStructuredQB(thisQBs)

      setEditModal(false)
      setEditType('')
      setSubtopicTBE({chaptername: '', subtoptics: ''})
      setChapterTBE('')
    }
  }

  if (redirectTo.length > 0) {
    return <Redirect to={redirectTo} />;
  }

  if(isapiLoad) {
    return <div>
        Loading ...
    </div>
  }

  return (
    <div>
      <MiniDrawer>
      <Container maxWidth="lg" style={{padding: '30px 2.5%'}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Box style={{marginBottom: '25px', background: '#ffffff', padding: '25px', borderRadius: '14px'}}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography style={{color : '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>All Chapters</Typography>
                </Box>
                <Box>
                  <Tooltip title="Add Chapter">
                    <ChromeReaderModeIcon onClick={addNewChap} />
                  </Tooltip>
                </Box>
              </Box>
              <TreeView
                style={{marginTop: '25px'}}
                defaultExpanded={['0']}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}
              >
                {allChapter.map((chap, index) => {
                  return (
                    <Box marginBottom="8px" key={index}>
                      <StyledTreeItem key={index.toString()} nodeId={index.toString()}  labelText={(index + 1).toString() + '. ' + (chap.chaptername)} labelIcon={MenuBookIcon}
                        classes={(currentChapter === chap.chaptername) ? { label: classes.label } : {label : classes.graylabel}}
                        currentActive={(currentChapter === chap.chaptername)}
                        thistreetype="chapter"
                        mainClickEvent={() => {}}
                        editClickEvent={() => {editChapter(chap.chaptername)}}
                        addClickEvent={() => addSubtopic(index)}
                        deleteClickEvent={() => {deleteChapter(chap.chaptername)}}
                        labelInfo={(structuredQB.filter(sqb => sqb.status!==0 && sqb.selected!==0 && sqb.chaptername === chap.chaptername).length > 0) ? String(structuredQB.filter(sqb => sqb.status!==0 && sqb.selected!==0 && sqb.chaptername === chap.chaptername).length) : ''}
                      >
                        {chap.subtoptics.map((subto, subindex) => {
                          return (
                            <StyledTreeItem key={index.toString()+subindex.toString()} nodeId={index.toString()+subindex.toString()} labelText={(subto)} labelIcon={BookIcon}
                              classes={(currentChapter === chap.chaptername && currentSubTopic === subto) ? { label: classes.label } : {label : classes.graylabel}}
                              thistreetype="subtopic"
                              currentActive={false}
                              editClickEvent={() => {editSubtopic(chap.chaptername, subto)}}
                              addClickEvent={() => {}}
                              deleteClickEvent={() => {deleteSubtopic(chap.chaptername, subto)}}
                              mainClickEvent={() => {
                                if(currentChapter !== chap.chaptername) {
                                  setCurrentChapter(chap.chaptername);
                                }
                                if(currentSubTopic !== subto) {
                                  setCurrentSubTopic(subto)
                                }
                              }}
                              labelInfo={(structuredQB.filter(sqb => (sqb.status!==0 && sqb.selected!==0 && sqb.chaptername === chap.chaptername && sqb.subTopic === subto)).length > 0) ? String(structuredQB.filter(sqb => (sqb.status!==0 && sqb.selected!==0 && sqb.chaptername === chap.chaptername && sqb.subTopic === subto)).length) : ''}
                            >
                            </StyledTreeItem> 
                          )
                        })}
                      </StyledTreeItem>
                    </Box>
                  )
                })}
              </TreeView>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box style={{marginBottom: '25px', background: '#ffffff', padding: '25px', borderRadius: '14px'}}>
              <Typography style={{color : '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, marginTop: '15px', marginLeft : '5px'}}>All Questions</Typography>
              <Grid container spacing={3} style={{marginTop : '15px', paddingRight: '10px'}}>
                <Grid item xs={6}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel>Select Question Type</InputLabel>
                    <Select
                      value={questionType}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        setquestionType(e.target.value as "single" | "multiple" | "numeric" | "match" | "comprehensive")
                      }}
                      label="Select Question Type"
                      readOnly={editMode}
                    >
                      <MenuItem value={'single'}>Single Answer Multiple Choice</MenuItem>
                      <MenuItem value={'multiple'}>Multiple Answer Multiple Choice</MenuItem>
                      <MenuItem value={'numeric'}>Numeric Answer</MenuItem>
                      <MenuItem value={'match'}>Match the Following</MenuItem>
                      <MenuItem value={'comprehensive'}>Comprehensive Question</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  {(questionType !== 'comprehensive') &&
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel>Select Question Complexity</InputLabel>
                      <Select
                        value={questionComplexity}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                          setquestionComplexity(e.target.value as "easy" | "medium" | "high")
                        }
                        label="Select Question Complexity"
                      >
                        <MenuItem value={'easy'}>Easy</MenuItem>
                        <MenuItem value={'medium'}>Medium</MenuItem>
                        <MenuItem value={'high'}>High</MenuItem>
                      </Select>
                    </FormControl>
                  }
                </Grid>
              </Grid>

              <Grid container style={{marginTop: '25px', marginBottom: '20px'}}>
                <Grid item xs={12}>
                  {(questionType === 'single' && questionComplexity !== '') &&
                    <SingleQuestion clearQval={clearQval} addChoiceQuestionToList={addChoiceQuestionToList} updateChoiceQuestionToList={updateChoiceQuestionToList} questionComplexity={questionComplexity} qTBEStateC={qTBEStateC} />
                  }
                  {(questionType === 'multiple' && questionComplexity !== '') &&
                    <MultipleQuestion clearQval={clearQval} addChoiceQuestionToList={addChoiceQuestionToList} updateChoiceQuestionToList={updateChoiceQuestionToList} questionComplexity={questionComplexity} qTBEStateC={qTBEStateC} />
                  }
                  {(questionType === 'numeric' && questionComplexity !== '') &&
                    <NumericQuestion clearQval={clearQval} addNumericQuestionToList={addNumericQuestionToList} updateNumericQuestionToList={updateNumericQuestionToList} questionComplexity={questionComplexity}  qTBEStateN={qTBEStateN} />
                  }
                  {(questionType === 'match' && questionComplexity !== '') &&
                    <MatchQuestion clearQval={clearQval} addMatchQuestionToList={addMatchQuestionToList} updateMatchQuestionToList={updateMatchQuestionToList} questionComplexity={questionComplexity} qTBEStateM={qTBEStateM} />
                  }
                  {(questionType === 'comprehensive') &&
                    <ComprehensiveQuestion basicData={basicData} structuredQB={structuredQB} addComprehensiveQuestionToList={addComprehensiveQuestionToList} qTBEStateCo={qTBEStateCo} setStructuredQB={setStructuredQB}/>
                  }
                  {(questionType !== 'comprehensive' && (questionType === '' || questionComplexity === '')) &&
                    <Grid container>
                      <Grid item xs={12} style={{textAlign: 'center', marginTop: '30px'}}>
                        <div>No Data</div>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'center', marginTop: '20px'}}>
                        {(structuredQB.length === 0) &&
                          <div>
                            <Typography style={{fontSize: fontOptions.size.small, color: '#3D3D3D'}}>You have not uploaded anything yet</Typography>
                            <Typography style={{marginTop: '10px', color: '#A4A6A5'}}>Please select question type and complexity in order to start adding a new question</Typography>
                          </div>
                        }
                        {(structuredQB.length > 0) &&
                          <div>
                            <Typography style={{color: '#A4A6A5'}}>Please select question type and complexity in order to add another question</Typography>
                          </div>
                        }
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {!editMode &&
              <span style={{float: 'right', marginTop: '20px', marginRight: '9%'}} className={classes.addAssi}>
                <Button color="primary" disableElevation variant="outlined" 
                  style={{marginRight: '15px', padding: '10px 40px', marginTop: '1px'}}
                  onClick={moveBack}
                >
                  {'Back'}
                </Button>
                <Button color="primary" disableElevation variant="contained" 
                  onClick={moveToNext}
                >
                  Save All
                </Button>
              </span>
            }
          </Grid>
        </Grid>
      </Container>
      <ConfirmModal
        openModal={openModal}
        onClose={() => {setOpenModal(false)}}
        allChapter={allChapter}
        setAllChapter={setAllChapter}
        structuredQB={structuredQB}
        setStructuredQB={setStructuredQB}
      />
      <ConfirmationModal
        header="Delete Chapter"
        helperText="Questions were added in this Chapter. Continue?"
        openModal={openConfirmationModalC}
        onClose={() => {
          setOpenConfirmationModalC(false)
          setChapterTBD('')
        }}
        handleDelete={() => deleteChapterOnConfirm(chapterTBD)}
      />
      <ConfirmationModal
        header="Delete Subtopic"
        helperText="Questions were added in this Subtopic. Continue?"
        openModal={openConfirmationModalS}
        onClose={() => {
          setOpenConfirmationModalS(false)
          setSubtopicTBD({chaptername: '', subtoptics: ''})
        }}
        handleDelete={() => deleteSubtopicOnConfirm(subtopicTBD.chaptername, subtopicTBD.subtoptics)}
      />
      <EditChapterSubtpoicModal 
        openModal={openEditModal}
        onClose={() => {
          setEditModal(false)
          setEditType('')
          setSubtopicTBE({chaptername: '', subtoptics: ''})
          setChapterTBE('')
        }}
        type={editType}
        prev={editType === 'Chapter' ? chapterTBE : subtopicTBE.subtoptics}
        editChapterCompl={editChapterCompl}
        editSubtopicCompl={editSubtopicCompl}
      />
      </MiniDrawer>
    </div>
  );
}


export default QBQuestions;