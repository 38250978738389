import axios from 'axios'
import { jobApplicationRes, jobPostingRes } from './interfaces'

const BASE_URL = process.env.REACT_APP_API_2 + "jobposting/"
const CREATE_JOB = BASE_URL + "createJob"
const GET_JOB_SPREADSHEET = BASE_URL + "getSpreadsheet"
const FETCH_DOWNLOAD_URL_FOR_CV = BASE_URL + "fetchDownloadUrlForCv/"
const UPDATE_APPLICANT = BASE_URL + "updateApplicant/"
const UPDATE_APPLICANTS = BASE_URL + "updateApplicants/"
const GET_JOB_ID = BASE_URL + "getJobId/"
const GET_JOBS = BASE_URL + "getJobsForBO/"
const UPDATE_JOB = BASE_URL + "updateJob/"
const DELETE_JOB = BASE_URL + "deleteJob/"

interface jobQuery {
    fromDate: Date,
    endDate: Date,
    status: string,
    dataType: string
}

export const getJobsSpreadsheet = async (query: jobQuery) => {
    const axiosOptions: any = {
        params: query,
    }
    if(query.dataType === 'file') {
        axiosOptions.responseType = 'blob'
        await axios.get(GET_JOB_SPREADSHEET, axiosOptions).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'job-applicants.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    } 
    const res = await axios.get<{data: jobApplicationRes[]}>(GET_JOB_SPREADSHEET, axiosOptions).then((response) => response.data.data);
    return res
}

export const updateApplicant = async (updateReq: any) => {
    return await axios.post(UPDATE_APPLICANT, updateReq)
}

export const updateApplicants = async (updateReq: any) => {
    return await axios.post(UPDATE_APPLICANTS, updateReq)
}

export const fetchDownloadUrlForCv = async (uuid: string) => {
    const res = await axios.get(FETCH_DOWNLOAD_URL_FOR_CV, {params: {uuid}})
    return res.data.data
}

export const createJob = async (obj: any) => {
    return await axios.post(CREATE_JOB, obj)
}

export const getJobId = async (dept: string) => {
    const res = await axios.get(GET_JOB_ID, {params: {dept}})
    return res.data.data
}

export const getJobs = async () => {
    const res = await axios.get<{data: jobPostingRes[]}>(GET_JOBS)
    return res.data.data
}

export const updateJob = async (data: Object) => {
    await axios.post(UPDATE_JOB, data)
}

export const deleteJob = async (_id: string) => {
    await axios.post(DELETE_JOB, {_id})
}