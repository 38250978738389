import axios from 'axios'
import { otpResponse, academicResponse, consolidatedReport } from './interfaces'

const BASE_URL = process.env.REACT_APP_API + "admin/"
const SEND_EMAIL = BASE_URL + "sendEmail"
const OTPS = BASE_URL + "otps"
const ACADEMIC_DETAILS = BASE_URL + "academicDetails"
const CONSOLIDATED_REPORT = BASE_URL + 'consolidatedReport'
const SECRET_DEV = BASE_URL + "generateSecret"
const SECRET_DEV_DELETE = BASE_URL + "deleteSecret"
const SECRET_DEV_UPDATE = BASE_URL + "changeStatusSecret"

export const sendEmail = async (obj: {
    mail: [{
        name: string,
        email: string
    }],
    subject: string,
    text: string
}) => {
    await axios.post(SEND_EMAIL, obj)
}

export const fetchOtps = async (params: {startDate: Date, endDate: Date}) => {
    const response = await axios.get<{data: otpResponse[]}>(OTPS, {params})
    return response.data.data
}

export const fetchAcademicDetails = async (params: {startDate: Date, endDate: Date, userType: string}) => {
    const response = await axios.get<{data: academicResponse[]}>(ACADEMIC_DETAILS, {params})
    return response.data.data
}

export const getConsolidatedReport = async(params: {
	userType: string,
	reportType: string,
	modelId: string
}) => {
	const response = await axios.get<{data: consolidatedReport[]}>(CONSOLIDATED_REPORT, {params})
	return response.data.data
}

export const fetchAllSecretKeys = async(params: {
	ownerId: string,
	mobile: string
}) => {
	const response = await axios.get(SECRET_DEV, {params})
	return response.data.data
}

export const generateSecretKey = async (data: {
    ownerId: string,
    mobile: string,
    orgId: string
}) => {	//crosscheck
    const response = await axios.post(SECRET_DEV, data);
    return response.data.data;
};

export const deleteSecret = async (data: {
    ownerId: string,
    userKey: string,
}) => {	//crosscheck
    const response = await axios.post(SECRET_DEV_DELETE, data);
    return response.data.data;
};

export const changePairStatus = async (data: {
    ownerId: string,
    userKey: string,
}) => {	//crosscheck
    const response = await axios.post(SECRET_DEV_UPDATE, data);
    return response.data.data;
};

