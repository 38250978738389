export const ClassID = {
    Other: 0,
    One:1,
    Two: 2,
    Three: 3,
    Four: 4,
    Five: 5,
    Six: 6,
    Seven: 7,
    Eight: 8,
    Nine: 9,
    Ten: 10,
    Eleven: 11,
    Twelve: 12,
    IITJEE: 13,
    NEET: 14,
    RAILWAY: 15,
  };
  
  export const getClassDesc = (code: number) => {
    switch (code) {
      case ClassID.One:
        return "Class I";
      case ClassID.Two:
        return "Class II";
      case ClassID.Three:
        return "Class III";
      case ClassID.Four:
        return "Class IV";  
      case ClassID.Five:
        return "Class V";
      case ClassID.Six:
        return "Class VI";
      case ClassID.Seven:
        return "Class VII";
      case ClassID.Eight:
        return "Class VIII";
      case ClassID.Nine:
        return "Class IX";
      case ClassID.Ten:
        return "Class X";
      case ClassID.Eleven:
        return "Class XI";
      case ClassID.Twelve:
        return "Class XII";
      case ClassID.IITJEE:
         return "IIT-JEE";
      case ClassID.NEET:
        return "NEET";
      case ClassID.RAILWAY:
        return "Railway";
      default:
        return "Other"
    }
  };
