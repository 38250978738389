import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Box,
  TextField,
  Button,
  Container,
  CssBaseline,
  darken,
  Grid,
  Typography,
  makeStyles,
  IconButton,
  MenuItem,
  Menu,
  FormControl,
  Input,
  Chip,
} from "@material-ui/core";
import MiniDrawer from "../../common/components/SideDrawer";
import { deleteBoardCommonTypes, deleteClassCommonTypes, deleteSubjectCommonTypes, fetchBoardCommonTypes, fetchClassCommonTypes, fetchSubjectCommonTypes, saveBoardCommonTypes, saveClassCommonTypes, saveSubjectCommonTypes } from "../helpers/api";
import { StandardBoardTypesInterface } from "../contracts/StandardBoardTypesInterface";
import { StandardClassTypesInterface } from "../contracts/StandardClassTypesInterface";
import { StandardSubjectTypesInterface } from "../contracts/StandardSubjectTypesInterface";
import { fontOptions } from "../../common/theme";
import { menuOptions } from "../constants/edumaticaStandards_constants";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import { StandardAutoComplete } from "../contracts/CommonTypesInterface";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121',
	  },
    label: {
      fontWeight: fontOptions.weight.bold,
      fontSize: fontOptions.size.small,
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
          margin: "5px",
      },
    }
})

const ITEM_HEIGHT = 48;

interface Props extends RouteComponentProps {}

const EdumaticaStandardPage: React.FC<Props> = ({ location }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [preDefBoards, setPreDefBoards] = React.useState<StandardBoardTypesInterface[]>([])
  const [preDefClasses, setPreDefClasses] = React.useState<StandardClassTypesInterface[]>([])
  const [preDefSubjects, setPreDefSubjects] = React.useState<StandardSubjectTypesInterface[]>([])

  const [headers, setHeaders] = React.useState<StandardAutoComplete[]>([])
  const [header, setHeader] = React.useState<StandardAutoComplete>({title: ''})
  const filter = createFilterOptions<StandardAutoComplete>();

  const [id, setId] = React.useState<number>(0)
  const [desc, setDesc] = React.useState<string | null>(null)
  const [commonNames, setCommonNames] = React.useState<string[]>([])
  const [newCommonName, setNewCommonName] = React.useState<string | null>(null)

  const [isOld, setIsOld] = React.useState<boolean>(false)

  // Main Side Menu
	const [activeMenuItem, setActiveMenuItem] = React.useState<string | null>(menuOptions[0]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  console.log(activeMenuItem, headers)

  // Main Side Menu
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };
  const handleMenu = (option: string) => {
      setActiveMenuItem(option);
      setAnchorEl(null);
  }

  React.useEffect(() => {
   (async () => {
    await fetchAllCommonTypes()
   })();
  }, [])

  React.useEffect(() => {
    handleActiveMenu()
    setIsOld(false)
    setHeader({title:''})
    setId(0)
    setDesc('')
    setCommonNames([])
  }, [activeMenuItem])

  React.useEffect(() => {
    if(header.title.length > 0) {
      handleHeaderChange()
    } else{
      setIsOld(false)
      setId(0)
      setDesc('')
      setCommonNames([])
    }
    
  },[header])

  function handleHeaderChange() {
    if(activeMenuItem === menuOptions[0]) {
      const filteredItem = preDefBoards.filter(el => el.boardName === header.title)
      if(filteredItem.length > 0) {
        setId(filteredItem[0].boardId)
        setDesc(filteredItem[0].boardDesc)
        setCommonNames(filteredItem[0].commonNames)
        setIsOld(true)
      }
    }
    if(activeMenuItem === menuOptions[1]) {
      const filteredItem = preDefClasses.filter(el => el.className === header.title)
      if(filteredItem.length > 0) {
        setId(filteredItem[0].classId)
        setDesc(filteredItem[0].classDesc)
        setCommonNames(filteredItem[0].commonNames)
        setIsOld(true)
      }
    }
    if(activeMenuItem === menuOptions[2]) {
      const filteredItem = preDefSubjects.filter(el => el.subjectName === header.title)
      if(filteredItem.length > 0) {
        setId(filteredItem[0].subjectId)
        setDesc(filteredItem[0].subjectDesc)
        setCommonNames(filteredItem[0].commonNames)
        setIsOld(true)
      }
    }
  }

  function handleActiveMenu() {
    if(activeMenuItem === menuOptions[0]) {
      const filteredHeaders = preDefBoards.map(el => ({title:el.boardName}))
      setHeaders(filteredHeaders)
    }
    if(activeMenuItem === menuOptions[1]) {
      const filteredHeaders = preDefClasses.map(el => ({title:el.className}))
      setHeaders(filteredHeaders)
    }
    if(activeMenuItem === menuOptions[2]) {
      const filteredHeaders = preDefSubjects.map(el => ({title:el.subjectName}))
      setHeaders(filteredHeaders)
    }
  }

  async function fetchAllCommonTypes() {
    const preDefBoardsRes = fetchBoardCommonTypes()
    const preDefClassesRes = fetchClassCommonTypes()
    const preDefSubsRes = fetchSubjectCommonTypes()
    const [allBoards, allClasses, allSubs] = await Promise.all([preDefBoardsRes, preDefClassesRes, preDefSubsRes])
    setPreDefBoards(allBoards)
    setPreDefClasses(allClasses)
    setPreDefSubjects(allSubs)

    // @ts-ignore
    const filteredHeaders = allBoards.map(el => ({title:el.boardName}))
    setHeaders(filteredHeaders)
  }

  const handleSave = async () => {
    if(!header.title || !id || !desc || !commonNames) {
      enqueueSnackbar('Please fill in all the fields', {variant: "warning"})
      return
    }
    if(activeMenuItem === menuOptions[0]) {
      await saveBoardCommonTypes(header.title, id, desc, commonNames)
    }
    if(activeMenuItem === menuOptions[1]) {
      await saveClassCommonTypes(header.title, id, desc, commonNames)
    }
    if(activeMenuItem === menuOptions[2]) {
      await saveSubjectCommonTypes(header.title, id, desc, commonNames)
    }
    setHeader({title:''})
    setId(0)
    setDesc('')
    setCommonNames([])
    setIsOld(false)
    await fetchAllCommonTypes()
  }

  const handleDelete = async () => {
    if(!header.title) return
    if(activeMenuItem === menuOptions[0]) {
      await deleteBoardCommonTypes(header.title)
    }
    if(activeMenuItem === menuOptions[1]) {
      await deleteClassCommonTypes(header.title)
    }
    if(activeMenuItem === menuOptions[2]) {
      await deleteSubjectCommonTypes(header.title)
    }
    setHeader({title:''})
    setId(0)
    setDesc('')
    setCommonNames([])
    setIsOld(false)
    await fetchAllCommonTypes()
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <MiniDrawer>
        <Container maxWidth="lg">
          <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="5px"
            color="#fff"
          >
            <Grid item container>
              <Grid item sm={8}>
                <Box style={{ height: "100%" }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: "100%" }}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.title}>
                        Edumatica Standard Keywords
                      </Typography>
                      <Typography>
                        Add or Edit Edumatica Standard Keywords
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            bgcolor="#ffffff"
            borderRadius="5px"
            padding="12px 25px"
            marginTop="25px"
          >
            <Box display="flex" gridColumnGap="10px" alignItems="center">
              <div>
              <IconButton
                color="primary"
                // style={{border:"1px solid #4C8BF5"}}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "40ch",
                  },
                }}
              >
                {menuOptions.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === activeMenuItem}
                    onClick={() => handleMenu(option)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
              </div>
              <h2>{activeMenuItem}</h2>
            </Box>

            <Box margin="12px auto" width="70%">
            <Grid container>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="normal">
                  <Box className={classes.label}>Enter Standard Name</Box>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={8}>
                <Autocomplete
                  value={header}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setHeader({
                        title: newValue,
                      });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setHeader({
                        title: newValue.inputValue,
                      });
                    } else {
                      if (newValue) {
                        setHeader(newValue);
                      }
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                      filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  autoSelect
                  handleHomeEndKeys
                  options={headers}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                  }}
                  renderOption={(option) => option.title}
                  style={{ width: 300 }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Header"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container>
								<Grid item xs={12} md={4}>
									<FormControl fullWidth margin="normal">
										<Box fontWeight="bold" marginTop="5px">
                      Standard ID <span>*</span>
										</Box>
									</FormControl>
								</Grid>
			
								<Grid item xs={12} md={8}>
									<FormControl fullWidth margin="normal">
										<Input
                      required
											placeholder="Edumatica Standard ID"
                      type="number"
											value={id as number}
											onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
												setId(e.target.value as number)
											}
										/>
									</FormControl>
								</Grid>
							</Grid>

              <Grid container>
								<Grid item xs={12} md={4}>
									<FormControl fullWidth margin="normal">
										<Box fontWeight="bold" marginTop="5px">
                      Standard Description<span>*</span>
										</Box>
									</FormControl>
								</Grid>
			
								<Grid item xs={12} md={8}>
									<FormControl fullWidth margin="normal">
										<Input
											placeholder="Edumatica Standard Description"
											value={desc}
											inputProps={{ maxLength: 50 }}
											onChange={(e) => setDesc(e.target.value as string)}
										/>
									</FormControl>
								</Grid>
							</Grid>

              <Grid container>
								<Grid item xs={12} md={4}>
									<FormControl fullWidth margin="normal">
										<Box fontWeight="bold" marginTop="5px">
                      New Common Name<span>*</span>
										</Box>
									</FormControl>
								</Grid>
			
								<Grid item xs={12} md={6}>
									<FormControl fullWidth margin="normal">
										<Input
											placeholder="Edumatica Standard Common Name"
											value={newCommonName}
											inputProps={{ maxLength: 50 }}
											onChange={(e) => setNewCommonName(e.target.value as string)}
										/>
									</FormControl>
								</Grid>

                <Box marginLeft="5px">
									<FormControl fullWidth margin="normal">
										<IconButton color="secondary" onClick={() => {
                      if(!newCommonName) return
                      let currentCNs = commonNames
                      currentCNs.push(newCommonName);
                      setCommonNames(currentCNs);
                      setNewCommonName("");
                    }}>
                      <AddIcon/>
                    </IconButton>
									</FormControl>
								</Box>
							</Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth margin="normal">
										<Box fontWeight="bold" marginTop="5px">
                      All Common Names<span>*</span>
										</Box>
									</FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
									<FormControl fullWidth margin="normal">
                    <Box className={classes.root}>
                    {
                      commonNames.map(el => {
                        return (
                          <Chip
                            label={el}
                            color="primary"
                            variant="outlined"
                            onDelete={() => {
                              let currentCNs = commonNames.filter(ele => ele !== el);
                              setCommonNames(currentCNs);
                            }}
                          />
                        )
                      })
                    }
                    </Box>
									</FormControl>
								</Grid>
              </Grid>

              <Grid container>
								<Grid item xs={12} md={8}>
								</Grid>

                <Grid item xs={12} md={2}>
									<FormControl fullWidth margin="normal">
                    {
                      isOld && (
                        <Button style={{marginRight:"5px"}} disableElevation variant="outlined" color="primary" onClick={handleDelete}>
                          Delete
                        </Button>
                      )
                    }
									</FormControl>
								</Grid>

                <Grid item xs={12} md={2}>
									<FormControl fullWidth margin="normal">
										<Button disableElevation variant="contained" color="primary" onClick={handleSave}>
                      Save
                    </Button>
									</FormControl>
								</Grid>

							</Grid>

            </Box>

          </Box>
        </Container>
      </MiniDrawer>
    </React.Fragment>
  );
};

export default EdumaticaStandardPage;
