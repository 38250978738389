import React, { useState, useEffect, FunctionComponent, FormEvent } from 'react';
import { makeStyles, createStyles, Theme, lighten } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
	Box, Container, Grid, Checkbox, Button, TextField, Input, MenuItem, Menu,
	Typography, FormControlLabel, Switch, FormHelperText, OutlinedInput, IconButton, CssBaseline, Card, CardContent, CardHeader, Tooltip, InputAdornment, Toolbar, Radio
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
import { connect, useDispatch } from 'react-redux';

import MiniDrawer from "../../common/components/SideDrawer"
import { fontOptions } from '../../common/theme';
import { AccountBalance, Block, Clear, Delete, Edit, Receipt, Search, Visibility, VisibilityOff } from '@material-ui/icons';
import useStyles from '../../leadsManagement/styles';
import { Admin, Role, User, CustomUser } from '../../common/contracts/dashboard';
import Datagrids, { datagridCellExpand } from '../../dashboard/components/dataGrid';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../utilities/constants';
import Datepickers from '../../dashboard/components/datepickers';
import { jobApplicantStatus, jobApplicantUpdate, jobApplication, jobApplicationRes, jobPostingStatus } from '../interfaces';
import { getJobsSpreadsheet, updateApplicants } from '../api';
import JobApplicantModal from '../components/jobApplicantModal';
import * as XLSX from 'xlsx';
import { compareAsc } from 'date-fns';
import { onApiError } from '../../common/helpers';

interface Props extends RouteComponentProps {

}
interface EnhancedTableToolbarProps {
	searchText: string;
	setSearchText: React.Dispatch<React.SetStateAction<string>>
	setFocused: React.Dispatch<React.SetStateAction<boolean>>
	maxLength: number
}


const useToolbarStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(1),
			height: '100%'
		},
		highlight:
			theme.palette.type === 'light'
				? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
				}
				: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
				},
		title: {
			flex: '1 1 100%'
		}
	})
);

const JobPosting: FunctionComponent<Props> = ({ history }) => {

	const { enqueueSnackbar } = useSnackbar()
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const [selected, setSelected] = useState<string[]>([]);
	// const [jobApplicationsList, setJobApplicationsList] = useState<jobApplicationRes[]>([]);	//crosscheck
	const [fromDate, setFromDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [status, setStatus] = useState('')

	const [showDataGrid, setShowDataGrid] = useState(false)
	const [gridRows, setGridRows] = useState<jobApplicationRes[]>([])
	const [gridColumns, setGridColumns] = useState<GridColDef[]>([])

	const [openModal, setOpenModal] = useState(false)
	const [selectedApplicant, setSelectedApplicant] = useState<jobApplicationRes>()

	const styles = useStyles();
	const dispatch = useDispatch();


	useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		const requiredPermissions = [MASTER_PERMISSIONS.getSpreadsheet, MASTER_PERMISSIONS.updateApplicants]
		var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	const readExcel = (file: File | null) => {
		const promise = new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			if (file) {
				fileReader.readAsArrayBuffer(file);
				fileReader.onload = (e) => {
					const bufferArray = e.target ? e.target.result : '';
					const wb = XLSX.read(bufferArray, { type: 'buffer' });
					const wsname = wb.SheetNames[0];
					const ws = wb.Sheets[wsname];

					/* Convert array to json*/
					const jsonData = XLSX.utils.sheet_to_json(ws);
					resolve(jsonData);
				};
				fileReader.onerror = (error) => {
					reject(error);
				};
			}
		});
		promise.then(async (applicantsArr: any) => {
			const applicantsUpdateReq: jobApplicantUpdate[] = await Promise.all(
				applicantsArr &&
				applicantsArr.map(async (applicant: any) => {

					return {
						caseId: applicant["Case Id"],
						status: applicant["Status"],
						remarks: applicant["Remarks"]
					};
				})
			);
			if(!validateApplicantUpdates(applicantsUpdateReq)) return;
			await updateApplicants(applicantsUpdateReq)
			.then(() => {
				enqueueSnackbar('Successfully Updated', {variant: 'success'})
			})
			.catch(err => onApiError(err, enqueueSnackbar, setRedirectTo))
		});
	};

	const validateApplicantUpdates = (applicantsUpdateReq: jobApplicantUpdate[]) => {
		return applicantsUpdateReq.every(cur => {
			const {caseId, status, remarks} = cur
			if(!caseId) {
				enqueueSnackbar('Invalid spreadsheet file', {variant: 'error'})
				return false;
			}
			if(!Object.values(jobApplicantStatus).includes(status)) {
				enqueueSnackbar(`Status has to be "Fresh" | "Closed" | "Deferred" for ${caseId}`, {variant: 'warning'})
				return false;
			}
			if(!(remarks && remarks.length > 3)) {
				enqueueSnackbar(`Min 3 characters needed for ${caseId} remarks`, {variant: 'warning'})
				return false;
			}
			return true
		})
	}

	const createDataGrid = (list: jobApplicationRes[]) => {
		function createData(jobApplication: jobApplicationRes, i: number) {
			const { cvUuid, fullJD, emailId, postedOn, jobId, mobileNo, name, query, remarkedon, remarks, status, caseId, department, jobName } = jobApplication
			return {
				cvUuid, fullJD, emailId, postedOn, jobId, mobileNo, name, query, remarkedon, remarks, status, department, id: i + 1, caseId, jobName
			}
		}

		const rows = list.map((cur, i) => createData(cur, i))

		setGridRows(rows.map((row, index) => {
			const { cvUuid, fullJD, emailId, postedOn, jobId, mobileNo, name, query, remarkedon, remarks, status, caseId, department, id, jobName } = row
			return ({
				cvUuid, fullJD, emailId, postedOn, jobId, mobileNo, name, query, remarkedon, remarks, status, caseId, department, id, jobName
			})
		}))

		setGridColumns([
			{ field: 'id', headerName: 'S.No', flex: 0.5 },
			{ field: 'name', headerName: 'Name', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'mobileNo', headerName: 'Mobile', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'emailId', headerName: 'Email', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'query', headerName: 'Query', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'status', headerName: 'Status', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'postedOn', headerName: 'Posted On', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'remarks', headerName: 'Remarks', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'remarkedon', headerName: 'Remark Date', flex: 1, width: 100, renderCell: datagridCellExpand },
			{
				field: 'action', headerName: 'Action', flex: 1,
				disableClickEventBubbling: true,
				renderCell: (params: GridCellParams) => {
					const selectedRow = {
						id: params.getValue("id") as number,
						emailId: params.getValue("emailId") as string,
						name: params.getValue("name") as string
					}

					const selectedRowDetails = rows.find((row, index) => {
						return (row.emailId === selectedRow.emailId && row.name === selectedRow.name && index === (selectedRow.id - 1))
					})

					const buttonSet = buttonData.map((button, index) => {
						return (
							<Tooltip key={index} title={button.title}>
								<IconButton
									onClick={() => {
										button.action(selectedRowDetails as jobApplicationRes);
									}}
									size="small"
								>
									{button.icon}
								</IconButton>
							</Tooltip>
						);
					})

					return <div>{buttonSet}</div>;
				}
			}
		])
	}

	const viewUser = (row: jobApplicationRes) => {
		setSelectedApplicant(row)
		setOpenModal(true)
	}

	const buttonData = [
		{
			title: 'view applicant',
			action: viewUser,
			icon: <Visibility />
		},
	];

	const downloadJobapplications = async () => {
		if (!status) {
			enqueueSnackbar('Please select status', { variant: 'warning' });
			return;
		}
		if (endDate && fromDate) {
			if (compareAsc(fromDate, endDate) == 1) {
				enqueueSnackbar("End date should be after start date", { variant: "error" })
				return;
			}
			await getJobsSpreadsheet({
				dataType: 'file',
				fromDate: new Date(fromDate.setHours(0,0,0,0)),
				endDate: new Date(endDate.setHours(23,59,0,0)),
				status,
			}).catch(err => onApiError(err, enqueueSnackbar, setRedirectTo))
		}
	}

	const showTable = (val: jobApplicationRes[]) => {
		createDataGrid(val)
		setShowDataGrid(true)
	}

	const viewJobapplications = async () => {
		if (!status) {
			enqueueSnackbar('Please select status', { variant: 'warning' });
			return;
		}
		if (endDate && fromDate) {
			if (compareAsc(fromDate, endDate) == 1) {
				enqueueSnackbar("End date should be after start date", { variant: "error" })
				return;
			}
			await getJobsSpreadsheet({
				dataType: 'table',
				fromDate: new Date(fromDate.setHours(0,0,0,0)),
				endDate: new Date(endDate.setHours(23,59,0,0)),
				status,
			}).then(val => showTable(val))
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				{selectedApplicant && <JobApplicantModal
					openModal={openModal}
					onClose={() => {
						setOpenModal(false)
						setSelectedApplicant(undefined)
						viewJobapplications()
					}}
					// saveUser={savePersonalInformation}
					applicant={selectedApplicant}
				/>}
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Search Customers</Typography>
							</Grid> */}

							<Card className={styles.card} variant='outlined' style={{ width: '100%' }}>
								<CardHeader title='Career Enquiry Details' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>
									<Grid container>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>From Date</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Datepickers selectedDate={fromDate} handleDateChange={(date) => setFromDate(date)}
												/>
											</FormControl>
											{/* {dateError.dob &&
												<Typography className={classes.dateError}>{dateError.dob}</Typography>
											} */}
										</Grid>
									</Grid>

									<Grid container>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>End Date</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Datepickers selectedDate={endDate} handleDateChange={(date) => setEndDate(date)}
												/>
											</FormControl>
											{/* {dateError.dob &&
												<Typography className={classes.dateError}>{dateError.dob}</Typography>
											} */}
										</Grid>
									</Grid>


									<Grid container >
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Job post status</Box>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Select>
													{/* <MenuItem value="Select">Select</MenuItem> */}
													{['All', ...Object.values(jobPostingStatus)].map((cur, index) => (
														<MenuItem value={cur} key={index} onClick={() => setStatus(cur)}>
															{cur}
														</MenuItem>
													))}
												</Select>
												{/* {errors.role && (
													<FormHelperText error>
														{errors.role.message}
													</FormHelperText>
												)} */}
											</FormControl>
										</Grid>
									</Grid>

									<br />

									<Grid container spacing={3} justify="center" alignItems="center">
										<Grid item>
											<Button variant="contained" color="primary" size="medium"
												onClick={viewJobapplications}
											>
												View
											</Button>
										</Grid>

										<Grid item>
											<Button variant="contained" color="primary" size="medium"
												onClick={downloadJobapplications}
											>
												Download XLS
											</Button>
										</Grid>

										<Grid item>
											<input
												accept=".xls, .xlsx"
												style={{ display: 'none' }}
												id="contained-button-file"
												type="file"
												onChange={(e) => {
													readExcel(e.target.files && e.target.files[0]);
													e.target.value = '';
												}}
											/>
											<label htmlFor="contained-button-file">
												<Button
													component="span" variant="contained" color="primary" size="medium"
												>
													Bulk Update Applicants
												</Button>
											</label>
										</Grid>
									</Grid>

								</CardContent>
							</Card>

							{showDataGrid && <Datagrids gridRows={gridRows} gridColumns={gridColumns} />}

						</Grid>
					</Container>
				</div>
			</MiniDrawer>
		</>
	);
}

export default JobPosting;