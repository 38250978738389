import axios from "axios"
import { StandardBoardTypesInterface } from "../contracts/StandardBoardTypesInterface"
import { StandardClassTypesInterface } from "../contracts/StandardClassTypesInterface"
import { StandardSubjectTypesInterface } from "../contracts/StandardSubjectTypesInterface"
import { BOARD_RESORCE, CLASS_RESORCE, SUBJECT_RESORCE } from "./routes"

export const fetchBoardCommonTypes = async (boardName?: string) => {
    const response = await axios.get(BOARD_RESORCE, {params: {boardName:boardName}})
    return response.data.data
}

export const fetchClassCommonTypes = async (className?: string) => {
    const response = await axios.get(CLASS_RESORCE, {params: {className:className}})
    return response.data.data
}

export const fetchSubjectCommonTypes = async (subjectName?: string) => {
    const response = await axios.get(SUBJECT_RESORCE, {params: {subjectName:subjectName}})
    return response.data.data
}

export const saveBoardCommonTypes = async (boardName: string, boardId: number, boardDesc: string, commonNames: string[]) => {
    const response = await axios.post(BOARD_RESORCE, {boardName, boardId, boardDesc, commonNames})
    return response.data
}

export const saveClassCommonTypes = async (className: string, classId: number, classDesc: string, commonNames: string[]) => {
    const response = await axios.post(CLASS_RESORCE, {className, classId, classDesc, commonNames})
    return response.data
}

export const saveSubjectCommonTypes = async (subjectName: string, subjectId: number, subjectDesc: string, commonNames: string[]) => {
    const response = await axios.post(SUBJECT_RESORCE, {subjectName, subjectId, subjectDesc, commonNames})
    return response.data
}

export const deleteBoardCommonTypes = async (boardName: string) => {
    const response = await axios.delete(BOARD_RESORCE, {params: {boardName:boardName}})
    return response.data.data
}

export const deleteClassCommonTypes = async (className: string) => {
    const response = await axios.delete(CLASS_RESORCE, {params: {className:className}})
    return response.data.data
}

export const deleteSubjectCommonTypes = async (subjectName: string) => {
    const response = await axios.delete(SUBJECT_RESORCE, {params: {subjectName:subjectName}})
    return response.data.data
}