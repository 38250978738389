import { Button, darken, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Container, Box, Grid, Typography, useMediaQuery, Input, InputAdornment } from '@material-ui/core';
import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { useDebounce } from '../../common/Hooks/useDebounce';
import { fontOptions } from '../../common/theme';
import { FetchQB } from '../contracts/qb_interface';
import { deleteQuestionBank, fetchQuestionBank } from '../helpers/api';
import {
    Delete as DeleteIcon,
    Edit as EditIcon
  } from '@material-ui/icons';
  import {
    Search as SearchIcon,
    Clear as ClearIcon,
  } from '@material-ui/icons';
import { orderBy } from 'lodash';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import Datagrids, { datagridCellExpand } from '../../dashboard/components/dataGrid';
import ConfirmationModal from '../../common/components/confirmation_modal';
import MiniDrawer from '../../common/components/SideDrawer';

const useStyles = makeStyles({
    title: {
        fontSize: fontOptions.size.large
      },
      addAssi: {
        '& button': {
          color: '#4C8BF5',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: darken('#FFFFFF', 0.1),
          }
        },
        paddingTop: '12px'
      },
      addAssi2: {
        '& button': {
          padding: '15px 20px',
        },
      },
      drafts: {
        '& button': {
          color: '#FFFFFF',
          backgroundColor: '#F9BD33',
          '&:hover': {
            backgroundColor: darken('#F9BD33', 0.1),
          }
        }
      },
})

function createData(
    boardname: string,
    classname: string,
    lastUpdated: Date,
    noOfQues: number,
    questionBankFriendlyName: string,
    subjectname: string,
    description: string,
    availableForMockTest: boolean
  ): FetchQB {
    return { boardname, classname, lastUpdated, noOfQues, questionBankFriendlyName, subjectname, description, availableForMockTest };
  }

interface Props extends RouteComponentProps {}

const EdumacQuestionBank: React.FC<Props> = ({location, history}) => {
    const classes = useStyles();

    const [qb, setQb] = React.useState<FetchQB[]>([])
    const [filteredQb, setFilteredQb] = React.useState<FetchQB[]>([])
    const [focused, setFocused] = React.useState(false);
    const [searchText, setSearchText] = React.useState<string>('');

    const [redirectTo, setRedirectTo] = React.useState('');
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
    const [byoqTBD, setByoqTBD] = React.useState<FetchQB | undefined>(undefined)
    const [isapiLoad, setisapiLoad] = React.useState(false)
    const [deleting, setDeleting] = React.useState(false)

    const debouncedDelete = useDebounce((byoqTBD: FetchQB | undefined) => deleteQB(byoqTBD), 1500)

    const {enqueueSnackbar} = useSnackbar()

    React.useEffect(() => {
        onUseEffect()
      }, []);
    
      React.useEffect(() => {
        let filtered = qb
        if(searchText !== '') {
          filtered = filtered.filter(fil => 
            fil.questionBankFriendlyName.toLowerCase().includes(searchText.toLowerCase()) ||
            fil.boardname.toLowerCase().includes(searchText.toLowerCase()) ||
            fil.classname.toLowerCase().includes(searchText.toLowerCase()) ||
            fil.subjectname.toLowerCase().includes(searchText.toLowerCase()) ||
            String(fil.noOfQues).includes(searchText) ||
            new Date(fil.lastUpdated).toLocaleString().includes(searchText)
          )
        }
        setFilteredQb(filtered)
      }, [searchText]);
    
      const onUseEffect = async () => {
        try {
          setisapiLoad(true)
          const questionBank = await fetchQuestionBank()
          if(questionBank.length > 0) {
            setQb(orderBy(questionBank, ['lastUpdated'], ['asc']))
            setFilteredQb(orderBy(questionBank, ['lastUpdated'], ['asc']))
          } else {
            setQb([])
            setFilteredQb([])
          }
          setisapiLoad(false)
        } catch (error) {
          setisapiLoad(false)
          setRedirectTo('/login');
            localStorage.clear();
        }
      }
    
      const rows = filteredQb.map((filqb) => {
        return(
          createData(
            filqb.boardname,
            filqb.classname,
            filqb.lastUpdated,
            filqb.noOfQues,
            filqb.questionBankFriendlyName,
            filqb.subjectname,
            filqb.description,
            filqb.availableForMockTest
          )
        )
      });
    
      const editQB = (data: FetchQB | undefined) => {
        if(data && data.questionBankFriendlyName) {
          history.push(`/edumaticaQB/create?qbName=${data.questionBankFriendlyName}`)
        }
      }
    
      const initDelete = (data: FetchQB | undefined) => {
        console.log(data)
        if(!data) return;
        setByoqTBD(data)
        setOpenConfirmationModal(true)
      }
    
      const deleteQB = async (byoqTBDp: FetchQB | undefined) => {
        if(!byoqTBDp) return;
        try{
          setDeleting(true)
          await deleteQuestionBank(byoqTBDp.questionBankFriendlyName)
          setOpenConfirmationModal(false)
          enqueueSnackbar('Successfully deleted', {variant: 'success'})
          setByoqTBD(undefined)
          onUseEffect()
          setDeleting(false)
          setOpenConfirmationModal(false);
        } catch (error) {
            setRedirectTo('/login');
        }
      }
    
      const buttonData = [
        {
          title: 'Edit',
          action: editQB,
          color: '#4C8BF5',
          icon: <EditIcon />
        },
        {
          title: 'Delete',
          action: initDelete,
          color: '#4C8BF5',
          icon: <DeleteIcon />
        }
      ];
    
      const gridColumns: GridColDef[] = [
        { field: 'id', headerName: 'Sl No.', flex: 0.7 },
        { field: 'name', headerName: 'Name', flex: 1, renderCell: datagridCellExpand },
        { field: 'noq', headerName: 'Number Of Question', flex: 1, renderCell: datagridCellExpand },
        { field: 'board', headerName: 'Board', flex: 1, renderCell: datagridCellExpand },
        { field: 'class', headerName: 'Class', flex: 1, renderCell: datagridCellExpand },
        { field: 'subject', headerName: 'Subject', flex: 1, renderCell: datagridCellExpand },
        { field: 'luo', headerName: 'Last Updated On', flex: 1, renderCell: datagridCellExpand },
        { field: 'action', headerName: 'Action', flex: 1,
          disableClickEventBubbling: true,
          renderCell: (params: GridCellParams) => {
            const selectedRow = {
              id: params.getValue("id") as number,
              name: params.getValue("name") as string,
              board: params.getValue("board") as Date
            }
    
            const selectedRowDetails = rows.find((row, index) => {
              return (row.questionBankFriendlyName === selectedRow.name && index === (selectedRow.id - 1))
            })
    
            const buttonSet = buttonData.map((button, index) => {
              return (
                <Tooltip key={index} title={button.title}>
                  <IconButton
                    onClick={() => {
                      button.action(selectedRowDetails);
                    }}
                    size="small"
                  >
                    {button.icon}
                  </IconButton>
                </Tooltip>
              );
            })
      
            return <div>{buttonSet}</div>;
          }
        }
      ];
    
      const gridRows = rows.map((row, index) => {
        return ({
          id: (index + 1),
          name: row.questionBankFriendlyName,
          noq: String(row.noOfQues),
          board: row.boardname,
          class: row.classname,
          subject: row.subjectname,
          luo: new Date(row.lastUpdated).toLocaleString("en-GB"),
        })
      })
    
      if (redirectTo.length > 0) {
        return <Redirect to={redirectTo} />;
      }
    
      if(isapiLoad) {
        return <div>
            Loading
        </div>
      }
    

    return (
        <div>
        <MiniDrawer>
           <Container maxWidth="lg" style={{padding: '30px 2.5%'}}>
            <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="14px"
            color='#fff'
            >
            <Grid item container>
                <Grid item sm={8}>
                <Box style={{height: '100%'}}>
                    <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                    >
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                        Question Bank
                        </Typography>
                        <Typography>
                        Create Edumatica Question Bank | Import Edumatica Question Bank 
                        </Typography>
                        {(qb.length > 0) &&
                        <Box className={classes.addAssi}>
                            <Button variant="contained" disableElevation onClick={() => history.push('/edumaticaQB/create')}>
                            Add Question Bank
                            </Button>
                            <Button style={{marginLeft: '10px'}} variant="outlined" disableElevation onClick={() => history.push('/edumaticaQB/bulk')}>
                            Import Question
                            </Button>
                        </Box>
                        }
                    </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            </Box>

            <Box
            bgcolor="#ffffff"
            borderRadius="14px"
            padding="25px"
            marginTop='25px'
            >
            {(qb.length > 0) &&
                <Grid container>
                <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
                    <Typography style={{fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, color: '#3D3D3D'}}>
                    All Question Bank
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{marginBottom: '10px'}}>
                    <Box display="flex" flexDirection="row-reverse">
                    <Box p={1}>
                        <Input
                        name="search"
                        inputProps={{ inputMode: 'search' }}
                        placeholder="Search"
                        onFocus={() => setFocused(true)} 
                        onBlur={() => setFocused(false)}
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        endAdornment={
                            searchText.trim() !== '' ? (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={() => setSearchText('')}>
                                <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                            ) : (
                            <InputAdornment position="end">
                                <IconButton disabled size="small">
                                <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                            )
                        }
                        />
                        {(searchText.trim() !== '') &&
                        <Typography style={{marginTop: '5px', fontSize: fontOptions.size.small, color: '#666666'}}>
                            Filtered Table using Keyword '{searchText}'
                        </Typography>
                        }
                    </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} style={{marginTop: '15px'}}>
                    <Datagrids gridRows={gridRows} gridColumns={gridColumns} disableCheckbox={true} />
                </Grid>
                </Grid>
            }

            {(qb.length === 0) &&
                <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                    <Typography style={{marginTop: '15px', marginBottom: '40px', color: '#3D3D3D', fontSize: fontOptions.size.large, fontWeight: fontOptions.weight.bold}}>Create Edumatica Question Banks</Typography>
                    <ul style={{color: '#3D3D3D', fontSize: fontOptions.size.small, fontWeight: fontOptions.weight.normal}}>
                        <li>You can create as many question as you want to.</li>
                        <li style={{marginTop: '25px'}}>Here you can create all type of question for any standard</li>
                        <li style={{marginTop: '25px'}}>Enjoy limitless chances to edit questions</li>
                        <li style={{marginTop: '25px'}}>An interface where you can add any kind of numerical questions also</li>
                    </ul>
                </Grid>
                <Grid item xs={12} md={5} style={{textAlign: 'center'}}>
                    {/* <img src={ByoqCover} alt="Cover" style={{height: '350px'}}/> */}
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.addAssi2}>
                    <Button variant="outlined" color="primary" disableElevation onClick={() => history.push('/edumaticaQB/bulk')}>
                        Import Question
                    </Button>
                    <Button style={{marginLeft: '20px'}} color="primary" variant="contained" disableElevation onClick={() => history.push('/edumaticaQB/create')}>
                        Add Question Bank
                    </Button> 
                    </Box>
                </Grid>
                </Grid>
            }

            </Box>
        </Container>
        <ConfirmationModal
            header="Delete Question Bank"
            helperText="Are you sure you want to delete?"
            openModal={openConfirmationModal}
            onClose={() => {setOpenConfirmationModal(false)}}
            handleDelete={() => debouncedDelete(byoqTBD)}
            deleting={deleting}
        /> 
        </MiniDrawer>
        </div>
    )
}

export default EdumacQuestionBank
