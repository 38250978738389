import React, { FunctionComponent } from 'react';
import { TextField, TextFieldProps, OutlinedInputProps } from '@material-ui/core';
import {
	createStyles,
	fade,
	Theme,
	withStyles,
	makeStyles,
} from '@material-ui/core/styles';

function CustomTextField(props: TextFieldProps) {

	const useStylesReddit = makeStyles((theme: Theme) =>
		createStyles({
			root: {
				border: '1px solid #e2e2e1',
				overflow: 'hidden',
				borderRadius: 4,
				backgroundColor: '#fcfcfb',
				transition: theme.transitions.create(['border-color', 'box-shadow']),
				'&:hover': {
					backgroundColor: '#fff',
				},
				'&$focused': {
					backgroundColor: '#fff',
					boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
					borderColor: theme.palette.primary.main,
				},
			},
			focused: {},
		}),
	);

	const classes = useStylesReddit();

	return (
		<TextField
			InputProps={{ classes, disableUnderline: true } as Partial<OutlinedInputProps>}
			// variant="filled"
			{...props}
		/>
	);
}

export default CustomTextField;