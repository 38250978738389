import React, { useState, useEffect, FunctionComponent, } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
	Box, Container, Grid, Button, Input, MenuItem, Typography, FormHelperText, CssBaseline, Card, CardContent, CardHeader
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';

import MiniDrawer from "../../../common/components/SideDrawer"
import { editAdminUser, getRoles } from '../../../../api/dashboard';
import useStyles from './styles';
import { Admin, Role, } from '../../../common/contracts/dashboard';
import { RootState } from '../../../redux/store';
import { connect, useDispatch } from 'react-redux';
import { setAdminsList, } from '../../../redux/actions/adminActions';
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../../utilities/constants';

interface Props extends RouteComponentProps {
	admin: Admin,
	adminsList: Admin[]
}

interface FormData {
	firstName: string,
	lastName: string,
	emailId: string,
	mobileNumber: string,
	role: string
}

const AssignRole: FunctionComponent<Props> = ({ admin, adminsList }) => {

	const ValidationSchema = yup.object().shape({
		role: yup.string()
			// .notOneOf([ admin.adminRole.name])
			.required('Role is required')
	});
	const { errors, setError, clearError, handleSubmit, register, reset, control, setValue } = useForm<FormData>({
		mode: 'onBlur',
		validationSchema: ValidationSchema
	});

	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const [roles, setRoles] = useState<Role[]>([]);
	const styles = useStyles();
	const dispatch = useDispatch()

	useEffect(() => {
		getLoggedInUserData();
	}, []);

	const getUserRoles = async () => {
		setLoading(true);
		try {
			const roles = await getRoles();
			setRoles(roles);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}


	const getLoggedInUserData = async () => {
		// setLoading(true);
		// try {
		// 	const adminUserData = await getAdminData();
		// 	dispatch(setLoginUserData(JSON.stringify(adminUserData)));
		// 	setLoading(false);
		// 	if (adminUserData.isItDefaultPassword) {
		// 		localStorage.setItem('isItDefaultPassword', JSON.stringify(adminUserData.isItDefaultPassword));
		// 		setRedirectTo('/set-password');
		// 	}
		// } catch (error) {
		// 	setLoading(false);
		// 	enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
		// }
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		const requiredPermissions = [MASTER_PERMISSIONS.editAdmin, MASTER_PERMISSIONS.getRoles]
		var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		} else {
			getUserRoles();
		}
	}

	const handleAssignRole = async (data: FormData) => {
		var { role, emailId } = data;
		if (admin.adminRole && admin.adminRole.name == role) {
			setError('role', 'Invalid data', 'You currently have this role');
			return;
		}

		setLoading(true);
		try {
			var obj = {
				oldEmailId: admin.emailId,
				role: role
			}
			await editAdminUser(obj);
			setLoading(false);
			let clone = JSON.parse(JSON.stringify(adminsList));
			let index = clone.findIndex((item: any) => item._id == admin._id);
			if (index >= 0) {
				clone[index].adminRole.name = role;
			}
			dispatch(setAdminsList(clone));
			enqueueSnackbar('Assigned successfully', { variant: 'success' });
			setRedirectTo('/search-admin');
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Manage Users</Typography>
							</Grid> */}
							<Grid item className={styles.header}>
								<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
							</Grid>
							<Card className={styles.card} variant='outlined'>
								<CardHeader title='Assign Role' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>

									<form onSubmit={handleSubmit(handleAssignRole)} >
										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>First Name</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="firstName"
													placeholder="Enter First Name"
													inputProps={{ maxLength: 50 }}
													className={styles.margin}
													defaultValue={admin ? admin.firstName : ''}
													disabled={true}
												/>
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Last Name</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="lastName"
													placeholder="Enter Last Name"
													inputProps={{ maxLength: 50 }}
													className={styles.margin}
													defaultValue={admin ? admin.lastName : ''}
													disabled={true}
												/>
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Email Id</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="emailId"
													placeholder="Enter Email Id"
													inputProps={{ maxLength: 50 }}
													className={styles.margin}
													defaultValue={admin ? admin.emailId : ''}
													disabled={true}
												/>
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Mobile Number</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="mobileNumber"
													placeholder="Enter Mobile Number"
													inputProps={{ inputMode: 'numeric', maxLength: 10 }}
													className={styles.margin}
													defaultValue={admin ? admin.mobileNo : ''}
													disabled={true}
												/>
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Role</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<FormControl fullWidth margin="normal">
													{<Controller
														as={
															<Select >
																{/* <MenuItem value="Select">Select</MenuItem> */}
																{roles.length > 0 && roles.map((item, index) => (
																	<MenuItem value={item.name} key={index}	>
																		{item.name}
																	</MenuItem>
																))}
															</Select>
														}
														name="role"
														inputRef={register}
														control={control}
														setValue={setValue}
														defaultValue={admin && admin.adminRole ? admin.adminRole.name : ''}
														className={styles.margin}
													/>}
													{errors.role && (
														<FormHelperText error>
															{errors.role.message}
														</FormHelperText>
													)}
												</FormControl>
											</Grid>
										</Grid>

										<Grid container justify='center' className={styles.submitBtn}>
											<Grid item xs={12} md={6} >
												<Button
													disableElevation
													variant="contained"
													color="primary"
													size="large"
													type="submit"
													disabled={loading}
												>
													{'Assign & save'}
												</Button>
											</Grid>
										</Grid>

									</form>
								</CardContent>
							</Card>

						</Grid>
					</Container>
				</div>
			</MiniDrawer>
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	admin: state.adminReducer.admin as Admin,
	adminsList: state.adminReducer.adminsList as Admin[],
});

export default connect(mapStateToProps)(AssignRole);