import React, { FunctionComponent, useState, useEffect } from 'react'
import {
  Box,
  FormControl,
  Grid,
  Input,
  Typography,
  Button,
  makeStyles,
  darken,
  CssBaseline,
  Container
} from '@material-ui/core'
import { useSnackbar } from "notistack"

import { fontOptions } from "../../../../theme";
import MiniDrawer from '../../../common/components/SideDrawer';
import { MASTER_PERMISSIONS } from "../../../../utilities/constants";
import { getLoggedInUserData} from '../../../common/helpers';
import { EMAIL_PATTERN } from '../../../common/validations/patterns';
import { sendEmail } from '../../api';

const useStyles = makeStyles({
	typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121',
	  },
      label: {
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.small,
      },
});

const SendEmail: FunctionComponent = () => {
    const classes = useStyles()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [text, setText] = useState('')

    const [redirectTo, setRedirectTo] = useState<string>('')
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        getLoggedInUserData(setRedirectTo, [MASTER_PERMISSIONS.sendEmail], enqueueSnackbar)
    }, [])

    const checkValidations = () => {
        if(name.length < 3) {
            enqueueSnackbar('Min 3 chars required for name', {variant: 'warning'})
            return false
        }
        if(!email.match(EMAIL_PATTERN)) {
            enqueueSnackbar('Invalid Email', {variant: 'warning'})
            return false
        }
        if(subject.length < 3) {
            enqueueSnackbar('Min 3 chars required for subject', {variant: 'warning'})
            return false
        }
        if(text.length < 3) {
            enqueueSnackbar('Min 3 chars required for text', {variant: 'warning'})
            return false
        }
        return true
    }

    const handleSendEmail = async () => {
        if(!checkValidations()) return;
        await sendEmail({
            mail: [{
                name,
                email
            }],
            subject,
            text
        }).then(() => {
            enqueueSnackbar('Mail sent successfully', {variant: 'success'})
            setName('')
            setEmail('')
            setSubject('')
            setText('')
        }).catch(() => {
            enqueueSnackbar('Mail not sent', {variant: 'warning'})
        })
    }

    const renderInput = (key: string, value: any, setter: any, maxLength?: number, multiline?: boolean) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={classes.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Input
                placeholder={key}
                value={value}
                inputProps={{ maxLength: maxLength ? maxLength : 100 }}
                multiline={multiline}
                onChange={(e) => setter(e.target.value)}
                />
            </FormControl>
            </Grid>
        </Grid>
    )

    const renderButton = (name: string, cb: any) => (
        <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item>
                <Button variant="contained" color="primary" size="medium"
                    onClick={cb}
                >
                    {name}
                </Button>
            </Grid>
        </Grid>
    )

    return (
        <>
        <CssBaseline />
        <MiniDrawer>
        <Container maxWidth="lg">
            <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="5px"
            color='#fff'
            >
            <Grid item container>
                <Grid item sm={8}>
                <Box style={{height: '100%'}}>
                    <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                    >
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            Send Email
                        </Typography>
                    </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            </Box>

            <Box
				bgcolor="#ffffff"
				borderRadius="5px"
				padding="12px 25px"
				marginTop='25px'
			>
                <Grid container justify='center'>
                    <Grid item xs={12} lg={6} style={{paddingTop: '5px', marginBottom: '8px'}}>
                        {renderInput('Name', name, setName, 50)}
                        {renderInput('Email ID', email, setEmail, 50)}
                        {renderInput('Subject', subject, setSubject, 100)}
                        {renderInput('Message', text, setText, 5000, true)}
                        {renderButton('Send Email', handleSendEmail)}
                    </Grid>
                </Grid>
            </Box>  
        </Container>
        </MiniDrawer>
        </>
    )
}

export default SendEmail