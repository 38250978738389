import { createAction } from '@reduxjs/toolkit';

export const setAuthToken = createAction('SET_AUTH_TOKEN', (accessToken: string) => {
	localStorage.setItem('accessToken', accessToken);
	return { payload: accessToken };
});

export const setRefreshToken = createAction('SET_REFRESH_TOKEN', (refreshToken: string) => {
	localStorage.setItem('refreshToken', refreshToken);
	return { payload: refreshToken };
});

export const setForgotPasswordEmailId = createAction('SET_FORGOT_PASSWORD_EMAIL_ID', (email: string) => {
	localStorage.setItem('forgotPasswordEmailId', email);
	return { payload: email };
});