import React, { useState, useEffect, FunctionComponent, } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
	Box, Container, Grid, Button, Input, MenuItem, CssBaseline, Card, CardContent, CardHeader, IconButton, Typography,
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
import { connect, } from 'react-redux';

import MiniDrawer from "../../../common/components/SideDrawer"
import useStyles from '../manageUsers/styles';
import { CustomUser, PackagePlan, } from '../../../common/contracts/dashboard';
import { MASTER_PERMISSIONS, PLANS, USER_ROLES, viewPackageInitData, LIVE_CLASS_TYPES } from '../../../../utilities/constants';
import Datepickers from '../../../dashboard/components/datepickers';
import { getUserPackage, updatePackage, postPackage, getPackageApprovalByIds, } from '../../../../api/dashboard';
import { RootState } from '../../../redux/store';
import { format } from 'date-fns';
import { ArrowBack } from '@material-ui/icons';

interface Props extends RouteComponentProps {
	user: CustomUser
}
interface ChipData {
	key: number;
	label: string;
}

const ViewPackage: FunctionComponent<Props> = ({ user, history }) => {

	var initData = viewPackageInitData;

	const { enqueueSnackbar } = useSnackbar()
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [userPackage, setUserPackage] = useState<any>(initData);
	const [disabled, setIsDisabled] = useState<boolean>(true);
	const [filteredPaymentPlans, setFilteredPaymentPlans] = useState<PackagePlan[]>(initData.paymentPlans);
	const [isNewPackageCreated, setIsNewPackageCreated] = useState<boolean>(false);
	const [reason, setReason] = useState<string>('');
	const [currentPlanId, setCurrentPlanId] = useState<string>('');
	const styles = useStyles();

	console.log(filteredPaymentPlans);

	var allowedPermissions: string[] = [];
	var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
	var loginUserType = '';
	if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
		allowedPermissions = loginUserData.adminRole.permissions;
		loginUserType = loginUserData.adminRole.name;
	}

	useEffect(() => {
		const requiredPermissions: string[] = [MASTER_PERMISSIONS.packageapproval]
		var hasEligibility = (loginUserType == USER_ROLES.super) || requiredPermissions.every((perm) => allowedPermissions.includes(perm));
		// var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.edumacpackage);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		} else {
			checkIfThereIsAnUpdatedPackage();
		}
	}, []);

	const checkIfThereIsAnUpdatedPackage = async () => {
		if (user._id) {
			try {
				// @ts-ignore
				const packages = await getPackageApprovalByIds(user.ownerId, user._id)
				if (packages.length == 0) {
					getPackage();
				} else {
					if (packages && packages.length >= 0) {
						let pkg = packages[packages.length - 1];
						setIsNewPackageCreated(true);
						setUserPackage(pkg);
						setCurrentPlanId(pkg.planId)
						updateFilteredPaymentPlans(pkg)
					} else {
						enqueueSnackbar('Something went wrong', { variant: 'warning' });
					}
				}
			} catch (error) {
				console.log(error);
				enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
				if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
					setRedirectTo('/login');
				}
			}
		}
	}

	const getPackage = async () => {
		if (user && user._id) {
			try {
				var ownerId = user.ownerId
				// @ts-ignore
				const pkg = await getUserPackage(ownerId);
				setUserPackage(pkg);
				setCurrentPlanId(pkg.planId)
				updateFilteredPaymentPlans(pkg)
			} catch (error) {
				console.log(error);
				enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
				if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
					setRedirectTo('/login');
				}
			}
		}
	}

	const updateFilteredPaymentPlans = (pkg: any) => {
		filteredPaymentPlans.forEach((item, index) => {
			pkg.paymentPlans.forEach((plan: PackagePlan) => {
				if (item.paymentcycle == plan.paymentcycle) {
					filteredPaymentPlans[index] = plan;
					return;
				}
			});
		});

		console.log(filteredPaymentPlans, pkg.paymentPlans);
		setFilteredPaymentPlans([...filteredPaymentPlans])
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}


	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (userPackage) {
			switch (e.target.name) {
				case 'studentCount':
					userPackage.studentCount = Number(e.target.value)
					break;
				case 'tutorCount':
					userPackage.tutorCount = Number(e.target.value)
					break;
				case 'adminCount':
					userPackage.adminCount = Number(e.target.value)
					break;
				case 'batchCount':
					userPackage.batchCount = Number(e.target.value)
					break;
				case 'courseCount':
					userPackage.courseCount = Number(e.target.value)
					break;
				case 'sessionCount':
					userPackage.sessionCount = Number(e.target.value)
					break;
				case 'customChapter':
					userPackage.customChapter = Number(e.target.value)
					break;
				case 'liveClassQuota':
					userPackage.liveClassQuota = Number(e.target.value)
					break;
				case 'recordingQuota':
					userPackage.recordingQuota = Number(e.target.value)
					break;
				case 'perstudentcost':
					userPackage.perstudentcost = Number(e.target.value)
					break;
				case 'convenienceFee':
					userPackage.convenienceFee = Number(e.target.value)
					break;
				case 'graceperiod':
					userPackage.graceperiod = Number(e.target.value)
					break;
				case 'contentSize':
					userPackage.contentSize = Number(e.target.value)
					break;
				case 'MONTHLY':
					filteredPaymentPlans[0].totalprice = Number(e.target.value)
					break;
				case 'QUARTERLY':
					filteredPaymentPlans[1].totalprice = Number(e.target.value)
					break;
				case 'HALFYEARLY':
					filteredPaymentPlans[2].totalprice = Number(e.target.value)
					break;
				case 'LUMPSUM':
					filteredPaymentPlans[3].totalprice = Number(e.target.value)
					break;
				// case 'discount':
				// 	let value = e.target.value ? Number(e.target.value) : 0;
				// 	filteredPaymentPlans[0].discount = value
				// 	filteredPaymentPlans[1].discount = value
				// 	filteredPaymentPlans[2].discount = value
				// 	filteredPaymentPlans[3].discount = value
				// 	break;
				case 'additionalCharge':
					userPackage.additionalCharge[0].totalprice = Number(e.target.value);
					break;
				default:
					break;
			}
			setUserPackage({ ...userPackage });
		}
	}


	const handleApproveAsMaker = () => {
		handleSavePackage('makerApproved');
	}
	const handleApproveAsChecker = () => {
		handleSavePackage('checkerApproved');
	}
	const handleRejectAsMaker = () => {
		handleSavePackage('makerRejected');
	}
	const handleRejectAsChecker = () => {
		handleSavePackage('checkerRejected');
	}

	const hasValidationErrors = () => {

		// if (userPackage.studentCount !== undefined && userPackage.studentCount !== null) {
		// 	enqueueSnackbar('Please enter student count', { variant: 'warning' });
		// 	return false;
		// }
		// if (userPackage.tutorCount !== undefined && userPackage.tutorCount !== null) {
		// 	enqueueSnackbar('Please enter tutor count', { variant: 'warning' });
		// 	return false;
		// }
		// if (userPackage.adminCount !== undefined && userPackage.adminCount !== null) {
		// 	enqueueSnackbar('Please enter admin count', { variant: 'warning' });
		// 	return false;
		// }
		// if (userPackage.batchCount !== undefined && userPackage.batchCount !== null) {
		// 	enqueueSnackbar('Please enter batch count', { variant: 'warning' });
		// 	return false;
		// }
		// if (userPackage.courseCount !== undefined && userPackage.courseCount !== null) {
		// 	enqueueSnackbar('Please enter course count', { variant: 'warning' });
		// 	return false;
		// }
		// if (userPackage.recordingQuota !== undefined && userPackage.recordingQuota !== null) {
		// 	enqueueSnackbar('Please enter recording hrs/lecture', { variant: 'warning' });
		// 	return false;
		// }
		// if (userPackage.sessionCount !== undefined && userPackage.sessionCount !== null) {
		// 	enqueueSnackbar('Please enter session count', { variant: 'warning' });
		// 	return false;
		// }
		// if (userPackage.customChapter !== undefined && userPackage.customChapter !== null) {
		// 	enqueueSnackbar('Please enter custom chapter count', { variant: 'warning' });
		// 	return false;
		// }
		// if (!(userPackage.paymentPlans.length == 4)) {
		// 	enqueueSnackbar('Please enter all fee amount', { variant: 'warning' });
		// 	return false;
		// }
		// if (userPackage.convenienceFee !== undefined && userPackage.convenienceFee !== null) {
		// 	enqueueSnackbar('Please enter convenience fee', { variant: 'warning' });
		// 	return false;
		// }
		if (!userPackage.graceperiod) {
			enqueueSnackbar('Please enter grace period', { variant: 'warning' });
			return false;
		}
		if (!userPackage.contentSize) {
			enqueueSnackbar('Please enter content space allowed', { variant: 'warning' });
			return false;
		}
		if (!reason) {
			enqueueSnackbar('Please enter reason for approval/rejection', { variant: 'warning' });
			return false;
		}
		return true
	}

	const handleSavePackage = (actionType: string) => {

		if (!hasValidationErrors()) {
			return;
		}

		userPackage.packageStatus = actionType;

		let paymentPlans: PackagePlan[] = filteredPaymentPlans;
		// filteredPaymentPlans.forEach((plan: PackagePlan) => {
		// 	if (plan.totalprice) {
		// 		paymentPlans.push(plan)
		// 	}
		// });


		var obj: any = {
			name: userPackage.name,
			planId: userPackage.planId,
			perstudentcost: userPackage.perstudentcost,
			convenienceFee: userPackage.convenienceFee,
			graceperiod: userPackage.graceperiod,
			recordingQuota: userPackage.recordingQuota,
			courseCount: userPackage.courseCount,
			studentCount: userPackage.studentCount,
			tutorCount: userPackage.tutorCount,
			adminCount: userPackage.adminCount,
			batchCount: userPackage.batchCount,
			sessionCount: userPackage.sessionCount,
			contentSize: userPackage.contentSize,
			// liveClassQuota: userPackage.liveClassQuota,
			// liveClassType: userPackage.liveClassType,
			customChapter: userPackage.customChapter,
			// isActive: true,
			// activeFrom: fromDate,
			// activeTill: endDate,
			ownerId: user.ownerId,
			accountType: userPackage.accountType,
			entityId: user._id,
			packageStatus: userPackage.packageStatus,
			actionReason: reason,
		}
		if (userPackage.renewalDate) {
			obj['renewalDate'] = userPackage.renewalDate;
		}
		if (userPackage.liveClassQuota) {
			obj['liveClassQuota'] = userPackage.liveClassQuota;
		}
		if (userPackage.liveClassType) {
			obj['liveClassType'] = userPackage.liveClassType;
		}

		if (userPackage.planId != currentPlanId) {
			obj = {};
			obj = {
				name: userPackage.name,
				planId: userPackage.planId,
				ownerId: user.ownerId,
				accountType: userPackage.accountType,
				entityId: user._id,
				packageStatus: userPackage.packageStatus,
				actionReason: reason,
			}
		}


		if (userPackage.paymentcycle && userPackage.cost && userPackage.paymentplan) {
			let index = paymentPlans.findIndex(item => item.paymentcycle == userPackage.paymentcycle)
			if (index >= 0) {
				obj['cost'] = paymentPlans[index].totalprice;
				obj['paymentplan'] = paymentPlans[index].paymentplan;
				obj['paymentcycle'] = paymentPlans[index].paymentcycle;
			}
		} else {
			filteredPaymentPlans.forEach((plan: PackagePlan) => {
				if (plan.totalprice) {
					obj['cost'] = plan.totalprice;
					obj['paymentplan'] = plan.paymentplan;
					obj['paymentcycle'] = plan.paymentcycle;
				}
			});
		}  //not required


		if (isNewPackageCreated && userPackage._id) {
			obj['_id'] = userPackage._id
			var pkgObj = {
				packageDetails: obj,
				packagePlans: paymentPlans,
				additionalCharges: userPackage.additionalCharge
			}
			handleUpdatePackage(pkgObj);
		} else {
			var pkgObj = {
				packageDetails: obj,
				packagePlans: paymentPlans,
				additionalCharges: userPackage.additionalCharge
			}
			handleCreatePackage(pkgObj);
		}
		// console.log(pkgObj, 'pkgObj');

	}

	const handleCreatePackage = async (pkgObj: any) => {
		try {
			const data = await postPackage(pkgObj)
			setUserPackage(initData);
			// setRedirectTo('/search-customer')
			enqueueSnackbar('Updated successfully', { variant: 'success' });
			checkIfThereIsAnUpdatedPackage()
		} catch (error) {
			console.log(error);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	const handleUpdatePackage = async (pkgObj: any) => {
		try {
			const data = await updatePackage(pkgObj)
			setUserPackage(initData);
			setRedirectTo('/search-customer')
			enqueueSnackbar('Updated successfully', { variant: 'success' });
		} catch (error) {
			console.log(error);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid item className={styles.header2}>
							<IconButton onClick={() => history.goBack()}>
								<ArrowBack />
							</IconButton>
							<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
						</Grid>
						<Card className={styles.card} variant='outlined' style={{ width: '100%' }}>
							<CardHeader title='View/Edit Package' titleTypographyProps={{ varaint: 'h5' }} />
							<CardContent>

								<Grid container >
									<Grid item xs={12} md={3}>
										<FormControl fullWidth margin="normal">
											<Box className={styles.label}>Package Friendly Name</Box>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={3}>
										<Input
											name="package"
											inputProps={{ maxLength: 25 }}
											className={styles.margin}
											value={userPackage ? userPackage.name : ''}
											disabled
										/>
									</Grid>
								</Grid>

								<Grid container>
									<Grid item xs={12} md={3}>
										<FormControl fullWidth margin="normal">
											<Box className={styles.label}>Start Date</Box>
										</FormControl>
									</Grid>

									<Grid item xs={12} md={3}>
										<Input
											name="package"
											inputProps={{ maxLength: 25 }}
											className={styles.margin}
											value={userPackage && userPackage.createdAt ? format(new Date(userPackage.createdAt), 'dd/MM/yyyy') : 'NA'}
											disabled
										/>
									</Grid>

									<Grid item xs={12} md={3}>
										<FormControl fullWidth margin="normal">
											<Box className={styles.label}>Renewal Date</Box>
										</FormControl>
									</Grid>

									<Grid item xs={12} md={3}>
										<FormControl fullWidth margin="normal">
											<Datepickers selectedDate={userPackage ? userPackage.renewalDate : ''}
												handleDateChange={(date) => {
													// if (!disabled) {
													userPackage.renewalDate = date;
													setUserPackage({ ...userPackage })
													// }
												}}
												// minDate={new Date()}
												disabled={disabled}
											/>
										</FormControl>
									</Grid>

									<Grid item xs={12} md={3}>
										<FormControl fullWidth margin="normal">
											<Box className={styles.label}>Plan Type</Box>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={3}>
										<Select onChange={(e) => {
											userPackage.planId = e.target.value as string
											setUserPackage({ ...userPackage })
										}}
											disabled={disabled}
											value={userPackage.planId}>
											{PLANS.map((item, index) => (
												<MenuItem value={item.value} key={index}	>
													{item.name}
												</MenuItem>
											))}
										</Select>
									</Grid>

									{/* <Grid item xs={6}>
										<Button color='primary' variant='contained'
											onClick={() => setIsDisabled(!disabled)}
										>Change Plan</Button>
									</Grid> */}
								</Grid>

								<Grid container>
									{userPackage.packageStatus &&
										<>
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Status</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={3}>
												<Input
													name="package"
													className={styles.margin}
													value={userPackage.packageStatus}
													disabled
												/>
											</Grid>
										</>
									}
									<Grid item xs={6}>
										<Button color='primary' variant='contained'
											onClick={() => setIsDisabled(!disabled)}
										>Edit</Button>
									</Grid>
								</Grid>

								{
									userPackage.planId == currentPlanId &&
									<>
										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Account Type</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Input
														name="accountType"
														placeholder="Account Type"
														inputProps={{ maxLength: 5 }}
														value={userPackage ? userPackage.accountType : ''}
														disabled={true}
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Total students allowed</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Input
														name="studentCount"
														placeholder="Enter student count"
														value={userPackage ? userPackage.studentCount as number : ''}
														disabled={disabled}
														type="number"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (userPackage){
																userPackage.studentCount = (e.target.value as number)
																setUserPackage({ ...userPackage });
															}
														}}
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Total tutors allowed</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Input
														name="tutorCount"
														placeholder="Enter tutor count"
														value={userPackage ? userPackage.tutorCount as number : ''}
														disabled={disabled}
														type="number"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (userPackage){
																userPackage.tutorCount = (e.target.value as number)
																setUserPackage({ ...userPackage });
															}
														}}
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Total admins allowed</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Input
														name="adminCount"
														placeholder="Enter admin count"
														value={userPackage ? userPackage.adminCount as number : ''}
														disabled={disabled}
														type="number"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (userPackage){
																userPackage.adminCount = (e.target.value as number)
																setUserPackage({ ...userPackage });
															}
														}}
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Total No. of batches allowed</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Input
														name="batchCount"
														placeholder="Enter batch count"
														value={userPackage ? userPackage.batchCount as number : ''}
														disabled={disabled}
														type="number"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (userPackage){
																userPackage.batchCount = (e.target.value as number)
																setUserPackage({ ...userPackage });
															}
														}}
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Total No. of courses allowed</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Input
														name="courseCount"
														placeholder="Enter courses count"
														value={userPackage ? userPackage.courseCount as number : ''}
														disabled={disabled}
														type="number"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (userPackage){
																userPackage.courseCount = (e.target.value as number)
																setUserPackage({ ...userPackage });
															}
														}}
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Live Class hrs/day</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Input
														name="liveClassQuota"
														placeholder="Enter live Class Quota "
														value={userPackage && userPackage.liveClassQuota ? userPackage.liveClassQuota as number : ''}
														disabled={disabled}
														type="number"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (userPackage){
																userPackage.liveClassQuota = (e.target.value as number)
																setUserPackage({ ...userPackage });
															}
														}}
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Live class type</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={4}>
												<Select onChange={(e) => {
													userPackage.liveClassType = e.target.value as string
													setUserPackage({ ...userPackage })
												}}
													disabled={disabled}
													value={userPackage.liveClassType}>
													{LIVE_CLASS_TYPES.map((item, index) => (
														<MenuItem value={item.value} key={index}	>
															{item.name}
														</MenuItem>
													))}
												</Select>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Recording hrs/lecture</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Input
														name="recordingQuota"
														placeholder="Enter recording Quota"
														value={userPackage ? userPackage.recordingQuota as number : ''}
														disabled={disabled}
														type="number"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (userPackage){
																userPackage.recordingQuota = (e.target.value as number)
																setUserPackage({ ...userPackage });
															}
														}}
													/>
												</FormControl>
											</Grid>
										</Grid>

										{userPackage.sessionCount !== undefined && userPackage.sessionCount !== null &&
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Session count</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Input
															name="sessionCount"
															placeholder="Enter session count"
															value={userPackage ? userPackage.sessionCount as number : ''}
															disabled={disabled}
															type="number"
															inputProps={{ maxLength: 5, typeof: 'number' }}
															onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
																if (userPackage){
																	userPackage.sessionCount = (e.target.value as number)
																	setUserPackage({ ...userPackage });
																}
															}}
														/>
													</FormControl>
												</Grid>
											</Grid>
										}

										{userPackage.customChapter &&
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Custom chapters count</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Input
															name="customChapter"
															placeholder="Enter chapters count"
															value={userPackage ? userPackage.customChapter as number : ''}
															disabled={disabled}
															type="number"
															inputProps={{ maxLength: 5, typeof: 'number' }}
															onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
																if (userPackage){
																	userPackage.customChapter = (e.target.value as number)
																	setUserPackage({ ...userPackage });
																}
															}}
														/>
													</FormControl>
												</Grid>
											</Grid>
										}

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Per student per sub per month fee (₹)</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Input
														name="perstudentcost"
														placeholder="Enter per student cost"
														inputProps={{ maxLength: 5 }}
														value={userPackage ? userPackage.perstudentcost : ''}
														onChange={handleChange}
														disabled={disabled}
													/>
												</FormControl>
											</Grid>
										</Grid>

										{
											filteredPaymentPlans && filteredPaymentPlans.length &&
											<>
												<Grid container className={styles.paddClass}>
													<Grid item xs={12} md={4}>
														<FormControl fullWidth margin="normal">
															<Box className={styles.label}>Min monthly fee from tutor (₹)</Box>
														</FormControl>
													</Grid>

													<Grid item xs={12} md={4}>
														<FormControl fullWidth margin="normal">
															<Input
																name="MONTHLY"
																placeholder="Min fee from tutor"
																value={filteredPaymentPlans && filteredPaymentPlans.length ? filteredPaymentPlans[0].totalprice as number : ''}
																type="number"
																inputProps={{ maxLength: 5, typeof: 'number' }}
																onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
																	if (userPackage){
																		filteredPaymentPlans[0].totalprice = (e.target.value as number)
																		setFilteredPaymentPlans([ ...filteredPaymentPlans ]);
																	}
																}}
																disabled={disabled}
															/>
														</FormControl>
													</Grid>
												</Grid>
												<Grid container className={styles.paddClass}>
													<Grid item xs={12} md={4}>
														<FormControl fullWidth margin="normal">
															<Box className={styles.label}>Min quarterly fee from tutor (₹)</Box>
														</FormControl>
													</Grid>

													<Grid item xs={12} md={4}>
														<FormControl fullWidth margin="normal">
															<Input
																name="QUARTERLY"
																placeholder="Min fee from tutor"
																value={filteredPaymentPlans && filteredPaymentPlans.length ? filteredPaymentPlans[1].totalprice : ''}
																type="number"
																inputProps={{ maxLength: 5, typeof: 'number' }}
																onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
																	if (userPackage){
																		filteredPaymentPlans[1].totalprice = (e.target.value as number)
																		setFilteredPaymentPlans([ ...filteredPaymentPlans ]);
																	}
																}}
																disabled={disabled}
															/>
														</FormControl>
													</Grid>
												</Grid>
												<Grid container className={styles.paddClass}>
													<Grid item xs={12} md={4}>
														<FormControl fullWidth margin="normal">
															<Box className={styles.label}>Min half yearly fee from tutor (₹)</Box>
														</FormControl>
													</Grid>

													<Grid item xs={12} md={4}>
														<FormControl fullWidth margin="normal">
															<Input
																name="HALFYEARLY"
																placeholder="Min fee from tutor"
																value={filteredPaymentPlans && filteredPaymentPlans.length ? filteredPaymentPlans[2].totalprice : ''}
																type="number"
																inputProps={{ maxLength: 5, typeof: 'number' }}
																onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
																	if (userPackage){
																		filteredPaymentPlans[2].totalprice = (e.target.value as number)
																		setFilteredPaymentPlans([ ...filteredPaymentPlans ]);
																	}
																}}
																disabled={disabled}
															/>
														</FormControl>
													</Grid>
												</Grid>
												<Grid container className={styles.paddClass}>
													<Grid item xs={12} md={4}>
														<FormControl fullWidth margin="normal">
															<Box className={styles.label}>Min annual fee from tutor (₹)</Box>
														</FormControl>
													</Grid>

													<Grid item xs={12} md={4}>
														<FormControl fullWidth margin="normal">
															<Input
																name="LUMPSUM"
																placeholder="Min fee from tutor"
																value={filteredPaymentPlans && filteredPaymentPlans.length ? filteredPaymentPlans[3].totalprice : ''}
																type="number"
																inputProps={{ maxLength: 5, typeof: 'number' }}
																onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
																	if (userPackage){
																		filteredPaymentPlans[3].totalprice = (e.target.value as number)
																		setFilteredPaymentPlans([ ...filteredPaymentPlans ]);
																	}
																}}
																disabled={disabled}
															/>
														</FormControl>
													</Grid>
												</Grid>

												{/* <Grid container className={styles.paddClass}>
													<Grid item xs={12} md={4}>
														<FormControl fullWidth margin="normal">
															<Box className={styles.label}>Discount</Box>
														</FormControl>
													</Grid>

													<Grid item xs={12} md={4}>
														<FormControl fullWidth margin="normal">
															<Input
																name="discount"
																placeholder="Enter discount"
																inputProps={{ maxLength: 3 }}
																value={filteredPaymentPlans && filteredPaymentPlans.length ? filteredPaymentPlans[0].discount : ''}
																onChange={handleChange}
																disabled={disabled}
															/>
														</FormControl>
													</Grid>
												</Grid> */}
											</>
										}

										{
											userPackage.additionalCharge && userPackage.additionalCharge.length &&
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Additional Charge (₹)</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Input
															name="additionalCharge"
															placeholder="Enter Additional charge"
															inputProps={{ maxLength: 5 }}
															value={userPackage.additionalCharge && userPackage.additionalCharge.length ? userPackage.additionalCharge[0].totalprice : ''}
															onChange={handleChange}
															disabled={disabled}
														/>
													</FormControl>
												</Grid>
											</Grid>
										}

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Convenience Fee (₹)</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Input
														name="convenienceFee"
														placeholder="Enter convenience fee"
														inputProps={{ maxLength: 3 }}
														value={userPackage ? userPackage.convenienceFee : 0}
														onChange={handleChange}
														disabled={disabled}
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Grace Period (days)</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Input
														name="graceperiod"
														placeholder="Enter grace period"
														inputProps={{ maxLength: 3 }}
														value={userPackage ? userPackage.graceperiod : 0}
														onChange={handleChange}
														disabled={disabled}
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Max cumulative content space allowed (KB)</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Input
														name="contentSize"
														placeholder="Enter content Size"
														value={userPackage ? userPackage.contentSize as number : 0}
														disabled={disabled}
														type="number"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (userPackage){
																	userPackage.contentSize = (e.target.value as number)
																	setUserPackage({ ...userPackage });
															}
														}}
													/>
												</FormControl>
											</Grid>
										</Grid>

									</>
								}

								<Grid container className={styles.paddClass}>
									<Grid item xs={12} md={4}>
										<FormControl fullWidth margin="normal">
											<Box className={styles.label}>Approval/Rejection Reason</Box>
										</FormControl>
									</Grid>

									<Grid item xs={12} md={4}>
										<FormControl fullWidth margin="normal">
											<Input
												name="reason"
												placeholder="Enter Reason"
												inputProps={{ maxLength: 100 }}
												value={reason}
												onChange={(e) => setReason(e.target.value)}
												disabled={disabled}
											/>
										</FormControl>
									</Grid>
								</Grid>

								{/* <Grid item xs={12} md={6}>
									<Button color='primary' variant='contained' disabled={loading}
										onClick={handleSavePackage}>Save</Button>
								</Grid> */}
								<>
									{(allowedPermissions.includes(MASTER_PERMISSIONS.makerBankChange) || loginUserType == USER_ROLES.super) &&
										<Button variant="contained" color="primary" size="medium"
											style={{ margin: 10 }}
											onClick={handleApproveAsMaker}
											disabled={disabled}
										>	Approve as Maker
										</Button>}

									{(allowedPermissions.includes(MASTER_PERMISSIONS.checkerBankChange) || loginUserType == USER_ROLES.super)
										&& <Button variant="contained" color="primary" size="medium"
											style={{ margin: 10 }}
											onClick={handleApproveAsChecker}
											disabled={disabled}
										>	Approve as Checker
										</Button>}

									{(allowedPermissions.includes(MASTER_PERMISSIONS.makerBankChange) || loginUserType == USER_ROLES.super) &&
										<Button variant="contained" color="primary" size="medium"
											style={{ margin: 10 }}
											onClick={handleRejectAsMaker}
											disabled={disabled}
										>	Reject as Maker
										</Button>}

									{(allowedPermissions.includes(MASTER_PERMISSIONS.checkerBankChange) || loginUserType == USER_ROLES.super)
										&& <Button variant="contained" color="primary" size="medium"
											style={{ margin: 10 }}
											onClick={handleRejectAsChecker}
											disabled={disabled}
										>	Reject as Checker
										</Button>}
								</>


							</CardContent>
						</Card>
					</Container>
				</div>
			</MiniDrawer >
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	user: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(ViewPackage);
