import React, { useState, useEffect, FunctionComponent } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  lighten,
} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import {
  Box,
  Container,
  Grid,
  Button,
  Input,
  Typography,
  FormControlLabel,
  IconButton,
  CssBaseline,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  InputAdornment,
  Toolbar,
  Radio,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { RouteComponentProps, Redirect } from "react-router";
import { connect, useDispatch } from "react-redux";

import MiniDrawer from "../../../common/components/SideDrawer";
import { fontOptions } from "../../../common/theme";
import {
  EMAIL_PATTERN,
  PHONE_PATTERN,
} from "../../../common/validations/patterns";
import {
  createAdminUser,
  deleteollegeGroup,
  getAdminData,
  getAdmins,
  getAllCollegeGroups,
  getRoles,
  getUserDetails,
  getUsers,
} from "../../../../api/dashboard";
import {
  AccountBalance,
  ColorLens,
  Block,
  Clear,
  CreditCard,
  Delete,
  Edit,
  Receipt,
  Search,
  Visibility,
  VisibilityOff,
  ViewList,
  LockOpen,
} from "@material-ui/icons";
import useStyles from "./styles";
import { User, CustomUser } from "../../../common/contracts/dashboard";
import  { datagridCellExpand, datagridCellExpand2 } from "../../components/dataGrid";
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import {
  setCurrentUser,
  setCustomersList,
} from "../../../redux/actions/adminActions";
import { USER_TYPES, USER_TYPE_VALUES } from "../../../../utilities/constants";
import { RootState } from "../../../redux/store";
import {
  MASTER_PERMISSIONS,
  USER_ROLES,
} from "../../../../utilities/constants";
import Datepickers from "../../components/datepickers";
import ReviewModal from "../../../finance/components/modals/reviewModal";
import { UserDataTable } from "../../components/UserDataTable";
import { compareAsc } from "date-fns";
import AdbIcon from "@material-ui/icons/Adb";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ConfirmationModal from "../../../common/components/confirmation_modal";
import Datagrids from "../../../uploadContent/components/datagrids";

interface Props extends RouteComponentProps {
  customersList: CustomUser[];
  User: CustomUser;
}
interface EnhancedTableToolbarProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setFocused: React.Dispatch<React.SetStateAction<boolean>>;
  maxLength: number;
  placeholder: string;
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      height: "100%",
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

const EnhancedTableToolbar: FunctionComponent<EnhancedTableToolbarProps> = ({
  searchText,
  setSearchText,
  setFocused,
  maxLength,
  placeholder,
}) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft="auto"
      >
        <Box margin="15px 10px 10px 25px">
          <FormControl fullWidth margin="normal">
            <Input
              name="search"
              inputProps={{ inputMode: "search", maxLength: maxLength }}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              placeholder={placeholder}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              endAdornment={
                searchText.trim() !== "" ? (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setSearchText("")}>
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <IconButton disabled size="small">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </FormControl>
          {searchText.trim() !== "" && (
            <Typography
              style={{
                marginTop: "5px",
                fontSize: fontOptions.size.small,
                color: "#666666",
              }}
            >
              Filtered Table using Keyword '{searchText}'
            </Typography>
          )}
        </Box>
      </Box>
    </Toolbar>
  );
};

const CollegeGroupsDashboard: FunctionComponent<Props> = ({
  history,
  customersList,
  User,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const styles = useStyles();
  const dispatch = useDispatch();
	const [rows, setRows] = useState<any>([])
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedGroup,setSelectedGroup] = useState<string>("")

  useEffect(()=>{
    getDashboradData()
  },[])

  const getDashboradData = async()=>{
    try {
      let colleges = await getAllCollegeGroups()
      let tempRows:any = colleges?.map((college:any,index:number)=>{
        return {id:index+1,groupId:college?._id,groupName:college?.collegeGroupName,noOfColleges:college?.collegeOwnerIdsArr?.length,collegeOwnerIdsArr:college?.collegeOwnerIdsArr}
      })
      setRows(tempRows)
      // console.log("colleges",colleges); 
    } catch (error) {
      console.log("error",error);
    }
  }

  const handleEditGroup = (props:any)=>{
    history.push("/create-group",{data:props})
  }

  const handelDeleteGroup = (props:any)=>{
    setOpenConfirmationModal(true)
    setSelectedGroup(props?.groupId)
  }

  const handelViewGroup = (props:any)=>{
    history.push("/view-group",{data:props})
  }

  const deleteCollegeGroup = async()=>{
    try {
      setLoading(true)
      console.log("selectedGroup",selectedGroup);
      await deleteollegeGroup(selectedGroup)
      await getDashboradData()
      setOpenConfirmationModal(false)
      enqueueSnackbar("College group deleted successfully", { variant: "success" })
      setLoading(false)
    } catch (error) {
      console.log("error",error);
      setOpenConfirmationModal(false)
      setLoading(false)
    }
  }

  const buttonData = [
		{
			title: 'Edit',
			action: handleEditGroup,
			icon: <EditIcon />
		},
		{
			title: 'Delete',
			action: handelDeleteGroup,
			icon: <DeleteIcon />
		},
    {
			title: 'View',
			action: handelViewGroup,
			icon: <VisibilityIcon />
		},
	]

  const renderCell = (params:any) => {
    const { value } = params;
  
    return (
      <div style={{ width: '100%' }}>
        {value}
      </div>
    );
  };

const  gridColumns:GridColDef[] = [
    { field: 'id', headerName: 'Sr.no' },
    { field: 'groupId', headerName: 'Group ID', type: 'string', renderCell: renderCell, flex: 1 },
    { field: 'groupName', headerName: 'Group Name', type: 'string', renderCell: renderCell, flex: 1 },
    { field: 'noOfColleges', headerName: 'No. of Colleges Added', type: 'string', renderCell: renderCell , flex: 1},
    {
			field: 'action', headerName: 'Actions', width: 200,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				const selectedRow:any = {
					id: params.getValue("id") as number,
					groupId: params.getValue("groupId") as string,
					groupName: params.getValue("groupName") as string,
          noOfColleges: params.getValue("noOfColleges") as number,
				}

				const selectedRowDetails = rows.find((row:any) => {
					return (row.groupId === selectedRow.groupId )
				})

				const buttonSet = buttonData.map((button, index) => {
					return (
						<Tooltip key={index} title={button.title}>
							<IconButton
								onClick={() => {
									button.action(selectedRowDetails as any);
								}}
								size="small"
							>
								{button.icon}
							</IconButton>
						</Tooltip>
					);
				})

				return <div>{buttonSet}</div>;
			},
      flex: 1
		}
  ];

  return (
    <>
      <CssBaseline />
      <MiniDrawer>
        <div style={{ height: "80vh", width: "100%" }}>
          <Container style={{ width: "100%" }}>
            <Grid container justify="space-between" style={{alignItems:"center"}}>
                <Grid item>
                    <Typography style={{fontSize:24}}>All College Groups</Typography>
                </Grid>
                <Grid item>
                    <Box className={styles.submitBtn}>
						<Button style={{textTransform:"capitalize"}} disableElevation variant="contained" color="primary" size="large" type="submit" onClick={()=>{history.push("/create-group")}} disabled={loading}>+ New College Group</Button>
					</Box>
                </Grid>
            </Grid>
            <Grid container>
              {/* <UserDataTable
                key={"key" + selectedUserType + fromDate + toDate}
                gridData={modalData}
                reportType={selectedUserType}
                buttonData={buttonData}
                userType={
                  selectedUserType === USER_TYPE_VALUES.ORG_TUTOR
                    ? USER_TYPE_VALUES.TUTOR
                    : selectedUserType
                }
              /> */}
              {/* </Box> */}
              <Datagrids gridRows={rows}
        gridColumns={gridColumns}
        // showToolBar={true}
         disableCheckbox={true} 
        searchBool={true}
        />
            </Grid>
          </Container>
        </div>
        <ConfirmationModal
				header="Delete College Group"
                helperText="Are you sure you want to delete?"
                openModal={openConfirmationModal}
                onClose={() => {setOpenConfirmationModal(false)}}
                handleDelete={deleteCollegeGroup}
			/>
      </MiniDrawer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  customersList: state.adminReducer.customersList as CustomUser[],
  User: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(CollegeGroupsDashboard);
