import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Grid,
  Input,
  Typography,
  Button,
  Select,
  MenuItem
} from '@material-ui/core';
import { GridColDef } from '@material-ui/data-grid';
import { useSnackbar } from "notistack";

import Datagrids, { datagridCellExpand } from "../../dashboard/components/dataGrid";
import Modal from '../../common/components/modal';
import useStyles from '../../leadsManagement/styles';
import { attendanceReport, attendanceRow } from '../interfaces';
import { fetchAttendanceReport } from '../api';
import { onApiError } from '../../common/helpers';

interface Props {
    openModal: boolean;
    onClose: () => any;
    meetingID: string;
}

const ViewAttendanceReportModal: FunctionComponent<Props> = ({
    meetingID,
    onClose,
    openModal
}) => {
    const styles = useStyles();

    const [showDataGrid, setShowDataGrid] = useState(false)
    const [gridRows, setGridRows] = useState<attendanceRow[]>([])
    const [gridColumns, setGridColumns] = useState<GridColDef[]>([])
    
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        getAttendanceReport()
    }, [])

    const getAttendanceReport = async () => {
        await fetchAttendanceReport({meetingID})
        .then(val => {
            createDataGrid(val)
            setShowDataGrid(true)
        })
        .catch((err) => {
            onApiError(err, enqueueSnackbar)
        })
    }

    const createDataGrid = (report: attendanceReport[]) => {
        setGridRows(report.map((cur, i) => Object.assign({}, cur, {id: i+1})))
        setGridColumns([
            { field: 'id', headerName: 'S.No', flex: 0.5 },
			{ field: 'studentName', headerName: 'Student', flex: 1.5, width: 100, renderCell: datagridCellExpand },
			{ field: 'entryTime', headerName: 'Entry Time', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'percentage', headerName: 'Attended %', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'status', headerName: 'Status', flex: 1, width: 100, renderCell: datagridCellExpand }
        ])
    }

    return (
        <>
        <Modal
            handleClose={() => {
                setShowDataGrid(false)
                setGridRows([])
                setGridColumns([])
                onClose()
            }}
            open={openModal}
            large
            header={
                <Box>
                    <Typography component="span" color="inherit">
                    <Box component="h3">
                        View Attendance
                    </Box>
                    </Typography>
                </Box>
            }
        >
            {showDataGrid && <Datagrids gridRows={gridRows} gridColumns={gridColumns} disableCheckbox />}
        </Modal>
        </>
    )
}

export default ViewAttendanceReportModal