import React, { FunctionComponent, useState, useEffect } from 'react';
import { Box, Container, Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme, darken } from '@material-ui/core/styles';
import MiniDrawer from '../../common/components/SideDrawer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSnackbar } from "notistack";
import Button from '../../common/components/form_elements/button';
import { fontOptions } from '../../common/theme';
import { createMetaTags,getAllMetaTags,getMetaTag } from '../helper/api';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import Datagrids, { datagridCellExpand } from '../../dashboard/components/dataGrid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from '../../common/components/confirmation_modal';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { CloseRounded } from '@material-ui/icons';
import { clear } from 'console';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
        fontSize: fontOptions.size.large
    },
    addAssi: {
        '& button': {
          color: '#4C8BF5',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: darken('#FFFFFF', 0.1),
          }
        },
        paddingTop: '12px'
    },
    tstudent: {
        fontSize: theme.typography.pxToRem(14),
        color: 'black'
      },
  }),
);

interface MetaData{
    address:String,
addressFriendlyName:String,
description:String,
keyWords:String[]
link: String[]
scripts: String[]
title: String
}

interface Props extends RouteComponentProps {}

const SeoForPage: FunctionComponent<Props> = ({history, location})  => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [metaData] = useState<any>(location.state||null);
    const [address, setAddress] = useState("");
    const [addressName, setAddressName] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [links, setLinks] = useState<String[]>([]);
    const [link, setLink] = useState<String>("");
    const [scripts, setScripts] = useState<String[]>([]);
    const [scriptsHtml, setScriptsHtml] = useState<String[]>([]);
    const [script, setScript] = useState<String>("");
    const [scriptHtml, setScriptHtml] = useState<String>("");
    const [keyWords, setKeyWords] = useState<String[]>([]);
    const [keyWord, setKeyWord] = useState<String>("");
    const [h1Tags, setH1Tags] = useState<string[]>([]);
    const [h1Tag, setH1Tag] = useState<string>("");
	const [h2Tags, setH2Tags] = useState<string[]>([]);
	const [h2Tag, setH2Tag] = useState<string>("");
	const [h3Tags, setH3Tags] = useState<string[]>([]);
	const [h3Tag, setH3Tag] = useState<string>("");
	const [h4Tags, setH4Tags] = useState<string[]>([]);
	const [h4Tag, setH4Tag] = useState<string>("");
	const [h5Tags, setH5Tags] = useState<string[]>([]);
	const [h5Tag, setH5Tag] = useState<string>("");
	const [h6Tags, setH6Tags] = useState<string[]>([]);
	const [h6Tag, setH6Tag] = useState<string>("");
	

    useEffect(() => {
      console.log('%cseoForPages.tsx line:87 metaData', 'color: #007acc;', metaData);
        if(metaData){
          setAddress(metaData.data.address?metaData.data.address:"")
          setAddressName(metaData.data.addressFriendlyName?metaData.data.addressFriendlyName:"")
          setTitle(metaData.data.title?metaData.data.title:"")
          setDescription(metaData.data.description?metaData.data.description:"")
          setKeyWords(metaData.data.keyWords?metaData.data.keyWords:[])
          setScriptsHtml(metaData.data.scriptHtml?metaData.data.scriptHtml:[])
          setLinks(metaData.data.link?metaData.data.link:[])
          setScripts(metaData.data.scripts?metaData.data.scripts:[])
        }  
    }, [location.search])
    useEffect(()=>{
      console.log('%cseoForPages.tsx line:100 keyWords', 'color: #007acc;', keyWords);
    },[keyWords])

    const addKeyword = ()=>{
        let arr:String[] = []
        arr = keyWords
        
    
    arr.push(keyWord)
    setKeyWords(arr)
    setKeyWord('')    
    }
    const addLink = ()=>{
        let arr:String[] = []
        arr = links
        
        if(link!==''){

            arr.push(link)
            setLinks(arr)
            setLink('')    
        }else{

        }
    }
    const addScript = ()=>{
        let arr:String[] = []
        arr = scripts
        
    if(script!==''){
        arr.push(script)
        setScripts(arr)
        setScript('')    

    }else{
        return
    }
    }
    const addScriptHtml = ()=>{
        let arr:String[] = []
        arr = scriptsHtml
        
    if(scriptHtml!==''){
        arr.push(scriptHtml)
        setScriptsHtml(arr)
        setScriptHtml('')    

    }else{
        return
    }
    }
    const addh1Tags = ()=>{
        console.log("hello")
        let arr:string[] = []
        arr = h1Tags
        
    if(h1Tag!==''){
        arr.push(h1Tag)
        setH1Tags(arr)
        setH1Tag('')    

    }else{
        return
    }
    }
    
    const addh2Tags = ()=>{
        let arr:string[] = []
        arr = h2Tags
        
    if(h2Tag!==''){
        arr.push(h2Tag)
        setH2Tags(arr)
        setH2Tag('')    

    }else{
        return
    }
    }

    const addh3Tags = ()=>{
        let arr:string[] = []
        arr = h3Tags
        
    if(h3Tag!==''){
        arr.push(h3Tag)
        setH3Tags(arr)
        setH3Tag('')    

    }else{
        return
    }
    }
    const addh4Tags = ()=>{
        let arr:string[] = []
        arr = h4Tags
        
    if(h4Tag!==''){
        arr.push(h4Tag)
        setH4Tags(arr)
        setH4Tag('')    

    }else{
        return
    }
    }
    const addh5Tags = ()=>{
        let arr:string[] = []
        arr = h5Tags
        
    if(h5Tag!==''){
        arr.push(h5Tag)
        setH5Tags(arr)
        setH5Tag('')    

    }else{
        return
    }
    }
    const addh6Tags = ()=>{
        let arr:string[] = []
        arr = h6Tags
        
    if(h6Tag!==''){
        arr.push(h6Tag)
        setH6Tags(arr)
        setH6Tag('')    

    }else{
        return
    }
    }


    const saveTags = async ()=>{
        let obj = {
            address:address,
            addressFriendlyName:addressName,
            title:title,
            description:description,
            keyWords:keyWords,
            link:links,
            scripts:scripts
        }  
        await createMetaTags(
            keyWords,
            links,
            scripts,
            h1Tags,
            h2Tags,
            h3Tags,
            h4Tags,
            h5Tags,
            h6Tags,
            addressName,
            address,
            title,
            description
            )
            enqueueSnackbar('Tag updated',{variant:'success'})
    }
    const handleDeleteKeywords = (item:String,index:number)=>{
        let arr = keyWords.filter((el)=>el!==item)
        setKeyWords(arr)      
    }
    const handleDeleteLinks = (item:String,index:number)=>{
        let arr = links.filter((el)=>el!==item)
        setLinks(arr)      
    }
    const handleDeleteScripts = (item:String,index:number)=>{
        let arr = scriptsHtml.filter((el)=>el!==item)
        setScriptsHtml(arr)      
    }
    



    return (
        <div>
            <MiniDrawer>
            <Container maxWidth="lg" style={{padding: '30px 2.5%'}}>
                <Grid container>
                    <Box >
                      <Grid item>
                        <Typography style={{marginBottom:'10px',marginTop:'10px'}}>
                            Address
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="Address"
                        placeholder="Address Of the page"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        // multiline
                        disabled={true}
                        // rows={8}
                        style={{width:"550px"}}
                        variant="outlined"
                />
                        </Grid>  
                      <Grid item>
                        <Typography style={{marginBottom:'10px',marginTop:'10px'}}>
                            Address Name:
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="Address Name"
                        placeholder="Name of the Address"
                        value={addressName}
                        onChange={(e) => setAddressName(e.target.value)}
                        // multiline
                        disabled={true}
                        // rows={8}
                        style={{width:"550px"}}
                        variant="outlined"
                />
                        </Grid>  
                      <Grid item>
                        <Typography style={{marginBottom:'10px',marginTop:'10px'}}>
                            Title:
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="Title"
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        // multiline
                        // rows={8}
                        style={{width:"550px"}}
                        variant="outlined"
                />
                        </Grid>  
                      <Grid item>
                        <Typography style={{marginBottom:'10px',marginTop:'10px'}}>
                            Description:
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="Description"
                        placeholder="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        multiline
                        rows={8}
                        style={{width:"550px"}}
                        variant="outlined"
                />
                        </Grid>  
                      <Grid item>
                        <Typography style={{marginBottom:'10px',marginTop:'10px'}}>
                            Keywords:
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="Keywords"
                        placeholder="Keywords"
                        value={keyWord}
                        onChange={(e) => setKeyWord(e.target.value)}
                        // multiline
                        // rows={8}
                        style={{width:"550px"}}
                        variant="outlined"
                />  
                <Tooltip title="Add keyword">
                        <IconButton onClick={addKeyword}>
                          <AddCircleIcon fontSize='large' color='primary'/>
                        </IconButton>

                        </Tooltip>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
           <Box width={'100'} >
           <Typography component="span" color="primary">
									<Box component="h3" style={{margin: '0',fontWeight: 500,
      fontSize: fontOptions.size.medium,
      letterSpacing: '1px',
      color: '#4C8BF5'}} >
										All Keywords({keyWords.length})
									</Box>
								</Typography>
           </Box>
           </Grid>
          <Grid item xs={3} style={{paddingTop: '10px', marginBottom: '10px', marginLeft:'200px'}} >
           <Box width={'100'} justifyContent='flex-end' alignSelf={'flex-end'} style={{border:'1 px solid', marginLeft:'50px'}} >
           <Grid container direction='row' spacing={2}>
          {keyWords.map((item,index)=>(
              <Grid item >
            <Box
          bgcolor="#2E7FF824"
          borderRadius="14px"
          padding="5px"
          marginTop='5px'
          display={'flex'}
          flexDirection='row'
          
        >
        <Box>
        <Typography className={classes.tstudent}>{item}</Typography>

          
        </Box>
        <Box>
          <IconButton  size='small' onClick={()=>{
            handleDeleteKeywords(item,index)
          }}>
            <CloseRounded fontSize='small'/>
          </IconButton>
        </Box>
        </Box>
        
      </Grid>
          ))}
          </Grid>
           </Box>
           </Grid>  
                      <Grid item>
                        <Typography style={{marginBottom:'10px',marginTop:'10px'}}>
                            Links:
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="Links"
                        placeholder="Links"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        multiline
                        rows={8}
                        style={{width:"550px"}}
                        variant="outlined"
                />  
                <Tooltip title="Add Tax">
                        <IconButton onClick={addLink}>
                          <AddCircleIcon fontSize='large' color='primary'/>
                        </IconButton>

                        </Tooltip>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
           <Box width={'100'} >
           <Typography component="span" color="primary">
									<Box component="h3" style={{margin: '0',fontWeight: 500,
      fontSize: fontOptions.size.medium,
      letterSpacing: '1px',
      color: '#4C8BF5'}} >
										All Link({links.length})
									</Box>
								</Typography>
           </Box>
           </Grid>
          <Grid item xs={3} style={{paddingTop: '10px', marginBottom: '10px', marginLeft:'200px'}} >
           <Box width={'100'} justifyContent='flex-end' alignSelf={'flex-end'} style={{border:'1 px solid', marginLeft:'50px'}} >
           <Grid container direction='row' spacing={2}>
          {links.map((item,index)=>(
              <Grid item >
            <Box
          bgcolor="#2E7FF824"
          borderRadius="14px"
          padding="5px"
          marginTop='5px'
          display={'flex'}
          flexDirection='row'
          
        >
        <Box>
        <Typography className={classes.tstudent}>{item}</Typography>

          
        </Box>
        <Box>
          <IconButton  size='small' onClick={()=>{
            handleDeleteLinks(item,index)
          }}>
            <CloseRounded fontSize='small'/>
          </IconButton>
        </Box>
        </Box>
        
      </Grid>
          ))}
          </Grid>
           </Box>
           </Grid>  
                      {/* <Grid item>
                        <Typography>
                            Scripts:
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="Scripts"
                        placeholder="Scripts"
                        value={script}
                        onChange={(e) => setScript(e.target.value)}
                        multiline
                        rows={1}
                        style={{width:"550px"}}
                        variant="outlined"
                />  
                <Tooltip title="Add Tax">
                        <IconButton onClick={addScript}>
                          <AddCircleIcon fontSize='large' color='primary'/>
                        </IconButton>

                        </Tooltip>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
           <Box width={'100'} >
           <Typography component="span" color="primary">
									<Box component="h3" style={{margin: '0',fontWeight: 500,
      fontSize: fontOptions.size.medium,
      letterSpacing: '1px',
      color: '#4C8BF5'}} >
										All Script({scripts.length})
									</Box>
								</Typography>
           </Box>
           </Grid>
          <Grid item xs={3} style={{paddingTop: '10px', marginBottom: '10px', marginLeft:'200px'}} >
           <Box width={'100'} justifyContent='flex-end' alignSelf={'flex-end'} style={{border:'1 px solid', marginLeft:'50px'}} >
           <Grid container direction='row' spacing={2}>
          {scripts.map((item,index)=>(
              <Grid item >
            <Box
          bgcolor="#2E7FF824"
          borderRadius="14px"
          padding="5px"
          marginTop='5px'
          display={'flex'}
          flexDirection='row'
          
        >
         <Box>
        <Typography className={classes.tstudent}>{item}</Typography>

          
        </Box>
        <Box>
          <IconButton  size='small' onClick={()=>{
            // handleDlete(item._id)
          }}>
            <CloseRounded fontSize='small'/>
          </IconButton>
        </Box>
        </Box>
        
      </Grid>
          ))}
          </Grid>
           </Box>
           </Grid>   */}
           <Grid item>
                        <Typography style={{marginBottom:'10px',marginTop:'10px'}}>
                            Scripts Inner Html:
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="Scripts Html"
                        placeholder="Scripts Html"
                        value={scriptHtml}
                        onChange={(e) => setScriptHtml(e.target.value)}
                        multiline
                        rows={8}
                        style={{width:"550px"}}
                        variant="outlined"
                />  
                <Tooltip title="Add Tax">
                        <IconButton onClick={addScriptHtml}>
                          <AddCircleIcon fontSize='large' color='primary'/>
                        </IconButton>

                        </Tooltip>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
           <Box width={'100'} >
           <Typography component="span" color="primary">
									<Box component="h3" style={{margin: '0',fontWeight: 500,
      fontSize: fontOptions.size.medium,
      letterSpacing: '1px',
      color: '#4C8BF5'}} >
										All Script Inner HTML({scriptsHtml.length})
									</Box>
								</Typography>
           </Box>
           </Grid>
          <Grid item xs={3} style={{paddingTop: '10px', marginBottom: '10px', marginLeft:'200px'}} >
           <Box width={'100'} justifyContent='flex-end' alignSelf={'flex-end'} style={{border:'1 px solid', marginLeft:'50px'}} >
           <Grid container direction='row' spacing={2}>
          {scriptsHtml.map((item,index)=>(
              <Grid item >
            <Box
          bgcolor="#2E7FF824"
          borderRadius="14px"
          padding="5px"
          marginTop='5px'
          display={'flex'}
          flexDirection='row'
          
        >
         <Box>
        <Typography className={classes.tstudent}>{item}</Typography>

          
        </Box>
        <Box>
          <IconButton  size='small' onClick={()=>{
            handleDeleteScripts(item,index)
}}>
            <CloseRounded fontSize='small'/>
          </IconButton>
        </Box>
        </Box>
        
      </Grid>
          ))}
          </Grid>
           </Box>
           </Grid>  
                      
                      
                      
                     {/* <Grid item>
                        <Typography>
                            H1 Tags:
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="h1 Tags"
                        placeholder="h1 Tags"
                        value={h1Tag}
                        onChange={(e) => setH1Tag(e.target.value)}
                        multiline
                        rows={1}
                        style={{width:"550px"}}
                        variant="outlined"
                />  
                <Tooltip title="Add Tax">
                        <IconButton onClick={addh1Tags}>
                          <AddCircleIcon fontSize='large' color='primary'/>
                        </IconButton>

                        </Tooltip>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
           <Box width={'100'} >
           <Typography component="span" color="primary">
									<Box component="h3" style={{margin: '0',fontWeight: 500,
      fontSize: fontOptions.size.medium,
      letterSpacing: '1px',
      color: '#4C8BF5'}} >
										All H1 Tags({h1Tags.length})
									</Box>
								</Typography>
           </Box>
           </Grid>
          <Grid item xs={3} style={{paddingTop: '10px', marginBottom: '10px', marginLeft:'200px'}} >
           <Box width={'100'} justifyContent='flex-end' alignSelf={'flex-end'} style={{border:'1 px solid', marginLeft:'50px'}} >
           <Grid container direction='row' spacing={2}>
          {h1Tags.map((item,index)=>(
              <Grid item >
            <Box
          bgcolor="#2E7FF824"
          borderRadius="14px"
          padding="5px"
          marginTop='5px'
          display={'flex'}
          flexDirection='row'
          
        >
        <Box>
        <Typography className={classes.tstudent}>{item}</Typography>

          
        </Box>
        <Box>
          <IconButton  size='small' onClick={()=>{
            // handleDlete(item._id)
          }}>
            <CloseRounded fontSize='small'/>
          </IconButton>
        </Box>
        </Box>
        
      </Grid>
          ))}
          </Grid>
           </Box>
           </Grid>  


                      <Grid item>
                        <Typography>
                            H2 Tags:
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="h2 Tags"
                        placeholder="h2 Tags"
                        value={h2Tag}
                        onChange={(e) => setH2Tag(e.target.value)}
                        multiline
                        rows={1}
                        style={{width:"550px"}}
                        variant="outlined"
                />  
                <Tooltip title="Add Tax">
                        <IconButton onClick={addh2Tags}>
                          <AddCircleIcon fontSize='large' color='primary'/>
                        </IconButton>

                        </Tooltip>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
           <Box width={'100'} >
           <Typography component="span" color="primary">
									<Box component="h3" style={{margin: '0',fontWeight: 500,
      fontSize: fontOptions.size.medium,
      letterSpacing: '1px',
      color: '#4C8BF5'}} >
										All H2 Tags({h2Tags.length})
									</Box>
								</Typography>
           </Box>
           </Grid>
          <Grid item xs={3} style={{paddingTop: '10px', marginBottom: '10px', marginLeft:'200px'}} >
           <Box width={'100'} justifyContent='flex-end' alignSelf={'flex-end'} style={{border:'1 px solid', marginLeft:'50px'}} >
           <Grid container direction='row' spacing={2}>
          {h2Tags.map((item,index)=>(
              <Grid item >
            <Box
          bgcolor="#2E7FF824"
          borderRadius="14px"
          padding="5px"
          marginTop='5px'
          display={'flex'}
          flexDirection='row'
          
        >
        <Box>
        <Typography className={classes.tstudent}>{item}</Typography>

          
        </Box>
        <Box>
          <IconButton  size='small' onClick={()=>{
            // handleDlete(item._id)
          }}>
            <CloseRounded fontSize='small'/>
          </IconButton>
        </Box>
        </Box>
        
      </Grid>
          ))}
          </Grid>
           </Box>
           </Grid>  
                      <Grid item>
                        <Typography>
                            H3 Tags:
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="h3 Tags"
                        placeholder="h3 Tags"
                        value={h3Tag}
                        onChange={(e) => setH3Tag(e.target.value)}
                        multiline
                        rows={1}
                        style={{width:"550px"}}
                        variant="outlined"
                />  
                <Tooltip title="Add Tax">
                        <IconButton onClick={addh3Tags}>
                          <AddCircleIcon fontSize='large' color='primary'/>
                        </IconButton>

                        </Tooltip>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
           <Box width={'100'} >
           <Typography component="span" color="primary">
									<Box component="h3" style={{margin: '0',fontWeight: 500,
      fontSize: fontOptions.size.medium,
      letterSpacing: '1px',
      color: '#4C8BF5'}} >
										All H3 Tags({h3Tags.length})
									</Box>
								</Typography>
           </Box>
           </Grid>
          <Grid item xs={3} style={{paddingTop: '10px', marginBottom: '10px', marginLeft:'200px'}} >
           <Box width={'100'} justifyContent='flex-end' alignSelf={'flex-end'} style={{border:'1 px solid', marginLeft:'50px'}} >
           <Grid container direction='row' spacing={2}>
          {h3Tags.map((item,index)=>(
              <Grid item >
            <Box
          bgcolor="#2E7FF824"
          borderRadius="14px"
          padding="5px"
          marginTop='5px'
          display={'flex'}
          flexDirection='row'
          
        >
        <Box>
        <Typography className={classes.tstudent}>{item}</Typography>

          
        </Box>
        <Box>
          <IconButton  size='small' onClick={()=>{
            // handleDlete(item._id)
          }}>
            <CloseRounded fontSize='small'/>
          </IconButton>
        </Box>
        </Box>
        
      </Grid>
          ))}
          </Grid>
           </Box>
           </Grid>  
                      <Grid item>
                        <Typography>
                            H4 Tags:
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="h4 Tags"
                        placeholder="h4 Tags"
                        value={h4Tag}
                        onChange={(e) => setH4Tag(e.target.value)}
                        multiline
                        rows={1}
                        style={{width:"550px"}}
                        variant="outlined"
                />  
                <Tooltip title="Add Tax">
                        <IconButton onClick={addh4Tags}>
                          <AddCircleIcon fontSize='large' color='primary'/>
                        </IconButton>

                        </Tooltip>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
           <Box width={'100'} >
           <Typography component="span" color="primary">
									<Box component="h3" style={{margin: '0',fontWeight: 500,
      fontSize: fontOptions.size.medium,
      letterSpacing: '1px',
      color: '#4C8BF5'}} >
										All H4 Tags({h4Tags.length})
									</Box>
								</Typography>
           </Box>
           </Grid>
          <Grid item xs={3} style={{paddingTop: '10px', marginBottom: '10px', marginLeft:'200px'}} >
           <Box width={'100'} justifyContent='flex-end' alignSelf={'flex-end'} style={{border:'1 px solid', marginLeft:'50px'}} >
           <Grid container direction='row' spacing={2}>
          {h4Tags.map((item,index)=>(
              <Grid item >
            <Box
          bgcolor="#2E7FF824"
          borderRadius="14px"
          padding="5px"
          marginTop='5px'
          display={'flex'}
          flexDirection='row'
          
        >
        <Box>
        <Typography className={classes.tstudent}>{item}</Typography>

          
        </Box>
        <Box>
          <IconButton  size='small' onClick={()=>{
            // handleDlete(item._id)
          }}>
            <CloseRounded fontSize='small'/>
          </IconButton>
        </Box>
        </Box>
        
      </Grid>
          ))}
          </Grid>
           </Box>
           </Grid>  
                      <Grid item>
                        <Typography>
                            H5 Tags:
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="h5 Tags"
                        placeholder="h5 Tags"
                        value={h5Tag}
                        onChange={(e) => setH5Tag(e.target.value)}
                        multiline
                        rows={1}
                        style={{width:"550px"}}
                        variant="outlined"
                />  
                <Tooltip title="Add Tax">
                        <IconButton onClick={addh5Tags}>
                          <AddCircleIcon fontSize='large' color='primary'/>
                        </IconButton>

                        </Tooltip>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
           <Box width={'100'} >
           <Typography component="span" color="primary">
									<Box component="h3" style={{margin: '0',fontWeight: 500,
      fontSize: fontOptions.size.medium,
      letterSpacing: '1px',
      color: '#4C8BF5'}} >
										All H5 Tags({h5Tags.length})
									</Box>
								</Typography>
           </Box>
           </Grid>
          <Grid item xs={3} style={{paddingTop: '10px', marginBottom: '10px', marginLeft:'200px'}} >
           <Box width={'100'} justifyContent='flex-end' alignSelf={'flex-end'} style={{border:'1 px solid', marginLeft:'50px'}} >
           <Grid container direction='row' spacing={2}>
          {h5Tags.map((item,index)=>(
              <Grid item >
            <Box
          bgcolor="#2E7FF824"
          borderRadius="14px"
          padding="5px"
          marginTop='5px'
          display={'flex'}
          flexDirection='row'
          
        >
        <Box>
        <Typography className={classes.tstudent}>{item}</Typography>

          
        </Box>
        <Box>
          <IconButton  size='small' onClick={()=>{
            // handleDlete(item._id)
          }}>
            <CloseRounded fontSize='small'/>
          </IconButton>
        </Box>
        </Box>
        
      </Grid>
          ))}
          </Grid>
           </Box>
           </Grid>  

                      <Grid item>
                        <Typography>
                            H6 Tags:
                        </Typography>
                      <TextField
                        id="outlined-textarea"
                        label="h6 Tags"
                        placeholder="h6 Tags"
                        value={h6Tag}
                        onChange={(e) => setH6Tag(e.target.value)}
                        multiline
                        rows={1}
                        style={{width:"550px"}}
                        variant="outlined"
                />  
                <Tooltip title="Add Tax">
                        <IconButton onClick={addh6Tags}>
                          <AddCircleIcon fontSize='large' color='primary'/>
                        </IconButton>

                        </Tooltip>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
           <Box width={'100'} >
           <Typography component="span" color="primary">
									<Box component="h3" style={{margin: '0',fontWeight: 500,
      fontSize: fontOptions.size.medium,
      letterSpacing: '1px',
      color: '#4C8BF5'}} >
										All H6 Tags({h6Tags.length})
									</Box>
								</Typography>
           </Box>
           </Grid>
          <Grid item xs={3} style={{paddingTop: '10px', marginBottom: '10px', marginLeft:'200px'}} >
           <Box width={'100'} justifyContent='flex-end' alignSelf={'flex-end'} style={{border:'1 px solid', marginLeft:'50px'}} >
           <Grid container direction='row' spacing={2}>
          {h6Tags.map((item,index)=>(
              <Grid item >
            <Box
          bgcolor="#2E7FF824"
          borderRadius="14px"
          padding="5px"
          marginTop='5px'
          display={'flex'}
          flexDirection='row'
          
        >
        <Box>
        <Typography className={classes.tstudent}>{item}</Typography>

          
        </Box>
        <Box>
          <IconButton  size='small' onClick={()=>{
            // handleDlete(item._id)
          }}>
            <CloseRounded fontSize='small'/>
          </IconButton>
        </Box>
        </Box>
        
      </Grid>
          ))}
          </Grid> */}
           {/* </Box> */}
           {/* </Grid>   */}





                    </Box>
                </Grid>
                <Button variant='contained' color='primary' onClick={()=>saveTags()}>
                Save
          </Button>
            </Container>
            </MiniDrawer>
            
        </div>
    );
}

export default withRouter(SeoForPage)
