import React, { FunctionComponent, useState, useEffect } from "react";
import {
    Box,
	Checkbox,
	Container,
	CssBaseline,
	Grid,
	IconButton,
	makeStyles,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
    Button
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import MiniDrawer from "../../../common/components/SideDrawer";
import { fontOptions } from "../../../../theme";
import { RouteComponentProps, withRouter } from "react-router";
import { getBatchFee, getCouresFee, postBatchFee } from "../../../../api/dashboard";
import { FetchBatchOffer } from "../../interfaces";
import { currencies } from "../../../common/constants/currency";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles({
	title: {
		fontSize: fontOptions.size.large
	},
});

interface Props extends RouteComponentProps {
   
}

const CMPFeeUpdate: FunctionComponent<Props> = ({location, history}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar()

    const [pricingSet, setPricingSet] = useState<FetchBatchOffer[]>([]);
    const [pricingCSet, setPricingCSet] = useState<FetchBatchOffer[]>([]);
    const [price1, setPrice1] = useState('')
    const [price2, setPrice2] = useState('')
    const [pricef1, setPricef1] = useState('')
    const [pricef2, setPricef2] = useState('')
    const [activeCountryIndex, setActiveCountryIndex] = useState(16);
    const [activeFCountryIndex, setActiveFCountryIndex] = useState(36);
    const [selectedOffer, setSelectedOffers] = useState<string[]>([])

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const courseId = params.get('courseId');
        const batchId = params.get('batchId');
        if(courseId && batchId) {
            fetchFeeDetail(courseId, batchId)
        }
    }, []);

    const fetchFeeDetail = async (courseId: string, batchId: string) => {
        const pbPricing = await getBatchFee(courseId, batchId)
        const pcPricing = await getCouresFee(courseId)
		setPricingSet(pbPricing)
        setPricingCSet(pcPricing)
        const psIndex1 = pbPricing.findIndex(ps => ps.title === 'Whole Package')
        const psIndex2 = pbPricing.findIndex(ps => ps.title === 'Self Paced')

        const allPricingSelect = [...selectedOffer]

        if(psIndex1 > -1) {
          allPricingSelect.push('Whole Package')
          setPrice1(String(pbPricing[psIndex1].salePrice))
          setPricef1(String(pbPricing[psIndex1].foreignSalePrice))
          setActiveCountryIndex(currencies.findIndex(cs => cs.code === pbPricing[psIndex1].localCurrency))
        }
        
        if(psIndex2 > -1) {
          allPricingSelect.push('Self Paced')
          setPrice2(String(pbPricing[psIndex2].salePrice))
          setPricef2(String(pbPricing[psIndex2].foreignSalePrice))
          setActiveCountryIndex(currencies.findIndex(cs => cs.code === pbPricing[psIndex2].localCurrency))
        }

        setSelectedOffers(allPricingSelect)
    }

    const savePrice = async () => {
        if(pricingSet && pricingSet[0] && pricingSet[0].title !== 'Self Paced' && price1 === '') {
            enqueueSnackbar('Please Enter Local Price for Whole Package', { variant: "warning" })
        } else if(pricingSet && pricingSet[0] && pricingSet[0].title === 'Self Paced' && price2 === '') {
            enqueueSnackbar('Please Enter Local Price for Self Paced', { variant: "warning" })
        } else if(pricingSet && pricingSet[0] && pricingSet[0].title !== 'Self Paced' && pricef1 === '') {
            enqueueSnackbar('Please Enter Foreign Price for Whole Package', { variant: "warning" })
        } else if(pricingSet && pricingSet[0] && pricingSet[0].title === 'Self Paced' && pricef2 === '') {
            enqueueSnackbar('Please Enter Foreign Price for Self Paced', { variant: "warning" })
        } else if(pricingSet && pricingSet[0] && pricingSet[0].title !== 'Self Paced' && price1 && (isNaN(Number(price1)))) {
            enqueueSnackbar('Invalid Local Price for Whole Package', { variant: "warning" })
        } else if(pricingSet && pricingSet[0] && pricingSet[0].title === 'Self Paced' && price2 && (isNaN(Number(price2)))) {
            enqueueSnackbar('Invalid Local Price for Self Paced', { variant: "warning" })
        } else if(pricingSet && pricingSet[0] && pricingSet[0].title !== 'Self Paced' && pricef1 && (isNaN(Number(pricef1)))) {
            enqueueSnackbar('Invalid Foreign Price for Whole Package', { variant: "warning" })
        } else if(pricingSet && pricingSet[0] && pricingSet[0].title === 'Self Paced' && pricef2 && (isNaN(Number(pricef2)))) {
            enqueueSnackbar('Invalid Foreign Price for Self Paced', { variant: "warning" })
        } else if(pricingSet && pricingSet[0] && pricingSet[0].title !== 'Self Paced' && price1 && (Number(price1) < 0)) {
            enqueueSnackbar('Local Price for Whole Package Should be greater than or equal to Zero', { variant: "warning" })
        } else if(pricingSet && pricingSet[0] && pricingSet[0].title === 'Self Paced' && price2 && (Number(price2) < 0)) {
            enqueueSnackbar('Local Price for Self Paced Should be greater than or equal to Zero', { variant: "warning" })
        } else if(pricingSet && pricingSet[0] && pricingSet[0].title !== 'Self Paced' && pricef1 && (Number(pricef1) < 0)) {
            enqueueSnackbar('Foreign Price for Whole Package Should be greater than or equal to Zero', { variant: "warning" })
        } else if(pricingSet && pricingSet[0] && pricingSet[0].title === 'Self Paced' && pricef2 && (Number(pricef2) < 0)) {
            enqueueSnackbar('Foreign Price for Self Paced Should be greater than or equal to Zero', { variant: "warning" })
        } else if(pricingCSet.length > 0 && pricingCSet[0].localCurrency !== currencies[activeCountryIndex].code) {
            enqueueSnackbar(`Local Currency for all Batches should be same in a course. You have selected ${pricingCSet[0].localCurrency} for other batches`, { variant: "warning" })
        } else {
            const params = new URLSearchParams(location.search);
            const id = params.get('courseId'); 
            const batchId = params.get('batchId');
      
            if(id && batchId) {
                const bodyData: FetchBatchOffer[] = []
                if(pricingSet && pricingSet[0] && pricingSet[0].title === 'Self Paced') {
                    let indiOffer: FetchBatchOffer = {
                        courseId: id,
                        batchId : batchId,
                        title: 'Self Paced',
                        salePrice : Number(Number(price2).toFixed(2)),
                        foreignSalePrice: Number(Number(pricef2).toFixed(2)),
                        localCurrency: currencies[activeCountryIndex].code,
                        foreignCurrency: currencies[activeFCountryIndex].code
                    }
        
                    const psIndex = pricingSet.findIndex(ps => ps.title === 'Self Paced')
        
                    if(psIndex > -1) {
                        indiOffer = {...indiOffer, ...{_id: pricingSet[psIndex]._id as string}}
                    }
        
                    bodyData.push(indiOffer)
                } else if(pricingSet && pricingSet[0] && pricingSet[0].title !== 'Self Paced') {
                    let indiOffer: FetchBatchOffer = {
                        courseId: id,
                        batchId : batchId,
                        title: 'Whole Package',
                        salePrice : Number(Number(price1).toFixed(2)),
                        foreignSalePrice: Number(Number(pricef1).toFixed(2)),
                        localCurrency: currencies[activeCountryIndex].code,
                        foreignCurrency: currencies[activeFCountryIndex].code
                    }
        
                    const psIndex = pricingSet.findIndex(ps => ps.title === 'Whole Package')
        
                    if(psIndex > -1) {
                        indiOffer = {...indiOffer, ...{_id: pricingSet[psIndex]._id as string}}
                    }
        
                    bodyData.push(indiOffer)
                }
      
                const res = await postBatchFee(bodyData)
                enqueueSnackbar('Course Batch Fee Updated', { variant: "success" })
                history.push(`/cmpreports/batches?courseId=${id}`)
            }
        }
    }

    return(
        <div>
            <CssBaseline />
            <MiniDrawer>
                <Container maxWidth="lg">
                    <Box
                        bgcolor="#4C8BF5"
                        padding="20px 30px"
                        marginBottom="30px"
                        position="relative"
                        borderRadius="5px"
                        color='#fff'
                    >
                        <Grid item container>
                            <Grid item sm={8}>
                                <Box style={{height: '100%'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography className={classes.title}>
                                                Course Marketplace Batch Fee
                                            </Typography>
                                            <Typography>
                                                View and Update Batch Fee
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    
                    <Grid container style={{marginTop: '15px'}}>
                        {(pricingSet && pricingSet[0] && pricingSet[0].title === 'Self Paced') &&
                            <Grid item xs={12} style={{marginBottom: '15px'}}>
                                <Box width="100%" border="1px solid gray" borderRadius="5px" padding="20px 15px">
                                <Grid container spacing={3}
                                    direction="row"
                                    alignItems="center"
                                    justify="center"
                                    style={{height: '100%'}}
                                >
                                    <Grid item xs={12}>
                                    <Box display="flex" justifyContent="flex-start">
                                        <Box>
                                        <Checkbox icon={<RadioButtonUncheckedIcon style={{color: '#4C8BF5'}} />} checkedIcon={<CheckCircleIcon style={{color: '#4C8BF5'}} />} checked style={{padding: "0px", margin: '0px'}} 
                                        />
                                        </Box>
                                        <Box>
                                        <Typography style={{marginLeft: '10px', color: '#3D3D3D', fontSize: fontOptions.size.smallPlus, fontWeight: fontOptions.weight.bold}}>Self Paced</Typography>
                                        </Box>
                                    </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>For Local Students</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                        <Select
                                            value={activeCountryIndex}
                                            onChange={(
                                            e: React.ChangeEvent<{ value: unknown }>
                                            ) => setActiveCountryIndex(e.target.value as number)}
                                            style={{width: '100%', marginTop: '5px'}}
                                            renderValue={(index) => {
                                            const i = index as number;
                                            return (
                                                <Tooltip title={`${currencies[i].code} - ${currencies[i].expansion}`}>
                                                    <Typography color='textSecondary' component='caption'>
                                                    {currencies[i].code} - {currencies[i].expansion}
                                                    </Typography>
                                                </Tooltip>
                                            )
                                            }}
                                        >
                                            {currencies.map((el, index) => (
                                            <MenuItem key={index} value={index}>
                                                <Typography component='caption'>
                                                    {el.code} - {el.expansion}
                                                </Typography>
                                            </MenuItem>
                                            ))}
                                        </Select>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                        <TextField value={price2} style={{width: '100%', marginTop: '10px'}} 
                                            onChange={(e) => {
                                            setPrice2(e.target.value as string)
                                            }} placeholder="Enter Local Pricing..."
                                        />
                                        </Grid>
                                    </Grid>

                                    <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '15px'}}>For Foreign Students</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                        <Select
                                            value={activeFCountryIndex}
                                            readOnly
                                            style={{width: '100%', marginTop: '5px'}}
                                            renderValue={(index) => {
                                            const i = index as number;
                                            return (
                                                <Tooltip title={`${currencies[i].code} - ${currencies[i].expansion}`}>
                                                    <Typography color='textSecondary' component='caption'>
                                                    {currencies[i].code} - {currencies[i].expansion}
                                                    </Typography>
                                                </Tooltip>
                                            )
                                            }}
                                        >
                                            {currencies.map((el, index) => (
                                            <MenuItem key={index} value={index}>
                                                <Typography component='caption'>
                                                    {el.code} - {el.expansion}
                                                </Typography>
                                            </MenuItem>
                                            ))}
                                        </Select>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                        <TextField value={pricef2} style={{width: '100%', marginTop: '10px'}} 
                                            onChange={(e) => {
                                            setPricef2(e.target.value as string)
                                            }} placeholder="Enter foreign Pricing..."
                                        />
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                </Grid>
                                </Box>
                            </Grid>
                        }

                        {(pricingSet && pricingSet[0] && pricingSet[0].title !== 'Self Paced') &&
                            <Grid item xs={12} style={{marginBottom: '15px'}}>
                                <Box width="100%" border="1px solid gray" borderRadius="5px" padding="20px 15px">
                                <Grid container spacing={3}
                                    direction="row"
                                    alignItems="center"
                                    justify="center"
                                    style={{height: '100%'}}
                                >
                                    <Grid item xs={12}>
                                    <Box display="flex" justifyContent="flex-start">
                                        <Box>
                                        <Checkbox icon={<RadioButtonUncheckedIcon style={{color: '#4C8BF5'}} />} checkedIcon={<CheckCircleIcon style={{color: '#4C8BF5'}} />} checked style={{padding: "0px", margin: '0px'}} 
                                        />
                                        </Box>
                                        <Box>
                                        <Typography style={{marginLeft: '10px', color: '#3D3D3D', fontSize: fontOptions.size.smallPlus, fontWeight: fontOptions.weight.bold}}>Whole Package</Typography>
                                        </Box>
                                    </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>For Local Students</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                        <Select
                                            value={activeCountryIndex}
                                            onChange={(
                                            e: React.ChangeEvent<{ value: unknown }>
                                            ) => setActiveCountryIndex(e.target.value as number)}
                                            style={{width: '100%', marginTop: '5px'}}
                                            renderValue={(index) => {
                                            const i = index as number;
                                            return (
                                                <Tooltip title={`${currencies[i].code} - ${currencies[i].expansion}`}>
                                                    <Typography color='textSecondary' component='caption'>
                                                    {currencies[i].code} - {currencies[i].expansion}
                                                    </Typography>
                                                </Tooltip>
                                            )
                                            }}
                                        >
                                            {currencies.map((el, index) => (
                                            <MenuItem key={index} value={index}>
                                                <Typography component='caption'>
                                                    {el.code} - {el.expansion}
                                                </Typography>
                                            </MenuItem>
                                            ))}
                                        </Select>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                        <TextField value={price1} style={{width: '100%', marginTop: '10px'}} 
                                            onChange={(e) => {
                                            setPrice1(e.target.value as string)
                                            }} placeholder="Enter Local Pricing..."
                                        />
                                        </Grid>
                                    </Grid>

                                    <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '15px'}}>For Foreign Students</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                        <Select
                                            value={activeFCountryIndex}
                                            readOnly
                                            style={{width: '100%', marginTop: '5px'}}
                                            renderValue={(index) => {
                                            const i = index as number;
                                            return (
                                                <Tooltip title={`${currencies[i].code} - ${currencies[i].expansion}`}>
                                                    <Typography color='textSecondary' component='caption'>
                                                    {currencies[i].code} - {currencies[i].expansion}
                                                    </Typography>
                                                </Tooltip>
                                            )
                                            }}
                                        >
                                            {currencies.map((el, index) => (
                                            <MenuItem key={index} value={index}>
                                                <Typography component='caption'>
                                                    {el.code} - {el.expansion}
                                                </Typography>
                                            </MenuItem>
                                            ))}
                                        </Select>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                        <TextField value={pricef1} style={{width: '100%', marginTop: '10px'}} 
                                            onChange={(e) => {
                                            setPricef1(e.target.value as string)
                                            }} placeholder="Enter foreign Pricing..."
                                        />
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                </Grid>
                                </Box>
                            </Grid>
                        }

                        <Grid item xs={12} style={{marginBottom: '15px'}}>
                            <Button style={{float:'right'}} disableElevation color="primary" variant="contained" onClick={savePrice}>Save</Button>
                            <Button style={{float:'right', marginRight: '15px'}} disableElevation color="primary" variant="outlined"
                                onClick={() => {
                                    const params = new URLSearchParams(location.search);
                                    const courseId = params.get('courseId');
                                    history.push(`/cmpreports/batches?courseId=${courseId}`)
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </MiniDrawer>
        </div>
    )
}

export default withRouter(CMPFeeUpdate);