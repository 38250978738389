import axios from 'axios'
import { eduAcademyContent } from './interfaces'

const BASE_URL = process.env.REACT_APP_API + "admin/"
const EDUMATICA_ACADEMY = BASE_URL + "edumaticaAcademy"
const EDUMATICA_ACADEMY_HEADERS = BASE_URL + "edumaticaAcademyHeaders"
const EDUMATICA_ACADEMY_ORDER = BASE_URL + "edumaticaAcademyOrder"

export const addEduAcademyContent = async (eduAcademyContent: eduAcademyContent) => {
    await axios.post(EDUMATICA_ACADEMY, {academyContent: eduAcademyContent})
}

export const getHeaders = async () => {
    const res = await axios.get<{data: {header: string, _id: string, orderNo: number}[]}>(EDUMATICA_ACADEMY_HEADERS)
    return res.data.data
}

export const getEduAcademyContent = async (_id: string) => {
    const res = await axios.get<{content: eduAcademyContent}>(`${EDUMATICA_ACADEMY}/${_id}`)
    return res.data
}

export const updateEduAcademyContent = async (eduAcademyContent: eduAcademyContent) => {
    await axios.patch(EDUMATICA_ACADEMY, {academyContent: eduAcademyContent})
}

export const reorderAcademyContents = async (reorderReq: {_id: string, orderNo: number}[]) => {
    await axios.post(EDUMATICA_ACADEMY_ORDER, {reorderReq})
}

export const deleteAcademyContent = async (_id: string) => {
    await axios.delete(EDUMATICA_ACADEMY, {params: {contentId: _id}})
}