import React, { useState, useEffect, FunctionComponent } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  lighten,
} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import {
  Box,
  Container,
  Grid,
  Button,
  Input,
  Typography,
  FormControlLabel,
  IconButton,
  CssBaseline,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  InputAdornment,
  Toolbar,
  Radio,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { RouteComponentProps, Redirect } from "react-router";
import { connect, useDispatch } from "react-redux";

import MiniDrawer from "../../../common/components/SideDrawer";
import { fontOptions } from "../../../common/theme";
import {
  EMAIL_PATTERN,
  PHONE_PATTERN,
} from "../../../common/validations/patterns";
import {
  createAdminUser,
  getAdminData,
  getAdmins,
  getRoles,
  getUserDetails,
  getUsers,
  updateEnroll,
} from "../../../../api/dashboard";
import {
  AccountBalance,
  ColorLens,
  Block,
  Clear,
  CreditCard,
  Delete,
  Edit,
  Receipt,
  Search,
  Visibility,
  VisibilityOff,
  ViewList,
  LockOpen,
} from "@material-ui/icons";
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import useStyles from "./styles";
import { User, CustomUser } from "../../../common/contracts/dashboard";
import Datagrids, { datagridCellExpand } from "../../components/dataGrid";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import {
  setCurrentUser,
  setCustomersList,
  setModalDatas,
} from "../../../redux/actions/adminActions";
import { USER_TYPES, USER_TYPE_VALUES } from "../../../../utilities/constants";
import { RootState } from "../../../redux/store";
import {
  MASTER_PERMISSIONS,
  USER_ROLES,
} from "../../../../utilities/constants";
import Datepickers from "../../components/datepickers";
import ReviewModal from "../../../finance/components/modals/reviewModal";
import { UserDataTable } from "../../components/UserDataTable";
import { compareAsc } from "date-fns";
import AdbIcon from "@material-ui/icons/Adb";
import SdStorageIcon from '@material-ui/icons/SdStorage';
interface Props extends RouteComponentProps {
  customersList: CustomUser[];
  User: CustomUser;
}
interface EnhancedTableToolbarProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setFocused: React.Dispatch<React.SetStateAction<boolean>>;
  maxLength: number;
  placeholder: string;
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      height: "100%",
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

const EnhancedTableToolbar: FunctionComponent<EnhancedTableToolbarProps> = ({
  searchText,
  setSearchText,
  setFocused,
  maxLength,
  placeholder,
}) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft="auto"
      >
        <Box margin="15px 10px 10px 25px">
          <FormControl fullWidth margin="normal">
            <Input
              name="search"
              inputProps={{ inputMode: "search", maxLength: maxLength }}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              placeholder={placeholder}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              endAdornment={
                searchText.trim() !== "" ? (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setSearchText("")}>
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <IconButton disabled size="small">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </FormControl>
          {searchText.trim() !== "" && (
            <Typography
              style={{
                marginTop: "5px",
                fontSize: fontOptions.size.small,
                color: "#666666",
              }}
            >
              Filtered Table using Keyword '{searchText}'
            </Typography>
          )}
        </Box>
      </Box>
    </Toolbar>
  );
};

const SearchCustomer: FunctionComponent<Props> = ({
  history,
  customersList,
  User,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [redirectTo, setRedirectTo] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  // const [usersList, setUsersList] = useState<any[]>([]);	//crosscheck
  const [selected, setSelected] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [focused, setFocused] = useState(false);
  const [searchBySelection, setSearchBySelection] = useState("mobile");
  console.log("searchBySelection",searchBySelection)
  const [searchForSelection, setSearchForSelection] = useState(
    USER_TYPES.TUTOR
  );
  const [selectedUserType, setSelectedUserType] = useState(
    USER_TYPE_VALUES.TUTOR 
  );

  console.log("selectedUserType",selectedUserType)
  const [fromDate, setFromDate] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [toDate, setToDate] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 0, 0))
  );
  const [fromNotiDate, setFromNotiDate] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [toNotiDate, setToNotiDate] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 0, 0))
  );

  const [modalType, setModalType] = useState<string | null>(null);
  const [modalData, setModalData] = useState<any[]>(customersList);
  console.log("modalData--",modalData)
  const [openModal, setOpenModal] = useState<boolean>(false);

  const styles = useStyles();
  const dispatch = useDispatch();

  var allowedPermissions: string[] = [];
  var loginUserData = JSON.parse(localStorage.getItem("loginUserData") || "{}");
  var loginUserType = "";
  if (
    loginUserData &&
    loginUserData.adminRole &&
    loginUserData.adminRole.permissions
  ) {
    allowedPermissions = loginUserData.adminRole.permissions;
    loginUserType = loginUserData.adminRole.name;
  }

  useEffect(() => {
    getLoggedInUserData();
    setSelectedUserType(getSelectedUserType());
    setSearchForSelection(getSelectedSearchFor());
  }, []);

  const getSelectedUserType = () => {
    if (customersList.length > 0 && User && User.userType) {
      return User.userType;
    } else {
      return selectedUserType;
    }
  };

  const getSelectedSearchFor = () => {
    if (customersList.length > 0 && User && User.userType) {
      switch (User.userType) {
        case USER_TYPE_VALUES.TUTOR:
          return USER_TYPES.TUTOR;
        case USER_TYPE_VALUES.ORG:
          return USER_TYPES.ORG;
        case USER_TYPE_VALUES.STUDENT:
          return USER_TYPES.STUDENT;
        case USER_TYPE_VALUES.PARENT:
          return USER_TYPES.PARENT;
        case USER_TYPE_VALUES.ORG_TUTOR:
          return USER_TYPES.TUTOR;
        default:
          return USER_TYPES.TUTOR;
      }
    } else {
      return searchForSelection;
    }
  };

  const getLoggedInUserData = async () => {
    // setLoading(true);
    // try {
    // 	const adminUserData = await getAdminData();
    // 	dispatch(setLoginUserData(JSON.stringify(adminUserData)));
    // 	setLoading(false);
    // 	if (adminUserData.isItDefaultPassword) {
    // 		localStorage.setItem('isItDefaultPassword', JSON.stringify(adminUserData.isItDefaultPassword));
    // 		setRedirectTo('/set-password');
    // 	}
    // } catch (error) {
    // 	setLoading(false);
    // 	enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
    // }
    if (
      JSON.parse(localStorage.getItem("isItDefaultPassword") || "{}") == true
    ) {
      setRedirectTo("/set-password");
    }
    if (
      JSON.parse(localStorage.getItem("isItDefaultPassword") || "{}") == true
    ) {
      setRedirectTo("/set-password");
    }
    var allowedPermissions: string[] = [];
    var loginUserData = JSON.parse(
      localStorage.getItem("loginUserData") || "{}"
    );
    var loginUserType = "";
    if (
      loginUserData &&
      loginUserData.adminRole &&
      loginUserData.adminRole.permissions
    ) {
      allowedPermissions = loginUserData.adminRole.permissions;
      loginUserType = loginUserData.adminRole.name;
    }
    var hasEligibility =
      loginUserType == USER_ROLES.super ||
      allowedPermissions.includes(MASTER_PERMISSIONS.getUsers);
    if (!hasEligibility) {
      enqueueSnackbar("You don't have access to this route", {
        variant: "warning",
      });
      setRedirectTo("/dashboard");
    }
  };
  const handleSearchAll = async () => {
    setLoading(true);
    try {
      var data = [];
    
      var obj: any = {};
      if (searchBySelection == "mobile" && PHONE_PATTERN.test(searchText)) {
        obj = getObj();
        data = await getUsers(obj);
      } else if (
        searchBySelection == "email" &&
        EMAIL_PATTERN.test(searchText)
      ) {
        obj = getObj();
        data = await getUsers(obj);
      } else if (searchBySelection == "name" && searchText != "") {
        obj = getObj();
        data = await getUsers(obj);
      } else {
        if (searchBySelection) {
          switch (searchBySelection) {
            case "mobile":
              enqueueSnackbar("Please enter valid mobile number", {
                variant: "warning",
              });
              return;
            default:
              enqueueSnackbar("Please enter valid " + searchBySelection, {
                variant: "warning",
              });
              return;
          }
        }
      }
      enqueueSnackbar(data.length + " record(s) fetched", {
        variant: "success",
      });
      // setUsersList(data);
      dispatch(setCustomersList(data));
      setModalType("userDetails");
      setModalData(data);
      dispatch(setModalDatas(data));

      console.log("data--",data)
      setOpenModal(true);
      setLoading(false);
      
    } catch (error) {
      enqueueSnackbar(error.response?.data.message, { variant: "warning" });
      setLoading(false);
      if (
        error.response?.status === 401 &&
        error.response?.data.message !== "TokenExpiredError"
      ) {
        setRedirectTo("/login");
      }
    }
  };
  // dispatch(setModalDatas(modalData));

  const getObj = () => {
    var obj: any = {
      userType:
        selectedUserType === USER_TYPE_VALUES.ORG_TUTOR
          ? USER_TYPE_VALUES.TUTOR
          : selectedUserType,
    };
    var commonObj = {
      $regex: searchText,
      $options: "i",
    };
    var query: any = {};
    if (searchBySelection == "mobile") {
      query["mobileNo"] = commonObj;
    } else if (searchBySelection == "name") {
      switch (selectedUserType) {
        case USER_TYPE_VALUES.TUTOR:
          query[USER_TYPES.TUTOR] = commonObj;
          break;
        case USER_TYPE_VALUES.ORG_TUTOR:
          query[USER_TYPES.TUTOR] = commonObj;
          break;
        case USER_TYPE_VALUES.ORG:
          query[USER_TYPES.ORG] = commonObj;
          break;
        case USER_TYPE_VALUES.STUDENT:
          query[USER_TYPES.STUDENT] = commonObj;
          break;
        case USER_TYPE_VALUES.PARENT:
          query[USER_TYPES.PARENT] = commonObj;
          break;
        default:
          query[USER_TYPES.TUTOR] = commonObj;
          break;
      }
    } else {
      query["emailId"] = commonObj;
    }
    obj["query"] = query;

    var mObj = serialize(obj);
    return mObj;
  };

  const serialize = (obj: any, prefix?: any) => {
    var str: any = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === "object"
            ? serialize(v, k)
            : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
    return str.join("&");
  };

  function createData(user: any) {
    let name = user[USER_TYPES.TUTOR];
    if (customersList.length > 0) {
      let fRecord: User = customersList[0];
      if (fRecord["tutorName"]) {
        name = user[USER_TYPES.TUTOR];
      } else if (fRecord["organizationName"]) {
        name = user[USER_TYPES.ORG];
      } else if (fRecord["studentName"]) {
        name = user[USER_TYPES.STUDENT];
      } else if (fRecord["parentName"]) {
        name = user[USER_TYPES.PARENT];
      }else if (fRecord["orgTutorName"]) {
        name = user[USER_TYPES.TUTOR];
      }
    }
    return {
      // name: (user[searchForSelection] || user[USER_TYPES.ORG] || user[USER_TYPES.STUDENT] || user[USER_TYPES.PARENT]),
      name: name,
      mobileNo: user.mobileNo,
      emailId: user.emailId,
      _id: user._id,
      userType:
        selectedUserType === USER_TYPE_VALUES.ORG_TUTOR
          ? USER_TYPE_VALUES.TUTOR
          : selectedUserType,
      ownerId: user.ownerId,
    };
  }

  const rows = customersList.map((user) => createData(user));
  console.log("rows",rows)

  const gridRows = rows.map((row, index) => {
    return {
      id: index + 1,
      name: row.name,
      mobileNo: row.mobileNo,
      emailId: row.emailId,
      userType: row.userType,
    };
  });

  console.log("gridRows",gridRows)

  const handleBlockUser = (row: CustomUser) => {
    console.log(row);
    if (
      allowedPermissions.includes(MASTER_PERMISSIONS.updateUserStatus) ||
      loginUserType == USER_ROLES.super
    ) {
      dispatch(setCurrentUser(row));
      history.push("/block-user");
    } else {
      enqueueSnackbar("You don't have permission on this action", {
        variant: "info",
      });
    }
  };

  const handleLogIn = (row: CustomUser) => {
    console.log(row);
    if (
      allowedPermissions.includes(MASTER_PERMISSIONS.updateUserStatus) ||
      loginUserType == USER_ROLES.super
    ) {
      dispatch(setCurrentUser(row));
      history.push("/log-in-user");
    } else {
      enqueueSnackbar("You don't have permission on this action", {
        variant: "info",
      });
    }
  };

  const handleFileStorage = (row: CustomUser) => {
    console.log(row);
    if (
      allowedPermissions.includes(MASTER_PERMISSIONS.updateUserStatus) ||
      loginUserType == USER_ROLES.super
    ) {
      dispatch(setCurrentUser(row));
      history.push("/file-storage");
    } else {
      enqueueSnackbar("You don't have permission on this action", {
        variant: "info",
      });
    }
  };
  const handleEnrollUpdate = async(row: CustomUser) => {
    console.log("enrollment row-->",row);
    if (
      allowedPermissions.includes(MASTER_PERMISSIONS.updateUserStatus) ||
      loginUserType == USER_ROLES.super
    ) {
      dispatch(setCurrentUser(row));
      const data = await updateEnroll(row?.ownerId);
      console.log("data----",data)
      enqueueSnackbar("Update Successful.", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("You don't have permission on this action", {
        variant: "info",
      });
    }
  };

  const handleBankAccount = (row: CustomUser) => {
    console.log(row);
    if (
      allowedPermissions.includes(MASTER_PERMISSIONS.makerBankChange) ||
      allowedPermissions.includes(MASTER_PERMISSIONS.checkerBankChange) ||
      loginUserType == USER_ROLES.super
    ) {
      dispatch(setCurrentUser(row));
      history.push("/update-bank-account");
    } else {
      enqueueSnackbar("You don't have permission on this action", {
        variant: "info",
      });
    }
  };

  const handleCustomizations = (row: CustomUser) => {
    console.log("row---",row);
    if (
      allowedPermissions.includes(MASTER_PERMISSIONS.makerBankChange) ||
      allowedPermissions.includes(MASTER_PERMISSIONS.checkerBankChange) ||
      loginUserType == USER_ROLES.super
    ) {
      dispatch(setCurrentUser(row));
      history.push("/update-customization");
    } else {
      enqueueSnackbar("You don't have permission on this action", {
        variant: "info",
      });
    }
  };
  const handleInvoiceDetails = (row: CustomUser) => {
    // dispatch(setCurrentUser(row));
    alert("in progress");
  };
  const handleViewDetails = (row: CustomUser) => {
    console.log(row);
    // if (allowedPermissions.includes(MASTER_PERMISSIONS.updateUserStatus) || loginUserType == USER_ROLES.super) {
    dispatch(setCurrentUser(row));
    history.push("/view-customer");
    // }
    // else {
    // 	enqueueSnackbar("You don't have permission on this action", { variant: 'info' });
    // }
  };

  const handleViewPackage = (row: CustomUser) => {
    console.log(row);
    if (
      allowedPermissions.includes(MASTER_PERMISSIONS.packageapproval) ||
      loginUserType == USER_ROLES.super
    ) {
      dispatch(setCurrentUser(row));
      history.push("/view-package");
    } else {
      enqueueSnackbar("You don't have permission on this action", {
        variant: "info",
      });
    }
  };

  const handleViewUsers = (row: CustomUser) => {
    console.log(row);
    // dispatch(setCurrentUser(row))
    history.push({
      pathname: "/viewUsers",
      state: {
        userId: row._id,
        userType: row.userType,
        username: row.name,
      },
    });
  };

  const handleDevApi = (row: CustomUser) => {
    console.log(row);
    // if (allowedPermissions.includes(MASTER_PERMISSIONS.updateUserStatus) || loginUserType == USER_ROLES.super) {
    dispatch(setCurrentUser(row));
    history.push("/addDevApi");
    // }
    // else {
    // 	enqueueSnackbar("You don't have permission on this action", { variant: 'info' });
    // }
  };

  const handlePermission = (row: CustomUser) => {
    console.log(row);
    // if (allowedPermissions.includes(MASTER_PERMISSIONS.updateUserStatus) || loginUserType == USER_ROLES.super) {
    dispatch(setCurrentUser(row));
    history.push("/addPermission");
    // }
    // else {
    // 	enqueueSnackbar("You don't have permission on this action", { variant: 'info' });
    // }
  };

  const getButtonDatas = (userType: string) => {
    const buttonDatas = [
      {
        title: "Block/Unblock",
        action: handleBlockUser,
        icon: <Block />,
      },
      {
        title: "Change Bank Account",
        action: handleBankAccount,
        icon: <AccountBalance />,
      },
      {
        title: "View/Edit Details",
        action: handleViewDetails,
        icon: <Visibility />,
      },
      {
        title: "View/Edit Package",
        action: handleViewPackage,
        icon: <CreditCard />,
      },
      {
        title: "View Customizations",
        action: handleCustomizations,
        icon: <ColorLens />,
      },
      {
        title: "Dev Apis",
        action: handleDevApi,
        icon: <AdbIcon />,
      },
      {
        title: "My Storage Usage",
        action: handleFileStorage,
        icon: <SdStorageIcon />,
      },
      
    ];
    if ( userType === USER_TYPE_VALUES.ORG ||
      userType === USER_TYPE_VALUES.TUTOR ||
      userType === USER_TYPE_VALUES.ORG_TUTOR ||
      userType === USER_TYPE_VALUES.STUDENT ||
      userType === USER_TYPE_VALUES.PARENT) {
      const index = buttonDatas.findIndex(
        (cur) => cur.title === "Change Bank Account"
      );
      if (index > -1) buttonDatas.splice(index, 1);
    }
    if (
      userType === USER_TYPE_VALUES.ORG ||
      userType === USER_TYPE_VALUES.TUTOR ||
      userType === USER_TYPE_VALUES.ORG_TUTOR ||
      userType === USER_TYPE_VALUES.STUDENT ||
      userType === USER_TYPE_VALUES.PARENT
    ) {
      buttonDatas.push({
        title: "View Users",
        action: handleViewUsers,
        icon: <ViewList />,
      });
    }

    if (
      userType === USER_TYPE_VALUES.ORG ||
      userType === USER_TYPE_VALUES.TUTOR ||
      userType === USER_TYPE_VALUES.ORG_TUTOR ||
      userType === USER_TYPE_VALUES.STUDENT ||
      userType === USER_TYPE_VALUES.PARENT
    ) {
      buttonDatas.push({
        title: "Enable/Disable Modules",
        action: handlePermission,
        icon: <LockOpen />,
      });
    }

    if (
      userType === USER_TYPE_VALUES.ORG ||
      userType === USER_TYPE_VALUES.TUTOR ||
      userType === USER_TYPE_VALUES.ORG_TUTOR ||
      userType === USER_TYPE_VALUES.STUDENT ||
      userType === USER_TYPE_VALUES.PARENT
    ) {
      buttonDatas.push({
        title: "Log In Reset",
        action: handleLogIn,
        icon: <Edit />,
      });
    }

    if (
      userType === USER_TYPE_VALUES.ORG
    ) {
      buttonDatas.push({
          title: "Update Student/Tutor Enrollment",
          action: handleEnrollUpdate,
          icon: <SystemUpdateAltIcon />,
      });
    }

    
    return buttonDatas;
  };

  const buttonData = getButtonDatas(selectedUserType);

  const setSelection = (selected: any) => {
    const selectedRow = selected.selectionModel.map((index: string) => {
      const row = rows[Number(index) - 1];
      return row.emailId;
    });

    setSelected(selectedRow);
  };

  const gridColumns: GridColDef[] = [
    { field: "id", headerName: "Sl No.", flex: 0.5 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: datagridCellExpand,
    },
    {
      field: "mobileNo",
      headerName: "Mobile",
      flex: 1,
      renderCell: datagridCellExpand,
    },
    {
      field: "emailId",
      headerName: "Email",
      flex: 1,
      renderCell: datagridCellExpand,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params: GridCellParams) => {
        const selectedRow = {
          id: params.getValue("id") as number,
          emailId: params.getValue("emailId") as string,
          name: params.getValue("name") as string,
        };

        const selectedRowDetails = rows.find((row, index) => {
          return (
            row.emailId === selectedRow.emailId &&
            row.name === selectedRow.name &&
            index === selectedRow.id - 1
          );
        });

        const buttonSet = buttonData.map((button, index) => {
          return (
            <Tooltip key={index} title={button.title}>
              <IconButton
                onClick={() => {
                  button.action(selectedRowDetails as any);
                }}
                size="small"
              >
                {button.icon}
              </IconButton>
            </Tooltip>
          );
        });

        return <div>{buttonSet}</div>;
      },
    },
  ];

  if (redirectTo.length > 0) {
    return <Redirect to={redirectTo} />;
  }

  const handleSearchForSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchForSelection(event.target.value);
    switch (event.target.value) {
      case USER_TYPES.TUTOR:
        setSelectedUserType(USER_TYPE_VALUES.TUTOR);
        break;
      case USER_TYPES.ORG:
        setSelectedUserType(USER_TYPE_VALUES.ORG);
        break;
      case USER_TYPES.STUDENT:
        setSelectedUserType(USER_TYPE_VALUES.STUDENT);
        break;
      case USER_TYPES.PARENT:
        setSelectedUserType(USER_TYPE_VALUES.PARENT);
        break;
      case USER_TYPE_VALUES.ORG_TUTOR:
        setSelectedUserType(USER_TYPE_VALUES.ORG_TUTOR);
        break;
      default:
        setSelectedUserType(USER_TYPE_VALUES.TUTOR);
        break;
    }
    setSearchText("");
  };

  const handleSearchBySelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchBySelection(event.target.value);
    setSearchText("");
  };

  const handleSearchAllDetails = () => {
    // @ts-ignore
    if (!fromDate) {
      enqueueSnackbar("Please enter start date", { variant: "warning" });
      return;
    }
    if (!toDate) {
      enqueueSnackbar("Please enter end date", { variant: "warning" });
      return;
    }
    if (compareAsc(fromDate, toDate) == 1) {
      enqueueSnackbar("End date should be after start date", {
        variant: "warning",
      });
      return;
    }

    getUserDetails(
      selectedUserType,
      toDate,
      fromDate,
      toNotiDate ? toNotiDate : undefined,
      fromNotiDate ? fromNotiDate : undefined
    ).then((userData) => {
      setModalType("userDetails");
      setModalData(userData);
      setOpenModal(true);
      dispatch(setCustomersList(userData));
    });
  };

  return (
    <>
      <CssBaseline />
      <MiniDrawer>
        <div style={{ height: "80vh", width: "100%" }}>
          <Container style={{ width: "100%" }}>
            <Grid container>
              {/* <Grid item className={styles.header}>
								<Typography variant="h5" >Search Customers</Typography>
							</Grid> */}
              <Grid item className={styles.header}>
                <Typography variant="h5">
                  {localStorage.getItem("welcomeMsg") || ""}
                </Typography>
              </Grid>
              <Card
                className={styles.card}
                variant="outlined"
                style={{ width: "100%" }}
              >
                <CardHeader
                  title="Search for"
                  titleTypographyProps={{ varaint: "h5" }}
                />
                <CardContent>
                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchForSelection === USER_TYPES.TUTOR}
                        onChange={handleSearchForSelection}
                        value={USER_TYPES.TUTOR}
                        name={USER_TYPES.TUTOR}
                        color="default"
                      />
                    }
                    label="Tutor"
                  />

                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={
                          searchForSelection === USER_TYPE_VALUES.ORG_TUTOR
                        }
                        onChange={handleSearchForSelection}
                        value={USER_TYPE_VALUES.ORG_TUTOR}
                        name={USER_TYPE_VALUES.ORG_TUTOR}
                        color="default"
                      />
                    }
                    label="Org Tutor"
                  />

                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchForSelection === USER_TYPES.ORG}
                        onChange={handleSearchForSelection}
                        value={USER_TYPES.ORG}
                        name={USER_TYPES.ORG}
                        color="default"
                      />
                    }
                    label="Institute"
                  />
                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchForSelection === USER_TYPES.STUDENT}
                        onChange={handleSearchForSelection}
                        value={USER_TYPES.STUDENT}
                        name={USER_TYPES.STUDENT}
                        color="default"
                      />
                    }
                    label="Students"
                  />
                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchForSelection === USER_TYPES.PARENT}
                        onChange={handleSearchForSelection}
                        value={USER_TYPES.PARENT}
                        name={USER_TYPES.PARENT}
                        color="default"
                      />
                    }
                    label="Guardians"
                  />
                </CardContent>
              </Card>

              <Card
                className={styles.card}
                variant="outlined"
                style={{ width: "100%" }}
              >
                <CardHeader
                  title="Search by"
                  titleTypographyProps={{ varaint: "h5" }}
                />
                <CardContent>
                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchBySelection === "mobile"}
                        onChange={handleSearchBySelection}
                        value="mobile"
                        name="mobile"
                        color="default"
                      />
                    }
                    label="Mobile"
                  />
                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchBySelection === "name"}
                        onChange={handleSearchBySelection}
                        value="name"
                        name="name"
                        color="default"
                      />
                    }
                    label="Name"
                  />
                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchBySelection === "email"}
                        onChange={handleSearchBySelection}
                        value="email"
                        name="email"
                        color="default"
                      />
                    }
                    label="Email"
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{ margin: 10 }}
                    onClick={handleSearchAll}
                    disabled={searchBySelection ? false : true}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{ margin: 10 }}
                    onClick={handleSearchAllDetails}
                    disabled={searchBySelection ? false : true}
                  >
                    Search All
                  </Button>

                  <Grid item xs={12} justify="flex-start">
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="flex-start"
                      padding="0px"
                    >
                      <EnhancedTableToolbar
                        searchText={searchText}
                        setSearchText={setSearchText}
                        setFocused={setFocused}
                        maxLength={searchBySelection == "mobile" ? 15 : 50}
                        placeholder={
                          searchBySelection == "mobile"
                            ? "No special characters allowed"
                            : "Search"
                        }
                      />
                    </Box>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <Box fontWeight="bold" marginTop="5px">
                          Start Date (Only for search all)
                        </Box>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <FormControl fullWidth margin="normal">
                        <Datepickers
                          selectedDate={fromDate}
                          handleDateChange={(date) => {
                            if (date) {
                              date.setHours(0, 0, 0, 0);
                              setFromDate(date);
                            }
                          }}
                          maxDate={new Date()}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <Box fontWeight="bold" marginTop="5px">
                          End Date (Only for search all)
                        </Box>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <FormControl fullWidth margin="normal">
                        <Datepickers
                          selectedDate={toDate}
                          handleDateChange={(date) => {
                            if (date) {
                              date.setHours(23, 59, 0, 0);
                              setToDate(date);
                            }
                          }}
                          maxDate={new Date()}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {(selectedUserType == USER_TYPE_VALUES.TUTOR ||
                    selectedUserType == USER_TYPE_VALUES.ORG) && (
                    <React.Fragment>
                      <Grid container>
                        <Grid item xs={12} md={4}>
                          <FormControl fullWidth margin="normal">
                            <Box fontWeight="bold" marginTop="5px">
                              Start Date (Notification Details)
                            </Box>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={8}>
                          <FormControl fullWidth margin="normal">
                            <Datepickers
                              selectedDate={fromNotiDate}
                              handleDateChange={(date) => {
                                if (date) {
                                  date.setHours(0, 0, 0, 0);
                                  setFromNotiDate(date);
                                }
                              }}
                              maxDate={new Date()}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={4}>
                          <FormControl fullWidth margin="normal">
                            <Box fontWeight="bold" marginTop="5px">
                              End Date (Notification Details)
                            </Box>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={8}>
                          <FormControl fullWidth margin="normal">
                            <Datepickers
                              selectedDate={toNotiDate}
                              handleDateChange={(date) => {
                                if (date) {
                                  date.setHours(23, 59, 0, 0);
                                  setToNotiDate(date);
                                }
                              }}
                              maxDate={new Date()}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </CardContent>
              </Card>

              {customersList && customersList.length > 0 && (
                <Grid item xs={12} md={12} style={{ margin: "10px 0px" }}>
                  <Typography variant="h6" align="right">
                    Recent search results
                  </Typography>
                </Grid>
              )}
              {/* <Box bgcolor='white' width='100%'>  */}
              {/* {
								!modalType ?
									<Datagrids key={searchText} gridRows={gridRows} gridColumns={gridColumns} setSelection={setSelection} /> :
									modalData ? <UserDataTable key={'key' + selectedUserType + fromDate + toDate} gridData={modalData} reportType={selectedUserType} buttonData={buttonData} /> : null
							} */}
              <UserDataTable
                key={"key" + selectedUserType + fromDate + toDate}
                gridData={modalData}
                reportType={selectedUserType}
                buttonData={buttonData}
                userType={
                  selectedUserType === USER_TYPE_VALUES.ORG_TUTOR
                    ? USER_TYPE_VALUES.TUTOR
                    : selectedUserType
                }
              />
              {/* </Box> */}
            </Grid>
          </Container>
        </div>
        {/* {
					!modalType ? '' :
						<ReviewModal
							openModal={openModal}
							onClose={() => { setOpenModal(false); setModalType(null) }}
							viewData={modalData}
							activeType={modalType}
						/>
				} */}
      </MiniDrawer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  customersList: state.adminReducer.customersList as CustomUser[],
  User: state.adminReducer.user as CustomUser,
  // Data: state.adminReducer.data as any,
});

export default connect(mapStateToProps)(SearchCustomer);
