import axios from 'axios'
// import { questionUpdateReq } from './interface'

const BASE_URL = process.env.REACT_APP_API + "admin/"
const SET_RECEIPT_TEMPLATE = BASE_URL + 'receiptTemplate'


export const updateReceiptTemplate = async (ownerId:String, receiptTemplate:String) => {
    const response = await axios.post(SET_RECEIPT_TEMPLATE, { ownerId, receiptTemplate })
    return response.data
}

