import axios, { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { setAuthToken, setRefreshToken, } from './modules/redux/actions/authActions';
import { useSnackbar } from "notistack"

export class Bootstrap {
	static init() {
		Bootstrap.configureAxios();
	}

	private static configureAxios() {
		// Automatically append the authorization token for all the requests
		// if the user is authenticated.
		axios.interceptors.request.use(function (config) {
			const authToken = localStorage.getItem('accessToken');

			const serverURL = new URL(process.env.REACT_APP_API as string);
			const serverURL2 = new URL(process.env.REACT_APP_API_2 as string);
			const requestURL = new URL(config.url as string);

			if (serverURL.host === requestURL.host || serverURL2.host === requestURL.host) {
				config.headers.Authorization = authToken && authToken.length > 0 ? `Bearer ${authToken}` : null;
			}
			return config;
		});

	}
}