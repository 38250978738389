import React, { useState, useEffect, FunctionComponent, } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
	Box, Container, Grid, Button, Input, MenuItem,
	Typography, FormHelperText, CssBaseline, Card, CardContent, CardHeader, InputAdornment
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';

import MiniDrawer from "../../../common/components/SideDrawer"
import { EMAIL_PATTERN, NAME_PATTERN, PASSWORD_LENGTH, PASSWORD_PATTERN, PHONE_PATTERN } from '../../../common/validations/patterns';
import { createAdminUser, getRoles } from '../../../../api/dashboard';
import useStyles from './styles';
import { Role } from '../../../common/contracts/dashboard';
import PasswordVisibilityButton from '../../../common/components/password_visibility_button';
import { useDispatch } from 'react-redux';
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../../utilities/constants';

interface ModalState {
	_id: string,
	name: string,
	status: string
}

interface Props extends RouteComponentProps {

}

interface FormData {
	firstName: string,
	lastName: string,
	emailId: string,
	mobileNumber: string,
	defPassword: string,
	role: string
}

const ValidationSchema = yup.object().shape({
	firstName: yup.string().trim().required('First name is required')
		.matches(NAME_PATTERN, 'First Name is invalid'),
	// .validate(val=>val.trim().length>0)
	// .min(5, 'cannot be less than 5 characters'),
	lastName: yup.string().required('Last name is required')
		.matches(NAME_PATTERN, 'Last Name is invalid'),
	emailId: yup.string().required('Email is required')
		.matches(EMAIL_PATTERN, 'Email is invalid'),
	mobileNumber: yup.string().required('Mobile number is required')
		.matches(PHONE_PATTERN, 'Mobile number is invalid'),
	defPassword: yup.string().required('Default password is required')
		.matches(PASSWORD_PATTERN, 'Password must contain at least one uppercase, lowercase, alphanumeric & special character')
		.matches(PASSWORD_LENGTH, 'Password must contain at least 8 characters'),
	role: yup.string().notOneOf(['Select']).required('Role is required')
});

const CreateUser: FunctionComponent<Props> = ({ location }) => {
	const { errors, setError, clearError, handleSubmit, register, reset, control, setValue } = useForm<FormData>({
		mode: 'onBlur',
		validationSchema: ValidationSchema
	});

	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [roles, setRoles] = useState<Role[]>([]);
	const styles = useStyles();
	const dispatch = useDispatch();


	useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getUserRoles = async () => {
		setLoading(true);
		try {
			const roles = await getRoles();
			setRoles(roles);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	const getLoggedInUserData = async () => {
		// setLoading(true);
		// try {
		// 	const adminUserData = await getAdminData();
		// 	dispatch(setLoginUserData(JSON.stringify(adminUserData)));
		// 	setLoading(false);
		// 	if (adminUserData.isItDefaultPassword) {
		// 		localStorage.setItem('isItDefaultPassword', JSON.stringify(adminUserData.isItDefaultPassword));
		// 		setRedirectTo('/set-password');
		// 	}
		// } catch (error) {
		// 	setLoading(false);
		// 	enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
		// }
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		const requiredPermissions = [MASTER_PERMISSIONS.createAdmin, MASTER_PERMISSIONS.getRoles]
		var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		} else {
			getUserRoles();
		}
	}

	const handleCreate = async (data: FormData) => {
		var { firstName, lastName, emailId, mobileNumber, defPassword, role } = data;

		if (firstName.replace(/ /g, "").length < 3) {
			setError('firstName', 'Invalid Data', 'First name cannot be less than 3 character excluding spaces');
			return;
		} else {
			clearError('firstName');
		}

		if (lastName.replace(/ /g, "").length < 1) {
			setError('lastName', 'Invalid Data', 'Last name cannot be less than 1 character excluding spaces');
			return;
		} else {
			clearError('lastName');
		}


		var adminReq = {
			"mobileNo": mobileNumber,
			"firstName": firstName,
			"lastName": lastName,
			"emailId": emailId,
		}
		var adminUserReq = {
			"username": emailId,
			"password": defPassword,
			"role": role
		}

		setLoading(true);
		try {
			await createAdminUser(adminReq, adminUserReq);
			setLoading(false);
			enqueueSnackbar('User created successfully', { variant: 'success' });
			reset({ role: 'Select' })
		} catch (error) {

			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							<Grid item className={styles.header}>
								<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
							</Grid>
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Manage Users</Typography>
							</Grid> */}

							<Card className={styles.card} variant='outlined' >
								<CardHeader title='Add User' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent >

									<form onSubmit={handleSubmit(handleCreate)} >
										<Grid container>
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>First Name</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="firstName"
													placeholder="Enter First Name"
													inputProps={{ maxLength: 50 }}
													className={styles.margin}
													inputRef={register}
												/>
												{errors.firstName && (
													<FormHelperText error>
														{errors.firstName.message}
													</FormHelperText>
												)}
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Last Name</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="lastName"
													placeholder="Enter Last Name"
													inputProps={{ maxLength: 50 }}
													className={styles.margin}
													inputRef={register}
												/>
												{errors.lastName && (
													<FormHelperText error>
														{errors.lastName.message}
													</FormHelperText>
												)}
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Email Id</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="emailId"
													placeholder="Enter Email Id"
													inputProps={{ maxLength: 50 }}
													className={styles.margin}
													inputRef={register}
												/>
												{errors.emailId && (
													<FormHelperText error>
														{errors.emailId.message}
													</FormHelperText>
												)}
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Mobile Number</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="mobileNumber"
													placeholder="Enter Mobile Number"
													inputProps={{ inputMode: 'numeric', maxLength: 10 }}
													className={styles.margin}
													inputRef={register}
												/>
												{errors.mobileNumber && (
													<FormHelperText error>
														{errors.mobileNumber.message}
													</FormHelperText>
												)}
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Default Password</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="defPassword"
													type={isPasswordVisible ? 'text' : 'password'}
													placeholder="Enter Default Password"
													inputProps={{ maxLength: 50 }}
													className={styles.margin}
													inputRef={register}
													endAdornment={
														<InputAdornment position="end">
															<PasswordVisibilityButton
																isVisible={isPasswordVisible}
																handleChange={(isVisible) =>
																	setIsPasswordVisible(isVisible)
																}
															/>
														</InputAdornment>
													}
												/>
												{errors.defPassword && (
													<FormHelperText error>
														{errors.defPassword.message}
													</FormHelperText>
												)}
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Role</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<FormControl fullWidth margin="normal">
													<Controller
														as={
															<Select>
																<MenuItem value="Select">Select</MenuItem>
																{roles.length > 0 && roles.map((item, index) => (
																	<MenuItem value={item.name} key={index}	>
																		{item.name}
																	</MenuItem>
																))}
															</Select>
														}
														name="role"
														inputRef={register}
														control={control}
														setValue={setValue}
														defaultValue="Select"
														displayEmpty
													/>
													{errors.role && (
														<FormHelperText error>
															{errors.role.message}
														</FormHelperText>
													)}
												</FormControl>
											</Grid>
										</Grid>

										<Grid container justify='center' className={styles.submitBtn}>
											<Grid item xs={12} md={6} >
												<Button
													disableElevation
													variant="contained"
													color="primary"
													size="large"
													type="submit"
													disabled={loading}
												>
													Create
													</Button>
											</Grid>
										</Grid>

									</form>
								</CardContent>
							</Card>

						</Grid>
					</Container>
				</div>
			</MiniDrawer >
		</>
	);
}

export default CreateUser;
