import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  GridColumns,
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core';
import Datagrids, { datagridCellExpand } from '../../dashboard/components/dataGrid';

interface RowData {
  rowNo: number,
  message: string,
}

interface Props {
  gridData: RowData[];
}

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600'
    },
    '& .super-app.negative': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600'
    },
    '& .super-app.positive': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600'
    }
  },
  paginate:{
    display:"flex"
  }
});

export const ErrDataTable: FunctionComponent<Props> = ({
  gridData
}) => {
  const classes = useStyles();

  const columns: GridColumns = [
    { field: 'id', headerName: 'S.No', flex:0.5 },
    { field: 'rowNo', headerName: 'Excel Row reference', flex:1, renderCell: datagridCellExpand },
    {
      field: 'message',
      headerName: 'ErrOr Message',
      type: 'string',
      flex:2,
      renderCell: datagridCellExpand
    },
  ];

  const [rows,setRows] = useState<RowData[]>([])

   useEffect(()=>{
      setRows(()=>{
          return gridData?.map((data,index)=>{
              return {id:index+1, rowNo:data.rowNo, message:data.message} as RowData
          }) as RowData[]
      })
    },[gridData])

  return (
    <Datagrids gridRows={rows} gridColumns={columns} disableCheckbox={true}  />
  );
};
