import React, { useState, useEffect, FunctionComponent, } from 'react';
import {
	Box, Container, Grid, Input, Typography, CssBaseline, Card, CardContent, CardHeader, IconButton, Select, MenuItem, Button, TextField, Checkbox, ListItemText, darken, makeStyles, InputAdornment, lighten, createStyles, Theme
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
// import { isValidPhoneNumber } from 'libphonenumber-js';

import MiniDrawer from "../../../common/components/SideDrawer"
import { CustomUser, } from '../../../common/contracts/dashboard';
import { RootState } from '../../../redux/store';
import { connect, useDispatch } from 'react-redux';
import { createCollegeGroup, getGroupByOwnerId, getOrgList, getUser, updateCollegeGroup, updateUserPreferences } from '../../../../api/dashboard';
import { ArrowBack } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { fontOptions } from '../../../../theme';
import CancelIcon from '@material-ui/icons/Cancel';

interface Props extends RouteComponentProps {
	user: CustomUser
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const useStyles = makeStyles((theme: Theme)=>
createStyles({
	typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121',
	  },
      label: {
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.small,
      },
      root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        height: "100%",
      },
      highlight:
        theme.palette.type === "light"
          ? {
              color: theme.palette.secondary.main,
              backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
          : {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.secondary.dark,
            },
}
));

const CreateGroup: FunctionComponent<Props> = ({ user, history,location }) => {
    const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
    const [collegeGroupName,setCollegeGroupName] = useState("")
    const [collegesList,setCollegesList] = useState<any>([])
    const [selectedColegeList,setSelectedCollegeList] = useState<any>([])
    const State: any = location?.state;
    let data = State?.data
    console.log("data",data);
    
    useEffect(()=>{
        getCollegeList()  
    },[])

    useEffect(()=>{
        if(data){
            console.log("1122222");
            setCollegeGroupName(data?.groupName)
            let tempCollegeList:any = collegesList?.filter((college:any)=>data?.collegeOwnerIdsArr?.includes(college?.ownerId))
            setSelectedCollegeList(tempCollegeList)
        }
    },[collegesList])

    const getCollegeList = async()=>{
        try {
            let collegeList =await getOrgList()
            setCollegesList(collegeList)
        } catch (error) {
            console.log("error",error);
        }   
    }

    const handleSelectColleges = (node:any,reason:any)=>{
        setSelectedCollegeList(node)
    }

    const handleRemoveCollege = (index:number)=>{
        let filteredCollege = [...selectedColegeList]?.filter((col:any,ind:number)=>index!=ind)
        setSelectedCollegeList(filteredCollege)
    }

    const createGroup = async()=>{
        try {
            let alreadyPresent = false
            let alreadyPresentCollege = ""
           await Promise.all(selectedColegeList?.map(async(college:any)=>{
                let collegeGroup = await getGroupByOwnerId(college?.ownerId)
                console.log("collegeGroup",collegeGroup.collegeGroupName);
                if(collegeGroup.collegeGroupName){
                    alreadyPresent = true
                    alreadyPresentCollege = college?.organizationName
                }
            }))
            console.log("alreadyPresent",alreadyPresent,alreadyPresentCollege);

            if(alreadyPresent){
                return enqueueSnackbar(`${alreadyPresentCollege} is present in other group.`, { variant: "warning" })
            }
            else if(collegeGroupName==""||!collegeGroupName){
                return enqueueSnackbar("Please Enter College Group Name.", { variant: "warning" })
            }else if(selectedColegeList?.length<1){
                return enqueueSnackbar("Select atleast one college.", { variant: "warning" })
            }else{
                let tempCollegeList = selectedColegeList?.map((col:any)=>col?.ownerId)
                let payload = {
                    collegeGroupName:collegeGroupName,
                    collegeOwnerIdsArr:tempCollegeList
                }
                if(data){
                    let payloadUpdate = {
                        collegeGroupName:collegeGroupName,
                        collegeOwnerIdsArr:tempCollegeList,
                        _id:data?.groupId
                    }
                    await updateCollegeGroup(payloadUpdate)
                }
                else
                await createCollegeGroup(payload)
                enqueueSnackbar(`College group ${data?"updated":"created"} successfully`, { variant: "success" })
                history.push("/college-groups")
            }
        } catch (error) {
            console.log("error",error);
            return enqueueSnackbar("something went wrong", { variant: "warning" })
        }
    }

    console.log("selectedColegeList",selectedColegeList,collegesList);
	
	return (
		<>
			<CssBaseline />
			<MiniDrawer>
					<Container style={{ width: '100%' }}>
                        <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="5px"
            color='#fff'
            >
            <Grid item container>
                <Grid item sm={8}>
                <Box style={{height: '100%'}}>
                    <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                    >
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                        {data?"Upadte":"Create"} New College Group
                        </Typography>
                        <Typography>
                        {data?"Upadte":"Create"} college group & {data?"upadte":"add"} college list.                        </Typography>
                        <Button style={{textTransform:"capitalize",backgroundColor:"#fff",color:"#4C8BF5",marginTop:10}} disableElevation variant="contained" size="small"  onClick={()=>{history.goBack()}} >Back</Button>
                    </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            </Box>
					
                    <Box style={{backgroundColor:"#fff",borderRadius:8,padding:24,paddingLeft:32}}>
                        <Grid xs={12} sm={12} md={8} lg={6}>
                            <Typography style={{fontWeight:"bold",marginBottom:8}}>College Group Name</Typography>
                            <TextField id="outlined-basic" placeholder='Enter College Group Name' variant="outlined" fullWidth value={collegeGroupName} onChange={(e)=>setCollegeGroupName(e.target.value)}/>
                        </Grid>
                        <Grid  xs={12} style={{ marginTop: '20px' }}>
                            <hr style={{ borderTop: '2px dashed #B0B1B3', width: '100%', height: '0px', borderLeft: '0', borderRight: '0', borderBottom: '0' }}></hr>
                        </Grid>
                        {/* <Grid container xs={12} sm={12} md={6} lg={6} justify='space-between' style={{alignItems:"center",marginTop:"20px"}}>
                            <Grid item sm={9}>
                            <TextField
                                id="input-with-icon-textfield"
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" style={{color:"rgba(102, 102, 102, 0.50);"}}>
                                    <SearchIcon />
                                    </InputAdornment>
                                ),
                                }}
                                fullWidth
                                placeholder='Search College'
                            />
                            </Grid>
                            <Grid item sm={2}>
                            <Button style={{textTransform:"capitalize",backgroundColor:"#fff",color:"#4C8BF5",marginTop:10,borderWidth:"1px",borderColor:"#4C8BF5"}} disableElevation variant="outlined" size="medium"  onClick={()=>{history.goBack()}} >Search</Button>
                            </Grid>
                        </Grid> */}
                        {(collegesList && collegesList?.length > 0 )&& (
                        <Grid xs={12} sm={12} md={8} lg={6}>
                        <Typography style={{fontWeight:"bold",marginBottom:8}}>Select Colleges</Typography>
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={collegesList}
                            getOptionLabel={(option:any) => option.organizationName}
                            filterSelectedOptions
                            onChange={(e, node: any,reason:any) => { handleSelectColleges(node, reason) }}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select College"
                            />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <div key={index} style={{ display: 'none' }}></div>
                                ))
                              }
                            value={selectedColegeList}
                            // disableClearable
                        />
                        </Grid>)}
                        {(selectedColegeList&&selectedColegeList?.length>0)&&<Grid container wrap='wrap' style={{marginTop:"20px"}}>
                            {selectedColegeList?.map((college:any,index:number)=>{
                                return(
                                    <Box  style={{marginRight:"10px",marginBottom:"8px",padding:"6px",borderRadius:6,backgroundColor:"rgba(76, 139, 245, 0.10)",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <Typography style={{marginRight:"8px"}}>{college?.organizationName}</Typography>
                                        <CancelIcon style={{color:'#4C8BF5'}} onClick={()=>handleRemoveCollege(index)}/>
                                    </Box>
                                )
                            })}
                        </Grid>}
                        <Grid container justify='flex-end'>
                            <Button style={{textTransform:"capitalize",backgroundColor:"#4C8BF5",color:"#fff",marginTop:10,}} disableElevation variant="outlined" size="medium"  onClick={createGroup} >{data?"Update":"Save"}</Button>
                            </Grid>
                    </Box>
                    </Container>
			</MiniDrawer >
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	user: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(CreateGroup);