import React, { useState, useEffect, FunctionComponent } from "react";
import { Redirect } from 'react-router'
import {
	Box,
	Container,
	CssBaseline,
	darken,
	FormControl,
	Grid,
	IconButton,
	makeStyles,
	MenuItem,
	Select,
	Tooltip,
	Typography,
} from "@material-ui/core";
import Button from "../../common/components/form_elements/button";
import { Edit, Visibility } from "@material-ui/icons";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import MiniDrawer from "../../common/components/SideDrawer";
import { fontOptions } from "../../../theme";
import { MASTER_PERMISSIONS } from "../../../utilities/constants";
import { getBoardsList, getChapterList, getClassList, getMasterQuestions, getSubjectList } from "../api";
import Datagrids, { datagridCellExpand } from "../../dashboard/components/dataGrid";
import { questionsRow } from "../interfaces";
import EditMasterQuestionModal from "../components/editMasterQuestionModal";
import ViewMasterQuestionModal from "../components/viewMasterQuestionModal";
import { getLoggedInUserData, onApiError, toTitleCase } from '../../common/helpers';
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
	typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121',
	  },
      label: {
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.small,
      },
});

const EditMasterQuestions: FunctionComponent = () => {
    const classes = useStyles();
    const [boards, setBoards] = useState<string[]>([])
    const [classnames, setClassnames] = useState<string[]>([])
    const [subjects, setSubjects] = useState<string[]>([])
    const [chapters, setChapters] = useState<{original: string, converted: string}[]>([])

    const [boardname, setBoardname] = useState('')
    const [classname, setClassname] = useState('')
    const [subjectname, setSubjectname] = useState('')
    const [chaptername, setChaptername] = useState('')

    const [showDataGrid, setShowDataGrid] = useState(false)
    const [gridRows, setGridRows] = useState<questionsRow[]>([])
    const [gridColumns, setGridColumns] = useState<GridColDef[]>([])
    const [selectedQuestion, setSelectedQuestion] = useState<questionsRow>()

    const [openModal, setOpenModal] = useState(false)
    const [viewModal, setViewModal] = useState(false)

    const [isEdited, setIsEdited] = useState(false)
    
	const [redirectTo, setRedirectTo] = useState<string>('')
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        getLoggedInUserData(setRedirectTo, [MASTER_PERMISSIONS.masterQuestions], enqueueSnackbar, handleGetBoards)
    }, [])

    if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

    const handleGetBoards = async () => {
        try {
            const boardsRes = await getBoardsList()
            setBoards(boardsRes.boardDetailsList.map((cur: any) => cur.boardName))
        } catch(err) {
            onApiError(err, enqueueSnackbar, setRedirectTo)
        }     
    }

    const handleBoardChange = async (selectedBoard: string) => {
        setBoardname(selectedBoard)
        const classesRes = await getClassList({boardname: selectedBoard})
        setClassnames(classesRes.classList.map((cur: any) => cur.className))
        setClassname('')
        setSubjects([])
        setSubjectname('')
        setChapters([])
        setChaptername('') 
    }

    const handleClassChange = async (selectedClass: string) => {
        setClassname(selectedClass)
        const subjectsRes = await getSubjectList({boardname, classname: selectedClass})
        setSubjects(subjectsRes.subjectList.map((cur: any) => cur.subjectName))
        setSubjectname('')
        setChapters([])
        setChaptername('') 
    }

    const handleSubjectChange = async (selectedSubject: string) => {
        setSubjectname(selectedSubject)
        const chapters = await getChapterList({boardname, classname, subjectname: selectedSubject})
        setChapters(
            chapters.map((cur: string) => ({
                original: cur,
                converted: toTitleCase(cur)
            }))
        )
        setChaptername('')
    }

    const getOriginalChaptername = () => {
        const originalIndex = chapters.findIndex(cur => cur.converted === chaptername)
        return chapters[originalIndex].original
    }

    const handleFetchQuestions = async () => {
        try {
            const questionsRes: any[] = await getMasterQuestions({boardname, classname, subjectname, chaptername: getOriginalChaptername()})
            questionsRes.forEach((cur, i) => cur.id = i+1)
            createDataGrid(questionsRes)
        } catch (err) {
            onApiError(err, enqueueSnackbar, setRedirectTo)
        }
    }

    const createDataGrid = (questions: questionsRow[]) => {
		setShowDataGrid(true)
        setGridRows(questions)
		setGridColumns([
			{ field: 'id', headerName: 'S.No', flex: 0.1 },
			{ field: 'questionDescription', headerName: 'Question', flex: 1, renderCell: datagridCellExpand },
			{
				field: 'action', headerName: 'Action', flex: 0.1,
				disableClickEventBubbling: true,
				renderCell: (params: GridCellParams) => {
					const selectedRow = {
						id: params.getValue("id") as number,
						questionDescription: params.getValue("questionDescription") as string
					}

					const selectedRowDetails = questions.find((row, index) => {
						return (row.questionDescription === selectedRow.questionDescription && index === (selectedRow.id - 1))
					})

					const editQuestion = (row: questionsRow) => {
						setSelectedQuestion(row)
						setOpenModal(true)
					}

                    const viewQuestion = (row: questionsRow) => {
                        setSelectedQuestion(row)
                        setViewModal(true)
                    }

					const buttonData = [
                        {
							title: 'View Question',
							action: viewQuestion,
							icon: <Visibility />
						},
						{
							title: 'Edit Question',
							action: editQuestion,
							icon: <Edit />
						}
					];

					const buttonSet = buttonData.map((button, index) => {
						return (
							<Tooltip key={index} title={button.title}>
								<IconButton
									onClick={() => {
										button.action(selectedRowDetails as questionsRow);
									}}
									size="small"
								>
									{button.icon}
								</IconButton>
							</Tooltip>
						);
					})

					return <div>{buttonSet}</div>;
				}
			}
		])
	}

    const onCloseModal = () => {
		setOpenModal(false)
		setSelectedQuestion(undefined)
		handleFetchQuestions()
	}

    const handleEdit = () => {
        setViewModal(false)
        if(!openModal) setOpenModal(true)
    }

    const onCloseViewModal = () => {
        setViewModal(false)
		setSelectedQuestion(undefined)
    }

    const renderDropdown = (key: string, value: any, list: string[], setter: any) => (
        <Grid container>
			<Grid item xs={12} md={4}>
				<FormControl fullWidth margin="normal">
					<Box className={classes.label}>{key}</Box>
				</FormControl>
			</Grid>

			<Grid item xs={12} md={8}>
				<FormControl fullWidth margin="normal">
					<Select
						value={value}
						onChange={(e: any) => setter(e.target.value)}
					>
						<MenuItem value="">Select</MenuItem>
						{list.map((item: any, index: any) => (
							<MenuItem value={item} key={index}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
    )

    const renderButton = (name: string, cb: any) => (
        <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item>
                <Button variant="contained" color="primary" size="medium"
                    onClick={cb}
                >
                    {name}
                </Button>
            </Grid>
        </Grid>
    )

	return(
        <>
        <CssBaseline />
        <MiniDrawer>
        {selectedQuestion && <EditMasterQuestionModal
            openModal={openModal}
            onClose={onCloseModal}
            question={selectedQuestion}
            setOpenModal={setOpenModal}
            setIsEdited={setIsEdited}
            isEdited={isEdited}
            handleEdit={handleEdit}
        />}
        {selectedQuestion && <ViewMasterQuestionModal
            openModal={viewModal}
            onClose={onCloseViewModal}
            question={selectedQuestion}
            handleEdit={handleEdit}
            isEdited={isEdited}
        />}
        <Container maxWidth="lg">
            <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="5px"
            color='#fff'
            >
            <Grid item container>
                <Grid item sm={8}>
                <Box style={{height: '100%'}}>
                    <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                    >
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            Master Questions
                        </Typography>
                        <Typography>
                            Update Master Questions
                        </Typography>
                    </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            </Box>

            <Box
				bgcolor="#ffffff"
				borderRadius="5px"
				padding="12px 25px"
				marginTop='25px'
			>
                <Grid container justify='center'>
                    <Grid item xs={12} lg={6} style={{paddingTop: '5px', marginBottom: '8px'}}>
                        {renderDropdown('Select Board', boardname, boards, handleBoardChange)}
                        {renderDropdown('Select Class', classname, classnames, handleClassChange)}
                        {renderDropdown('Select Subject', subjectname, subjects, handleSubjectChange)}
                        {renderDropdown('Select Chapter', chaptername, chapters.map(cur => cur.converted), setChaptername)}
                        {renderButton('Fetch Questions', handleFetchQuestions)}
                    </Grid>
                    <Grid item xs={12}>
                        {showDataGrid && <Datagrids gridRows={gridRows} gridColumns={gridColumns} disableCheckbox />}
                    </Grid>
                </Grid>
            </Box>   
        </Container>
        </MiniDrawer>
        </>
    )
};

export default EditMasterQuestions;