import React, { FunctionComponent, useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Container, Box, Grid, Typography, FormControl, Input, Select, MenuItem,  FormHelperText, useMediaQuery, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { fontOptions } from '../../../theme';
import AssiSheet from '../../../assets/svgs/asmPaper.svg'
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import Button from '../../common/components/form_elements/button';
import { uniq } from 'lodash'
import { fetchQuestionBank, fetchQuestionBankSet, smallUpdateQuestionBank } from '../helpers/api';
import { Course, QuestionBank, SmallUpdate } from '../contracts/qb_interface';
import { NAME_PATTERN3, QB_PATTERN, QB_PATTERN2 } from '../../common/validations/patterns';
import MiniDrawer from '../../common/components/SideDrawer';
import { courseDetails } from '../../../utilities/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: fontOptions.size.large
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    error: {
      "&:not(.Mui-disabled)::before": {
        borderColor: "#F44E42"
      }
    },
    formInput: {
      '& input::placeholder': {
        fontWeight: fontOptions.weight.normal,
        fontSize: fontOptions.size.small,
        lineHeight: '18px',
        color: 'rgba(0, 0, 0, 0.54)'
      }
    },
    spError: {
      fontSize: fontOptions.size.small,
      color: '#F44E41'
    },
    addAssi: {
      '& button': {
        padding: '10px 30px 10px 30px',
      },
    },
    dropzoneRoot: {
      width: '100%',
      minHeight: 'auto',
      border: '2.5px dashed rgba(76, 139, 245, 0.5)',
      boxSizing: 'border-box',
      borderRadius: '5px',
      paddingBottom: '15px'
    },
    dropzoneTextContainerRoot: {
     paddingTop: '3%' 
    },
    dropzoneText: {
      fontFamily:fontOptions.family,
      fontSize: fontOptions.size.small,
      fontWeight: fontOptions.weight.bold,
      color: '#4C8BF5',
      margin: '20px 0',
    },
    dropzoneIcon: {
      color: '#4C8BF5'
    },
  }),
);

interface FormData {
  qbName: string;
  description: string;
  boardName: string;
  className: string;
  subject: string;
  availStudent: boolean;
}

interface AllChapter {
  chaptername: string,
  subtoptics: string[]
}

interface Props {
  location: {
    state: {
      basicData: FormData,
      allChapter?: AllChapter[],
      structuredQB?: QuestionBank[]
    },
    search: string
  },
}

const QBCreate: FunctionComponent<Props> = ({location}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { errors, setError, clearError } = useForm<FormData>();

  const [qbName, setQbName] = useState('');
  const [prevQbName, setPrevQbName] = useState('');
  const [description, setDescription] = useState('');
  const [allCourses, setAllCourses] = useState<Course[]>([])
  const [boardName, setBoardName] = useState('');
  const [className, setClassName] = useState('');
  const [subject, setSubject] = useState('');
  const [availStudent, setAvailStudent] = useState(true);
  const [isapiLoad, setisapiLoad] = useState(false)

  const [boardList, setBoardList] = useState<string[]>([])
  const [classList, setClassList] = useState<string[]>([])
  const [subjectList, setSubjectList] = useState<string[]>([])
  const [isupdate, setIsupdate] = useState(false)
  const [updateQbName, setUpdateQbName] = useState('')

  const [basicData, setBasicData] = useState<FormData>()
  const [allChapter, setAllChapter] = useState<AllChapter[]>([])
  const [structuredQB, setStructuredQB] = useState<QuestionBank[]>([])

  const [redirectTo, setRedirectTo] = useState('');
  let history = useHistory();

  useEffect(() => {
    (async () => {
      try {
          setisapiLoad(true)

          const courses = courseDetails
          setAllCourses(courses)
          if(courses.length > 0) {
            setBoardList(uniq(courses.map(bo => bo.board)))
          }
          

          const params = new URLSearchParams(window.location.search);
          const qbNameParam = params.get('qbName');
          if(qbNameParam && qbNameParam.length > 0) {
            setIsupdate(true)
            setUpdateQbName(qbNameParam)

            const questionBankRes = await fetchQuestionBank()
            const thisqb = questionBankRes.find(qb => qb.questionBankFriendlyName.trim() === qbNameParam.trim())
            if(thisqb) {
              setQbName(thisqb.questionBankFriendlyName.trim())
              setPrevQbName(thisqb.questionBankFriendlyName)
              setDescription(thisqb.description)
              setAvailStudent(thisqb.availableForMockTest)
              setBoardName(thisqb.boardname)
              setClassName(thisqb.classname)
              setSubject(thisqb.subjectname)
              setClassList(uniq(courses.filter(bo => bo.board === thisqb.boardname).map(bo => bo.className)))
              setSubjectList(uniq(courses.filter(bo => bo.board === thisqb.boardname && bo.className === thisqb.classname).map(bo => bo.subject)))
            }
          } else {
            if(location.state && location.state.basicData && location.state.basicData.qbName){
              window.scroll(0,0)
              setBasicData(location.state.basicData)
              setQbName(location.state.basicData.qbName)
              setDescription(location.state.basicData.description)
              setAvailStudent(location.state.basicData.availStudent)
              setBoardName(location.state.basicData.boardName)
              setClassName(location.state.basicData.className)
              setSubject(location.state.basicData.subject)
              setClassList(uniq(courses.filter(bo => bo.board === location.state.basicData.boardName).map(bo => bo.className)))
              setSubjectList(uniq(courses.filter(bo => bo.board === location.state.basicData.boardName && bo.className === location.state.basicData.className).map(bo => bo.subject)))
            }
            if(location.state && location.state.allChapter) {
              setAllChapter(location.state.allChapter)
            } 
            if(location.state && location.state.structuredQB) {
              setStructuredQB(location.state.structuredQB)
            }
          }

          setisapiLoad(false)
        
      } catch (error) {
        setRedirectTo('/login');
      }
    })();
  }, [location.search])

  const setBoardAndFetchClasses = async (board: string) => {
    try {
      setClassName('');
      setSubject('');
      setClassList([]);
      setSubjectList([]);
      setBoardName(board);
      if (board.length > 0) {
        if(allCourses.length > 0) {
          setClassList(uniq(allCourses.filter(bo => bo.board === board).map(bo => bo.className)))
        }
      } else {
        setClassList([]);
      }
    } catch (error) {
        setRedirectTo('/login');
    }
  };

  const setClassAndFetchSubjects = (data: string) => {
    setSubject('');
    setSubjectList([]);
    setClassName(data)
    if (data.length > 0) {
      if(allCourses.length > 0) {
        setSubjectList(uniq(allCourses.filter(bo => bo.board === boardName && bo.className === data).map(bo => bo.subject)))
      }
    } else {
      setSubjectList([]);
    }
  }

  const createQB = () => {
    if (!qbName || (qbName && qbName.length === 0)) {
      setError(
        'qbName',
        'Invalid Data',
        'Name cannot be Empty'
      );
      return;
    } else {
      clearError('qbName');
    }

    if (qbName && (qbName.replace(/ /g, "").length < 5)) {
      setError(
        'qbName',
        'Invalid Data',
        'Name should have atleast 5 characters excluding spaces'
      );
      return;
    } else {
      clearError('qbName');
    }

    if (qbName && (qbName.length > 50)) {
      setError(
        'qbName',
        'Invalid Data',
        'Name should not have more than 50 characters'
      );
      return;
    } else {
      clearError('qbName');
    }

    if (qbName && qbName.trim() && (!QB_PATTERN.test(qbName.trim()))) {
      setError(
        'qbName',
        'Invalid Data',
        'Name is Invalid'
      );
      return;
    } else {
      clearError('qbName');
    }

    if (!description || (description && description.length === 0)) {
      setError(
        'description',
        'Invalid Data',
        'Description cannot be Empty'
      );
      return;
    } else {
      clearError('description');
    }

    if (description && (description.replace(/ /g, "").length < 10)) {
      setError(
        'description',
        'Invalid Data',
        'Description should have atleast 10 characters excluding spaces'
      );
      return;
    } else {
      clearError('description');
    }

    if (description && (description.length > 1000)) {
      setError(
        'description',
        'Invalid Data',
        'Description should not have more than 1000 characters'
      );
      return;
    } else {
      clearError('description');
    }

    if (description && description.trim() && (!QB_PATTERN2.test(description.trim()))) {
      setError(
        'description',
        'Invalid Data',
        'Description is Invalid'
      );
      return;
    } else {
      clearError('description');
    }

    if (!boardName || (boardName && boardName.length === 0)) {
      setError(
        'boardName',
        'Invalid Data',
        'Board cannot be Empty'
      );
      return;
    } else {
      clearError('boardName');
    }

    if (!className || (className && className.length === 0)) {
      setError(
        'className',
        'Invalid Data',
        'Class cannot be Empty'
      );
      return;
    } else {
      clearError('className');
    }

    if (!subject || (subject && subject.length === 0)) {
      setError(
        'subject',
        'Invalid Data',
        'Subject cannot be Empty'
      );
      return;
    } else {
      clearError('subject');
    }

    const qbData: FormData = {
      qbName: qbName.trim(), description: description.trim(), boardName, className, subject, availStudent
    }

    if(location.state && location.state.basicData && location.state.basicData.qbName && location.state.allChapter && location.state.structuredQB) {
      history.push({pathname: '/edumaticaQB/questions', state: {basicData: qbData, allChapter: allChapter, structuredQB: structuredQB }})
    } else {
      history.push({pathname: '/edumaticaQB/questions', state: {basicData: qbData}})
    }
  }

  const updateQB = async() => {
    if (!qbName || (qbName && qbName.length === 0)) {
      setError(
        'qbName',
        'Invalid Data',
        'Name cannot be Empty'
      );
      return;
    } else {
      clearError('qbName');
    }

    if (!description || (description && description.length === 0)) {
      setError(
        'description',
        'Invalid Data',
        'Description cannot be Empty'
      );
      return;
    } else {
      clearError('description');
    }

    if (description && description.trim() && (!QB_PATTERN2.test(description.trim()))) {
      setError(
        'description',
        'Invalid Data',
        'Description is Invalid'
      );
      return;
    } else {
      clearError('description');
    }

    if (!boardName || (boardName && boardName.length === 0)) {
      setError(
        'boardName',
        'Invalid Data',
        'Board cannot be Empty'
      );
      return;
    } else {
      clearError('boardName');
    }

    if (!className || (className && className.length === 0)) {
      setError(
        'className',
        'Invalid Data',
        'Class cannot be Empty'
      );
      return;
    } else {
      clearError('className');
    }

    if (!subject || (subject && subject.length === 0)) {
      setError(
        'subject',
        'Invalid Data',
        'Subject cannot be Empty'
      );
      return;
    } else {
      clearError('subject');
    }

    const qbData: FormData = {
      qbName: qbName.trim(), description: description.trim(), boardName, className, subject, availStudent
    }

    const structutredSmallQB: SmallUpdate = {
      boardname: boardName,
      classname: className,
      questionBankFriendlyName: qbName.trim(),
      previousQuestionBankFriendlyName: prevQbName,
      subjectname: subject,
      description: description.trim(),
      availableForMockTest: availStudent
    }

    await smallUpdateQuestionBank(structutredSmallQB)

    const getList = await fetchQuestionBankSet(qbName.trim(), availStudent)
    if(getList.length > 0) {
      const allChapter: AllChapter[] = []
      const structuredQB: QuestionBank[] = []

      getList.forEach(list => {
        const thisIndex = allChapter.findIndex(ac => ac.chaptername === list.chaptername)
        if(thisIndex > -1) {
          if(!allChapter[thisIndex].subtoptics.includes(list.subTopic)) {
            allChapter[thisIndex].subtoptics.push(list.subTopic)
          }
        } else {
          const newAc: AllChapter = {
            chaptername: list.chaptername,
            subtoptics: [list.subTopic]
          }
          allChapter.push(newAc)
        }

        if(list.type === 'single' || list.type === 'multiple' || list.type === 'match') {
          const listOpt = [...list.answer]
          const newAnswe = listOpt.map(lo => {
            if(lo === 'A') {
              return '1'
            } else if(lo === 'B') {
              return '2'
            } else if(lo === 'C') {
              return '3'
            } else if(lo === 'D') {
              return '4'
            } else if(lo === 'E') {
              return '5'
            } else {
              return '6'
            }
          })

          list.answer = newAnswe
        }

        structuredQB.push(list)        
      })

      history.push({pathname: '/edumaticaQB/qlist', search: `?mode=edit`, state: {basicData: qbData, allChapter: allChapter, structuredQB: structuredQB }})
    }
  }

  if (redirectTo.length > 0) {
    return <Redirect to={redirectTo} />;
  }

  if(isapiLoad) {
    return <div>
        Loading ...
    </div>
  }

  return (
    <div>
      <MiniDrawer>
      <Container maxWidth="lg" style={{padding: '30px 2.5%'}}>
        <Box
          bgcolor="#F9BD33"
          padding="20px 30px"
          marginBottom="30px"
          position="relative"
          borderRadius="14px"
          color='#fff'
        >
          <Grid item container>
            <Grid item sm={8}>
              <Box style={{height: '100%'}}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                  style={{ height: '100%' }}
                >
                  <Grid item xs={12}>
                    <Typography className={classes.title}>
                      Create your Question Bank
                    </Typography>
                    <Typography>
                      Create Question Bank
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          bgcolor="#ffffff"
          borderRadius="14px"
          padding="25px"
          marginTop='25px'
        >
          <Grid container spacing={1}>
            <Grid item xs={12} style={{marginBottom: '25px'}}>
              <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium}}>
                Question Bank Detail
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <Box>Name</Box>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={8}>
                  <FormControl style={{width: '75%'}} margin="normal">
                    <Input
                      placeholder="Enter Name"
                      value={qbName}
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => setQbName(e.target.value)}
                      className={errors.qbName ? `${classes.error} ${classes.formInput}` : classes.formInput}
                    />
                  </FormControl>
                  {errors.qbName && (
                    <FormHelperText error>
                      {errors.qbName.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <Box>Description</Box>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={8}>
                  <FormControl style={{width: '75%'}} margin="normal">
                    <Input
                      placeholder="Enter Description"
                      value={description}
                      multiline
                      rows={4}
                      inputProps={{ maxLength: 1500 }}
                      onChange={(e) => setDescription(e.target.value)}
                      className={errors.description ? `${classes.error} ${classes.formInput}` : classes.formInput}
                    />
                  </FormControl>
                  {errors.description && (
                    <FormHelperText error>
                      {errors.description.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <Box>Board</Box>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={8}>
                  <FormControl style={{width: '75%'}} margin="normal">
                    <Select
                      value={boardName}
                      onChange={(
                        e: React.ChangeEvent<{ value: unknown }>
                      ) =>
                        setBoardAndFetchClasses(e.target.value as string)
                      }
                      displayEmpty
                      className={errors.boardName ? `${classes.error} ${classes.formInput}` : classes.formInput}
                    >
                      <MenuItem value="">Select board</MenuItem>
                      {boardList.length > 0 &&
                        boardList.map((item) => (
                        <MenuItem
                          value={item}
                          key={item}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.boardName && (
                    <FormHelperText error>
                      {errors.boardName.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <Box>Class</Box>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={8}>
                  <FormControl style={{width: '75%'}} margin="normal">
                    <Select
                      value={className}
                      onChange={(
                        e: React.ChangeEvent<{ value: unknown }>
                      ) => setClassAndFetchSubjects(e.target.value as string)}
                      displayEmpty
                      className={errors.className ? `${classes.error} ${classes.formInput}` : classes.formInput}
                    >
                      <MenuItem value="">Select class</MenuItem>
                      {classList.length > 0 &&
                        classList.map((standard) => (
                          <MenuItem
                            value={standard}
                            key={standard}
                          >
                            {standard}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.className && (
                    <FormHelperText error>
                      {errors.className.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <Box>Subject</Box>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={8}>
                  <FormControl style={{width: '75%'}} margin="normal">
                    <Select
                      value={subject}
                      onChange={(
                        e: React.ChangeEvent<{ value: unknown }>
                      ) => setSubject(e.target.value as string)}
                      displayEmpty
                      className={errors.subject ? `${classes.error} ${classes.formInput}` : classes.formInput}
                    >
                      <MenuItem value="">Select subject</MenuItem>
                      {subjectList.length > 0 &&
                        subjectList.map((sub) => (
                          <MenuItem
                            value={sub}
                            key={sub}
                          >
                            {sub}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.subject && (
                    <FormHelperText error>
                      {errors.subject.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={availStudent}
                          onChange={() => setAvailStudent(prev => !prev)}
                          color="primary"
                        />
                      }
                      label="Allow for Mock Test Series"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <span style={{float: 'right', paddingRight: '7%', paddingTop: '25px'}} className={classes.addAssi}>

                <Button style={{marginLeft: '15px', marginBottom:'10px'}} color="primary" disableElevation variant="contained" onClick={() => {
                  if(isupdate) {
                    updateQB()
                  } else {
                    createQB()
                  }}}
                >
                  Next
                </Button>
              </span>
            </Grid>
          </Grid>
        </Box>
      </Container>
      </MiniDrawer>
    </div>
  );
}

export default QBCreate;