import React, { FunctionComponent, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Redirect } from 'react-router'
import { connect, useDispatch } from 'react-redux';
import { Button, Collapse, Link } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import routesList from '../../../routesList'
import { setAuthToken } from '../../redux/actions/authActions';
import { USER_ROLES } from '../../../utilities/constants';
import { setAdminsList, setCustomersList } from '../../redux/actions/adminActions';
import categorisedRoutesList from '../../../catogorisedRoutesList';
import { useSnackbar } from 'notistack';
const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		menuButton: {
			marginRight: 36,
		},
		hide: {
			display: 'none',
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: theme.spacing(7) + 1,
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9) + 1,
			},
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
		grow: {
			flexGrow: 1,
		},
		activeIcon: {
			color: '#4C8BF5',
			height: '24px', width: '24px',
		},
		inActiveIcon: {
			color: '#666666',
			height: '24px', width: '24px',
		},
		icon: {
			// width: '100%', height: '100%'
			height: '24px', width: '24px',
			//  margin: '0px 5px',
			//  transform: scale(2.5)
			// maxHeight: '24px', maxWidth: '24px',
		}
	}),
);

interface MiniDrawerProps {
	children: React.ReactNode
}

const MiniDrawer: FunctionComponent<MiniDrawerProps> = ({ children }) => {
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [redirectTo, setRedirectTo] = useState<string>('')
	const dispatch = useDispatch();
	const [menuOpen, setMenuOpen] = React.useState(false)
	const { enqueueSnackbar } = useSnackbar();

	const handleClick = (item: any) => {

		!item.subMenus.filter((el: any) => el.includedInNavbar && (loginUserType == USER_ROLES.super || allowedPermissions.some(perm => el.permissions.includes(perm)))).length &&
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });

		setMenuOpen(!menuOpen)
		item.expanded = !item.expanded
	}

	var allowedPermissions: string[] = [];
	var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
	var loginUserType = '';
	if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
		allowedPermissions = loginUserData.adminRole.permissions;
		loginUserType = loginUserData.adminRole.name;
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
		setMenuOpen(false);
		categorisedRoutesList.forEach((item) => item.expanded = false);
	};

	const handleLogout = () => {
		dispatch(setAuthToken(''));
		dispatch(setCustomersList([]));
		dispatch(setAdminsList([]));
		localStorage.clear();
		setRedirectTo('/login');
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, {
							[classes.hide]: open,
						})}
					>
						<MenuIcon />
					</IconButton>
					{/* <Typography variant="h6" noWrap>
						Hello {loginUserData && loginUserData.firstName ?
							(loginUserData.firstName + ' ' + (loginUserData.lastName ? loginUserData.lastName : '')) :
							'Admin'
						} !
					</Typography> */}
					<Link variant="h6" component="button" noWrap style={{ color: '#FFF', textDecorationLine: 'none' }}
						onClick={() => {
							if (window.location.pathname !== '/dashboard') {
								setRedirectTo('/dashboard')
							}
						}}
					>
						Edumatica Back Office
					</Link>

					<div className={classes.grow} />
					<Button onClick={handleLogout} color="inherit">Logout</Button>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
				onMouseEnter={handleDrawerOpen}
				onMouseLeave={handleDrawerClose}
			>
				<div className={classes.toolbar}>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</div>
				<Divider />
				<List style={{ paddingTop: '30px' }}>
					{/* {routesList.filter(el => el.includedInNavbar && (loginUserType == USER_ROLES.super || allowedPermissions.includes(el.permission))) */}
					{/* {routesList.filter(el => el.includedInNavbar)
						.map((item, index) => (
							<ListItem button key={index} onClick={() => { if (window.location.pathname !== item.redirectTo) setRedirectTo(item.redirectTo) }} style={{ paddingLeft: '25px' }}>
								<img src={item.icon}
									className={(window.location.pathname.substring(0, item.redirectTo.length) === item.redirectTo) ? classes.activeIcon : classes.inActiveIcon}
								/>
								<ListItemText primary={item?.name} style={{ paddingLeft: '15px' }}
									className={(window.location.pathname.substring(0, item.redirectTo.length) === item.redirectTo) ? classes.activeIcon : classes.inActiveIcon}
								/>
							</ListItem>
						))} */}

					{
						categorisedRoutesList.map((item: any, index: number) => {
							let routesArray = item.subMenus.filter((el: any) => el.includedInNavbar && (loginUserType == USER_ROLES.super || allowedPermissions.some(perm => el.permissions && el.permissions.includes(perm))))
							// console.log(routesArray)
							return (
								routesArray.length ?
									<div key={index}>
										<ListItem button onClick={() => handleClick(item)} style={{ paddingLeft: '25px' }}>
											{/* <ListItemIcon ><item.icon /></ListItemIcon> */}
											<img src={item.icon}
												className={classes.icon}
											/>
											<ListItemText primary={item.name} style={{ paddingLeft: '25px' }} />
											{/* <ListItemIcon >
										{menuOpen ? <ExpandLess /> : <ExpandMore />}
									</ListItemIcon> */}
										</ListItem>

										<Collapse in={item.expanded} timeout="auto" unmountOnExit>
											<Divider />
											<List component="div" disablePadding>
												{routesArray.map((menu: any, index: number) => (
													<ListItem button key={index}
														onClick={() => {
															if (window.location.pathname !== menu.redirectTo) {
																setRedirectTo(menu.redirectTo)
																handleDrawerClose()
															}
														}} >
														<ListItemText inset primary={menu.name} />
													</ListItem>
												))}
											</List>
										</Collapse>
									</div> :
									null
							)
						})
					}

				</List>
				<Divider />
			</Drawer>

			<main className={classes.content}>
				<div className={classes.toolbar} />
				{children}
			</main>

		</div>
	);
}

export default MiniDrawer
// export default connect(mapStateToProps)(MiniDrawer);
