import React, { useState, useEffect, FunctionComponent, FormEvent } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
	Box, Container, Grid, Checkbox, Button, TextField, Input, MenuItem, Menu,
	Typography, FormControlLabel, Switch, FormHelperText, OutlinedInput, IconButton, CssBaseline, Card, CardContent, CardHeader
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"


import MiniDrawer from "../../common/components/SideDrawer"
import useStyles from '../../dashboard/containers/customerManagement/styles';
import Datepickers from '../../dashboard/components/datepickers';
import Dropzone from '../../common/components/dropzone/dropzone';
import UploadedContent from "../../common/components/dropzone/previewers/uploadedContent"
import { postFeedback } from '../api';
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../utilities/constants';
import { convertToBase64, onApiError } from '../../common/helpers';

interface Props extends RouteComponentProps {

}


const Feedback: FunctionComponent<Props> = ({ location }) => {

	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const [customerName, setCustomerName] = useState<string>('')
	const [customerTitle, setCustomerTitle] = useState<string>('')
	const [school, setSchool] = useState<string>('')
	const [customerPicture, setCustomerPicture] = useState<string>('');
	const [fromDate, setFromDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [feedback, setFeedback] = useState<string>('');
	const styles = useStyles();


	const [dropzoneKey, setDropzoneKey] = useState(0)
	// const [droppedFiles, setDroppedFiles] = useState<File[]>([])
	const [picFiles, setPicFiles] = useState<File[]>([])

	useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.createFeedback);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	const addingFile = (files: File[], setter: any) => {
		if (files.length < 1) return;
		setter(files)
	}

	const removingFile = (setter: any) => setter([])

	const handleCreate = async () => {
		if (customerName.replace(/ /g, "").length < 4) {
			enqueueSnackbar('Customer name should be more than 4 characters', { variant: 'warning' });
			return;
		} else if (customerTitle.replace(/ /g, "").length < 4) {
			enqueueSnackbar('Customer title should be more than 4 characters', { variant: 'warning' });
			return;
		} else if (school.replace(/ /g, "").length < 4) {
			enqueueSnackbar('School/Institute should be more than 4 characters', { variant: 'warning' });
			return;
		} else if (!picFiles[0]) {
			enqueueSnackbar('Please upload valid picture', { variant: 'warning' });
			return;
		} else if (!fromDate) {
			enqueueSnackbar('Enter start date', { variant: 'warning' });
			return;
		} else if (!endDate) {
			enqueueSnackbar('Enter end date', { variant: 'warning' });
			return;
		} else if (feedback.replace(/ /g, "").length < 20) {
			enqueueSnackbar('Feedback should be more than 20 characters', { variant: 'warning' });
			return;
		} else {
			const customerPic = await convertToBase64(picFiles[0])
			var obj = {
				name: customerName,
				title: customerTitle,
				institute: school,
				customerPic,
				startDate: new Date(fromDate.setHours(0, 0, 0, 0)),
				endDate: new Date(endDate.setHours(23, 59, 0, 0)),
				feedback: feedback
			}

			await postFeedback(obj).then(() => {
				enqueueSnackbar('Feedback sent successfully', { variant: 'success' })
				setCustomerName('')
				setCustomerTitle('')
				setSchool('')
				setPicFiles([])
				setFeedback('')
			})
				.catch((err) => {
					onApiError(err, enqueueSnackbar, setRedirectTo)
				})
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Manage Users</Typography>
							</Grid> */}

							<Card className={styles.card} variant='outlined' >
								<CardHeader title='Customer Feedback' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent className={styles.cardContent}>

									<Grid container>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Customer Name</Box>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={8}>
											<Input
												name="customerName"
												placeholder="Enter Customer Name"
												inputProps={{ maxLength: 50 }}
												className={styles.margin}
												onChange={(e) => setCustomerName(e.target.value)}
												value={customerName}
											/>
										</Grid>
									</Grid>

									<Grid container >
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Customer Title</Box>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={8}>
											<Input
												name="customerTitle"
												placeholder="Enter Customer Title"
												inputProps={{ maxLength: 50 }}
												className={styles.margin}
												onChange={(e) => setCustomerTitle(e.target.value)}
												value={customerTitle}
											/>
										</Grid>
									</Grid>

									<Grid container >
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>School/Institute Name</Box>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={8}>
											<Input
												name="school"
												placeholder="Enter School/Institute Name"
												inputProps={{ maxLength: 50 }}
												className={styles.margin}
												onChange={(e) => setSchool(e.target.value)}
												value={school}
											/>
										</Grid>
									</Grid>

									<Grid container>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box >From Date</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Datepickers selectedDate={fromDate} handleDateChange={(date) => setFromDate(date)}

												/>
											</FormControl>
											{/* {dateError.dob &&
												<Typography className={classes.dateError}>{dateError.dob}</Typography>
											} */}
										</Grid>
									</Grid>

									<Grid container>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box >End Date</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Datepickers selectedDate={endDate} handleDateChange={(date) => setEndDate(date)}
												/>
											</FormControl>
											{/* {dateError.dob &&
												<Typography className={classes.dateError}>{dateError.dob}</Typography>
											} */}
										</Grid>
									</Grid>

									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Customer Picture</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<Dropzone
												key={0}
												acceptedFiles={['image/jpeg', 'image/png']}
												multipleFiles={false}
												maxFileSize={1048576} // 1 mb
												files={picFiles}
												onChange={(files) => addingFile(files, setPicFiles)}
											/>
											<UploadedContent
												files={picFiles}
												onRemoveItem={() => removingFile(setPicFiles)}
											/>
										</Grid>
									</Grid>

									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Feedback</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													name='feedback'
													placeholder='Feedback'
													inputProps={{ maxLength: 1000 }}
													multiline
													onChange={(e) => setFeedback(e.target.value)}
													value={feedback}
												/>
											</FormControl>
											{/* {errors.feedback && (
													<FormHelperText error>
														{errors.feedback.message}
													</FormHelperText>
												)} */}
										</Grid>
									</Grid>


									<Grid container justify='center' className={styles.submitBtn}>
										<Grid item xs={12} md={6} >
											<Button
												disableElevation
												variant="contained"
												color="primary"
												size="large"
												type="submit"
												disabled={loading}
												onClick={handleCreate}
											>
												Create
													</Button>
										</Grid>
									</Grid>

								</CardContent>
							</Card>

						</Grid>
					</Container>
				</div>
			</MiniDrawer >
		</>
	);
}

export default Feedback;
