import React, { useState, useEffect, FunctionComponent, } from 'react';
import { connect, } from "react-redux";
import FormControl from '@material-ui/core/FormControl';
import {
	Box, Container, Grid, Button, Input, Typography, CssBaseline, Card, CardContent, CardHeader, InputAdornment, IconButton
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"

import { Admin } from "../../../common/contracts/dashboard";
import { RootState } from "../../../redux/store";
import MiniDrawer from "../../../common/components/SideDrawer"
import { PASSWORD_LENGTH, PASSWORD_PATTERN, PHONE_PATTERN } from '../../../common/validations/patterns';
import { resetPasswordByAdmin, sendEmailPassword } from '../../../../api/dashboard';
import useStyles from './styles';
import PasswordVisibilityButton from '../../../common/components/password_visibility_button';
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../../utilities/constants';
import { ArrowBack } from '@material-ui/icons';

interface Props extends RouteComponentProps {
	admin: Admin
}

const ResetPassword: FunctionComponent<Props> = ({ admin, history }) => {
	const [isPasswordVisible1, setIsPasswordVisible1] = useState(false);
	const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);
	const [password1, setPassword1] = useState('');
	const [password2, setPassword2] = useState('');
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const { enqueueSnackbar } = useSnackbar();

	const styles = useStyles();

	useEffect(() => {
		getLoggedInUserData();
	}, []);

	const getLoggedInUserData = async () => {

		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.resetPassword);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	const validationChecks = () => {
		if (!admin || !admin.emailId) {
			enqueueSnackbar('Something went wrong', { variant: 'warning' });
			return;
		}
		if (!PASSWORD_PATTERN.test(password1)) {
			enqueueSnackbar('Password must contain at least one uppercase, lowercase, alphanumeric & special character', { variant: 'warning' });
			return;
		} else if (!PASSWORD_LENGTH.test(password1)) {
			enqueueSnackbar('Password must contain at least 8 characters', { variant: 'warning' });
			return
		} else if (password1 != password2) {
			enqueueSnackbar('Passwords should match', { variant: 'warning' });
			return;
		} else {
			return true
		}
	}

	const handleReset = async () => {
		if (validationChecks()) {
			setLoading(true);
			try {
				await resetPasswordByAdmin(admin.emailId, password1);
				setLoading(false);
				enqueueSnackbar('Updated successfully', { variant: 'success' });
			} catch (error) {
				setLoading(false);
				enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
				if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
					setRedirectTo('/login');
				}
			}
		}
	}

	const handleSendEmail = async () => {
		try {
			if (validationChecks()) {
				await sendEmailPassword(admin.firstName + ' ' + admin.lastName, admin.emailId, password1);
				enqueueSnackbar('Sent successfully', { variant: 'success' });
			}
		} catch (error) {
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Manage Users</Typography>
							</Grid> */}
							<Grid item className={styles.header}>
								<IconButton onClick={() => history.goBack()}>
									<ArrowBack />
								</IconButton>
								<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
							</Grid>

							<Card className={styles.card} variant='outlined'>
								<CardHeader title='Reset Password' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>

									<form onSubmit={handleReset} >

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>First Name</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="firstName"
													value={admin.firstName}
													disabled={true}
													className={styles.margin}
												/>
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Last Name</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="lastName"
													value={admin.lastName}
													disabled={true}
													className={styles.margin}
												/>
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Email</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="emailId"
													value={admin.emailId}
													disabled={true}
													className={styles.margin}
												/>
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Mobile Number</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="mobileNo"
													value={admin.mobileNo}
													disabled={true}
													className={styles.margin}
												/>
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Password</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="password"
													type={isPasswordVisible1 ? 'text' : 'password'}
													placeholder="Enter Default Password"
													inputProps={{ maxLength: 50 }}
													className={styles.margin}
													onChange={(e) => setPassword1(e.target.value)}
													endAdornment={
														<InputAdornment position="end">
															<PasswordVisibilityButton
																isVisible={isPasswordVisible1}
																handleChange={(isVisible) =>
																	setIsPasswordVisible1(isVisible)
																}
															/>
														</InputAdornment>
													}
												/>
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Re enter Password</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="rePassword"
													type={isPasswordVisible2 ? 'text' : 'password'}
													placeholder="Re enter Default Password"
													inputProps={{ maxLength: 50 }}
													className={styles.margin}
													onChange={(e) => setPassword2(e.target.value)}
													endAdornment={
														<InputAdornment position="end">
															<PasswordVisibilityButton
																isVisible={isPasswordVisible2}
																handleChange={(isVisible) =>
																	setIsPasswordVisible2(isVisible)
																}
															/>
														</InputAdornment>
													}
												/>
											</Grid>
										</Grid>

										<Grid container >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Role</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="adminRole"
													value={admin && admin.adminRole ? admin.adminRole.name : ''}
													disabled={true}
													className={styles.margin}
												/>
											</Grid>
										</Grid>


										<Grid container justify='center' className={styles.submitBtn}>
											<Grid item xs={12} md={5} >
												<Button
													disableElevation
													variant="contained"
													color="primary"
													size="large"
													disabled={loading}
													onClick={handleReset}
												>
													Save
													</Button>
											</Grid>
											<Grid item xs={12} md={5} >
												<Button
													disableElevation
													variant="contained"
													color="primary"
													size="large"
													disabled={loading}
													onClick={handleSendEmail}
												>
													Send Email
													</Button>
											</Grid>
										</Grid>

									</form>
								</CardContent>
							</Card>

						</Grid>
					</Container>
				</div>
			</MiniDrawer>
		</>)
}

const mapStateToProps = (state: RootState) => ({
	admin: state.adminReducer.admin as Admin,
});

export default connect(mapStateToProps)(ResetPassword);