import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link, Redirect, RouteComponentProps } from 'react-router-dom'
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../utilities/constants';
import * as XLSX from 'xlsx';
import MiniDrawer from "../../common/components/SideDrawer"
import { Box, Button, Container, CssBaseline, darken, FormControl, FormHelperText, Grid, makeStyles, MenuItem, Typography, Select, IconButton, Menu, Radio, Input, Divider } from '@material-ui/core';
import { fontOptions } from '../../../theme';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DownloadIcon from '../../../assets/images/download-icon.png';
import UploadIcon from '../../../assets/images/upload-icon.png';
import { downloadOptions, menuOptions, searchTxRadioOptions, uploadOptions } from '../constants/financeOperations_constants';
import Datepickers from '../../dashboard/components/datepickers';
import ReviewModal from '../components/modals/reviewModal';
import { getPaymentOrder } from '../../../api/finance';
import Dropzone from '../../common/components/dropzone/dropzone';
import { MprDataTable } from '../components/MprDataTable';
import { TxDataTable } from '../components/TxDataTable';

const useStyles = makeStyles({
	typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121'
	  },
});

const ITEM_HEIGHT = 48;

interface Props extends RouteComponentProps {

}

const FinanceOperation: FunctionComponent<Props> = ({ location }) => {
	const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('');
	const [modalType, setModalType] = useState<string | null>(null);
	const [modalData, setModalData] = useState<any[] | null>(null)
	const [openModal, setOpenModal] = useState<boolean>(false)
	// Main Side Menu
	const [activeMenuItem, setActiveMenuItem] = useState<string | null>(menuOptions[0]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
	// Search Tx
	const [activeSearchTxOption, setActiveSearchTxOption] = useState<string | null>(searchTxRadioOptions[0]);
	const [viewSearchTxData, setViewSearchTxData] = useState<any[] | null>(null);
	const [orderId, setOrderId] = useState<string | null>(null);
	const [mobileNo, setMobileNo] = useState<string | null>(null);
	const [emailId, setEmailId] = useState<string | null>(null);
	const [userName, setUserName] = useState<string | null>(null);
	const [fromDate, setFromDate] = useState<Date | null>(null);
	const [toDate, setToDate] = useState<Date | null>(null);
	// Upload Tx Reports
	const [activeUploadOption, setActiveUploadOption] = useState<string | null>(uploadOptions[0]);
	const [viewUploadData, setViewUploadData] = useState<any[] | null>(null);
	
	// Download Tx Reports
	const [activeDownloadOption, setActiveDownloadOption] = useState<string | null>(downloadOptions[0]);
	const [viewDownloadData, setViewDownloadData] = useState<any[] | null>(null);

	// Main Side Menu
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenu = (option: string) => {
        setActiveMenuItem(option);
        setAnchorEl(null);
		setModalType(null);
    }



    useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		const requiredPermissions = [MASTER_PERMISSIONS.payorder]
		var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

    const readExcel = (file: File | null) => {
		const promise = new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			if (file) {
				fileReader.readAsArrayBuffer(file);
				fileReader.onload = (e) => {
					const bufferArray = e.target ? e.target.result : '';
					const wb = XLSX.read(bufferArray, { type: 'buffer' });
					const wsname = wb.SheetNames[0];
					const ws = wb.Sheets[wsname];

					/* Convert array to json*/
					const jsonData = XLSX.utils.sheet_to_json(ws);
					resolve(jsonData);
				};
				fileReader.onerror = (error) => {
					reject(error);
				};
			}
		});
		promise.then(async (dataArr: any) => {
			const uploadReq: any = await Promise.all(
				dataArr &&
				dataArr.map(async (data: any) => {
					if(activeUploadOption === uploadOptions[0]) {
							return {
								orderId: data["Order Id"],
								receipt: data["Reference Id"],
								userName: data["Customer Name"],
								mobileNo: data["Customer Phone"],
								emailId: data["Customer Email"],
								notes: data["Order Note"],
								currency: data["Currency"],
								amount_paid: data["Amount"],
								gatewayServiceCharge: data["Service Charge"],
								serviceChargeTax: data["ST/GST"],
								settlementAmount: data["Settlement Amount"],
								paymentMode: data["Payment Mode"],
								bankName: data["Bank Name"],
								cardScheme: data["Card Scheme"],
								cardNo: data["Card Number"],
								cardCountry: data["Card Country"],
								txTime: data["Transaction Time"],
								txStatus: data["Transaction Status"],   //
								settlementStatus: data["Settlement"],   //
								settlementUTR: data["UTR No."],    //
								settledOn: data["Settled On"],
								isRefunded: data["Refunded"]
							}
					}
				})
			);
			setModalType(uploadOptions[0])
			setModalData(uploadReq)
			setOpenModal(true)
			
		});
	};

	const handleTxSearch = () => {
		if(activeMenuItem === menuOptions[0]){
			if(activeSearchTxOption === searchTxRadioOptions[0]) {
				// @ts-ignore
				getPaymentOrder({orderId:orderId, fromDate:fromDate, toDate:toDate}).then(data => {
					if(data.code === 0) {
						setModalType(menuOptions[0])
						setModalData(data.payOrders)
						setOpenModal(true)
						setViewSearchTxData(data.payOrders)
					}else {
						enqueueSnackbar(data.message, { variant: 'error' });
					}
				}).catch(err => {					
					setModalData([])
					enqueueSnackbar(err.response?.data.message, { variant: 'warning' });
					console.log(err)
				})
			}
			if(activeSearchTxOption === searchTxRadioOptions[1]) {
				// @ts-ignore
				getPaymentOrder({mobileNo:mobileNo, fromDate:fromDate, toDate:toDate}).then(data => {
					if(data.code === 0) {
						setModalType(menuOptions[0])
						setModalData(data.payOrders)
						setOpenModal(true)
						setViewSearchTxData(data.payOrders)
					}else {
						enqueueSnackbar(data.message, { variant: 'error' });
					}
				}).catch(err => {
					setModalData([])
					enqueueSnackbar(err.response?.data.message, { variant: 'warning' });
					console.log(err)
				})
			}
			if(activeSearchTxOption === searchTxRadioOptions[2]) {
				// @ts-ignore
				getPaymentOrder({emailId:emailId, fromDate:fromDate, toDate:toDate}).then(data => {
					if(data.code === 0) {
						setModalType(menuOptions[0])
						setModalData(data.payOrders)
						setOpenModal(true)
						setViewSearchTxData(data.payOrders)
					}else {
						enqueueSnackbar(data.message, { variant: 'error' });
					}
				}).catch(err => {
					setModalData([])
					enqueueSnackbar(err.response?.data.message, { variant: 'warning' });
					console.log(err)
				})
			}
			if(activeSearchTxOption === searchTxRadioOptions[3]) {
				// @ts-ignore
				getPaymentOrder({userName:userName, fromDate:fromDate, toDate:toDate}).then(data => {
					if(data.code === 0) {
						setModalType(menuOptions[0])
						setModalData(data.payOrders)
						setOpenModal(true)
						setViewSearchTxData(data.payOrders)
					}else {
						enqueueSnackbar(data.message, { variant: 'error' });
					}
				}).catch(err => {
					setModalData([])
					enqueueSnackbar(err.response?.data.message, { variant: 'warning' });
					console.log(err)
				})
			}
		}
	}

	


    return (
        <>
            <CssBaseline />
            <MiniDrawer>
			<Container maxWidth="xl">
				<Box
				bgcolor="#4C8BF5"
				padding="20px 30px"
				marginBottom="30px"
				position="relative"
				borderRadius="5px"
				color='#fff'
				>
				<Grid item container>
					<Grid item sm={8}>
					<Box style={{height: '100%'}}>
						<Grid
						container
						direction="row"
						alignItems="center"
						justify="center"
						style={{ height: '100%' }}
						>
						<Grid item xs={12}>
							<Typography className={classes.title}>
							    Finance Operations
							</Typography>
							<Typography>
							    Batch Transfer, Settlements, GST & Tax Reports etc.
							</Typography>
						</Grid>
						</Grid>
					</Box>
					</Grid>
				</Grid>
				</Box>

				<Box
				bgcolor="#ffffff"
				borderRadius="5px"
				padding="12px 25px"
				marginTop='25px'
				>
					<Grid container>
						<Grid item xs={12} style={{paddingTop: '5px', marginBottom: '8px'}}>
							<Box width="100%" display="flex" justifyContent="flex-start" alignItems="center">
								<div>
                                    <IconButton
										color="primary"
										// style={{border:"1px solid #4C8BF5"}}
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: '40ch',
                                        },
                                        }}
                                    >
                                        {menuOptions.map((option) => (
                                        <MenuItem key={option} selected={option === activeMenuItem} onClick={() => handleMenu(option)}>
                                            {option}
                                        </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
								<Typography style={{fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, color: '#3D3D3D', marginRight:"18px"}}>
									{activeMenuItem}
								</Typography>
								{
									activeMenuItem === menuOptions[0] ? 
										searchTxRadioOptions.map(el => {
											return (
												<>
													<Radio
														style={{marginLeft:"18px"}}
														checked={activeSearchTxOption === el}
														onChange={() => {
															setActiveSearchTxOption(el)
														}}
														value={activeSearchTxOption}
													/>
													{el}
												</>
											)
										})
									: activeMenuItem === menuOptions[1] ? 
										uploadOptions.map(el => {
											return (
												<>
													<Radio
														style={{marginLeft:"18px"}}
														checked={activeUploadOption === el}
														onChange={() => {
															setActiveUploadOption(el)
														}}
														value={activeUploadOption}
													/>
													{el}
												</>
											)
										})
									:
										downloadOptions.map(el => {
											return (
												<>
													<Radio
														style={{marginLeft:"18px"}}
														checked={activeDownloadOption === el}
														onChange={() => {
															setActiveDownloadOption(el)
														}}
														value={activeDownloadOption}
													/>
													{el}
												</>
											)
										})
								}
                            </Box>
                        </Grid>
					</Grid>
					<Divider style={{margin: "8px 0"}}/>
					<Box maxWidth="580px" paddingLeft="80px">
					{
						activeMenuItem === menuOptions[0] ?
						<>
						{
							activeSearchTxOption === searchTxRadioOptions[0] ? 
							<Grid container>
								<Grid item xs={12} md={4}>
									<FormControl fullWidth margin="normal">
										<Box fontWeight="bold" marginTop="5px">
										Enter Order ID <span>*</span>
										</Box>
									</FormControl>
								</Grid>
			
								<Grid item xs={12} md={8}>
									<FormControl fullWidth margin="normal">
										<Input
											placeholder="Order ID"
											value={orderId}
											inputProps={{ maxLength: 50 }}
											onChange={(e) => setOrderId(e.target.value as string)}
										/>
									</FormControl>
								</Grid>
							</Grid>
							:
							activeSearchTxOption === searchTxRadioOptions[1] ? 
							<Grid container>
								<Grid item xs={12} md={4}>
									<FormControl fullWidth margin="normal">
										<Box fontWeight="bold" marginTop="5px">
										Enter Mobile No. <span>*</span>
										</Box>
									</FormControl>
								</Grid>
			
								<Grid item xs={12} md={8}>
									<FormControl fullWidth margin="normal">
										<Input
											placeholder="Mobile No."
											value={mobileNo}
											inputProps={{ maxLength: 10 }}
											onChange={(e) => setMobileNo(e.target.value as string)}
										/>
									</FormControl>
								</Grid>
							</Grid>
							:
							activeSearchTxOption === searchTxRadioOptions[2] ? 
							<Grid container>
								<Grid item xs={12} md={4}>
									<FormControl fullWidth margin="normal">
										<Box fontWeight="bold" marginTop="5px">
										Enter Email ID <span>*</span>
										</Box>
									</FormControl>
								</Grid>
			
								<Grid item xs={12} md={8}>
									<FormControl fullWidth margin="normal">
										<Input
											placeholder="Email ID"
											value={emailId}
											inputProps={{ maxLength: 80 }}
											onChange={(e) => setEmailId(e.target.value as string)}
										/>
									</FormControl>
								</Grid>
							</Grid>
							: 
							activeSearchTxOption === searchTxRadioOptions[3] ?
							<Grid container>
								<Grid item xs={12} md={4}>
									<FormControl fullWidth margin="normal">
										<Box fontWeight="bold" marginTop="5px">
										Name <span>*</span>
										</Box>
									</FormControl>
								</Grid>
			
								<Grid item xs={12} md={8}>
									<FormControl fullWidth margin="normal">
										<Input
											placeholder="User Name"
											value={userName}
											inputProps={{ maxLength: 50 }}
											onChange={(e) => setUserName(e.target.value as string)}
										/>
									</FormControl>
								</Grid>
							</Grid>
							:
							''
						}
						{
							activeSearchTxOption === searchTxRadioOptions[0] ? '' :
							<>
							<Grid container>
								<Grid item xs={12} md={4}>
									<FormControl fullWidth margin="normal">
										<Box fontWeight="bold" marginTop="5px">
										From Date
										</Box>
									</FormControl>
								</Grid>
			
								<Grid item xs={12} md={8}>
									<FormControl fullWidth margin="normal">
										<Datepickers selectedDate={fromDate} handleDateChange={(date) => setFromDate(date)}/>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={12} md={4}>
									<FormControl fullWidth margin="normal">
										<Box fontWeight="bold" marginTop="5px">
										To Date
										</Box>
									</FormControl>
								</Grid>
			
								<Grid item xs={12} md={8}>
									<FormControl fullWidth margin="normal">
										<Datepickers selectedDate={toDate} handleDateChange={(date) => setToDate(date)}/>
									</FormControl>
								</Grid>
							</Grid>
							</>
						}
							<Grid container>
								<Grid item xs={12}>
									<Button style={{margin:"18px 0", width:"100%"}} onClick={handleTxSearch} disableElevation variant="contained" color="primary">
										Search
									</Button>
								</Grid>
							</Grid>
						</>
						: activeMenuItem === menuOptions[1] ? 
							<Grid container>
								<Grid item xs={12} style={{paddingTop: '5px', marginBottom: '8px'}}>
								<Dropzone
									filesLimit={1}
									dropzoneParagraphClass="Please upload excel File - XLS XLSX only"
									acceptedFiles={[
									".csv",
									"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
									"application/vnd.ms-excel",
									]}
									onChange={(files: File[]) => {
										readExcel(files[0]);
									}}
								/>
								</Grid>
							</Grid>
						:
							<Grid container>
								<Grid item xs={12} style={{paddingTop: '5px', marginBottom: '8px'}}>
									
								</Grid>
							</Grid>
					}
					</Box>
					<Grid container>
						<Grid item xs={12}>
							{
								!activeUploadOption || !modalType ? null :
									(modalType === uploadOptions[0] && modalData?.length ? 
									<MprDataTable gridData={modalData} />
									:( modalType === menuOptions[0] && modalData?.length ? 
									<TxDataTable gridData={modalData} />
									: null))
							}
						</Grid>
					</Grid>
				</Box>
			</Container>
            </MiniDrawer>
			{/* {
				!activeUploadOption || !modalType ? '' :
				<ReviewModal
					openModal={openModal}
					onClose={() => {setOpenModal(false); setModalType(null)}}
					viewData={modalData}
					activeType={modalType}
				/>
			} */}
        </>   
    )
}

export default FinanceOperation
