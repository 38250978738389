import { FunctionComponent, useEffect, useState } from "react";
import React from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  CssBaseline,
  Grid,
} from "@material-ui/core";
import Button from "../../../common/components/form_elements/button";
import { RouteComponentProps, useHistory } from "react-router";
import MiniDrawer from "../../../common/components/SideDrawer";
import { CustomUser } from "../../../common/contracts/dashboard";
import { RootState } from "../../../redux/store";
import { connect } from "react-redux";
import { getPermission, updatePermission } from "../../../../api/dashboard";
import { useSnackbar } from "notistack";

interface Props extends RouteComponentProps {
  user: CustomUser;
}

const AddPermission: FunctionComponent<Props> = ({ user }) => {
  const { enqueueSnackbar } = useSnackbar();
  console.log("user:", user);
  const [redirectTo, setRedirectTo] = useState<string>("");
  const history = useHistory();

  const OrganizationSearch: string[] = [
    "Dashboard",
    "Manage Faculty",
    "Manage User",
    "Admin",
    "Degree Courses",
    "Degree Programs",
    "Degree Admissions",
    "CRM",
    "Manage Students",
    "Batches",
    "Attendance",
    "Student Promotions",
    "Group Discussion",
    "Student Requests",
    "Onscreen Evaluation",
    "Marksheet",
    "All Schedules",
    "Holidays/Events",
    "Demo Classes",
    "Lesson Plan",
    "Course Content",
    "Assessment",
    "Assignments",
    "Question Bank",
    "Edumatica Courses",
    // student side only
    "College Based Courses",
    "Meeting History",
    // student side only
    "Worksheet",
    // student side only
    "Notes",
    // student side only
    "Mock Test",
    "Interactive Worksheets",
    // student side only
    "Certificates",
    "Physical Library",
    "Digital Library",
    "Assets Management",
    "Manage Alumni",
    "Feedback System",
    "Manage Leave",
    "Staff Attendance",
    "Payment",
    "Fee Collections",
    "Manage Expenses",
    "Manage Fees",
    "Payroll",
    "Public Profile",
    "Analytics",
    "Settings",
    "Roles and Permissions",
    "KYC",
    "Announcements",
    "Email Integration",
    "SMS Integration",
    "Statcraft Integration",
    "OJTL",
    "OBE",
  ];

  const [state, setState] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!state?.includes(event.target.name)) {
      setState([...state, event.target.name]);
    } else {
      setState(state.filter((el) => el !== event.target.name));
    }
  };

  const handleUpdatePermission = async () => {
    const payload = {
      permissions: state,
      ownerId: user?.ownerId,
    };
    try {
      await updatePermission(payload);
      enqueueSnackbar("Choices Saved successfully", { variant: "success" });
      history.push("/search-customer");
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.response?.data.message, { variant: "warning" });
      if (
        error.response?.status === 401 &&
        error.response?.data.message !== "TokenExpiredError"
      ) {
        setRedirectTo("/login");
      }
    }
  };

  useEffect(() => {
    handleGetPermission();
  }, []);

  const handleGetPermission = async () => {
    const ownerId = user?.ownerId;
    try {
      const res = await getPermission(ownerId);
      const payload = res.permission;

      setState(payload);
    } catch (error) {
      console.log(error);
      if (
        error.response?.status === 401 &&
        error.response?.data.message !== "TokenExpiredError"
      ) {
        setRedirectTo("/login");
      }
    }
  };

  return (
    <>
      <CssBaseline />
      <MiniDrawer>
        <div style={{ height: "80vh", width: "100%", gap: "2%" }}>
          <div>
            <h1>Select Modules to Disable The Access</h1>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {state &&
              OrganizationSearch?.map((permissions: string) => {
                console.log("permissions ---", permissions);

                let isPresent = state?.includes(permissions);
                console.log("isPresent ---", isPresent);
                return (
                  <FormControlLabel
                    style={{ width: "23%" }}
                    control={
                      <Checkbox
                        onChange={handleChange}
                        checked={isPresent}
                        name={permissions}
                      />
                    }
                    label={permissions}
                  />
                );
              })}
          </div>
          <br />
          <Grid container sm={12} xs={12} style={{ marginTop: "1rem" }}>
            <Grid item sm={8}></Grid>
            <Grid item sm={3} style={{ marginLeft: "3rem" }}>
              <Button
                style={{ padding: "0.7rem 2rem 0.7rem 2rem", fontSize: "1rem" }}
                disableElevation
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                onClick={handleUpdatePermission}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <br />
          <br />
        </div>
      </MiniDrawer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(AddPermission);
