import React, { useState, useEffect, FunctionComponent, FormEvent } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
	Box, Container, Grid, Checkbox, Button, TextField, Input, MenuItem, Menu,
	Typography, FormControlLabel, Switch, FormHelperText, OutlinedInput, IconButton, CssBaseline, Card, CardContent, CardHeader, Select
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"

import MiniDrawer from "../../common/components/SideDrawer"
import useStyles from '../../dashboard/containers/customerManagement/styles';
import Datepickers from '../../dashboard/components/datepickers';
import Dropzone from '../../common/components/dropzone/dropzone';
import { createJob, getJobId } from '../api';
import { compareAsc } from 'date-fns';
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../utilities/constants';
import { onApiError } from '../../common/helpers';

interface Props extends RouteComponentProps {

}

const CreateJob: FunctionComponent<Props> = ({ location }) => {

	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const [name, setName] = useState<string>('')
	const [department, setDepartment] = useState<string>('')
	const [jobId, setJobId] = useState<string>('')
	const [brief, setBrief] = useState<string>('')
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [fullJD, setFullJD] = useState<string>('');
	const styles = useStyles();


	useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		const requiredPermissions = [MASTER_PERMISSIONS.jobposting, MASTER_PERMISSIONS.getJobId]
		var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	const handleCreate = async () => {
		if (name.replace(/ /g, "").length < 4) {
			enqueueSnackbar('Job title should be more than 4 characters', { variant: 'warning' });
			return;
		} else if (!department) {
			enqueueSnackbar('Please select department', { variant: 'warning' });
			return;
		} else if (brief.replace(/ /g, "").length < 4) {
			enqueueSnackbar('Brief should be more than 4 characters', { variant: 'warning' });
			return;
		} else if (!startDate) {
			enqueueSnackbar('Enter start date', { variant: 'warning' });
			return;
		} else if (!endDate) {
			enqueueSnackbar('Enter end date', { variant: 'warning' });
			return;
		} else if (compareAsc(startDate, endDate) == 1) {
			enqueueSnackbar("End date should be after start date", { variant: "error" })
			return;
		} if (startDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
			enqueueSnackbar("Start date should be today or after today", { variant: "error" })
			return
		} else if (endDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
			enqueueSnackbar("End date should be today or after today", { variant: "error" })
			return
		} else if (fullJD.length < 5) {
			enqueueSnackbar('Job description should be more than 5 characters', { variant: 'warning' });
			return;
		} else {
			var obj = {
				name,
				department,
				jobId,
				brief,
				fullJD,
				startDate: new Date(startDate.setHours(0,0,0,0)),
				endDate: new Date(endDate.setHours(23,59,0,0)),
				status: 'Fresh'
			}

			await createJob(obj).then(() => {
				enqueueSnackbar('Job created successfully', { variant: "success" })
				setName('')
				setBrief('')
				setFullJD('')
				setDepartment('')
				setJobId('')
			})
			.catch(err => onApiError(err, enqueueSnackbar))


		}
	}

	const updateDept = async (dept: string) => {
		setDepartment(dept)
		setJobId(await getJobId(dept))
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const renderInfo = (key: string, value: string) => (
		<Grid container>
			<Grid item xs={12} md={4}>
				<FormControl fullWidth margin="normal">
					<Box className={styles.label}>{key}</Box>
				</FormControl>
			</Grid>

			<Grid item xs={12} md={8}>
				<FormControl fullWidth margin="normal">
					<Box>{value}</Box>
				</FormControl>
			</Grid>
		</Grid>
	)

	const renderInput = (key: string, value: any, setter: any) => (
		<Grid container>
			<Grid item xs={12} md={4}>
				<FormControl fullWidth margin="normal">
					<Box className={styles.label}>{key}</Box>
				</FormControl>
			</Grid>

			<Grid item xs={12} md={8}>
				<FormControl fullWidth margin="normal">
					<Input
						placeholder={key}
						value={value}
						inputProps={{ maxLength: 100 }}
						onChange={(e) => setter(e.target.value)}
					/>
				</FormControl>
			</Grid>
		</Grid>
	)

	const renderDropdown = (key: string, list: string[], value: string, setter: any) => (
		<Grid container>
			<Grid item xs={12} md={4}>
				<FormControl fullWidth margin="normal">
					<Box className={styles.label}>{key}</Box>
				</FormControl>
			</Grid>

			<Grid item xs={12} md={8}>
				<FormControl fullWidth margin="normal">
					<Select
					value={value}
					onChange={(e: any) => setter(e.target.value)}
					>
						<MenuItem value="">Select</MenuItem>
						{list.map((item: any, index: any) => (
							<MenuItem value={item} key={index}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
	)

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Manage Users</Typography>
							</Grid> */}

							<Card className={styles.card} variant='outlined' >
								<CardHeader title='Create Job posting' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent className={styles.cardContent}>

									{renderInput('Job Title', name, setName)}
									{renderDropdown('Department', ['Engineering', 'Marketing', 'Human Resource', 'Sales', 'Operation', 'Admin', 'Finance'], department, updateDept)}
									{jobId.length > 1 && renderInfo('Job Id', jobId)}
									{renderInput('Brief', brief, setBrief)}

									<Grid container>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Start Date</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Datepickers selectedDate={startDate} handleDateChange={(date) => setStartDate(date)}
													minDate={new Date()}
												/>
											</FormControl>
											{/* {dateError.dob &&
												<Typography className={classes.dateError}>{dateError.dob}</Typography>
											} */}
										</Grid>
									</Grid>

									<Grid container>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>End Date</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Datepickers selectedDate={endDate} handleDateChange={(date) => setEndDate(date)}
													minDate={new Date()}
												/>
											</FormControl>
											{/* {dateError.dob &&
												<Typography className={classes.dateError}>{dateError.dob}</Typography>
											} */}
										</Grid>
									</Grid>

									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Job Description</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													name='fullJD'
													placeholder='Job Description'
													inputProps={{ maxLength: 1000 }}
													multiline
													onChange={(e) => setFullJD(e.target.value)}
													value={fullJD}
												/>
											</FormControl>
											{/* {errors.feedback && (
													<FormHelperText error>
														{errors.feedback.message}
													</FormHelperText>
												)} */}
										</Grid>
									</Grid>


									<Grid container justify='center' className={styles.submitBtn}>
										<Grid item xs={12} md={6} >
											<Button
												disableElevation
												variant="contained"
												color="primary"
												size="large"
												type="submit"
												disabled={loading}
												onClick={handleCreate}
											>
												Create
											</Button>
										</Grid>
									</Grid>

								</CardContent>
							</Card>

						</Grid>
					</Container>
				</div>
			</MiniDrawer >
		</>
	);
}

export default CreateJob;