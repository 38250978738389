import axios from "axios"
import { FetchQB, QuestionBank, SmallUpdate, WSQB } from "../contracts/qb_interface"
import { GET_QUESTIONBANK, GET_QUESTIONBANK_SET, PATCH_QUESTIONBANK, POST_QUESTIONBANK, PUT_QUESTIONBANK } from "./routes"

export interface FetchQBResponse {
    questionBankList: FetchQB[]
  }
  
  export interface FetchQBListResponse {
    questionBank: QuestionBank[]
  }
  
  export interface FetchQBNamesResponse {
    questionBanks: WSQB[]
  }

export const postQuestionBank = async (structutredQB: QuestionBank[]) => {
    return axios.post(POST_QUESTIONBANK, {questionBank: structutredQB})
  }
  
  export const smallUpdateQuestionBank = async (structutredSmallQB: SmallUpdate) => {
    return axios.patch(PATCH_QUESTIONBANK, structutredSmallQB)
  }
  
  export const fetchQuestionBank = async () =>{
    const response = await axios.get<FetchQBResponse>(GET_QUESTIONBANK)
    return response.data.questionBankList
  }
  
  export const fetchQuestionBankSet = async (qbName: string, avail: boolean) =>{
    const response = await axios.get<FetchQBListResponse>(GET_QUESTIONBANK_SET, {params: {questionBankFriendlyName: qbName, availableForMockTest: avail}})
    return response.data.questionBank
  }
  
  export const deleteQuestionBank = async (questionBankFriendlyName: string) => {
    await axios.delete(GET_QUESTIONBANK_SET, {params: {questionBankFriendlyName}})
  }
  
//   export const getQBNames = async (boardname: string, classname: string, subject: string, isMock: boolean) =>{
//     const response = await axios.post<FetchQBNamesResponse>(GET_QBNAMES, {boardname: boardname, classname: classname, subjectListArr: [subject], isMock: isMock})
//     return response.data.questionBanks
//   }
  
  interface ModQuestionBankStatus extends QuestionBank {
    status: number
  }
  
  export const putQuestionBank = async (structutredQB: ModQuestionBankStatus[]) => {
    return axios.put(PUT_QUESTIONBANK, {questionBank: structutredQB})
  }