export enum resourceType {
    blog = "Blog",
    ebook = "E-book",
    video = "Video",
    feedback = "Feedback"
}

export interface webResource {
    _id?: string,
    type: resourceType,
    title: string,
    startDate: Date,
    endDate: Date,
    thumbnail?: string,
    uuid?: string,
    url?: string,
    contentType?: string,
}

export interface webResourceRes {
    _id?: string,
    type: resourceType,
    title: string,
    startDate: string,
    endDate: string,
    thumbnail?: string,
    uuid?: string,
    url?: string,
    contentType?: string,
}

export interface feedback {
    _id: string,
    name: string,
    title: string,
    startDate: Date,
    endDate: Date,
    feedback: string,
    institute: string,
    customerPic: string,
}

export interface feedbackRes {
    _id: string,
    name: string,
    title: string,
    startDate: string,
    endDate: string,
    feedback: string,
    institute: string,
    customerPic: string,
}