import React, { useState, useEffect, FunctionComponent } from "react";
import { Redirect } from 'react-router'
import {
	Box,
	Container,
	CssBaseline,
	darken,
	FormControl,
	Grid,
	IconButton,
	makeStyles,
	MenuItem,
	Select,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Edit, Visibility } from "@material-ui/icons";

import Button from "../../common/components/form_elements/button";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import MiniDrawer from "../../common/components/SideDrawer";
import { fontOptions } from "../../../theme";
import { MASTER_PERMISSIONS } from "../../../utilities/constants";
import Datagrids, { datagridCellExpand } from "../../dashboard/components/dataGrid";
import { getLoggedInUserData, onApiError} from '../../common/helpers';
import { fetchNotificationTemplate, fetchNotificationTemplateTypes } from "../api";
import { notificationTemplate } from "../interfaces";
import EditMasterNotificationTemplateModal from "../components/editMasterNotificationTemplateModal";

const useStyles = makeStyles({
	typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121',
	  },
      label: {
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.small,
      },
});

const ViewNotificationTemplates: FunctionComponent = () => {
    const classes = useStyles();

    const [templateType, setTemplateType] = useState('')
    const [messageTypes, setMessageTypes] = useState<string[]>([])
    const [emailTypes, setEmailTypes] = useState<string[]>([])
    const [messageType, setMessageType] = useState<string>('')
    const [template, setTemplate] = useState<notificationTemplate>()

    const [openEditModal, setOpenEditModal] = useState(false)

    const [redirectTo, setRedirectTo] = useState<string>('')
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        getLoggedInUserData(setRedirectTo, [MASTER_PERMISSIONS.masterQuestions], enqueueSnackbar, getNotificationTemplateTypes)
    }, [])

    if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

    const getNotificationTemplateTypes = async () => {
        await fetchNotificationTemplateTypes().then((val) => {
            setMessageTypes(val.smsTypes)
            setEmailTypes(val.emailTypes)
        })
        .catch(err => onApiError(err, enqueueSnackbar, setRedirectTo))
    }

    const handleFetchTemplate = async () => {
        if(messageType.length < 1) {
            enqueueSnackbar('Select a template type', {variant: 'warning'})
            return;
        }
        await fetchNotificationTemplate({messageType}).then(val => setTemplate(val))
        .catch(err => onApiError(err, enqueueSnackbar, setRedirectTo))
    }

    const handleEdit = () => {
        setOpenEditModal(true)
    }

    const renderDropdown = (key: string, value: any, list: string[], setter: any) => (
        <Grid container>
			<Grid item xs={12} md={4}>
				<FormControl fullWidth margin="normal">
					<Box className={classes.label}>{key}</Box>
				</FormControl>
			</Grid>

			<Grid item xs={12} md={8}>
				<FormControl fullWidth margin="normal">
					<Select
						value={value}
						onChange={(e: any) => setter(e.target.value)}
					>
						<MenuItem value="">Select</MenuItem>
						{list.map((item: any, index: any) => (
							<MenuItem value={item} key={index}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
    )

    const renderButton = (name: string, cb: any) => (
        <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item>
                <Button variant="contained" color="primary" size="medium"
                    onClick={cb}
                >
                    {name}
                </Button>
            </Grid>
        </Grid>
    )

    if(template) {
        var {htmlIdentifierText, msgIdentifierText} = template
    }

    return(
        <>
        <CssBaseline />
        <MiniDrawer>
        {template && <EditMasterNotificationTemplateModal
            key={template.messageType}
            openModal={openEditModal}
            onClose={() => {
                setOpenEditModal(false)
            }}
            template={template}
        />}
        <Container maxWidth="lg">
            <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="5px"
            color='#fff'
            >
            <Grid item container>
                <Grid item sm={8}>
                <Box style={{height: '100%'}}>
                    <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                    >
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            Edit Notification Templates
                        </Typography>
                        <Typography>
                            View and Edit Message and E-mail templates
                        </Typography>
                    </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            </Box>

            <Box
				bgcolor="#ffffff"
				borderRadius="5px"
				padding="12px 25px"
				marginTop='25px'
			>
                <Grid container justify='center'>
                    <Grid item xs={12} lg={6} style={{paddingTop: '5px', marginBottom: '8px'}}>
                        {renderDropdown('Select template type', templateType, ['Message', 'Email'], setTemplateType)}
                        {templateType === 'Message' && renderDropdown('Select Message Template', messageType, messageTypes, setMessageType)}
                        {templateType === 'Email' && renderDropdown('Select Email Template', messageType, emailTypes, setMessageType)}
                        {renderButton('Fetch Template', handleFetchTemplate)}
                    </Grid>
                    <Grid item xs={12}>
                        {msgIdentifierText && 
                        <>
                        <Box className={classes.label}>Message Template</Box>
                        <br />
                        <Box>{msgIdentifierText}</Box>
                        <br />
                        {renderButton('Edit', handleEdit)}
                        </>}

                        {htmlIdentifierText &&
                        <>
                        <Box className={classes.label}>Email Template</Box>
                        <br />
                        { <div dangerouslySetInnerHTML={{__html: htmlIdentifierText}} /> }
                        <br />
                        {renderButton('Edit', handleEdit)}
                        </>}
                    </Grid>
                </Grid>
            </Box>   
        </Container>
        </MiniDrawer>
        </>
    )
}

export default ViewNotificationTemplates;