import { Grid, IconButton, Box, Tooltip, Typography } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, {FunctionComponent, useState, useEffect} from "react";
import { addStyles, EditableMathField, MathField, StaticMathField } from 'react-mathquill'
import { mathSymbol } from "../../contracts/qb_interface";
import { mathSymbolsResponse } from "./mathSymbolsResponse";
import { useStyles } from "./styles";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SymbolsDialog from "./symbolsDialog";
import Button from '../../../common/components/form_elements/button';
import { fontOptions } from "../../../../theme";
//@ts-ignore
import { parse, HtmlGenerator } from 'latex.js'
import ContentEditable from 'react-contenteditable'

addStyles()

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    textBox: {
        border: '1px dashed #4C8BF5',
        borderRadius: '5px',
    }
  }),
);

interface Props {
    latex: string,
    setLatex: (lat: string) => void,
    reset: boolean
    addImages?: any
    style?: any,
    endAdornment?: any
}

interface textType {
  type: 'normal' | 'math',
  value: string,
  Mathel?: MathField
}

const MathEditor:FunctionComponent<Props>  = ({latex, setLatex, addImages, style, endAdornment, reset}) => {
    const [mathSymbols, setMathSymbols] = useState<mathSymbol[]>([])
    const [symbolsDialog, setSymbolsDialog] = useState(false)
    const [values, setValues] = useState<textType[]>([{type: 'normal', value: ''}])
    const [lastActive, setLastActive] = useState(-1)
    const [lastFocus, setLastFocus] = useState<'normal'|'math'>('normal')
    const currentMath = React.useRef<EditableMathField|null>(null)
    const classes = useStyles()
    const stylez = useStyle()

    useEffect(() => {
      const thisVals:string[] = [];
      [...values].forEach(val => {
        if(val.type === 'normal') {
          if(val.value.length > 0) {
            thisVals.push(val.value.replace(/&nbsp;/g, ' '))
          } else {
            thisVals.push(' ')
          }
        } else {
          if(val.value.length > 0) {
            thisVals.push('$' + val.value + '$')
          } else {
            thisVals.push('$' + ' ' + '$')
          }
        }
      })

      

      const filterVal = thisVals.filter(fil => (fil !== " " && fil !== "$ $"))
      const updatedVal: string[] = []

      filterVal.forEach(fv => {
        if(updatedVal.length === 0) {
          updatedVal.push(fv)
        } else {
          if(updatedVal[updatedVal.length - 1].charAt(0) === '$' && updatedVal[updatedVal.length - 1].charAt(updatedVal[updatedVal.length - 1].length - 1) === '$' && fv.charAt(0) === '$' && fv.charAt(fv.length - 1) === '$') {
            updatedVal[updatedVal.length - 1] = updatedVal[updatedVal.length - 1].substring(0, updatedVal[updatedVal.length - 1].length - 1) + ' ' + fv.substring(1, updatedVal[updatedVal.length - 1].length)
          } else if(updatedVal[updatedVal.length - 1].charAt(0) !== '$' && updatedVal[updatedVal.length - 1].charAt(updatedVal[updatedVal.length - 1].length - 1) !== '$' && fv.charAt(0) !== '$' && fv.charAt(fv.length - 1) !== '$') {
            updatedVal[updatedVal.length - 1] = updatedVal[updatedVal.length - 1] + ' ' + fv
          } else {
            updatedVal.push(fv)
          }
        }
      })

      setLatex(updatedVal.join(''))
    }, [values])

    useEffect(() => {
      getMathsSymbols()
    }, [])

    

    useEffect(() => {
      const word = latex.replaceAll('$$', '$');
      const vals: textType[] = []
      if(word.charAt(0) === '$') {
        const splitedWord = word.substring(1, word.length -1).split('$')
        splitedWord.forEach((sw, ind) => {
          const val: textType = (ind % 2 === 0) ? {type: 'math', value: sw} : {type: 'normal', value: sw}
          vals.push(val)
        })
      } else {
        const splitedWord = word.split('$')
        splitedWord.forEach((sw, ind) => {
          const val: textType = (ind % 2 === 0) ? {type: 'normal', value: sw} : {type: 'math', value: sw}
          vals.push(val)
        })
      }

      setValues(vals)
    }, [reset])

    const getMathsSymbols = () => {
        setMathSymbols(mathSymbolsResponse);
    };

    const latexParserCheck =  (latexString:String) =>{
        let generator = new HtmlGenerator({ hyphenate: false })
        let doc = parse(latexString, { generator: generator }).htmlDocument()
        return doc.documentElement.outerHTML
    }
    
      const ifValidLatex = (latexString:string) => {
        let result;
        try {
          const parser = latexParserCheck(latexString);
          result = true
        } catch (error) {
          result = false
        }
    
        return result
    }

    const handleSymbolsClick = () => {
        setSymbolsDialog(true)
    }
    const handleSubmit = (symbol: mathSymbol) => {
        if(!symbol) return
        if(!values[lastActive].Mathel) return
        values[lastActive].Mathel?.write(symbol.output)
        // if(values[lastActive].value.includes('{ }')){
        //     const allVal = [...values]
        //     allVal[lastActive].value.replace("{ }",symbol.output)
        //     setValues(allVal)
        // } else {
        //     const allVal = [...values]
        //     allVal[lastActive].value = values[lastActive].value + symbol.output
        //     setValues(allVal)
        // }
        setSymbolsDialog(false)
    }

    const setValueFunc = (index: number, thisValue: string) => {
      setValues(prev => {
        const newValue = [...prev]
        newValue[index] = {...newValue[index], ...{value: thisValue}}
        return newValue
      })
    }
  
    const add = () => {
      const newValue = [...values]
      newValue.push({type: 'math', value: ''})
      setLastActive(newValue.length)
      setValues(newValue)
    }
  
    const stop = () => {
      const newValue = [...values]
      newValue.push({type: 'normal', value: ''})
      setValues(newValue)
    }

    return(
      <div style={style ? style : {}}>
        {symbolsDialog &&
          <SymbolsDialog 
            open={symbolsDialog}
            mathSymbols={mathSymbols}
            onClose={() => setSymbolsDialog(false)}
            handleSubmit={handleSubmit}
          />
        }

        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row-reverse">
              {endAdornment &&
                <Box>
                  {endAdornment}
                </Box>
              }
              {addImages &&
                <Box>
                  {addImages}
                </Box>
              }
              {(lastFocus === 'math' && (values[values.length - 1].type === 'math')) &&
                <Box>
                  <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}} onClick={handleSymbolsClick}>Add Symbol</Typography>
                </Box>
              }
              {(values[values.length - 1].type === 'normal') &&
                <Box>
                  <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}} onClick={add}>Start Math Mode</Typography>
                </Box>
              }
              {(values[values.length - 1].type === 'math') &&
                <Box>
                  <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}} onClick={stop}>End Math Mode</Typography>
                </Box>
              }
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box border='1px dashed #4C8BF5' borderRadius='5px' minHeight="80px" marginTop="10px" style={{padding: '25px'}}>
              <Grid container>
                <Grid item xs={12}>
                  {values.map((val, ind) => {
                    if(val.type === 'normal') {
                      return (
                        <ContentEditable
                          html={val.value} 
                          disabled={false}    
                          onPaste={(e: React.ClipboardEvent<HTMLDivElement>) => {
                            // cancel paste
                            e.preventDefault();
                            // get text representation of clipboard
                            var text = e.clipboardData.getData("text/plain");
                            // insert text manually
                            document.execCommand("insertText", false, text);
                          }}
                          onChange={e => setValueFunc(ind, e.target.value as string)} 
                          style={{outline: '0px solid transparent', maxWidth: '100%', fontSize: fontOptions.size.small, marginRight: '5px', display: 'inline-block'}}
                          onFocus={() => {
                            setLastActive(ind)
                            setLastFocus(val.type)
                          }}
                        />
                        // <TextField 
                        //   value={val.value}
                        //   onChange={e => setValueFunc(ind, e.target.value as string)} 
                        //   style={{outline: '0px solid transparent', fontSize: fontOptions.size.small, marginRight: '5px', display: 'inline-block'}}
                        //   onFocus={() => {
                        //     setLastActive(ind)
                        //     setLastFocus(val.type)
                        //   }}
                        // />
                      ) 
                    } else {
                      return (
                        <EditableMathField latex={val.value} onChange={(mathField) => {setValueFunc(ind, mathField.latex())}} 
                          style={{fontSize: fontOptions.size.small, marginRight: '5px', borderRadius: '3px', padding: '5px', display: 'inline-block', maxWidth: '100%'}}
                          
                          mathquillDidMount={el=>{
                            setValues(prev => {
                            const newValue = [...prev]
                            newValue[ind] = {...newValue[ind], ...{Mathel: el}}
                            return newValue
                            })
                          }}
                          onFocus={(ev:React.FocusEvent<HTMLSpanElement>) => {
                            setLastActive(ind)
                            setLastFocus(val.type)
                          }}
                        />
                      )
                    }
                  })}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    )
}

export default MathEditor