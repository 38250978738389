import React, { useState, useEffect, FunctionComponent, } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
	Box, Container, Grid, Button, Input,
	Typography, FormHelperText, CssBaseline, Card, CardContent, CardHeader
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';

import MiniDrawer from "../../../common/components/SideDrawer"
import { EMAIL_PATTERN, NAME_PATTERN, PHONE_PATTERN } from '../../../common/validations/patterns';
import { editAdminUser, } from '../../../../api/dashboard';
import useStyles from './styles';
import { Admin, Role, } from '../../../common/contracts/dashboard';
import { RootState } from '../../../redux/store';
import { setAdminsList, } from '../../../redux/actions/adminActions';
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../../utilities/constants';

interface ModalState {
	_id: string,
	name: string,
	status: string
}

interface Props extends RouteComponentProps {
	admin: Admin,
	adminsList: Admin[]
}

interface FormData {
	firstName: string,
	lastName: string,
	emailId: string,
	mobileNumber: string,
	role: string
}

const ValidationSchema = yup.object().shape({
	firstName: yup.string().trim().required('First name is required')
		.matches(NAME_PATTERN, 'First Name is invalid'),
	// .validate(val=>val.trim().length>0)
	// .min(5, 'cannot be less than 5 characters'),
	lastName: yup.string().required('Last name is required')
		.matches(NAME_PATTERN, 'Last Name is invalid'),
	emailId: yup.string().required('Email is required')
		.matches(EMAIL_PATTERN, 'Email is invalid'),
	mobileNumber: yup.string().required('Mobile number is required')
		.matches(PHONE_PATTERN, 'Mobile number is invalid'),
	// role: yup.string().notOneOf(['Select']).required('Role is required')
});

const EditUser: FunctionComponent<Props> = ({ admin, adminsList }) => {
	const { errors, setError, clearError, handleSubmit, register, reset, control, setValue } = useForm<FormData>({
		mode: 'onBlur',
		validationSchema: ValidationSchema
	});

	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const [roles, setRoles] = useState<Role[]>([]);
	const styles = useStyles();
	const dispatch = useDispatch()

	useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.editAdmin);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	const handleEditUser = async (data: FormData) => {
		var { firstName, lastName, emailId, mobileNumber, role } = data;
		// clearError('serverError');

		if (firstName.replace(/ /g, "").length < 3) {
			setError('firstName', 'Invalid Data', 'First name cannot be less than 3 character excluding spaces');
			return;
		} else {
			clearError('firstName');
		}

		if (lastName.replace(/ /g, "").length < 1) {
			setError('lastName', 'Invalid Data', 'Last name cannot be less than 1 character excluding spaces');
			return;
		} else {
			clearError('lastName');
		}

		if (firstName == admin.firstName && lastName == admin.lastName &&
			mobileNumber == admin.mobileNo && emailId == admin.emailId) {
			enqueueSnackbar('There are no changes to update', { variant: 'info' });
			return;
		}

		var obj: any = {};

		if (firstName != admin.firstName)
			obj['firstName'] = firstName;
		if (lastName != admin.lastName)
			obj['lastName'] = lastName;
		if (mobileNumber != admin.mobileNo)
			obj['mobileNo'] = mobileNumber;
		if (emailId != admin.emailId) {
			obj['emailId'] = emailId;
		}
		obj['oldEmailId'] = admin.emailId;
		obj['_id'] = admin._id;

		setLoading(true);
		try {
			await editAdminUser(obj);
			setLoading(false);
			let clone = JSON.parse(JSON.stringify(adminsList));
			let index = clone.findIndex((item: any) => item._id == admin._id);
			if (index >= 0) {
				if (obj.firstName) clone[index].firstName = obj.firstName;
				if (obj.lastName) clone[index].lastName = obj.lastName;
				if (obj.mobileNo) clone[index].mobileNo = obj.mobileNo;
				if (obj.emailId) clone[index].emailId = obj.emailId;
			}
			dispatch(setAdminsList(clone));
			enqueueSnackbar('Updated successfully', { variant: 'success' });
			setRedirectTo('/search-admin');
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Manage Users</Typography>
							</Grid> */}
							<Grid item className={styles.header}>
								<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
							</Grid>

							<Card className={styles.card} variant='outlined'>
								<CardHeader title='Edit User' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent >

									<form onSubmit={handleSubmit(handleEditUser)} >
										<Grid container  >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>First Name</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="firstName"
													placeholder="Enter First Name"
													inputProps={{ maxLength: 50 }}
													className={styles.margin}
													inputRef={register}
													defaultValue={admin ? admin.firstName : ''}
												/>
												{errors.firstName && (
													<FormHelperText error>
														{errors.firstName.message}
													</FormHelperText>
												)}
											</Grid>

											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.previousLabel}>Previous: {admin.firstName ? admin.firstName : ''}</Box>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container  >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Last Name</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="lastName"
													placeholder="Enter Last Name"
													inputProps={{ maxLength: 50 }}
													className={styles.margin}
													inputRef={register}
													defaultValue={admin ? admin.lastName : ''}
												/>
												{errors.lastName && (
													<FormHelperText error>
														{errors.lastName.message}
													</FormHelperText>
												)}
											</Grid>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.previousLabel}>Previous: {admin.lastName ? admin.lastName : ''}</Box>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container  >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Email Id</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="emailId"
													placeholder="Enter Email Id"
													inputProps={{ maxLength: 50 }}
													className={styles.margin}
													inputRef={register}
													defaultValue={admin ? admin.emailId : ''}
												/>
												{errors.emailId && (
													<FormHelperText error>
														{errors.emailId.message}
													</FormHelperText>
												)}
											</Grid>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.previousLabel}>Previous: {admin.emailId ? admin.emailId : ''}</Box>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container  >
											<Grid item xs={12} md={3}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Mobile Number</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={5}>
												<Input
													name="mobileNumber"
													placeholder="Enter Mobile Number"
													inputProps={{ inputMode: 'numeric', maxLength: 10 }}
													className={styles.margin}
													inputRef={register}
													defaultValue={admin ? admin.mobileNo : ''}
												/>
												{errors.mobileNumber && (
													<FormHelperText error>
														{errors.mobileNumber.message}
													</FormHelperText>
												)}
											</Grid>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.previousLabel}>Previous: {admin.mobileNo ? admin.mobileNo : ''}</Box>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container justify='center' className={styles.submitBtn}>
											<Grid item xs={12} md={6} >
												<Button
													disableElevation
													variant="contained"
													color="primary"
													size="large"
													type="submit"
													disabled={loading}
												>
													Save user
													</Button>
											</Grid>
										</Grid>

									</form>
								</CardContent>
							</Card>

						</Grid>
					</Container>
				</div >
			</MiniDrawer >
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	admin: state.adminReducer.admin as Admin,
	adminsList: state.adminReducer.adminsList as Admin[],
});

export default connect(mapStateToProps)(EditUser);