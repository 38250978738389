import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Grid,
  Input,
  Typography,
  Button
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import Modal from '../../common/components/modal';
import useStyles from '../../leadsManagement/styles';
import { questionsRow, questionUpdateReq } from '../interfaces';
import { updateMasterQuestion } from '../api';
import ViewMasterQuestionModal from './viewMasterQuestionModal';
import { onApiError } from '../../common/helpers';

interface Props {
    openModal: boolean;
    onClose: () => any;
    question: questionsRow;
    setOpenModal: (val: boolean) => any;
    setIsEdited: (val: boolean) => any;
    isEdited: boolean;
    handleEdit: () => any;
}

const EditMasterQuestionModal: FunctionComponent<Props> = ({
    openModal,
    onClose,
    question,
    setOpenModal,
    handleEdit,
    isEdited,
    setIsEdited
}) => {
    const {_id, answerDescription, questionDescription, option1, option2, option3, option4} = question

    const [newAnswerDescription, setNewAnswerDescription] = useState('')
    const [newQuestionDescription, setNewQuestionDesription] = useState('')
    const [newOption1, setNewOption1] = useState('')
    const [newOption2, setNewOption2] = useState('')
    const [newOption3, setNewOption3] = useState('')
    const [newOption4, setNewOption4] = useState('')

    const [openPreview, setOpenPreview] = useState(false)

    const {enqueueSnackbar} = useSnackbar()

    const editedQuestion = question

    const styles = useStyles()

    useEffect(() => {
        init()
    }, [])

    const init = () => {
        setNewAnswerDescription(answerDescription)
        setNewQuestionDesription(questionDescription)
        setNewOption1(option1)
        setNewOption2(option2)
        setNewOption3(option3)
        setNewOption4(option4)
    }

    const checkValidation = () => {
        return true
    }

    const handlePreview = () => {
        Object.assign(editedQuestion, {
            answerDescription: newAnswerDescription,
            questionDescription: newQuestionDescription,
            option1: newOption1,
            option2: newOption2,
            option3: newOption3,
            option4: newOption4
        })
        setIsEdited(true)
        setOpenModal(false)
        setOpenPreview(true)
    }

    const getUpdateCount = (toUpdate?: questionUpdateReq) => {
        let updateCount = 0

        if(newQuestionDescription !== questionDescription) {
            if(toUpdate) toUpdate.questionDescription = newQuestionDescription
            updateCount++
        }
        if(newOption1 !== option1) {
            if(toUpdate) toUpdate.option1 = newOption1
            updateCount++
        }
        if(newOption2 !== option2) {
            if(toUpdate) toUpdate.option2 = newOption2
            updateCount++
        }
        if(newOption3 !== option3) {
            if(toUpdate) toUpdate.option3 = newOption3
            updateCount++
        }
        if(newOption4 !== option4) {
            if(toUpdate) toUpdate.option4 = newOption4
            updateCount++
        }
        if(newAnswerDescription !== answerDescription) {
            if(toUpdate) toUpdate.answerDescription = newAnswerDescription
            updateCount++
        }

        return updateCount
    }

    const saveChanges = async () => {
        if (!checkValidation()) return;
        const edited = {
            _id,
            answerDescription: newAnswerDescription,
            questionDescription: newQuestionDescription,
            option1: newOption1,
            option2: newOption2,
            option3: newOption3,
            option4: newOption4
        }
        const toUpdate: questionUpdateReq = isEdited ? edited : {_id}
        
        if(!isEdited) {
            const updateCount = getUpdateCount(toUpdate)
            if(updateCount === 0) {
                enqueueSnackbar('No changes made', {variant: "warning"})
                return;
            }
        }

        await updateMasterQuestion(toUpdate)
        .then(() => {
            enqueueSnackbar('Successfully updated', { variant: "success" })
            onClose()
        })
        .catch((err) => {
			onApiError(err, enqueueSnackbar)
        })
    }

    const renderInput = (key: string, value: any, setter: any, maxLength?: number, multiline?: boolean) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Input
                placeholder={key}
                value={value}
                inputProps={{ maxLength: maxLength ? maxLength : 100 }}
                multiline={multiline}
                onChange={(e) => setter(e.target.value)}
                />
            </FormControl>
            </Grid>
        </Grid>
    )
    
    const renderButton = (buttons: {name: string, cb: any} []) => (
        <Grid container spacing={3} justify="center" alignItems="center">
            {buttons.map(cur => (
                <Grid item>
                    <Button variant="contained" color="primary" size="medium"
                    onClick={cur.cb}
                    >
                        {cur.name}
                    </Button>
                </Grid>
            ))}
        </Grid>
    )

    return (
        <>
        {editedQuestion && <ViewMasterQuestionModal
            openModal={openPreview}
            onClose={() => setOpenPreview(false)}
            question={editedQuestion}
            handleEdit={() => {
                handleEdit()
                setOpenPreview(false)
            }}
            isEdited={isEdited}
            saveChanges={saveChanges}
        />}
        <Modal
            open={openModal}
            handleClose={onClose}
            header={
                <Box>
                    <Typography component="span" color="inherit">
                    <Box component="h3">
                        Edit Question
                    </Box>
                    </Typography>
                </Box>
            }
            large
        >
            {renderInput('Question Description', newQuestionDescription, setNewQuestionDesription, 2000, true)}
            {renderInput('Option 1', newOption1, setNewOption1)}
            {renderInput('Option 2', newOption2, setNewOption2)}
            {renderInput('Option 3', newOption3, setNewOption3)}
            {renderInput('Option 4', newOption4, setNewOption4)}
            {renderInput('Answer Description', newAnswerDescription, setNewAnswerDescription, 2000, true)}
            {renderButton([{name: 'Preview Changes', cb: handlePreview}, {name: 'Save Changes', cb: saveChanges}])}
        </Modal>
        </>
    )
}

export default EditMasterQuestionModal