import React, { useState, useEffect, FunctionComponent, } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
	Box, Container, Grid, Button, Input, MenuItem, Typography, Switch, CssBaseline, Card, CardContent, CardHeader, Chip
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"

import MiniDrawer from "../../../common/components/SideDrawer"
import useStyles from '../manageUsers/styles';
import { Package, PackagePlan, AdditionalCharges } from '../../../common/contracts/dashboard';
import { LIVE_CLASS_TYPES, packagesInitData, PAYMENT_PLANS, MASTER_PERMISSIONS, USER_ROLES } from '../../../../utilities/constants';
import Datepickers from '../../../dashboard/components/datepickers';
import { createPackage, getEdumaticaPackages, updateMasterPackage } from '../../../../api/dashboard';

interface Props extends RouteComponentProps {

}

const FeePackages: FunctionComponent<Props> = ({ history }) => {

	var initData = packagesInitData;

	const { enqueueSnackbar } = useSnackbar()
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const [packagesList, setPackagesList] = useState<Package[]>([]);	//crosscheck
	const [fromDate, setFromDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [selectedpackage, setSelectedPackage] = useState<any>(initData);
	const [paymentPlan, setPaymentPlan] = useState(['PREPAID', 'PREPAID', 'PREPAID']);
	const [details, setDetails] = useState<string[][]>([[], [], []]);
	const [detail, setDetail] = useState<string[]>([]);
	const [filteredPaymentPlans, setFilteredPaymentPlans] = useState<PackagePlan[][]>([]);
	const [isActive, setIsActive] = useState(true);

	const styles = useStyles();

	useEffect(() => {
		getLoggedInUserData();
		filterPaymentPlans(initData)
	}, [isActive]);

	const filterPaymentPlans = (list: any) => {
		let data: PackagePlan[][] = []
		list.map((item: Package) => {
			data.push(item.paymentPlans);
		});

		setFilteredPaymentPlans(data);
	}

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.edumacpackage);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		} else {
			getPackages();
		}
	}

	const getPackages = async () => {
		var obj: any = {
			avtivePackagesOnly: isActive
		}
		if (fromDate && endDate) {
			obj['fromDate'] = fromDate;
			obj['toDate'] = endDate;
		}
		try {
			const data = await getEdumaticaPackages(obj);
			var list = data.data;
			if(list.length > 0){
				list.forEach((cur: any) => {
					cur.contentSize = cur.contentSize / 1073741824
				})
				// enqueueSnackbar(list.length + ' record(s) fetched', { variant: 'success' });
				setPackagesList(list);
				setSelectedPackage(list);
				filterPaymentPlans(list);
				let details: string[][] = [];
				list.forEach((item: any) => {
					if (item.details) {
						details.push(item.details.split('|||'));
					}
				})
				setDetails(details);
			}
		} catch (error) {
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const handlePackage = (event: React.ChangeEvent<{ value: unknown }>) => {
		let etv = event.target.value;
		// packagesList.forEach((item) => {
		// 	if (item.name == etv) {
		// 		setSelectedPackage(item);
		// 		setPlanId(item.planId);
		// 		setLiveClasstype(item.liveClassType);
		// 		if (item.paymentPlans[0]) {
		// 			setPaymentCycle(item.paymentPlans[0].paymentcycle)
		// 			setPaymentPlan(item.paymentPlans[0].paymentplan)
		// 		}
		// 		return;
		// 	}
		// });
	}

	const hasValidationErrors = () => {
		if (!fromDate) {
			enqueueSnackbar('Please enter start date', { variant: 'warning' });
			return false;
		}
		if (!endDate) {
			enqueueSnackbar('Please enter end date', { variant: 'warning' });
			return false;
		}

		selectedpackage.every((item: any, index: number) => {
			if (!item.name) {
				enqueueSnackbar('Please enter package name', { variant: 'warning' });
				return false;
			}
			if (!item.studentCount) {
				enqueueSnackbar('Please enter student count', { variant: 'warning' });
				return false;
			}
			if (!item.batchCount) {
				enqueueSnackbar('Please enter batch count', { variant: 'warning' });
				return false;
			}
			if (!item.liveClassType) {
				enqueueSnackbar('Please enter live class type', { variant: 'warning' });
				return false;
			}
			if (!item.liveClassQuota) {
				enqueueSnackbar('Please enter live class hrs', { variant: 'warning' });
				return false;
			}
			if (!paymentPlan[index]) {
				enqueueSnackbar('Please enter plan type', { variant: 'warning' });
				return false;
			}
			if (!item.graceperiod) {
				enqueueSnackbar('Please enter grace period', { variant: 'warning' });
				return false;
			}
			// if (!item.details.length) {
			// 	enqueueSnackbar('Please enter details', { variant: 'warning' });
			// 	return false;
			// }
			if (!item.paymentPlans.length) {
				enqueueSnackbar('Please enter monthly fee', { variant: 'warning' });
				return false;
			}
		})
		return true
	}

	const handleSavePackage = async(publish: boolean) => {

		if (!hasValidationErrors()) {
			return;
		}
		setLoading(true);
		Promise.all(selectedpackage && selectedpackage.forEach(async (item: any, index: number) => {
			var detailString: string = '';
			if(details.length > 0) {
				details[index].forEach((item, dIndex) => {
					detailString = details[index].length == dIndex + 1 ? detailString + item : detailString + item + '|||';
				});
			}
			var paymentPlans: PackagePlan[] = [];

			selectedpackage[index].paymentPlans.forEach((plan: PackagePlan) => {
				// if (plan.totalprice) {
				paymentPlans.push({
					totalprice: plan.totalprice,
					discount: 0,
					discountCap: 0,
					paymentplan: plan.paymentplan,
					paymentcycle: plan.paymentcycle,
					graceperiod: selectedpackage[index].graceperiod,
				})
				// }
			})

			var additionalCharges: AdditionalCharges[] = [];

			paymentPlans.forEach((plan) => {
				let changedPrice = 0.3 * (plan.totalprice)
				additionalCharges.push({
					feeName: '',
					totalprice: Number(changedPrice.toFixed(2)),
					discount: 0,
					discountCap: 0,
					paymentplan: paymentPlan[index],
				})
			})

			var obj = {
				name: selectedpackage[index].name,
				planId: selectedpackage[index].planId,
				perstudentcost: selectedpackage[index].perstudentcost,
				convenienceFee: selectedpackage[index].convenienceFee,
				graceperiod: selectedpackage[index].graceperiod,
				recordingQuota: selectedpackage[index].recordingQuota,
				courseCount: selectedpackage[index].courseCount,
				studentCount: selectedpackage[index].studentCount,
				tutorCount: selectedpackage[index].tutorCount,
				adminCount: selectedpackage[index].adminCount,
				batchCount: selectedpackage[index].batchCount,
				sessionCount: selectedpackage[index].sessionCount,
				contentSize: selectedpackage[index].contentSize * 1073741824,
				liveClassQuota: selectedpackage[index].liveClassQuota,
				liveClassType: selectedpackage[index].liveClassType,
				// customChapter: number,
				// status: number,
				isActive: true,
				activeFrom: fromDate,
				activeTill: endDate,
				// icon: string,
				details: detailString
			}

			var pkgObj = {
				packageDetails: obj,
				packagePlans: paymentPlans,
				additionalCharges: additionalCharges
			}


			try {
				if(!publish) {
					await createPackage(pkgObj);
					enqueueSnackbar('Packages created successfully', { variant: 'success', preventDuplicate: true });
				}else{
					await updateMasterPackage(pkgObj);
					enqueueSnackbar('Packages updated successfully', { variant: 'success', preventDuplicate: true });
				}
			} catch (error) {
				enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
				if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
					setRedirectTo('/login');
				}
			}
		}))
			.then(data => {
				setSelectedPackage(initData);
				setDetails([[], [], []]);
				enqueueSnackbar('Packages created successfully', { variant: 'success' });
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				// enqueueSnackbar('Something went wrong', { variant: 'warning' });
			});
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
		if (selectedpackage) {
			switch (e.target.name) {
				case 'packageFriendlyName':
					selectedpackage[index].name = e.target.value
					break;
				case 'studentCount':
					selectedpackage[index].studentCount = Number(e.target.value)
					break;
				case 'batchCount':
					selectedpackage[index].batchCount = Number(e.target.value)
					break;
				case 'liveClassQuota':
					selectedpackage[index].liveClassQuota = Number(e.target.value)
					break;
				case 'recordingQuota':
					selectedpackage[index].recordingQuota = Number(e.target.value)
					break;
				case 'perstudentcost':
					selectedpackage[index].perstudentcost = Number(e.target.value)
					break;
				case 'convenienceFee':
					selectedpackage[index].convenienceFee = Number(e.target.value)
					break;
				case 'graceperiod':
					selectedpackage[index].graceperiod = Number(e.target.value)
					break;
				case 'contentSize':
					selectedpackage[index].contentSize = Number(e.target.value)
					break;
				case 'MONTHLY':
					filteredPaymentPlans[index][0].totalprice = Number(e.target.value)
					break;
				case 'QUARTERLY':
					filteredPaymentPlans[index][1].totalprice = Number(e.target.value)
					break;
				case 'HALFYEARLY':
					filteredPaymentPlans[index][2].totalprice = Number(e.target.value)
					break;
				case 'LUMPSUM':
					filteredPaymentPlans[index][3].totalprice = Number(e.target.value)
					break;
				default:
					break;
			}
			setSelectedPackage([...selectedpackage]);

		}
	}
	const handleLiveClassType = (e: React.ChangeEvent<{ value: unknown }>, index: number) => {
		// setLiveClasstype(e.target.value as string);
		selectedpackage[index].liveClassType = e.target.value as string;
		setSelectedPackage([...selectedpackage]);
	}

	const handleDelete = (chipToDelete: string, index: number, detailIndex: number) => () => {
		details[index].splice(detailIndex, 1)
		setDetails([...details]);
	};

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Search Customers</Typography>
							</Grid> */}

							<Card className={styles.card} variant='outlined' style={{ width: '100%' }}>
								<CardHeader title='Create Fee Package' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>


									<Grid container >
										{/* <Grid item xs={12} md={3}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Package Friendly Name</Box>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={3}>
											<Input
												name="package"
												placeholder="Enter Package Name"
												inputProps={{ maxLength: 25 }}
												className={styles.margin}
												value={packageName}
												onChange={(e) => setPackageName(e.target.value)}
											/>
										</Grid> */}


									</Grid>

									<Grid container>
										<Grid item xs={12} md={3}>
											<FormControl fullWidth margin="normal">
												<Box >From Date</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={3}>
											<FormControl fullWidth margin="normal">
												<Datepickers selectedDate={fromDate} handleDateChange={(date) => setFromDate(date)}
													minDate={new Date()}
												// maxDate={new Date()}
												/>
											</FormControl>
											{/* {dateError.dob &&
												<Typography className={classes.dateError}>{dateError.dob}</Typography>
											} */}
										</Grid>
										{/* </Grid>

									<Grid container> */}
										<Grid item xs={12} md={3}>
											<FormControl fullWidth margin="normal">
												<Box >End Date</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={3}>
											<FormControl fullWidth margin="normal">
												<Datepickers selectedDate={endDate} handleDateChange={(date) => setEndDate(date)}
													minDate={new Date()}
												/>
											</FormControl>
										</Grid>
									</Grid>


									<Grid container>
										<Grid item xs={12} md={3}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Select an old package to clone</Box>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={3}>
											<FormControl fullWidth margin="normal">
												<Select onChange={handlePackage}>
													<MenuItem value="Select">Select</MenuItem>
													{packagesList && packagesList.map((item, index) => (
														<MenuItem value={item.name} key={index}	>
															{item.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={3}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Active Packages</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={3}>
											<Switch
												checked={isActive}
												onChange={(e) => setIsActive(e.target.checked)}
												color="primary"
												name="checkedB"
												inputProps={{ 'aria-label': 'primary checkbox' }}
											/>
										</Grid>
									</Grid>


								</CardContent>
							</Card>

						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={12} md={3}>
								<Grid item style={{ height: '60px' }}>
									<Typography>Package</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Package friendly name</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Total students allowed</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Total tutors allowed</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Total admins allowed</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Total courses allowed</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Total sessions allowed</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Total No. of batches allowed</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Live Class type</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Live Class hrs/day</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Recording hrs/lecture</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Plan Type</Typography>
								</Grid>

								{/* <Grid item style={{ height: '60px' }}>
									<Typography>Payment Cycle</Typography>
								</Grid> */}

								<Grid item style={{ height: '60px' }}>
									<Typography>Per student per sub per month fee</Typography>
								</Grid>

								<Grid item style={{ height: '60px' }}>
									<Typography>Commision% per course fee</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Min monthly fee from tutor</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Min quarterly fee from tutor</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Min half yearly fee from tutor</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Min annual fee from tutor</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Grace Period</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Max cumulative content space allowed (in GB)</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Details(click enter after every detail)</Typography>
								</Grid>

							</Grid>

							<>
								{
									selectedpackage && selectedpackage.map((item: any, index: number) => {
										return (
											< Grid item xs={12} md={3} key={index}>

												<Grid item style={{ height: '60px' }}>
													<Typography>{item && item.planId ? item.planId : ''}</Typography>
													{/* <Select onChange={(e) => setPlanId(e.target.value as string)} value={planId}>
														{PLANS.map((item, index) => (
															<MenuItem value={item.value} key={index}	>
																{item.name}
															</MenuItem>
														))}
													</Select> */}
												</Grid>
												<Grid item style={{ height: '60px' }}>
													<Input
														name="packageFriendlyName"
														placeholder="Enter package name"
														inputProps={{ maxLength: 25 }}
														value={item.name as string}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (selectedpackage){
																selectedpackage[index].name = (e.target.value as string)
																setSelectedPackage([...selectedpackage]);
															}
														}}
													/>
												</Grid>
												<Grid item style={{ height: '60px' }}>
													<Input
														name="studentCount"
														type="number"
														placeholder="Enter student count"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														value={item.studentCount as number}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (selectedpackage){
																selectedpackage[index].studentCount = (e.target.value as number)
																setSelectedPackage([...selectedpackage]);
															}
														}}
													/>
												</Grid>
												<Grid item style={{ height: '60px' }}>
													<Input
														name="tutorCount"
														type="number"
														placeholder="Enter tutors count"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														value={item.tutorCount as number}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (selectedpackage){
																selectedpackage[index].tutorCount = (e.target.value as number)
																setSelectedPackage([...selectedpackage]);
															}
														}}
													/>
												</Grid>
												<Grid item style={{ height: '60px' }}>
													<Input
														name="adminCount"
														type="number"
														placeholder="Enter admins count"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														value={item.adminCount as number}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (selectedpackage){
																selectedpackage[index].adminCount = (e.target.value as number)
																setSelectedPackage([...selectedpackage]);
															}
														}}
													/>
												</Grid>
												<Grid item style={{ height: '60px' }}>
													<Input
														name="courseCount"
														type="number"
														placeholder="Enter courses count"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														value={item.courseCount as number}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (selectedpackage){
																selectedpackage[index].courseCount = (e.target.value as number)
																setSelectedPackage([...selectedpackage]);
															}
														}}
													/>
												</Grid>
												<Grid item style={{ height: '60px' }}>
													<Input
														name="sessionCount"
														type="number"
														placeholder="Enter sessions count"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														value={item.sessionCount as number}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (selectedpackage){
																selectedpackage[index].sessionCount = (e.target.value as number)
																setSelectedPackage([...selectedpackage]);
															}
														}}
													/>
												</Grid>
												<Grid item style={{ height: '60px' }}>
													<Input
														name="batchCount"
														type="number"
														placeholder="Enter batch count"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														value={item.batchCount as number}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (selectedpackage){
																selectedpackage[index].batchCount = (e.target.value as number)
																setSelectedPackage([...selectedpackage]);
															}
														}}
													/>
												</Grid>

												<Grid item style={{ height: '60px' }}>
													<Select onChange={(e) => handleLiveClassType(e, index)} value={item.liveClassType}>
														{LIVE_CLASS_TYPES.map((item, index) => (
															<MenuItem value={item.value} key={index}	>
																{item.name}
															</MenuItem>
														))}
													</Select>
												</Grid>
												<Grid item style={{ height: '60px' }}>
													<Input
														name="liveClassQuota"
														placeholder="Enter live Class Quota "
														type="number"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														value={item.liveClassQuota as number}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (selectedpackage){
																selectedpackage[index].liveClassQuota = (e.target.value as number)
																setSelectedPackage([...selectedpackage]);
															}
														}}
													/>
												</Grid>
												<Grid item style={{ height: '60px' }}>
													<Input
														name="recordingQuota"
														placeholder="Enter recording Quota"
														type="number"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														value={item.recordingQuota as number}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (selectedpackage){
																selectedpackage[index].recordingQuota = (e.target.value as number)
																setSelectedPackage([...selectedpackage]);
															}
														}}
													/>
												</Grid>

												<Grid item style={{ height: '60px' }}>
													<Select onChange={(e) => {
														paymentPlan[index] = e.target.value as string
														setPaymentPlan(paymentPlan)
													}}>
														{PAYMENT_PLANS.map((item, index) => (
															<MenuItem value={item.value} key={index}	>
																{item.name}
															</MenuItem>
														))}
													</Select>
												</Grid>
												{/* <Grid item style={{ height: '60px' }}>
													<Select onChange={(e) => setPaymentCycle(e.target.value as string)} value={paymentCycle}>
														{PAYMENT_CYCLE.map((item, index) => (
															<MenuItem value={item.value} key={index}	>
																{item.name}
															</MenuItem>
														))}
													</Select>
												</Grid> */}
												<Grid item style={{ height: '60px' }}>
													<Input
														name="perstudentcost"
														placeholder="Enter per student cost"
														value={item.perstudentcost as number}
														type="number"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (selectedpackage){
																selectedpackage[index].perstudentcost = (e.target.value as number)
																setSelectedPackage([...selectedpackage]);
															}
														}}
													/>
												</Grid>
												<Grid item style={{ height: '60px' }}>
													<Input
														name="convenienceFee"
														placeholder="Enter convenienceFee"
														value={item.convenienceFee as number}
														type="number"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (selectedpackage){
																selectedpackage[index].convenienceFee = (e.target.value as number)
																setSelectedPackage([...selectedpackage]);
															}
														}}
													/>
												</Grid>
												{
													filteredPaymentPlans && filteredPaymentPlans.length > index &&
													<>
														<Grid item style={{ height: '60px' }}>
															<Input
																name="MONTHLY"
																placeholder="Min fee from tutor"
																inputProps={{ maxLength: 5 }}
																value={filteredPaymentPlans ? filteredPaymentPlans[index][0].totalprice : ''}
																onChange={(e) => handleChange(e, index)}
															/>
														</Grid>
														<Grid item style={{ height: '60px' }}>
															<Input
																name="QUARTERLY"
																placeholder="Min quarterly fee from tutor"
																inputProps={{ maxLength: 5 }}
																value={filteredPaymentPlans ? filteredPaymentPlans[index][1].totalprice : ''}
																onChange={(e) => handleChange(e, index)}
															/>
														</Grid>
														<Grid item style={{ height: '60px' }}>
															<Input
																name="HALFYEARLY"
																placeholder="Min half yearly fee from tutor"
																inputProps={{ maxLength: 5 }}
																value={filteredPaymentPlans ? filteredPaymentPlans[index][2].totalprice : ''}
																onChange={(e) => handleChange(e, index)}
															/>
														</Grid>
														<Grid item style={{ height: '60px' }}>
															<Input
																name="LUMPSUM"
																placeholder="Min annual fee from tutor"
																inputProps={{ maxLength: 5 }}
																value={filteredPaymentPlans ? filteredPaymentPlans[index][3].totalprice : ''}
																onChange={(e) => handleChange(e, index)}
															/>
														</Grid>
													</>
												}
												<Grid item style={{ height: '60px' }}>
													<Input
														name="graceperiod"
														placeholder="Enter grace period"
														value={item.graceperiod as number}
														type="number"
														inputProps={{ maxLength: 5, typeof: 'number' }}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (selectedpackage){
																selectedpackage[index].graceperiod = (e.target.value as number)
																setSelectedPackage([...selectedpackage]);
															}
														}}
													/>
												</Grid>
												<Grid item style={{ height: '60px' }}>
													<Input
														name="contentSize"
														placeholder="Enter content Size"
														value={item.contentSize as number}
														type="number"
														inputProps={{ maxLength: 3, typeof: 'number' }}
														onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
															if (selectedpackage){
																selectedpackage[index].contentSize = (e.target.value as number)
																setSelectedPackage([...selectedpackage]);
															}
														}}
													/>
												</Grid>
												<Grid item style={{ height: '60px' }}>
													<Input
														name="details"
														placeholder="Enter details"
														inputProps={{ maxLength: 50 }}
														value={detail[index]}
														onChange={(e) => {
															detail[index] = e.target.value as string
															setDetail([...detail])
														}}
														// multiline
														onKeyDown={(e) => {
															if (e.key === 'Enter') {
																details && details[index] && details[index].push(detail[index]);
																// setDetails([...details]);
																detail[index] = ''
																setDetail([...detail]);
																selectedpackage[index].details = details[index]
															}
														}}
													/>
												</Grid>

												{
													details && details[index] && details[index].map((data, dIndex) => {
														return (
															<li key={index}>
																<Chip
																	label={data}
																	onDelete={handleDelete(data, index, dIndex)}
																// className={classes.chip}
																/>
															</li>
														);
													})
												}

											</Grid>
										)
									})
								}
							</>

						</Grid>

						<Grid item xs={12} md={6}>
							<Button color='primary' variant='contained' disabled={loading}
								onClick={() => {handleSavePackage(false)}}
							>
								Save
							</Button>

							<Button style={{marginLeft: '10px'}} color='primary' variant='contained' disabled={loading}
								onClick={() => {handleSavePackage(true)}}
							>
								Publish
							</Button>							
						</Grid>
					</Container>
				</div>
			</MiniDrawer >
		</>
	);
}

export default FeePackages;
