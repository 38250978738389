import React, { FunctionComponent, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  Input,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import Modal from '../../common/components/modal';
import useStyles from '../../leadsManagement/styles';
import { notificationTemplate } from '../interfaces';
import { saveHtmlTemplate, saveMsgTemplate } from '../api';
import { onApiError } from '../../common/helpers';

interface Props {
    openModal: boolean;
    onClose: () => any;
    template: notificationTemplate;
}

const EditMasterNotificationTemplateModal: FunctionComponent<Props> = ({
  openModal,
  onClose,
  template
}) => {
  const {htmlIdentifierText, msgIdentifierText, messageType} = template
  const [newHtmlText, setNewHtmlText] = useState(htmlIdentifierText)
  const [newMsgText, setNewMsgText] = useState(msgIdentifierText)
  const styles = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const checkValidation = () => {
    if(newHtmlText) {
      // if(newHtmlText === htmlIdentifierText) {
      //   enqueueSnackbar('No changes made', {variant: 'warning'})
      //   return false
      // }
      if(newHtmlText.length < 5) {
        enqueueSnackbar('Min 5 characters required', {variant: 'warning'})
        return false
      }
    }

    if(newMsgText) {
      // if(newMsgText === msgIdentifierText) {
      //   enqueueSnackbar('No changes made', {variant: 'warning'})
      //   return false
      // }
      if(newMsgText.length < 5) {
        enqueueSnackbar('Min 5 characters required', {variant: 'warning'})
        return false
      }
    }

    return true
  }

  const handleSaveChanges = async () => {
    if(!checkValidation()) return;
    
    const message = newMsgText ? newMsgText : newHtmlText
    const api = newMsgText ? saveMsgTemplate : saveHtmlTemplate
    if(!message) return;

    await api(messageType, message, '')
    .then(() => {
      enqueueSnackbar('Edited successfully', {variant: 'success'})
      onClose()
    })
    .catch((err) => {
			onApiError(err, enqueueSnackbar)
    })
  }

  const renderInfo = (key: string, value: string) => (
    <Grid container>
      <Grid item xs={12} md={4}>
      <FormControl fullWidth margin="normal">
          <Box className={styles.label}>{key}</Box>
      </FormControl>
      </Grid>

      <Grid item xs={12} md={8}>
      <FormControl fullWidth margin="normal">
          <Box className={styles.label}>{value}</Box>
      </FormControl>
      </Grid>
    </Grid>
  )

  const renderInput = (key: string, value: any, setter: any) => (
    <Grid container>
        <Grid item xs={12} md={4}>
        <FormControl fullWidth margin="normal">
            <Box className={styles.label}>{key}</Box>
        </FormControl>
        </Grid>

        <Grid item xs={12} md={8}>
        <FormControl fullWidth margin="normal">
            <TextField
            placeholder={key}
            value={value}
            multiline
            onChange={(e) => setter(e.target.value)}
            />
        </FormControl>
        </Grid>
    </Grid>
  )

  const renderButton = (name: string, cb: any) => (
    <Grid container spacing={3} justify="center" alignItems="center">
      <Grid item>
          <Button variant="contained" color="primary" size="medium" onClick={cb}>
            {name}
          </Button>
      </Grid>
    </Grid>
  )

  return (
    <>
    <Modal
      open={openModal}
      handleClose={onClose}
      header={
        <Box>
            <Typography component="span" color="inherit">
            <Box component="h3">
                Edit Notification Template
            </Box>
            </Typography>
        </Box>
      }
      large
    >
      {renderInfo('Message type', messageType)}
      {newMsgText && renderInput('Message Text', newMsgText, setNewMsgText, )}
      {newHtmlText && renderInput('Html text', newHtmlText, setNewHtmlText)}
      {renderButton('Save Changes', handleSaveChanges)}
    </Modal>
    </>
  )
}

export default EditMasterNotificationTemplateModal