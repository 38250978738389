import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepButton,
  Button
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '../../common/components/modal';
import BulkTemplate from '../../../assets/images/bulkTemplate.png';
import BulkZip from '../../../assets/images/bulkZip.png';
import BulkFile from '../../../assets/images/bulkFile.png';
import BulkForm from '../../../assets/images/bulkForm.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    '& .MuiStepConnector-lineHorizontal': {
      padding: '0 20px'
    },

    '& .MuiStepper-root': {
      padding: '24px 0'
    }
  },
  button: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },

  modalHeading: {
    fontWeight: 500,
    fontSize: '22px',
    letterSpacing: '1px',
    color: '#ffffff',
    margin: '0px'
  },
  kycSteps: {
    '& p': {
      marginBottom: '15px',
      color: '#212121',
      fontSize: '15px'
    },
    '& img': {
      border: '1px dashed #DDDDDD',
      borderRadius: '5px',
      marginBottom: '15px'
    }
  }
}));

interface Props {
  openModal: boolean;
  onClose: () => any;
}

const BulkUploadHelpModal: FunctionComponent<Props> = ({ openModal, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  // const [activeIndex, setActiveIndex] = useState(0);
  const steps = getSteps();

  const classes = useStyles();

  useEffect(() => {
    setActiveStep(0)
  },[openModal])

  function getSteps() {
    return ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6'];
  }

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <Box className={classes.kycSteps}>
            <img style={{width: '500px'}} src={BulkTemplate} alt="Bulk Template" />
            <Typography>
              Click on 'Download Template' Button to Download Excel Template
            </Typography>
            <Typography>Open this file in any Excel Editor</Typography>
          </Box>
        );
      case 1:
        return (
          <Box className={classes.kycSteps} style={{textAlign: 'justify', textJustify: 'inter-word'}}>
            <Typography>
              After Opening the file on an Excel Editor
            </Typography>
            <ul>
              <li>Fill S_No, ChapterName, Sub_Topic and Question_Description.</li>
              <li>Fill Type as 'single', 'multiple', 'match', 'numeric' without the quotes for uploading Single Answer MCQ, Multiple Answer MCQ, Match the following and Numeric Question Respectively.</li>
              <li>Fill Answer_Description and SolutionVideo, if required. SolutionVideo should be a Valid URL</li>
              <li>Fill Complexity. Complexity should be easy, medium or high</li>
              <li>For Single Answer MCQ, Multiple Answer MCQ and Match the following Questions, fill Option1 and Option2, which is mandatory. Option3 - Option6 can be filled, if required.</li>
              <li>For Match the Following questions, fill List1 and List2. Each item in both list should be seperated by '|' without the quote. Lists should have atleast 2 item, and should not have more than 6 item.</li>
            </ul>
            <Typography>
              Add Answer for Single Answer MCQ and Match the Following Questions
            </Typography>
            <ul>
            <li>Fill Answer_option_number. If the answer is Option1, fill the cell as 1. If the answer is Option2, fill cell as 2. And so on.</li>
            </ul>
            <Typography>
              Add Answer for Multiple Answer MCQ
            </Typography>
            <ul>
              <li>Fill Answer_option_number. If the answer is Option1, fill the cell as 1. If the answer is Option2, fill cell as 2. If Both Option1 and Option2 are answers, fill cell as 1,2. And so on.</li>
            </ul>
            <Typography>
              Add Answer for Numerical Questions
            </Typography>
            <ul>
              <li>Fill Answer_option_number. It should be a number</li>
            </ul>
            <Typography>
              To Add Comprehensive Question
            </Typography>
            <ul>
              <li>Follow the steps according to what question type is (single, multiple, match, numeric).</li>
              <li>Fill CommonQuestion, which will be the Paragraph Question.</li>
            </ul>
            
            <Typography>
              For Latex Related Issue please visit{' '}
              <a
                href="https://latex.js.org/playground.html"
                target="_blank"
              >
                https://latex.js.org/playground.html
              </a>{' '}
            </Typography>
          </Box>
        );
      case 2:
        return (
          <Box className={classes.kycSteps} style={{textAlign: 'justify', textJustify: 'inter-word'}}>
            <Typography>
              Steps to Attach Images
            </Typography>
            <ul>
              <li>If either Question Description, Options or Answer Description requires an image, you will be able to upload.</li>
              <li>Fill Image_Names cell with name of Image file including the extension, seperated by a comma. If no Images are required for the question, no need to fill the cell. Image should be of type png, jpg or jpeg.</li>
              <li>If Image is to be shown along with question description, the image filename should start with 'q' without quotes.</li>
              <li>If Image is to be shown along with answer description, the image filename should start with 's' without quotes.</li>
              <li>If Image is to be shown along with option 1, the image filename should start with '1' without quotes. If Image is to be shown along with option 2, the image filename should start with '2' without quotes. And so on.</li>
              <li>Sample: qImage1.png, 1Image2.png</li>
              <li>Incase if image is uploaded for options or Answer Description without filling its respective cell in excel, that image will get discarded.</li>
            </ul>

            <Typography>
              Save the Excel File
            </Typography>
          </Box>
        );
      case 3:
        return (
          <Box className={classes.kycSteps}>
            <img style={{width: '500px'}} src={BulkZip} alt="Bulk Zip" />
            <Typography>
              Select all Images mentioned in Image_Names in the Excel. Add them to Archive. Archive Format should be ZIP.
            </Typography>
          </Box>
        );
      case 4:
        return (
          <Box className={classes.kycSteps}>
            <img style={{width: '500px'}} src={BulkForm} alt="Bulk Form" />
            <Typography>
              Fill in the Text Fields
            </Typography>
          </Box>
        );
      case 5:
        return (
          <Box className={classes.kycSteps}>
            <img style={{width: '500px'}} src={BulkFile} alt="Bulk File" />
            <Typography>
              Upload Excel, and Zip File (if there is any Image_Names mentioned in Excel) in respective upload Area.
            </Typography>
            <Typography>
              Click on Generate Button
            </Typography>
          </Box>
        );
      default:
        return <Typography>Unknown step</Typography>;
    }
  }

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <Modal
      open={openModal}
      handleClose={onClose}
      header={
        <Box display="flex" alignItems="center">
          <Box marginLeft="15px">
            <Typography component="span" color="primary">
              <Box component="h3" className={classes.modalHeading}>
                Question Bank Upload Steps
              </Box>
            </Typography>
          </Box>
        </Box>
      }
    >
      <div className={classes.root}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton onClick={handleStep(index)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          <Typography className={classes.instructions}>
            {getStepContent(activeStep)}
          </Typography>
          <Box marginTop="30px">
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.button}
              variant="outlined"
              color="primary"
            >
              Back
            </Button>
            {
              activeStep !== 5 ?
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
            >
              Next
            </Button>
            : ''
            }
          </Box>
        </div>
      </div>
    </Modal>
  );
};

export default BulkUploadHelpModal;