import React, { FunctionComponent, useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Container, Box, Grid, Typography, useMediaQuery, Select, MenuItem, FormControl, InputLabel, SvgIconProps, Tooltip, Paper, Checkbox, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { fontOptions } from '../../../theme';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { StaticMathField } from 'react-mathquill'

import { QuestionBank, QuestionBankWithSelect } from '../contracts/qb_interface';
import Button from '../../common/components/form_elements/button';

//@ts-ignore
import { parse, HtmlGenerator } from 'latex.js'
import ReactPlayer from 'react-player';
import { URL_PATTERN, YOUTUBE_LINK_PATTERN } from '../../common/validations/patterns';
import { postQuestionBank, putQuestionBank } from '../helpers/api';
import MiniDrawer from '../../common/components/SideDrawer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: '100%',
    },
    addBtn: {
      color: '#EBF2FF', 
      background: '#4C8BF5', 
      border: 'none',
      '&:hover': {
        cursor: 'pointer',
      }
    },
    label: {
      color: '#4C8BF5'
    },
    graylabel: {
      color: '#3D3D3D'
    },
    addAssi: {
      '& button': {
        padding: '10px 30px 10px 30px',
      },
    },
    latex: {
      '& p': {
        marginTop: '0px',
        maxWidth: '100%', 
        wordWrap: 'break-word', 
      },
    },
    previewImg: {
      height: '140px',
      borderRadius: '5px'
    }
  }),
);

interface FormData {
  qbName: string;
  description: string;
  boardName: string;
  className: string;
  subject: string;
  availStudent: boolean;
}

interface AllChapter {
  chaptername: string,
  subtoptics: string[]
}

interface ModQuestionBankStatus extends QuestionBankWithSelect {
  status: number
}

interface Props{
  location: {
    state: {
      basicData: FormData,
      allChapter?: AllChapter[],
      structuredQB?: QuestionBankWithSelect[]
    }
  },
}

const QBList: FunctionComponent<Props> = ({location}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [basicData, setBasicData] = useState<FormData>()
  const [allChapter, setAllChapter] = useState<AllChapter[]>([])
  const [structuredQB, setStructuredQB] = useState<QuestionBankWithSelect[]>([])
  const [toBeRem, setToBeRem] = useState<number[]>([])

  const [isapiLoad, setisapiLoad] = useState(false)

  const [redirectTo, setRedirectTo] = useState('');

  let history = useHistory();

  const latexParser =  (latexString:String) =>{
    let generator = new HtmlGenerator({ hyphenate: false })
    let doc = parse(latexString, { generator: generator }).htmlDocument()
    return doc.documentElement.outerHTML
  }
  
  useEffect(() => {
    if(location.state && location.state.basicData && location.state.basicData.qbName){
      window.scroll(0,0)
      setBasicData(location.state.basicData)
    }
    if(location.state.allChapter && location.state.allChapter.length > 0) {
      setAllChapter(location.state.allChapter)
    } 
    if(location.state.structuredQB && location.state.structuredQB.length > 0) {
      const remList:number[] = [];
      location.state.structuredQB.forEach((lis, remin) => {
        if(lis.selected === 0) {
          remList.push(remin)
        }
      })
      setToBeRem(remList)
      setStructuredQB(location.state.structuredQB)
    }
  }, [location]);

  if (redirectTo.length > 0) {
    return <Redirect to={redirectTo} />;
  }

  const confirmAll = async () => {
    if(toBeRem.length === structuredQB.length) {
      enqueueSnackbar('Please Select atleast One Question', {variant: 'warning'});
    } else {
      const allSelectedQuestion: QuestionBankWithSelect[] = [];
      structuredQB.forEach((sqb, ind) => {
        if(sqb.status === 0) {
          const modSqb:QuestionBankWithSelect = {
            ...sqb,
            ...{
              selected: 0
            }
          }
          allSelectedQuestion.push(modSqb)
        } else if(!toBeRem.includes(ind)) {
          const modSqb:QuestionBankWithSelect = {
            ...sqb,
            ...{
              selected: 1
            }
          }
          allSelectedQuestion.push(modSqb)
        } else {
          const modSqb:QuestionBankWithSelect = {
            ...sqb,
            ...{
              selected: 0
            }
          }
          allSelectedQuestion.push(modSqb)
        } 
      }) 

      const params = new URLSearchParams(window.location.search);
      const modeParam = params.get('mode');
      const searchParam = (modeParam === 'edit') ? `?mode=edit` : ``

      if(modeParam === 'edit') {
        const updatedQB:ModQuestionBankStatus[]  = allSelectedQuestion.map(sqb => {
          return {...sqb, ...{status: (sqb.selected === 1) ? 1 : ((sqb.selected === 0) ? 0 : 1)}}
        })
        await putQuestionBank(updatedQB)
        history.push('/edumaticaQB');
      } else {
        await postQuestionBank(allSelectedQuestion.filter(sqb => sqb.selected))
        history.push('/edumaticaQB');
      }  
    }
  }

  const editMode = (inde: number) => {
    const params = new URLSearchParams(window.location.search);
    const modeParam = params.get('mode');
    const searchParam = (modeParam === 'edit') ? `?mode=edit&index=${String(inde)}` : `?index=${String(inde)}`

    history.push({pathname: `/edumaticaQB/questions`, search: searchParam, state: {basicData: basicData, allChapter: allChapter, structuredQB: structuredQB }})
  }

  const goBack = () => {
    const params = new URLSearchParams(window.location.search);
    const modeParam = params.get('mode');
    const searchParam = (modeParam === 'edit') ? `?mode=edit` : ``

    const allSelectedQuestion: QuestionBankWithSelect[] = [];

    structuredQB.forEach((sqb, ind) => {
      if(toBeRem.includes(ind)) {
        const modSqb:QuestionBankWithSelect = {
          ...sqb,
          ...{
            selected: 0
          }
        }
        allSelectedQuestion.push(modSqb)
      } else {
        const modSqb:QuestionBankWithSelect = {
          ...sqb,
          ...{
            selected: 1
          }
        }
        allSelectedQuestion.push(modSqb)
      } 
    }) 

    history.push({pathname: `/edumaticaQB/questions`, search: searchParam, state: {basicData: basicData, allChapter: allChapter, structuredQB: allSelectedQuestion }})
  }

  if(isapiLoad) {
    return <div>
        Loading ...
    </div>
  }

  return (
    <div>
      <MiniDrawer>
      <Container maxWidth="lg" style={{padding: '30px 2.5%'}}>
        <Grid container>
          <Grid item xs={12}>
            <Box style={{marginBottom: '25px', background: '#ffffff', padding: '25px', borderRadius: '14px'}}>
              <Grid container>
                <Grid item xs={12} style={{marginBottom: '15px'}}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography style={{color : '#3D3D3D', fontSize: fontOptions.size.mediumPlus, fontWeight: fontOptions.weight.bold}}>Question Bank</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Button style={{float: 'left', padding: '10px 20px'}} color="primary" variant="contained" disableElevation
                        onClick={goBack}
                      >
                        Go Back to Editing
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {structuredQB.map((sqb, inde) => {
                  if((sqb.type === 'single' || sqb.type === 'multiple') && (sqb.status !== 0)) {
                    return (
                      <Grid item xs={12} style={toBeRem.includes(inde) ? {marginTop: '15px', marginBottom: '15px', opacity: 0.4} : {marginTop: '15px', marginBottom: '15px'}}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container style={{marginTop: '5px'}}>
                              <Grid item xs={12}>
                                {(sqb.commonQuestionPart && sqb.commonQuestionPart.length > 0) &&
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <Typography
                                        style={{ wordWrap: "break-word", color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}
                                      >
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser((String(inde+1) + '. ' + sqb.commonQuestionPart) as string)}}/>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography style={{color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer', marginTop: '2px', float: 'right', display: 'inline-block'}} 
                                        onClick={() => {
                                          if(!toBeRem.includes(inde)){
                                            const rem = [...toBeRem]
                                            rem.push(inde)
                                            setToBeRem(rem)
                                          } else {
                                            const rem = [...toBeRem].filter(fil => fil !== inde)
                                            setToBeRem(rem)
                                          }
                                        }}
                                      >
                                        {toBeRem.includes(inde) ? 'Undo' : 'Delete'}
                                      </Typography>
                                      {/* {(!toBeRem.includes(inde) && !sqb._id) &&
                                        <Typography style={{color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer', marginTop: '2px', float: 'right', display: 'inline-block', marginRight: '5px'}} onClick={() => editMode(inde)}>
                                          Edit
                                        </Typography>
                                      } */}
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop: '15px'}}>
                                      <Typography
                                        style={{ wordWrap: "break-word", color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}
                                      >
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(('Q: ' + sqb.questionDescription) as string)}}/>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                }
                                {(!sqb.commonQuestionPart) &&
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <Typography
                                        style={{ wordWrap: "break-word", color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}
                                      >
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser((String(inde+1) + '. ' + sqb.questionDescription) as string)}}/>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography style={{color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer', marginTop: '2px', float: 'right', display: 'inline-block'}} 
                                        onClick={() => {
                                          if(!toBeRem.includes(inde)){
                                            const rem = [...toBeRem]
                                            rem.push(inde)
                                            setToBeRem(rem)
                                          } else {
                                            const rem = [...toBeRem].filter(fil => fil !== inde)
                                            setToBeRem(rem)
                                          }
                                        }}
                                      >
                                        {toBeRem.includes(inde) ? 'Undo' : 'Delete'}
                                      </Typography>
                                      {/* {(!toBeRem.includes(inde) && !sqb._id)&&
                                        <Typography style={{color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer', marginTop: '2px', float: 'right', display: 'inline-block', marginRight: '5px'}} onClick={() => editMode(inde)}>
                                          Edit
                                        </Typography>
                                      } */}
                                    </Grid>
                                  </Grid>
                                }
                              </Grid>
                              {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 'q') &&
                                <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 'q')?.encoding} alt="questionImg" />
                              }
                              <Grid item xs ={12} style={{marginTop: '15px'}}>
                                <Box display="flex" flexDirection="row">
                                  <Box>
                                    <Checkbox checked={sqb.answer.includes("1")} />
                                  </Box>
                                  <Box>
                                    <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '6px'}}>
                                      <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.option1 as string)}}/>
                                      {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '1') &&
                                        <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '1')?.encoding} alt="option1Img" />
                                      }
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs ={12} style={{marginTop: '5px'}}>
                                <Box display="flex" flexDirection="row">
                                  <Box>
                                    <Checkbox checked={sqb.answer.includes("2")}/>
                                  </Box>
                                  <Box>
                                    <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '6px'}}>
                                      <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.option2 as string)}}/>
                                      {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '2') &&
                                        <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '2')?.encoding} alt="option2Img" />
                                      }
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              {sqb.option3 &&
                                <Grid item xs ={12} style={{marginTop: '5px'}}>
                                  <Box display="flex" flexDirection="row">
                                    <Box>
                                      <Checkbox checked={sqb.answer.includes("3")} />
                                    </Box>
                                    <Box>
                                      <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '6px'}}>
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.option3 as string)}}/>
                                        {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '3') &&
                                          <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '3')?.encoding} alt="option3Img" />
                                        }
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              }
                              {sqb.option4 &&
                                <Grid item xs ={12} style={{marginTop: '5px'}}>
                                  <Box display="flex" flexDirection="row">
                                    <Box>
                                      <Checkbox checked={sqb.answer.includes("4")}/>
                                    </Box>
                                    <Box>
                                      <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '6px'}}>
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.option4 as string)}}/>
                                        {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '4') &&
                                          <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '4')?.encoding} alt="option4Img" />
                                        }
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              }
                              {sqb.option5 &&
                                <Grid item xs ={12} style={{marginTop: '5px'}}>
                                  <Box display="flex" flexDirection="row">
                                    <Box>
                                      <Checkbox checked={sqb.answer.includes("5")}/>
                                    </Box>
                                    <Box>
                                      <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '6px'}}>
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.option5 as string)}}/>
                                        {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '5') &&
                                          <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '5')?.encoding} alt="option5Img" />
                                        }
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              }
                              {sqb.option6 &&
                                <Grid item xs ={12} style={{marginTop: '5px'}}>
                                  <Box display="flex" flexDirection="row">
                                    <Box>
                                      <Checkbox checked={sqb.answer.includes("6")}/>
                                    </Box>
                                    <Box>
                                      <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '6px'}}>
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.option6 as string)}}/>
                                        {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '6') &&
                                          <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '6')?.encoding} alt="option6Img" />
                                        }
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              }
                              {((sqb.solnVideoRef && sqb.solnVideoRef.length > 0) || (sqb.answerDescription && sqb.answerDescription.length > 0)) &&
                                <Grid item xs={12} style={{marginTop: '15px'}}>
                                  {console.log('heelo')}
                                  <Accordion elevation={0} style={{border: '1px solid #4C8BF5'}}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon style={{color: '#4C8BF5'}} />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography style={{color: '#4C8BF5'}}>Solution</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Grid container>
                                        <Grid item xs={12} style={{marginTop: '10px'}}>
                                          {(sqb.answerDescription && sqb.answerDescription.length > 0) &&
                                            <Typography style={{fontSize: fontOptions.size.medium, color: '#3D3D3D'}}>
                                              <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.answerDescription as string)}}/>
                                              {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 's') &&
                                                <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 's')?.encoding} alt="solutionImg" />
                                              }
                                            </Typography>
                                          }
                                          {sqb.solnVideoRef && sqb.solnVideoRef.length > 0 && sqb.solnVideoRef.match(YOUTUBE_LINK_PATTERN) &&
                                            <div>
                                              <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.small}}>Solution Link</Typography>
                                              <ReactPlayer
                                                url={sqb.solnVideoRef}
                                                controls
                                                light
                                                style={{width: '100%', marginTop: '15px'}}
                                                width={'100%'}
                                              />
                                            </div>
                                          }
                                          {sqb.solnVideoRef && sqb.solnVideoRef.length > 0 && !sqb.solnVideoRef.match(YOUTUBE_LINK_PATTERN) && sqb.solnVideoRef.match(URL_PATTERN) &&
                                            <div>
                                              <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.small}}>Solution Link</Typography>
                                              <Typography style={{color: '#4C8BF5', cursor: 'pointer', marginTop: '10px', textDecoration: 'underline'}}
                                                onClick={() => {
                                                  window.open(sqb.solnVideoRef)
                                                }}
                                              >
                                                {sqb.solnVideoRef}
                                              </Typography>
                                            </div>
                                          }
                                        </Grid>
                                      </Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                </Grid>
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  } else if(sqb.type === 'numeric' && (sqb.status !== 0)) {
                    return (
                      <Grid item xs={12} style={toBeRem.includes(inde) ? {marginTop: '15px', marginBottom: '15px', opacity: 0.4} : {marginTop: '15px', marginBottom: '15px'}}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container style={{marginTop: '5px'}}>
                              <Grid item xs={12}>
                                {(sqb.commonQuestionPart && sqb.commonQuestionPart.length > 0) &&
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <Typography
                                        style={{ wordWrap: "break-word", color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}
                                      >
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser((String(inde+1) + '. ' + sqb.commonQuestionPart) as string)}}/>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography style={{color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer', marginTop: '2px', float: 'right', display: 'inline-block'}} 
                                        onClick={() => {
                                          if(!toBeRem.includes(inde)){
                                            const rem = [...toBeRem]
                                            rem.push(inde)
                                            setToBeRem(rem)
                                          } else {
                                            const rem = [...toBeRem].filter(fil => fil !== inde)
                                            setToBeRem(rem)
                                          }
                                        }}
                                      >
                                        {toBeRem.includes(inde) ? 'Undo' : 'Delete'}
                                      </Typography>
                                      {/* {(!toBeRem.includes(inde) && !sqb._id) &&
                                        <Typography style={{color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer', marginTop: '2px', float: 'right', display: 'inline-block', marginRight: '5px'}} onClick={() => editMode(inde)}>
                                          Edit
                                        </Typography>
                                      } */}
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop: '15px'}}>
                                      <Typography
                                        style={{ wordWrap: "break-word", color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}
                                      >
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(('Q: ' + sqb.questionDescription) as string)}}/>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                }
                                {(!sqb.commonQuestionPart) &&
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <Typography
                                        style={{ wordWrap: "break-word", color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}
                                      >
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser((String(inde+1) + '. ' + sqb.questionDescription) as string)}}/>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography style={{color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer', marginTop: '2px', float: 'right', display: 'inline-block'}} 
                                        onClick={() => {
                                          if(!toBeRem.includes(inde)){
                                            const rem = [...toBeRem]
                                            rem.push(inde)
                                            setToBeRem(rem)
                                          } else {
                                            const rem = [...toBeRem].filter(fil => fil !== inde)
                                            setToBeRem(rem)
                                          }
                                        }}
                                      >
                                        {toBeRem.includes(inde) ? 'Undo' : 'Delete'}
                                      </Typography>
                                      {/* {(!toBeRem.includes(inde) && !sqb._id) &&
                                        <Typography style={{color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer', marginTop: '2px', float: 'right', display: 'inline-block', marginRight: '5px'}} onClick={() => editMode(inde)}>
                                          Edit
                                        </Typography>
                                      } */}
                                    </Grid>
                                  </Grid>
                                }
                              </Grid>
                              {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 'q') &&
                                <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 'q')?.encoding} alt="questionImg" />
                              }
                              <Grid item xs={12}>
                                <Typography
                                  style={{ wordWrap: "break-word", color: '#3D3D3D', fontWeight: fontOptions.weight.normal, fontSize: fontOptions.size.medium }}
                                >
                                  Answer: {sqb.answer[0]}
                                </Typography>
                              </Grid>
                              {((sqb.solnVideoRef && sqb.solnVideoRef.length > 0) || (sqb.answerDescription && sqb.answerDescription.length > 0)) &&
                                <Grid item xs={12} style={{marginTop: '15px'}}>
                                  <Accordion elevation={0} style={{border: '1px solid #4C8BF5'}}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon style={{color: '#4C8BF5'}} />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography style={{color: '#4C8BF5'}}>Solution</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Grid container>
                                        <Grid item xs={12} style={{marginTop: '10px'}}>
                                          {(sqb.answerDescription && sqb.answerDescription.length > 0) &&
                                            <Typography style={{fontSize: fontOptions.size.medium, color: '#3D3D3D'}}>
                                              <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.answerDescription as string)}}/>
                                              {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 's') &&
                                                <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 's')?.encoding} alt="solutionImg" />
                                              }
                                            </Typography>
                                          }
                                          {sqb.solnVideoRef && sqb.solnVideoRef.length > 0 && sqb.solnVideoRef.match(YOUTUBE_LINK_PATTERN) &&
                                            <div>
                                              <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.small}}>Solution Link</Typography>
                                              <ReactPlayer
                                                url={sqb.solnVideoRef}
                                                controls
                                                light
                                                style={{width: '100%', marginTop: '15px'}}
                                                width={'100%'}
                                              />
                                            </div>
                                          }
                                          {sqb.solnVideoRef && sqb.solnVideoRef.length > 0 && !sqb.solnVideoRef.match(YOUTUBE_LINK_PATTERN) && sqb.solnVideoRef.match(URL_PATTERN) &&
                                            <div>
                                              <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.small}}>Solution Link</Typography>
                                              <Typography style={{color: '#4C8BF5', cursor: 'pointer', marginTop: '10px', textDecoration: 'underline'}}
                                                onClick={() => {
                                                  window.open(sqb.solnVideoRef)
                                                }}
                                              >
                                                {sqb.solnVideoRef}
                                              </Typography>
                                            </div>
                                          }
                                        </Grid>
                                      </Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                </Grid>
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  } else if((sqb.status !== 0)) {
                    return (
                      <Grid item xs={12} style={toBeRem.includes(inde) ? {marginTop: '15px', marginBottom: '15px', opacity: 0.4} : {marginTop: '15px', marginBottom: '15px'}}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container style={{marginTop: '5px'}}>
                              <Grid item xs={12}>
                              {(sqb.commonQuestionPart && sqb.commonQuestionPart.length > 0) &&
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <Typography
                                        style={{ wordWrap: "break-word", color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}
                                      >
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser((String(inde+1) + '. ' + sqb.commonQuestionPart) as string)}}/>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography style={{color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer', marginTop: '2px', float: 'right', display: 'inline-block'}} 
                                        onClick={() => {
                                          if(!toBeRem.includes(inde)){
                                            const rem = [...toBeRem]
                                            rem.push(inde)
                                            setToBeRem(rem)
                                          } else {
                                            const rem = [...toBeRem].filter(fil => fil !== inde)
                                            setToBeRem(rem)
                                          }
                                        }}
                                      >
                                        {toBeRem.includes(inde) ? 'Undo' : 'Delete'}
                                      </Typography>
                                      {/* {(!toBeRem.includes(inde) && !sqb._id) &&
                                        <Typography style={{color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer', marginTop: '2px', float: 'right', display: 'inline-block', marginRight: '5px'}} onClick={() => editMode(inde)}>
                                          Edit
                                        </Typography>
                                      } */}
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop: '15px'}}>
                                      <Typography
                                        style={{ wordWrap: "break-word", color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}
                                      >
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(('Q: ' + sqb.questionDescription) as string)}}/>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                }
                                {(!sqb.commonQuestionPart) &&
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <Typography
                                        style={{ wordWrap: "break-word", color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}
                                      >
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser((String(inde+1) + '. ' + sqb.questionDescription) as string)}}/>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography style={{color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer', marginTop: '2px', float: 'right', display: 'inline-block'}} 
                                        onClick={() => {
                                          if(!toBeRem.includes(inde)){
                                            const rem = [...toBeRem]
                                            rem.push(inde)
                                            setToBeRem(rem)
                                          } else {
                                            const rem = [...toBeRem].filter(fil => fil !== inde)
                                            setToBeRem(rem)
                                          }
                                        }}
                                      >
                                        {toBeRem.includes(inde) ? 'Undo' : 'Delete'}
                                      </Typography>
                                      {/* {(!toBeRem.includes(inde) && !sqb._id) &&
                                        <Typography style={{color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer', marginTop: '2px', float: 'right', display: 'inline-block', marginRight: '5px'}} onClick={() => editMode(inde)}>
                                          Edit
                                        </Typography>
                                      } */}
                                    </Grid>
                                  </Grid>
                                }
                              </Grid>

                              {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 'q') &&
                                <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 'q')?.encoding} alt="questionImg" />
                              }

                              <Grid item xs={12} style={{marginTop: '15px'}}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={6} >
                                    {sqb.list1?.map(ls1 => {
                                      return (
                                        <Box border="1px dashed #4C8BF5" borderRadius="5px" width="100%" marginBottom="12px" 
                                          padding="20px" textAlign="center"
                                        >
                                          <div style={{marginTop: '15px'}} className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(ls1 as string)}}/>
                                        </Box>
                                      )
                                    })}
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    {sqb.list2?.map(ls2 => {
                                      return (
                                        <Box border="1px dashed #4C8BF5" borderRadius="5px" width="100%" marginBottom="12px"
                                        padding="20px" textAlign="center"
                                        >
                                          <div style={{marginTop: '15px'}} className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(ls2 as string)}}/>
                                        </Box>
                                      )
                                    })}
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs ={12} style={{marginTop: '15px'}}>
                                <Box display="flex" flexDirection="row">
                                  <Box>
                                    <Checkbox checked={sqb.answer.includes("1")} />
                                  </Box>
                                  <Box>
                                    <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '6px'}}>
                                      <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.option1 as string)}}/>
                                      {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '1') &&
                                        <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '1')?.encoding} alt="option1Img" />
                                      }
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs ={12} style={{marginTop: '5px'}}>
                                <Box display="flex" flexDirection="row">
                                  <Box>
                                    <Checkbox checked={sqb.answer.includes("2")}/>
                                  </Box>
                                  <Box>
                                    <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '6px'}}>
                                      <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.option2 as string)}}/>
                                      {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '2') &&
                                        <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '2')?.encoding} alt="option2Img" />
                                      }
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              {sqb.option3 &&
                                <Grid item xs ={12} style={{marginTop: '5px'}}>
                                  <Box display="flex" flexDirection="row">
                                    <Box>
                                      <Checkbox checked={sqb.answer.includes("3")} />
                                    </Box>
                                    <Box>
                                      <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '6px'}}>
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.option3 as string)}}/>
                                        {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '3') &&
                                          <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '3')?.encoding} alt="option3Img" />
                                        }
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              }
                              {sqb.option4 &&
                                <Grid item xs ={12} style={{marginTop: '5px'}}>
                                  <Box display="flex" flexDirection="row">
                                    <Box>
                                      <Checkbox checked={sqb.answer.includes("4")}/>
                                    </Box>
                                    <Box>
                                      <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '6px'}}>
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.option4 as string)}}/>
                                        {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '4') &&
                                          <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '4')?.encoding} alt="option4Img" />
                                        }
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              }
                              {sqb.option5 &&
                                <Grid item xs ={12} style={{marginTop: '5px'}}>
                                  <Box display="flex" flexDirection="row">
                                    <Box>
                                      <Checkbox checked={sqb.answer.includes("5")}/>
                                    </Box>
                                    <Box>
                                      <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '6px'}}>
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.option5 as string)}}/>
                                        {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '5') &&
                                          <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '5')?.encoding} alt="option5Img" />
                                        }
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              }
                              {sqb.option6 &&
                                <Grid item xs ={12} style={{marginTop: '5px'}}>
                                  <Box display="flex" flexDirection="row">
                                    <Box>
                                      <Checkbox checked={sqb.answer.includes("6")}/>
                                    </Box>
                                    <Box>
                                      <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, marginTop: '6px'}}>
                                        <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.option6 as string)}}/>
                                        {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '6') &&
                                          <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === '6')?.encoding} alt="option6Img" />
                                        }
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              }
                              {((sqb.solnVideoRef && sqb.solnVideoRef.length > 0) || (sqb.answerDescription && sqb.answerDescription.length > 0)) &&
                                <Grid item xs={12} style={{marginTop: '15px'}}>
                                  <Accordion elevation={0} style={{border: '1px solid #4C8BF5'}}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon style={{color: '#4C8BF5'}} />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography style={{color: '#4C8BF5'}}>Solution</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Grid container>
                                        <Grid item xs={12} style={{marginTop: '10px'}}>
                                          {(sqb.answerDescription && sqb.answerDescription.length > 0) &&
                                            <Typography style={{fontSize: fontOptions.size.medium, color: '#3D3D3D'}}>
                                              <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(sqb.answerDescription as string)}}/>
                                              {sqb.imageLinks && sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 's') &&
                                                <img className={classes.previewImg} src={sqb.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 's')?.encoding} alt="solutionImg" />
                                              }
                                            </Typography>
                                          }
                                          {sqb.solnVideoRef && sqb.solnVideoRef.length > 0 && sqb.solnVideoRef.match(YOUTUBE_LINK_PATTERN) &&
                                            <div>
                                              <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.small}}>Solution Link</Typography>
                                              <ReactPlayer
                                                url={sqb.solnVideoRef}
                                                controls
                                                light
                                                style={{width: '100%', marginTop: '15px'}}
                                                width={'100%'}
                                              />
                                            </div>
                                          }
                                          {sqb.solnVideoRef && sqb.solnVideoRef.length > 0 && !sqb.solnVideoRef.match(YOUTUBE_LINK_PATTERN) && sqb.solnVideoRef.match(URL_PATTERN) &&
                                            <div>
                                              <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.small}}>Solution Link</Typography>
                                              <Typography style={{color: '#4C8BF5', cursor: 'pointer', marginTop: '10px', textDecoration: 'underline'}}
                                                onClick={() => {
                                                  window.open(sqb.solnVideoRef)
                                                }}
                                              >
                                                {sqb.solnVideoRef}
                                              </Typography>
                                            </div>
                                          }
                                        </Grid>
                                      </Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                </Grid>
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  }
                })}
                <Grid item xs={12}>
                  <span style={{float: 'right', marginTop: '20px'}} className={classes.addAssi}>
                    <Button color="primary" disableElevation variant="contained" 
                      onClick={confirmAll}
                    >
                      Publish
                    </Button>
                  </span>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
      </MiniDrawer>
    </div>
  );
}

export default QBList;