import React, { FunctionComponent, useState, useEffect } from "react";
import {
    Box,
	Container,
	CssBaseline,
	Grid,
	IconButton,
	makeStyles,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import MiniDrawer from "../../../common/components/SideDrawer";
import { fontOptions } from "../../../../theme";
import { blockUnblockPC, fetchPubliccourse } from "../../../../api/dashboard";
import { PCourse } from "../../interfaces";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import Datagrids, { datagridCellExpand } from "../../components/dataGrid";
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import { RouteComponentProps, withRouter } from "react-router";

const useStyles = makeStyles({
	title: {
		fontSize: fontOptions.size.large
	},
});
  
function createData(
    _id: string,
    contentCreator: string,
    ownerId: string,
    mobileNo: string,
    title: string,
    domain: string,
    ownerName: string,
    blocked: number
): PCourse {
    return { _id, contentCreator, ownerId, mobileNo, title, domain, ownerName, blocked };
}

interface Props extends RouteComponentProps {
   
}

const CourseMarketplace: FunctionComponent<Props> = ({history}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar()

    const [courses, setCourses] = useState<PCourse[]>([])

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = async () => {
        const pcResp = await fetchPubliccourse();
        setCourses(pcResp)
    }

    const viewCourse = (data: PCourse) => {
        history.push(`/cmpreports/batches?courseId=${data._id}`)
    }

    const blockUnblock = async (data: PCourse) => {
        if(data.blocked) {
            await blockUnblockPC(data._id, 0)
            fetchData()
        } else {
            await blockUnblockPC(data._id, 1)
            fetchData()
        }
    }

    const buttonData = [
        { title: 'View',
          action: viewCourse,
          icon: <VisibilityIcon />
        },
        { title: 'Block',
          action: blockUnblock,
          icon: <BlockIcon />
        },
    ];

    const rows = courses.map((course) => {
        return(
          createData(
            course._id,
            course.contentCreator,
            course.ownerId,
            course.mobileNo,
            course.title ? course.title : '',
            course.domain,
            course.ownerName,
            course.blocked
          )
        )
    });
    
    const gridColumns: GridColDef[] = [
        { field: 'id', headerName: 'Sl No.', flex: 0.7 },
        { field: 'contentCreator', headerName: 'Creator', flex: 1, renderCell: datagridCellExpand },
        { field: 'ownerId', headerName: 'Owner ID', flex: 1, renderCell: datagridCellExpand },
        { field: 'ownerName', headerName: 'Owner Name', flex: 1, renderCell: datagridCellExpand },
        { field: 'mobileNo', headerName: 'Mobile No', flex: 1, renderCell: datagridCellExpand },
        { field: 'title', headerName: 'Title', flex: 0.7, renderCell: datagridCellExpand },
        { field: 'domain', headerName: 'Domain', flex: 1, renderCell: datagridCellExpand },
        { field: 'blocked', headerName: 'Blocked', flex: 1, renderCell: datagridCellExpand },
        { field: 'action', headerName: 'Action', flex: 1.3,
          disableClickEventBubbling: true,
          renderCell: (params: GridCellParams) => {
            const selectedRow = {
              id: params.getValue("id") as number,
              contentCreator: params.getValue("contentCreator") as string,
              ownerId: params.getValue("ownerId") as string
            }
    
            const selectedRowDetails = rows.find((row, index) => {
              return (row.contentCreator === selectedRow.contentCreator && row.ownerId === selectedRow.ownerId && index === (selectedRow.id - 1))
            })
    
            const buttonSet = buttonData.map((button, index) => {
              const title = (button.title !== 'Block') ? button.title : ((selectedRowDetails && selectedRowDetails.blocked) ? 'Unblock' : 'Block')
              
              return (
                <Tooltip key={index} title={title}>
                  <IconButton
                    onClick={() => {
                      button.action(selectedRowDetails as PCourse);
                    }}
                    size="small"
                  >
                    {button.icon}
                  </IconButton>
                </Tooltip>
              );
            })
      
            return <div>{buttonSet}</div>;
          }
        }
    ];
    
    const gridRows = rows.map((row, index) => {
        return ({
          id: (index + 1),
          contentCreator: row.contentCreator,
          ownerId: row.ownerId,
          ownerName: row.ownerName,
          mobileNo: row.mobileNo,
          title: row.title,
          domain: row.domain,
          blocked: row.blocked ? 'Yes' : 'No',
        })
    })

    return(
        <div>
            <CssBaseline />
            <MiniDrawer>
                <Container maxWidth="lg">
                    <Box
                        bgcolor="#4C8BF5"
                        padding="20px 30px"
                        marginBottom="30px"
                        position="relative"
                        borderRadius="5px"
                        color='#fff'
                    >
                        <Grid item container>
                            <Grid item sm={8}>
                                <Box style={{height: '100%'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography className={classes.title}>
                                                Course Marketplace
                                            </Typography>
                                            <Typography>
                                                View and Manage Public Courses
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        bgcolor="#FFFFFF"
                        padding="20px 30px"
                        marginBottom="30px"
                        position="relative"
                        borderRadius="5px"
                    >
                        <Grid container>
                            <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
                                <Typography style={{fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, color: '#3D3D3D'}}>
                                    Published Public Courses
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{marginBottom: '10px'}}>
                            </Grid>

                            <Grid item xs={12} style={{marginTop: '15px'}}>
                                <Datagrids gridRows={gridRows} gridColumns={gridColumns} disableCheckbox={true} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </MiniDrawer>
        </div>
    )
}

export default withRouter(CourseMarketplace);
