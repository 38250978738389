import React, { FunctionComponent, useEffect, useState } from 'react'
import { Box, FormControl, Select, Grid, MenuItem, CssBaseline, Card, Typography, CardContent, Tooltip, IconButton } from '@material-ui/core'
import { GridCellParams, GridColDef } from '@material-ui/data-grid'
import { Delete, Edit } from '@material-ui/icons'
import { useSnackbar } from "notistack"
import { Redirect } from 'react-router'

import { resourceType, webResource, webResourceRes, feedbackRes, feedback } from '../interfaces/webResource'
import useStyles from '../../dashboard/containers/customerManagement/styles'
import MiniDrawer from '../../common/components/SideDrawer'
import Datagrids, { datagridCellExpand } from '../../dashboard/components/dataGrid'
import { deleteContent, deleteFeedback, getFeedbacks, getHomeContents } from '../api'
import EditWebResourceModal from '../components/editWebResourceModal'
import EditFeedbackModal from '../components/editFeedbackModal'
import ConfirmationModal from '../../common/components/confirmation_modal'
import { convertDate, onApiError } from '../../common/helpers'
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../utilities/constants'

const EditResources: FunctionComponent = () => {
	const styles = useStyles()
	const [type, setType] = useState<resourceType>()

	const [showDataGrid, setShowDataGrid] = useState(false)
	const [gridRows, setGridRows] = useState<webResourceRes[]>([])
	const [gridColumns, setGridColumns] = useState<GridColDef[]>([])

	const [showFeedbackGrid, setShowFeedbackGrid] = useState(false)
	const [feedbackRows, setFeedbackRows] = useState<feedbackRes[]>([])
	const [feedbackColumns, setFeedbackColumns] = useState<GridColDef[]>([])

	const [selectedResource, setSelectedResource] = useState<webResourceRes>()
	const [openModal, setOpenModal] = useState(false)
	const [openResourceConfirmationModal, setOpenResourceConfirmationModal] = useState(false)
	const [selectedResourceId, setSelectedResourceId] = useState('')

	const [selectedFeedback, setSelectedFeedback] = useState<feedbackRes>()
	const [openFeedbackModal, setOpenFeedbackModal] = useState(false)
	const [openFeedbackConfirmationModal, setOpenFeedbackConfirmationModal] = useState(false)
	const [selectedFeedbackId, setSelectedFeedbackId] = useState('')
	const [redirectTo, setRedirectTo] = useState<string>('')

	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.updateContent)
			|| allowedPermissions.includes(MASTER_PERMISSIONS.updateFeedback)
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const createFeedbackGrid = (contents: feedback[]) => {
		function createData(content: feedback, i: number) {
			const { title, startDate, endDate, _id, customerPic, feedback, institute, name } = content
			return {
				title, startDate: convertDate(new Date(startDate)), endDate: convertDate(new Date(endDate)), id: i + 1, _id, customerPic, feedback, institute, name
			}
		}

		const rows = contents.map((cur, i) => createData(cur, i))

		setFeedbackRows(rows.map((row, index) => {
			const { title, startDate, endDate, id, _id, customerPic, feedback, institute, name } = row
			return ({
				title, startDate, endDate, id, _id, customerPic, feedback, institute, name
			})
		}))

		setFeedbackColumns([
			{ field: 'id', headerName: 'S.No', flex: 0.25 },
			{ field: 'name', headerName: 'Name', flex: 1, renderCell: datagridCellExpand },
			{ field: 'title', headerName: 'Title', flex: 0.8, renderCell: datagridCellExpand },
			{ field: 'startDate', headerName: 'Start Date', flex: 0.8, renderCell: datagridCellExpand },
			{ field: 'endDate', headerName: 'End Date', flex: 0.8, renderCell: datagridCellExpand },
			{
				field: 'action', headerName: 'Action', flex: 0.5,
				disableClickEventBubbling: true,
				renderCell: (params: GridCellParams) => {
					const selectedRow = {
						id: params.getValue("id") as number,
						name: params.getValue("name") as string
					}

					const selectedRowDetails = rows.find((row, index) => {
						return (row.name === selectedRow.name && index === (selectedRow.id - 1))
					})

					const viewFeedback = (row: feedbackRes) => {
						setSelectedFeedback(row)
						setOpenFeedbackModal(true)
					}

					const handleDeleteFeedback = (row: feedbackRes) => {
						setSelectedFeedbackId(row._id)
						setOpenFeedbackConfirmationModal(true)
					}

					const buttonData = [
						{
							title: 'Edit feedback',
							action: viewFeedback,
							icon: <Edit />
						},
						{
							title: 'Delete feedback',
							action: handleDeleteFeedback,
							icon: <Delete />
						}
					];

					const buttonSet = buttonData.map((button, index) => {
						return (
							<Tooltip key={index} title={button.title}>
								<IconButton
									onClick={() => {
										button.action(selectedRowDetails as feedbackRes);
									}}
									size="small"
								>
									{button.icon}
								</IconButton>
							</Tooltip>
						);
					})

					return <div>{buttonSet}</div>;
				}
			}
		])
	}

	const createDataGrid = (contents: webResource[]) => {

		function createData(content: webResource, i: number) {
			const { title, startDate, endDate, type, url, thumbnail, uuid, _id } = content
			return {
				title, startDate: convertDate(new Date(startDate)), endDate: convertDate(new Date(endDate)), id: i + 1, type, url, thumbnail, uuid, _id
			}
		}

		const rows = contents.map((cur, i) => createData(cur, i))

		setGridRows(rows.map((row, index) => {
			const { title, startDate, endDate, id, type, url, thumbnail, uuid, _id } = row
			return ({
				title, startDate, endDate, id, type, url, thumbnail, uuid, _id
			})
		}))

		setGridColumns([
			{ field: 'id', headerName: 'S.No', flex: 0.25 },
			{ field: 'title', headerName: 'Title', flex: 2, renderCell: datagridCellExpand },
			{ field: 'startDate', headerName: 'Start Date', flex: 0.8, renderCell: datagridCellExpand },
			{ field: 'endDate', headerName: 'End Date', flex: 0.8, renderCell: datagridCellExpand },
			{
				field: 'action', headerName: 'Action', flex: 0.5,
				disableClickEventBubbling: true,
				renderCell: (params: GridCellParams) => {
					const selectedRow = {
						id: params.getValue("id") as number,
						title: params.getValue("title") as string
					}

					const selectedRowDetails = rows.find((row, index) => {
						return (row.title === selectedRow.title && index === (selectedRow.id - 1))
					})

					const viewResource = (row: webResourceRes) => {
						setSelectedResource(row)
						setOpenModal(true)
					}

					const handleDeleteResource = (row: webResourceRes) => {
						if (row._id) setSelectedResourceId(row._id)
						setOpenResourceConfirmationModal(true)
					}

					const buttonData = [
						{
							title: 'View resource',
							action: viewResource,
							icon: <Edit />
						},
						{
							title: 'Delete resource',
							action: handleDeleteResource,
							icon: <Delete />
						}
					];

					const buttonSet = buttonData.map((button, index) => {
						return (
							<Tooltip key={index} title={button.title}>
								<IconButton
									onClick={() => {
										button.action(selectedRowDetails as webResourceRes);
									}}
									size="small"
								>
									{button.icon}
								</IconButton>
							</Tooltip>
						);
					})

					return <div>{buttonSet}</div>;
				}
			}
		])
	}

	const viewResources = async (selectedType: resourceType) => {
		try {
			if (selectedType === resourceType.feedback) {
				const contents = await getFeedbacks()
				createFeedbackGrid(contents)
				setShowDataGrid(false)
				setShowFeedbackGrid(true)
				setType(selectedType)
			} else {
				const contents: webResource[] = await getHomeContents(selectedType)
				createDataGrid(contents)
				setShowFeedbackGrid(false)
				setShowDataGrid(true)
				setType(selectedType)
			}
		} catch (error) {
			onApiError(error, enqueueSnackbar, setRedirectTo)
		}
	}

	const deleteResource = async () => {
		await deleteContent(selectedResourceId)
			.then(() => {
				enqueueSnackbar('Successfully deleted', { variant: 'success' })
				setSelectedResourceId('')
				setOpenResourceConfirmationModal(false)
				if (type) viewResources(type)
			})
			.catch(err => onApiError(err, enqueueSnackbar, setRedirectTo))
	}

	const handleDeleteFeedback = async () => {
		await deleteFeedback(selectedFeedbackId)
			.then(() => {
				enqueueSnackbar('Successfully deleted', { variant: 'success' })
				setSelectedFeedbackId('')
				setOpenFeedbackConfirmationModal(false)
				if (type) viewResources(type)
			})
			.catch(err => onApiError(err, enqueueSnackbar, setRedirectTo))
	}

	const renderDropdown = (key: string, list: string[], value: string | undefined, setter: any) => (
		<Grid container>
			<Grid item xs={12} md={4}>
				<FormControl fullWidth margin="normal">
					<Box className={styles.label}>{key}</Box>
				</FormControl>
			</Grid>

			<Grid item xs={12} md={8}>
				<FormControl fullWidth margin="normal">
					<Select
						value={value}
						onChange={(e: any) => setter(e.target.value)}
					>
						<MenuItem value="">Select</MenuItem>
						{list.map((item: any, index: any) => (
							<MenuItem value={item} key={index}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
	)

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				{selectedResource && type && <EditWebResourceModal
					openModal={openModal}
					onClose={() => {
						setOpenModal(false)
						setSelectedResource(undefined)
						viewResources(type)
					}}
					resource={selectedResource}
				/>}
				{selectedFeedback && type && <EditFeedbackModal
					openModal={openFeedbackModal}
					onClose={() => {
						setOpenModal(false)
						setSelectedFeedback(undefined)
						viewResources(type)
					}}
					feedbackRes={selectedFeedback}
				/>}
				{openResourceConfirmationModal && <ConfirmationModal
					header="Delete Resource"
					helperText="Are you sure you want to delete?"
					openModal={openResourceConfirmationModal}
					onClose={() => setOpenResourceConfirmationModal(false)}
					handleDelete={deleteResource}
				/>}
				{openFeedbackConfirmationModal && <ConfirmationModal
					header="Delete Feedback"
					helperText="Are you sure you want to delete?"
					openModal={openFeedbackConfirmationModal}
					onClose={() => setOpenFeedbackConfirmationModal(false)}
					handleDelete={handleDeleteFeedback}
				/>}
				<Grid container>
					<Grid item className={styles.header}>
						<Typography variant="h5">
							Edit Resources
                </Typography>
					</Grid>
					<Card className={styles.webCard} variant='outlined'>
						<CardContent className={styles.webCard}>
							{renderDropdown('Select Resource type', Object.values(resourceType), type, viewResources)}
							{showDataGrid && <Datagrids gridRows={gridRows} gridColumns={gridColumns} disableCheckbox />}
							{showFeedbackGrid && <Datagrids gridRows={feedbackRows} gridColumns={feedbackColumns} disableCheckbox />}
						</CardContent>
					</Card>
				</Grid>
			</MiniDrawer>
		</>
	)
}

export default EditResources