import React, { FunctionComponent } from 'react';
import {
  Button as BaseButton,
  ButtonProps,
  createStyles,
  Theme,
  darken
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { fontOptions } from '../../../../theme';

const getButtonColor = (
  color: string | undefined,
  variant: string | undefined,
  theme: Theme
) => {
  if (color === 'error') {
    return theme.palette.error.main;
  }

  if (variant !== 'contained') {
    return color;
  }

  switch (color) {
    case 'primary':
      return `${theme.palette.primary.main}`;
    case 'secondary':
      return `${theme.palette.error.main}`;
    default:
      return '#00000000';
  }
};

const getButtonHoverColor = (
  color: string | undefined,
  variant: string | undefined,
  theme: Theme
) => {
  if (color === 'error') {
    return theme.palette.error.main;
  }

  if (variant !== 'contained') {
    return color;
  }

  switch (color) {
    case 'primary':
      return `${darken(theme.palette.primary.main, 0.25)}`;
    case 'secondary':
      return `${darken(theme.palette.error.main, 0.25)}`;
    default:
      return `${darken('#00000000', 0.25)}`;
  }
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      background: ({ color, variant }: ButtonProps) =>
        getButtonColor(color, variant, theme),
      borderRadius: '4px',
      textTransform: 'capitalize',
      fontWeight: fontOptions.weight.bold,
      fontFamily:fontOptions.family,
      fontSize: fontOptions.size.small,
      lineHeight: '18px',
      letterSpacing: '1.25px',
      padding: '10px',
      '&:hover': {
        background: ({ color, variant }: ButtonProps) =>
        getButtonHoverColor(color, variant, theme)
      }
    }
  });

interface Props
  extends WithStyles<typeof styles>,
    Omit<ButtonProps, 'classes'> {
  component?: React.ReactNode;
  to?: string;
}

const Button: FunctionComponent<Props> = ({ classes, ...props }) => (
  <BaseButton {...props} className={classes.root} />
);

export default withStyles(styles)(Button);
