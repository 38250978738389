import React, { FunctionComponent, useState, useEffect } from 'react';
import { Box, Container, FormControl, Grid, Input, Typography, Select, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles, Theme, darken } from '@material-ui/core/styles';
import MiniDrawer from '../../common/components/SideDrawer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSnackbar } from "notistack";
import Button from '../../common/components/form_elements/button';
import { fontOptions } from '../../common/theme';
import Dropzone from '../../common/components/dropzone';
import GridPreview from '../../common/components/dropzone/previewers/gridViewUploadContent';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { createCanvas } from 'canvas';
import { jsPDF } from "jspdf"; 
import { CertificateObj, DataInput, FontArray, ImageArray, TextArray } from "../interfaces";
import { certupload, getcertuploadbyid, putcertupload } from '../helpers/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
        fontSize: fontOptions.size.large
    },
    addAssi: {
        '& button': {
          color: '#FFFFFF',
          backgroundColor: '#4C8BF5',
          '&:hover': {
            backgroundColor: darken('#4C8BF5', 0.1),
          }
        },
        paddingTop: '12px'
    },
    addAssi2: {
        '& button': {
          color: '#4C8BF5',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: darken('#FFFFFF', 0.1),
          }
        },
        paddingTop: '12px'
    },
    formInput: {
        '& input::placeholder': {
          fontWeight: fontOptions.weight.normal,
          fontSize: fontOptions.size.small,
          lineHeight: '18px',
          color: 'rgba(0, 0, 0, 0.54)'
        }
    },
    dropzoneRoot: {
        width: '100%',
        minHeight: 'auto',
        border: '2.5px dashed rgba(76, 139, 245, 0.5)',
        boxSizing: 'border-box',
        borderRadius: '5px',
        paddingBottom: '15px'
    },
    dropzoneTextContainerRoot: {
       paddingTop: '3%' 
    },
    dropzoneText: {
        fontFamily:fontOptions.family,
        fontSize: fontOptions.size.small,
        fontWeight: fontOptions.weight.bold,
        color: '#4C8BF5',
        margin: '20px 0',
    },
    dropzoneIcon: {
        color: '#4C8BF5'
    }
  }),
);

interface Props extends RouteComponentProps {}

const UploadCertificate: FunctionComponent<Props> = ({history, location})  => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [certificateName, setCertificateName] = useState('');
    const [ownerId, setOwnerId] = useState('');
    const [certificateImg, setCertificateImg] = useState('');
    const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
    const [certificateSImg, setCertificateSImg] = useState('');
    const [droppedSFiles, setDroppedSFiles] = useState<File[]>([]);
    const [figmaWidthHeight, setFigmaWidthHeight] = useState<number[]>([])
    const [textArray, setTextArray] = useState<TextArray[]>([])
    const [fontArray, setFontArray] = useState<FontArray[]>([])
    const [imageArray, setImageArray] = useState<ImageArray[]>([])
    const [isapiLoad, setisapiLoad] = useState(false)
    const [certi, setCerti] = useState<CertificateObj>()

    useEffect(() => {
        (async () => {
          try {
            setisapiLoad(true)  
            const params = new URLSearchParams(location.search);
            const id = params.get('id')
            if(id) {
                const resp = await getcertuploadbyid(id)
                setCerti(resp)
                setCertificateName(resp.certificateDesign)
                setOwnerId(resp.ownerId)
                if(resp.certificateDataUrl) {
					const imFile = convertBase64ToFile(resp.certificateDataUrl, 'certificateDataUrl')
					setDroppedFiles([imFile])
                    setFigmaWidthHeight([resp.figmaDesignWidth, resp.figmaDesignHeight])
				}

                if(resp.sample) {
					const imFiles = convertBase64ToFile(resp.sample, 'sample')
					setDroppedSFiles([imFiles])
				}

                for (let index = 0; index < resp.fontArray.length; index++) {
                    const element = resp.fontArray[index];
                    const newFile = new File([""], `${element.fontName}-${element.fontStyle}.ttf`, {type: ''})
                    setFontArray(prev => {
                        const newFont = {...element, fontfile: [newFile]}
                        return [...prev, ...[newFont]]
                    })
                }

                setTextArray(resp.textArray)

                for (let index = 0; index < resp.imageArray.length; index++) {
                    const element = resp.imageArray[index];
                    const newFile = new File([""], `image-${index + 1}.png`, {type: 'image/png'})
                    setImageArray(prev => {
                        const newImage = {...element, imagefile: [newFile]}
                        return [...prev, ...[newImage]]
                    })
                }

            }
            setisapiLoad(false)
          } catch (error) {
            console.log(error)
          }
        })();
    }, [location.search])

    useEffect(() => {
        if(droppedFiles.length === 0) {
            setCertificateImg('')
            setFigmaWidthHeight([])
        } else {
            onFileDrop(droppedFiles)
        }
    }, [droppedFiles])

    useEffect(() => {
        if(droppedSFiles.length === 0) {
            setCertificateSImg('')
        } else {
            onFileSDrop(droppedSFiles)
        }
    }, [droppedSFiles])

    const addingFile = (files: File[]) => {
        if(files.length > 0) {
            const fsize = files[0].size;
            const file = (fsize / (1024 * 1024));
            if (file >= 100) {
                enqueueSnackbar('File too Big, please select a file less than 100MB', {variant: 'error'});
            } else {
                setDroppedFiles(files)
                enqueueSnackbar('Successfully Added Image', {variant: 'success'});
            }
        }
    }

    const onFileDrop = (files: File[]) => {
        if (files.length) {
            //Restricting image file size
            const fsize = files[0].size;
            const file = (fsize / (1024 * 1024));
            if (file >= 100) {
                enqueueSnackbar('File too Big, please select a file less than 100MB', {variant: 'error'});
                return;
            }
            const img = new Image();
            img.onload = async () => {
                // Create canvas
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                // Set width and height
                canvas.width = img.width;
                canvas.height = img.height;
                // Draw the image
                if (ctx instanceof CanvasRenderingContext2D) {
                    ctx.drawImage(img, 0, 0);
                    var imgData=ctx.getImageData(0,0,canvas.width,canvas.height);
                    var data=imgData.data;
                    for(var i=0;i<data.length;i+=4){
                        if(data[i+3]<255){
                            data[i]=255;
                            data[i+1]=255;
                            data[i+2]=255;
                            data[i+3]=255;
                        }
                    }
                    ctx.putImageData(imgData,0,0);
                }
                setCertificateImg(canvas.toDataURL('image/jpeg', 1))
                if(!isapiLoad) {
                    setFigmaWidthHeight([img.width, img.height])
                }
            };
            img.src = URL.createObjectURL(files[0]);
        }
    }
    
    const removingFile = (file: File, index: number) => {
        setDroppedFiles([])
    }

    const addingSFile = (files: File[]) => {
        if(files.length > 0) {
            const fsize = files[0].size;
            const file = (fsize / (1024 * 1024));
            if (file >= 100) {
                enqueueSnackbar('File too Big, please select a file less than 100MB', {variant: 'error'});
            } else {
                setDroppedSFiles(files)
                enqueueSnackbar('Successfully Added Image', {variant: 'success'});
            }
        }
    }

    const onFileSDrop = (files: File[]) => {
        if (files.length) {
            //Restricting image file size
            const fsize = files[0].size;
            const file = (fsize / (1024 * 1024));
            if (file >= 100) {
                enqueueSnackbar('File too Big, please select a file less than 100MB', {variant: 'error'});
                return;
            }
            const img = new Image();
            img.onload = async () => {
                // Create canvas
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                // Set width and height
                canvas.width = img.width;
                canvas.height = img.height;
                // Draw the image
                if (ctx instanceof CanvasRenderingContext2D) {
                    ctx.drawImage(img, 0, 0);
                    var imgData=ctx.getImageData(0,0,canvas.width,canvas.height);
                    var data=imgData.data;
                    for(var i=0;i<data.length;i+=4){
                        if(data[i+3]<255){
                            data[i]=255;
                            data[i+1]=255;
                            data[i+2]=255;
                            data[i+3]=255;
                        }
                    }
                    ctx.putImageData(imgData,0,0);
                }
                setCertificateSImg(canvas.toDataURL('image/jpeg', 1))
            };
            img.src = URL.createObjectURL(files[0]);
        }
    }
    
    const removingSFile = (file: File, index: number) => {
        setDroppedSFiles([])
    }

    const addTextArray = () => {
        const newText: TextArray = {
            fontSize: 40,
            fontWeight: 500,
            fontStyle: '',
            fontName: '',
            fontColor: '',
            x: 0,
            y: 0,
            xWidth: 300,
            opacity: 1,
            text: '',
            align: '',
            textMeta: ''
        }

        const updatedTextArray = [...textArray, ...[newText]]
        setTextArray(updatedTextArray)
    }

    const addFontArray = () => {
        const newFont: FontArray = {
            fontName: '',
            fontStyle: '',
            fontWeight: 500,
            fontBase64: '',
            fontfile: []
        }

        const updatedFontArray = [...fontArray, ...[newFont]]
        setFontArray(updatedFontArray)
    }

    const addImageArray = () => {
        const newImage: ImageArray = {
            x: 0,
            y: 0,
            maxWidth: 0,
            maxHeight: 0,
            dataUri: '',
            imageMeta: '',
            imagefile: []
        }

        const updatedImageArray = [...imageArray, ...[newImage]]
        setImageArray(updatedImageArray)
    }

    const toBase64 = (file: File) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const convertBase64ToFile = function (image: string, name: string) {
        const byteString = atob(image.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1) {
          ia[i] = byteString.charCodeAt(i);
        }
        const newBlob = new Blob([ab], {
          type: 'image/jpeg',
        });
        const imageFile = new File([newBlob], name, {type: 'image/jpeg'});
        return imageFile;
    };

    const toBase64Img = (file: File) => new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = async () => {
            // Create canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            // Set width and height
            canvas.width = img.width;
            canvas.height = img.height;
            // Draw the image
            if (ctx instanceof CanvasRenderingContext2D) {
                ctx.drawImage(img, 0, 0);
                var imgData=ctx.getImageData(0,0,canvas.width,canvas.height);
                var data=imgData.data;
                for(var i=0;i<data.length;i+=4){
                    if(data[i+3]<255){
                        data[i]=255;
                        data[i+1]=255;
                        data[i+2]=255;
                        data[i+3]=255;
                    }
                }
                ctx.putImageData(imgData,0,0);
            }
            resolve(canvas.toDataURL('image/jpeg', 1))
        }
        img.onerror = error => reject(error);
        img.src = URL.createObjectURL(file);
    });

    const addFontFile = async(index: number, files: File[]) => {
        if(files.length > 0) {
            const fsize = files[0].size;
            const file = (fsize / (1024 * 1024));
            const fileExt = files[0].name.split('.')
            if (file >= 1) {
                enqueueSnackbar('File too Big, please select a file less than 1MB', {variant: 'error'});
            } else if(fileExt[fileExt.length - 1] !== 'ttf') {
                enqueueSnackbar('Invalid File Type', {variant: 'error'});
            } else {
                const b64file = await toBase64(files[0]) as string
                const fontBase64 = b64file.substring(37)
                setFontArray(prev => {
                    const newFA = [...prev]
                    newFA[index].fontBase64 = fontBase64
                    newFA[index].fontfile = files
                    return newFA
                })
                enqueueSnackbar('Successfully Added Image', {variant: 'success'});
            }
        }
    }

    const addImageFile = async(index: number, files: File[]) => {
        if(files.length > 0) {
            const fsize = files[0].size;
            const file = (fsize / (1024 * 1024));
            if (file >= 100) {
                enqueueSnackbar('File too Big, please select a file less than 100 MB', {variant: 'error'});
            } else {
                const b64file = await toBase64Img(files[0]) as string
                setImageArray(prev => {
                    const newFA = [...prev]
                    newFA[index].dataUri = b64file
                    newFA[index].imagefile = files
                    return newFA
                })
                enqueueSnackbar('Successfully Added Image', {variant: 'success'});
            }
        }
    }

    function scaleDataURL(dataURL: string, maxWidth: number, maxHeight: number){
        return new Promise(done=>{
          var img = new Image();
          img.onload = ()=>{
            var scale, newWidth, newHeight, canvas, ctx,dx,dy;
            if(img.width < maxWidth){
              scale = maxWidth / img.width;
            }else{
              scale = maxHeight / img.height;
            }
            newWidth = img.width * scale;
            newHeight = img.height * scale;
            dx = (maxWidth - newWidth) / 2;
            dy = (maxHeight - newHeight) / 2;
            canvas = createCanvas(newWidth,newHeight)
            ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, img.width, img.height, dx, dy, newWidth, newHeight);
            done(canvas.toDataURL());
          };
          img.src = dataURL;
        }); 
    }

    const generatePdf = async () => {
        if(certificateImg === '') {
            enqueueSnackbar('Upload a Certificate Template', {variant: 'warning'});
        } else if(certificateSImg === '') {
            enqueueSnackbar('Upload a Certificate Sample', {variant: 'warning'});
        } else if(certificateName === '') {
            enqueueSnackbar('Enter a Certificate Name', {variant: 'warning'});
        } else if(ownerId === '') {
            enqueueSnackbar('Enter a Owner ID', {variant: 'warning'});
        } else if(!textArray.find(tar => tar.textMeta === 'studentName')) {
            enqueueSnackbar('studentName TextMeta is missing in Text Array', {variant: 'warning'});
        } else if(!textArray.find(tar => tar.textMeta === 'course')) {
            enqueueSnackbar('course TextMeta is missing in Text Array', {variant: 'warning'});
        } else if(!textArray.find(tar => tar.textMeta === 'currentDate')) {
            enqueueSnackbar('currentDate TextMeta is missing in Text Array', {variant: 'warning'});
        } else if(!imageArray.find(tar => tar.imageMeta === 'brandLogo')) {
            enqueueSnackbar('brandLogo ImageMeta is missing in Image Array', {variant: 'warning'});
        } else if(!imageArray.find(tar => tar.imageMeta === 'signature')) {
            enqueueSnackbar('signature ImageMeta is missing in Image Array', {variant: 'warning'});
        } else {
            try {
                const postInput: CertificateObj = {
                    width: figmaWidthHeight[0] * 3.126050420,
                    height: figmaWidthHeight[1] * 3.12605042,
                    certificateDesign: certificateName,
                    figmaDesignWidth: figmaWidthHeight[0],
                    figmaDesignHeight: figmaWidthHeight[1],
                    fontAdjust: 1.35,
                    certificateImageFormat: 'image/png',
                    fontArray: fontArray,
                    textArray: textArray.map(ta => {
                        const newta = {...ta}
                        if(newta.align === '') {
                            delete newta.align
                        }
                        
                        if(newta.textMeta === '') {
                            delete newta.textMeta
                        }
        
                        return newta
                    }),
                    imageArray: imageArray.map(ta => {
                        const newta = {...ta}
                        
                        if(newta.imageMeta === '') {
                            delete newta.imageMeta
                        }
        
                        return newta
                    }),
                    certificateDataUrl: certificateImg,
                    sample: certificateSImg,
                    ownerId: ownerId
                }

                if(certi) {
                    postInput._id = certi._id as string
                    await putcertupload(postInput)
                    history.push('/certificateupload')
                } else {
                    await certupload(postInput)
                    history.push('/certificateupload')
                }
            } catch (error) {
                console.log(error)
            }
        }

    }

    const previewPdf = async () => {
        if(certificateImg === '') {
            enqueueSnackbar('Upload a Certificate Template', {variant: 'warning'});
        } else if(certificateSImg === '') {
            enqueueSnackbar('Upload a Certificate Sample', {variant: 'warning'});
        } else if(certificateName === '') {
            enqueueSnackbar('Enter a Certificate Name', {variant: 'warning'});
        } else if(ownerId === '') {
            enqueueSnackbar('Enter a Owner ID', {variant: 'warning'});
        } else if(!textArray.find(tar => tar.textMeta === 'studentName')) {
            enqueueSnackbar('studentName TextMeta is missing in Text Array', {variant: 'warning'});
        } else if(!textArray.find(tar => tar.textMeta === 'course')) {
            enqueueSnackbar('course TextMeta is missing in Text Array', {variant: 'warning'});
        } else if(!textArray.find(tar => tar.textMeta === 'currentDate')) {
            enqueueSnackbar('currentDate TextMeta is missing in Text Array', {variant: 'warning'});
        } else if(!imageArray.find(tar => tar.imageMeta === 'brandLogo')) {
            enqueueSnackbar('brandLogo ImageMeta is missing in Image Array', {variant: 'warning'});
        } else if(!imageArray.find(tar => tar.imageMeta === 'signature')) {
            enqueueSnackbar('signature ImageMeta is missing in Image Array', {variant: 'warning'});
        } else {
            const design = certificateName;
            const dataInput: DataInput = {
                design: design,
                textInfo: {
                    studentName: 'Test Student',
                    course : 'Test',
                    teacherName : 'Test Teacher',
                    currentDate: new Date().toLocaleDateString('en-GB')
                },
                imageInfo :{
                    brandLogo: '',
                    signature : '' 
                }
            }
    
            const certificateInfo = {
                certificateDataUrl: certificateImg,
                width: figmaWidthHeight[0] * 3.126050420,
                height: figmaWidthHeight[1] * 3.126050420,
                certificateImageFormat: 'image/png',
                fontArray: fontArray,
                figmaDesignWidth: figmaWidthHeight[0],
                figmaDesignHeight: figmaWidthHeight[1],
                textArray: textArray.map(ta => {
                    const newta = {...ta}
                    if(newta.align === '') {
                        delete newta.align
                    }
                    
                    if(newta.textMeta === '') {
                        delete newta.textMeta
                    }
    
                    return newta
                }),
                fontAdjust: 1.35,
                imageArray: imageArray.map(ta => {
                    const newta = {...ta}
                    
                    if(newta.imageMeta === '') {
                        delete newta.imageMeta
                    }
    
                    return newta
                })
            }
    
            let image = {image: certificateInfo.certificateDataUrl, width: certificateInfo.width, height: certificateInfo.height, format: certificateInfo.certificateImageFormat}
            const doc = new jsPDF({
                orientation: 'l',
                unit: 'px',
                format: [certificateInfo.width,certificateInfo.height],
                compress:true
            });
    
            certificateInfo.fontArray.map((el)=>{
                doc.addFileToVFS(`${el.fontName}-${el.fontStyle}.ttf`, el.fontBase64);
                doc.addFont(`${el.fontName}-${el.fontStyle}.ttf`, el.fontName,el.fontStyle,el.fontWeight );
            })
    
            doc.deletePage(1);
    
            let imageRatio = {width:image.width/certificateInfo.figmaDesignWidth, height: image.height/certificateInfo.figmaDesignHeight}
    
            doc.addPage();
            doc.addImage(
                image.image,
                image.format,
                0,
                0,
                image.width,
                image.height,
                undefined,
                "FAST"
            );
    
            certificateInfo.textArray.map((el)=>{
                if(el.textMeta){
                     //@ts-ignore
                    if(Object.keys(dataInput.textInfo).includes(el.textMeta) && dataInput.textInfo[el.textMeta]){
                        if(el.textMeta=='course'){
                            el={
                                ...el,
                                text : el.text.replace(/Maths/g,dataInput.textInfo[el.textMeta] )
                            }
                        }
                        else{
                            //@ts-ignore
                            el={...el, text : dataInput.textInfo[el.textMeta]}
                        }
    
                    }
                }
    
                //@ts-ignore
                doc.setGState(new doc.GState({opacity: el.opacity}));
                doc.setFont(el.fontName,el.fontStyle, el.fontWeight)
                doc.setTextColor(el.fontColor)
                doc.setFontSize(el.fontSize*imageRatio.height*certificateInfo.fontAdjust)
                //@ts-ignore
                doc.text(el.text,((el.align ? el.align=="center"?(el.x+(el.xWidth/2)):el.x:(el.x+(el.xWidth/2)))*imageRatio.width),(el.y*imageRatio.height),{ align:el.align ? el.align : "center", baseline:"top",lineHeightFactor:1.15})
            })
    
            let imagePromises = certificateInfo.imageArray.map((el)=>{
                if(el.imageMeta){
                     //@ts-ignore
                    if(dataInput.imageInfo && Object.keys(dataInput.imageInfo).includes(el.imageMeta) && dataInput.imageInfo[el.imageMeta]){
                         //@ts-ignore
                        el={ ...el,dataUri : dataInput.imageInfo[el.imageMeta]}
                    }
                }
                return new Promise(async(resolve,reject)=>{
                    try{
                        let scaledImage =await scaleDataURL(el.dataUri,el.maxWidth*imageRatio.width, el.maxHeight*imageRatio.height)
                        //@ts-ignore
                        doc.addImage(scaledImage,
                            el.dataUri.substring(el.dataUri.indexOf(":")+1, el.dataUri.indexOf(";")),
                            el.x*imageRatio.width,
                            el.y*imageRatio.height,
                            el.maxWidth*imageRatio.width,
                            el.maxHeight*imageRatio.height,
                            undefined,
                            "FAST"
                        )
                        resolve(1)
                    }
                    catch(err){
                        reject(err)
                    }
                })
            })
    
            await Promise.all(imagePromises)
            // Creates a PDF and opens it in a new browser tab.
            const pdfURL = doc.output("datauristring");
            const byteString = atob(pdfURL.split(',')[1]);
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i += 1) {
              ia[i] = byteString.charCodeAt(i);
            }
            var file = new Blob([ab], { type: 'application/pdf;base64' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }

    return (
        <div>
            <MiniDrawer>
            <Container maxWidth="lg" style={{padding: '30px 2.5%'}}>
                <Box
                    bgcolor="#4C8BF5"
                    padding="20px 30px"
                    marginBottom="30px"
                    position="relative"
                    borderRadius="14px"
                    color='#fff'
                >
                    <Grid item container>
                        <Grid item sm={12}>
                            <Box style={{height: '100%'}}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justify="center"
                                    style={{ height: '100%' }}
                                >
                                    <Grid item xs={12}>
                                        <Typography className={classes.title}>
                                            Upload Certificate
                                        </Typography>
                                        <Typography>
                                            Certificate Template Upload
                                        </Typography>
                                        <Box className={classes.addAssi2}>
                                            <Button variant="contained" disableElevation onClick={() => history.push('/certificateupload')}>
                                                Back
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    bgcolor="#ffffff"
                    borderRadius="14px"
                    padding="25px"
                    marginTop='25px'
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{marginBottom: '20px'}}>
                            <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium}}>
                                Certificate Detail
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
							<Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>
								Certificate Template
							</Typography>
							<Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>File Size upto 100 MB</Typography>
							<Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>Support Format: JPG/JPEG/PNG/SVG</Typography>
							<Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>Single File is accepted</Typography>
							<FormControl style={{width: '100%'}} margin="normal">
                                <GridPreview
                                    files={droppedFiles}
                                    onRemoveItem={(file, index) => removingFile(file, index)}
                                    setDroppedFiles={setDroppedFiles}
                                />
                                <Dropzone
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/svg+xml']}
                                    showresp={['error', 'info']}
                                    maxFileSize={104857600} // 100 MB
                                    files={droppedFiles}
                                    onChange={(files) => {
                                        addingFile(files)
                                    }}
                                    xtraClasses={{
                                        root: classes.dropzoneRoot,
                                        textContainer: classes.dropzoneTextContainerRoot,
                                        text: classes.dropzoneText,
                                        icon: classes.dropzoneIcon
                                    }}
                                    dropzoneText="Upload your file here"
                                />
							</FormControl>
						</Grid>

                        <Grid item xs={12}>
							<Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>
								Certificate Sample
							</Typography>
							<Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>File Size upto 100 MB</Typography>
							<Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>Support Format: JPG/JPEG/PNG/SVG</Typography>
							<Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>Single File is accepted</Typography>
							<FormControl style={{width: '100%'}} margin="normal">
                                <GridPreview
                                    files={droppedSFiles}
                                    onRemoveItem={(file, index) => removingSFile(file, index)}
                                    setDroppedFiles={setDroppedSFiles}
                                />
                                <Dropzone
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/svg+xml']}
                                    showresp={['error', 'info']}
                                    maxFileSize={104857600} // 100 MB
                                    files={droppedFiles}
                                    onChange={(files) => {
                                        addingSFile(files)
                                    }}
                                    xtraClasses={{
                                        root: classes.dropzoneRoot,
                                        textContainer: classes.dropzoneTextContainerRoot,
                                        text: classes.dropzoneText,
                                        icon: classes.dropzoneIcon
                                    }}
                                    dropzoneText="Upload your file here"
                                />
							</FormControl>
						</Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                    <Box>Name</Box>
                                </FormControl>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                    <FormControl style={{width: '75%'}} margin="normal">
                                        <Input
                                            placeholder="Enter Certificate Name"
                                            value={certificateName}
                                            inputProps={{ maxLength: 50 }}
                                            onChange={(e) => {
                                                setCertificateName(e.target.value)
                                            }}
                                            className={classes.formInput}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12} md={4}>
                                <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                    <Box>Figma Width</Box>
                                </FormControl>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                    <FormControl style={{width: '75%'}} margin="normal">
                                        <Input
                                            value={(figmaWidthHeight.length > 0 && String(figmaWidthHeight[0]))? String(figmaWidthHeight[0]) : ''}
                                            className={classes.formInput}
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>   

                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                    <Box>Figma Height</Box>
                                </FormControl>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                    <FormControl style={{width: '75%'}} margin="normal">
                                        <Input
                                            value={(figmaWidthHeight.length > 0 && String(figmaWidthHeight[1]))? String(figmaWidthHeight[1]) : ''}
                                            className={classes.formInput}
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12} md={4}>
                                <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                    <Box>Owner ID</Box>
                                </FormControl>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                    <FormControl style={{width: '75%'}} margin="normal">
                                        <Input
                                            placeholder="Enter Owner ID"
                                            value={ownerId}
                                            inputProps={{ maxLength: 50 }}
                                            onChange={(e) => {
                                                setOwnerId(e.target.value)
                                            }}
                                            className={classes.formInput}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>   

                        <Grid item xs={12} style={{marginTop: '20px'}}>
                            <Box display="flex" flexDirection="row">
                                <Box>
                                    <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>
                                        Font Arrays 
                                    </Typography>
                                </Box>
                                <Box style={{marginLeft: '15px'}}>
                                    <AddCircleIcon style={{ cursor: 'pointer', color: "#4C8BF5"}} onClick={addFontArray} />
                                </Box>
                            </Box>
                            
                            {fontArray.map((fa, facount) => {
                                return (
                                    <Box style={{background: '#F1F6FE', padding: '15px', marginTop: '15px'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography style={{fontWeight: fontOptions.weight.bold}}>{`Font ${String(facount + 1)}`}</Typography>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <RemoveCircleIcon style={{cursor: 'pointer', float: 'right', color: 'crimson'}} 
                                                    onClick={() => {
                                                        setTextArray(prev => {
                                                            const newFA = [...prev]
                                                            const updatedFA = newFA.filter(font => (font.fontName !== fa.fontName || font.fontStyle !== fa.fontStyle || font.fontWeight !== fa.fontWeight))
                                                            return updatedFA
                                                        })

                                                        setFontArray(prev => {
                                                            const newFA = [...prev]
                                                            newFA.splice(facount, 1)
                                                            return newFA
                                                        })
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Font Name</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter Font Name"
                                                                value={fa.fontName}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setFontArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[facount].fontName = e.target.value
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>  

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Font Style</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter Font Style"
                                                                value={fa.fontStyle}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setFontArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[facount].fontStyle = e.target.value
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>  
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Font Weight</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter Font Weight"
                                                                value={String(fa.fontWeight)}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setFontArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[facount].fontWeight = Number(e.target.value)
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>
                                                    Font File Upload
                                                </Typography>
                                                <Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>File Size upto 1 MB</Typography>
                                                <Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>Support Format: TTF</Typography>
                                                <Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>Single File is accepted</Typography>
                                                <FormControl style={{width: '100%'}} margin="normal">
                                                    {fa.fontfile && 
                                                        <GridPreview
                                                            files={fa.fontfile}
                                                            onRemoveItem={(file, index) => {
                                                                setFontArray(prev => {
                                                                    const newFA = [...prev]
                                                                    newFA[facount].fontBase64 = ''
                                                                    newFA[facount].fontfile = []
                                                                    return newFA
                                                                })
                                                            }}
                                                            setDroppedFiles={setDroppedFiles}
                                                        />
                                                    }
                                                    <Dropzone
                                                        showresp={['error', 'info']}
                                                        maxFileSize={104857600} // 100 MB
                                                        files={droppedFiles}
                                                        onChange={(files) => {
                                                            addFontFile(facount, files)
                                                        }}
                                                        xtraClasses={{
                                                            root: classes.dropzoneRoot,
                                                            textContainer: classes.dropzoneTextContainerRoot,
                                                            text: classes.dropzoneText,
                                                            icon: classes.dropzoneIcon
                                                        }}
                                                        dropzoneText="Upload your file here"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            })}
                        </Grid> 

                        <Grid item xs={12} style={{marginTop: '20px'}}>
                            <Box display="flex" flexDirection="row">
                                <Box>
                                    <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>
                                        Text Arrays 
                                    </Typography>
                                </Box>
                                <Box style={{marginLeft: '15px'}}>
                                    <AddCircleIcon style={{ cursor: 'pointer', color: "#4C8BF5"}} onClick={addTextArray} />
                                </Box>
                            </Box>

                            {textArray.map((ta, tacount) => {
                                return (
                                    <Box style={{background: '#F1F6FE', padding: '15px', marginTop: '15px'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography style={{fontWeight: fontOptions.weight.bold}}>{`Text ${String(tacount + 1)}`}</Typography>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <RemoveCircleIcon style={{cursor: 'pointer', float: 'right', color: 'crimson'}} 
                                                    onClick={() => {
                                                        setTextArray(prev => {
                                                            const newFA = [...prev]
                                                            newFA.splice(tacount, 1)
                                                            return newFA
                                                        })
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Font Name</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Select
                                                                value={ta.fontName}
                                                                className={classes.formInput}
                                                                onChange={(
                                                                    e: React.ChangeEvent<{ value: unknown }>
                                                                ) => {
                                                                    setTextArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[tacount].fontName = e.target.value as string
                                                                        return newFA
                                                                    })
                                                                }}
                                                            >
                                                                <MenuItem value="">Select Font Name</MenuItem>
                                                                {fontArray.map((fa, index) => (
                                                                    <MenuItem key={`fn${index}`} value={fa.fontName}>
                                                                        {fa.fontName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>  

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Font Style</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Select
                                                                value={ta.fontStyle}
                                                                className={classes.formInput}
                                                                onChange={(
                                                                    e: React.ChangeEvent<{ value: unknown }>
                                                                ) => {
                                                                    setTextArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[tacount].fontStyle = e.target.value as string
                                                                        return newFA
                                                                    })
                                                                }}
                                                            >
                                                                <MenuItem value="">Select Font Style</MenuItem>
                                                                {(ta.fontName !== '') && fontArray.filter(fa => fa.fontName === ta.fontName).map((fa, index) => (
                                                                    <MenuItem key={`fs${index}`} value={fa.fontStyle}>
                                                                        {fa.fontStyle}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Font Weight</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Select
                                                                value={ta.fontWeight}
                                                                className={classes.formInput}
                                                                onChange={(
                                                                    e: React.ChangeEvent<{ value: unknown }>
                                                                ) => {
                                                                    setTextArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[tacount].fontWeight = Number(e.target.value)
                                                                        return newFA
                                                                    })
                                                                }}
                                                            >
                                                                <MenuItem value="">Select Font Weight</MenuItem>
                                                                {(ta.fontName !== '' && ta.fontStyle !== '') && fontArray.filter(fa => fa.fontName === ta.fontName && fa.fontStyle === ta.fontStyle).map((fa, index) => (
                                                                    <MenuItem key={`fw${index}`} value={fa.fontWeight}>
                                                                        {fa.fontWeight}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>  

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Font Size</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter Font Size"
                                                                value={String(ta.fontSize)}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setTextArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[tacount].fontSize = Number(e.target.value)
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Font Color</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter Font Color"
                                                                value={ta.fontColor}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setTextArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[tacount].fontColor = e.target.value
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Font Opacity</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter Font Opacity"
                                                                value={String(ta.opacity)}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setTextArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[tacount].opacity = Number(e.target.value)
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>X position</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter X position"
                                                                value={String(ta.x)}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setTextArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[tacount].x = Number(e.target.value)
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Y position</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter Y position"
                                                                value={String(ta.y)}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setTextArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[tacount].y = Number(e.target.value)
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>X-Width</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter X-Width"
                                                                value={String(ta.xWidth)}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setTextArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[tacount].xWidth = Number(e.target.value)
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Text</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter Text"
                                                                value={ta.text}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setTextArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[tacount].text = e.target.value
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Text Meta</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter Text Meta"
                                                                value={ta.textMeta}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setTextArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[tacount].textMeta = e.target.value
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Align</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Select
                                                                value={ta.align}
                                                                className={classes.formInput}
                                                                onChange={(
                                                                    e: React.ChangeEvent<{ value: unknown }>
                                                                ) => {
                                                                    setTextArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[tacount].align = e.target.value as string
                                                                        return newFA
                                                                    })
                                                                }}
                                                            >
                                                                <MenuItem value="">Select Align</MenuItem>
                                                                <MenuItem value="left">Left</MenuItem>
                                                                <MenuItem value="center">Center</MenuItem>
                                                                <MenuItem value="right">Right</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            })}
                        </Grid> 

                        <Grid item xs={12} style={{marginTop: '20px'}}>
                            <Box display="flex" flexDirection="row">
                                <Box>
                                    <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>
                                        Image Arrays 
                                    </Typography>
                                </Box>
                                <Box style={{marginLeft: '15px'}}>
                                    <AddCircleIcon style={{ cursor: 'pointer', color: "#4C8BF5"}} onClick={addImageArray} />
                                </Box>
                            </Box>
                            
                            {imageArray.map((ia, iacount) => {
                                return (
                                    <Box style={{background: '#F1F6FE', padding: '15px', marginTop: '15px'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography style={{fontWeight: fontOptions.weight.bold}}>{`Image ${String(iacount + 1)}`}</Typography>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <RemoveCircleIcon style={{cursor: 'pointer', float: 'right', color: 'crimson'}} 
                                                    onClick={() => {
                                                        setImageArray(prev => {
                                                            const newFA = [...prev]
                                                            newFA.splice(iacount, 1)
                                                            return newFA
                                                        })
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>X Position</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter X Position"
                                                                value={String(ia.x)}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setImageArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[iacount].x = Number(e.target.value)
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>  

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Y Position</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter Y Position"
                                                                value={String(ia.y)}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setImageArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[iacount].y = Number(e.target.value)
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid> 

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Image Meta</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter Image Meta"
                                                                value={String(ia.imageMeta)}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setImageArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[iacount].imageMeta = e.target.value
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>  
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Max Width</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter Max Width"
                                                                value={String(ia.maxWidth)}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setImageArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[iacount].maxWidth = Number(e.target.value)
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid item xs={12} md={4}>
                                                    <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                                                        <Box>Max Height</Box>
                                                    </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={8}>
                                                        <FormControl style={{width: '75%'}} margin="normal">
                                                            <Input
                                                                placeholder="Enter Max Height"
                                                                value={String(ia.maxHeight)}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={(e) => {
                                                                    setImageArray(prev => {
                                                                        const newFA = [...prev]
                                                                        newFA[iacount].maxHeight = Number(e.target.value)
                                                                        return newFA
                                                                    })
                                                                }}
                                                                className={classes.formInput}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>
                                                    Image File Upload
                                                </Typography>
                                                <Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>File Size upto 100 MB</Typography>
                                                <Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>Support Format: JPG/JPEG/PNG/SVG</Typography>
                                                <Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>Single File is accepted</Typography>
                                                <FormControl style={{width: '100%'}} margin="normal">
                                                    {ia.imagefile && 
                                                        <GridPreview
                                                            files={ia.imagefile}
                                                            onRemoveItem={(file, index) => {
                                                                setImageArray(prev => {
                                                                    const newFA = [...prev]
                                                                    newFA[iacount].dataUri = ''
                                                                    newFA[iacount].imagefile = []
                                                                    return newFA
                                                                })
                                                            }}
                                                            setDroppedFiles={setDroppedFiles}
                                                        />
                                                    }
                                                    <Dropzone
                                                        acceptedFiles={['image/jpeg', 'image/png', 'image/svg+xml']}
                                                        showresp={['error', 'info']}
                                                        maxFileSize={104857600} // 100 MB
                                                        files={droppedFiles}
                                                        onChange={(files) => {
                                                            addImageFile(iacount, files)
                                                        }}
                                                        xtraClasses={{
                                                            root: classes.dropzoneRoot,
                                                            textContainer: classes.dropzoneTextContainerRoot,
                                                            text: classes.dropzoneText,
                                                            icon: classes.dropzoneIcon
                                                        }}
                                                        dropzoneText="Upload your file here"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            })}
                        </Grid>              
                        
                        <Grid item xs={12}>
                            <span style={{float: 'right', paddingTop: '25px'}} className={classes.addAssi}>
                                <Button color="primary" disableElevation variant="contained" onClick={previewPdf}>
                                    Preview
                                </Button>

                                <Button style={{marginLeft: '15px'}} color="primary" disableElevation variant="contained" onClick={generatePdf}>
                                    Generate
                                </Button>
                            </span>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            </MiniDrawer>
        </div>
    );
}

export default withRouter(UploadCertificate)
