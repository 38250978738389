import React, { FunctionComponent, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Tooltip
} from '@material-ui/core';

import Modal from '../../common/components/modal';
import { questionsRow } from '../interfaces';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { fontOptions } from "../../../theme";

import 'katex/dist/katex.min.css';
import "./katexCustom.css"

var Latex= require("react-latex")

interface Props {
    openModal: boolean;
    onClose: () => any;
    question: questionsRow;
    handleEdit: () => any;
    isEdited: boolean;
    saveChanges?: () => any;
}

const ViewMasterQuestionModal: FunctionComponent<Props> = ({
    openModal,
    onClose,
    question,
    handleEdit,
    isEdited,
    saveChanges
}) => {
    const {_id, answerDescription, questionDescription, option1, option2, option3, option4, imageLinks, type, answer, percentageError} = question

    const renderButton = (buttons: {name: string, cb: () => any} []) => (
        <Grid container spacing={3} justify="center" alignItems="center">
            {buttons.map(cur => (
                <Grid item>
                    <Button variant="contained" color="primary" size="medium"
                    onClick={cur.cb}
                    >
                        {cur.name}
                    </Button>
                </Grid>
            ))}
        </Grid>
    )

    const buttons = [{name: 'Edit Question', cb: handleEdit}]
    if(isEdited && saveChanges) buttons.push({name: 'Save Changes', cb: saveChanges})

    return (
        <>
        <Modal
            open={openModal}
            handleClose={onClose}
            header={
                <Box>
                    <Typography component="span" color="inherit">
                    <Box component="h3">
                        View Question
                    </Box>
                    </Typography>
                </Box>
            }
            large
        >
            <Grid container >
                <Grid item xs={9} sm={9} md={9} lg={9}>
                    <Box
                        marginLeft="-20px"
                        marginTop="10px"
                        marginBottom="10px"
                    >

                        <Typography variant="h5" >
                            <Grid container>                          
                            <Grid item md={12} lg={12} sm={12} xs={3}>
                                <Latex trust={true} strict="warn">
                                    {questionDescription}
                                </Latex>
                            </Grid>
                            </Grid>
                        </Typography>
                        {
                            imageLinks.filter((el)=>el?.filename.substring(0,1)==="q").map((image)=>{
                                const data = image.encoding
                                return <img src={`data:image/jpeg;base64,${data}`} alt="question"/>
                            })
                        }
                    </Box>

                    <Box marginBottom="20px">
                        <Grid container>
                            {type !== 'numeric' ?
                            <div style={{width: '100%'}}>
                                {[option1, option2, option3, option4].filter((el)=>el.length!==0).map((option, index) => {
                                    return (
                                        <div key={index} style={{width: '100%'}}>
                                            <Grid container>
                                            <Grid item sm={1}>
                                            <Typography style={{fontSize: fontOptions.size.medium, padding: '10px 0px'}}>{['A', 'B', 'C', 'D'][index]}.</Typography>
                                            </Grid>
                                            <Grid item sm={11} >
                                            <Box display="flex" justifyContent="flex-start">
                                                <Box>
                                                <Typography style={{fontSize: fontOptions.size.medium, padding: '10px 0px'}}>
                                                    <Latex children={option} fleqn/>
                                                </Typography>
                                                </Box>
                                                {(answer as string[]).includes(["A", "B", "C", "D"][index]) &&
                                                <Box color="#3D3D3D" marginLeft="5px">
                                                    <Typography style={{fontSize: fontOptions.size.medium, padding: '10px 0px'}}>
                                                    <CheckRoundedIcon />
                                                    </Typography>
                                                </Box>
                                                }
                                            </Box>
                                            {
                                                imageLinks.filter((el) => el.filename.substring(0, 1) === (index+1).toString()).length > 0 && imageLinks.filter((el) => el.filename.substring(0, 1) === (index+1).toString()).map((image) => {
                                                const data = image.encoding
                                                return <img src={`data:image/jpeg;base64,${data}`} alt="question" />
                                                })
                                            }
                                            </Grid>
                                            </Grid>
                                            </div>
                                    )
                                })}
                            </div> : 
                            <>
                                <Grid item xs={4} sm={2} md={2} lg={1}>
                                    <Typography
                                        variant="body1"
                                    >
                                        Ans. -
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} sm={4} md={2} lg={2}>
                                    <Typography
                                        variant="body1">

                                        {Number(answer[0]).toFixed(2)}

                                    </Typography>
                                </Grid>

                                <Grid item xs={11} sm={6} md={3} lg={3}>
                                    <Typography
                                        variant="body1">
                                        Allowed Ranges     =
                                    </Typography>
                                </Grid>

                                <Grid item xs={4} sm={2} md={1} lg={1}>
                                    <Typography
                                        variant="body1">
                                        Min :
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} sm={4} md={2} lg={2}>
                                    <Tooltip title="Change percentage error value to change values">
                                        <Typography
                                            variant="body1">

                                            {Number(Number(answer[0]) * (1 - (percentageError / 100))).toFixed(2)}

                                        </Typography>
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={4} sm={2} md={1} lg={1}>
                                    <Typography
                                        variant="body1">
                                        Max:
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} sm={4} md={2} lg={2}>
                                    <Tooltip
                                        title="Change percentage error value to change values">
                                        <Typography
                                            variant="body1">

                                            {Number(Number(answer[0]) * (1 + (percentageError / 100))).toFixed(2)}

                                        </Typography>
                                    </Tooltip>
                                </Grid>
                            </>}                                    
                        </Grid>
                    </Box>

                    <Box
                    marginLeft="-20px"
                    marginTop="10px"
                    marginBottom="10px"
                    >
                        <Typography variant="h5" >
                            Answer Description:
                            <br />
                            <Latex displayMode trust children={answerDescription}/>
                        </Typography>
                        {
                        imageLinks.filter((el)=>el.filename.substring(0,1)==="s").length>0 && imageLinks.filter((el)=>el.filename.substring(0,1)==="s").map((image)=>{
                        const data = image.encoding
                        return <img src={`data:image/jpeg;base64,${data}`} alt="question" />
                        }) 
                        }
                    </Box>

                    {renderButton(buttons)}
                    
                </Grid>
            </Grid>
        </Modal>
        </>
    )
}

export default ViewMasterQuestionModal