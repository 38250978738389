import React, { FunctionComponent, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import Modal from '../../common/components/modal';
import { mediaTypes, roles } from '../enums';
import { eduAcademyMedia } from '../interfaces';
import { URL_PATTERN } from '../../common/validations/patterns';
import { Autocomplete } from '@material-ui/lab';

interface Props {
    openModal: boolean
    onClose: () => any
    stateProps: {
        media: eduAcademyMedia[],
        setMedia: any
    },
    mediaTbeIndex: number,

    renderDropdown: (...args : any[]) => JSX.Element
    renderInput: (...args : any[]) => JSX.Element
    renderButton: (...args : any[]) => JSX.Element
    renderInfo: (...args : any[]) => JSX.Element
    classes: any
}

const AddMediaModal: FunctionComponent<Props> = ({
    openModal,
    stateProps,
    mediaTbeIndex,
    onClose,
    renderButton,
    renderInfo,
    renderDropdown,
    renderInput,
    classes
}) => {
    const {media, setMedia} = stateProps
    const mediaTbe = media[mediaTbeIndex]
    const [mediaType, setMediaType] = useState(mediaTbe ? mediaTbe.type : '')
    const [description, setDescription] = useState(mediaTbe ? mediaTbe.description : '')
    const [link, setLink] = useState(mediaTbe ? mediaTbe.link : '')
    const [categoryOrderNo, setCategoryOrderNo] = useState(mediaTbe ? mediaTbe.categoryOrderNo : media.length + 1)
    const [name, setName] = useState(mediaTbe ? mediaTbe.name : '')
    const [mediaRoles, setMediaRoles] = useState<roles[]>(mediaTbe ? mediaTbe.mediaRoles : [])
    const [encoding, setEncoding] = useState(mediaTbe ? mediaTbe.encoding : '')

    // const [dropzoneKey, setDropzoneKey] = useState(0)
	// const [droppedFiles, setDroppedFiles] = useState<File[]>([])

    const {enqueueSnackbar} = useSnackbar()

    // const getFileTypes = (type: string) => {
	// 	if (type === mediaTypes.Image) return ['image/jpeg', 'image/png']
	// 	if (type === mediaTypes.Video) return ['video/mp4']
	// 	return []
	// }

    // const addingFile = (files: File[], setter: any) => {
	// 	if (files.length < 1) return;
	// 	setter(files)
	// }

	// const removingFile = (setter: any) => setter([])

    const isValid = () => {
        if(mediaType.length < 3) {
            enqueueSnackbar('Please select a mediaType', {variant: 'warning'})
            return false
        }
        if(name.length < 3) {
            enqueueSnackbar('Min 3 characters required for name', {variant: 'warning'})
            return false
        }
        if(description.length < 4) {
            enqueueSnackbar('Min 4 characters required for description', {variant: 'warning'})
            return false
        }
        if(!link.match(URL_PATTERN)) {
            enqueueSnackbar('Link must be valid', {variant: 'warning'})
            return false
        }
        if(mediaRoles.length < 1) {
            enqueueSnackbar('Please select atleast one Applicable Role', {variant: 'warning'})
            return false
        }
        const PATTERN = /^[()a-zA-Z0-9 /',?’_+&-]*(?:[a-zA-Z][.()a-zA-Z0-9 /',?’_+&-\/]*){0,1000}$/
        if(!PATTERN.test(name)) {
            enqueueSnackbar('Invalid name', {variant: 'warning'})
            return false
        }
        const PATTERN2 = /^[()a-zA-Z0-9 /',_+&-]*(?:[a-zA-Z][.()a-zA-Z0-9 /',_+&-\/]*){0,1000}$/m
        if(!PATTERN2.test(description)) {
            enqueueSnackbar('Invalid media description', {variant: 'warning'})
            return false
        }
        return true
    }

    const handleAddMedia = async () => {
        if(!isValid()) return;

        const newMedia: eduAcademyMedia = {
            categoryOrderNo,
            description,
            encoding,
            link,
            name,
            type: mediaType,
            mediaRoles
        }

        if(mediaTbeIndex >= 0) {
            const editedMedia = media.map((cur, i) => {
                if(i === mediaTbeIndex) return newMedia
                return cur
            })
            setMedia(editedMedia)
            onClose()
            return;
        }

        setMedia([...media, newMedia])
        onClose()
    }

    return (
        <>
        <Modal
        open={openModal}
        handleClose={onClose}
        header={
            <Box>
                <Typography component="span" color="inherit">
                <Box component="h3">
                    Add Media
                </Box>
                </Typography>
            </Box>
        }
        large
        >
            {renderDropdown('Select Media Type', mediaType, Object.values(mediaTypes), setMediaType)}
            {renderInput('Enter Name', name, setName)}
            {renderInput('Enter Description', description, setDescription, 5000, true)}
            {renderInfo('Category Order No', categoryOrderNo)}
            {/* {mediaType.length > 1 && mediaType !== mediaTypes.YouTube && <>
                <Grid container justify='center'>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth margin="normal">
                            <Box className={classes.label}>
                                Upload {mediaType}
                            </Box>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Dropzone
                            key={dropzoneKey}
                            acceptedFiles={getFileTypes(mediaType)}
                            multipleFiles={false}
                            maxFileSize={104857600} // 100 MB
                            files={droppedFiles}
                            onChange={(files) => addingFile(files, setDroppedFiles)}
                        />
                        <UploadedContent
                            files={droppedFiles}
                            onRemoveItem={() => removingFile(setDroppedFiles)}
                        />
                    </Grid>
                </Grid>
            </>}

            {mediaType === mediaTypes.YouTube && renderInput('Enter Youtube Embed Link', ytlink, setYtlink)} */}

            <Grid container justify='center'>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                        <Box className={classes.label}>
                            Applicable Roles
                        </Box>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        filterSelectedOptions
                        onChange={(event, val) => setMediaRoles(val)}
                        options={Object.values(roles).sort()}
                        getOptionLabel={(option) => option}
                        defaultValue={mediaRoles}
                        disableClearable
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Applicable roles"
                            placeholder="Applicable roles"
                        />
                        )}
                    />
                </Grid>
            </Grid>

            {mediaType.length > 2 && renderInput(`Enter ${mediaType} Link`, link, setLink)}

            <br /><br />
            
            {renderButton('Add Media', handleAddMedia)}
            
        </Modal>
        </>
    )
}

export default AddMediaModal