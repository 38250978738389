import React, { FunctionComponent, useState, useEffect } from "react";
import {
    Box,
	Container,
	CssBaseline,
	Grid,
	IconButton,
	makeStyles,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import MiniDrawer from "../../../common/components/SideDrawer";
import { fontOptions } from "../../../../theme";
import { getBatchStudents, getCourseStatusCertificate, getCourseStatusTutor, getSale } from "../../../../api/dashboard";
import { FetchCourseStatus, FetchPublicSale, Student } from "../../interfaces";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import Datagrids, { datagridCellExpand } from "../../components/dataGrid";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { RouteComponentProps, withRouter } from "react-router";

const useStyles = makeStyles({
	title: {
		fontSize: fontOptions.size.large
	},
});
  
interface TutorCourseBatchStudentFetch {
    _id: string,
    studentName: string,
    mobileNo: string,
    email: string,
    joinDate: string,
    status: boolean,
    certified: boolean
}
  
function createData(
    _id: string,
    studentName: string,
    mobileNo: string,
    email: string,
    joinDate: string,
    status: boolean,
    certified: boolean
): TutorCourseBatchStudentFetch {
    return { _id, studentName, mobileNo, email, joinDate, status, certified };
}

interface Props extends RouteComponentProps {
   
}

const CMPStudents: FunctionComponent<Props> = ({location, history}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar()

    const [studentList, setStudentList] = useState<Student[]>([])
    const [saleList, setSaleList] = useState<FetchPublicSale[]>([])
    const [courseStatus, setCourseStatus] = useState<FetchCourseStatus[]>([])
    const [certStatus, setCertStatus] = useState<FetchCourseStatus[]>([])

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const courseId = params.get('courseId');
        const batchId = params.get('batchId');
        if(courseId && batchId) {
            fetchStudents(courseId, batchId)
        }
    }, []);

    const fetchStudents = async (courseId: string, batchId: string) => {
        const pbstudents = await getBatchStudents(courseId, batchId)
        setStudentList(pbstudents)
        const coursestatusresp = await getCourseStatusTutor(courseId, batchId)
        setCourseStatus(coursestatusresp)
        const coursecertstatus = await getCourseStatusCertificate(courseId, batchId, '')
        setCertStatus(coursecertstatus)
        const pbsales = await getSale(courseId, batchId, '')
        setSaleList(pbsales)
    }

    const viewStudent = (data: TutorCourseBatchStudentFetch) => {
        const params = new URLSearchParams(location.search);
        const courseId = params.get('courseId');
        const batchId = params.get('batchId');
        if(courseId && batchId) {
          history.push(`/cmpreports/studentdetail?courseId=${courseId}&batchId=${batchId}&studentId=${data._id}`)      
        }
    }

    const buttonData = [
        { title: 'View',
          action: viewStudent,
          icon: <VisibilityIcon />
        },
    ];

    const rows = studentList.map((student) => {
        const theSale = saleList.find(sl => sl.studentId === student._id)
        const status = courseStatus.find(sl => sl.studentId === student._id)
        const cert = certStatus.find(sl => sl.studentId === student._id)
        return(
          createData(
            student._id as string,
            student.studentName,
            student.mobileNo,
            student.emailId,
            theSale ? new Date(theSale.createdon).toLocaleString('en-GB') : '-',
            status ? status.status : false,
            cert ? cert.status : false
          )
        )
    });
    
    const gridColumns: GridColDef[] = [
        { field: 'id', headerName: 'Sl No.', flex: 0.7 },
        { field: 'studentName', headerName: 'Name', flex: 1, renderCell: datagridCellExpand },
        { field: 'mobileNo', headerName: 'Mobile', flex: 1, renderCell: datagridCellExpand },
        { field: 'email', headerName: 'Email', flex: 1, renderCell: datagridCellExpand },
        { field: 'joinDate', headerName: 'Joined Batch', flex: 1, renderCell: datagridCellExpand },
        { field: 'status', headerName: 'Course Completion', flex: 1, renderCell: datagridCellExpand },
        { field: 'certified', headerName: 'Cerificate Received', flex: 1, renderCell: datagridCellExpand },
        { field: 'action', headerName: 'Action', flex: 1.3,
          disableClickEventBubbling: true,
          renderCell: (params: GridCellParams) => {
            const selectedRow = {
              id: params.getValue("id") as number,
              studentName: params.getValue("studentName") as string,
              mobileNo: params.getValue("mobileNo") as string
            }
    
            const selectedRowDetails = rows.find((row, index) => {
              return (row.studentName === selectedRow.studentName && row.mobileNo === selectedRow.mobileNo && index === (selectedRow.id - 1))
            })
    
            const buttonSet = buttonData.map((button, index) => {              
              return (
                <Tooltip key={index} title={button.title}>
                  <IconButton
                    onClick={() => {
                      button.action(selectedRowDetails as TutorCourseBatchStudentFetch);
                    }}
                    size="small"
                  >
                    {button.icon}
                  </IconButton>
                </Tooltip>
              );
            })
      
            return <div>{buttonSet}</div>;
          }
        }
    ];
    
    const gridRows = rows.map((row, index) => {
        return ({
          id: (index + 1),
          studentName: row.studentName,
          mobileNo: row.mobileNo,
          email: row.email,
          joinDate: row.joinDate,
          status: row.status ? 'Completed' : 'Not Completed',
          certified : row.certified ? 'Received' : 'Not Received'
        })
    })

    return(
        <div>
            <CssBaseline />
            <MiniDrawer>
                <Container maxWidth="lg">
                    <Box
                        bgcolor="#4C8BF5"
                        padding="20px 30px"
                        marginBottom="30px"
                        position="relative"
                        borderRadius="5px"
                        color='#fff'
                    >
                        <Grid item container>
                            <Grid item sm={8}>
                                <Box style={{height: '100%'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography className={classes.title}>
                                                Course Marketplace Students
                                            </Typography>
                                            <Typography>
                                                View and Manage Students
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        bgcolor="#FFFFFF"
                        padding="20px 30px"
                        marginBottom="30px"
                        position="relative"
                        borderRadius="5px"
                    >
                        <Grid container>
                            <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
                                <Typography style={{fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, color: '#3D3D3D'}}>
                                    Public Courses Students
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{marginBottom: '10px'}}>
                            </Grid>

                            <Grid item xs={12} style={{marginTop: '15px'}}>
                                <Datagrids gridRows={gridRows} gridColumns={gridColumns} disableCheckbox={true} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </MiniDrawer>
        </div>
    )
}

export default withRouter(CMPStudents);