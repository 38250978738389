export interface jobPosting {
    jobId: string,
    name: string,
    status: string,
    department: string,
    brief: string,
    startDate: Date,
    endDate: Date,
    fullJD: string
}

export interface jobPostingRes extends jobPosting {
    _id: string,
}

export interface jobPostingRow {
    _id: string,
    jobId: string,
    name: string,
    status: string,
    department: string,
    brief: string,
    startDate: string,
    endDate: string,
    fullJD: string
}

export interface updateJobPostingReq {
    _id: string,
    name?: string,
    status?: string,
    brief?: string,
    startDate?: Date,
    endDate?: Date,
    fullJD?: string
}

export interface jobApplication {
    jobId: string,
    name: string,
    mobileNo: string,
    emailId: string,
    description: string,
    query: string,
    cvUuid: string,
    insertedon: Date,
    status: string,
    remarks: string,
    remarkedon: Date
}

export interface jobApplicationRes {
    caseId: string,
    name: string,
    cvUuid: string,
    department: string,
    jobId: string,
    jobName: string,
    fullJD: string,
    mobileNo: string,
    emailId: string,
    query: string,
    postedOn: string,
    status: string,
    remarks: string,
    remarkedon: string
}

export interface jobApplicantUpdate {
    caseId: string,
    status: jobApplicantStatus,
    remarks: string
}

export enum jobPostingStatus {
    fresh = 'Fresh',
    closed = 'Closed'
}

export enum jobApplicantStatus {
    fresh = 'Fresh',
    selected = 'Selected',
    onHold = 'On Hold',
    rejected = 'Rejected'
}