import axios from 'axios';
import {
	POST_USER_LOGIN, GET_OTP, VERIFY_OTP, SET_PASSWORD,
	UPDATE_DEFAULT_PASSWORD, VERIFY_CAPTCHA
} from './routes';


export const adminLogin = async (userName: string, password: string) => {
	const response = await axios.post(POST_USER_LOGIN, { userName, password });
	return response;
};

export const verifyCaptcha = (captchaValue: string) => {
	return axios.post(VERIFY_CAPTCHA, { captchaValue });
};

export const getOtp = async (username: string) => {
	const response = await axios.get(GET_OTP, { params: { username } });
	return response.data.otp;
}

export const verifyOtp = async (username: string, otp: string) => {
	const response = await axios.post(VERIFY_OTP, { username, otp })
	return response.data;
}

export const postPassword = async (username: string, newPassword: string) => {
	const response = await axios.post(SET_PASSWORD, { username, newPassword });
	return response;
}

export const changePassword = async (oldPassword: string, newPassword: string, isForced: boolean) => {
	const response = await axios.post(UPDATE_DEFAULT_PASSWORD, { oldPassword, newPassword, isForced });
	return response;

}