import React from 'react';
import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './App.css';
import Modal from '../../../common/components/modal'
import { Delete } from '@material-ui/icons';

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: 8,
  overflow: 'auto',
});

// @ts-ignore
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 8 * 2,
  margin: `0 ${8}px 0 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

function DragDropReorderAcademy(
  openModal: boolean,
  onClose: () => any,
  academyContents: {
    _id: string,
    orderNo: number,
    title: string,
  }[],
  setContents: any,
  classes: any,
  renderButton: (...args : any[]) => JSX.Element,
  handleSubmit: any,
  handleDeleteAcademyContent: any,
  ) {

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const items = Array.from(academyContents);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items.forEach((cur, i) => {
      cur.orderNo = i+1
    })
    setContents(items)
  }

  return (
    <div className="App">
      <Modal
        open={openModal}
        handleClose={onClose}
        header={
            <Box>
                <Typography component="span" color="inherit">
                <Box component="h3">
                    Reorder Academy Contents
                </Box>
                </Typography>
            </Box>
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters" direction="horizontal">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                    {academyContents.map((cur, index) => {
                      return (
                        <Draggable key={cur.title} draggableId={cur.title} index={index}>
                          {(provided, snapshot) => (
                            <Grid container alignItems='center' className={classes.mediaCard} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}>
                              <Grid item xs={10}>
                                <p>{cur.title} - {cur.orderNo}</p>
                              </Grid>
                              
                              <Grid item xs={2}>
                                <IconButton onClick={() => {
                                  handleDeleteAcademyContent(cur._id, index)
                                }}>
                                  <Delete />
                                </IconButton>
                              </Grid>
                            </Grid>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {renderButton('Save Changes', handleSubmit)}
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}

export default DragDropReorderAcademy;