import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	Box,
	FormControl,
	FormHelperText,
	Grid,
	Input,
	InputAdornment,
	Theme,
	Typography
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import * as yup from 'yup';
import { useSnackbar } from "notistack"
import { useDispatch } from 'react-redux';

import Button from '../../common/components/form_elements/button';
import LoginImg from '../../../assets/images/login.jpg';
import { fontOptions } from '../../../theme';
import { PASSWORD_PATTERN, PASSWORD_LENGTH, EMAIL_PATTERN, } from '../../common/validations/patterns';
import PasswordVisibilityButton from '../../common/components/password_visibility_button';
import { adminLogin, changePassword } from '../../../api/auth';
import { postPassword } from '../../../api/auth';

const styles = (theme: Theme) =>
	createStyles({
		navLink: {
			fontFamily: fontOptions.family,
			fontSize: fontOptions.size.small,
			lineHeight: '134.69%',
			letterSpacing: '0.0125em',
			textDecorationLine: 'underline'
		},
		error: {
			"&:not(.Mui-disabled)::before": {
				borderColor: "#F44E42"
			}
		},
		loginGrid: {
			height: '100vH',
			backgroundColor: '#FFFFFF'
		},
		loginHead: {
			fontSize: fontOptions.size.mediumPlus,
			fontWeight: fontOptions.weight.bold,
			color: '#3D3D3D',
			paddingTop: '3px'
		},
		loginBtn: {
			paddingTop: '50px',
			'& button': {
				padding: '12px 60px 12px 60px',
				fontWeight: fontOptions.weight.bold,
				fontSize: fontOptions.size.small,
				color: '#FFFFFF',
				borderRadius: '3px',
			},
		},
	});

const ValidationSchema = yup.object().shape({
	password: yup.string().required('Please enter your password')
		.matches(PASSWORD_PATTERN, 'Password must contain at least one uppercase, lowercase, alphanumeric & special character')
		.matches(PASSWORD_LENGTH, 'Password must contain at least 8 characters'),
	confirmPassword: yup.string().required('Please confirm your password')
		.matches(PASSWORD_PATTERN, 'Password must contain at least one uppercase, lowercase, alphanumeric & special character')
		.matches(PASSWORD_LENGTH, 'Password must contain at least 8 characters')
		.oneOf([yup.ref('password'), null], "Passwords don't match.")
});

interface Props extends RouteComponentProps<{ usertype?: string; }> { }
interface Props extends WithStyles<typeof styles> { }

interface FormData {
	password: string;
	confirmPassword: string;
	serverError: string;
	oldPassword: string
}


const SetPassword: FunctionComponent<Props> = ({ classes }) => {
	const { handleSubmit, register, errors, setError, clearError } = useForm<FormData>({
		mode: 'onBlur',
		validationSchema: ValidationSchema
	});

	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
	const [redirectTo, setRedirectTo] = useState('');
	const [isItDefaultPassword, setIsItDefaultPassword] = useState(false);
	const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();


	useEffect(() => {
		var isItDefault = JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}');
		if (isItDefault == true) {
			setIsItDefaultPassword(true);
		}
	}, [])

	const handleResetPassword = async (data: FormData) => {
		var { password, confirmPassword } = data;

		if (isItDefaultPassword) {
			var { oldPassword } = data;
			if (!PASSWORD_PATTERN.test(oldPassword)) {
				setError('oldPassword', 'Invalid Data', 'Password must contain at least one uppercase, lowercase, alphanumeric & special character');
				return;
			} else if (!PASSWORD_LENGTH.test(oldPassword)) {
				setError('oldPassword', 'Invalid Data', 'Password must contain at least 8 characters');
				return;
			} else if (oldPassword == confirmPassword) {
				setError('password', 'Invalid Data', 'Password should not match old password');
				return;
			} else {
				clearError('oldPassword');
			}
			try {
				await changePassword(oldPassword, confirmPassword, true);
				localStorage.removeItem('isItDefaultPassword');
				enqueueSnackbar('update successful', { variant: 'success' });
				setRedirectTo('/login');
			} catch (error) {
				enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			}
		} else {
			try {
				var emailId: string = localStorage.getItem('forgotPasswordEmailId') as string;
				await postPassword(emailId, confirmPassword);
				enqueueSnackbar('update successful', { variant: 'success' });
				setRedirectTo('/login');
			} catch (error) {
				enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<div>
			<Grid container>
				<Grid item xs={12} md={6} >
					<Box height="100%">
						<Box style={{ height: '100%' }}>
							<Grid item xs={12} >
								<img src={LoginImg} alt="Login" style={{ height: '100vh' }} />
							</Grid>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md={6} className={classes.loginGrid}>
					<Box paddingY="30px" paddingX="100px" height="100%">
						<Box style={{ height: '100%' }}>
							<Grid
								container
								direction="row"
								alignItems="center"
								justify="center"
								style={{ height: '95%' }}
							>
								<Grid item xs={12}>
									<Typography className={classes.loginHead}>Set Password</Typography>
									<form onSubmit={handleSubmit(handleResetPassword)}>

										{isItDefaultPassword &&
											<FormControl fullWidth margin="normal">
												<Input
													name="oldPassword"
													inputProps={{ maxLength: 20 }}
													placeholder="Enter old password"
													type={isOldPasswordVisible ? 'text' : 'password'}
													endAdornment={
														<InputAdornment position="end">
															<PasswordVisibilityButton
																isVisible={isOldPasswordVisible}
																handleChange={(isVisible) =>
																	setIsOldPasswordVisible(isVisible)
																}
															/>
														</InputAdornment>
													}
													inputRef={register}
													className={errors.oldPassword ? classes.error : ''}
												/>
												{errors.oldPassword && (
													<FormHelperText error>
														{errors.oldPassword.message}
													</FormHelperText>
												)}
											</FormControl>
										}

										<FormControl fullWidth margin="normal">
											<Input
												name="password"
												inputProps={{ maxLength: 20 }}
												placeholder="Enter password"
												type={isPasswordVisible ? 'text' : 'password'}
												endAdornment={
													<InputAdornment position="end">
														<PasswordVisibilityButton
															isVisible={isPasswordVisible}
															handleChange={(isVisible) =>
																setIsPasswordVisible(isVisible)
															}
														/>
													</InputAdornment>
												}
												inputRef={register}
												className={errors.password ? classes.error : ''}
											/>
											{errors.password && (
												<FormHelperText error>
													{errors.password.message}
												</FormHelperText>
											)}
										</FormControl>

										<FormControl fullWidth margin="normal">
											<Input
												name="confirmPassword"
												placeholder="Re enter password"
												inputProps={{ maxLength: 20 }}
												type={isConfirmPasswordVisible ? 'text' : 'password'}
												endAdornment={
													<InputAdornment position="end">
														<PasswordVisibilityButton
															isVisible={isConfirmPasswordVisible}
															handleChange={(isVisible) =>
																setIsConfirmPasswordVisible(isVisible)
															}
														/>
													</InputAdornment>
												}
												inputRef={register}
												className={errors.confirmPassword ? classes.error : ''}
											/>
											{errors.confirmPassword && (
												<FormHelperText error>
													{errors.confirmPassword.message}
												</FormHelperText>
											)}
										</FormControl>
										<Box className={classes.loginBtn}>
											<Button
												disableElevation
												variant="contained"
												color="primary"
												size="large"
												type="submit"
											>
												Set Password
                      </Button>
										</Box>
										{errors.serverError && (
											<FormHelperText error>
												{errors.serverError.message}
											</FormHelperText>
										)}
									</form>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Grid>
				<Box
					position="absolute"
					bottom="20px"
					left="20px"
					color="#666666"
					fontFamily={fontOptions.family}
					fontSize={fontOptions.size.small}
				>
					V 1.0
        </Box>
			</Grid>
		</div>
	)
};

export default withStyles(styles)(withRouter(SetPassword));
