import React, { FunctionComponent, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  Input,
  Typography,
  Button,
  Select,
  MenuItem
} from '@material-ui/core';

import { jobApplicantStatus, jobApplicationRes } from '../interfaces';
import Modal from '../../common/components/modal';
import useStyles from '../../leadsManagement/styles';
import { fetchDownloadUrlForCv, updateApplicant, updateApplicants } from '../api';

interface Props {
    openModal: boolean;
    onClose: () => any;
    applicant: jobApplicationRes;
}

const JobApplicantModal: FunctionComponent<Props> = ({
    openModal,
    onClose,
    applicant
}) => {
    const styles = useStyles();

    const {cvUuid, department, name, mobileNo, query, emailId, postedOn, remarks, remarkedon, jobId, fullJD, status, jobName, caseId} = applicant

    const [newRemark, setNewRemark] = useState(remarks ? remarks : '')
    const [newStatus, setNewStatus] = useState(status ? status : '')
    const [resumeLink, setResumeLink] = useState('')

    const saveChanges = async () => {
        const updateReq = [{
            caseId,
            status: newStatus,
            remarks: newRemark
        }]
        await updateApplicants(updateReq).then(() => {
            setResumeLink('')
            onClose()
        })
    }

    const downloadResume = async () => {
        await fetchDownloadUrlForCv(cvUuid).then(val => {
            window.open(val)
        })
    }

    const renderInfo = (key: string, value: string) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Box>{value}</Box>
            </FormControl>
            </Grid>
        </Grid>
    )

    const renderInput = (key: string, value: any, setter: any) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Input
                placeholder={key}
                value={value}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => setter(e.target.value)}
                />
            </FormControl>
            </Grid>
        </Grid>
    )

    const renderDropdown = (key: string, list: string[], value: string, setter: any) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Select
                value={value}
                onChange={(e: any) => setter(e.target.value)}
                >
                    <MenuItem value="">Select</MenuItem>
                    {list.map((item: any, index: any) => (
                        <MenuItem value={item} key={index}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            </Grid>
        </Grid>
    )

    const renderButton = (name: string, cb: any) => (
        <Button 
        variant="contained" 
        color="primary" 
        size="medium"
        style={{ margin: 10 }}
        onClick={cb}
        >
			{name}
		</Button>
    )

    return (
        <Modal
            open={openModal}
            handleClose={onClose}
            header={
                <Box>
                    <Typography component="span" color="inherit">
                    <Box component="h3">
                        Applicant Information
                    </Box>
                    </Typography>
                </Box>
            }
        >
            {renderInfo('Applicant Name', name)}
            {renderInfo('Mobile', mobileNo)}
            {renderInfo('Email', emailId)}
            {renderInfo('JobId', `${jobId} - ${jobName}`)}
            {query && renderInfo('Query', query)}
            {renderInfo('Posted on', postedOn)}
            {renderButton('Download Resume', downloadResume)}
            {resumeLink &&
                    <>
                      <iframe id="my_iframe" style={{ "display": "none" }} src={resumeLink} />
                    </>}
            {renderInput('Remarks', newRemark, setNewRemark)}
            {renderDropdown('Status', Object.values(jobApplicantStatus), newStatus, setNewStatus)}
            {renderButton('Save Changes', saveChanges)}
        </Modal>
    )
}

export default JobApplicantModal