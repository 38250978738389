import axios from "axios"

const BASE_URL = process.env.REACT_APP_API_2 + "lead/"
const GET_LEADS = BASE_URL + "getLeads"
const UPDATE_LEADS = BASE_URL + "updateLeads"

export const getLeads = async (query: any) => {
    const axiosOptions: any = {
        params: query,
    }
    if(query.dataType === 'file') {
        axiosOptions.responseType = 'blob'
        await axios.get(GET_LEADS, axiosOptions).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'leads.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    } 
    const res = await axios.get<{data: any}>(GET_LEADS, axiosOptions).then((response) => response.data.data);
    return res
}

export const updateLeads = async (updateReq: any) => {
    return await axios.post(UPDATE_LEADS, updateReq)
}