//@ts-nocheck
import {
    Box,
    LinearProgress,
    LinearProgressProps, Container, createStyles, FormControl, Grid,
    IconButton, Input, makeStyles, MenuItem, Select, SvgIconProps,
    Theme, Tooltip, Typography, Button
  } from '@material-ui/core';
  import { Delete as DeleteIcon, Folder as FolderIcon } from '@material-ui/icons';
  import AddBoxIcon from '@material-ui/icons/AddBox';
  import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
  import ArrowRightIcon from '@material-ui/icons/ArrowRight';
  import BookmarkIcon from '@material-ui/icons/Bookmark';
  import DescriptionIcon from '@material-ui/icons/Description';
  import EditIcon from '@material-ui/icons/Edit';
  import FileCopyIcon from '@material-ui/icons/FileCopy';
  import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
  import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
  import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
  import TreeView from '@material-ui/lab/TreeView';
  import axios from 'axios';
  import ObjectID from "bson-objectid";
  import { startCase, truncate, sortBy } from 'lodash';
  import { DropzoneArea } from 'material-ui-dropzone';
  import { useSnackbar } from 'notistack';
  import React, { FunctionComponent, useEffect, useState } from 'react';
  import { Redirect } from 'react-router-dom';
  import MiniDrawer from '../../common/components/SideDrawer';
  import { fontOptions } from "../../common/theme"
  import Resumable from "resumablejs";
  import { v4 as uuidv4 } from 'uuid';
  import { APISubject, isStudentEnrolled, uploadFileOnUrl } from '../helper/api';
  import Dropzone from '../components/dropzone';
  import GridPreview from '../components/gridViewUploadContent';
  import Modal from '../components/modal';
  import { apiDataProcess, DataApiResponse, getCourseBundles, getManifest, getReportData, getUploadUrlApi, publicCourse } from '../helper/api';
  import { Chapter, Content, CourseBundle, QuestionData, Subject, Topic } from "../helper/contracts";
  import Datagrids, {datagridCellExpand} from '../components/datagrids'
  import { GridColDef, GridCellParams } from '@material-ui/data-grid';
  import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
  import CheckCircleIcon from '@material-ui/icons/CheckCircle';
  
  interface Props {}
  
  
  declare module 'csstype' {
    interface Properties {
      '--tree-view-color'?: string;
      '--tree-view-bg-color'?: string;
    }
  }
  
  type StyledTreeItemProps = TreeItemProps & {
    labelIcon: React.ElementType<SvgIconProps>;
    labelText: string;
    level:number
    bgColor?: string;
    color?: string;
    labelInfo?: string;
    labelData? : string;
    addFunc? : ()=>void;
    editFunc? : () => void;
    deleteFunc?: ()=>void;
    addFuncTip? : string;
    editFuncTip?: string;
    deleteFuncTip?: string;
    subIndex?: number
    chapIndex?: number;
    topIndex? :number
    conIndex? : number
    quesIndex? : number
  };
  
  const useTreeItemStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
          backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
          backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
          color: 'var(--tree-view-color)',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
          backgroundColor: 'transparent',
        },
      },
      content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
          fontWeight: theme.typography.fontWeightRegular,
        },
      },
      
      expanded: {},
      selected: {},
      label: {
        fontWeight: 'inherit',
        color: 'inherit',
      },
      labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
      },
      labelIcon: {
        marginRight: theme.spacing(1),
      },
      labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
      },
    }),
  );
  
  const useStyles = makeStyles(
    createStyles({
      root: {
        flexGrow: 1,
        maxWidth: 1000,
      },
      header : {
        color: '#4C8BF5',
        fontSize: fontOptions.size.mediumPlus, 
        fontWeight: fontOptions.weight.bolder
      },
      dropzoneRoot: {
        width: '100%',
        minHeight: 'auto',
        border: `2.5px dashed #4C8BF5`,
        boxSizing: 'border-box',
        borderRadius: '5px',
        paddingBottom: '15px'
      },
      dropzoneTextContainerRoot: {
        paddingTop: '3%'
      },
      dropzoneText: {
        fontFamily: fontOptions.family,
        fontSize: fontOptions.size.small,
        fontWeight: fontOptions.weight.bold,
        color: '#4C8BF5',
        margin: '20px 0',
      },
      dropzoneIcon: {
        color: '#4C8BF5'
      }
    }),
  );
  
  
  
  console.log(ObjectID().toHexString())
  function StyledTreeItem(props: StyledTreeItemProps) {
    const classes = useTreeItemStyles();
    const { labelText,labelData,level, labelIcon: LabelIcon, labelInfo, color, bgColor,addFunc , editFunc, deleteFunc, addFuncTip , editFuncTip, deleteFuncTip,subIndex, chapIndex, topIndex, conIndex, quesIndex, ...other } = props;
  
    return (
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <LabelIcon color="inherit" className={classes.labelIcon} />
            <div className={classes.labelText}> 
            <Typography variant="body2" >
              {labelText}
            </Typography>
            <Typography variant="caption" >
              {labelData}
            </Typography>
            </div>
            <div>
              <Box display="flex">
              {
                addFunc && 
                  <Tooltip title={addFuncTip ? addFuncTip : ''}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        //@ts-ignore
                        addFunc(subIndex, chapIndex, topIndex, conIndex, quesIndex)
                      }}
                      >
                      <AddBoxIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
              }
              {
                editFunc &&
                <Tooltip title={editFuncTip ? editFuncTip : ''}>
  
                <IconButton 
                size="small"
                
                onClick={() => {
                  //@ts-ignore
                  editFunc(subIndex, chapIndex, topIndex, conIndex, quesIndex)
                }}
                >
                  <EditIcon fontSize="small"/>
                </IconButton>
                </Tooltip>
              }
              {
                deleteFunc &&
                <Tooltip title={deleteFuncTip ? deleteFuncTip : ''}>
  
                <IconButton 
                size="small"
                
                onClick={() => {
                  //@ts-ignore
                  deleteFunc(subIndex, chapIndex, topIndex, conIndex, quesIndex)
                }}
                >
                  <DeleteIcon fontSize="small"/>
                </IconButton>
                </Tooltip>
              }
              </Box>
            </div>
          </div>
        }
        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          label: classes.label,
          //@ts-ignore
          group: {
            marginLeft: 0,
            '& $content': {
              paddingLeft: `${(8*level).toString()}px`,
            },
          }
        }}
        {...other}
      />
    );
  }
  
  interface RowData {
    id: string;
    index: string|number;
    error:string;
  }
  
  
  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography style={{float: 'right'}} variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  
  interface CourseModalProps {
    openModal: boolean;
    onClose: () => any;
    mode : "add" | "update";
    subject :"courseBundle" | "subject" |"chapter" |"topic" |"content" | "question"
    saveData : (saveData : CourseBundle | Subject | Chapter | Topic | Content | QuestionData) => any
    data?: CourseBundle | Subject | Chapter | Topic | Content | QuestionData,
    
  }
  
  interface DeleteProps {
    subject:"courseBundle" | "subject" |"chapter" |"topic" |"content" | "question";
    name: string;
    openModal: boolean;
    onClose: () => any;
    mongoId : string 
    handleDelete: (data:string) => any;
  }
  
  const DeleteModal: FunctionComponent<DeleteProps> = ({
    subject,
    name,
    openModal,
    onClose,
    mongoId,
    handleDelete,
  }) => {
    return (
      <Modal
        open={openModal}
        handleClose={onClose}
        mWidth="small"
        header={
          <Box marginLeft="10px">
            <Typography component="span" color="secondary">
              <Box component="h3" color="white" fontWeight="400" margin="0">
                {`Delete ${startCase(subject)}`}
              </Box>
            </Typography>
          </Box>
        }
      >
        <Box padding="0 10px">
          <Box marginBottom="10px">
            <Typography>{`Delete ${startCase(subject)} with ${startCase(subject)} name : ${name}`}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" marginTop="10px">
            <Box marginRight="10px">
              <Button variant="contained" color="secondary" onClick={onClose}>
                Cancel
              </Button>
            </Box>
  
            <Button variant="contained" color="primary" onClick={()=>{
              handleDelete(mongoId)
            }} >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };
  
  
  
  const UpdateModal: FunctionComponent<CourseModalProps> = ({openModal, onClose, mode , data,saveData, subject }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [formData, setFormData] = useState<typeof data>(data)
    const [faqValue, setFaqValue ] = useState<string>('')
    console.log("formData", data)
    let blankCourseBundle = {
      uuid : uuidv4(),
      _id : ObjectID().toHexString(),
      "board": "CBSE",
      "class":"Class VI",
      "salePrice": 799,
      "aboutPackage": "This is about Package",
      "visibleOrgs": ["EDUMATICA"],
      "localCurrency" : "INR",
      "foreignCurrency" : "USD",  
      subjects : []  
    }
  
    let blankSubject:Subject = {
      _id : ObjectID().toHexString(),
      "board": "CBSE",
      "class":"Class VI",
      "defaultContentName": "Subject Title",
      "subjectname": "Subject",
      "courseDesc": "Course Description",
      "courseType": "Board Material",
      "takeaways": [],
      "syllabusPath": "",
      "offerDescription": "Offer Description",
      "salePrice": 799,
      "localCurrency": "INR",
      "foreignCurrency": "USD",
      "deliveryLang": "English",
      "faqs":[],
      "chaptersData": []
    }
  
    let blankChapter:Chapter = {
      _id : ObjectID().toHexString(),
      "name": "Chapter Name",
      "orderNo": 10,
      "chapterSummaryPath": "",
      "conceptSummaryPath": "",
      "mindMapPath": "",
      "chapterDesc": "Chapter Description",   
      topics : []
    }
  
    let blankTopic:Topic = {
      _id : ObjectID().toHexString(),
      "name": "Topic Name ",
      "description": "Topic Description",
      "orderNo": 1,
      "trial": true,
      "qualificationMarksPercent": 50,
      contents : []
    }
  
    let blankContent : Content = {
      _id : ObjectID().toHexString(),
      "title": "Content Name",
      "type": "video",
      "description": "Content Desc",
      "isQualificationQuiz": false,
      "quizDuration" : 5,
      "optional": false,
      orderNo :  1,
      path : "",
      requiredPercentage : 50,
      questionData: []  
    }
  
    let blankQuestion : QuestionData = {
      _id : ObjectID().toHexString(),
      "questionText": "QUestionText",
      "option1": "Option 1",
      "option2": "Option 2",
      "option3": "Option 3",
      "option4": "Option 4",
      "solution": "Solution",
      "answer": "Answer",  
      "marks": 2,
      "imagePaths":0,
      "list1": [],
      "list2": [],
      "type": "single",
      "negativeMarking": 0,
      "percentageError" : 0
    }
    useEffect(() => {
      if (mode == "add") {
        console.log("C1")
        if (!data) {
          switch (subject) {
            case "courseBundle":
              setFormData(blankCourseBundle)
              break;
            case "subject":
              setFormData(blankSubject)
              break;
            case "chapter":
              setFormData(blankChapter)
              break;
            case "topic":
              setFormData(blankTopic)
              break;
            case "content":
              setFormData(blankContent)
              break;
            case "question":
              setFormData(blankQuestion)
              break;
  
            default:
              break;
          }
        }
      }
    }, [openModal])
    return ( 
      <Modal
        open={openModal}
        handleClose={onClose}
        header={
          <Box width="400px" display="flex" alignItems="center">
            <Box marginLeft="15px">
              <Typography component="span" color="secondary">
                <Box component="h3" color="white" fontWeight="400" margin="0">
                  {`${startCase(mode)} ${startCase(subject)}`}
                </Box>
              </Typography>
            </Box>
          </Box>
        }
      >
        <Container>
          <Box width="100%">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>
                  
                </Typography>
              </Grid>
              <Grid item xs={12} >
              
                {
                  formData && Object.keys(formData).map((key, index)=>{
                    console.log(typeof formData[key])
                    if(key.includes("path")|| key.includes("Path")){
                      return <React.Fragment>
                        <Grid container>
                                              <Grid item xs={12} md={4}>
                      <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                        <Box> {startCase(key)} </Box>
                      </FormControl>
                    </Grid>
  
                    <Grid item xs={12} md={8}>
                      <FormControl style={{width: '75%'}} margin="normal">
                        {
                          typeof formData[key] =="object" && <GridPreview
                          files={[formData[key]]}
                          onRemoveItem={(file =>{
                            setFormData((prev)=>{
                              if(prev){
                                return {
                                  ...prev,
                                  [key] : ''
                                }
                              }
                              else{
                                return prev
                              }
                            })
                          })}
                        />
                        }
                      
                    <Dropzone
                      acceptedFiles={['image/jpeg', 'image/png', 'application/pdf','video/mp4']}
                      multipleFiles={true}
                      maxFileSize={5368709120} // 5 GB
                      files={[formData[key]]}
                      onDrop={(file =>{
                        setFormData((prev)=>{
                          if(prev){
                            return {
                              ...prev,
                              [key] : file[0]
                            }
                          }
                          else{
                            return prev
                          }
                        })
                      })}
                      showresp={['error', 'info']}
                      dropzoneText="Upload your file here"
                    />  
                           
                        
                      </FormControl>
                    </Grid>
                  </Grid>
                  </React.Fragment>
                      
                    }
                    if(key.includes("faqs")|| key.includes("Faqs")){
                      return <React.Fragment>
                        <Grid container>
                        <Grid item xs={12} md={4}>
                      <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                        <Box> {startCase(key)} </Box>
                      </FormControl>
                    </Grid>
  
                    <Grid item xs={12} md={8}>
                      <FormControl style={{width: '75%'}} margin="normal">
                        {
                      <Input
                        inputProps={{ maxLength: 3000 }}
                        multiline
                        style={{width:'100%'}}
                        rows = {6}
                        value={formData[key].map(el=>[el.questionText,el.responseText].join('|')).join('#')}
                        disabled={key=="_id" || key =="uuid"}
                        type={key.includes("path")|| key.includes("Path")? "file": typeof formData[key] =="number" ? "number" : "text" }
                        onChange={(e:React.ChangeEvent<{ value: unknown }>) => {
                          const value = e.target.value 
                          setFormData((prev)=>{
                            if(prev){
                              return {
                                ...prev,
                                [key] : value.split('#').map(el=>{return {questionText : el.split('|')[0]? el.split('|')[0] : '' , responseText : el.split('|')[1] ? el.split('|')[1] : ''}})
                              }
                            }
                            else{
                              return prev
                            }
                          })
                        }}
                      />
                           
                        }
                      </FormControl>
                    </Grid>
                  </Grid>
                  </React.Fragment>
                      
                    }
                    if(key.includes("visibleOrgs")|| key.includes("VisibleOrgs") || key.includes("takeaways")|| key.includes("Takeaways")|| key.includes("list1")|| key.includes("List1")|| key.includes("list2")|| key.includes("List2")  ){
                      return <React.Fragment>
                        <Grid container>
                        <Grid item xs={12} md={4}>
                      <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                        <Box> {startCase(key)} </Box>
                      </FormControl>
                    </Grid>
  
                    <Grid item xs={12} md={8}>
                      <FormControl style={{width: '75%'}} margin="normal">
                        {
                      <Input
                        inputProps={{ maxLength: 3000 }}
                        value={formData[key].join('|')}
                        disabled={key=="_id" || key =="uuid"}
                        multiline
                        rows={(key.includes("visibleOrgs")|| key.includes("VisibleOrgs")) ? 1 : 6}
                        type={key.includes("path")|| key.includes("Path")? "file": typeof formData[key] =="number" ? "number" : "text" }
                        onChange={(e:React.ChangeEvent<{ value: unknown }>) => {
                          const value = e.target.value 
                          setFormData((prev)=>{
                            if(prev){
                              return {
                                ...prev,
                                [key] : value.split('|')
                              }
                            }
                            else{
                              return prev
                            }
                          })
                        }}
                      />
                           
                        }
                      </FormControl>
                    </Grid>
                  </Grid>
                  </React.Fragment>
                      
                    }
                    if(typeof formData[key]=="string" || typeof formData[key]=="number" || typeof formData[key]=="boolean" ){
                      return <Grid container>
                        <Grid item xs={12} md={4}>
                      <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                        <Box> {startCase(key)} </Box>
                      </FormControl>
                    </Grid>
  
                    <Grid item xs={12} md={8}>
                      <FormControl style={{width: '75%'}} margin="normal">
                        {
                      <Input
                        inputProps={{ maxLength: 200 }}
                        value={formData[key]}
                        disabled={key=="_id" || key =="uuid"}
                        type={key.includes("path")|| key.includes("Path")? "file": typeof formData[key] =="number" ? "number" : "text" }
                        onChange={(e:React.ChangeEvent<{ value: unknown }>) => {
                          const value = e.target.value 
                          setFormData((prev)=>{
                            if(prev){
                              return {
                                ...prev,
                                [key] : value
                              }
                            }
                            else{
                              return prev
                            }
                          })
                        }}
                      />
                           
                        }
                      </FormControl>
                    </Grid>
                  </Grid>
                    }
                    else{
                      return <React.Fragment>
  
                      </React.Fragment>
                    }
                    
                  })
                }
                 
                
                {/* <Typography style={{color: '#3D3D3D', marginTop: '5px', display: 'inline-block', fontWeight: fontOptions.weight.bold}} >Change {type} name - {prev} to</Typography> */}
                {/* <TextField style={{margin: '10px', color: '#3D3D3D'}} value={blockReason} placeholder="Reason for blocking" onChange={(e) => setBlockReason(e.target.value)} /> */}
                {/* <TextField style={{margin: '10px', color: '#3D3D3D'}} value={blockReason} placeholder="Display Text for Student" onChange={(e) => setBlockDisplayText(e.target.value)} /> */}
              </Grid>
  
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" marginTop="10px">
                  <Box marginRight="10px">
                    <Button variant="contained" color="secondary" onClick={onClose}>
                      Cancel
                    </Button>
                  </Box>
  
                  <Button variant="contained" color="primary" onClick={() => {
                      
                      if(formData){
                        let contFlag  = true
                        for(var key in formData) {
                          if(typeof formData[key] ==  "string"  && !(key.includes("path")|| key.includes("Path"))){
                            if(formData[key].length==0){
                              enqueueSnackbar(`${startCase(key)} cannot be empty`)
                              contFlag =false
                            }
                          }
                          if(typeof formData[key] ==  "number"){
                            if(formData[key] < 0){
                              enqueueSnackbar(`${startCase(key)} cannot be less than zero`)
                              contFlag = false
                            }
                          }
                        }
                        if(contFlag) {
                          saveData(formData)
                        }
                      }
                    }}
                  >
                    {startCase(mode)}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Modal>
    );
  }
  
  interface idData {
    courseBundleId?: string,
    subjectId?: string,
    chapterId?: string,
    topicId?: string,
    contentId?: string,
    questionId?: string
  }
  
  export interface ApiRequest {
    subject:"courseBundle" | "subject" |"chapter" |"topic" |"content" | "question",
    type: "create" | "update" | "delete",
    timestamp :  number;
    data : CourseBundle | Subject | Chapter | Topic | Content | QuestionData,
    idData : idData,
    uuid : string
  }
  
  const UploadContentFile: FunctionComponent<Props> = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false)
    const [duplicateErrorShow,setDuplicateErrorShow]= useState<boolean>(false)
    const [uploadedFiles, setUploadedFiles ] = useState<string[]>([])
    const [courseBundleData , setCourseBundleData]= useState<CourseBundle|undefined>(undefined)
    const [requestStack, setRequestStack]= useState<ApiRequest[]>([])
    const [chunk, setChunksize] = useState(1048576);
    const [progress,setProgress] = useState(0)
    const [courseBundles,setCourseBundles] = useState<publicCourse[]>([])
    const [selectedIndex, setSelectedIndex] = useState<number>(-1)
    const [subjects,setSubjects] = useState<APISubject[]>([])
    const [selectedSubject,setSelectedSubject] = useState<number>(-1)
    const classes = useStyles();

    
  const [reportMode,setReportMode] = useState<boolean>(false)
  const [reportData,setReportData] = useState<DataApiResponse>({status:[],obj :[]})
  const [rows,setRows] = useState<DataTableRow[]>([])
  const [chapters,setChapters] = useState<string[]>([])
  const [currentChapterIndex, setCurrentChapterIndex] = useState<number>(-1)


  useEffect(()=>{
    console.log(reportMode && selectedIndex>-1 && selectedSubject>-1)
    if(reportMode && selectedIndex>-1 && selectedSubject>-1) {
      getreportDataAPI()
    }
  },[reportMode, selectedSubject])

  useEffect(()=>{
    if(currentChapterIndex>-1){
      
    dataTableData()
    }
  },[reportData,currentChapterIndex])

  const getreportDataAPI = async () =>{
    let reportDataApi = await getReportData('EDUMATICA',subjects[selectedSubject]._id,subjects[selectedSubject].batches[0]._id)
    setReportData(reportDataApi)
    setChapters(reportDataApi.obj.map(el=>el.chaptername))
    setCurrentChapterIndex(-1)
  }

  interface DataTableRow {
    studentName:string,
    quizCompleted:  string,
    videoCompleted : string,
    assignmentCompleted : string,
    recordingCompleted: string,
    pdfCompleted : string,
    imageCompleted : string
  }

  const dataTableData = ()=>{
    
    let moduleElements = reportData.obj.find(el=>el.chaptername == chapters[currentChapterIndex])?.moduleElements
    let DataTableRows : DataTableRow[] = reportData.status.map(el=>el.studentData).map((stu, index)=>{
      return {
        studentName : stu.studentName,
        videoCompleted  : `${reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Video" && el.completionStatus=="completed").length.toString()} / ${reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Video").length.toString()}${(reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Video").length>0? ('('+(reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Video" && el.completionStatus=="completed").length/reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Video").length) *100).toString() + '%)' : '-') }`,
        quizCompleted  : `${reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Quiz" && el.completionStatus=="completed").length.toString()} / ${reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Quiz").length.toString()}${(reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Quiz").length>0? ('('+(reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Quiz" && el.completionStatus=="completed").length/reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Quiz").length) *100).toString() + '%)' : '-') }`,
        pdfCompleted  : `${reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="PDF" && el.completionStatus=="completed").length.toString()} / ${reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="PDF").length.toString()}${(reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="PDF").length>0? ('('+(reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="PDF" && el.completionStatus=="completed").length/reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="PDF").length) *100).toString() + '%)' : '-') }`,
        recordingCompleted  : `${reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Recording" && el.completionStatus=="completed").length.toString()} / ${reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Recording").length.toString()}${(reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Recording").length>0? ('('+(reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Recording" && el.completionStatus=="completed").length/reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Recording").length) *100).toString() + '%)' : '-') }`,
        assignmentCompleted  : `${reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Assignment" && el.completionStatus=="completed").length.toString()} / ${reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Assignment").length.toString()}${(reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Assignment").length>0? ('('+(reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Assignment" && el.completionStatus=="completed").length/reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Assignment").length) *100).toString() + '%)' : '-') }`,
        imageCompleted  : `${reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Image" && el.completionStatus=="completed").length.toString()} / ${reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Image").length.toString()}${(reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Image").length>0? ('('+(reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Image" && el.completionStatus=="completed").length/reportData.status[index].trackingData.filter(el=>moduleElements?.find(ele=>ele._id == el.moduleId)?.type=="Image").length) *100).toString() + '%)' : '-') }`
      }
    })

    
    // let DataTableRows : DataTableRow[] = reportData.obj.map(chap=>{
      
    //   return chap.moduleElements.map((modEl)=>{
    //     return {
    //       name: modEl.title,
    //       type:modEl.type,
    //       optional : modEl.optional,
    //       desc :modEl.subtitle,
    //       chaptername: chap.chaptername,
    //       studentCompleted : reportData.status.filter(el=>{
    //         let trackedFileIndex = el.trackingData.findIndex(ele=>ele.moduleId)
    //         if(trackedFileIndex>-1){
    //           return el.trackingData[trackedFileIndex].completionStatus=="completed"
    //         }
    //         else{
    //           return false
    //         }
    //       }).map(el=>el.studentData.studentName),
    //       studentNotCompleted :reportData.status.filter(el=>{
    //         let trackedFileIndex = el.trackingData.findIndex(ele=>ele.moduleId)
    //         if(trackedFileIndex>-1){
    //           return el.trackingData[trackedFileIndex].completionStatus!="completed"
    //         }
    //         else{
    //           return true
    //         }
    //       }).map(el=>el.studentData.studentName),
    //     }
    //   })
    // }).flat()

    setRows(DataTableRows)


  }

  const gridRows = rows.map((row, index) => {
    return ({
      id: (index + 1),
      studentName: row.studentName,
      videoCompleted: row.videoCompleted == '0 / 0-' ? '-' :row.videoCompleted,      
      imageCompleted: row.imageCompleted  == '0 / 0-' ? '-' :row.imageCompleted,
      quizCompleted:row.quizCompleted  == '0 / 0-' ? '-' :row.quizCompleted,
      pdfCompleted : row.pdfCompleted  == '0 / 0-' ? '-' :row.pdfCompleted
    })
  })

  const gridColumns: GridColDef[] = [
    { field: 'id', headerName: 'Sl No.', flex: 0.7 },
    { field: 'studentName', headerName: 'Student Name', flex: 1, renderCell: datagridCellExpand },
    { field: 'videoCompleted', headerName: 'Videos', flex: 1.2, renderCell: datagridCellExpand },
    { field: 'imageCompleted', headerName: 'Images', flex: 1, renderCell: datagridCellExpand },    
    { field: 'quizCompleted', headerName: 'Quizzes', flex: 0.7, renderCell: datagridCellExpand },
    { field: 'pdfCompleted', headerName: 'PDFs', flex: 0.7, renderCell: datagridCellExpand },
    // { field : 'studentCompleted' , headerName: 'Completed' ,flex : 1 , renderCell : ((params: GridCellParams)=>{
    //   let studentCom = params.getValue("studentCompleted") as string[]
    //   if(studentCom){
    //     return<Tooltip title={studentCom.join(",")}>
    //       <Typography variant="caption" >{studentCom.length}</Typography>
    //     </Tooltip> 
  
    //     }
    //     else{
    //       return <Typography variant="caption" >0</Typography>
    //     }
    // })},
    // { field : 'studentNotCompleted' , headerName: 'Not Completed' ,flex : 1 , renderCell : ((params: GridCellParams)=>{
    //   let studentCom = params.getValue("studentNotCompleted") as string[]
    //   console.log(studentCom)
    //   if(studentCom){
    //   return<Tooltip title={studentCom.join(",")}>
    //     <Typography variant="caption" >{studentCom.length}</Typography>
    //   </Tooltip> 

    //   }
    //   else{
    //     return <Typography variant="caption" >0</Typography>
    //   }
    // })}
  ]


  
    React.useEffect(()=>{
      getData()
    },[])
  
    const [modalMode, setModalMode] = useState<"add" | "update">("add")
    const [modalOpen ,setModalOpen] = useState<boolean>(false)
    const [modalData, setModalData] = useState<CourseBundle|Subject|Chapter|Topic|Content|QuestionData|undefined>(undefined)
    const [modalSubject , setModalSubject] = useState<"courseBundle" | "subject" |"chapter" |"topic" |"content" | "question">("courseBundle")
    const [idData, setIdData] = useState<idData>({
      courseBundleId :'', subjectId: '', chapterId :'', topicId: '', contentId: '', questionId :''
    })
    const [uuid,setUuid] = useState<string>('')
    // const [courseBundleModalOpen, setCourseBundleModalOpen] = useState<boolean>(false)
    // const [subjectModalOpen, setSubjectModalOpen] = useState<boolean>(false)
    // const [chapterModalOpen, setChapterModalOpen] = useState<boolean>(false)
    // const [topicModalOpen, setTopicModalOpen] = useState<boolean>(false)
    // const [contentModalOpen, setContentModalOpen] = useState<boolean>(false)
    // const [questionModalOpen, setQuestionModalOpen] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [deleteSubjectId,setDeleteSubjectId] = useState<"courseBundle" | "subject" | "topic" | "chapter" | "content" | "question">("courseBundle")
    const [deleteSubjectName, setDeleteSubjectName] = useState<string>("")
    const [deleteModalMongoId , setDeleteModalMongoId] = useState<string>("")
    const [stenrolled, setStenrolled] = useState(false)
  
    const getData =async () =>{
      const courseBundlesData = await getCourseBundles()

      let filteredEdumaticaCourses:any=courseBundlesData?.filter((el:any)=>el?.visibleOrgs?.includes("EDUMATICA"))
      console.log('filteredEdumaticaCourses:', filteredEdumaticaCourses)

      setCourseBundles(filteredEdumaticaCourses)
    }
  

  
    const getCourseBundleData = async () =>{
      if(selectedIndex>-1){
        const data = await getManifest(courseBundles[selectedIndex]._id)
        const enrolled = await isStudentEnrolled(courseBundles[selectedIndex]._id)
        setStenrolled(enrolled)
        setCourseBundleData(data)
        setUuid(data.uuid)
        setSubjects(courseBundles[selectedIndex].includedCourses)
      }

    }
  
  
    const cleanupCourseBundle =()=>{
      setCourseBundleData(undefined)
      setUuid('')
      setSubjects([])
      setSelectedSubject(-1)
    }
  
    
  
    const submitForm = () => {
      // var formData = new FormData();
      if (droppedFiles.length === 0) {
        enqueueSnackbar('Cannot make request without excel file', {
          variant: 'error'
        });
        return;
      }
      if(droppedFiles.length > 0){
        let file = droppedFiles[0] 
        var r = new Resumable({
        target: `${process.env.REACT_APP_API}upload/uploadContentFile`,
        testChunks: false,
        chunkSize: chunk,
        simultaneousUploads: 3,
        query: { upload_token: file["name"] },
        });
       r.addFile(file);
       r.on("fileAdded", function (file, event) {
         r.upload();
       });
       r.on("fileSuccess", function (file:File, message:string) {
        verifyJsonAndStatus(droppedFiles[0].name)
       });
       r.on("fileError", function (file, message) {
        console.log("error Uploading the file");
       });
       r.on("fileProgress", function (file:File, message:string) {
       let progress = r.progress();
        setProgress(progress*100)
       });
      }
      
  
      // if (!bypassImage) {
      //   if (imageFile === undefined) {
      //     enqueueSnackbar('No Image File is attached. Make request anyway?', {
      //       variant: 'warning',
      //       action: SnackbarAction
      //     });
      //     return;
      //   }
      // }
  
      // if (!bypassImage) {
      //   formData.append('files', imageFile as File);
      // }
      // formData.append('files', excelFile as File);
      // performDataUpdate(formData);
      // setSubmitDisabled(true)
      setDuplicateErrorShow(false)
    };
  
    const verifyJsonAndStatus = (fileName:string) =>{
      axios.post((process.env.REACT_APP_API as string)+'/upload/makeContentDataUpdate',{filename:fileName})
      .then((response)=>{
        console.log(response)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  
  
    const dataIdDetails = (subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      console.log(subIndex,chapIndex,topIndex, conIndex, quesIndex)
      let courseBundleId, subjectId, chapterId, topicId, contentId, questionId
  
      if(courseBundleData){
        courseBundleId = courseBundleData._id
        
      if(subIndex !== undefined){
        console.log(courseBundleData.subjects[subIndex])
        subjectId = courseBundleData.subjects[subIndex]._id
      }
  
      if(subIndex!== undefined && chapIndex!== undefined){
        chapterId = sortBy(courseBundleData.subjects[subIndex].chaptersData, ['orderNo'])[chapIndex]._id
      }
      if(subIndex!== undefined && chapIndex!== undefined && topIndex!== undefined){
        topicId = sortBy(courseBundleData.subjects[subIndex].chaptersData, ['orderNo'])[chapIndex].topics[topIndex]._id
      }
      if(subIndex!== undefined && chapIndex!== undefined && topIndex!== undefined && conIndex!== undefined){
        contentId = sortBy(courseBundleData.subjects[subIndex].chaptersData, ['orderNo'])[chapIndex].topics[topIndex].contents[conIndex]._id
      }
      if(subIndex!== undefined && chapIndex!== undefined && topIndex!== undefined && conIndex!== undefined && quesIndex!== undefined){
        questionId = sortBy(courseBundleData.subjects[subIndex].chaptersData, ['orderNo'])[chapIndex].topics[topIndex].contents[conIndex].questionData?.[quesIndex]._id
      }
    }
    console.log({
      courseBundleId, subjectId, chapterId, topicId, contentId, questionId
    })
    return {
      courseBundleId, subjectId, chapterId, topicId, contentId, questionId
    }
    }
  
    const submitApi = async () =>{
      try{
        await apiDataProcess(requestStack)
        setRequestStack([])
        getCourseBundleData()
        
      }
      catch(err){
        console.log(err)
      }
    }
  
    const handleDataChange =(saveData : CourseBundle | Subject | Chapter | Topic | Content | QuestionData) =>{
      let uploadPromises
      if(modalSubject=="question"){
        uploadPromises = []
      }
      else{

        uploadPromises = Object.keys(saveData).filter(key=>key.includes("path")|| key.includes("Path")).filter(key=>{return (typeof saveData[key])!="string"}).map((key)=>{
          
          return new Promise(async (resolve, reject)=>{
            try{
              let file : File = saveData[key]
              
              if(modalSubject!="content"){
                if(file.type!=='application/pdf'){
                  enqueueSnackbar(`Only Pdf upload allowed for ${startCase(key)}`)
                  return reject("PDF")
                }
              }
                let uploadType = modalSubject == "subject" ? "Syllabus" :"CourseContent"
                let data = await getUploadUrlApi(uploadType,file.name,uuid,modalSubject=="content"?file.type: undefined)
                await uploadFileOnUrl(data.dataUrl, saveData[key] )
                saveData[key] = data.timestamp + '/' + file.name
                resolve( '/' + uploadType + '/' + uuid + '/' + data.timestamp + '/' + file.name)
            }
            catch(err){
              reject(err)
            }      
          })
        })
      }
  
      console.log(idData)
      if(modalMode=="add"){
        switch (modalSubject) {
          case 'courseBundle':
            let newCourseBundle = saveData
            setCourseBundleData(newCourseBundle  as CourseBundle)
            break ; 
          case 'subject' : 
            setCourseBundleData((prev)=>{
              return {
                ...prev,
                subjects : [...prev?.subjects as Subject[], saveData]
              } as CourseBundle
            })
            break;
          case 'chapter' : {
            setCourseBundleData((prev)=>{
              return {
                ...prev,
                subjects :  prev?.subjects.map((sub)=>{
                  if(sub._id== idData.subjectId){
                    saveData.orderNo = Number(saveData.orderNo)
                    return {
                      ...sub, 
                      chaptersData : [...sub.chaptersData, saveData]
                    } as Subject
                  }
                  else{ 
                    return sub
                  }
                })
              } as CourseBundle 
            })
          }
          break;
          case 'topic':  {
            setCourseBundleData((prev)=>{
              return {
                ...prev,
                subjects :  prev?.subjects.map((sub)=>{
                  if(sub._id== idData.subjectId){
                    return {
                      ...sub, 
                      chaptersData : sub.chaptersData.map((chap)=>{
                        if(chap._id==idData.chapterId){
                          return {
                            ...chap, 
                            topics : [...chap.topics, saveData]
                          }
                        }
                        else{
                          return chap
                        }
                      })
                    } as Subject
                  }
                  else{
                    return sub
                  } 
                })
              }  as CourseBundle
            })
          }
          break;
          case 'content' : {
            setCourseBundleData((prev)=>{
              return {
                ...prev,
                subjects :  prev?.subjects.map((sub)=>{
                  if(sub._id== idData.subjectId){
                    return {
                      ...sub, 
                      chaptersData : sub.chaptersData.map((chap)=>{
                        if(chap._id==idData.chapterId){
                          return {
                            ...chap, 
                            topics : chap.topics.map((top)=>{
                              if(top._id == idData.topicId){
                                return {
                                  ...top , 
                                  contents :[...top.contents, saveData]
                                }
                              }
                              else{
                                return top
                              }
                            })
                          }
                        }
                        else{
                          return chap
                        }
                      })
                    } as Subject
                  }
                  else{
                    return sub
                  }
                })
              }   as CourseBundle
            })
          }
  
          break;
          case 'question' : {
            setCourseBundleData((prev)=>{
              return {
                ...prev,
                subjects :  prev?.subjects.map((sub)=>{
                  if(sub._id== idData.subjectId){
                    return {
                      ...sub, 
                      chaptersData : sub.chaptersData.map((chap)=>{
                        if(chap._id==idData.chapterId){
                          return {
                            ...chap, 
                            topics : chap.topics.map((top)=>{
                              if(top._id == idData.topicId){
                                return {
                                  ...top , 
                                  contents : top.contents.map((con)=>{
                                    if(con._id==idData.contentId){
                                      return {
                                        ...con, 
                                        questionData : [...con.questionData as QuestionData[], saveData]
                                      }
                                      
                                    }
                                    else{
                                      return con
                                    }
                                  }) 
                                }
                              }
                              else{
                                return top
                              }
                            })
                          }
                        }
                        else{
                          return chap
                        }
                      })
                    } as Subject
                  }
                  else{
                    return sub
                  }
                })
              }  as CourseBundle
            })
          }
            break;
        
          default:
            break;
        }
      }
      if(modalMode=="update"){
        switch (modalSubject) {
          case 'courseBundle':
            setCourseBundleData((prev)=>{
              return {
                ...prev,
                ...saveData
              } as CourseBundle
            })
          case 'subject' : 
            setCourseBundleData((prev)=>{
              return {
                ...prev,
                subjects : prev?.subjects.map((sub)=>{
                  if(sub._id== idData.subjectId){
                    return {
                      ...sub, 
                      ...saveData
                    } as Subject
                  }
                  else{
                    return sub
                  }
                })
              } as CourseBundle
            })
            break;
          case 'chapter' : {
            setCourseBundleData((prev)=>{
              return {
                ...prev,
                subjects :  prev?.subjects.map((sub)=>{
                  if(sub._id== idData.subjectId){
                    return {
                      ...sub, 
                      chaptersData : sub.chaptersData.map((chap)=>{
                        if(chap._id==idData.chapterId){
                          return {
                            ...chap, 
                            ...saveData
                          }
                        }
                        else{
                          return chap
                        }
                      })
                    } as Subject
                  }
                  else{
                    return sub
                  }
                })
              } as CourseBundle 
            })
          }
          break;
          case 'topic':  {
            setCourseBundleData((prev)=>{
              return {
                ...prev,
                subjects :  prev?.subjects.map((sub)=>{
                  if(sub._id== idData.subjectId){
                    return {
                      ...sub, 
                      chaptersData : sub.chaptersData.map((chap)=>{
                        if(chap._id==idData.chapterId){
                          return {
                            ...chap, 
                            topics : chap.topics.map((top)=>{
                              if(top._id == idData.topicId){
                                return {
                                  ...top , 
                                  ...saveData
                                }
                              }
                              else{
                                return top
                              }
                            })
                          }
                        }
                        else{
                          return chap
                        }
                      })
                    } as Subject
                  }
                  else{
                    return sub
                  } 
                })
              }  as CourseBundle
            })
          }
          break;
          case 'content' : {
            setCourseBundleData((prev)=>{
              return {
                ...prev,
                subjects :  prev?.subjects.map((sub)=>{
                  if(sub._id== idData.subjectId){
                    return {
                      ...sub, 
                      chaptersData : sub.chaptersData.map((chap)=>{
                        if(chap._id==idData.chapterId){
                          return {
                            ...chap, 
                            topics : chap.topics.map((top)=>{
                              if(top._id == idData.topicId){
                                return {
                                  ...top , 
                                  contents :top.contents.map((con)=>{
                                    if(con._id==idData.contentId){
                                      return {
                                        ...con, 
                                        ...saveData
                                      }
                                      
                                    }
                                    else{
                                      return con
                                    }
                                  })
                                }
                              }
                              else{
                                return top
                              }
                            })
                          }
                        }
                        else{
                          return chap
                        }
                      })
                    } as Subject
                  }
                  else{
                    return sub
                  }
                })
              }   as CourseBundle
            })
          }
  
          break;
          case 'question' : {
            setCourseBundleData((prev)=>{
              return {
                ...prev,
                subjects :  prev?.subjects.map((sub)=>{
                  if(sub._id== idData.subjectId){
                    return {
                      ...sub, 
                      chaptersData : sub.chaptersData.map((chap)=>{
                        if(chap._id==idData.chapterId){
                          return {
                            ...chap, 
                            topics : chap.topics.map((top)=>{
                              if(top._id == idData.topicId){
                                return {
                                  ...top , 
                                  contents :top.contents.map((con)=>{
                                    if(con._id==idData.contentId){
                                      return {
                                        ...con, 
                                        questionData : con.questionData?.map((ques)=>{
                                          if(ques._id==idData){
                                            return {
                                              ...ques,
                                              ...saveData
                                            }
                                          }
                                          else{
                                            return ques
                                          }
                                        })
                                      }
                                      
                                    }
                                    else{
                                      return con
                                    }
                                  })
                                }
                              }
                              else{
                                return top
                              }
                            })
                          }
                        }
                        else{
                          return chap
                        }
                      })
                    } as Subject
                  }
                  else{
                    return sub
                  }
                })
              }  as CourseBundle
            })
          }
            break;
        
          default:
            break;
        }
      }
  
      
      if(uploadPromises.length>0){
        Promise.all(uploadPromises)
        .then((promResults)=>{
          let newApiRequest :ApiRequest ={
            type: modalMode == "add" ? "create" :"update",
            subject : modalSubject,
            timestamp : Date.now(),
            data : saveData,
            idData : idData,
            uuid : uuid
          }
          setRequestStack((prev)=>{
            return [...prev, newApiRequest]
          })
  
          handleCloseModal()
        })
        .catch((err)=>{
          enqueueSnackbar("Request Could not be pushed to stack", {variant:"warning"})
          console.log(err)
          handleCloseModal()
        })
  
      }
      else{
        let newApiRequest :ApiRequest ={
          type: modalMode == "add" ? "create" :"update",
          subject : modalSubject,
          timestamp : Date.now(),
          data : saveData,
          idData : idData,
            uuid : uuid
        }
        setRequestStack((prev)=>{
          return [...prev, newApiRequest]
        })
        handleCloseModal()
      }
  
    }
  
    const addCourseBundle =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      console.log(subIndex,chapIndex,topIndex, conIndex, quesIndex)
      let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
      setIdData(idData)
      setModalMode("add")
      setModalSubject("courseBundle")
      setModalOpen(true)
    }
  
    const addSubject =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
      setIdData(idData)
      setModalMode("add")
      setModalSubject("subject")
      setModalOpen(true)
    }
  
    const editCourseBundle =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
      setIdData(idData)
      setModalMode("update")
      setModalSubject("courseBundle")
      if(courseBundleData){
        setModalData(courseBundleData)
      }
      setModalOpen(true)
    }
  
  
    const handleDeleteAction = (mongoId : string) =>{
      let newApiRequest ={
        subject: deleteSubjectId,
        type: "delete",
        timestamp :  Date.now(),
        data : {_id: mongoId},
        idData : idData,
        uuid : uuid
      } as ApiRequest
      setRequestStack((prev)=>{
        return [...prev, newApiRequest]
      })
      
      switch (deleteSubjectId) {
        case 'courseBundle':
          setCourseBundleData(undefined)
          setUuid('')
        case 'subject' : 
          setCourseBundleData((prev)=>{
            return {
              ...prev,
              subjects : prev?.subjects.filter(el=>el._id!=idData.subjectId)
            } as CourseBundle
          })
          break;
        case 'chapter' : {
          setCourseBundleData((prev)=>{
            return {
              ...prev,
              subjects :  prev?.subjects.map((sub)=>{
                if(sub._id== idData.subjectId){
                  return {
                    ...sub, 
                    chaptersData : sub.chaptersData.filter(el=>el._id!=idData.chapterId)
                  } as Subject
                }
                else{
                  return sub
                }
              })
            } as CourseBundle 
          })
        }
        break;
        case 'topic':  {
          setCourseBundleData((prev)=>{
            return {
              ...prev,
              subjects :  prev?.subjects.map((sub)=>{
                if(sub._id== idData.subjectId){
                  return {
                    ...sub, 
                    chaptersData : sub.chaptersData.map((chap)=>{
                      if(chap._id==idData.chapterId){
                        return {
                          ...chap, 
                          topics : chap.topics.filter(el=>el._id!=idData.topicId)
                        }
                      }
                      else{
                        return chap
                      }
                    })
                  } as Subject
                }
                else{
                  return sub
                } 
              })
            }  as CourseBundle
          })
        }
        break;
        case 'content' : {
          setCourseBundleData((prev)=>{
            return {
              ...prev,
              subjects :  prev?.subjects.map((sub)=>{
                if(sub._id== idData.subjectId){
                  return {
                    ...sub, 
                    chaptersData : sub.chaptersData.map((chap)=>{
                      if(chap._id==idData.chapterId){
                        return {
                          ...chap, 
                          topics : chap.topics.map((top)=>{
                            if(top._id == idData.topicId){
                              return {
                                ...top , 
                                contents : top.contents.filter(el=>el._id!=idData.contentId)
                              }
                            }
                            else{
                              return top
                            }
                          })
                        }
                      }
                      else{
                        return chap
                      }
                    })
                  } as Subject
                }
                else{
                  return sub
                }
              })
            }   as CourseBundle
          })
        }
  
        break;
        case 'question' : {
          setCourseBundleData((prev)=>{
            return {
              ...prev,
              subjects :  prev?.subjects.map((sub)=>{
                if(sub._id== idData.subjectId){
                  return {
                    ...sub, 
                    chaptersData : sub.chaptersData.map((chap)=>{
                      if(chap._id==idData.chapterId){
                        return {
                          ...chap, 
                          topics : chap.topics.map((top)=>{
                            if(top._id == idData.topicId){
                              return {
                                ...top , 
                                contents : top.contents.map((con)=>{
                                  if(con._id==idData.contentId){
                                    return {
                                      ...con, 
                                      questionData : con.questionData?.filter(el=>el._id!=idData.questionId)
                                    }
                                    
                                  }
                                  else{
                                    return con
                                  }
                                }) 
                              }
                            }
                            else{
                              return top
                            }
                          })
                        }
                      }
                      else{
                        return chap
                      }
                    })
                  } as Subject
                }
                else{
                  return sub
                }
              })
            }  as CourseBundle
          })
        }
          break;
      
        default:
          break;
      }
  
  
      handleDeleteClose()
    }
    const deleteCourseBundle =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      // if(stenrolled) {
      //   enqueueSnackbar('Cannot Delete Course Bundle, since students have enrolled for this course', { variant: "warning" })
      // } else {
        let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
        setIdData(idData)
        setDeleteSubjectId("courseBundle")
        setDeleteModal(true)
        //@ts-ignore
        setDeleteModalMongoId(courseBundleData?._id)
        //@ts-ignore
        setDeleteSubjectName(courseBundleData.board + ' ' + courseBundleData.class)
      // }
    }
  
    const addChapter =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      // if(stenrolled) {
      //   enqueueSnackbar('Cannot Add chapter, since students have enrolled for this course', { variant: "warning" })
      // } else {
        let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
        setIdData(idData)
        setModalMode("add")
        setModalSubject("chapter")
        setModalOpen(true)
      // }
    }
    
    const editSubject =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      console.log(subIndex,chapIndex,topIndex, conIndex, quesIndex)
      let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
      console.log(idData)
      setIdData(idData)
      setModalMode("update")
      setModalSubject("subject")
      // console.log(subIndex)
      // console.log(courseBundleData?.subjects[subIndex as number])
      //@ts-ignore
        setModalData(courseBundleData?.subjects[subIndex])
      
      setModalOpen(true)
    }
  
    const deleteSubject =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      // if(stenrolled) {
      //   enqueueSnackbar('Cannot Delete subject, since students have enrolled for this course', { variant: "warning" })
      // } else {
        let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
        setIdData(idData)
        setDeleteSubjectId("subject")
        setDeleteModal(true)
        //@ts-ignore
        setDeleteModalMongoId(courseBundleData?.subjects[subIndex]._id)
        //@ts-ignore
        setDeleteSubjectName(courseBundleData?.subjects[subIndex].defaultContentName)
      // }
    }
  
    const handleCloseModal =()=>{
      setModalOpen(false)
      setModalData(undefined)
      setModalSubject("courseBundle")
      setModalMode("add")
      setIdData({
        courseBundleId :'', subjectId: '', chapterId :'', topicId: '', contentId: '', questionId :''
      })
    }
  
    const addTopic =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      // if(stenrolled) {
      //   enqueueSnackbar('Cannot Add topic, since students have enrolled for this course', { variant: "warning" })
      // } else {
        let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
        setIdData(idData)
        setModalMode("add")
        setModalSubject("topic")
        setModalOpen(true)
      // }
    }
  
    const handleDeleteClose = () =>{
      setDeleteModal(false)
      setDeleteSubjectName("")
      setDeleteSubjectId("question")
    }
  
    const editChapter =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
      setIdData(idData)
      setModalMode("update")
      setModalSubject("chapter")
      // if(chapIndex && subIndex && courseBundleData?.subjects[subIndex].chaptersData[chapIndex]){4
      //@ts-ignore
        setModalData(sortBy(courseBundleData?.subjects[subIndex].chaptersData,['orderNo'])[chapIndex])
      // }
      setModalOpen(true)
    }
  
    const deleteChapter =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      // if(stenrolled) {
      //   enqueueSnackbar('Cannot Delete chapter, since students have enrolled for this course', { variant: "warning" })
      // } else {
        let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
        setIdData(idData)
        setDeleteSubjectId("chapter")
        setDeleteModal(true)
        //@ts-ignore
        setDeleteModalMongoId(sortBy(courseBundleData?.subjects[subIndex].chaptersData, ['orderNo'])[chapIndex]._id)
        //@ts-ignore
        setDeleteSubjectName(sortBy(courseBundleData?.subjects[subIndex].chaptersData, ['orderNo'])[chapIndex].name)
      // }
      
    }
  
    const addContent =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      // if(stenrolled) {
      //   enqueueSnackbar('Cannot Add content, since students have enrolled for this course', { variant: "warning" })
      // } else {
        let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
        setIdData(idData)
        setModalMode("add")
        setModalSubject("content")
        setModalOpen(true)
      // }
    }
  
    const editTopic =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
      setIdData(idData)
      setModalMode("update")
      setModalSubject("topic")
      // if(topIndex && chapIndex && subIndex && courseBundleData?.subjects[subIndex].chaptersData[chapIndex].topics[topIndex]){
        //@ts-ignore
        setModalData(sortBy(courseBundleData?.subjects[subIndex].chaptersData, ['orderNo'])[chapIndex].topics[topIndex])
      // }
      setModalOpen(true)
    }
  
    const deleteTopic =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      // if(stenrolled) {
      //   enqueueSnackbar('Cannot Delete topic, since students have enrolled for this course', { variant: "warning" })
      // } else {
        let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
        setIdData(idData)
        setDeleteSubjectId("topic")
        setDeleteModal(true)
        //@ts-ignore
        setDeleteModalMongoId(sortBy(courseBundleData?.subjects[subIndex].chaptersData,['orderNo'])[chapIndex].topics[topIndex]._id)
        //@ts-ignore
        setDeleteSubjectName(sortBy(courseBundleData?.subjects[subIndex].chaptersData, ['orderNo'])[chapIndex].topics[topIndex].name)
      // }
    }
  
    const addQuestion =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      // if(stenrolled) {
      //   enqueueSnackbar('Cannot Add Question, since students have enrolled for this course', { variant: "warning" })
      // } else {
        let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
        setIdData(idData)
        setModalMode("add")
        setModalSubject("question")
        setModalOpen(true)
      // }
    }
  
    const editContent =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      // if(stenrolled) {
      //   enqueueSnackbar('Cannot Edit content, since students have enrolled for this course', { variant: "warning" })
      // } else {
        let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
        setIdData(idData)
        setModalMode("update")
        setModalSubject("content")
        // if(topIndex && conIndex && chapIndex && subIndex && courseBundleData?.subjects[subIndex].chaptersData[chapIndex].topics[topIndex].contents[conIndex]){
        //@ts-ignore
        setModalData(sortBy(courseBundleData?.subjects[subIndex].chaptersData, ['orderNo'])[chapIndex].topics[topIndex].contents[conIndex])
        // }
        setModalOpen(true)
      // }
    }
  
    const deleteContent =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      // if(stenrolled) {
      //   enqueueSnackbar('Cannot Delete content, since students have enrolled for this course', { variant: "warning" })
      // } else {
        let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
        setIdData(idData)
        setDeleteSubjectId("content")
        setDeleteModal(true)
        //@ts-ignore
        setDeleteModalMongoId(sortBy(courseBundleData?.subjects[subIndex].chaptersData,['orderNo'])[chapIndex].topics[topIndex].contents[conIndex]._id)
        //@ts-ignore
        setDeleteSubjectName(sortBy(courseBundleData?.subjects[subIndex].chaptersData,['orderNo'])[chapIndex].topics[topIndex].contents[conIndex].title)
      // }
    }
  
    const deleteQuestion =(subIndex?:number, chapIndex?:number, topIndex?:number, conIndex?:number, quesIndex?:number) =>{
      // if(stenrolled) {
      //   enqueueSnackbar('Cannot Delete question, since students have enrolled for this course', { variant: "warning" })
      // } else {
        let idData = dataIdDetails(subIndex,chapIndex,topIndex, conIndex, quesIndex)
        setIdData(idData)
        setDeleteSubjectId("question")
        setDeleteModal(true)
          //@ts-ignore
          setDeleteModalMongoId(sortBy(courseBundleData?.subjects[subIndex].chaptersData,['orderNo'])[chapIndex].topics[topIndex].contents[conIndex].questionData[quesIndex]._id)
      
        //@ts-ignore
        setDeleteSubjectName(truncate(sortBy(courseBundleData?.subjects[subIndex].chaptersData,['orderNo'])[chapIndex].topics[topIndex].contents[conIndex].questionData[quesIndex].questionText),{length:20})
      // }
    }

    const addingFile = (file: File[]) => {
      if(file.length > 0) {
        setDroppedFiles(file)
        enqueueSnackbar('File Imported Successfully', {variant: 'success'});
      }
    }
  
    return (
      <div>
        <MiniDrawer>
          <Container maxWidth="lg" style={{ padding: '30px 2.5%' }}>
            <Box style={{background: '#FFFFFF'}} padding="20px">
             <Typography className= {classes.header}>
              Create Course Bundle
             </Typography>
             <Typography style={{color: 'gray', marginTop: '10px'}}>
               Upload File
             </Typography>
             <Grid container spacing={2}>
               <Grid item xs={12}>
                 <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>
                   ZIP File
                 </Typography>
                 <Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>Support Format: ZIP</Typography>
                 <Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>Single File is accepted</Typography>
                 <FormControl style={{width: '100%'}} margin="normal">
                   <GridPreview 
                    files={droppedFiles}
                    onRemoveItem={(file, index) => setDroppedFiles([])}
                    setDroppedFiles={setDroppedFiles}
                  />
                  <Dropzone
                    acceptedFiles={[
                      'application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip'
                    ]}
                    showresp={['error', 'info']}
                    maxFileSize={10485760000} // 100 MB
                    files={droppedFiles as File[]}
                    onDrop={addingFile}
                    xtraClasses={{
                      root: classes.dropzoneRoot,
                      textContainer: classes.dropzoneTextContainerRoot,
                      text: classes.dropzoneText,
                      icon: classes.dropzoneIcon
                    }}
                    dropzoneText="Please upload content File - Zip only"
                  />
                </FormControl>
                {duplicateErrorShow && <p style={{color:"crimson"}}>Excel file with same name already uploaded</p>}
                <LinearProgressWithLabel value={progress} />
              </Grid>
              <Grid item xs={12}>
                <Button style={{float: 'right', padding: '5px 15px'}}
                  disableElevation
                  color="primary"
                  variant='contained'
                  onClick={() => {
                    submitForm();
                  }}
                  disabled={submitDisabled}
                >
                  Upload
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button style={{padding: '5px 15px'}}
                  disableElevation
                  color="primary"
                  variant='contained'
                  onClick={() => {
                    addCourseBundle()
                  }}
                  disabled={uuid && uuid.length>0}
                >
                  Add Course Details
                </Button>
                {/* <Button style={{padding: '5px 15px', marginLeft: '15px'}}
                  disableElevation
                  color="primary"
                  variant='contained'
                  onClick={() => {
                    
                  }}
                >
                  Reorder Courses
                </Button> */}
              </Grid>
              <Grid item xs={3}>
                <Select
                  value={selectedIndex}
                  displayEmpty
                  variant='outlined'
                  onChange={(
                    e: React.ChangeEvent<{ value: unknown }>
                  ) => {
                    setSelectedIndex(e.target.value as number)
                    setSelectedSubject(-1)
                  }}
                  style={{ backgroundColor: '#FFFFFF', width:'100%', height: '40px'}}
                >
                  <MenuItem disabled value={-1}>
                    Select Course Bundle
                  </MenuItem>
                  {courseBundles.map((el, index) => (
                    <MenuItem key={index} value={index}>
                      {el.board} {' - '} {el.class} {' Complete Set'}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    {(uuid && uuid.length>0) ?
                      <CheckCircleIcon style={{color: '4C8BF5', fontSize: fontOptions.size.large, marginTop: '2px'}} /> :
                      <CheckCircleOutlineIcon style={selectedIndex > -1 ? {color: '4C8BF5', cursor: 'pointer', fontSize: fontOptions.size.large, marginTop: '2px'} : {color: '4C8BF5', fontSize: fontOptions.size.large, marginTop: '2px'}} 
                        onClick={() => {
                          getCourseBundleData()
                        }
                      }/>
                    }
                  </Grid>
                  <Grid item xs={10}>
                    {(uuid && uuid.length>0) &&
                      <Button style={{padding: '5px 15px', marginLeft: '10px'}}
                        disableElevation
                        color="primary"
                        variant={reportMode ? 'contained' : 'outlined'}
                        onClick={() => {
                          setReportMode(false)
                          setSelectedSubject(-1)
                          setSelectedIndex(-1)
                          cleanupCourseBundle()
                        }}
                      >
                        Clear Selection
                      </Button>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                
              </Grid>
              <Grid item xs={3}>
                {(uuid && uuid.length>0) &&
                  <Button style={{padding: '5px 15px', float: 'right'}}
                    disableElevation
                    color="primary"
                    variant={reportMode ? 'contained' : 'outlined'}
                    onClick={() => {
                      setReportMode(prev=>!prev)
                      setSelectedSubject(-1)
                    }}
                  >
                    Report Switch
                  </Button>
                }
              </Grid>

              {(reportMode) &&
                <React.Fragment>
                  <Grid item xs={3}>
                    <Select
                      value={selectedSubject}
                      displayEmpty
                      variant='outlined'
                      onChange={(
                        e: React.ChangeEvent<{ value: unknown }>
                      ) => {
                        setSelectedSubject(e.target.value as number)
                        
                      }}
                      style={{ backgroundColor: '#FFFFFF', width:'100%', height: '40px'}}
                    >
                      <MenuItem disabled value={-1}>
                        Select Subject
                      </MenuItem>
                      {subjects.map((el, index) => (
                        <MenuItem key={index} value={index}>
                          {el.title} {' - '} {el.deliveryLang[0] ? el.deliveryLang[0] : "Unknown"} {' Complete Set'}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      value={currentChapterIndex}
                      displayEmpty
                      variant='outlined'
                      onChange={(
                        e: React.ChangeEvent<{ value: unknown }>
                      ) => setCurrentChapterIndex(e.target.value as number)}
                      style={{ backgroundColor: '#FFFFFF', width:'100%', height: '40px'}}
                    >
                      <MenuItem disabled value={-1}>
                        Select Chapter
                      </MenuItem>
                      {chapters.map((el, index) => (
                        <MenuItem key={index} value={index}>
                          {el}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </React.Fragment>
              }

              {(reportMode) &&
                <Grid item xs={12}>
                  <Datagrids gridColumns={gridColumns} gridRows={gridRows} />
                </Grid>  
              }

              <Grid item xs={12}>
                <TreeView
                  className={classes.root}
                  defaultExpanded={['3']}
                  defaultCollapseIcon={<ArrowDropDownIcon />}
                  defaultExpandIcon={<ArrowRightIcon />}
                  defaultEndIcon={<div style={{ width: 24 }} />}
                >
                  {(!reportMode) && courseBundleData && courseBundleData.subjects &&
                    <React.Fragment>
                      <StyledTreeItem 
                        nodeId="1"
                        level ={0} 
                        labelText={courseBundleData.board + ' - '+courseBundleData.class} 
                        labelData={`${courseBundleData.subjects.length.toString()} Subjects`}
                        labelIcon={LibraryBooksIcon}
                        addFunc={addSubject}
                        addFuncTip={"Add new Subject"}
                        editFunc={editCourseBundle}
                        editFuncTip="Edit Course Bundle"
                        deleteFunc={deleteCourseBundle}
                        deleteFuncTip="Delete Course Bundle"
                      >
                        {courseBundleData.subjects.map((sub,subIndex)=>{
                          return sub.chaptersData && 
                            <StyledTreeItem 
                              nodeId={(10+subIndex).toString()} 
                              level ={1} 
                              subIndex={subIndex}   
                              labelText={sub.defaultContentName + ' - ' + sub.deliveryLang } 
                              labelData={`${sub.chaptersData.length.toString()} Chapters`}
                              labelIcon={BookmarkIcon}
                              addFunc={addChapter}
                              addFuncTip={"Add new Chapter"}
                              editFunc={editSubject}
                              editFuncTip="Edit Subject"
                              deleteFunc={deleteSubject}
                              deleteFuncTip="Delete Subject"
                            >
                              {sortBy(sub.chaptersData, ['orderNo']).map((chap,chapIndex)=>
                                { return chap.topics && 
                                  <StyledTreeItem 
                                    level ={2} 
                                    nodeId={(100+subIndex*10+chapIndex).toString()} 
                                    labelText={chap.name}
                                    chapIndex={chapIndex}
                                    subIndex={subIndex}   
                                    labelData={`${chap.topics.length.toString()} Topics`}
                                    labelIcon={FolderIcon}
                                    addFunc={addTopic}
                                    addFuncTip={"Add new Topic"}
                                    editFunc={editChapter}
                                    editFuncTip="Edit Chapter"
                                    deleteFunc={deleteChapter}
                                    deleteFuncTip="Delete Chapter"
                                  >
                                    {chap.topics.map((top, topIndex)=>
                                      { return top.contents && 
                                        <StyledTreeItem 
                                          nodeId={(1000+subIndex*100+chapIndex*10 + topIndex).toString()} 
                                          labelText={top.name} 
                                          level ={3} 
                                          topIndex={topIndex}
                                          chapIndex={chapIndex}
                                          subIndex={subIndex}  
                                          labelData={`${top.contents.filter(el=>el.type=="video").length.toString()} Videos ,${top.contents.filter(el=>el.type=="image").length.toString()} Images,${top.contents.filter(el=>el.type=="document").length.toString()} PDFs, ${top.contents.filter(el=>el.type=="quiz").length.toString()} Quiz`}
                                          labelIcon={FileCopyIcon}
                                          addFunc={addContent}
                                          addFuncTip={"Add new Content"}
                                          editFunc={editTopic}
                                          editFuncTip="Edit Topic"
                                          deleteFunc={deleteTopic}
                                          deleteFuncTip="Delete Topic"
                                        >
                                          {top.contents.map((con,conIndex)=>{
                                            return <StyledTreeItem 
                                            nodeId={(10000+subIndex*1000+chapIndex*100 + topIndex*10 + conIndex).toString()} 
                                            labelText={con.title} 
                                            level ={4} 
                                            conIndex={conIndex}
                                            topIndex={topIndex}
                                            chapIndex={chapIndex}
                                            subIndex={subIndex}  
                                            labelData={con.type == "quiz" ? `${con?.questionData?.length.toString()} Questions` : startCase(con.type)}
                                            labelIcon={DescriptionIcon}
                                            addFunc = {con.type == "quiz" ? addQuestion :  undefined}
                                            addFuncTip = {con.type=="quiz" ? "Add Question" : undefined}
                                            editFunc = {editContent}
                                            editFuncTip = {"Edit Content"}
                                            deleteFunc={deleteContent}
                                            deleteFuncTip="Delete Content"
                                            >
                                              {
                                                con.type=="quiz" && 
                                                  con?.questionData?.map((ques,quesIndex)=>{
                                                    return <StyledTreeItem 
                                                    nodeId={(100000+subIndex*10000+chapIndex*1000 + topIndex*100+conIndex*10+quesIndex).toString()} 
                                                    labelText={truncate(ques.questionText,{length:25})} 
                                                    level ={5} 
                                                    quesIndex ={quesIndex}
                                                    conIndex={conIndex}
                                                    topIndex={topIndex}
                                                    chapIndex={chapIndex}
                                                    subIndex={subIndex}
                                                    labelData={`${startCase(ques.type)} - ${ques.imagePaths} Images`}
                                                    labelIcon={HelpOutlineIcon}
                                                    deleteFunc={deleteQuestion}
                                                    deleteFuncTip="Delete Question"
                                                    />
                                                  })
                                                
                                              }
                                              </StyledTreeItem>
                                          })}
                                        </StyledTreeItem>
                                      })
                                    }
                                  </StyledTreeItem>
                                })
                              }
                            </StyledTreeItem>    
                          })
                        }
                      </StyledTreeItem>
                    </React.Fragment>
                  }
                </TreeView>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" >{`${requestStack.length} changes pending for update`}</Typography>
                <Button style={{padding: '5px 15px'}}
                  disableElevation
                  color="primary"
                  variant={reportMode ? 'contained' : 'outlined'}
                  onClick={() => {
                    submitApi()
                  }}
                  disabled={requestStack.length==0}
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Box>
          {
            modalOpen && 
            <UpdateModal 
              openModal={modalOpen}
              data={modalData}
              subject={modalSubject}
              mode={modalMode}
              onClose={handleCloseModal}
              saveData ={handleDataChange} 
            />
          }
          {
            deleteModal && 
            <DeleteModal 
              openModal ={deleteModal} 
              subject ={deleteSubjectId} 
              name ={deleteSubjectName}
              mongoId ={deleteModalMongoId}
              onClose={handleDeleteClose}
              handleDelete = {handleDeleteAction}
            />
          }
        </Container>
        </MiniDrawer>
      </div>
    );
  }
  
  
  
  export default (UploadContentFile);
  