


import React, { FunctionComponent, useState, useEffect } from 'react';
import { Badge, Box, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { fontOptions } from '../../../theme';
import Button from '../../common/components/form_elements/button';
import ReactPlayer from 'react-player';
import { URL_PATTERN } from '../../common/validations/patterns';
import { QuestionBankWithSelect } from '../contracts/qb_interface';
import { isEqual } from 'lodash';

//@ts-ignore
import { parse, HtmlGenerator } from 'latex.js'
import { capitalize, filter, includes, truncate } from 'lodash';
import MathEditor from './mathTextEditor/mathEditor';

const containsObject = (obj: any, list: any[]) => {
  return list.some(elem => isEqual(elem, obj))
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
    },
    addAssi: {
      '& button': {
        padding: '10px 30px 10px 30px',
      },
    },
    latex: {
      '& p': {
        // marginTop: '0px',
      },
    },
    root: {
      border: '1px solid #4C8BF5',
    },
    customBadge : {
      backgroundColor: "#80C12C",
      color: "white"
    }
  }),
);

interface FormData {
  qbName: string;
  description: string;
  boardName: string;
  className: string;
  subject: string;
  availStudent: boolean;
}

interface Props {
  basicData: FormData | undefined,
  structuredQB: QuestionBankWithSelect[],
  addComprehensiveQuestionToList: (ques: QuestionBankWithSelect[], sbQ?: QuestionBankWithSelect[] | undefined) => void
  setStructuredQB: React.Dispatch<React.SetStateAction<QuestionBankWithSelect[]>>
  qTBEStateCo: QuestionBankWithSelect[] | null
}

const ComprehensiveQuestion: FunctionComponent<Props> = ({basicData, structuredQB, addComprehensiveQuestionToList, qTBEStateCo, setStructuredQB}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { errors, setError, clearError } = useForm<FormData>();

  const [isapiLoad, setisapiLoad] = useState(false)
  const [question, setQuestion] = useState('')
  const [prevQuestion, setPrevQuestion] = useState('')
  const [allQuestion, setAllQuestion] = useState<QuestionBankWithSelect[]>([])
  const [reset, setReset] = useState(false)

  const latexParserCheck =  (latexString:String) =>{
    let generator = new HtmlGenerator({ hyphenate: false })
    let doc = parse(latexString, { generator: generator }).htmlDocument()
    return doc.documentElement.outerHTML
  }

  const ifValidLatex = (latexString:string) => {
    let result;
    try {
      const parser = latexParserCheck(latexString);
      result = true
    } catch (error) {
      result = false
    }

    return result
  }

  const latexParser =  (latexString:string) =>{
    let latexStg = latexString
    if(!ifValidLatex(latexStg)) {
      latexStg = '$' + latexStg + '$'
    }
    let generator = new HtmlGenerator({ hyphenate: false })
    let doc = parse(latexStg, { generator: generator }).htmlDocument()
    return doc.documentElement.outerHTML
  }

  useEffect(() => {
    if(basicData) {
      if(qTBEStateCo !== null) {
        setAllQuestion(qTBEStateCo)
        if(qTBEStateCo && qTBEStateCo[0] && qTBEStateCo[0].commonQuestionPart) {
          setPrevQuestion(qTBEStateCo[0].commonQuestionPart)
          setQuestion(qTBEStateCo[0].commonQuestionPart)
        }
        setReset(prev => !prev)
      } else {
        const nowQB = [...structuredQB]
  
        const oneQuestion:QuestionBankWithSelect = {
          srno: Number(nowQB.length + 1),
          questionBankFriendlyName: basicData.qbName,
          description: basicData.description,
          boardname: basicData.boardName,
          classname: basicData.className,
          subjectname: basicData.subject,
          chaptername: '',
          subTopic: '',
          type: 'single',
          complexity: 'easy',
          availableForMockTest: basicData.availStudent,
          commonQuestionPart: question,
          questionDescription: '',
          option1: undefined,
          option2: undefined,
          option3: undefined,
          option4: undefined,
          option5: undefined,
          option6: undefined,
          list1: [''],
          list2: [''],
          answer: [''],
          answerDescription: '',
          solnVideoRef: '',
          imageLinks: []
        }
  
        if(oneQuestion.type === 'single' || oneQuestion.type === 'multiple' || oneQuestion.type === 'match') {
          oneQuestion.option1 = 'Option1'
          oneQuestion.option2 = 'Option2'
          oneQuestion.answer = ['1']
        }
  
        if(oneQuestion.type === 'match') {
          oneQuestion.list1 = ["Option-A:", "Option-B:"]
          oneQuestion.list2 = ["Option-I:", "Option-II:"]
        }
  
        const nowQues = [...allQuestion]
        nowQues.push(oneQuestion)
        setAllQuestion(nowQues)
      }
    }
  },[qTBEStateCo])

  const changeComplexity = (ind: number, complex: 'easy' | 'medium' | 'high') => {
    const nowQues = [...allQuestion]
    nowQues[ind].complexity = complex
    setAllQuestion(nowQues)
  }

  const changeType = (ind: number, typ: 'multiple' | 'single' | 'match' | 'numeric') => {
    const nowQues = [...allQuestion]
    nowQues[ind] = {
      srno: nowQues[ind].srno,
      questionBankFriendlyName: nowQues[ind].questionBankFriendlyName,
      description: nowQues[ind].description,
      boardname: nowQues[ind].boardname,
      classname: nowQues[ind].classname,
      subjectname: nowQues[ind].subjectname,
      chaptername: '',
      subTopic: '',
      type: typ,
      complexity: nowQues[ind].complexity,
      availableForMockTest: nowQues[ind].availableForMockTest,
      commonQuestionPart: question,
      questionDescription: '',
      option1: undefined,
      option2: undefined,
      option3: undefined,
      option4: undefined,
      option5: undefined,
      option6: undefined,
      list1: [''],
      list2: [''],
      answer: [''],
      answerDescription: '',
      solnVideoRef: '',
      imageLinks: []
    }

    if(nowQues[ind].type === 'single' || nowQues[ind].type === 'multiple' || nowQues[ind].type === 'match') {
      nowQues[ind].option1 = 'Option1'
      nowQues[ind].option2 = 'Option2'
      nowQues[ind].answer = ['1']
    }

    if(nowQues[ind].type === 'match') {
      nowQues[ind].list1 = ["Option-A:", "Option-B:"]
      nowQues[ind].list2 = ["Option-I:", "Option-II:"]
    }

    setAllQuestion(nowQues)
    setReset(prev => !prev)
  }

  const changeProperty = (ind: number, property: 'questionDescription' | 'option1' | 'option2' | 'option3' | 'option4' | 'option5' | 'option6' | 'answerDescription' | 'solnVideoRef', value: string | undefined) => {
    const nowQues = [...allQuestion]
    if(property == 'questionDescription' || property === 'answerDescription') {
      nowQues[ind][property] = value as string
    } else {
      nowQues[ind][property] = value
    }
    setAllQuestion(nowQues)
  }

  const changeArrPty = (ind: number, property: 'list1' | 'list2' | 'answer', value: string[]) => {
    const nowQues = [...allQuestion]
    nowQues[ind][property] = value
    setAllQuestion(nowQues)
  }

  const clearAllVal = (ind: number) => {
    const nowQues = [...allQuestion]
    nowQues[ind] = {
      srno: nowQues[ind].srno,
      questionBankFriendlyName: nowQues[ind].questionBankFriendlyName,
      description: nowQues[ind].description,
      boardname: nowQues[ind].boardname,
      classname: nowQues[ind].classname,
      subjectname: nowQues[ind].subjectname,
      chaptername: '',
      subTopic: '',
      type: nowQues[ind].type,
      complexity: nowQues[ind].complexity,
      availableForMockTest: nowQues[ind].availableForMockTest,
      commonQuestionPart: question,
      questionDescription: '',
      option1: undefined,
      option2: undefined,
      option3: undefined,
      option4: undefined,
      option5: undefined,
      option6: undefined,
      list1: [''],
      list2: [''],
      answer: [''],
      answerDescription: '',
      solnVideoRef: '',
      imageLinks: []
    }

    if(nowQues[ind].type === 'single' || nowQues[ind].type === 'multiple' || nowQues[ind].type === 'match') {
      nowQues[ind].option1 = 'Option1'
      nowQues[ind].option2 = 'Option2'
      nowQues[ind].answer = ['1']
    }

    if(nowQues[ind].type === 'match') {
      nowQues[ind].list1 = ["Option-A:", "Option-B:"]
      nowQues[ind].list2 = ["Option-I:", "Option-II:"]
    }

    setAllQuestion(nowQues)
    setReset(prev => !prev)
  }

  const addNewQuesion = () => {
    if(basicData) {
      const nowQues = [...allQuestion]

      const oneQuestion:QuestionBankWithSelect = {
        srno: nowQues[nowQues.length - 1].srno + 1,
        questionBankFriendlyName: basicData.qbName,
        description: basicData.description,
        boardname: basicData.boardName,
        classname: basicData.className,
        subjectname: basicData.subject,
        chaptername: '',
        subTopic: '',
        type: 'single',
        complexity: 'easy',
        availableForMockTest: basicData.availStudent,
        commonQuestionPart: question,
        questionDescription: '',
        option1: undefined,
        option2: undefined,
        option3: undefined,
        option4: undefined,
        option5: undefined,
        option6: undefined,
        list1: [''],
        list2: [''],
        answer: [''],
        answerDescription: '',
        solnVideoRef: '',
        imageLinks: []
      }

      if(oneQuestion.type === 'single' || oneQuestion.type === 'multiple' || oneQuestion.type === 'match') {
        oneQuestion.option1 = 'Option1'
        oneQuestion.option2 = 'Option2'
        oneQuestion.answer = ['1']
      }

      if(oneQuestion.type === 'match') {
        oneQuestion.list1 = ["Option-A:", "Option-B:"]
        oneQuestion.list2 = ["Option-I:", "Option-II:"]
      }

      nowQues.push(oneQuestion)
      setAllQuestion(nowQues)
    }
  }

  const removeThisQues = (index: number) => {
    if(allQuestion.length === 1) {
      enqueueSnackbar('There should be atleast one Question in this Section', {variant: 'warning'});
    } else {
      const nowQues = [...allQuestion]
      nowQues.splice(index, 1)
      setAllQuestion(nowQues)
    }
  }

  const addQuestion = () => {
    const newQuests: QuestionBankWithSelect[] = []

    if(question.length === 0) {
      enqueueSnackbar('Please Add the Paragraph Question', {variant: 'warning'});
      return
    }

    if(!ifValidLatex(question)) {
      enqueueSnackbar('Paragraph Question is not Valid', {variant: 'warning'});
      return
    }

    for(let newQInd=0; newQInd<allQuestion.length; newQInd++) {
      const newQ = allQuestion[newQInd]

      if(newQ.type === 'single') {
        if(newQ.questionDescription.length === 0) {
          enqueueSnackbar('Please Add Question Description for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(!ifValidLatex(newQ.questionDescription)) {
          enqueueSnackbar('Question Description is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined).some(opt => (opt as string).length === 0)) {
          enqueueSnackbar('Please fill All Options for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(filter([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined), (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
          enqueueSnackbar('Please remove Duplicates in Option for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined).some(opt => !ifValidLatex(opt as string))) {
          enqueueSnackbar('Some option is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.answerDescription && newQ.answerDescription.length > 0 && !ifValidLatex(newQ.answerDescription)) {
          enqueueSnackbar('Solution is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.solnVideoRef && newQ.solnVideoRef.length !== 0 && !newQ.solnVideoRef.match(URL_PATTERN)) {
          enqueueSnackbar('Solution Link is Not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        let newQuest: QuestionBankWithSelect = {
          srno: newQ.srno,
          questionBankFriendlyName: newQ.questionBankFriendlyName,
          description: newQ.description,
          boardname: newQ.boardname,
          classname: newQ.classname,
          subjectname: newQ.subjectname,
          chaptername: '',
          subTopic: '',
          type: newQ.type,
          complexity: newQ.complexity,
          availableForMockTest: newQ.availableForMockTest,
          commonQuestionPart: !ifValidLatex(question) ? ('$' + question.replaceAll('<br>','\\\\') + '$') : question.replaceAll('<br>','\\\\'),
          questionDescription: !ifValidLatex(newQ.questionDescription) ? ('$' + newQ.questionDescription.replaceAll('<br>','\\\\') + '$') : newQ.questionDescription.replaceAll('<br>','\\\\'),
          answer: newQ.answer,
          answerDescription: (newQ.answerDescription && newQ.answerDescription.length > 0) ? (!ifValidLatex(newQ.answerDescription) ? ('$' + newQ.answerDescription.replaceAll('<br>','\\\\') + '$') : newQ.answerDescription.replaceAll('<br>','\\\\')) : '',
          solnVideoRef: (newQ.solnVideoRef && newQ.solnVideoRef.length > 0) ? newQ.solnVideoRef : undefined,
          imageLinks: newQ.imageLinks
        }

        if(newQ.option1 !== undefined) {
          newQuest = {...newQuest, ...{option1: !ifValidLatex(newQ.option1) ? ('$' + newQ.option1.replaceAll('<br>','\\\\') + '$') : newQ.option1.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option2 !== undefined) {
          newQuest = {...newQuest, ...{option2: !ifValidLatex(newQ.option2) ? ('$' + newQ.option2.replaceAll('<br>','\\\\') + '$') : newQ.option2.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option3 !== undefined) {
          newQuest = {...newQuest, ...{option3: !ifValidLatex(newQ.option3) ? ('$' + newQ.option3.replaceAll('<br>','\\\\') + '$') : newQ.option3.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option4 !== undefined) {
          newQuest = {...newQuest, ...{option4: !ifValidLatex(newQ.option4) ? ('$' + newQ.option4.replaceAll('<br>','\\\\') + '$') : newQ.option4.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option5 !== undefined) {
          newQuest = {...newQuest, ...{option5: !ifValidLatex(newQ.option5) ? ('$' + newQ.option5.replaceAll('<br>','\\\\') + '$') : newQ.option5.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option6 !== undefined) {
          newQuest = {...newQuest, ...{option6: !ifValidLatex(newQ.option6) ? ('$' + newQ.option6.replaceAll('<br>','\\\\') + '$') : newQ.option6.replaceAll('<br>','\\\\')}}
        }

        const checkQuest = {...newQuest}
        checkQuest.srno = 1;

        if(containsObject(checkQuest, newQuests.map(nq => {
          return {
            ...nq,
            ...{srno: 1}
          }
        }))) {
          enqueueSnackbar('Duplicate Question set found within this Comprehensive Question', {variant: 'warning'});
          return;
        } else {
          newQuests.push(newQuest)
        }
      } else if(newQ.type === 'multiple') {
        if(newQ.questionDescription.length === 0) {
          enqueueSnackbar('Please Add Question Description for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(!ifValidLatex(newQ.questionDescription)) {
          enqueueSnackbar('Question Description is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined).some(opt => (opt as string).length === 0)) {
          enqueueSnackbar('Please fill All Options for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(filter([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined), (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
          enqueueSnackbar('Please remove Duplicates in Option for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined).some(opt => !ifValidLatex(opt as string))) {
          enqueueSnackbar('Some option is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.answerDescription && newQ.answerDescription.length > 0 && !ifValidLatex(newQ.answerDescription)) {
          enqueueSnackbar('Solution is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.solnVideoRef && newQ.solnVideoRef.length !== 0 && !newQ.solnVideoRef.match(URL_PATTERN)) {
          enqueueSnackbar('Solution Link is Not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        let newQuest: QuestionBankWithSelect = {
          srno: newQ.srno,
          questionBankFriendlyName: newQ.questionBankFriendlyName,
          description: newQ.description,
          boardname: newQ.boardname,
          classname: newQ.classname,
          subjectname: newQ.subjectname,
          chaptername: '',
          subTopic: '',
          type: newQ.type,
          complexity: newQ.complexity,
          availableForMockTest: newQ.availableForMockTest,
          commonQuestionPart: !ifValidLatex(question) ? ('$' + question.replaceAll('<br>','\\\\') + '$') : question.replaceAll('<br>','\\\\'),
          questionDescription: !ifValidLatex(newQ.questionDescription) ? ('$' + newQ.questionDescription.replaceAll('<br>','\\\\') + '$') : newQ.questionDescription.replaceAll('<br>','\\\\'),
          answer: newQ.answer,
          answerDescription: (newQ.answerDescription && newQ.answerDescription.length > 0) ? (!ifValidLatex(newQ.answerDescription) ? ('$' + newQ.answerDescription.replaceAll('<br>','\\\\') + '$') : newQ.answerDescription.replaceAll('<br>','\\\\')) : '',
          solnVideoRef: (newQ.solnVideoRef && newQ.solnVideoRef.length > 0) ? newQ.solnVideoRef : undefined,
          imageLinks: newQ.imageLinks
        }

        if(newQ.option1 !== undefined) {
          newQuest = {...newQuest, ...{option1: !ifValidLatex(newQ.option1) ? ('$' + newQ.option1.replaceAll('<br>','\\\\') + '$') : newQ.option1.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option2 !== undefined) {
          newQuest = {...newQuest, ...{option2: !ifValidLatex(newQ.option2) ? ('$' + newQ.option2.replaceAll('<br>','\\\\') + '$') : newQ.option2.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option3 !== undefined) {
          newQuest = {...newQuest, ...{option3: !ifValidLatex(newQ.option3) ? ('$' + newQ.option3.replaceAll('<br>','\\\\') + '$') : newQ.option3.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option4 !== undefined) {
          newQuest = {...newQuest, ...{option4: !ifValidLatex(newQ.option4) ? ('$' + newQ.option4.replaceAll('<br>','\\\\') + '$') : newQ.option4.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option5 !== undefined) {
          newQuest = {...newQuest, ...{option5: !ifValidLatex(newQ.option5) ? ('$' + newQ.option5.replaceAll('<br>','\\\\') + '$') : newQ.option5.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option6 !== undefined) {
          newQuest = {...newQuest, ...{option6: !ifValidLatex(newQ.option6) ? ('$' + newQ.option6.replaceAll('<br>','\\\\') + '$') : newQ.option6.replaceAll('<br>','\\\\')}}
        }

        const checkQuest = {...newQuest}
        checkQuest.srno = 1;

        if(containsObject(checkQuest, newQuests.map(nq => {
          return {
            ...nq,
            ...{srno: 1}
          }
        }))) {
          enqueueSnackbar('Duplicate Question set found within this Comprehensive Question', {variant: 'warning'});
          return;
        } else {
          newQuests.push(newQuest)
        }
      } else if(newQ.type === 'match') {
        if(newQ.questionDescription.length === 0) {
          enqueueSnackbar('Please Add Question Description for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(!ifValidLatex(newQ.questionDescription)) {
          enqueueSnackbar('Question Description is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined).some(opt => (opt as string).length === 0)) {
          enqueueSnackbar('Please fill All Options for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(filter([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined), (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
          enqueueSnackbar('Please remove Duplicates in Option for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined).some(opt => !ifValidLatex(opt as string))) {
          enqueueSnackbar('Some option is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.answerDescription && newQ.answerDescription.length > 0 && !ifValidLatex(newQ.answerDescription)) {
          enqueueSnackbar('Solution is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.list1 && newQ.list1.filter(fil => fil !== undefined).some(opt => (opt as string).length === 0)) {
          enqueueSnackbar('Please fill All Boxes in Column 1 for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.list1 && filter(newQ.list1.filter(fil => fil !== undefined), (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
          enqueueSnackbar('Please remove Duplicates in Option, in Column 1 for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.list1 && newQ.list1.filter(fil => fil !== undefined).some(opt => !ifValidLatex(opt as string))) {
          enqueueSnackbar('Some option in Column 1 is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.list2 && newQ.list2.filter(fil => fil !== undefined).some(opt => (opt as string).length === 0)) {
          enqueueSnackbar('Please fill All Boxes in List2 for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.list2 && filter(newQ.list2.filter(fil => fil !== undefined), (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
          enqueueSnackbar('Please remove Duplicates in Option, in Column 2 for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.list2 && newQ.list2.filter(fil => fil !== undefined).some(opt => !ifValidLatex(opt as string))) {
          enqueueSnackbar('Some option in Column 2 is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.solnVideoRef && newQ.solnVideoRef.length !== 0 && !newQ.solnVideoRef.match(URL_PATTERN)) {
          enqueueSnackbar('Solution Link is Not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
        
        let newQuest: QuestionBankWithSelect = {
          srno: newQ.srno,
          questionBankFriendlyName: newQ.questionBankFriendlyName,
          description: newQ.description,
          boardname: newQ.boardname,
          classname: newQ.classname,
          subjectname: newQ.subjectname,
          chaptername: '',
          subTopic: '',
          type: newQ.type,
          complexity: newQ.complexity,
          list1: newQ.list1?.map(l1 => !ifValidLatex(l1) ? ('$' + l1.replaceAll('<br>','\\\\') + '$') : l1.replaceAll('<br>','\\\\')),
          list2: newQ.list2?.map(l2 => !ifValidLatex(l2) ? ('$' + l2.replaceAll('<br>','\\\\') + '$') : l2.replaceAll('<br>','\\\\')),
          availableForMockTest: newQ.availableForMockTest,
          commonQuestionPart: !ifValidLatex(question) ? ('$' + question.replaceAll('<br>','\\\\') + '$') : question.replaceAll('<br>','\\\\'),
          questionDescription: !ifValidLatex(newQ.questionDescription) ? ('$' + newQ.questionDescription.replaceAll('<br>','\\\\') + '$') : newQ.questionDescription.replaceAll('<br>','\\\\'),
          answer: newQ.answer,
          answerDescription: (newQ.answerDescription && newQ.answerDescription.length > 0) ? (!ifValidLatex(newQ.answerDescription) ? ('$' + newQ.answerDescription.replaceAll('<br>','\\\\') + '$') : newQ.answerDescription.replaceAll('<br>','\\\\')) : '',
          solnVideoRef: (newQ.solnVideoRef && newQ.solnVideoRef.length > 0) ? newQ.solnVideoRef : undefined,
          imageLinks: newQ.imageLinks
        }

        if(newQ.option1 !== undefined) {
          newQuest = {...newQuest, ...{option1: !ifValidLatex(newQ.option1) ? ('$' + newQ.option1.replaceAll('<br>','\\\\') + '$') : newQ.option1.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option2 !== undefined) {
          newQuest = {...newQuest, ...{option2: !ifValidLatex(newQ.option2) ? ('$' + newQ.option2.replaceAll('<br>','\\\\') + '$') : newQ.option2.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option3 !== undefined) {
          newQuest = {...newQuest, ...{option3: !ifValidLatex(newQ.option3) ? ('$' + newQ.option3.replaceAll('<br>','\\\\') + '$') : newQ.option3.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option4 !== undefined) {
          newQuest = {...newQuest, ...{option4: !ifValidLatex(newQ.option4) ? ('$' + newQ.option4.replaceAll('<br>','\\\\') + '$') : newQ.option4.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option5 !== undefined) {
          newQuest = {...newQuest, ...{option5: !ifValidLatex(newQ.option5) ? ('$' + newQ.option5.replaceAll('<br>','\\\\') + '$') : newQ.option5.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option6 !== undefined) {
          newQuest = {...newQuest, ...{option6: !ifValidLatex(newQ.option6) ? ('$' + newQ.option6.replaceAll('<br>','\\\\') + '$') : newQ.option6.replaceAll('<br>','\\\\')}}
        }

        const checkQuest = {...newQuest}
        checkQuest.srno = 1;

        if(containsObject(checkQuest, newQuests.map(nq => {
          return {
            ...nq,
            ...{srno: 1}
          }
        }))) {
          enqueueSnackbar('Duplicate Question set found within this Comprehensive Question', {variant: 'warning'});
          return;
        } else {
          newQuests.push(newQuest)
        }
      } else {
        if(newQ.questionDescription.length === 0) {
          enqueueSnackbar('Please Add Question Description for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(!ifValidLatex(newQ.questionDescription)) {
          enqueueSnackbar('Question Description is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.answerDescription && newQ.answerDescription.length > 0 && !ifValidLatex(newQ.answerDescription)) {
          enqueueSnackbar('Solution is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.solnVideoRef && newQ.solnVideoRef.length !== 0 && !newQ.solnVideoRef.match(URL_PATTERN)) {
          enqueueSnackbar('Solution Link is Not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.answer.length === 0) {
          enqueueSnackbar('Please Enter Answer for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(isNaN(Number(newQ.answer))) {
          enqueueSnackbar('Entered Answer is not a Number for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        let newQuest: QuestionBankWithSelect = {
          srno: newQ.srno,
          questionBankFriendlyName: newQ.questionBankFriendlyName,
          description: newQ.description,
          boardname: newQ.boardname,
          classname: newQ.classname,
          subjectname: newQ.subjectname,
          chaptername: '',
          subTopic: '',
          type: newQ.type,
          complexity: newQ.complexity,
          availableForMockTest: newQ.availableForMockTest,
          commonQuestionPart: !ifValidLatex(question) ? ('$' + question.replaceAll('<br>','\\\\') + '$') : question.replaceAll('<br>','\\\\'),
          questionDescription: !ifValidLatex(newQ.questionDescription) ? ('$' + newQ.questionDescription.replaceAll('<br>','\\\\') + '$') : newQ.questionDescription.replaceAll('<br>','\\\\'),
          answer: newQ.answer,
          answerDescription: (newQ.answerDescription && newQ.answerDescription.length > 0) ? (!ifValidLatex(newQ.answerDescription) ? ('$' + newQ.answerDescription.replaceAll('<br>','\\\\') + '$') : newQ.answerDescription.replaceAll('<br>','\\\\')) : '',
          solnVideoRef: (newQ.solnVideoRef && newQ.solnVideoRef.length > 0) ? newQ.solnVideoRef : undefined,
          imageLinks: newQ.imageLinks
        }

        const checkQuest = {...newQuest}
        checkQuest.srno = 1;

        if(containsObject(checkQuest, newQuests.map(nq => {
          return {
            ...nq,
            ...{srno: 1}
          }
        }))) {
          enqueueSnackbar('Duplicate Question set found within this Comprehensive Question', {variant: 'warning'});
          return;
        } else {
          newQuests.push(newQuest)
        }
      }
    }

    addComprehensiveQuestionToList(newQuests)
  }

  const replaceQuestion = () => {
    const newQuests: QuestionBankWithSelect[] = []

    if(question.length === 0) {
      enqueueSnackbar('Please Add the Question', {variant: 'warning'});
      return
    }

    for(let newQInd=0; newQInd<allQuestion.length; newQInd++) {
      const newQ = allQuestion[newQInd]

      if(newQ.type === 'single') {
        if(newQ.questionDescription.length === 0) {
          enqueueSnackbar('Please Add Question Description for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(!ifValidLatex(newQ.questionDescription)) {
          enqueueSnackbar('Question Description is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined).some(opt => (opt as string).length === 0)) {
          enqueueSnackbar('Please fill All Options for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(filter([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined), (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
          enqueueSnackbar('Please remove Duplicates in Option for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined).some(opt => !ifValidLatex(opt as string))) {
          enqueueSnackbar('Some option is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.answerDescription && newQ.answerDescription.length > 0 && !ifValidLatex(newQ.answerDescription)) {
          enqueueSnackbar('Solution is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.solnVideoRef && newQ.solnVideoRef.length !== 0 && !newQ.solnVideoRef.match(URL_PATTERN)) {
          enqueueSnackbar('Solution Link is Not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        let newQuest: QuestionBankWithSelect = {
          srno: newQ.srno,
          questionBankFriendlyName: newQ.questionBankFriendlyName,
          description: newQ.description,
          boardname: newQ.boardname,
          classname: newQ.classname,
          subjectname: newQ.subjectname,
          chaptername: '',
          subTopic: '',
          type: newQ.type,
          complexity: newQ.complexity,
          availableForMockTest: newQ.availableForMockTest,
          commonQuestionPart: !ifValidLatex(question) ? ('$' + question.replaceAll('<br>','\\\\') + '$') : question.replaceAll('<br>','\\\\'),
          questionDescription: !ifValidLatex(newQ.questionDescription) ? ('$' + newQ.questionDescription.replaceAll('<br>','\\\\') + '$') : newQ.questionDescription.replaceAll('<br>','\\\\'),
          answer: newQ.answer,
          answerDescription: (newQ.answerDescription && newQ.answerDescription.length > 0) ? (!ifValidLatex(newQ.answerDescription) ? ('$' + newQ.answerDescription.replaceAll('<br>','\\\\') + '$') : newQ.answerDescription.replaceAll('<br>','\\\\')) : '',
          solnVideoRef: (newQ.solnVideoRef && newQ.solnVideoRef.length > 0) ? newQ.solnVideoRef : undefined,
          imageLinks: newQ.imageLinks
        }

        if(newQ._id && newQ._id.length > 0) {
          newQuest = {...newQuest, ...{_id: newQ._id}}
        }

        if(newQ.option1 !== undefined) {
          newQuest = {...newQuest, ...{option1: !ifValidLatex(newQ.option1) ? ('$' + newQ.option1.replaceAll('<br>','\\\\') + '$') : newQ.option1.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option2 !== undefined) {
          newQuest = {...newQuest, ...{option2: !ifValidLatex(newQ.option2) ? ('$' + newQ.option2.replaceAll('<br>','\\\\') + '$') : newQ.option2.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option3 !== undefined) {
          newQuest = {...newQuest, ...{option3: !ifValidLatex(newQ.option3) ? ('$' + newQ.option3.replaceAll('<br>','\\\\') + '$') : newQ.option3.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option4 !== undefined) {
          newQuest = {...newQuest, ...{option4: !ifValidLatex(newQ.option4) ? ('$' + newQ.option4.replaceAll('<br>','\\\\') + '$') : newQ.option4.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option5 !== undefined) {
          newQuest = {...newQuest, ...{option5: !ifValidLatex(newQ.option5) ? ('$' + newQ.option5.replaceAll('<br>','\\\\') + '$') : newQ.option5.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option6 !== undefined) {
          newQuest = {...newQuest, ...{option6: !ifValidLatex(newQ.option6) ? ('$' + newQ.option6.replaceAll('<br>','\\\\') + '$') : newQ.option6.replaceAll('<br>','\\\\')}}
        }

        newQuests.push(newQuest)
      } else if(newQ.type === 'multiple') {
        if(newQ.questionDescription.length === 0) {
          enqueueSnackbar('Please Add Question Description for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(!ifValidLatex(newQ.questionDescription)) {
          enqueueSnackbar('Question Description is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined).some(opt => (opt as string).length === 0)) {
          enqueueSnackbar('Please fill All Options for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(filter([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined), (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
          enqueueSnackbar('Please remove Duplicates in Option for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined).some(opt => !ifValidLatex(opt as string))) {
          enqueueSnackbar('Some option is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.answerDescription && newQ.answerDescription.length > 0 && !ifValidLatex(newQ.answerDescription)) {
          enqueueSnackbar('Solution is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.solnVideoRef && newQ.solnVideoRef.length !== 0 && !newQ.solnVideoRef.match(URL_PATTERN)) {
          enqueueSnackbar('Solution Link is Not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        let newQuest: QuestionBankWithSelect = {
          srno: newQ.srno,
          questionBankFriendlyName: newQ.questionBankFriendlyName,
          description: newQ.description,
          boardname: newQ.boardname,
          classname: newQ.classname,
          subjectname: newQ.subjectname,
          chaptername: '',
          subTopic: '',
          type: newQ.type,
          complexity: newQ.complexity,
          availableForMockTest: newQ.availableForMockTest,
          commonQuestionPart: !ifValidLatex(question) ? ('$' + question.replaceAll('<br>','\\\\') + '$') : question.replaceAll('<br>','\\\\'),
          questionDescription: !ifValidLatex(newQ.questionDescription) ? ('$' + newQ.questionDescription.replaceAll('<br>','\\\\') + '$') : newQ.questionDescription.replaceAll('<br>','\\\\'),
          answer: newQ.answer,
          answerDescription: (newQ.answerDescription && newQ.answerDescription.length > 0) ? (!ifValidLatex(newQ.answerDescription) ? ('$' + newQ.answerDescription.replaceAll('<br>','\\\\') + '$') : newQ.answerDescription.replaceAll('<br>','\\\\')) : '',
          solnVideoRef: (newQ.solnVideoRef && newQ.solnVideoRef.length > 0) ? newQ.solnVideoRef : undefined,
          imageLinks: newQ.imageLinks
        }

        if(newQ._id && newQ._id.length > 0) {
          newQuest = {...newQuest, ...{_id: newQ._id}}
        }

        if(newQ.option1 !== undefined) {
          newQuest = {...newQuest, ...{option1: !ifValidLatex(newQ.option1) ? ('$' + newQ.option1.replaceAll('<br>','\\\\') + '$') : newQ.option1.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option2 !== undefined) {
          newQuest = {...newQuest, ...{option2: !ifValidLatex(newQ.option2) ? ('$' + newQ.option2.replaceAll('<br>','\\\\') + '$') : newQ.option2.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option3 !== undefined) {
          newQuest = {...newQuest, ...{option3: !ifValidLatex(newQ.option3) ? ('$' + newQ.option3.replaceAll('<br>','\\\\') + '$') : newQ.option3.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option4 !== undefined) {
          newQuest = {...newQuest, ...{option4: !ifValidLatex(newQ.option4) ? ('$' + newQ.option4.replaceAll('<br>','\\\\') + '$') : newQ.option4.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option5 !== undefined) {
          newQuest = {...newQuest, ...{option5: !ifValidLatex(newQ.option5) ? ('$' + newQ.option5.replaceAll('<br>','\\\\') + '$') : newQ.option5.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option6 !== undefined) {
          newQuest = {...newQuest, ...{option6: !ifValidLatex(newQ.option6) ? ('$' + newQ.option6.replaceAll('<br>','\\\\') + '$') : newQ.option6.replaceAll('<br>','\\\\')}}
        }

        newQuests.push(newQuest)
      } else if(newQ.type === 'match') {
        if(newQ.questionDescription.length === 0) {
          enqueueSnackbar('Please Add Question Description for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(!ifValidLatex(newQ.questionDescription)) {
          enqueueSnackbar('Question Description is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined).some(opt => (opt as string).length === 0)) {
          enqueueSnackbar('Please fill All Options for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(filter([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined), (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
          enqueueSnackbar('Please remove Duplicates in Option for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if([newQ.option1, newQ.option2, newQ.option3, newQ.option4, newQ.option5, newQ.option6].filter(fil => fil !== undefined).some(opt => !ifValidLatex(opt as string))) {
          enqueueSnackbar('Some option is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.answerDescription && newQ.answerDescription.length > 0 && !ifValidLatex(newQ.answerDescription)) {
          enqueueSnackbar('Solution is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.list1 && newQ.list1.filter(fil => fil !== undefined).some(opt => (opt as string).length === 0)) {
          enqueueSnackbar('Please fill All Boxes in Column 1 for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.list1 && filter(newQ.list1.filter(fil => fil !== undefined), (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
          enqueueSnackbar('Please remove Duplicates in Option, in Column 1 for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.list1 && newQ.list1.filter(fil => fil !== undefined).some(opt => !ifValidLatex(opt as string))) {
          enqueueSnackbar('Some option in Column 1 is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.list2 && newQ.list2.filter(fil => fil !== undefined).some(opt => (opt as string).length === 0)) {
          enqueueSnackbar('Please fill All Boxes in Column 2 for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.list2 && filter(newQ.list2.filter(fil => fil !== undefined), (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
          enqueueSnackbar('Please remove Duplicates in Option, in Column 2 for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.list2 && newQ.list2.filter(fil => fil !== undefined).some(opt => !ifValidLatex(opt as string))) {
          enqueueSnackbar('Some option in Column 2 is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.solnVideoRef && newQ.solnVideoRef.length !== 0 && !newQ.solnVideoRef.match(URL_PATTERN)) {
          enqueueSnackbar('Solution Link is Not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
        
        let newQuest: QuestionBankWithSelect = {
          srno: newQ.srno,
          questionBankFriendlyName: newQ.questionBankFriendlyName,
          description: newQ.description,
          boardname: newQ.boardname,
          classname: newQ.classname,
          subjectname: newQ.subjectname,
          chaptername: '',
          subTopic: '',
          type: newQ.type,
          complexity: newQ.complexity,
          list1: newQ.list1?.map(l1 => !ifValidLatex(l1) ? ('$' + l1.replaceAll('<br>','\\\\') + '$') : l1.replaceAll('<br>','\\\\')),
          list2: newQ.list2?.map(l2 => !ifValidLatex(l2) ? ('$' + l2.replaceAll('<br>','\\\\') + '$') : l2.replaceAll('<br>','\\\\')),
          availableForMockTest: newQ.availableForMockTest,
          commonQuestionPart: !ifValidLatex(question) ? ('$' + question.replaceAll('<br>','\\\\') + '$') : question.replaceAll('<br>','\\\\'),
          questionDescription: !ifValidLatex(newQ.questionDescription) ? ('$' + newQ.questionDescription.replaceAll('<br>','\\\\') + '$') : newQ.questionDescription.replaceAll('<br>','\\\\'),
          answer: newQ.answer,
          answerDescription: (newQ.answerDescription && newQ.answerDescription.length > 0) ? (!ifValidLatex(newQ.answerDescription) ? ('$' + newQ.answerDescription.replaceAll('<br>','\\\\') + '$') : newQ.answerDescription.replaceAll('<br>','\\\\')) : '',
          solnVideoRef: (newQ.solnVideoRef && newQ.solnVideoRef.length > 0) ? newQ.solnVideoRef : undefined,
          imageLinks: newQ.imageLinks
        }

        if(newQ._id && newQ._id.length > 0) {
          newQuest = {...newQuest, ...{_id: newQ._id}}
        }

        if(newQ.option1 !== undefined) {
          newQuest = {...newQuest, ...{option1: !ifValidLatex(newQ.option1) ? ('$' + newQ.option1.replaceAll('<br>','\\\\') + '$') : newQ.option1.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option2 !== undefined) {
          newQuest = {...newQuest, ...{option2: !ifValidLatex(newQ.option2) ? ('$' + newQ.option2.replaceAll('<br>','\\\\') + '$') : newQ.option2.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option3 !== undefined) {
          newQuest = {...newQuest, ...{option3: !ifValidLatex(newQ.option3) ? ('$' + newQ.option3.replaceAll('<br>','\\\\') + '$') : newQ.option3.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option4 !== undefined) {
          newQuest = {...newQuest, ...{option4: !ifValidLatex(newQ.option4) ? ('$' + newQ.option4.replaceAll('<br>','\\\\') + '$') : newQ.option4.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option5 !== undefined) {
          newQuest = {...newQuest, ...{option5: !ifValidLatex(newQ.option5) ? ('$' + newQ.option5.replaceAll('<br>','\\\\') + '$') : newQ.option5.replaceAll('<br>','\\\\')}}
        }
        if(newQ.option6 !== undefined) {
          newQuest = {...newQuest, ...{option6: !ifValidLatex(newQ.option6) ? ('$' + newQ.option6.replaceAll('<br>','\\\\') + '$') : newQ.option6.replaceAll('<br>','\\\\')}}
        }

        newQuests.push(newQuest)
      } else {
        if(newQ.questionDescription.length === 0) {
          enqueueSnackbar('Please Add Question Description for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(!ifValidLatex(newQ.questionDescription)) {
          enqueueSnackbar('Question Description is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.answerDescription && newQ.answerDescription.length > 0 && !ifValidLatex(newQ.answerDescription)) {
          enqueueSnackbar('Solution is not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(newQ.solnVideoRef && newQ.solnVideoRef.length !== 0 && !newQ.solnVideoRef.match(URL_PATTERN)) {
          enqueueSnackbar('Solution Link is Not Valid for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        if(newQ.answer.length === 0) {
          enqueueSnackbar('Please Enter Answer for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }
    
        if(isNaN(Number(newQ.answer))) {
          enqueueSnackbar('Entered Answer is not a Number for Sub-Question ' + String(newQInd + 1), {variant: 'warning'});
          return
        }

        let newQuest: QuestionBankWithSelect = {
          srno: newQ.srno,
          questionBankFriendlyName: newQ.questionBankFriendlyName,
          description: newQ.description,
          boardname: newQ.boardname,
          classname: newQ.classname,
          subjectname: newQ.subjectname,
          chaptername: '',
          subTopic: '',
          type: newQ.type,
          complexity: newQ.complexity,
          availableForMockTest: newQ.availableForMockTest,
          commonQuestionPart: !ifValidLatex(question) ? ('$' + question.replaceAll('<br>','\\\\') + '$') : question.replaceAll('<br>','\\\\'),
          questionDescription: !ifValidLatex(newQ.questionDescription) ? ('$' + newQ.questionDescription.replaceAll('<br>','\\\\') + '$') : newQ.questionDescription.replaceAll('<br>','\\\\'),
          answer: newQ.answer,
          answerDescription: (newQ.answerDescription && newQ.answerDescription.length > 0) ? (!ifValidLatex(newQ.answerDescription) ? ('$' + newQ.answerDescription.replaceAll('<br>','\\\\') + '$') : newQ.answerDescription.replaceAll('<br>','\\\\')) : '',
          solnVideoRef: (newQ.solnVideoRef && newQ.solnVideoRef.length > 0) ? newQ.solnVideoRef : undefined,
          imageLinks: newQ.imageLinks
        }

        if(newQ._id && newQ._id.length > 0) {
          newQuest = {...newQuest, ...{_id: newQ._id}}
        }

        newQuests.push(newQuest)
      }
    }

    
    const sbQ = [...structuredQB].filter(sq => (!sq.commonQuestionPart || (sq.commonQuestionPart !== prevQuestion)))
    const prevQues = [...structuredQB].filter(sq => (sq.commonQuestionPart && (sq.commonQuestionPart === prevQuestion)))
    
    const sbqIds = newQuests.filter(sb => sb._id).map(sb => sb._id)
    
    const removedQues: QuestionBankWithSelect[] = []
    prevQues.forEach(pq => {
      if(!sbqIds.includes(pq._id)) {
        removedQues.push({...pq, ...{status: 0}})
      }
    })

    addComprehensiveQuestionToList([...newQuests, ...removedQues], sbQ)
  }

  if(isapiLoad) {
    return <div>

    </div>
  }

  const abcdOptions = ['A', 'B', 'C', 'D', 'E', 'F']
  const romanOptions = ['I', 'II', 'III', 'IV', 'V', 'VI']

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={8}>
            <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Paragraph Question</Typography>
          </Grid>
          <Grid item xs={4}>
          </Grid>
        </Grid>
        <MathEditor latex={question} setLatex={setQuestion} reset={reset} />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {allQuestion.map((oneQues, queInd) => {
            return (
              <Grid item xs={12} style={{marginTop: '30px'}} key={String(queInd)}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, marginTop: '7%'}}>Question {queInd + 1}</Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel>Question Type</InputLabel>
                          <Select
                            value={oneQues.type}
                            onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                              changeType(queInd, e.target.value as "single" | "multiple" | "numeric" | "match")
                            }}
                            label="Question Type"
                          >
                            <MenuItem value={'single'}>Single Answer Multiple Choice</MenuItem>
                            <MenuItem value={'multiple'}>Multiple Answer Multiple Choice</MenuItem>
                            <MenuItem value={'numeric'}>Numeric Answer</MenuItem>
                            <MenuItem value={'match'}>Match the Following</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel>Complexity</InputLabel>
                          <Select
                            value={oneQues.complexity}
                            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                            changeComplexity(queInd, e.target.value as "easy" | "medium" | "high")
                            }
                            label="Complexity"
                          >
                            <MenuItem value={'easy'}>Easy</MenuItem>
                            <MenuItem value={'medium'}>Medium</MenuItem>
                            <MenuItem value={'high'}>High</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button style={{height: '100%', width: '100%'}} color="primary" variant="outlined" onClick={() => removeThisQues(queInd)}>Remove Question</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{marginTop: '20px'}}>
                    {(oneQues.type === 'single') &&
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={8}>
                              <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Sub-Question</Typography>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                          </Grid>
                          <MathEditor latex={oneQues.questionDescription} setLatex={(e) => changeProperty(queInd, 'questionDescription', e)} reset={reset} />                     
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Options</Typography>
                          {[oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined).map((opts, optsNum) => {
                           const uploadBtnName = "uploadSO" + String(optsNum + 1) + "-button"
                           const optionParam = `option${String(optsNum + 1)}` 
                            return (
                              <div key={uploadBtnName}>
                                <MathEditor reset={reset} latex={opts as string} setLatex={(e) => {
                                  if([oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined)[optsNum] !== e) {
                                    //@ts-ignore
                                    changeProperty(queInd, optionParam, e)
                                  }
                                  }}
                                  endAdornment={
                                    <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}}
                                      onClick={() => {
                                        const allOpt = [oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined)
                                        if(allOpt.length < 3) {
                                          enqueueSnackbar('There should be atleast two Options for this Question', {variant: 'warning'});
                                        } else {
                                          changeArrPty(queInd, 'answer', ['1'])
                                          allOpt.splice(optsNum, 1)
                                          allOpt.forEach((singOpt, singOptNum) => {
                                            //@ts-ignore
                                            changeProperty(queInd, 'option' + String(singOptNum + 1), singOpt)
                                          })
                                          for(let i=allOpt.length + 1; i<=6; i++) {
                                            //@ts-ignore
                                            changeProperty(queInd, 'option' + String(i), undefined)
                                          }
                                          setReset(prev => !prev)
                                        }
                                      }}
                                    >
                                      Remove Option
                                    </Typography>
                                  }
                                  style={optsNum > 0 ? {marginTop: '20px'} : {}}
                                />
                              </div>
                            )
                          })}
                          {([oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined).length <= 5) &&
                            <Button style={{width: '100%', marginTop: '15px', height: '50px', border: '1px dashed #4C8BF5'}} color="primary" disableElevation
                              onClick={() => {
                                const allOpt = [oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined)
                                //@ts-ignore
                                changeProperty(queInd, 'option' + String(allOpt.length + 1), 'Option' + String(allOpt.length + 1))
                              }}
                            >
                              + Add Option
                            </Button>
                          }
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Answer</Typography>
                          <Grid container spacing={2} style={{marginTop: '15px'}}>
                            {[oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined).map((opts, optNum) => {
                              return (
                                <Grid item xs={4}>
                                  <Button color="primary" disableElevation
                                    onClick={() => changeArrPty(queInd, 'answer', [String(optNum + 1)])} 
                                    style={(String(optNum + 1) === oneQues.answer[0]) ? {width: '100%', height: '50px', background: '#80C12C', color: "#FFFFFF", textTransform: 'none'} : {width: '100%', height: '50px', color: "#4C8BF5", border: "1px solid #4C8BF5", background: "#FFFFFF", textTransform: 'none'}}
                                  >
                                    {'Option ' + abcdOptions[optNum]}
                                  </Button>
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Grid container>
                            <Grid item xs={8}>
                              <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Solution Description</Typography>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                          </Grid>
                          <MathEditor reset={reset} latex={oneQues.answerDescription} setLatex={(e) => changeProperty(queInd, 'answerDescription', e)} />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Solution Video Link</Typography>
                          <TextField variant="outlined" style={{width: '100%', marginTop: '15px'}} placeholder="Paste Solution Video Link..." 
                            value={oneQues.solnVideoRef} onChange={(e) => changeProperty(queInd, 'solnVideoRef', e.target.value)}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.root
                              }
                            }}
                          />
                          {oneQues.solnVideoRef && oneQues.solnVideoRef.match(URL_PATTERN) &&
                            <ReactPlayer
                              url={oneQues.solnVideoRef}
                              controls
                              light
                              style={{width: '100%', marginTop: '15px'}}
                              width={'100%'}
                            />
                          }
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <span style={{float: 'right'}} className={classes.addAssi}>
                            <Button style={{marginLeft: '15px'}} color="primary" disableElevation variant="outlined" 
                              onClick={() => clearAllVal(queInd)}
                            >
                              Clear
                            </Button>
                          </span>
                        </Grid>
                      </Grid>
                    }
                    {(oneQues.type === 'multiple') &&
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={8}>
                              <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Sub-Question</Typography>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                          </Grid>
                          <MathEditor latex={oneQues.questionDescription} setLatex={(e) => changeProperty(queInd, 'questionDescription', e)} reset={reset} />                       
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Options</Typography>
                          {[oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined).map((opts, optsNum) => {
                          const uploadBtnName = "uploadSO" + String(optsNum + 1) + "-button"
                          const optionParam = `option${String(optsNum + 1)}` 
                            return (
                              <div key={uploadBtnName}>
                                <MathEditor reset={reset} latex={opts as string}  setLatex={(e) => {
                                    if([oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined)[optsNum] !== e) {
                                      //@ts-ignore
                                      changeProperty(queInd, optionParam, e)
                                    }
                                  }}
                                  endAdornment={
                                    <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}}
                                      onClick={() => {
                                        const allOpt = [oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined)
                                        if(allOpt.length < 3) {
                                          enqueueSnackbar('There should be atleast two Options for this Question', {variant: 'warning'});
                                        } else {
                                          changeArrPty(queInd, 'answer', ['1'])
                                          allOpt.splice(optsNum, 1)
                                          allOpt.forEach((singOpt, singOptNum) => {
                                            //@ts-ignore
                                            changeProperty(queInd, 'option' + String(singOptNum + 1), singOpt)
                                          })
                                          for(let i=allOpt.length + 1; i<=6; i++) {
                                            //@ts-ignore
                                            changeProperty(queInd, 'option' + String(i), undefined)
                                          }
                                          setReset(prev => !prev)
                                        }
                                      }}
                                    >
                                      Remove Option
                                    </Typography>
                                  }
                                  style={optsNum > 0 ? {marginTop: '20px'} : {}}
                                />
                              </div>
                            )
                          })}
                          {([oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined).length <= 5) &&
                            <Button style={{width: '100%', marginTop: '15px', height: '50px', border: '1px dashed #4C8BF5'}} color="primary" disableElevation
                              onClick={() => {
                                const allOpt = [oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined)
                                //@ts-ignore
                                changeProperty(queInd, 'option' + String(allOpt.length + 1), 'Option' + String(allOpt.length + 1))
                              }}
                            >
                              + Add Option
                            </Button>
                          }
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Answer</Typography>
                          <Grid container spacing={2} style={{marginTop: '15px'}}>
                            {[oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined).map((opts, optNum) => {
                              return (
                                <Grid item xs={4}>
                                  <Button color="primary" disableElevation
                                    onClick={() => {
                                      if(oneQues.answer.includes(String(optNum + 1))) {
                                        if(oneQues.answer.length < 2) {
                                          enqueueSnackbar('There should be atleast One Options Selected', {variant: 'warning'});
                                        } else {
                                          const allAns = [...oneQues.answer].filter(fil => fil !== String(optNum + 1));
                                          changeArrPty(queInd, 'answer', allAns)
                                        }
                                      } else {
                                        const allAns = [...oneQues.answer]
                                        allAns.push(String(optNum + 1))
                                        changeArrPty(queInd, 'answer', allAns)
                                      }
                                    }} 
                                    style={oneQues.answer.includes(String(optNum + 1)) ? {width: '100%', height: '50px', background: '#80C12C', color: "#FFFFFF", textTransform: 'none'} : {width: '100%', height: '50px', color: "#4C8BF5", border: "1px solid #4C8BF5", background: "#FFFFFF", textTransform: 'none'}}
                                  >
                                    {'Option ' + abcdOptions[optNum]}
                                  </Button>
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Grid container>
                            <Grid item xs={8}>
                              <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Solution Description</Typography>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                          </Grid>
                          <MathEditor reset={reset} latex={oneQues.answerDescription} setLatex={(e) => changeProperty(queInd, 'answerDescription', e)} />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Solution Video Link</Typography>
                          <TextField variant="outlined" style={{width: '100%', marginTop: '15px'}} placeholder="Paste Solution Video Link..." 
                            value={oneQues.solnVideoRef} onChange={(e) => changeProperty(queInd, 'solnVideoRef', e.target.value)}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.root
                              }
                            }}
                          />
                          {oneQues.solnVideoRef && oneQues.solnVideoRef.match(URL_PATTERN) &&
                            <ReactPlayer
                              url={oneQues.solnVideoRef}
                              controls
                              light
                              style={{width: '100%', marginTop: '15px'}}
                              width={'100%'}
                            />
                          }
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <span style={{float: 'right'}} className={classes.addAssi}>
                            <Button style={{marginLeft: '15px'}} color="primary" disableElevation variant="outlined" 
                              onClick={() => clearAllVal(queInd)}
                            >
                              Clear
                            </Button>
                          </span>
                        </Grid>
                      </Grid>
                    }
                    {(oneQues.type === 'match') &&
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={8}>
                              <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Sub-Question</Typography>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                          </Grid>
                          <MathEditor latex={oneQues.questionDescription} setLatex={(e) => changeProperty(queInd, 'questionDescription', e)} reset={reset} />                       
                        </Grid>

                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Column 1</Typography>
                              {oneQues.list1?.map((listOpt, listInd) => {
                                return (
                                  <MathEditor reset={reset} latex={listOpt} setLatex={(e) => {
                                      if(oneQues.list1) {
                                        const allList = [...oneQues.list1]
                                        allList[listInd] = e
                                        changeArrPty(queInd, 'list1', allList)
                                      }
                                    }}
                                    endAdornment={
                                      <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}}
                                        onClick={() => {
                                          if(oneQues.list1) {
                                            const allList = [...oneQues.list1]
                                            if(allList.length < 3) {
                                              enqueueSnackbar('There should be atleast two Options in Column 1', {variant: 'warning'});
                                            } else {
                                              allList.splice(listInd, 1)
                                              changeArrPty(queInd, 'list1', allList)
                                              setReset(prev => !prev)
                                            }
                                          }
                                        }}
                                      >
                                        Remove Option
                                      </Typography>
                                    }
                                    style={listInd > 0 ? {marginTop: '20px'} : {}}
                                  />
                                )
                              })}
                              {(oneQues.list1 && oneQues.list1.length <= 5) &&
                                <Button style={{width: '100%', marginTop: '15px', height: '50px', border: '1px dashed #4C8BF5'}} color="primary" disableElevation
                                  onClick={() => {
                                    if(oneQues.list1) {
                                      const allList = [...oneQues.list1]
                                      allList.push('Option-' + abcdOptions[allList.length] + ':')
                                      changeArrPty(queInd, 'list1', allList)
                                    }
                                  }}
                                >
                                  + Add Option
                                </Button>
                              }
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Column 2</Typography>
                              {oneQues.list2?.map((listOpt, listInd) => {
                                return (
                                  <MathEditor reset={reset} latex={listOpt} setLatex={(e) => {
                                      if(oneQues.list2) {
                                        const allList = [...oneQues.list2]
                                        allList[listInd] = e
                                        changeArrPty(queInd, 'list2', allList)
                                      }
                                    }}
                                    endAdornment={
                                      <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}}
                                        onClick={() => {
                                          if(oneQues.list2) {
                                            const allList = [...oneQues.list2]
                                            if(allList.length < 3) {
                                              enqueueSnackbar('There should be atleast two Options in Column 2', {variant: 'warning'});
                                            } else {
                                              allList.splice(listInd, 1)
                                              changeArrPty(queInd, 'list2', allList)
                                              setReset(prev => !prev)
                                            }
                                          }
                                        }}
                                      >
                                        Remove Option
                                      </Typography>
                                    }
                                    style={listInd > 0 ? {marginTop: '20px'} : {}}
                                  />
                                )
                              })}
                              {(oneQues.list2 && oneQues.list2.length <= 5) &&
                                <Button style={{width: '100%', marginTop: '15px', height: '50px', border: '1px dashed #4C8BF5'}} color="primary" disableElevation
                                  onClick={() => {
                                    if(oneQues.list2) {
                                      const allList = [...oneQues.list2]
                                      allList.push('Option-' + romanOptions[allList.length] + ':')
                                      changeArrPty(queInd, 'list2', allList)
                                    }
                                  }}
                                >
                                  + Add Option
                                </Button>
                              }
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Options</Typography>
                          {[oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined).map((opts, optsNum) => {
                          const uploadBtnName = "uploadSO" + String(optsNum + 1) + "-button"
                          const optionParam = `option${String(optsNum + 1)}` 
                            return (
                              <div key={uploadBtnName}>
                                <MathEditor reset={reset} latex={opts as string}  setLatex={(e) => {
                                    if([oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined)[optsNum] !== e) {
                                      //@ts-ignore
                                      changeProperty(queInd, optionParam, e)
                                    }
                                  }}
                                  endAdornment={
                                    <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}}
                                      onClick={() => {
                                        const allOpt = [oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined)
                                        if(allOpt.length < 3) {
                                          enqueueSnackbar('There should be atleast two Options for this Question', {variant: 'warning'});
                                        } else {
                                          changeArrPty(queInd, 'answer', ['1'])
                                          allOpt.splice(optsNum, 1)
                                          allOpt.forEach((singOpt, singOptNum) => {
                                            //@ts-ignore
                                            changeProperty(queInd, 'option' + String(singOptNum + 1), singOpt)
                                          })
                                          for(let i=allOpt.length + 1; i<=6; i++) {
                                            //@ts-ignore
                                            changeProperty(queInd, 'option' + String(i), undefined)
                                          }
                                          setReset(prev => !prev)
                                        }
                                      }}
                                    >
                                      Remove Option
                                    </Typography>
                                  }
                                  style={optsNum > 0 ? {marginTop: '20px'} : {}}
                                />
                                <FormHelperText>{'Sample: Option-A -> Option-II, Option-B -> Option-I'}</FormHelperText>
                              </div>
                            )
                          })}
                          {([oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined).length <= 5) &&
                            <Button style={{width: '100%', marginTop: '15px', height: '50px', border: '1px dashed #4C8BF5'}} color="primary" disableElevation
                              onClick={() => {
                                const allOpt = [oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined)
                                //@ts-ignore
                                changeProperty(queInd, 'option' + String(allOpt.length + 1), 'Option' + String(allOpt.length + 1))
                              }}
                            >
                              + Add Option
                            </Button>
                          }
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Answer</Typography>
                          <Grid container spacing={2} style={{marginTop: '15px'}}>
                            {[oneQues.option1, oneQues.option2, oneQues.option3, oneQues.option4, oneQues.option5, oneQues.option6].filter(fil => fil !== undefined).map((opts, optNum) => {
                              return (
                                <Grid item xs={4}>
                                  <Button color="primary" disableElevation
                                    onClick={() => changeArrPty(queInd, 'answer', [String(optNum + 1)])} 
                                    style={(String(optNum + 1) === oneQues.answer[0]) ? {width: '100%', height: '50px', background: '#80C12C', color: "#FFFFFF", textTransform: 'none'} : {width: '100%', height: '50px', color: "#4C8BF5", border: "1px solid #4C8BF5", background: "#FFFFFF", textTransform: 'none'}}
                                  >
                                    {'Option ' + abcdOptions[optNum]}
                                  </Button>
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Grid container>
                            <Grid item xs={8}>
                              <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Solution Description</Typography>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                          </Grid>
                          <MathEditor reset={reset} latex={oneQues.answerDescription} setLatex={(e) => changeProperty(queInd, 'answerDescription', e)} />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Solution Video Link</Typography>
                          <TextField variant="outlined" style={{width: '100%', marginTop: '15px'}} placeholder="Paste Solution Video Link..." 
                            value={oneQues.solnVideoRef} onChange={(e) => changeProperty(queInd, 'solnVideoRef', e.target.value)}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.root
                              }
                            }}
                          />
                          {oneQues.solnVideoRef && oneQues.solnVideoRef.match(URL_PATTERN) &&
                            <ReactPlayer
                              url={oneQues.solnVideoRef}
                              controls
                              light
                              style={{width: '100%', marginTop: '15px'}}
                              width={'100%'}
                            />
                          }
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <span style={{float: 'right'}} className={classes.addAssi}>
                            <Button style={{marginLeft: '15px'}} color="primary" disableElevation variant="outlined" 
                              onClick={() => clearAllVal(queInd)}
                            >
                              Clear
                            </Button>
                          </span>
                        </Grid>
                      </Grid>
                    }
                    {(oneQues.type === 'numeric') &&
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={8}>
                              <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Sub-Question</Typography>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                          </Grid>
                          <MathEditor latex={oneQues.questionDescription} setLatex={(e) => changeProperty(queInd, 'questionDescription', e)} reset={reset} /> 
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Answer</Typography>
                          <TextField variant="outlined" style={{width: '100%', marginTop: '15px'}} placeholder="Enter Answer..." 
                            value={oneQues.answer[0]} onChange={(e) => {
                              changeArrPty(queInd, 'answer', [e.target.value])
                            }}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.root
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Grid container>
                            <Grid item xs={8}>
                              <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Solution Description</Typography>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                          </Grid>
                          <MathEditor reset={reset} latex={oneQues.answerDescription} setLatex={(e) => changeProperty(queInd, 'answerDescription', e)} />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Solution Video Link</Typography>
                          <TextField variant="outlined" style={{width: '100%', marginTop: '15px'}} placeholder="Paste Solution Video Link..." 
                            value={oneQues.solnVideoRef} onChange={(e) => changeProperty(queInd, 'solnVideoRef', e.target.value)}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.root
                              }
                            }}
                          />
                          {oneQues.solnVideoRef && oneQues.solnVideoRef.match(URL_PATTERN) &&
                            <ReactPlayer
                              url={oneQues.solnVideoRef}
                              controls
                              light
                              style={{width: '100%', marginTop: '15px'}}
                              width={'100%'}
                            />
                          }
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '30px'}}>
                          <span style={{float: 'right'}} className={classes.addAssi}>
                            <Button style={{marginLeft: '15px'}} color="primary" disableElevation variant="outlined" 
                              onClick={() => clearAllVal(queInd)}
                            >
                              Clear
                            </Button>
                          </span>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button style={{width: '100%', marginTop: '30px', height: '50px', border: '1px dashed #4C8BF5'}} color="primary" disableElevation
          onClick={() => {
            addNewQuesion()
          }}
        >
          + Add a new Sub-Question
        </Button>
      </Grid>
      <Grid item xs={12} style={{marginTop: '30px'}}>
        <span style={{float: 'right'}} className={classes.addAssi}>
          <Button style={{marginLeft: '15px'}} color="primary" disableElevation variant="contained" 
            onClick={() => {
              if(qTBEStateCo === null) {
                addQuestion()
              } else {
                replaceQuestion()
              }
            }}
          >
            Add to List 
          </Button>
        </span>
      </Grid>
    </Grid>
  );
}

export default ComprehensiveQuestion;