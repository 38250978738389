import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	Box,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	Link,
	Theme,
	Typography
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import * as yup from 'yup';
import { useSnackbar } from "notistack"
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';

import Button from '../../common/components/form_elements/button';
// import LoginImg from '../../../assets/svgs/login.svg';
import LoginImg from '../../../assets/images/login.jpg';
import { fontOptions } from '../../../theme';
import { PASSWORD_PATTERN, PASSWORD_LENGTH, EMAIL_PATTERN, } from '../../common/validations/patterns';
import PasswordVisibilityButton from '../../common/components/password_visibility_button';
import { adminLogin, verifyCaptcha } from '../../../api/auth';
import { setAuthToken } from '../../redux/actions/authActions';
import { getAdminData } from '../../../api/dashboard';
import { setLoginUserData } from '../../redux/actions/adminActions';

const styles = (theme: Theme) =>
	createStyles({
		navLink: {
			fontFamily: fontOptions.family,
			fontSize: fontOptions.size.small,
			lineHeight: '134.69%',
			letterSpacing: '0.0125em',
			textDecorationLine: 'underline'
		},
		error: {
			"&:not(.Mui-disabled)::before": {
				borderColor: "#F44E42"
			}
		},
		loginGrid: {
			height: '100vH',
			backgroundColor: '#FFFFFF'
		},
		loginHead: {
			fontSize: fontOptions.size.mediumPlus,
			fontWeight: fontOptions.weight.bold,
			color: '#3D3D3D',
			paddingTop: '3px'
		},
		loginBtn: {
			paddingTop: '50px',
			'& button': {
				padding: '12px 60px 12px 60px',
				fontWeight: fontOptions.weight.bold,
				fontSize: fontOptions.size.small,
				color: '#FFFFFF',
				borderRadius: '3px',
			},
		},
	});

const ValidationSchema = yup.object().shape({
	userId: yup
		.string()
		// .email()
		.required('user Id is a required field')
		.matches(EMAIL_PATTERN, 'user Id is invalid'),
	password: yup
		.string()
		.required()
		.matches(PASSWORD_PATTERN, 'Password must contain at least one uppercase, lowercase, alphanumeric & special character')
		.matches(PASSWORD_LENGTH, 'Password must contain at least 8 characters')
});

interface Props extends RouteComponentProps<{ usertype?: string; }> { }
interface Props extends WithStyles<typeof styles> { }

interface FormData {
	userId: string;
	password: string;
	serverError: string;
}


const Login: FunctionComponent<Props> = ({ classes }) => {
	const { handleSubmit, register, errors, setError, clearError } = useForm<FormData>({
		mode: 'onBlur',
		validationSchema: ValidationSchema
	});

	const { enqueueSnackbar } = useSnackbar()
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isCaptchaVisible, setCaptchaVisible] = useState(false);
	const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);
	const [redirectTo, setRedirectTo] = useState('');
	const [loading, setLoading] = useState(false);
	const [captcha, setCaptcha] = useState<ReCAPTCHA | null>();
	const dispatch = useDispatch();

	const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY ? process.env.REACT_APP_RECAPTCHA_KEY : '';

	useEffect(() => {
		const script = document.createElement("script")
		script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`
		document.body.appendChild(script)
	}, [])

	const handleLogin = async ({ userId, password }: FormData) => {
		//@ts-ignore
		(window as any).grecaptcha.ready(() => { window.grecaptcha.execute(recaptchaKey).then(async (token) => {
		setLoading(true);
		try {
			const response = await adminLogin(userId, password);
			dispatch(setAuthToken(response.data.accessToken));
			const adminUserData = await getAdminData();
			dispatch(setLoginUserData(JSON.stringify(adminUserData)));
			setLoading(false);
			enqueueSnackbar('Login successful', { variant: 'success' });
			if (adminUserData.isItDefaultPassword) {
				localStorage.setItem('isItDefaultPassword', JSON.stringify(adminUserData.isItDefaultPassword));
				window.location.href = `/set-password`
			} else {
				window.location.href = '/dashboard';
			}
		} catch (error) {
			//@ts-ignore
			window.grecaptcha.reset();
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if (error.response?.data.code == 211) {
				window.location.href = '/dashboard';
			} else {
				captcha && captcha.reset();
				// setCaptchaVisible(true);
				// setIsLoginButtonDisabled(true);
			}
		}
	})})
	}

	const onChange = async (value: string | null) => {
		clearError('serverError');
		if (value) {
			try {
				const response = await verifyCaptcha(value);
				if (response?.data.code === 0) {
					setIsLoginButtonDisabled(false);
				} else {
					setError('serverError', 'Server response', response?.data.message);
				}
			} catch (error) {
				enqueueSnackbar('Something went wrong', { variant: 'warning' });
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<div>
			<Grid container>
				<Grid item xs={12} md={6} >
					<Box height="100%">
						<Box style={{ height: '100%' }}>
							<Grid item xs={12} >
								<img src={LoginImg} alt="Login" style={{ height: '100vh' }} />
							</Grid>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md={6} className={classes.loginGrid}>
					<Box paddingY="30px" paddingX="100px" height="100%">
						<Box style={{ height: '100%' }}>
							<Grid
								container
								direction="row"
								alignItems="center"
								justify="center"
								style={{ height: '95%' }}
							>
								<Grid item xs={12}>
									<Typography className={classes.loginHead}>Admin Workspace</Typography>
									<form onSubmit={handleSubmit(handleLogin)}>
										<FormControl fullWidth margin="normal">
											<Input
												name="userId"
												inputProps={{ inputMode: 'email', maxLength: 50 }}
												placeholder="User Id"
												// endAdornment={
												// 	<InputAdornment position="end">
												// 		<IconButton disabled size="small">
												// 			<MobileFriendlyIcon />
												// 		</IconButton>
												// 	</InputAdornment>
												// }
												inputRef={register}
												className={errors.userId ? classes.error : ''}
											/>
											{errors.userId && (
												<FormHelperText error>
													{errors.userId.message}
												</FormHelperText>
											)}
										</FormControl>

										<FormControl fullWidth margin="normal">
											<Input
												name="password"
												placeholder="Password"
												inputProps={{ maxLength: 50 }}
												type={isPasswordVisible ? 'text' : 'password'}
												endAdornment={
													<InputAdornment position="end">
														<PasswordVisibilityButton
															isVisible={isPasswordVisible}
															handleChange={(isVisible) =>
																setIsPasswordVisible(isVisible)
															}
														/>
													</InputAdornment>
												}
												inputRef={register}
												className={errors.password ? classes.error : ''}
											/>
											{errors.password && (
												<FormHelperText error>
													{errors.password.message}
												</FormHelperText>
											)}
										</FormControl>
										<Box
											textAlign="right"
											marginBottom="5px"
											className={classes.navLink}
										>
											<Link
												color="primary"
												to="/forgot-password"
												component={RouterLink}
											>
												Forgot Password?
                      </Link>
										</Box>
										<FormControl fullWidth margin="normal">
											<div
											className="g-recaptcha"
											data-sitekey={recaptchaKey}
											data-size="invisible"
											>
											</div>
										</FormControl>
										<Box className={classes.loginBtn}>
											<Button
												disableElevation
												variant="contained"
												color="primary"
												size="large"
												type="submit"
												disabled={isLoginButtonDisabled || loading}
											>
												Login
                      </Button>
										</Box>
									</form>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Grid>
				<Box
					position="absolute"
					bottom="20px"
					left="20px"
					color="#666666"
					fontFamily={fontOptions.family}
					fontSize={fontOptions.size.small}
				>
					V 2.1
        </Box>
			</Grid>
		</div>
	)
};

export default withStyles(styles)(withRouter(Login));
