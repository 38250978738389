import React, { FunctionComponent, useState, useEffect } from 'react';
import { Box, Container, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme, darken } from '@material-ui/core/styles';
import MiniDrawer from '../../common/components/SideDrawer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSnackbar } from "notistack";
import Button from '../../common/components/form_elements/button';
import { fontOptions } from '../../common/theme';
import { delcertupload, getcertupload } from '../helpers/api';
import { CertificateObj } from '../interfaces';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import Datagrids, { datagridCellExpand } from '../../dashboard/components/dataGrid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from '../../common/components/confirmation_modal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
        fontSize: fontOptions.size.large
    },
    addAssi: {
        '& button': {
          color: '#4C8BF5',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: darken('#FFFFFF', 0.1),
          }
        },
        paddingTop: '12px'
    },
  }),
);

interface Props extends RouteComponentProps {}

const CertificateUpload: FunctionComponent<Props> = ({history, location})  => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [certs, setCerts] = useState<CertificateObj[]>([])
    const [cTBD, setCTBD] = useState<string>('')
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    useEffect(() => {
        initData()
    }, [location.search])

    const initData = async() => {
        const batchesresp = await getcertupload()
        setCerts(batchesresp)
    }

    const editCert = (data: CertificateObj) => {
        if(data && data._id) {
            history.push(`/certificateupload/create?id=${data._id}`)
        }
    }

    const initdeleteCert = (selectedRowDetails: CertificateObj) => {
        if(selectedRowDetails && selectedRowDetails._id) {
            setCTBD(selectedRowDetails._id)
            setOpenConfirmationModal(true)
          }
    }

    const deleteCT = async () => {
        if(cTBD) {
          await delcertupload(cTBD)
          enqueueSnackbar('Successfully deleted', {variant: 'success'})
          setCTBD('')
          initData()
          setOpenConfirmationModal(false);
        }
      }

    const buttonData = [
        {
          title: 'Edit',
          action: editCert,
          icon: <EditIcon />
        },
        {
          title: 'Delete',
          action: initdeleteCert,
          icon: <DeleteIcon />
        }
    ];

    const gridColumns: GridColDef[] = [
        { field: 'id', headerName: 'Sl No.', flex: 0.7 },
        { field: 'name', headerName: 'Name', flex: 1, renderCell: datagridCellExpand },
        { field: 'ownerId', headerName: 'Owner ID', flex: 1, renderCell: datagridCellExpand },
        { field: 'height', headerName: 'Height', flex: 1, renderCell: datagridCellExpand },
        { field: 'width', headerName: 'Width', flex: 1, renderCell: datagridCellExpand },
        { field: 'action', headerName: 'Action', flex: 1.3, disableClickEventBubbling: true, 
            renderCell: (params: GridCellParams) => {
                const selectedRow = {
                    id: params.getValue("id") as number,
                    name: params.getValue("name") as string
                }

                const selectedRowDetails = certs?.find((row, index) => {
                    return (row.certificateDesign === selectedRow.name && index === (selectedRow.id - 1))
                })

                const buttonSet = buttonData.map((button, index) => {
                    return (
                        <Tooltip key={index} title={button.title}>
                            <IconButton
                                onClick={() => {
                                    button.action(selectedRowDetails as CertificateObj);
                                }}
                                size="small"
                            >
                                {button.icon}
                            </IconButton>
                        </Tooltip>
                    );
                })
    
                return <div>{buttonSet}</div>;
            }
        }
    ];
    
    const gridRows = certs?.map((row, index) => {
        return ({
          id: (index + 1),
          name: row.certificateDesign,
          ownerId: row.ownerId,
          height: row.height.toFixed(2),
          width: row.width.toFixed(2)
        })
    })

    return (
        <div>
            <MiniDrawer>
            <Container maxWidth="lg" style={{padding: '30px 2.5%'}}>
                <Box
                    bgcolor="#4C8BF5"
                    padding="20px 30px"
                    marginBottom="30px"
                    position="relative"
                    borderRadius="14px"
                    color='#fff'
                >
                    <Grid item container>
                        <Grid item sm={12}>
                            <Box style={{height: '100%'}}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justify="center"
                                    style={{ height: '100%' }}
                                >
                                    <Grid item xs={12}>
                                        <Typography className={classes.title}>
                                            Certificate List
                                        </Typography>
                                        <Typography>
                                            View Uploaded Certificate
                                        </Typography>
                                        <Box className={classes.addAssi}>
                                            <Button variant="contained" disableElevation onClick={() => history.push('/certificateupload/create')}>
                                                Upload Certificate
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>


                <Box
                    bgcolor="#ffffff"
                    borderRadius="14px"
                    padding="25px"
                    marginTop='25px'
                >
                    <Grid container>
                        <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
                            <Typography style={{fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, color: '#3D3D3D'}}>
                                Certificates
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{marginBottom: '10px'}}>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: '15px'}}>
                            <Datagrids gridRows={gridRows} gridColumns={gridColumns} disableCheckbox={true} />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            </MiniDrawer>
            <ConfirmationModal
				header="Delete Certificate Template"
                helperText="Are you sure you want to delete?"
                openModal={openConfirmationModal}
                onClose={() => {setOpenConfirmationModal(false)}}
                handleDelete={deleteCT}
			/>
        </div>
    );
}

export default withRouter(CertificateUpload)
