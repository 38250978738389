import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Container,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ErrDataTable } from './ErrDataTable';
import Modal from '../../common/components/modal';

interface Props {
  openModal: boolean;
  onClose: () => any;
  data: any[] | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

  }),
);

const ErrModal: FunctionComponent<Props> = ({openModal, onClose, data}) => {
  const classes = useStyles();
  const [chart, setChart] = useState<any[]>([])

  useEffect(() => {
    console.log(data)
    if(!data) return
    setChart(data)
  }, [data])

  

  return (
    <Modal
      open={openModal}
      handleClose={onClose}
      header={
        <Box width="800px" display="flex" alignItems="center">
          <Box marginLeft="15px">
            <Typography component="span" color="secondary">
              <Box component="h3" color="white" fontWeight="400" margin="0">
                Error Report
              </Box>
            </Typography>
          </Box>
        </Box>
      }
    >
      <Container>
        <Box width="100%">
          {chart.length === 0 ? (
            ''
          ) : (
            <Box padding="1px 0px 9px 0px">
                <ErrDataTable gridData={chart} />
            </Box>
          )}
        </Box>
      </Container>
    </Modal>
  );
}

export default ErrModal
