import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_2 + "customerfeedback/"
const CREATE_FEEDBACK = BASE_URL + "createFeedback"

interface jobQuery {
    fromDate: Date,
    endDate: Date,
    status: string,
    dataType: string
}

export const postFeedback = async (obj: any) => {
    return await axios.post(CREATE_FEEDBACK, obj)
} 