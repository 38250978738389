import React, { useState, useEffect, FunctionComponent, } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
	Box, Container, Grid, Tooltip, Typography, CssBaseline, Card, CardContent, CardHeader, IconButton, Button, TextField
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"

import MiniDrawer from "../../../common/components/SideDrawer"
import useStyles from './styles';
import { CustomUser, } from '../../../common/contracts/dashboard';
import { RootState } from '../../../redux/store';
import { connect, useDispatch } from 'react-redux';
import { MASTER_PERMISSIONS, USER_ROLES, USER_TYPES, USER_TYPE_VALUES } from '../../../../utilities/constants';
import { getUser, updateUserPreferences } from '../../../../api/dashboard';
import { ArrowBack } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { roles } from '../../../edumaticaAcademy/enums';
import { GridColumns , GridRow } from '@material-ui/data-grid';
import Datagrids, { datagridCellExpand } from '../../components/dataGrid';
import { fetchAllSecretKeys, generateSecretKey, deleteSecret } from "../../api"
import {  Block, Delete, Receipt, Search, Visibility, VisibilityOff, ViewList } from '@material-ui/icons';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';

interface Props extends RouteComponentProps {
	user: CustomUser
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddDevApi: FunctionComponent<Props> = ({ user, history }) => {

	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const [ownerId, setOwnerId] = useState<string>("")
	const [orgId, setOrgId] = useState<string>("")
	const [mobile, setMobile] = useState<string>("")
	const [userKey1, setUserKey1] = useState('User Key')
	const [secretKey1, setSecretKey1] = useState('Secret Key')
	const [rows, setRows] = useState<{id: number; userKey: string; secretKey: string}[]>([])
	const [update, setUpdate] = useState<boolean>(false)
	const styles = useStyles();



// let rows;

// rows = [
// 	{ id: 4, userKey: '12457878', secretKey: '12458787' },
// ];

	useEffect(() => {
		console.log("user", user)
		getLoggedInUserData(user?.ownerId || "", user?.mobileNo || "")
		setOwnerId(user?.ownerId || "")
		setMobile(user?.mobileNo || "")
		setOrgId(user?._id || "")
	}, [secretKey1, update]);

	const getLoggedInUserData = async (ownerId: string, mobileNo: string) => {
		const res = await fetchAllSecretKeys({
			 ownerId, 
			 mobile:mobileNo
			})
			let rowData = res.map((item:any, index:number)=>{
				return {
					id: index+1,
					userKey: item.user,
					secretKey: item.secretKey
				}
			})
		console.log('response',rowData)
		setRows(rowData)
	}

	const getSecretKeys = async () => {
		setLoading(true);
		try {
			const res = await generateSecretKey({ ownerId, mobile, orgId });
			console.log('generate res', res)
			setSecretKey1(res.secret)
			setUserKey1(res.user)
			
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}



	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const handelDelete = async (props:any)=>{
		console.log('on delete', props)
		try {
			const userKey = props.userKey
			const res = await deleteSecret({ownerId, userKey})
			console.log('res',res)
			setUpdate(!update)
		} catch (error) {
			
		}
	}
	const handelChangeStatus = (props:any)=>{

	}

	const buttonData = [
		{
			title: 'Block/Unblock',
			action: handelChangeStatus,
			icon: <Block />
		},
		{
			title: 'View/Edit Details',
			action: handelDelete,
			icon: <Delete />
		},
	]
	
	let columns = [
		{ field: 'id', headerName: 'S.No' },
		{ field: 'userKey', headerName: 'User Key', type: 'string', width: 400, minWidth: 400, maxWidth: 500 },
		{ field: 'secretKey', headerName: 'Secret Key', type: 'string', width: 400, minWidth: 400, maxWidth: 500},
		{
			field: 'action', headerName: 'Actions', width: 200,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				const selectedRow = {
					id: params.getValue("id") as number,
					userKey: params.getValue("userKey") as string,
					secretKey: params.getValue("secretKey") as string
				}

				const selectedRowDetails = rows.find((row, index) => {
					return (row.secretKey === selectedRow.secretKey && row.userKey === selectedRow.userKey && index === (selectedRow.id - 1))
				})

				const buttonSet = buttonData.map((button, index) => {
					return (
						<Tooltip key={index} title={button.title}>
							<IconButton
								onClick={() => {
									button.action(selectedRowDetails as any);
								}}
								size="small"
							>
								{button.icon}
							</IconButton>
						</Tooltip>
					);
				})

				return <div>{buttonSet}</div>;
			}
		}
	]


	

	

	

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Customer Management</Typography>
							</Grid> */}
							<Grid item className={styles.header}>
								<IconButton onClick={() => history.goBack()}>
									<ArrowBack />
								</IconButton>
								<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
							</Grid>
							<Card className={styles.card} variant='outlined'>
								<CardHeader title='Generate User-Secret keys' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>

									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>User Key</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												
												<TextField 		
													value={userKey1}
													disabled
												/>
											</FormControl>
										</Grid>
									</Grid>

                                    <Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Secret Key</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
											<TextField 		
													value={secretKey1}
													disabled
												/>
											</FormControl>
										</Grid>
									</Grid>

                                    <Grid container xs={12} style={{margin:'20px' }}>
										<Button variant="contained" color="primary" style={{ marginLeft: '15px', marginRight: '15px' }} onClick={getSecretKeys}>
                                        Generate Key
                                    </Button>
                                    </Grid>


								</CardContent>
							</Card>

						</Grid>

						<Box
							
							style={{
								width: '100%',
								// height: '100%',
								marginTop: '20px',
								marginBottom: '20px',
								display: 'flex'
							}}
						>
							
							<Datagrids gridRows={rows} gridColumns={columns} showToolBar={true} disableCheckbox={true} />
						</Box>
					</Container>
				</div >
			</MiniDrawer >
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	user: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(AddDevApi);