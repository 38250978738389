import React, { useState, useEffect, FunctionComponent } from 'react';
// import { makeStyles, createStyles, Theme, lighten } from '@material-ui/core/styles';
// import Select from '@material-ui/core/Select';
// import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
import * as XLSX from 'xlsx';

import MiniDrawer from "../../common/components/SideDrawer"
import Datagrids, { datagridCellExpand } from '../../dashboard/components/dataGrid';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { MASTER_PERMISSIONS } from '../../../utilities/constants';
import Datepickers from '../../dashboard/components/datepickers';
import { compareAsc } from 'date-fns';
import { getLoggedInUserData, onApiError } from '../../common/helpers';
import { updateReceiptTemplate} from "../api";
import { fontOptions } from "../../../theme";
import Button from "../../common/components/form_elements/button";
import EditMasterNotificationTemplateModal from '../../masterData/components/editMasterNotificationTemplateModal';
import {
    Box,
    CssBaseline,
    darken,
    FormControl,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    Typography,
    TextField
} from "@material-ui/core";
import { useSnackbar } from "notistack"
import { Edit, Visibility } from "@material-ui/icons";


interface Props extends RouteComponentProps {
history: any
}

const ReceiptTemp: FunctionComponent<Props> = ({ history }) => {
    const ownerId:String = "ORG992meyaAs5IA";
    const { enqueueSnackbar } = useSnackbar()
    const [redirectTo, setRedirectTo] = useState<string>('')
    const [emailTypes, setEmailTypes] = useState<string []>([]);
    const [emailType,serEmailType] = useState('');

    const [notificationTypes, setNotificationTypes] = useState<string []>(["email", "message"]);
    const [notificationType,setNotificationType] = useState('email');
    const [template, setTemplate] = useState<string>("")
    const classes = useStyles();
    const [showTemplate, setShowTemplate] = useState<Boolean>(true)
    const [editTemplate, setEditTemplate] = useState<boolean>(true)
    const [orgId, setOrgId] = useState<String>('')
    const getNotificationTemplateTypes = async () => {
    }

    if (redirectTo.length > 0) {
        return <Redirect to={redirectTo} />;
    }

    const updateCustomTemplates = async () =>{
        try {
            const res = await updateReceiptTemplate(orgId, template)
            // console.log('response of updation', res)
        } catch (error) {
            console.log("error ", error)
        }
    }
    

    const renderButton = (name: string, cb: any) => (

        <Button style={{ margin: '5px'}} variant="contained" color="primary" size="medium"
                    onClick={cb}
                >
                    {name}
                </Button>
    )

    const templateViewer = (temp: String)=>{
        return <div style={{width:"695px",marginBottom:"50px"}}>
            <Typography className={classes.title}>
             Template Preview
            </Typography>
            {<div dangerouslySetInnerHTML={{ __html: template }} />}
            <div style={{marginTop:"400px"}}>
            {renderButton('Done', () => { setShowTemplate(false)})}

            </div>
        </div>
    }


    const templateEditor = () =>{
       return <div style={{paddingTop: '50px',display:'flex',flexDirection:'column'}}>
            <TextField
            style={{width: "15%",marginBottom:'50px'}}
            placeholder='Owner Id'
            id="outlined-multiline-static"
            label="Owner Id"
            onChange={(e)=>{
               setOrgId(e.target.value)
            }}
            value={orgId}
            variant="outlined"
            />
           <TextField
                style={{width: "70%"}}
               id="outlined-multiline-static"
               label="Html Template"
               multiline
               rows={20}
               placeholder='Insert your Html text here'
               onChange={(e)=>{
                   console.log('item is changing', e.target.value)
                   setTemplate(e.target.value)
               }}
               value={template}
               variant="outlined"
           />
           <div style={{padding: '20px'}}>
               {renderButton('Update', () => { updateCustomTemplates() })}
           </div>
       </div> 
    }


    const previewTemplate = ()=>{
        setShowTemplate(true)
        setEditTemplate(false)
    }

    const uploadTemplate = ()=>{
        setShowTemplate(false)
        setEditTemplate(true)
    }

    return (
        <>
            <CssBaseline />
            <MiniDrawer>
                <Typography className={classes.title}>
                    Receipt Template Upload
                </Typography>

                {notificationType == "email" && showTemplate && templateViewer(template)}
                    <div style={{ display: 'flex', paddingTop: '20px'}}>
                        {renderButton('View Template', previewTemplate)}
                            {/* {renderButton('Upload Template', uploadTemplate)} */}
                    </div>
                {/* </>} */}
                <div >
                 {notificationType == "email" && templateEditor()}

                    {/* {notificationType == "message"  && messageTemplateViewer(messageTemplate)} */}
                </div>
            </MiniDrawer>
        </>
    );
}

const useStyles = makeStyles({
    typography_1: {
        color: '#4285F4'
    },
    typography_2: {
        color: 'red',
        paddingTop: '160px'
    },
    title: {
        fontSize: fontOptions.size.large
    },
    addAssi: {
        '& button': {
            color: '#4C8BF5',
            backgroundColor: '#FFFFFF',
            '&:hover': {
                backgroundColor: darken('#FFFFFF', 0.1),
            }
        },
        paddingTop: '12px'
    },
    formControl: {
        margin: "5px",
        minWidth: 120,
    },
    helperText: {
        fontSize: fontOptions.size.small,
        letterSpacing: '0.15px',
        color: 'rgba(0, 0, 0, 0.6)'
    },
    heading: {
        margin: '0',
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.medium,
        letterSpacing: '1px',
        color: '#212121',
    },
    label: {
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.small,
    },
});

export default ReceiptTemp;
