import {
	Box, Button, Card, Switch, CardContent, CardHeader, Container, CssBaseline, FormHelperText, Grid, IconButton, Input, MenuItem, Typography
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ArrowBack } from '@material-ui/icons';
import { startCase } from "lodash";
import { useSnackbar } from "notistack";
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from "react-router";
import * as yup from 'yup';
import { getCurrentLogoAndSubdomain, getCurrentTheme, getThemesData, setCurrentLogoAndSubdomain, setCurrentTheme } from '../../../../api/dashboard';
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../../utilities/constants';
import Dropzone from '../../../common/components/dropzone';
import MiniDrawer from "../../../common/components/SideDrawer";
import { CustomUser } from '../../../common/contracts/dashboard';
import { RootState } from '../../../redux/store';
import useStyles from './styles';
// import Highlight from 'react-highlight'

let SubdomainRegex = /^[a-zA-Z]+[a-zA-Z\d\-]*$/
// regex domain post dot length increased to 50
let UrlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,50}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/;
interface Props extends RouteComponentProps {
	user: CustomUser
}

interface FormData {
	customerType: string,
	bankAccount: string,
	confirmBankAccount: string,
	ifscCode: string,
	// checkImage:string,
	reason: string
}

const ValidationSchema = yup.object().shape({
	customerType: yup.string().notOneOf(['Select']).required('Customer Type is required'),
	// bankAccount: yup.string().required('Bank Account is required')
	// 	.matches(ACCOUNT_NO_PATTERN, 'Bank Account is invalid'),
	// confirmBankAccount: yup.string().required('Please confirm bank account')
	// 	.matches(ACCOUNT_NO_PATTERN, 'Bank Account is invalid')
	// 	.oneOf([yup.ref('bankAccount'), null], "Bank Accounts don't match."),
	// ifscCode: yup.string().required('IFSC code is required')
	// 	.matches(IFSC_PATTERN, 'IFSC code is invalid'),
	reason: yup.string().required('Reason is required'),
});

export interface Theme {
	_id?: string,
	themeName: string,
	colors: {
		primary: string,
		secondary: string,
		surface: string,
		backdrop: string,
	}
}

const UpdateCustomization: FunctionComponent<Props> = ({ user, history }) => {
	const { errors, setError, clearError, handleSubmit, register, reset, control, setValue } = useForm<FormData>({
		mode: 'onBlur',
		validationSchema: ValidationSchema
	});

	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [checkImage, setcheckImage] = useState<File[]>([]);
	const [titleImage, setTitleImage] = useState<File[]>([]);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitleImage, setPreviewTitleImage] = useState('');
	const [subDomainName, setSubDomainName] = useState<string>('')
	const [themesData, setThemesData] = useState<Theme[]>([])
	const [themeIndex, setThemeIndex] = useState<number>(-1)
	const [tagline, setTagline] = useState<string>('')
	const [redirectUrl, setRedirectUrl] = useState<string>('https://www.edumatica.io')
	const [logo, setLogo] = useState<string>('')
	const [titleTempImage, setTitleTempImage] = useState<string>('')
	const [mainTitle, setMainTitle] = useState<string>('')
	const [courseBool, setCourseBool] = useState(true)
	const [demoDeepLinkBool, setDemoDeepLinkBool] = useState(true)
	const [smsBool, setSmsBool] = useState(false)
	const [emailBool, setEmailBool] = useState(false)
	const [mobileQuery, setMobileQuery] = useState(false)
	const [emailQuery, setEmailQuery] = useState(false)
	const [nameQuery, setNameQuery] = useState(false)
	const [purposeQuery, setPurposeQuery] = useState(false)
	const [courseNameQuery, setCourseNameQuery] = useState(false)
	const [courseQuery, setCourseQuery] = useState(false)
	const [certRedirector, setCertRedirector] = useState("")
	//?  smsBool and emailBool are disable flag if they are set true means we dont have to send mail and sms. 
	//? developer Notes .

	console.log(user.ownerId)

	var allowedPermissions: string[] = [];
	var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
	var loginUserType = '';
	if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
		allowedPermissions = loginUserData.adminRole.permissions;
		loginUserType = loginUserData.adminRole.name;
	}

	const styles = useStyles();

	useEffect(() => {
		getLoggedInUserData();

	}, []);
	// console.error(themeIndex)
	const getAvailableThemes = async () => {
		try {
			const themesData: Theme[] = await getThemesData()
			setThemesData(themesData)
			getCurrentData(themesData)
		}

		catch (error) {
			//   exceptionTracker(error.response?.data.message);
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			} else {
				// profileUpdated(profile)
		};
			}
	}

	const getCurrentData = async (themeData: Theme[]) => {
		try {
			let theme = await getCurrentTheme(user.ownerId as string)
			console.log("theme", theme)
			console.log("There qqill vbw ")
			let themeIndexData = themeData.findIndex(el => el.themeName == theme.themeName)
			console.log(themeIndexData)
			setThemeIndex(themeIndexData)
			let logoAndSubdomainData = await getCurrentLogoAndSubdomain(user.ownerId as string)
			setLogo(logoAndSubdomainData.logo)
			setTitleTempImage(logoAndSubdomainData.titleImage)
			setPreviewImage(logoAndSubdomainData.logo)
			setPreviewTitleImage(logoAndSubdomainData.titleImage)
			setSubDomainName(logoAndSubdomainData.subdomain)
			setTagline(logoAndSubdomainData.tagline ? logoAndSubdomainData.tagline : '')
			setMainTitle(logoAndSubdomainData.mainTitle ? logoAndSubdomainData.mainTitle : '')
			setRedirectUrl(logoAndSubdomainData.redirectUrl ? logoAndSubdomainData.redirectUrl : 'https://www.edumatica.io')
			setDemoDeepLinkBool(logoAndSubdomainData.demoDeepLinkBool)
			setSmsBool(logoAndSubdomainData.smsBool)
			setEmailBool(logoAndSubdomainData.emailBool)
			setCertRedirector(logoAndSubdomainData?.certRedirector || "")
			let queryParams = logoAndSubdomainData?.queryParams
			if(queryParams?.length > 0){
				let newStr = queryParams.join(" ")
				if (newStr.includes("mobileQuery")){
					setMobileQuery(true)
				}
				if (newStr.includes("emailQuery")){
					setEmailQuery(true)
				}
				if (newStr.includes("nameQuery")){
					setNameQuery(true)
				}
				if (newStr.includes("purposeQuery")){
					setPurposeQuery(true)
				}
				if (newStr.includes("courseNameQuery")){
					setCourseNameQuery(true)
				}
				if (newStr.includes("courseQuery")){
					setCourseQuery(true)
				}
			}
			console.log(logoAndSubdomainData.courseBool)
			if (logoAndSubdomainData.courseBool !== undefined) {
				setCourseBool(logoAndSubdomainData.courseBool)
			} else {
				setCourseBool(true)
			}
		}
		catch (error) {
			// exceptionTracker(error.response?.data.message);
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			} else {
				console.log(error)
				// profileUpdated(profile);
			}
		}
	}


	const getLoggedInUserData = async () => {
		// setLoading(true);
		// try {
		// 	const adminUserData = await getAdminData();
		// 	dispatch(setLoginUserData(JSON.stringify(adminUserData)));
		// 	setLoading(false);
		// 	if (adminUserData.isItDefaultPassword) {
		// 		localStorage.setItem('isItDefaultPassword', JSON.stringify(adminUserData.isItDefaultPassword));
		// 		setRedirectTo('/set-password');
		// 	}
		// } catch (error) {
		// 	setLoading(false);
		// 	enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
		// }
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.makerBankChange)
			|| allowedPermissions.includes(MASTER_PERMISSIONS.checkerBankChange);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		} else {
			getAvailableThemes()
		}
	}



	const callUpdateApi = async (logoData: string, imageData: string, subdomain: string, redirectUrl: string, mainTitle: string, courseBool: boolean, demoDeepLinkBool:boolean, smsBool:boolean, emailBool: boolean) => {
		try {
				let queryParams: string[] = []
				if (mobileQuery) {
					queryParams.push("mobileQuery")
				}
				if (emailQuery) {
					queryParams.push("emailQuery")
				}
				if (nameQuery) {
					queryParams.push("nameQuery")
				}
				if (purposeQuery) {
					queryParams.push("purposeQuery")
				}
				if (courseNameQuery) {
					queryParams.push("courseNameQuery")
				}
				if (courseQuery) {
					queryParams.push("courseQuery")
				}
			
			await setCurrentLogoAndSubdomain(user.ownerId as string, logoData, imageData, subdomain, tagline, redirectUrl, mainTitle, courseBool, demoDeepLinkBool, smsBool, emailBool, certRedirector, queryParams)
			enqueueSnackbar("Successfully Applied", { variant: "success" })
		}
		catch (error) {
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	const reader = (file:any) => {
		return new Promise((resolve, reject) => {
	const fileReader = new FileReader();
	fileReader.onload = () => resolve(fileReader.result);
	fileReader.readAsDataURL(file);
});
}

	const handleChange = async () => {
		let logoData = logo
		let imageData = titleTempImage
		if (!UrlRegex.test(redirectUrl)) {
			return enqueueSnackbar('Please ensure correct Redirect Url', { variant: "warning" })
		}
		console.error("Exeecuted")
		try {
			await setCurrentTheme(themesData[themeIndex].themeName, user.ownerId as string)
		}
		catch (error) {
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
		if (checkImage[0]){
			const res = await reader(checkImage[0]);
			//@ts-ignore
			logoData = res;
		} 
		if (titleImage[0]) {
			const res2 = await reader(titleImage[0])
			//@ts-ignore
			imageData = res2;
		}
		console.log('reader', logoData?.substring(0,100))
		console.log('reader', imageData?.substring(0,100))
		callUpdateApi(logoData, imageData, subDomainName, redirectUrl, mainTitle, courseBool, demoDeepLinkBool, smsBool, emailBool)
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}



	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							<Grid item className={styles.header}>
								<IconButton onClick={() => history.goBack()}>
									<ArrowBack />
								</IconButton>
								<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
							</Grid>
							<Card className={styles.card} variant='outlined'>
								<CardHeader title='Change Customizations' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>

									<form onSubmit={handleSubmit(() => { })} >

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Customer Name</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														value={user.name ? user.name : ''}
														disabled
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>SubDomain</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														value={subDomainName}
														onChange={(ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
															const value = ev.target.value as string
															if (value.length > 0) {
																if (!SubdomainRegex.test(value)) {
																	setError(
																		'customerType',
																		'Invalid Data',
																		'Subdomain doesn\'t follows required pattern'
																	);
																}
																else {
																	clearError('customerType')
																}
															}
															setSubDomainName(value)
														}}
													/>
												</FormControl>
												<FormHelperText>
													Assigned Subdomain : {subDomainName}.edumatica.io
												</FormHelperText>
												{errors.customerType && (
													<FormHelperText error>
														{errors.customerType.message}
													</FormHelperText>
												)}
											</Grid>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Tagline</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														value={tagline}
														onChange={(ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
															const value = ev.target.value as string

															setTagline(value)
														}}
													/>
												</FormControl>
											</Grid>
										



											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Title</Box>
												</FormControl>
											</Grid>


											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														value={mainTitle}
														onChange={(ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
															const value = ev.target.value as string

															setMainTitle(value)
														}}
													/>
												</FormControl>


											</Grid>
										</Grid>
										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Redirect Url</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														value={redirectUrl}
														onChange={(ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
															const value = ev.target.value as string
															if (value.length > 0) {
																if (!UrlRegex.test(value)) {
																	setError(
																		'ifscCode',
																		'Invalid Data',
																		'Url doesn\'t follows required pattern. Please ensure HTTPS scheme'
																	);
																}
																else {
																	clearError('ifscCode')
																}
															}
															setRedirectUrl(value)
														}}
													/>
												</FormControl>
												<FormHelperText>
													Assigned Redirect URL : {redirectUrl}
												</FormHelperText>
												{errors.ifscCode && (
													<FormHelperText error>
														{errors.ifscCode.message}
													</FormHelperText>
												)}
											</Grid>
										</Grid>

										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Certificate Redirector</Box>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													value={certRedirector}
													onChange={(ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
														const value = ev.target.value as string
														setCertRedirector(value)
													}}
												/>
											</FormControl>
										</Grid>

										<Grid container className={styles.radioButtons}>
										Certificate redirect url query params option
											<Grid container className={styles.paddClass}>
												<Grid item xs={4} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.parallerButtons}>
															<Box className={styles.label}>Mobile</Box>
															<Grid item xs={12} md={12}>
																<Switch size="small" checked={mobileQuery} onChange={() => { setMobileQuery(!mobileQuery) }} />
															</Grid>
														</Box>
													</FormControl>
												</Grid>
												<Grid item xs={4} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.parallerButtons}>
															<Box className={styles.label}>Email</Box>
															<Grid item xs={12} md={12}>
																<Switch size="small" checked={emailQuery} onChange={() => { setEmailQuery(!emailQuery) }} />
															</Grid>
														</Box>
													</FormControl>
												</Grid>
												<Grid item xs={4} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.parallerButtons}>
															<Box className={styles.label}>Name</Box>
															<Grid item xs={12} md={12}>
																<Switch size="small" checked={nameQuery} onChange={() => { setNameQuery(!nameQuery) }} />
															</Grid>
														</Box>
													</FormControl>
												</Grid>
												<Grid item xs={4} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.parallerButtons}>
															<Box className={styles.label}>Purpose</Box>
															<Grid item xs={12} md={12}>
																<Switch size="small" checked={purposeQuery} onChange={() => { setPurposeQuery(!purposeQuery) }} />
															</Grid>
														</Box>
													</FormControl>
												</Grid>
												<Grid item xs={4} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.parallerButtons}>
															<Box className={styles.label}>Course Name</Box>
															<Grid item xs={12} md={12}>
																<Switch size="small" checked={courseNameQuery} onChange={() => { setCourseNameQuery(!courseNameQuery) }} />
															</Grid>
														</Box>
													</FormControl>
												</Grid>
												<Grid item xs={4} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.parallerButtons}>
															<Box className={styles.label}>Board || Class || Subject</Box>
															<Grid item xs={12} md={12}>
																<Switch size="small" checked={courseQuery} onChange={() => { setCourseQuery(!courseQuery) }} />
															</Grid>
														</Box>
													</FormControl>
												</Grid>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.parallerButtons}>
														<Box className={styles.label}>Edumatica Courses</Box>
														<Grid  item xs={12} md={12}>
															<Switch size="small" checked={courseBool} onChange={() => { setCourseBool(!courseBool) }} />
														</Grid>
													</Box>
													<FormHelperText style={{ borderBottomWidth: 1 }}>
														Disable it for removing Edumatica courses from organisation students.
													</FormHelperText>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.parallerButtons}>
														<Box className={styles.label}>Create demo deep link</Box>
														<Grid  item xs={12} md={12}>
															<Switch size="small" checked={demoDeepLinkBool} onChange={() => { setDemoDeepLinkBool(!demoDeepLinkBool) }} />
														</Grid>
													</Box>
													<FormHelperText style={{ borderBottomWidth: 1 }}>
														Disable it to stop sending firebase deeplink for demo.
													</FormHelperText>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.parallerButtons}>
														<Box className={styles.label}>Disable Email Notification</Box>
														<Grid item xs={12} md={12}>
															<Switch size="small" checked={emailBool} onChange={() => { setEmailBool(!emailBool) }} />
														</Grid>
													</Box>
													<FormHelperText style={{ borderBottomWidth: 1 }}>
														Enable it to stop sending Email Notification.
													</FormHelperText>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.parallerButtons}>
														<Box className={styles.label}>Disable SMS Messages</Box>
														<Grid item xs={12} md={12}>
															<Switch size="small" checked={smsBool} onChange={() => { setSmsBool(!smsBool) }} />
														</Grid>
													</Box>
													<FormHelperText style={{ borderBottomWidth: 1 }}>
														Enable it to stop sending Sms Messages.
													</FormHelperText>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Theme</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">

													<Select
														value={themeIndex}
														variant="outlined"
														onChange={(
															e: React.ChangeEvent<{ value: unknown }>
														) => setThemeIndex(e.target.value as number)}
														// style={{backgroundColor: '#FFFFFF', maxWidth: '80%' ,height:"30px", marginLeft : "10px"}}
														renderValue={(index) => {
															const i = index as number;
															//   console.error(i)
															if (i == -1) {
																return <Typography color='textSecondary' component='caption'>
																	Default
																</Typography>
															}
															//   console.error(startCase(themesData[themeIndex].themeName))
															return (

																<Typography color='textSecondary' component='caption'>
																	{startCase(themesData[themeIndex].themeName)}
																</Typography>

															)
														}}
													>
														{themesData.map((el, index) => (
															<MenuItem key={index} value={index}>
																<React.Fragment>
																	<Typography component='caption'>
																		{startCase(el.themeName)}
																	</Typography>
																	<div style={{ float: 'right', display: "flex" }}>
																		<div
																			style={{ width: "20px", height: "20px", marginLeft: "10px", borderRadius: "10px", backgroundColor: el.colors.primary }}

																		/>
																		<div
																			style={{ width: "20px", height: "20px", marginLeft: "10px", borderRadius: "10px", backgroundColor: el.colors.secondary }}

																		/>
																	</div>

																</React.Fragment>
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
										</Grid>








										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Logo Image</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<Dropzone
													key={0}
													onChange={(files) => {
														if (files.length > 0) {
															setcheckImage(files)
															setPreviewImage(URL.createObjectURL(files[0]))
														}
													}}
													files={checkImage}
													acceptedFiles={['image/jpeg', 'image/png']}
													maxFileSize={104857600} // 100 MB
													contenttype='image'
												/>

												{/* {
													kycData && kycData.kycDetails && kycData.kycDetails.map((item: any) => {
														return item.kycDocType == 'bank' ?
															<Button
																onClick={() => handleViewDocument(item)}
															>View Initial Image</Button> :
															null
													})
												} */}
												{previewImage && <img src={previewImage} />}

											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Title Icon</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<Dropzone
													key={0}
													onChange={(files) => {
														if (files.length > 0) {
															setTitleImage(files)
															setPreviewTitleImage(URL.createObjectURL(files[0]))
														}
													}}
													files={titleImage}
													acceptedFiles={['image/jpeg', 'image/png']}
													maxFileSize={104857600} // 100 MB
													contenttype='image'
												/>

												{/* {
													kycData && kycData.kycDetails && kycData.kycDetails.map((item: any) => {
														return item.kycDocType == 'bank' ?
															<Button
																onClick={() => handleViewDocument(item)}
															>View Initial Image</Button> :
															null
													})
												} */}
												{previewTitleImage && <img src={previewTitleImage} style={{width: 200, height: 200}}/>}

											</Grid>
										</Grid>


										<>
											{(allowedPermissions.includes(MASTER_PERMISSIONS.makerBankChange) || loginUserType == USER_ROLES.super) &&
												<div style={{display:'flex', justifyContent:'space-between',paddingTop:'20px'}}>
													<Button variant="contained" color="primary" size="medium"
													style={{ margin: 10 }}
													onClick={handleChange}
												>	Apply
												</Button>
													<Button variant="contained" color="primary" size="medium"
														style={{ margin: 10 }}
														onClick={()=>{
															// setRedirectTo('/customtemplates')
															history.push('/customtemplates', { user})
														}}
													>	Custom Templates
													</Button>
													</div>
												
												}
										</>

									</form>
								</CardContent>
							</Card>

						</Grid>
					</Container>
				</div >
			</MiniDrawer >
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	user: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(UpdateCustomization);
