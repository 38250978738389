
export const USER_TYPES = {
	TUTOR: 'tutorName',
	ORG: 'organizationName',
	PARENT: 'parentName',
	STUDENT: 'studentName'
}

export const CUSTOMER_TYPES = [
	{ name: 'Tutor', value: 'tutor' },
	{ name: 'Institute', value: 'org' },
	{ name: 'Student', value: 'student' },
	{ name: 'Guardians', value: 'parent' },
];

export const BLOCK_ACTION_TYPES = [
	{ name: 'Temporary Block', value: 'TEMPORARY_DEACTIVATE' },
	{ name: 'Permanent Block', value: 'PERMANENT_DEACTIVATE' },
	{ name: 'Activate', value: 'ACTIVE' },
];

export const USER_TYPE_VALUES = {
	TUTOR: 'tutor',
	ORG_TUTOR: 'orgTutor',
	ORG: 'org',
	PARENT: 'parent',
	STUDENT: 'student'
}

export const MASTER_PERMISSIONS = {
	createAdmin: "createAdmin",
	editAdmin: "editAdmin",
	getAdmin: "getAdmin",
	deleteAdmin: "deleteAdmin",
	getAdmins: "getAdmins",
	createRole: "createRole",
	editRole: "editRole",
	makerKyc: "makerKyc",
	checkerKyc: "checkerKyc",
	makerBankChange: "makerBankChange",
	checkerBankChange: "checkerBankChange",
	getRoles: "getRoles",

	resetPassword: 'resetPassword',
	sendNewPasswordMail: 'sendNewPasswordMail',
	updateUserStatus: 'updateUserStatus',
	getUsers: 'getUsers',
	getUser: 'getUser',
	fetchKycStatus: 'fetchKycStatus',
	fetchKycData: 'fetchKycData',
	otps: 'otps',
	academicDetails: 'academicDetails',
	consolidatedReport: 'consolidatedReport',

	sendEmail: 'sendEmail',

	liveClasses: 'liveClasses',

	edumacpackage: 'edumacpackage',
	getLeads: 'getLeads',
	updateLeads: 'updateLeads',
	jobposting: 'createJob',
	getJobId: 'getJobId',
	getSpreadsheet: 'getSpreadsheet',
	fetchDownloadUrlForCv: 'fetchDownloadUrlForCv',
	updateApplicant: 'updateApplicant',
	updateApplicants: 'updateApplicants',
	homecontent: 'homecontent',
	fetchUploadUrlForHomeContent: 'fetchUploadUrlForHomeContent',
	customerfeedback: 'customerfeedback',
	packageapproval: 'packageapproval',

	uploadCities: 'uploadMasterCities',
	msgtemplate: 'msgtemplate',
	imagesorter: 'imagesorter',
	uploadQuestions: 'uploadData',
	masterQuestions: 'masterQuestions',
	masterNotificationTemplate: 'masterNotificationTemplate',
	masterNotificationTemplateTypes: 'masterNotificationTemplateTypes',

	payorder: 'payorder',

	getJobsForBO: 'getJobsForBO',
	updateContent: 'updateContent',
	updateFeedback: 'updateFeedback',
	createContent: 'createContent',
	updateJob: 'updateJob',
	createFeedback: 'createFeedback',

	edumaticaAcademy: 'edumaticaAcademy',
	edumaticaStandards: 'edumaticaStandards',
	edumaticaQuestionBanks: 'edumaticaQuestionBanks',

	edumacException: 'edumacException',

	tutorPublicProfiles: 'tutorPublicProfiles',
	studentInquiries: 'studentInquiries',
	seoManager:'seoManager',
	viewAppLogs:'viewAppLogs'
}

export const USER_ROLES = {
	super: 'super'
}

export const LIVE_CLASS_TYPES = [
	{ name: 'FULL-ACCESS', value: 'FULL-ACCESS' },
	{ name: 'AUDIO-ONLY', value: 'AUDIO-ONLY' },
];

export const PAYMENT_PLANS = [
	{ name: 'PREPAID', value: 'PREPAID' },
	{ name: 'POSTPAID', value: 'POSTPAID' },
];

export const PAYMENT_CYCLE = [
	{ name: 'MONTHLY', value: 'MONTHLY' },
	{ name: 'HALFYEARLY', value: 'HALFYEARLY' },
	{ name: 'QUARTERLY', value: 'QUARTERLY' },
	{ name: 'LUMPSUM', value: 'LUMPSUM' },
];

export const PLANS = [
	{ name: 'Edumatica_Basic', value: 'Edumatica_Basic' },
	{ name: 'Edumatica_Plus', value: 'Edumatica_Plus' },
	{ name: 'Edumatica_Max', value: 'Edumatica_Max' },
]

export const KYC_STATUS = {
	makerApproved: 'makerApproved',
	makerRejected: 'makerRejected',
	checkerApproved: 'checkerApproved',
	checkerRejected: 'checkerRejected'
}

export const packagesInitData = [{
	name: '',
	planId: 'Edumatica_Basic',
	perstudentcost: 0,
	convenienceFee: 0,
	graceperiod: 0,
	recordingQuota: 0,
	courseCount: -1,
	studentCount: 0,
	tutorCount: -1,
	adminCount: -1,
	batchCount: 0,
	sessionCount: -1,
	contentSize: 0,
	liveClassQuota: 0,
	liveClassType: '',
	// customChapter: number,
	// status: number,
	isActive: true,
	activeFrom: new Date(),
	activeTill: new Date(),
	// icon: string,
	details: '',
	paymentPlans: [
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'MONTHLY',
			graceperiod: 0,
		},
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'QUARTERLY',
			graceperiod: 0,
		},
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'HALFYEARLY',
			graceperiod: 0,
		},
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'LUMPSUM',
			graceperiod: 0,
		}
	],
	additionalCharges: {
		feeName: '',
		totalprice: 0,
		discount: 0,
		discountCap: 0,
		paymentplan: '',
	}
},
{
	name: '',
	planId: 'Edumatica_Plus',
	perstudentcost: 0,
	convenienceFee: 0,
	graceperiod: 0,
	recordingQuota: 0,
	// courseCount: number,
	studentCount: 0,
	// tutorCount: selectedpackage,
	// adminCount: selectedpackage,
	batchCount: 0,
	// sessionCount: number,
	contentSize: 0,
	liveClassQuota: 0,
	liveClassType: '',
	// customChapter: number,
	// status: number,
	isActive: true,
	activeFrom: new Date(),
	activeTill: new Date(),
	// icon: string,
	details: '',
	paymentPlans: [
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'MONTHLY',
			graceperiod: 0,
		},
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'QUARTERLY',
			graceperiod: 0,
		},
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'HALFYEARLY',
			graceperiod: 0,
		},
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'LUMPSUM',
			graceperiod: 0,
		}
	],
	additionalCharges: {
		feeName: '',
		totalprice: 0,
		discount: 0,
		discountCap: 0,
		paymentplan: '',
	}
},
{
	name: '',
	planId: 'Edumatica_Max',
	perstudentcost: 0,
	convenienceFee: 0,
	graceperiod: 0,
	recordingQuota: 0,
	// courseCount: number,
	studentCount: 0,
	// tutorCount: selectedpackage,
	// adminCount: selectedpackage,
	batchCount: 0,
	// sessionCount: number,
	contentSize: 0,
	liveClassQuota: 0,
	liveClassType: '',
	// customChapter: number,
	// status: number,
	isActive: true,
	activeFrom: new Date(),
	activeTill: new Date(),
	// icon: string,
	details: '',
	paymentPlans: [
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'MONTHLY',
			graceperiod: 0,
		},
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'QUARTERLY',
			graceperiod: 0,
		},
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'HALFYEARLY',
			graceperiod: 0,
		},
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'LUMPSUM',
			graceperiod: 0,
		}
	],
	additionalCharges: {
		feeName: '',
		totalprice: 0,
		discount: 0,
		discountCap: 0,
		paymentplan: '',
	}
}];

export const viewPackageInitData = {
	name: '',
	planId: '',
	perstudentcost: 0,
	convenienceFee: 0,
	graceperiod: 0,
	recordingQuota: 0,
	// courseCount: number,
	studentCount: 0,
	// tutorCount: userPackage,
	// adminCount: userPackage,
	batchCount: 0,
	// sessionCount: number,
	contentSize: 0,
	liveClassQuota: 0,
	liveClassType: '',
	// customChapter: number,
	// status: number,
	isActive: true,
	activeFrom: new Date(),
	activeTill: new Date(),
	// icon: string,
	details: '',
	paymentPlans: [
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'MONTHLY',
			graceperiod: 0,
		},
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'QUARTERLY',
			graceperiod: 0,
		},
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'HALFYEARLY',
			graceperiod: 0,
		},
		{
			totalprice: 0,
			discount: 0,
			discountCap: 0,
			paymentplan: 'PREPAID',
			paymentcycle: 'LUMPSUM',
			graceperiod: 0,
		}
	],
	additionalCharges: {
		feeName: '',
		totalprice: 0,
		discount: 0,
		discountCap: 0,
		paymentplan: '',
	}
}

export const courseDetails = 
	[
		{
			board: "CBSE",
			className: "Class X",
			subject: "Mathematics",
		},
		{
			board: "CBSE",
			className: "Class X",
			subject: "Science",
		}
	]

