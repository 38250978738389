import axios from 'axios'
import { attendanceReport, liveClassesResponse } from './interfaces'

const BASE_URL = process.env.REACT_APP_API + "admin/"
const LIVE_CLASSES = BASE_URL + "liveClasses"
const ATTENDANCE_REPORT = BASE_URL + "attendanceReport"

export const fetchLiveClasses = async (params: {startDate: Date, endDate: Date}) => {
    const response = await axios.get<{data: liveClassesResponse[]}>(LIVE_CLASSES, {params})
    return response.data.data
}

export const fetchAttendanceReport = async (params: {meetingID: string}) => {
    const response = await axios.get<{data: attendanceReport[]}>(ATTENDANCE_REPORT, {params})
    return response.data.data
}