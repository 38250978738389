
import React, { FunctionComponent, useState, useEffect } from 'react';
import { Badge, FormHelperText, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { fontOptions } from '../../../theme';
import Button from '../../common/components/form_elements/button';
import CancelIcon from '@material-ui/icons/Cancel';
import ReactPlayer from 'react-player';
import { URL_PATTERN, YOUTUBE_LINK_PATTERN } from '../../common/validations/patterns';
import { ChoiceQuesType, ImageObject, MatchQuesType } from '../contracts/qb_interface';
//@ts-ignore
import { parse, HtmlGenerator } from 'latex.js'
import { capitalize, filter, includes, truncate } from 'lodash';
import MathEditor from './mathTextEditor/mathEditor';
import PreviewModal from './previewModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: '100%',
    },
    addAssi: {
      '& button': {
        padding: '10px 30px 10px 30px',
      },
    },
    latex: {
      '& p': {
        // marginTop: '0px',
      },
    },
    root: {
      border: '1px solid #4C8BF5',
    },
    customBadge : {
      backgroundColor: "#80C12C",
      color: "white"
    }
  }),
);

interface Props {
  clearQval: boolean;
  addMatchQuestionToList: (ques: MatchQuesType) => void
  updateMatchQuestionToList: (ques: MatchQuesType) => void
  qTBEStateM: MatchQuesType | null
  questionComplexity: "" | "medium" | "easy" | "high"
}

interface AllImages {
  question?: ImageObject,
  solution?: ImageObject,
  option1?: ImageObject,
  option2?: ImageObject,
  option3?: ImageObject,
  option4?: ImageObject,
  option5?: ImageObject,
  option6?: ImageObject
}

const MatchQuestion: FunctionComponent<Props> = ({clearQval, addMatchQuestionToList, updateMatchQuestionToList, qTBEStateM, questionComplexity}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { errors, setError, clearError } = useForm<FormData>();

  const [isapiLoad, setisapiLoad] = useState(false)
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState(0)
  const [solution, setSolution] = useState('')
  const [solutionlink, setSolutionlink] = useState('')
  const [option, setOption] = useState<string[]>(["Option1", "Option2"])
  const [list1, setList1] = useState<string[]>(["Option-A:", "Option-B:"])
  const [list2, setList2] = useState<string[]>(["Option-I:", "Option-II:"])
  const [reset, setReset] = useState(false)
  const [previewData, setPreviewData] = useState<MatchQuesType | null>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [allImages, setAllImages] = useState<AllImages>({question: undefined, solution: undefined, option1: undefined, option2: undefined, option3: undefined, option4: undefined, option5: undefined, option6: undefined})


  const latexParserCheck =  (latexString:String) =>{
    let generator = new HtmlGenerator({ hyphenate: false })
    let doc = parse(latexString, { generator: generator }).htmlDocument()
    return doc.documentElement.outerHTML
  }

  const ifValidLatex = (latexString:string) => {
    let result;
    try {
      const parser = latexParserCheck(latexString);
      result = true
    } catch (error) {
      result = false
    }

    return result
  }

  const latexParser =  (latexString:string) =>{
    let latexStg = latexString
    if(!ifValidLatex(latexStg)) {
      latexStg = '$' + latexStg + '$'
    }
    let generator = new HtmlGenerator({ hyphenate: false })
    let doc = parse(latexStg, { generator: generator }).htmlDocument()
    return doc.documentElement.outerHTML
  }

  useEffect(() => {
    clearAllVal()
  },[clearQval])

  useEffect(() => {
    if(qTBEStateM !== null) {
      setQuestion(qTBEStateM.question)
      setSolution(qTBEStateM.solution ? qTBEStateM.solution : '')
      setSolutionlink(qTBEStateM.solutionlink ? qTBEStateM.solutionlink : '')
      setOption(qTBEStateM.option)
      setList1(qTBEStateM.list1)
      setList2(qTBEStateM.list2)
      setAnswer(Number(qTBEStateM.answer[0]) - 1)
      if(qTBEStateM.imageLinks && qTBEStateM.imageLinks.length > 0) {
        const newImageSet = {...allImages}

        qTBEStateM.imageLinks.map(imLink => {
          const firstLetter = imLink.filename.charAt(0);

          switch(firstLetter) {
            case 'q':
              newImageSet.question = imLink
              break;
            case 's':
              newImageSet.solution = imLink
              break;
            case '1':
              newImageSet.option1 = imLink
              break;
            case '2':
              newImageSet.option2 = imLink
              break;
            case '3':
              newImageSet.option3 = imLink
              break;
            case '4':
              newImageSet.option4 = imLink
              break;
            case '5':
              newImageSet.option5 = imLink
              break;
            case '6':
              newImageSet.option6 = imLink
              break;
          }
        })

        setAllImages(newImageSet)
      }

      setReset(prev => !prev)
    }
  },[qTBEStateM])

  const clearAllVal = () => {
    setQuestion('')
    setSolution('')
    setSolutionlink('')
    setOption(["Option1", "Option2"])
    setList1(["Option-A:", "Option-B:"])
    setList2(["Option-I:", "Option-II:"])
    setAnswer(0)
    setAllImages({question: undefined, solution: undefined, option1: undefined, option2: undefined, option3: undefined, option4: undefined, option5: undefined, option6: undefined})
    setReset(prev => !prev)
  }

  const addQuestion = () => {
    if(questionComplexity === '') {
      enqueueSnackbar('Please Select Question Complexity', {variant: 'warning'});
      return
    }

    if(question.length === 0) {
      enqueueSnackbar('Please Add Question Description', {variant: 'warning'});
      return
    }

    if(!ifValidLatex(question)) {
      enqueueSnackbar('Question is not Valid', {variant: 'warning'});
      return
    }

    if(option.some(opt => opt.length === 0)) {
      enqueueSnackbar('Please fill All Options', {variant: 'warning'});
      return
    }

    if(filter(option, (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
      enqueueSnackbar('Please remove Duplicates in Option', {variant: 'warning'});
      return
    }

    if(option.some(opt => !ifValidLatex(opt))) {
      enqueueSnackbar('Some option is not Valid', {variant: 'warning'});
      return
    }

    if(solution && solution.length > 0 && !ifValidLatex(solution)) {
      enqueueSnackbar('Solution is not Valid', {variant: 'warning'});
      return
    }

    if(list1.some(opt => opt.length === 0)) {
      enqueueSnackbar('Please fill All Boxes in Column 1', {variant: 'warning'});
      return
    }

    if(filter(list1, (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
      enqueueSnackbar('Please remove Duplicates in Column 1', {variant: 'warning'});
      return
    }

    if(list1.some(opt => !ifValidLatex(opt))) {
      enqueueSnackbar('Some option in Column 1 is not Valid', {variant: 'warning'});
      return
    }

    if(list2.some(opt => opt.length === 0)) {
      enqueueSnackbar('Please fill All Boxes in Column 2', {variant: 'warning'});
      return
    }

    if(filter(list2, (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
      enqueueSnackbar('Please remove Duplicates in Column 2', {variant: 'warning'});
      return
    }

    if(list2.some(opt => !ifValidLatex(opt))) {
      enqueueSnackbar('Some option in Column 2 is not Valid', {variant: 'warning'});
      return
    }

    if(solutionlink.length !== 0 && !solutionlink.match(URL_PATTERN)) {
      enqueueSnackbar('Solution link not valid', {variant: 'warning'});
      return
    }

    let singleQues: MatchQuesType = {
      question: question.replaceAll('<br>','\\\\'),
      solution: (solution && solution.length > 0) ? solution.replaceAll('<br>','\\\\') : '',
      option: option.map(op => op.replaceAll('<br>','\\\\')),
      list1: list1.map(op => op.replaceAll('<br>','\\\\')),
      list2: list2.map(op => op.replaceAll('<br>','\\\\')),
      solutionlink: (solutionlink.length > 0) ? solutionlink : '',
      answer: [String(answer + 1)]
    }

    const uploadImg = Object.entries(allImages).map(mp => mp[1]).filter(el => el)

    if(uploadImg.length > 0) {
      const list: ImageObject[] = uploadImg.map(ui => {
        return {
          filename: ui.filename,
          encoding: ui.encoding
        }
      })

      singleQues = {...singleQues, ...{imageLinks: list}}
    }

    addMatchQuestionToList(singleQues)
  }

  const updateQuestion = () => {
    if(questionComplexity === '') {
      enqueueSnackbar('Please Select Question Complexity', {variant: 'warning'});
      return
    }

    if(question.length === 0) {
      enqueueSnackbar('Please Add Question Description', {variant: 'warning'});
      return
    }

    if(!ifValidLatex(question)) {
      enqueueSnackbar('Question is not Valid', {variant: 'warning'});
      return
    }

    if(option.some(opt => opt.length === 0)) {
      enqueueSnackbar('Please fill All Options', {variant: 'warning'});
      return
    }

    if(filter(option, (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
      enqueueSnackbar('Please remove Duplicates in Option', {variant: 'warning'});
      return
    }

    if(option.some(opt => !ifValidLatex(opt))) {
      enqueueSnackbar('Some option is not Valid', {variant: 'warning'});
      return
    }

    if(solution && solution.length > 0 && !ifValidLatex(solution)) {
      enqueueSnackbar('Solution is not Valid', {variant: 'warning'});
      return
    }

    if(list1.some(opt => opt.length === 0)) {
      enqueueSnackbar('Please fill All Boxes in Column 1', {variant: 'warning'});
      return
    }

    if(filter(list1, (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
      enqueueSnackbar('Please remove Duplicates in Column 1', {variant: 'warning'});
      return
    }

    if(list1.some(opt => !ifValidLatex(opt))) {
      enqueueSnackbar('Some option in Column 1 is not Valid', {variant: 'warning'});
      return
    }

    if(list2.some(opt => opt.length === 0)) {
      enqueueSnackbar('Please fill All Boxes in Column 2', {variant: 'warning'});
      return
    }

    if(filter(list2, (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
      enqueueSnackbar('Please remove Duplicates in Column 2', {variant: 'warning'});
      return
    }

    if(list2.some(opt => !ifValidLatex(opt))) {
      enqueueSnackbar('Some option in Column 2 is not Valid', {variant: 'warning'});
      return
    }

    if(solutionlink.length !== 0 && !solutionlink.match(URL_PATTERN)) {
      enqueueSnackbar('Solution link not valid', {variant: 'warning'});
      return
    }

    let singleQues: MatchQuesType = {
      question: question.replaceAll('<br>','\\\\'),
      solution: (solution && solution.length > 0) ? solution.replaceAll('<br>','\\\\') : '',
      option: option.map(op => op.replaceAll('<br>','\\\\')),
      list1: list1.map(op => op.replaceAll('<br>','\\\\')),
      list2: list2.map(op => op.replaceAll('<br>','\\\\')),
      solutionlink: (solutionlink.length > 0) ? solutionlink : '',
      answer: [String(answer + 1)]
    }

    const uploadImg = Object.entries(allImages).map(mp => mp[1]).filter(el => el)

    if(uploadImg.length > 0) {
      const list: ImageObject[] = uploadImg.map(ui => {
        return {
          filename: ui.filename,
          encoding: ui.encoding
        }
      })

      singleQues = {...singleQues, ...{imageLinks: list}}
    }

    updateMatchQuestionToList(singleQues)
    enqueueSnackbar('Updated Question', {variant: 'success'});
  }

  const addImages = (data: FileList, type: 'question' | 'solution' | 'option1' | 'option2' | 'option3' | 'option4' | 'option5' | 'option6') => {
    const dataName = data[0].name
    const last3 = dataName.substring(dataName.length - 3)
    const last4 = dataName.substring(dataName.length - 4)
    
    const extentionCheck = (last3 === 'png' || last3 === 'jpg' || last4 === 'jpeg') ? true : false
    if(extentionCheck) {
      const fsize = data[0].size;
      const file = Math.round(fsize / 1024);
      if (file >= 300) {
        enqueueSnackbar('File too Big, please select a file less than 300kb', {
          variant: 'warning'
        });
      } else {
        getEncoded(data[0], (result: any) => {
          
          const newImageSet = {...allImages}
          var continueAhead = true
          if(type!='question' && type!='solution'){
            Object.keys(newImageSet).map(key=>{
              // console.log(key!='solution' && key!='question')
              if(key!='solution' && key!='question'){
                //@ts-ignore
                if(newImageSet[key]){
                  //@ts-ignore
                  if(allImages[key].encoding==result){
                    // console.log(key)
                    enqueueSnackbar('Same image is used in one of the other options',{variant:"warning"})
                    continueAhead = false
                  }
                }
              }
            })
          }
          if(!continueAhead) return ;
          let fileName = ''
          switch(type) {
            case 'question':
              fileName = 'q' + dataName
              break;
            case 'solution':
              fileName = 's' + dataName
              break;
            case 'option1':
              fileName = '1' + dataName
              break;
            case 'option2':
              fileName = '2' + dataName
              break;
            case 'option3':
              fileName = '3' + dataName
              break;
            case 'option4':
              fileName = '4' + dataName
              break;
            case 'option5':
              fileName = '5' + dataName
              break;
            case 'option6':
              fileName = '6' + dataName
              break;
          }

          const newImage: ImageObject = {
            filename: fileName,
            encoding: result
          }

          newImageSet[type] = newImage

          setAllImages(newImageSet)
          enqueueSnackbar('Image successfully added', {variant: 'success'});
        })
      }
    } else {
      enqueueSnackbar('Please Upload Images in PNG, JPG or JPEG format', {variant: 'warning'});
    }
  }

  const getEncoded = (file: File, callback: any) => {
    const img = new Image();
    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      if (ctx instanceof CanvasRenderingContext2D) {
        ctx.drawImage(img, 0, 0);
        var imgData=ctx.getImageData(0,0,canvas.width,canvas.height);
        var data=imgData.data;
        for(var i=0;i<data.length;i+=4){
            if(data[i+3]<255){
                data[i]=255;
                data[i+1]=255;
                data[i+2]=255;
                data[i+3]=255;
            }
        }
        ctx.putImageData(imgData,0,0);
      }

      const retImg = canvas.toDataURL('image/jpeg', 0.1)
      callback(retImg)
    };
    img.src = URL.createObjectURL(file);
  }

  const removeImage = (type: 'question' | 'solution' | 'option1' | 'option2' | 'option3' | 'option4' | 'option5' | 'option6') => {
    const newImageSet = {...allImages}
    newImageSet[type] = undefined
    setAllImages(newImageSet)
    enqueueSnackbar('Image successfully removed', {variant: 'success'});
  }

  const showPreview = () => {
    if(questionComplexity === '') {
      enqueueSnackbar('Please Select Question Complexity', {variant: 'warning'});
      return
    }

    if(question.length === 0) {
      enqueueSnackbar('Please Add Question Description', {variant: 'warning'});
      return
    }

    if(!ifValidLatex(question)) {
      enqueueSnackbar('Question is not Valid', {variant: 'warning'});
      return
    }

    if(option.some(opt => opt.length === 0)) {
      enqueueSnackbar('Please fill All Options', {variant: 'warning'});
      return
    }

    if(filter(option, (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
      enqueueSnackbar('Please remove Duplicates in Option', {variant: 'warning'});
      return
    }

    if(option.some(opt => !ifValidLatex(opt))) {
      enqueueSnackbar('Some option is not Valid', {variant: 'warning'});
      return
    }

    if(solution && solution.length > 0 && !ifValidLatex(solution)) {
      enqueueSnackbar('Solution is not Valid', {variant: 'warning'});
      return
    }

    if(list1.some(opt => opt.length === 0)) {
      enqueueSnackbar('Please fill All Boxes in Column 1', {variant: 'warning'});
      return
    }

    if(filter(list1, (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
      enqueueSnackbar('Please remove Duplicates in Column 1', {variant: 'warning'});
      return
    }

    if(list1.some(opt => !ifValidLatex(opt))) {
      enqueueSnackbar('Some option in Column 1 is not Valid', {variant: 'warning'});
      return
    }

    if(list2.some(opt => opt.length === 0)) {
      enqueueSnackbar('Please fill All Boxes in Column 2', {variant: 'warning'});
      return
    }

    if(filter(list2, (val, i, iteratee) => includes(iteratee, val, i + 1)).length > 0) {
      enqueueSnackbar('Please remove Duplicates in Column 2', {variant: 'warning'});
      return
    }

    if(list2.some(opt => !ifValidLatex(opt))) {
      enqueueSnackbar('Some option in Column 2 is not Valid', {variant: 'warning'});
      return
    }

    if(solutionlink.length !== 0 && !solutionlink.match(URL_PATTERN)) {
      enqueueSnackbar('Solution link not valid', {variant: 'warning'});
      return
    }

    let singleQues: MatchQuesType = {
      question: question.replaceAll('<br>','\\\\'),
      solution: (solution && solution.length > 0) ? solution.replaceAll('<br>','\\\\') : '',
      option: option.map(op => op.replaceAll('<br>','\\\\')),
      list1: list1.map(op => op.replaceAll('<br>','\\\\')),
      list2: list2.map(op => op.replaceAll('<br>','\\\\')),
      solutionlink: (solutionlink.length > 0) ? solutionlink : '',
      answer: [String(answer + 1)]
    }

    const uploadImg = Object.entries(allImages).map(mp => mp[1]).filter(el => el)

    if(uploadImg.length > 0) {
      const list: ImageObject[] = uploadImg.map(ui => {
        return {
          filename: ui.filename,
          encoding: ui.encoding
        }
      })

      singleQues = {...singleQues, ...{imageLinks: list}}
    }

    setPreviewData(singleQues)
    setOpenModal(true)
  }

  if(isapiLoad) {
    return <div>

    </div>
  }

  const abcdOptions = ['A', 'B', 'C', 'D', 'E', 'F']
  const romanOptions = ['I', 'II', 'III', 'IV', 'V', 'VI']

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={8}>
            <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Question</Typography>
          </Grid>
          <Grid item xs={4}>
          </Grid>
        </Grid>
        <MathEditor latex={question} setLatex={setQuestion} reset={reset} addImages={
            <React.Fragment>
              <input
                style={{display: 'none'}}
                type="file"
                accept=".jpg, .jpeg, .png"
                id="uploadMAQ-button"
                onChange={(e) => {
                  var newFiles = e.target.files
                  addImages(newFiles as FileList, 'question')
                  e.target.value = ''
                }}
              />
              <label htmlFor="uploadMAQ-button">
                <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}}>
                  {
                    (allImages.question === undefined) ? 'Add Image' : 'Replace Image'
                  }
                </Typography>
              </label>
            </React.Fragment>
          }  
        />
      </Grid>

      {(allImages.question !== undefined && allImages.question && allImages.question.filename) &&
        <Grid item xs={12} style={{marginTop: '30px'}}>
          <img src={allImages.question.encoding} style={{borderRadius:'5px', height: '140px'}} />
          <CancelIcon
            style={{position:'relative', bottom:'127px', right:'13px', color:'crimson', cursor: 'pointer'}}
            onClick={() => removeImage('question')}
          />
        </Grid>
      }

      <Grid item xs={12} style={{marginTop: '30px'}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Column 1</Typography>
            {list1.map((opt, ind) => {
              return (
                <MathEditor reset={reset} latex={opt} setLatex={(e) => {
                    if(list1[ind] !== e) {
                      setList1(prev => {
                        const allOpt = [...prev]
                        allOpt[ind] = e;
                        return allOpt
                      })
                    }
                  }}
                  endAdornment={
                    <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}}
                      onClick={() => {
                        const allOpt = [...list1]
                        if(allOpt.length < 3) {
                          enqueueSnackbar('There should be atleast two Options in Column 1', {variant: 'warning'});
                        } else {
                          allOpt.splice(ind, 1)
                          setList1(allOpt)
                          setReset(prev => !prev)
                        }
                      }}
                    >
                      Remove Option
                    </Typography>
                  }
                  style={ind > 0 ? {marginTop: '20px'} : {}}
                />
              )
            })}
            {(list1.length <= 5) &&
              <Button style={{width: '100%', marginTop: '15px', height: '50px', border: '1px dashed #4C8BF5'}} color="primary" disableElevation
                onClick={() => {
                  const allOpt = [...list1]
                  allOpt.push('Option-' + abcdOptions[allOpt.length] + ':')
                  setList1(allOpt)
                }}
              >
                + Add Option
              </Button>
            }
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Column 2</Typography>
            {list2.map((opt, ind) => {
              return (
                <MathEditor reset={reset} latex={opt} setLatex={(e) => {
                    if(list2[ind] !== e) {
                      setList2(prev => {
                        const allOpt = [...prev]
                        allOpt[ind] = e;
                        return allOpt
                      })
                    }
                  }}
                  endAdornment={
                    <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}}
                      onClick={() => {
                        const allOpt = [...list2]
                        if(allOpt.length < 3) {
                          enqueueSnackbar('There should be atleast two Options in Column 2', {variant: 'warning'});
                        } else {
                          allOpt.splice(ind, 1)
                          setList2(allOpt)
                          setReset(prev => !prev)
                        }
                      }}
                    >
                      Remove Option
                    </Typography>
                  }
                  style={ind > 0 ? {marginTop: '20px'} : {}}
                />
              )
            })}
            {(list2.length <= 5) &&
              <Button style={{width: '100%', marginTop: '15px', height: '50px', border: '1px dashed #4C8BF5'}} color="primary" disableElevation
                onClick={() => {
                  const allOpt = [...list2]
                  allOpt.push('Option-' + romanOptions[allOpt.length] + ':')
                  setList2(allOpt)
                }}
              >
                + Add Option
              </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      
      <Grid item xs={12} style={{marginTop: '30px'}}>
        <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Options</Typography>
        {option.map((opt, ind) => {
          const uploadBtnName = "uploadMAO" + String(ind + 1) + "-button"
          const optionParam = `option${String(ind + 1)}`
          //@ts-ignore
          const optImg = allImages[optionParam]
          return (
            <div key={uploadBtnName}>
              <MathEditor reset={reset} latex={opt} setLatex={(e) => {
                  if(option[ind] !== e) {
                    setOption(prev => {
                      const allOpt = [...prev]
                      allOpt[ind] = e;
                      return allOpt
                    })
                  }
                }} 
                addImages={
                  <React.Fragment>
                    <input
                      style={{display: 'none'}}
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      id={uploadBtnName}
                      onChange={(e) => {
                        var newFiles = e.target.files
                        //@ts-ignore
                        addImages(newFiles as FileList, optionParam)
                        e.target.value = ''
                      }}
                    />
                    <label htmlFor={uploadBtnName}>
                      <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}}>
                        {
                          (optImg === undefined) ? 'Add Image' : 'Replace Image'
                        }
                      </Typography>
                    </label>
                  </React.Fragment>
                }
                endAdornment={
                  <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => {
                      const allOpt = [...option]
                      if(allOpt.length < 3) {
                        enqueueSnackbar('There should be atleast two Options for this Question', {variant: 'warning'});
                      } else {
                        setAnswer(0)
                        allOpt.splice(ind, 1)
                        setOption(allOpt)
                        setReset(prev => !prev)
                      }
                    }}
                  >
                    Remove Option
                  </Typography>
                }
                style={ind > 0 ? {marginTop: '20px'} : {}}
              />
              <FormHelperText>{'Sample: Option-A -> Option-II, Option-B -> Option-I'}</FormHelperText>

              {(optImg !== undefined && optImg && optImg.filename) &&
                <div style={{marginTop: '30px'}}>
                  <img src={optImg.encoding} style={{borderRadius:'5px', height: '140px'}} />
                  <CancelIcon
                    style={{position:'relative', bottom:'127px', right:'13px', color:'crimson', cursor: 'pointer'}}
                    //@ts-ignore
                    onClick={() => removeImage(optionParam)}
                  />
                </div>
              }
            </div>
          )
        })}
        {(option.length <= 5) &&
          <Button style={{width: '100%', marginTop: '15px', height: '50px', border: '1px dashed #4C8BF5'}} color="primary" disableElevation
            onClick={() => {
              const allOpt = [...option]
              allOpt.push('Option' + String(allOpt.length + 1))
              setOption(allOpt)
            }}
          >
            + Add Option
          </Button>
        }
      </Grid>
      <Grid item xs={12} style={{marginTop: '30px'}}>
        <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Answer</Typography>
        <Grid container spacing={2} style={{marginTop: '15px'}}>
          {option.map((opt, ind) => {
            return (
              <Grid item xs={4}>
                <Button color="primary" disableElevation
                  onClick={() => setAnswer(ind)} 
                  style={(ind === answer) ? {width: '100%', height: '50px', background: '#80C12C', color: "#FFFFFF", textTransform: 'none'} : {width: '100%', height: '50px', color: "#4C8BF5", border: "1px solid #4C8BF5", background: "#FFFFFF", textTransform: 'none'}}
                >
                  {'Option ' + abcdOptions[ind]}
                </Button>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{marginTop: '30px'}}>
        <Grid container>
          <Grid item xs={8}>
            <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Solution Description</Typography>
          </Grid>
          <Grid item xs={4}>
          </Grid>
        </Grid>
        <MathEditor reset={reset} latex={solution} setLatex={setSolution} addImages={
            <React.Fragment>
              <input
                style={{display: 'none'}}
                type="file"
                accept=".jpg, .jpeg, .png"
                id="uploadMAS-button"
                onChange={(e) => {
                  var newFiles = e.target.files
                  addImages(newFiles as FileList, 'solution')
                  e.target.value = ''
                }}
              />
              <label htmlFor="uploadMAS-button">
                <Typography style={{marginLeft: '10px', fontSize: fontOptions.size.small, color: '#4C8BF5', textDecoration: 'underline', cursor: 'pointer'}}>
                  {
                    (allImages.solution === undefined) ? 'Add Image' : 'Replace Image'
                  }
                </Typography>
              </label>
            </React.Fragment>
          }  
        />
      </Grid>

      {(allImages.solution !== undefined && allImages.solution && allImages.solution.filename) &&
        <Grid item xs={12} style={{marginTop: '30px'}}>
          <img src={allImages.solution.encoding} style={{borderRadius:'5px', height: '140px'}} />
          <CancelIcon
            style={{position:'relative', bottom:'127px', right:'13px', color:'crimson', cursor: 'pointer'}}
            onClick={() => removeImage('solution')}
          />
        </Grid>
      }

      <Grid item xs={12} style={{marginTop: '30px'}}>
        <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold}}>Solution Link</Typography>
        <TextField variant="outlined" style={{width: '100%', marginTop: '15px'}} placeholder="Paste Solution Link..." 
          value={solutionlink} onChange={(e) => {setSolutionlink(e.target.value)}}
          InputProps={{
            classes: {
              notchedOutline: classes.root
            }
          }}
        />
        {solutionlink && solutionlink.match(YOUTUBE_LINK_PATTERN) &&
          <ReactPlayer
            url={solutionlink}
            controls
            light
            style={{width: '100%', marginTop: '15px'}}
            width={'100%'}
          />
        }
      </Grid>
      <Grid item xs={12} style={{marginTop: '30px'}}>
        <span style={{float: 'right'}} className={classes.addAssi}>
          <Button style={{marginLeft: '15px'}} color="primary" disableElevation variant="outlined" 
            onClick={clearAllVal}
          >
            Clear
          </Button>
          <Button style={{marginLeft: '15px'}} color="primary" disableElevation variant="contained" 
            onClick={showPreview}
          >
            Preview
          </Button>
          <Button style={{marginLeft: '15px'}} color="primary" disableElevation variant="contained" 
            onClick={() => {
              if(qTBEStateM === null) {
                addQuestion()
              } else {
                updateQuestion()
              }
            }}
          >
            {qTBEStateM === null ? 'Add to List' : 'Update'}
          </Button>
        </span>
      </Grid>
      <PreviewModal
        question={previewData}
        openModal={openModal}
        onClose={() => {
          setOpenModal(false)
          setPreviewData(null)
        }}
      />
    </Grid>
  );
}

export default MatchQuestion;