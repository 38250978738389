export enum leadStatus {
    fresh = 'Fresh',
    closed = 'Closed',
    deferred = 'Deferred'
}

export interface leadUpdateReq {
    leadId: string,
    status: leadStatus,
    remarks: string
}