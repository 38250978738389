import React, { useState, useEffect, FunctionComponent } from "react";
import { Redirect } from 'react-router'
import {
	Box,
	Container,
	CssBaseline,
	darken,
	FormControl,
	Grid,
	IconButton,
	makeStyles,
	MenuItem,
	Select,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { compareAsc } from 'date-fns'
import { Edit, Visibility } from "@material-ui/icons";

import Button from "../../common/components/form_elements/button";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import MiniDrawer from "../../common/components/SideDrawer";
import { fontOptions } from "../../../theme";
import { MASTER_PERMISSIONS } from "../../../utilities/constants";
import Datagrids, { datagridCellExpand } from "../../dashboard/components/dataGrid";
import { getLoggedInUserData, onApiError} from '../../common/helpers';
import { fetchLiveClasses } from "../api";
import { attendanceReport, liveClassesResponse, liveClassRow } from "../interfaces";
import ViewAttendanceReportModal from "../components/viewAttendanceReportModal";

const useStyles = makeStyles({
	typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121',
	  },
      label: {
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.small,
      },
});

const ViewLiveClasses: FunctionComponent = () => {
    const classes = useStyles();

    const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().setHours(0,0,0,0)))
    const [endDate, setEndDate] = useState<Date | null>(new Date(new Date().setHours(23, 59, 59, 999)))

    const [showDataGrid, setShowDataGrid] = useState(false)
    const [gridRows, setGridRows] = useState<liveClassRow[]>([])
    const [gridColumns, setGridColumns] = useState<GridColDef[]>([])

    const [attendanceModal, setAttendanceModal] = useState(false)
    const [selectedMeetingID, setSelectedMeetingID] = useState('')

    const [redirectTo, setRedirectTo] = useState<string>('')
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        getLoggedInUserData(setRedirectTo, [MASTER_PERMISSIONS.liveClasses], enqueueSnackbar)
    }, [])

    if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

    const handleDateChange = (d: Date, dateType: string) => {
        if(dateType === 'start' && d) {
            d.setHours(0,0,0,0)
            setStartDate(d)
        }
        if(dateType === 'end' && d) {
            d.setHours(23, 59, 59, 999)
            setEndDate(d)
        }
    }

    const handleFetchLiveClasses = async () => {
        if(!startDate || !endDate) {
            enqueueSnackbar('Select valid Dates', {variant: 'warning'})
            return;
        }
        try {
            const liveClasses = await fetchLiveClasses({startDate, endDate})
            createDataGrid(liveClasses)
            setShowDataGrid(true)
        }
        catch (err) {
            onApiError(err, enqueueSnackbar, setRedirectTo)
        }
    }

    const createDataGrid = (list: liveClassesResponse[]) => {
		function createData(liveClass: liveClassesResponse, i: number) {
			const { attendedStudents, totalMeetingTimeMins, totalStudents, tutorName, meetingID, date, endTime, startTime, subjectname } = liveClass
			return {
				attendedStudents, totalMeetingTimeMins, totalStudents, tutorName, id: i+1, meetingID, date, endTime, startTime, subjectname
			}
		}

		const rows: liveClassRow[] = list.map((cur: liveClassesResponse, i: number) => createData(cur, i))

		setGridRows(rows)

		const columns = [
			{ field: 'id', headerName: 'S.No', flex: 0.5 },
			{ field: 'tutorName', headerName: 'Tutor', flex: 1.2, width: 100, renderCell: datagridCellExpand },
            { field: 'date', headerName: 'Date', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'startTime', headerName: 'From', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'endTime', headerName: 'To', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'totalMeetingTimeMins', headerName: 'Duration (mins)', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'subjectname', headerName: 'Subject', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'totalStudents', headerName: 'Total Students', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'attendedStudents', headerName: 'Attended', flex: 1, width: 100, renderCell: datagridCellExpand },
			{
				field: 'action', headerName: 'Action', flex: 1,
				disableClickEventBubbling: true,
				renderCell: (params: GridCellParams) => {
					const selectedRow = {
						id: params.getValue("id") as number,
						tutorName: params.getValue("tutorName") as string
					}

					const selectedRowDetails = rows.find((row, index) => {
						return (row.tutorName === selectedRow.tutorName && index === (selectedRow.id - 1))
					})

                    const buttonData = [
                        {
                            title: 'View Attendance',
                            action: viewAttendance,
                            icon: <Visibility />
                        },
                    ];

					const buttonSet = buttonData.map((button: any, index: any) => {
						return (
							<Tooltip key={index} title={button.title}>
								<IconButton
									onClick={() => {
										button.action(selectedRowDetails);
									}}
									size="small"
								>
									{button.icon}
								</IconButton>
							</Tooltip>
						);
					})

					return <div>{buttonSet}</div>;
				}
			}
		]
		setGridColumns(columns)
	}

    const viewAttendance = (row: liveClassRow) => {
        setAttendanceModal(true)
        setSelectedMeetingID(row.meetingID)
    }

    const renderDateInput = (key: string, value: any, setter: any, dateType: string) => (
        <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
                <Box className={classes.label}>
                    {key}
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl fullWidth margin="normal">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={key}
                            value={value}
                            onChange={(d) => setter(d, dateType)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
            </Grid>
        </Grid>
    )

    const renderButton = (name: string, cb: any) => (
        <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item>
                <Button variant="contained" color="primary" size="medium"
                    onClick={cb}
                >
                    {name}
                </Button>
            </Grid>
        </Grid>
    )

    return(
        <>
        <CssBaseline />
        <MiniDrawer>
        {selectedMeetingID.length > 1 && <ViewAttendanceReportModal
            onClose={() => {
                setAttendanceModal(false)
                setSelectedMeetingID('')
            }}
            meetingID={selectedMeetingID}
            openModal={attendanceModal}
        />}
        <Container maxWidth="lg">
            <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="5px"
            color='#fff'
            >
            <Grid item container>
                <Grid item sm={8}>
                <Box style={{height: '100%'}}>
                    <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                    >
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            View Live Classes
                        </Typography>
                        <Typography>
                            View Live Classes datas and Attendance Reports
                        </Typography>
                    </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            </Box>

            <Box
				bgcolor="#ffffff"
				borderRadius="5px"
				padding="12px 25px"
				marginTop='25px'
			>
                <Grid container justify='center'>
                    <Grid item xs={12} lg={6} style={{paddingTop: '5px', marginBottom: '8px'}}>
                        {renderDateInput('Start Date', startDate, handleDateChange, 'start')}
                        {renderDateInput('End Date', endDate, handleDateChange, 'end')}
                        {renderButton('Fetch Live Classes', handleFetchLiveClasses)}
                    </Grid>
                    <Grid item xs={12}>
                        {showDataGrid && <Datagrids gridRows={gridRows} gridColumns={gridColumns} disableCheckbox />}
                    </Grid>
                </Grid>
            </Box>   
        </Container>
        </MiniDrawer>
        </>
    )
}

export default ViewLiveClasses;