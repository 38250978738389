import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link, Redirect, RouteComponentProps } from 'react-router-dom'
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../utilities/constants';
import * as XLSX from 'xlsx';
import MiniDrawer from "../../common/components/SideDrawer"
import { Box, Button, Container, CssBaseline, darken, FormControl, FormHelperText, Grid, makeStyles, MenuItem, Typography, Select, CircularProgress } from '@material-ui/core';
import { fontOptions } from '../../../theme';
import Dropzone from '../../common/components/dropzone/dropzone';
import { downloadSortedImg } from '../api';
import axios from 'axios';

const useStyles = makeStyles({
	typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121',
	  },
});

function masterChapters(
	boardID: number,
    boardName: string, 
    boardDescriptions: string,
    classID: number,
    className: string, 
    subjectID: number,
	subjectName: string, 
	chapter: {
        name: string,
        topic: [string]
    },
  ) {
	return {boardID, boardName, boardDescriptions, classID, className, subjectID, subjectName, chapter};
}
      
interface Props extends RouteComponentProps {

}

const ImageSorter: FunctionComponent<Props> = ({ location }) => {
	const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
    const [imageLinks, setImageLinks] = useState<string[] | null>(null)
    const [imageNames, setImageNames] = useState<string[] | null>(null)
	const [loading, setLoading] = useState(false)

    useEffect(() => {
        const filteredArr: string[] = [];
        imageNames?.map(el => {
            if(el !== "") filteredArr.push(el)
        })
        downloadSortedImg(filteredArr).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Images.zip'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
    }, [imageNames])

    useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		const requiredPermissions = [MASTER_PERMISSIONS.imagesorter]
		var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

    const readExcel = (file: File | null) => {
		const promise = new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			if (file) {
				fileReader.readAsArrayBuffer(file);
				fileReader.onload = (e) => {
					const bufferArray = e.target ? e.target.result : '';
					const wb = XLSX.read(bufferArray, { type: 'buffer' });
					const wsname = wb.SheetNames[0];
					const ws = wb.Sheets[wsname];

					/* Convert array to json*/
					const jsonData = XLSX.utils.sheet_to_json(ws);
					resolve(jsonData);
				};
				fileReader.onerror = (error) => {
					reject(error);
				};
			}
		});
		promise.then(async (dataArr: any) => {
			const uploadReq: any = await Promise.all(
				dataArr &&
				dataArr.map(async (data: any) => {
					return {
						imageLinksData: data["Image_Links"],
					}
				})
			);
            var filteredData: string[] = [];
            // @ts-ignore
            uploadReq.map(el => {
                if(el.imageLinksData) {
                    var commaseperatedArr = el.imageLinksData.split(',')
                    filteredData = [...filteredData, ...commaseperatedArr]
                }
            })
            setImageNames(filteredData)
		});
	};

	if(loading) return <Box height="100vh" display="flex" justifyContent="center" alignItems="center"><CircularProgress /></Box>

    return (
        <>
            <CssBaseline />
            <MiniDrawer>
			<Container maxWidth="lg">
				<Box
				bgcolor="#4C8BF5"
				padding="20px 30px"
				marginBottom="30px"
				position="relative"
				borderRadius="5px"
				color='#fff'
				>
				<Grid item container>
					<Grid item sm={8}>
					<Box style={{height: '100%'}}>
						<Grid
						container
						direction="row"
						alignItems="center"
						justify="center"
						style={{ height: '100%' }}
						>
						<Grid item xs={12}>
							<Typography className={classes.title}>
							    Image Sorter Utility
							</Typography>
							<Typography>
							    Upload excel fetch all images
							</Typography>
						</Grid>
						</Grid>
					</Box>
					</Grid>
				</Grid>
				</Box>

				<Box
				bgcolor="#ffffff"
				borderRadius="5px"
				padding="12px 25px"
				marginTop='25px'
				>
					<Grid container>
						<Grid item xs={12} style={{paddingTop: '5px', marginBottom: '8px'}}>
						<Dropzone
							filesLimit={1}
							dropzoneParagraphClass="Please upload excel File - XLS XLSX only"
							acceptedFiles={[
							".csv",
							"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
							"application/vnd.ms-excel",
							]}
							onChange={(files: File[]) => {
								readExcel(files[0]);
							}}
						/>
						</Grid>
					</Grid>
				</Box>
			</Container>
            </MiniDrawer>
        </>   
    )
}

export default ImageSorter
