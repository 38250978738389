import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { setAuthToken, setRefreshToken, } from '../actions/authActions';

const INITIAL_AUTH_TOKEN = '';
const authToken = createReducer(INITIAL_AUTH_TOKEN, {
	[setAuthToken.type]: (_, action) => action.payload,
});

const INITIAL_REFRESH_TOKEN = '';
const refreshToken = createReducer(INITIAL_REFRESH_TOKEN, {
	[setRefreshToken.type]: (_, action) => action.payload,
});


export const authReducer = combineReducers({
	authToken,
	refreshToken,
});
