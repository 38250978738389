import { USER_ROLES } from "../../utilities/constants";

export const convertDateString = (dateString: string) => {
    var dateParts: any = dateString.split("/");
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
}

export const convertDate = (d: Date) => {
    function pad(s: any) { return (s < 10) ? '0' + s : s; }
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
}

export const convertToBase64 = async (file: File) => {
    let base64 = ''
    base64 = await new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
            // Create canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            // Set width and height
            canvas.width = img.width;
            canvas.height = img.height;
            // Draw the image
            if (ctx instanceof CanvasRenderingContext2D) {
                ctx.drawImage(img, 0, 0);
            }
            resolve(canvas.toDataURL('image/jpeg'))
        };
        img.onerror = (e) => reject(e)
        img.src = URL.createObjectURL(file)
    })
    return base64
}

export const getLoggedInUserData = async (setRedirectTo: any, requiredPermissions: string[], enqueueSnackbar: any, onUseEffect?: any) => {
    if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
        setRedirectTo('/set-password')
    }
    var allowedPermissions: string[] = [];
    var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
    var loginUserType = '';
    if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
        allowedPermissions = loginUserData.adminRole.permissions;
        loginUserType = loginUserData.adminRole.name;
    }
    var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
    if (!hasEligibility) {
        enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
        setRedirectTo('/dashboard');
    } else {
        if(onUseEffect) onUseEffect()
    }
}

export const toTitleCase = (str: string) => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
}

// @ts-ignore
export const onApiError = (error, enqueueSnackbar, setRedirectTo?) => {
    enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
    if (error.response?.status === 401 && setRedirectTo) {
        setRedirectTo('/login');
    }
}