import { FunctionComponent, useEffect, useState } from "react";
import React from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  CssBaseline,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import Button from "../../common/components/form_elements/button";
import MiniDrawer from "../../common/components/SideDrawer";
import { deleteAllAppLogs, getAllAppLogs } from "../api";
import { CustomUser } from "../../common/contracts/dashboard";
import { RootState } from "../../redux/store";
import { DataGrid, GridColumns, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/styles";
import { datagridCellExpand } from "../../dashboard/components/dataGrid";

interface Props extends RouteComponentProps {}

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "#d47483",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
  },
  paginate: {
    display: "flex",
  },
});

const ViewAppLogs: FunctionComponent<Props> = ({ history, location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [redirectTo, setRedirectTo] = useState<string>("");
  const classes = useStyles();

  const [allLogs, setAllLogs] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);

  const fetchAllAppLogs = async () => {
    try {
      const res = await getAllAppLogs();
      const data = res?.logData;
      // console.log("data:", data);
      setAllLogs(data);
    } catch (error) {
      console.error(error);
      if (
        error.response?.status === 401 &&
        error.response?.data.message !== "TokenExpiredError"
      ) {
        setRedirectTo("/login");
      }
    }
  };

  const handleDeleteAllLogs = async () => {
    try {
      await deleteAllAppLogs();
      enqueueSnackbar("App Logs Deleted successfully", { variant: "success" });
      fetchAllAppLogs();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error in deleting app logs", { variant: "success" });
      if (
        error.response?.status === 401 &&
        error.response?.data.message !== "TokenExpiredError"
      ) {
        setRedirectTo("/login");
      }
    }
  };

  const columns: GridColumns = [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      renderCell: datagridCellExpand,
    },
    {
      field: "objectId",
      headerName: "Object Id",
      flex: 0.5,
      renderCell: datagridCellExpand,
    },
    {
      field: "source",
      headerName: "Source",
      flex: 0.5,
      renderCell: datagridCellExpand,
    },
    {
      field: "log",
      headerName: "Log",
      flex: 1,
      renderCell: datagridCellExpand,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: datagridCellExpand,
    },
  ];

  useEffect(() => {
    fetchAllAppLogs();
  }, []);

  useEffect(() => {
    setRows(() => {
      return allLogs?.map((data: any, index: number) => {
        return {
          id: index + 1,
          objectId: data?._id,
          source: data?.source,
          log: data?.errorMessage,
          date: new Date(data?.createdOn).toLocaleDateString("en-GB", {
            hour12: false,
          }),
        } as any;
      }) as any;
    });
  }, [allLogs]);

  return (
    <>
      <CssBaseline />
      <MiniDrawer>
        <Box style={{ height: "80vh", width: "100%", gap: "2%" }}>
          <Grid
            container
            sm={12}
            xs={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item sm={10} xs={12}>
              <h1>App Logs</h1>
            </Grid>
            <Grid container sm={2} xs={12}>
              <Button
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                disableElevation
                variant="contained"
                color="primary"
                size="large"
                onClick={handleDeleteAllLogs}
              >
                Delete All Logs
              </Button>
            </Grid>
          </Grid>
          <hr />
          <Box
            className={classes.root}
            style={{
              width: "100%",
              height: "420px",
              display: "flex",
            }}
          >
            <DataGrid
              //   loading={allLogs.length === 0}
              // pagination
              components={{
                Toolbar: GridToolbar,
              }}
              // autoPageSize
              rows={rows}
              columns={columns}
            />
          </Box>
        </Box>
      </MiniDrawer>
    </>
  );
};

export default withRouter(ViewAppLogs);
