import { mathSymbol } from "../../contracts/qb_interface";

// export const mathSymbolsResponse: mathSymbol[] = [
//   {
//     name: 'Fraction',
//     symbolIcon: '\\frac{x}{y}',
//     classification: ['Basic Arithmetics'],
//     classnames: ['Class VIII'],
//     inputs: [
//       {
//         inputType: 'Numerator',
//         placeholder: 'Enter Numerator',
//         helperText: 'x/y -> x is numerator',
//         optional: false
//       },
//       {
//         inputType: 'Denominator',
//         placeholder: 'Enter Denominator',
//         helperText: 'x/y -> y is Denominator',
//         optional: false
//       }
//     ],
//     // output: (x: any, y: any) => `\\frac{}{}`,
//     output: '\\frac{}{}'
//   },
//   {
//     name: 'Square Root',
//     symbolIcon: '\\sqrt{x}',
//     classification: ['Roots'],
//     classnames: ['Class VIII'],
//     inputs: [
//       {
//         inputType: 'Radicand',
//         placeholder: 'Enter Radicand',
//         helperText: 'x is Radicand',
//         optional: false
//       }
//     ],
//     // output: (x: any) => `\\sqrt{${x}}`,
//     output: '\\sqrt{}'
//   },
//   {
//     name: 'Cubic Root',
//     symbolIcon: '\\sqrt[3]{x}',
//     classification: ['Roots'],
//     classnames: ['Class VIII'],
//     inputs: [
//       {
//         inputType: 'Radicand',
//         placeholder: 'Enter Radicand',
//         helperText: 'x is Radicand',
//         optional: false
//       }
//     ],
//     // output: (x: any) => `\\sqrt[3]{${x}}`,
//     output: '\\sqrt[3]{}'
//   },
// ]

export const mathSymbolsResponse: mathSymbol[] = [
  {
      "name": "Plus",
      "symbolicon": "+",
      "classification": [
          "Arithmetics"
      ],
      "output": "+",
      "includeInMainBar": true
  },
  {
      "name": "Minus",
      "symbolicon": "-",
      "classification": [
          "Arithmetics"
      ],
      "output": "-",
      "includeInMainBar": true
  },
  {
      "name": "Multiplication",
      "symbolicon": "\\times",
      "classification": [
          "Arithmetics"
      ],
      "output": "\\times",
      "includeInMainBar": true
  },
  {
      "name": "Division",
      "symbolicon": "\\div",
      "classification": [
          "Arithmetics"
      ],
      "output": "\\div",
      "includeInMainBar": true
  },
  {
      "name": "Plus or Minus",
      "symbolicon": "\\pm",
      "classification": [
          "Arithmetics"
      ],
      "output": "\\pm",
      "includeInMainBar": true
  },
  {
      "name": "Square",
      "symbolicon": "\\square\\^2",
      "classification": [
          "Arithmetics"
      ],
      "output": "^2",
      "includeInMainBar": true
  },
  {
      "name": "Cube",
      "symbolicon": "\\square\\^3",
      "classification": [
          "Arithmetics"
      ],
      "output": "^3",
      "includeInMainBar": true
  },
  {
      "name": "Superscript",
      "symbolicon": "\\square\\^x",
      "classification": [
          "Arithmetics"
      ],
      "output": "\\^{}",
      "includeInMainBar": true
  },
  {
    "name": "Subscript",
    "symbolicon": "\\square\\_x",
    "classification": [
        "Arithmetics"
    ],
    "output": "\\_{}",
    "includeInMainBar": true
  },
  {
      "name": "Square Root",
      "symbolicon": "\\sqrt{x}",
      "classification": [
          "Arithmetics"
      ],
      "output": "\\sqrt{}",
      "includeInMainBar": true
  },
  {
      "name": "Cubic Root",
      "symbolicon": "\\sqrt[3]{x}",
      "classification": [
          "Arithmetics"
      ],
      "output": "\\sqrt[3]{}",
      "includeInMainBar": true
  },
  {
      "name": "Fraction",
      "symbolicon": "\\frac{x}{y}",
      "classification": [
          "Arithmetics"
      ],
      "output": "\\frac{}{}",
      "includeInMainBar": true
  },
  {
      "name": "Equal",
      "symbolicon": "\\=",
      "classification": [
          "Arithmetics"
      ],
      "output": "\\=",
      "includeInMainBar": true
  },
  {
      "name": "Not Equal",
      "symbolicon": "\\ne",
      "classification": [
          "Arithmetics"
      ],
      "output": "\\ne",
      "includeInMainBar": true
  },
  {
      "name": "Greater",
      "symbolicon": "\\>",
      "classification": [
          "Arithmetics"
      ],
      "output": "\\>",
      "includeInMainBar": true
  },
  {
      "name": "Greater or equal",
      "symbolicon": "\\ge",
      "classification": [
          "Arithmetics"
      ],
      "output": "\\ge",
      "includeInMainBar": true
  },
  {
      "name": "Lesser",
      "symbolicon": "\\<",
      "classification": [
          "Arithmetics"
      ],
      "output": "\\<",
      "includeInMainBar": true
  },
  {
      "name": "Lesser or equal",
      "symbolicon": "\\le",
      "classification": [
          "Arithmetics"
      ],
      "output": "\\le",
      "includeInMainBar": true
  },
  {
    "name": "Angle",
    "symbolicon": "\\angle",
    "classification": [
        "Geometry"
    ],
    "output": "\\angle"
},
{
    "name": "Measured Angle",
    "symbolicon": "\\measuredangle",
    "classification": [
        "Geometry"
    ],
    "output": "\\measuredangle"
},
// {
//     "name": "Spherical Angle",
//     "symbolicon": "\\sphericalangle",
//     "classification": [
//         "Geometry"
//     ],
//     "output": "\\sphericalangle"
// },
{
    "name": "Degree",
    "symbolicon": "\\degree",
    "classification": [
        "Geometry"
    ],
    "output": "\\degree"
},
{
    "name": "line",
    "symbolicon": "\\overleftrightarrow{AB}",
    "classification": [
        "Geometry"
    ],
    "output": "\\overleftrightarrow{}"
},
{
    "name": "line segment",
    "symbolicon": "\\bar{AB}",
    "classification": [
        "Geometry"
    ],
    "output": "\\bar{}"
},
{
    "name": "ray",
    "symbolicon": "\\overrightarrow{AB}",
    "classification": [
        "Geometry"
    ],
    "output": "\\overrightarrow{}"
},
{
  "name": "arc",
  "symbolicon": "\\overarc{AB}",
  "classification": [
      "Geometry"
  ],
  "output": "\\overarc{}"
},
{
    "name": "perpendicular",
    "symbolicon": "\\perp",
    "classification": [
        "Geometry"
    ],
    "output": "\\perp"
},
{
    "name": "parallel",
    "symbolicon": "\\parallel",
    "classification": [
        "Geometry"
    ],
    "output": "\\parallel"
},
{
    "name": "congruent to",
    "symbolicon": "\\cong",
    "classification": [
        "Geometry"
    ],
    "output": "\\cong"
},
{
    "name": "similarity",
    "symbolicon": "~",
    "classification": [
        "Geometry"
    ],
    "output": "~"
},
{
    "name": "triangle",
    "symbolicon": "\\bigtriangleup",
    "classification": [
        "Geometry"
    ],
    "output": "\\bigtriangleup"
},
{
    "name": "pi",
    "symbolicon": "\\pi",
    "classification": [
        "Geometry",
        "Algebra"
    ],
    "output": "\\pi"
},
{
    "name": "equivalence",
    "symbolicon": "\\equiv",
    "classification": [
        "Algebra"
    ],
    "output": "\\equiv"
},
// {
//     "name": "equal by definition",
//     "symbolicon": "\\coloneqq",
//     "classification": [
//         "Algebra"
//     ],
//     "output": "\\coloneqq"
// },
{
    "name": "approximately equal",
    "symbolicon": "\\approx",
    "classification": [
        "Algebra"
    ],
    "output": "\\approx"
},
{
    "name": "proportional to",
    "symbolicon": "\\propto",
    "classification": [
        "Algebra"
    ],
    "output": "\\propto"
},
{
    "name": "infinity",
    "symbolicon": "\\infin",
    "classification": [
        "Algebra"
    ],
    "output": "\\infin"
},
{
    "name": "left floor bracket",
    "symbolicon": "\\lfloor",
    "classification": [
        "Algebra"
    ],
    "output": "\\lfloor"
},
{
    "name": "right floor brackets",
    "symbolicon": "\\rfloor",
    "classification": [
        "Algebra"
    ],
    "output": "\\rfloor"
},
{
    "name": "left ceiling bracket",
    "symbolicon": "\\lceil",
    "classification": [
        "Algebra"
    ],
    "output": "\\lceil"
},
{
    "name": "right ceiling brackets",
    "symbolicon": "\\rceil",
    "classification": [
        "Algebra"
    ],
    "output": "\\rceil"
},
{
    "name": "function composition",
    "symbolicon": "\\circ",
    "classification": [
        "Algebra"
    ],
    "output": "\\circ"
},
{
    "name": "delta",
    "symbolicon": "\\Delta",
    "classification": [
        "Algebra"
    ],
    "output": "\\Delta"
},
{
    "name": "sigma",
    "symbolicon": "\\Sigma",
    "classification": [
        "Algebra"
    ],
    "output": "\\Sigma"
},
{
    "name": "capital pi",
    "symbolicon": "\\Pi",
    "classification": [
        "Algebra"
    ],
    "output": "\\Pi"
},
{
    "name": "euler constant",
    "symbolicon": "e",
    "classification": [
        "Algebra"
    ],
    "output": "e"
},
{
    "name": "euler mascheroni constant",
    "symbolicon": "\\gamma",
    "classification": [
        "Algebra"
    ],
    "output": "\\gamma"
},
{
    "name": "golden ratio",
    "symbolicon": "\\varphi",
    "classification": [
        "Algebra"
    ],
    "output": "\\varphi"
},
{
    "name": "tensor product",
    "symbolicon": "\\otimes",
    "classification": [
        "Algebra"
    ],
    "output": "\\otimes"
},
{
    "name": "intersection",
    "symbolicon": "\\bigcap",
    "classification": [
        "Probability and Statistics",
        "Set Theory"
    ],
    "output": "\\bigcap"
},
{
    "name": "union",
    "symbolicon": "\\bigcup",
    "classification": [
        "Probability and Statistics",
        "Set Theory"
    ],
    "output": "\\bigcup"
},
{
    "name": "vertical line",
    "symbolicon": "\\vert",
    "classification": [
        "Probability and Statistics",
        "Logic"
    ],
    "output": "\\vert"
},
{
    "name": "mean",
    "symbolicon": "\\bar{x}",
    "classification": [
        "Probability and Statistics", "Logic"
    ],
    "output": "\\bar{}"
},
{
    "name": "sigma",
    "symbolicon": "\\sigma",
    "classification": [
        "Probability and Statistics",
        "Greek Alphabets (lowercase)"
    ],
    "output": "\\sigma"
},
{
    "name": "median",
    "symbolicon": "\\tilde{x}",
    "classification": [
        "Probability and Statistics"
    ],
    "output": "\\tilde{}"
},
{
    "name": "summation",
    "symbolicon": "\\sum_{i=1}^n",
    "classification": [
        "Probability and Statistics"
    ],
    "output": "\\sum_{i=1}^n"
},
{
    "name": "factorial",
    "symbolicon": "!",
    "classification": [
        "Combinatorics"
    ],
    "output": "!"
},
{
    "name": "permutation",
    "symbolicon": "\\_n\\^P\\_k",
    "classification": [
        "Combinatorics"
    ],
    "output": "\\_n\\^P\\_k"
},
{
    "name": "combination",
    "symbolicon": "\\_n\\^C\\_k",
    "classification": [
        "Combinatorics"
    ],
    "output": "\\_n\\^C\\_k"
},
{
    "name": "subset",
    "symbolicon": "\\subseteq",
    "classification": [
        "Set Theory"
    ],
    "output": "\\subseteq"
},
{
    "name": "proper subset",
    "symbolicon": "\\subset",
    "classification": [
        "Set Theory"
    ],
    "output": "\\subset"
},
{
    "name": "not subset",
    "symbolicon": "\\nsubseteq",
    "classification": [
        "Set Theory"
    ],
    "output": "\\nsubseteq"
},
{
    "name": "superset",
    "symbolicon": "\\supseteq",
    "classification": [
        "Set Theory"
    ],
    "output": "\\supseteq"
},
{
    "name": "proper superset",
    "symbolicon": "\\supset",
    "classification": [
        "Set Theory"
    ],
    "output": "\\supset"
},
{
    "name": "not superset",
    "symbolicon": "\\nsupseteq",
    "classification": [
        "Set Theory"
    ],
    "output": "\\nsupseteq"
},
{
    "name": "element of",
    "symbolicon": "\\in",
    "classification": [
        "Set Theory"
    ],
    "output": "\\in"
},
{
    "name": "not element of",
    "symbolicon": "\\notin",
    "classification": [
        "Set Theory"
    ],
    "output": "\\notin"
},
// {
//     "name": "universal set",
//     "symbolicon": "\\U",
//     "classification": [
//         "Set Theory"
//     ],
//     "output": "\\U"
// },
{
    "name": "natural numbers 0",
    "symbolicon": "\\N\\_0",
    "classification": [
        "Set Theory"
    ],
    "output": "\\N\\_0"
},
{
    "name": "natural numbers 1",
    "symbolicon": "\\N\\_1",
    "classification": [
        "Set Theory"
    ],
    "output": "\\N\\_1"
},
{
    "name": "integer numbers",
    "symbolicon": "\\Z",
    "classification": [
        "Set Theory"
    ],
    "output": "\\Z"
},
{
    "name": "rational numbers",
    "symbolicon": "\\Q",
    "classification": [
        "Set Theory"
    ],
    "output": "\\Q"
},
{
    "name": "real numbers",
    "symbolicon": "\\R",
    "classification": [
        "Set Theory"
    ],
    "output": "\\R"
},
{
    "name": "complex numbers",
    "symbolicon": "\\C",
    "classification": [
        "Set Theory"
    ],
    "output": "\\C"
},
{
    "name": "and",
    "symbolicon": "\\bullet",
    "classification": [
        "Logic"
    ],
    "output": "\\bullet"
},
{
    "name": "caret",
    "symbolicon": "\\wedge",
    "classification": [
        "Logic"
    ],
    "output": "\\wedge"
},
{
    "name": "ampersand",
    "symbolicon": "\\&",
    "classification": [
        "Logic"
    ],
    "output": "\\&"
  },
    {
        "name": "reversed caret",
        "symbolicon": "v",
        "classification": [
            "Logic"
        ],
        "output": "v"
    },
    {
        "name": "not",
        "symbolicon": "\\neg",
        "classification": [
            "Logic"
        ],
        "output": "\\neg"
    },
    {
        "name": "circled plus",
        "symbolicon": "\\oplus",
        "classification": [
            "Logic"
        ],
        "output": "\\oplus"
    },
    {
        "name": "tilde",
        "symbolicon": "~",
        "classification": [
            "Logic"
        ],
        "output": "~"
    },
    {
        "name": "implies",
        "symbolicon": "\\Longrightarrow",
        "classification": [
            "Logic"
        ],
        "output": "\\Longrightarrow"
    },
    {
        "name": "equivalent 1",
        "symbolicon": "\\Longleftrightarrow",
        "classification": [
            "Logic"
        ],
        "output": "\\Longleftrightarrow"
    },
    {
        "name": "equivalent 2",
        "symbolicon": "\\longleftrightarrow",
        "classification": [
            "Logic"
        ],
        "output": "\\longleftrightarrow"
    },
    {
        "name": "for all",
        "symbolicon": "\\forall",
        "classification": [
            "Logic"
        ],
        "output": "\\forall"
    },
    {
        "name": "there exists",
        "symbolicon": "\\exists",
        "classification": [
            "Logic"
        ],
        "output": "\\exists"
    },
    {
        "name": "there does not exists",
        "symbolicon": "\\nexists",
        "classification": [
            "Logic"
        ],
        "output": "\\nexists"
    },
    {
        "name": "therefore",
        "symbolicon": "\\therefore",
        "classification": [
            "Logic"
        ],
        "output": "\\therefore"
    },
    {
        "name": "because or since",
        "symbolicon": "\\because",
        "classification": [
            "Logic"
        ],
        "output": "\\because"
    },
    {
        "name": "limit",
        "symbolicon": "\\lim_{x \\to a}",
        "classification": [
            "Calculus"
        ],
        "output": "\\lim_{x \\to a}"
    },
    {
        "name": "epsilon",
        "symbolicon": "\\epsilon",
        "classification": [
            "Calculus", "Greek Alphabets (lowercase)"
        ],
        "output": "\\epsilon"
    },
    {
        "name": "derivative",
        "symbolicon": "\\frac{du}{dt}",
        "classification": [
            "Calculus"
        ],
        "output": "\\frac{du}{dt}"
    },
    {
        "name": "second derivative",
        "symbolicon": "\\frac{d^2 u}{dx^2}",
        "classification": [
            "Calculus"
        ],
        "output": "\\frac{d^2 u}{dx^2}"
    },
    {
        "name": "nth derivative",
        "symbolicon": "\\frac{d^n u}{dx^n}",
        "classification": [
            "Calculus"
        ],
        "output": "\\frac{d^n u}{dx^n}"
    },
    {
        "name": "partial derivative",
        "symbolicon": "\\frac{\\partial u}{\\partial t}",
        "classification": [
            "Calculus"
        ],
        "output": "\\frac{\\partial u}{\\partial t}"
    },
    {
        "name": "definite integral",
        "symbolicon": "\\int_a^b",
        "classification": [
            "Calculus"
        ],
        "output": "\\int_a^b"
    },
    {
        "name": "closed contour",
        "symbolicon": "\\oint",
        "classification": [
            "Calculus"
        ],
        "output": "\\oint"
    },
    // {
    //     "name": "closed surface integral",
    //     "symbolicon": "\\oiint",
    //     "classification": [
    //         "Calculus"
    //     ],
    //     "output": "\\oiint"
    // },
    // {
    //     "name": "closed volume integral",
    //     "symbolicon": "\\oiiint",
    //     "classification": [
    //         "Calculus"
    //     ],
    //     "output": "\\oiiint"
    // },
    {
        "name": "vector",
        "symbolicon": "\\vec{F}",
        "classification": [
            "Calculus"
        ],
        "output": "\\vec{F}"
    },
    {
        "name": "unit vector",
        "symbolicon": "\\hat{F}",
        "classification": [
            "Calculus"
        ],
        "output": "\\hat{F}"
    },
    // {
    //     "name": "laplace transform",
    //     "symbolicon": "\\\\mathcal\\\\{L\\\\}",
    //     "classification": [
    //         "Calculus"
    //     ],
    //     "output": "\\\\mathcal\\\\{L\\\\}"
    // },
    // {
    //     "name": "fourier transform",
    //     "symbolicon": "\\\\mathcal\\\\{F\\\\}",
    //     "classification": [
    //         "Calculus"
    //     ],
    //     "output": "\\\\mathcal\\\\{F\\\\}"
    // },
    {
        "name": "delta function",
        "symbolicon": "\\delta",
        "classification": [
            "Calculus",
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\delta"
    },
    {
        "name": "Gamma",
        "symbolicon": "\\Gamma",
        "classification": [
            "Greek Alphabets (uppercase)"
        ],
        "output": "\\Gamma"
    },
    {
        "name": "Delta",
        "symbolicon": "\\Delta",
        "classification": [
            "Greek Alphabets (uppercase)"
        ],
        "output": "\\Delta"
    },
    {
        "name": "Theta",
        "symbolicon": "\\Theta",
        "classification": [
            "Greek Alphabets (uppercase)",
            "Geometry"
        ],
        "output": "\\Theta"
    },
    {
        "name": "Lambda",
        "symbolicon": "\\Lambda",
        "classification": [
            "Greek Alphabets (uppercase)"
        ],
        "output": "\\Lambda"
    },
    {
        "name": "Xi",
        "symbolicon": "\\Xi",
        "classification": [
            "Greek Alphabets (uppercase)"
        ],
        "output": "\\Xi"
    },
    {
        "name": "Pi",
        "symbolicon": "\\Pi",
        "classification": [
            "Greek Alphabets (uppercase)"
        ],
        "output": "\\Pi"
    },
    {
        "name": "Sigma",
        "symbolicon": "\\Sigma",
        "classification": [
            "Greek Alphabets (uppercase)"
        ],
        "output": "\\Sigma"
    },
    {
        "name": "Upsilon",
        "symbolicon": "\\Upsilon",
        "classification": [
            "Greek Alphabets (uppercase)"
        ],
        "output": "\\Upsilon"
    },
    {
        "name": "Phi",
        "symbolicon": "\\Phi",
        "classification": [
            "Greek Alphabets (uppercase)"
        ],
        "output": "\\Phi"
    },
    {
        "name": "Psi",
        "symbolicon": "\\Psi",
        "classification": [
            "Greek Alphabets (uppercase)"
        ],
        "output": "\\Psi"
    },
    {
        "name": "Omega",
        "symbolicon": "\\Omega",
        "classification": [
            "Greek Alphabets (uppercase)"
        ],
        "output": "\\Omega"
    },
    {
        "name": "alpha",
        "symbolicon": "\\alpha",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\alpha"
    },
    {
        "name": "beta",
        "symbolicon": "\\beta",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\beta"
    },
    {
        "name": "gamma",
        "symbolicon": "\\gamma",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\gamma"
    },
    // {
    //     "name": "epsilon",
    //     "symbolicon": "\\epsilon",
    //     "classification": [
    //         "Greek Alphabets (lowercase)"
    //     ],
    //     "output": "\\epsilon"
    // },
    {
        "name": "varepsilon",
        "symbolicon": "\\varepsilon",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\varepsilon"
    },
    {
        "name": "zeta",
        "symbolicon": "\\zeta",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\zeta"
    },
    {
        "name": "eta",
        "symbolicon": "\\eta",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\eta"
    },
    {
        "name": "theta",
        "symbolicon": "\\theta",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\theta"
    },
    {
        "name": "eta",
        "symbolicon": "\\eta",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\eta"
    },
    {
        "name": "iota",
        "symbolicon": "\\iota",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\iota"
    },
    {
        "name": "kappa",
        "symbolicon": "\\kappa",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\kappa"
    },
    {
        "name": "lambda",
        "symbolicon": "\\lambda",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\lambda"
    },
    {
        "name": "mu",
        "symbolicon": "\\mu",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\mu"
    },
    {
        "name": "nu",
        "symbolicon": "\\nu",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\nu"
    },
    {
        "name": "xi",
        "symbolicon": "\\xi",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\xi"
    },
    {
        "name": "omicron",
        "symbolicon": "o",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "o"
    },
    {
        "name": "pi",
        "symbolicon": "\\pi",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\pi"
    },
    {
        "name": "rho",
        "symbolicon": "\\rho",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\rho"
    },
    {
        "name": "sigma",
        "symbolicon": "\\sigma",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\sigma"
    },
    {
        "name": "tau",
        "symbolicon": "\\tau",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\tau"
    },
    {
        "name": "upsilon",
        "symbolicon": "\\upsilon",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\upsilon"
    },
    {
        "name": "phi",
        "symbolicon": "\\phi",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\phi"
    },
    {
        "name": "chi",
        "symbolicon": "\\chi",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\chi"
    },
    {
        "name": "psi",
        "symbolicon": "\\psi",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\psi"
    },
    {
        "name": "omega",
        "symbolicon": "\\omega",
        "classification": [
            "Greek Alphabets (lowercase)"
        ],
        "output": "\\omega"
    }
]