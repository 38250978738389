import React, {FunctionComponent} from 'react'

import WebResourceUploader from '../components/webResourceUploader'
import { resourceType } from '../interfaces/webResource'

const UploadResource: FunctionComponent = () => {
    let type = resourceType.blog
    if(window.location.pathname.includes('video')) type = resourceType.video
    if(window.location.pathname.includes('book')) type = resourceType.ebook
    return <WebResourceUploader type={type}/>
}

export default UploadResource