import React, { useState, useEffect, FunctionComponent, } from 'react';
import {
    Box, Container, Grid, Input, Typography, CssBaseline, Card, CardContent, CardHeader, IconButton, Select, MenuItem, Button, TextField, Checkbox, ListItemText, darken, makeStyles, InputAdornment, lighten, createStyles, Theme, Tooltip
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
// import { isValidPhoneNumber } from 'libphonenumber-js';

import MiniDrawer from "../../../common/components/SideDrawer"
import { CustomUser, } from '../../../common/contracts/dashboard';

import { RootState } from '../../../redux/store';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createCollegeGroup, deleteEBook, deleteOrgChapterContent, fetchAnnouncements, fetchAssets, fetchAssignedAssignmentNew, fetchCurriculum, fetchEBook, fetchMasterCourse, getCourseBundles, getExamData, getGdFormBy_Id, getOrgList, getUser, updateCollegeGroup, updateUserPreferences } from '../../../../api/dashboard';
import { ArrowBack, Delete, Visibility } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { fontOptions } from '../../../../theme';
import CancelIcon from '@material-ui/icons/Cancel';
import { log } from 'console';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import ConfirmationModal from '../../../common/components/confirmation_modal';

interface Props extends RouteComponentProps {
    user: CustomUser
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography_1: {
            color: '#4285F4'
        },
        typography_2: {
            color: 'red',
            paddingTop: '160px'
        },
        title: {
            fontSize: fontOptions.size.large
        },
        addAssi: {
            '& button': {
                color: '#4C8BF5',
                backgroundColor: '#FFFFFF',
                '&:hover': {
                    backgroundColor: darken('#FFFFFF', 0.1),
                }
            },
            paddingTop: '12px'
        },
        formControl: {
            margin: "5px",
            minWidth: 120,
        },
        helperText: {
            fontSize: fontOptions.size.small,
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.6)'
        },
        heading: {
            margin: '0',
            fontWeight: fontOptions.weight.bold,
            fontSize: fontOptions.size.medium,
            letterSpacing: '1px',
            color: '#212121',
        },
        label: {
            fontWeight: fontOptions.weight.bold,
            fontSize: fontOptions.size.small,
        },
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            height: "100%",
        },
        highlight:
            theme.palette.type === "light"
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
    }
    ));

const CourseConSize: FunctionComponent<Props> = ({ user, history, location }) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [redirectTo, setRedirectTo] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false);
    const [collegeGroupName, setCollegeGroupName] = useState("")
    const [collegesList, setCollegesList] = useState<any>([])
    const [selectedColegeList, setSelectedCollegeList] = useState<any>([])
    const [ELibraryArray, setELibraryArray] = useState<any>();
    const [degreeMasterArray, setDegreeMasterArray] = useState<any>();
    const [gdArray, setGdArray] = useState<any>();
    const [assignArray, setAssignArray] = useState<any>();
    const [assetArray, setAssetArray] = useState<any>();
    const [anounceArray, setAnounceArray] = useState<any>();
    const [courseBundleArray, setcourseBundleArray] = useState<any>();
    const [onScreenArray, setOnScreenArray] = useState<any>();
    const [contentArray, setContentArray] = useState<any>();
    const [selectedRow, setSelectedRow] = useState<any>();
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);




    const State: any = location?.state;
    let data = State?.data

    const rowData = useSelector((state: RootState) => state.adminReducer.rowData);
    console.log("rowData course--", rowData)

    const viewData = useSelector((state: RootState) => state.adminReducer.viewData);
    console.log("viewData course2--", viewData)

    const orgData1 = useSelector((state: RootState) => state.adminReducer.data);

    const handleDelete = (row: any) => {
        console.log("row for row---", row)
        setOpenConfirmationModal(true)
        console.log("selectedRow deleteId--", row?.rowId)
        setSelectedRow(row?.rowId)
    }
    console.log("selectedRow--", selectedRow)

    const handleDeleteCourseContent = async () => {
        try {
            setLoading(true)
            console.log("row neww", selectedRow);
            await deleteOrgChapterContent(orgData1[0].ownerId)
            setOpenConfirmationModal(false)
            enqueueSnackbar("File Storage Deleted successfully", { variant: "success" })
            setLoading(false)
        } catch (error) {
            console.log("error", error);
            setOpenConfirmationModal(false)
            setLoading(false)
        }

    }
    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        const ccmResp = await fetchCurriculum(orgData1[0].ownerId)
        setContentArray(ccmResp)
        console.log('fetchCurriculum res:', ccmResp);
    }


    const createRows = (rowData: any) => {
        if (!Array.isArray(rowData)) return [];

        let idCounter = 1;

        return rowData.reduce((acc: any[], course: any) => {
            if (!course) return acc;
            console.log("course lib--", course)
            const validItems = [];

            const filename = course?.contentname || '';
            const parts = filename.split('_');
            const name = parts[parts.length - 1];
            const contentSize = `${Math.round((course?.contentlength) / (1024 * 1024))} MB` || '';
            const rowId = name;

            if (name && course?.contentlength) {
                validItems.push({
                    id: idCounter++,
                    name,
                    contentSize,
                    rowId
                });
            }

            return acc.concat(validItems);

        }, []);
    };

    const rows = createRows(rowData);

    console.log("rows rowdata------", rows);



    const columns = [
        { field: 'id', headerName: 'Sr.No.', width: 150 },
        { field: 'name', headerName: 'File Name', width: 400 },
        { field: 'contentSize', headerName: 'File Size', width: 300 },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            renderCell: (params: any) => (
                <>
                    <Tooltip title="Delete File">
                        <IconButton
                            color="primary"
                            onClick={() => handleDelete(params.row)}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>

                </>
            )
        },
    ];


    return (
        <>
            <CssBaseline />
            <MiniDrawer>
                <Container style={{ width: '100%' }}>
                    <Box
                        bgcolor="#4C8BF5"
                        padding="20px 30px"
                        marginBottom="30px"
                        position="relative"
                        borderRadius="5px"
                        color='#fff'
                    >
                        <Grid item container>
                            <Grid item sm={8}>
                                <Box style={{ height: '100%' }}>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography className={classes.title}>
                                                View File Storage
                                            </Typography>
                                            <Box style={{ display: "flex" }}>
                                                <Button style={{ textTransform: "capitalize", backgroundColor: "#fff", color: "#4C8BF5", marginTop: 10, marginRight: "10px" }} disableElevation variant="contained" size="small" onClick={() => { history.goBack() }} >Back</Button>

                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box style={{ backgroundColor: "#fff", borderRadius: 8, padding: 24, paddingLeft: 32 }}>
                        <Grid xs={12} sm={12} md={8} lg={6}>
                            <Typography style={{ fontWeight: "bold", marginBottom: 8, fontSize: "20px" }}>Module Name :  {viewData?.name}</Typography>

                            <Typography style={{ fontWeight: "bold", marginBottom: 8, fontSize: "20px" }}>Module File Storage : {viewData?.contentSize}</Typography>

                        </Grid>
                        <Grid xs={12} style={{ marginTop: '20px' }}>
                            <hr style={{ borderTop: '2px dashed #B0B1B3', width: '100%', height: '0px', borderLeft: '0', borderRight: '0', borderBottom: '0' }}></hr>
                        </Grid>

                        <div style={{ height: "80vh", width: "100%" }}>
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pagination
                                    components={{
                                        Toolbar: GridToolbar
                                    }}
                                    autoPageSize={false}
                                    checkboxSelection
                                    disableColumnMenu
                                    disableSelectionOnClick
                                    pageSize={5}
                                />
                            </div>
                        </div>
                        <ConfirmationModal
                            header="Delete File storage"
                            helperText="Are you sure you want to delete?"
                            openModal={openConfirmationModal}
                            onClose={() => { setOpenConfirmationModal(false) }}
                            handleDelete={handleDeleteCourseContent}
                        />
                    </Box>
                </Container>
            </MiniDrawer >
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    user: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(CourseConSize);


