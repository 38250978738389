import axios from 'axios'
import { questionUpdateReq } from './interfaces'

const BASE_URL = process.env.REACT_APP_API + "admin/"
const UPLOAD_MASTER_CITIES = BASE_URL + "uploadMasterCities"
const UPLOAD_MASTER_SCHOOLS = BASE_URL + "uploadMasterSchools"
const UPLOAD_MASTER_CHAPTERS = BASE_URL + "uploadMasterChapters"
const UPLOAD_MASTER_QUALIFICATIONS = BASE_URL + "uploadMasterQualifications"
const MSG_TEMPLATE = BASE_URL + 'msgtemplate';
const HTML_TEMPLATE = BASE_URL + 'htmltemplate'
const PUSH_TEMPLATE = BASE_URL + 'pushtemplate';
const GET_SORTED_IMG = BASE_URL + 'imagesorter';
const MASTER_QUESTIONS = BASE_URL + "masterQuestions"
const MASTER_QUESTIONS_CHAPTER_LIST = MASTER_QUESTIONS + '/chapternames'
const MASTER_DATA_URL = process.env.REACT_APP_API + 'masterdata/'
const GET_BOARDS_LIST = MASTER_DATA_URL + 'getBoardsList'
const GET_CLASS_LIST = MASTER_DATA_URL + 'getClassList'
const GET_SUBJECTS_LIST = MASTER_DATA_URL + 'getSubjectList'
const NOTIFICATION_TEMPLATE = BASE_URL + 'masterNotificationTemplate'
const NOTIFICATION_TEMPLATE_TYPES = BASE_URL + 'masterNotificationTemplateTypes'

export const uploadMasterCities = async (cities: any) => {
    await axios.post(UPLOAD_MASTER_CITIES, {cities})
}

export const fetchMasterCities = async () => {
    return await axios.get(UPLOAD_MASTER_CITIES)
     
}

export const uploadMasterSchools = async (schools: any) => {
    await axios.post(UPLOAD_MASTER_SCHOOLS, {schools})
}

export const fetchMasterSchools = async () => {
    return await axios.get(UPLOAD_MASTER_SCHOOLS)
}

export const uploadMasterChapters = async (chapters: any) => {
    await axios.post(UPLOAD_MASTER_CHAPTERS, {chapters})
}

export const fetchMasterChapters = async () => {
    return await axios.get(UPLOAD_MASTER_CHAPTERS)
}

export const uploadMasterQuestions = async (qualifications: any) => {
    await axios.post(UPLOAD_MASTER_QUALIFICATIONS, {qualifications})
}

export const fetchMasterQuestions = async () => {
    return await axios.get(UPLOAD_MASTER_QUALIFICATIONS)
}



export const saveMsgTemplate = async (type: string, template: string, icon: string) => {
  return axios.post(MSG_TEMPLATE, {type, template, icon})
}

export const saveHtmlTemplate = async (type: string, template: string, icon: string) => {
    return axios.post(HTML_TEMPLATE, {type, template, icon})
} 
  
export const savePushTemplate = async (type: string, template: string, icon: string) => {
  return axios.post(PUSH_TEMPLATE, {type, template, icon})
}
  
export const switchMsgTemplate = async (type: string, state: boolean) => {
  return axios.patch(MSG_TEMPLATE, {type, state})
}
  
export const switchPushTemplate = async (type: string, state: boolean) => {
  return axios.patch(PUSH_TEMPLATE, {type, state})
}



export const downloadSortedImg = async (imageNameArr: string[]) => {
    return axios.post(GET_SORTED_IMG, {imageNameArr}, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/zip'
        }
    })
}

export const getBoardsList = async () => {
    const response = await axios.get(GET_BOARDS_LIST)
    return response.data
}

export const getClassList = async (params: {
    boardname: string
}) => {
    const response = await axios.get(GET_CLASS_LIST, {params})
    return response.data
}

export const getSubjectList = async (params: {
    boardname: string,
    classname: string
}) => {
    const response = await axios.get(GET_SUBJECTS_LIST, {params})
    return response.data
}

export const getChapterList = async (params: {
    boardname: string,
    classname: string,
    subjectname: string
}) => {
    const response = await axios.get(MASTER_QUESTIONS_CHAPTER_LIST, {params})
    return response.data.data
}

export const getMasterQuestions = async (params: {
    boardname: string,
    classname: string,
    subjectname: string,
    chaptername: string
}) => {
    const response = await axios.get(MASTER_QUESTIONS, {params})
    return response.data.data
}

export const updateMasterQuestion = async (question: questionUpdateReq) => {
    await axios.patch(MASTER_QUESTIONS, question)
}

export const fetchNotificationTemplateTypes = async () => {
    const response = await axios.get<{data: {
        smsTypes: string[],
        emailTypes: string[]
    }}>(NOTIFICATION_TEMPLATE_TYPES)
    return response.data.data
}

export const fetchNotificationTemplate = async (params: {messageType: string}) => {
    const response = await axios.get(NOTIFICATION_TEMPLATE, {params})
    return response.data.data
}