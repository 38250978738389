import React, { FunctionComponent, useState, useEffect } from "react";
import {
    Box,
	Container,
	CssBaseline,
	Grid,
	IconButton,
	makeStyles,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import MiniDrawer from "../../../common/components/SideDrawer";
import { fontOptions } from "../../../../theme";
import { getPBatch } from "../../../../api/dashboard";
import { FetchPublicBatch } from "../../interfaces";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import Datagrids, { datagridCellExpand } from "../../components/dataGrid";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { RouteComponentProps, withRouter } from "react-router";
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles({
	title: {
		fontSize: fontOptions.size.large
	},
});
  
function createData(
    _id: string,
	batchName: string,
	batchStartDate : Date,
	batchEndDate: Date,
	batchMaxStudentCount : number,
	batchDuration : number,
	offerType: string[],        
	liveClassSchedules: string[],
	referals: string[]
): FetchPublicBatch {
    return { _id, batchName, batchStartDate, batchEndDate, batchMaxStudentCount, batchDuration, offerType, liveClassSchedules, referals };
}

interface Props extends RouteComponentProps {
   
}

const CMPBatches: FunctionComponent<Props> = ({location, history}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar()

    const [batchList, setBatchList] = useState<FetchPublicBatch[]>([])

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const courseId = params.get('courseId');
        if(courseId) {
            fetchBatches(courseId)
        }
    }, []);

    const fetchBatches = async (id: string) => {
        const pbatch = await getPBatch(id)
        setBatchList(pbatch)
    }

    const viewStudents = (data: FetchPublicBatch) => {
        const params = new URLSearchParams(location.search);
        const courseId = params.get('courseId');
        if(courseId) {
          history.push(`/cmpreports/students?courseId=${courseId}&batchId=${data._id}`)      
        }
    }

    const editFee = (data: FetchPublicBatch) => {
        const params = new URLSearchParams(location.search);
        const courseId = params.get('courseId');
        if(courseId) {
          history.push(`/cmpreports/feeupdate?courseId=${courseId}&batchId=${data._id}`)      
        }
    }

    const buttonData = [
        { title: 'View',
          action: viewStudents,
          icon: <VisibilityIcon />
        },
        { title: 'Edit Fee',
          action: editFee,
          icon: <EditIcon />
        },
    ];

    const rows = batchList.map((batch) => {
        return(
          createData(
            batch._id,
            batch.batchName,
            batch.batchStartDate,
            batch.batchEndDate,
            batch.batchMaxStudentCount,
            batch.batchDuration,
            batch.offerType,
            batch.liveClassSchedules,
            batch.referals
          )
        )
    });
    
    const gridColumns: GridColDef[] = [
        { field: 'id', headerName: 'Sl No.', flex: 0.7 },
        { field: 'batchName', headerName: 'Name', flex: 1, renderCell: datagridCellExpand },
        { field: 'batchStartDate', headerName: 'Start Date', flex: 1, renderCell: datagridCellExpand },
        { field: 'batchEndDate', headerName: 'End Date', flex: 1, renderCell: datagridCellExpand },
        { field: 'batchMaxStudentCount', headerName: 'Max. Students', flex: 1, renderCell: datagridCellExpand },
        { field: 'batchDuration', headerName: 'Duration (Approx. in hrs)', flex: 0.7, renderCell: datagridCellExpand },
        { field: 'action', headerName: 'Action', flex: 1.3,
          disableClickEventBubbling: true,
          renderCell: (params: GridCellParams) => {
            const selectedRow = {
              id: params.getValue("id") as number,
              batchName: params.getValue("batchName") as string
            }
    
            const selectedRowDetails = rows.find((row, index) => {
              return (row.batchName === selectedRow.batchName && index === (selectedRow.id - 1))
            })
    
            const buttonSet = buttonData.map((button, index) => {              
              return (
                <Tooltip key={index} title={button.title}>
                  <IconButton
                    onClick={() => {
                      button.action(selectedRowDetails as FetchPublicBatch);
                    }}
                    size="small"
                  >
                    {button.icon}
                  </IconButton>
                </Tooltip>
              );
            })
      
            return <div>{buttonSet}</div>;
          }
        }
    ];
    
    const gridRows = rows.map((row, index) => {
        return ({
          id: (index + 1),
          batchName: row.batchName,
          batchStartDate: row.batchName === 'Self Paced' ? '-' : new Date(row.batchStartDate).toLocaleString('en-GB'),
          batchEndDate: row.batchName === 'Self Paced' ? '-' : new Date(row.batchEndDate).toLocaleString('en-GB'),
          batchMaxStudentCount: row.batchName === 'Self Paced' ? 'No Limit' : String(row.batchMaxStudentCount),
          batchDuration: row.batchName === 'Self Paced' ? '-' : String(row.batchDuration)
        })
    })

    return(
        <div>
            <CssBaseline />
            <MiniDrawer>
                <Container maxWidth="lg">
                    <Box
                        bgcolor="#4C8BF5"
                        padding="20px 30px"
                        marginBottom="30px"
                        position="relative"
                        borderRadius="5px"
                        color='#fff'
                    >
                        <Grid item container>
                            <Grid item sm={8}>
                                <Box style={{height: '100%'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography className={classes.title}>
                                                Course Marketplace Batches
                                            </Typography>
                                            <Typography>
                                                View and Manage Batches
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        bgcolor="#FFFFFF"
                        padding="20px 30px"
                        marginBottom="30px"
                        position="relative"
                        borderRadius="5px"
                    >
                        <Grid container>
                            <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
                                <Typography style={{fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, color: '#3D3D3D'}}>
                                    Public Courses Batches
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{marginBottom: '10px'}}>
                            </Grid>

                            <Grid item xs={12} style={{marginTop: '15px'}}>
                                <Datagrids gridRows={gridRows} gridColumns={gridColumns} disableCheckbox={true} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </MiniDrawer>
        </div>
    )
}

export default withRouter(CMPBatches);