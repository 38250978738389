export const SubjectID = {
  Other: 0,
  Mathematics: 1,
  Science: 2,
  English: 3,
  Physics: 4,
  Chemistry: 5,
  Biology: 6, 
};

export const getSubDesc = (code: number) => {
  switch (code) {
    case SubjectID.Mathematics:
      return "Mathematics";
    case SubjectID.Science:
      return "Science";
    case SubjectID.English:
      return "English";
    case SubjectID.Physics:
      return "Physics";
    case SubjectID.Chemistry:
      return "Chemistry";
    case SubjectID.Biology:
      return "Biology";
    default:
      return "Other"
  }
};
