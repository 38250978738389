import React, { useState, FunctionComponent } from "react";
import {
	Avatar,
	Box,
	Container,
	CssBaseline,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import Button from "../../common/components/form_elements/button";
import { DropzoneArea } from "material-ui-dropzone";
import { Redirect } from "react-router-dom";
import { Folder as FolderIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { excelRow } from "../contracts/masterQuestion_interface";
import { dataupload, deleteByFilename, getFilename } from "../helpers/api";
import MiniDrawer from "../../common/components/SideDrawer";
import { fontOptions } from "../../../theme";
import { MASTER_PERMISSIONS, USER_ROLES } from "../../../utilities/constants";

const useStyles = makeStyles({
	title: {
		fontSize: fontOptions.size.large
	},
})

interface Props { }

interface RowData {
	id: string;
	index: string | number;
	error: string;
}

const MasterQuestions: FunctionComponent<Props> = () => {
	const classes = useStyles();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [excelFile, setExcelFile] = useState<File | null>(null);
	const [imageFile, setImageFile] = useState<File | null>(null);
	const [redirectTo, setRedirectTo] = useState("");
	const [excelData, setExcelData] = useState<excelRow[]>([]);
	const [uploaded, setUploaded] = useState<boolean>(false);
	const [rows, setRows] = useState<RowData[]>([]);
	const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
	const [duplicateErrorShow, setDuplicateErrorShow] = useState<boolean>(false);
	const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
	const [isapiLoad, setisapiLoad] = useState(false);

	React.useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {

		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		const requiredPermissions = [MASTER_PERMISSIONS.uploadQuestions]
		var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		} else {
			getFilenames();
		}
	}

	const getFilenames = async () => {
		const response = await getFilename();
		setUploadedFiles(response);
	};

	const deleteByFile = async (filename: string) => {
		const response = await deleteByFilename(filename);
		if (response?.data?.code === 200) {
			enqueueSnackbar("Successfully deleted", { variant: "success" });
		} else {
		}
		getFilenames();
	};
	const submitForm = (bypassImage: Boolean) => {
		var formData = new FormData();
		if (excelFile === undefined) {
			enqueueSnackbar("Cannot make request without excel file", {
				variant: "error",
			});
			return;
		}
		if (!bypassImage) {
			if (imageFile === undefined) {
				enqueueSnackbar("No Image File is attached. Make request anyway?", {
					variant: "warning",
					action: SnackbarAction,
				});
				return;
			}
		}

		if (!bypassImage) {
			formData.append("files", imageFile as File);
		}
		formData.append("files", excelFile as File);
		performDataUpdate(formData);
		setSubmitDisabled(true);
		setDuplicateErrorShow(false);
	};

	const columns: GridColDef[] = [
		{ field: "id", headerName: "Sr No.", type: "string", width: 100 },
		{ field: "index", headerName: "Identifier Row/String", width: 410 },
		{ field: "error", headerName: "Reason", width: 350 },
	];

	const performDataUpdate = async (formData: FormData) => {
		try {
			const response = await dataupload(formData);
			setSubmitDisabled(false);
			setExcelData(response);
			if (response !== undefined) {
				setRows(() => {
					return response.map((el, index) => {
						return {
							id: (index + 1).toString(),
							index: el.row,
							error: el.error,
						} as RowData;
					});
				});
			}
			setUploaded(true);
			setSubmitDisabled(false);
			getFilenames();
		} catch (err) {
			if (err.response?.data.code === 177) {
				setDuplicateErrorShow(true);
			}
			setSubmitDisabled(false);
			getFilenames();
			enqueueSnackbar(err.response?.data.message, {
				variant: "error",
			});
			if (err.response?.status === 401) {
				setRedirectTo("/login");
			}
		}
	};
	const SnackbarAction = (key: number) => {
		return (
			<React.Fragment>
				<Button
					onClick={() => {
						submitForm(true);
						closeSnackbar(key);
					}}
				>
					Confirm
        </Button>
				<Button
					onClick={() => {
						closeSnackbar(key);
					}}
				>
					Cancel
        </Button>
			</React.Fragment>
		);
	};

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<div>
			<CssBaseline />
			<MiniDrawer>
				<Container maxWidth="lg">
					<Box
						bgcolor="#4C8BF5"
						padding="20px 30px"
						marginBottom="30px"
						position="relative"
						borderRadius="5px"
						color="#fff"
					>
						<Grid item container>
							<Grid item sm={8}>
								<Box style={{ height: "100%" }}>
									<Grid
										container
										direction="row"
										alignItems="center"
										justify="center"
										style={{ height: "100%" }}
									>
										<Grid item xs={12}>
											<Typography className={classes.title}>
												Master Questions Upload
                      </Typography>
											<Typography>
												Upload excel in first dropzone, upload img zip in second dropzone
                      </Typography>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</Box>
					<Box marginTop="20px">
						<Grid container justify="center" alignItems="center">
							<Grid container justify="center">
								<Grid item xs={12} sm={11} lg={5} md={5}>
									<DropzoneArea
										filesLimit={1}
										dropzoneParagraphClass="Please upload excel File - XLS XLSX only"
										acceptedFiles={[
											"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
											"application/vnd.ms-excel",
										]}
										onChange={(files: File[]) => {
											setExcelFile(files[0]);
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={11} lg={5} md={5}>
									<DropzoneArea
										maxFileSize={30000000} // 10 MB
										filesLimit={1}
										dropzoneParagraphClass="Please upload Image Folder - ZIP Only"
										onChange={(files: File[]) => {
											setImageFile(files[0]);
										}}
									/>
								</Grid>
							</Grid>
							{duplicateErrorShow && (
								<p style={{ color: "red" }}>
									Excel file with same name already uploaded
								</p>
							)}
							<Grid container justify="center">
								<Grid item xs={12} sm={4} md={3} lg={2}>
									<Button
										color="primary"
										variant="contained"
										onClick={() => {
											submitForm(false);
										}}
										disabled={submitDisabled}
									>
										Submit
                  </Button>
								</Grid>
							</Grid>
							<List>
								{uploadedFiles.map((filename, index) => {
									return (
										<React.Fragment key={index}>
											<ListItem>
												<ListItemAvatar>
													<Avatar>
														<FolderIcon />
													</Avatar>
												</ListItemAvatar>
												<ListItemText primary={filename} />
												<ListItemSecondaryAction>
													<IconButton
														edge="end"
														aria-label="delete"
														onClick={() => {
															deleteByFile(filename);
														}}
													>
														<DeleteIcon />
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
                      ,
										</React.Fragment>
									);
								})}
							</List>

							<Grid container justify="center">
								<Grid item xs={12} sm={10} md={8} lg={6}>
									<Typography variant="body1">
										{uploaded ? (
											excelData.length === 0 ? (
												" All rows Successfully Uploaded"
											) : (
												<DataGrid rows={rows} columns={columns} autoHeight />
											)
										) : (
											""
										)}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</MiniDrawer>
		</div>
	);
};

export default MasterQuestions;
