import React, { useState, useEffect, FunctionComponent } from 'react';
// import { makeStyles, createStyles, Theme, lighten } from '@material-ui/core/styles';
// import Select from '@material-ui/core/Select';
// import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
import * as XLSX from 'xlsx';

import MiniDrawer from "../../common/components/SideDrawer"
import Datagrids, { datagridCellExpand } from '../../dashboard/components/dataGrid';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { MASTER_PERMISSIONS } from '../../../utilities/constants';
import Datepickers from '../../dashboard/components/datepickers';
import { compareAsc } from 'date-fns';
import { getLoggedInUserData, onApiError } from '../../common/helpers';
import { fetchNotificationTemplateTypes, getCustomTemplate, updateCustomTemplate, getCustomSmsTemplate, updateCustomSmsTemplate  } from "../api";
import { fontOptions } from "../../../theme";
import Button from "../../common/components/form_elements/button";
import EditMasterNotificationTemplateModal from '../../masterData/components/editMasterNotificationTemplateModal';
import {
    Box,
    CssBaseline,
    darken,
    FormControl,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    Typography,
    TextField
} from "@material-ui/core";
import { useSnackbar } from "notistack"
import { Edit, Visibility } from "@material-ui/icons";


interface Props extends RouteComponentProps {
history: any
}

const TemplateHome: FunctionComponent<Props> = ({ history }) => {
    const ownerId:String = history.location.state.user.ownerId;
    const { enqueueSnackbar } = useSnackbar()
    const [redirectTo, setRedirectTo] = useState<string>('')
    const [emailTypes, setEmailTypes] = useState<string []>([]);
    const [emailType,serEmailType] = useState('');
    const [messageTypes, setMessageTypes] = useState<string []>([]);
    const [messageType,setMessageType] = useState('');
    const [notificationTypes, setNotificationTypes] = useState<string []>(["email", "message"]);
    const [notificationType,setNotificationType] = useState('');
    const [template, setTemplate] = useState<string>("")
    const classes = useStyles();
    const [showTemplate, setShowTemplate] = useState<Boolean>(false)
    const [editTemplate, setEditTemplate] = useState<boolean>(false)
    const [messageTemplate, setMessageTemplate] = useState("")
    const getNotificationTemplateTypes = async () => {
        await fetchNotificationTemplateTypes().then((val) => {
            setEmailTypes(val.emailTypes)
            setMessageTypes(val.smsTypes)
        })
            .catch(err => onApiError(err, enqueueSnackbar, setRedirectTo))
    }

    useEffect(() => {
        getNotificationTemplateTypes()
    }, []);


    const getCustomTemplates = async (ownerId:String, emailType:String)=>{
        try {

            if (notificationType == "email") {
                const custTemplate = await getCustomTemplate(ownerId, emailType)
                setTemplate(custTemplate.data.template)
            } else if (notificationType == "message") {
                const custSmsTemplate = await getCustomSmsTemplate(ownerId, emailType)
                setMessageTemplate(custSmsTemplate.data.message)
            }    
    } catch (error) {
            console.log('error in getcustom template',error)
            setTemplate("")
        }

    }

    useEffect(()=>{
        getCustomTemplates(ownerId, emailType)
    },[emailType, messageType])

    if (redirectTo.length > 0) {
        return <Redirect to={redirectTo} />;
    }

    const updateCustomTemplates = async () =>{
        try {
            const res = await updateCustomTemplate(ownerId, emailType, template)
            console.log('response of updation', res)
        } catch (error) {
            console.log("error ", error)
        }
    }
    
    const updateSmsCustomTemplates = async () =>{
        try {
            const res = await updateCustomSmsTemplate(ownerId, messageType, messageTemplate)
            console.log('response of updation', res)
        } catch (error) {
            console.log("error ", error)
        }
    }

    const renderDropdown = (key: string, value: any, list: string[], setter: any) => (
        <Grid container>
            <Grid item xs={12} md={8}>
                <FormControl fullWidth margin="normal">
                    <Box className={classes.label}>{key}</Box>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={8}>
                <FormControl fullWidth margin="normal">
                    <Select
                        value={value}
                        onChange={(e: any) => setter(e.target.value)}
                    >
                        <MenuItem value="">Select</MenuItem>
                        {list.map((item: any, index: any) => (
                            <MenuItem value={item} key={index}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )

    const renderButton = (name: string, cb: any) => (

        <Button style={{ margin: '5px'}} variant="contained" color="primary" size="medium"
                    onClick={cb}
                >
                    {name}
                </Button>
    )

    const templateViewer = (temp: String)=>{
        return <div >
            <Typography className={classes.title}>
             Template Preview
            </Typography>
            {<div dangerouslySetInnerHTML={{ __html: template }} />}
            {renderButton('Done', () => { setShowTemplate(false)})}
        </div>
    }

    const messageTemplateViewer = (msg: any) => {
        return <div>
            <TextField value={messageTemplate} style={{ width: '100%', marginTop: '10px' }}
                onChange={(e) => {
                    setMessageTemplate(e.target.value as string)
                }} placeholder={"enter "+messageType+" message..."}
            />
            <div style={{ padding: '20px' }}>
                {renderButton('Update', () => { updateSmsCustomTemplates() })}
            </div>
        </div>
    }

    const templateEditor = () =>{
       return <div style={{paddingTop: '50px'}}>
           <TextField
                style={{width: "70%"}}
               id="outlined-multiline-static"
               label="Html Template"
               multiline
               rows={20}
               placeholder='Insert your Html text here'
               onChange={(e)=>{
                   console.log('item is changing', e.target.value)
                   setTemplate(e.target.value)
               }}
               value={template}
           />
           <div style={{padding: '20px'}}>
               {renderButton('Update', () => { updateCustomTemplates() })}
           </div>
       </div> 
    }


    const previewTemplate = ()=>{
        setShowTemplate(true)
        setEditTemplate(false)
    }

    const uploadTemplate = ()=>{
        setShowTemplate(false)
        setEditTemplate(true)
    }

    return (
        <>
            <CssBaseline />
            <MiniDrawer>
                {renderDropdown('Select Notification Type', notificationType, notificationTypes, setNotificationType)}
                
                {notificationType == "message" && renderDropdown('Select Message Template', messageType, messageTypes, setMessageType)}
                {notificationType == "email" && renderDropdown('Select Email Template', emailType, emailTypes, serEmailType)}
                {emailType.length > 0 && <>
                        {/* <Typography className={classes.label}>
                           View : for checking current template.
                           </Typography>
                           <Typography className={classes.label}>
                           Update : for uploading new template.
                        </Typography> */}

                    <div style={{ display: 'flex', paddingTop: '20px'}}>
                        {renderButton('View Template', previewTemplate)}
                            {renderButton('Upload Template', uploadTemplate)}
                    </div>
                </>}
                <div >
                {notificationType == "email" && showTemplate && templateViewer(template)}
                 {notificationType == "email" &&  editTemplate && templateEditor()}

                    {notificationType == "message"  && messageTemplateViewer(messageTemplate)}
                </div>
            </MiniDrawer>
        </>
    );
}

const useStyles = makeStyles({
    typography_1: {
        color: '#4285F4'
    },
    typography_2: {
        color: 'red',
        paddingTop: '160px'
    },
    title: {
        fontSize: fontOptions.size.large
    },
    addAssi: {
        '& button': {
            color: '#4C8BF5',
            backgroundColor: '#FFFFFF',
            '&:hover': {
                backgroundColor: darken('#FFFFFF', 0.1),
            }
        },
        paddingTop: '12px'
    },
    formControl: {
        margin: "5px",
        minWidth: 120,
    },
    helperText: {
        fontSize: fontOptions.size.small,
        letterSpacing: '0.15px',
        color: 'rgba(0, 0, 0, 0.6)'
    },
    heading: {
        margin: '0',
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.medium,
        letterSpacing: '1px',
        color: '#212121',
    },
    label: {
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.small,
    },
});

export default TemplateHome;
