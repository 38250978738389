import React from 'react';
import { Grid, IconButton } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { eduAcademyMedia } from '../../interfaces';
import './App.css';

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: 8,
  overflow: 'auto',
});

// @ts-ignore
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 8 * 2,
  margin: `0 ${8}px 0 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

function DragDropReorderMedia(
  media: eduAcademyMedia[],
  classes: any,
  setMedia: any,
  setMediaTbeIndex: any,
  setOpenModal: any
  ) {

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const items = Array.from(media);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items.forEach((cur, i) => cur.categoryOrderNo = i+1)

    setMedia(items);
  }

  const editMedia = (index: number) => {
    setMediaTbeIndex(index)
    setOpenModal(true)
  }

  return (
    <div className="App">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters" direction="horizontal">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {media.map((cur, index) => {
                  return (
                    <Draggable key={cur.name} draggableId={cur.name} index={index}>
                      {(provided, snapshot) => (
                        <Grid container alignItems='center' className={classes.mediaCard} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}>
                          <Grid item xs={8}>
                            <p>{cur.name} - {cur.categoryOrderNo}</p>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton onClick={() => {
                              const arr = [...media]
                              arr.splice(index, 1)
                              arr.forEach((cur, i) => cur.categoryOrderNo = i+1)
                              setMedia(arr)
                            }}>
                              <Delete />
                            </IconButton>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton onClick={() => editMedia(index)}>
                              <Edit />
                            </IconButton>
                          </Grid>
                        </Grid>
                          /* <Grid container alignItems='center'>
                            <Grid item xs={6}>
                            <p>{cur.name} - {cur.categoryOrderNo}</p>
                            </Grid>
                            <Grid item xs={6}>
                              <IconButton onClick={() => {
                                const arr = [...media]
                                arr.splice(index, 1)
                                setMedia(arr)
                              }}>
                                <Delete />
                              </IconButton>
                              <IconButton onClick={() => {
                                console.log('entered edit media')
                              }}>
                                <Edit />
                              </IconButton>
                            </Grid>
                          </Grid> */
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
    </div>
  );
}

export default DragDropReorderMedia;