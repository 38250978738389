import React, { useEffect, useState } from 'react'
import { Box, Button, Container, CssBaseline, darken, FormControl, Grid, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { fontOptions } from '../../../theme';
import MiniDrawer from '../../common/components/SideDrawer'
import { categoryAutoComplete, content, eduAcademyMedia } from '../interfaces';
import { addEduAcademyContent, deleteAcademyContent, getEduAcademyContent, getHeaders, reorderAcademyContents, updateEduAcademyContent } from '../api';
import { useSnackbar } from 'notistack';
import AddMediaModal from '../components/addMediaModal';
import DragDropReorderMedia from '../components/dragDropReorder/dragDropReorderMedia';
import DragDropReorderAcademy from '../components/dragDropReorder/dragDropReorderAcademy';
import ConfirmationModal from '../../common/components/confirmation_modal';
import { onApiError } from '../../common/helpers';

const useStyles = makeStyles({
	typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121',
	  },
      label: {
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.small,
      },
      mediaCard: {
        width: 345,
        minWidth: 200
      }
});

const AddEduAcademyContent = () => {
    const classes = useStyles()
    // const [category, setCategory] = useState<categoryAutoComplete>({title: ''})
    // const [categories, setCategories] = useState<categoryAutoComplete[]>([])
    const [headers, setHeaders] = useState<categoryAutoComplete[]>([])
    const [contents, setContents] = useState<content[]>([])
    const [orderNo, setOrderNo] = useState(0)
    const [header, setHeader] = useState<categoryAutoComplete>({title: ''})
    const [text, setText] = useState('')
    const [contentId, setContentId] = useState('')

    const [media, setMedia] = useState<eduAcademyMedia[]>([])
    const [mediaTbeIndex, setMediaTbeIndex] = useState<number>(-1)

    const [openReorder, setOpenReorder] = useState(false)

    const [openModal, setOpenModal] = useState(false)

    const [contentIdToDelete, setContentIdToDelete] = useState('')
    const [indexToDelete, setIndexToDelete] = useState(0)
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false)

    const filter = createFilterOptions<categoryAutoComplete>();

    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        onUseEffect()
    }, [])

    const onUseEffect = async () => {
        await getHeaders().then((val) => {
            setHeaders(val.map(cur => ({title: cur.header, _id: cur._id})))
            setContents(val.map(cur => ({title: cur.header, _id: cur._id, orderNo: cur.orderNo})))
            setOrderNo(val.length + 1)
        })
        .catch(err => onApiError(err, enqueueSnackbar))
    }

    const getAcademyInfo = async (_id: string) => {
        await getEduAcademyContent(_id).then((val) => {
            setMedia(val.content.media)
            setOrderNo(val.content.orderNo)
            setText(val.content.text)
        })
    }

    const handleAddMedia = () => {
        setOpenModal(true)
    }

    const isValid = () => {
        if(header.title.length < 3) {
            enqueueSnackbar('Min 3 characters required for header', {variant: 'warning'})
            return false
        }
        if(text.length < 4) {
            enqueueSnackbar('Min 4 characters required for header description', {variant: 'warning'})
            return false
        }
        const PATTERN = /^[()a-zA-Z0-9 /',?’_+&-]*(?:[a-zA-Z][.()a-zA-Z0-9 /',?’_+&-\/]*){0,1000}$/
        if(!PATTERN.test(header.title)) {
            enqueueSnackbar('Invalid header', {variant: 'warning'})
            return false
        }
        const PATTERN2 = /^[()a-zA-Z0-9 /',_+&-]*(?:[a-zA-Z][.()a-zA-Z0-9 /',_+&-\/]*){0,1000}$/m
        if(!PATTERN2.test(text)) {
            enqueueSnackbar('Invalid header description', {variant: 'warning'})
            return false
        }
        if(media.length < 1) {
            enqueueSnackbar('Min 1 media needed', {variant: 'warning'})
            return false
        }
        return true
    }

    const reOrderAcademyContents = () => {
        setOpenReorder(true)
    }

    const handleReorder = async () => {
        await reorderAcademyContents(contents).then(() => {
            enqueueSnackbar('Successfully reordered', {variant: 'success'})
            setOpenReorder(false)
            onUseEffect()
        })
        .catch(err => onApiError(err, enqueueSnackbar))
    }

    const handleDeleteAcademyContent = async (_id: string, index: number) => {
        setContentIdToDelete(_id)
        setIndexToDelete(index)
        setOpenDeleteConfirmationModal(true)
    }

    const confirmDelete = async () => {
        await deleteAcademyContent(contentIdToDelete).then(() => {
            const arr = [...contents]
            arr.splice(indexToDelete, 1)
            arr.forEach((cur, i) => cur.orderNo = i+1)
            setContents(arr)
            setContentIdToDelete('')
            setIndexToDelete(0)
            enqueueSnackbar('Successfully deleted', {variant: 'success'})
            setOpenDeleteConfirmationModal(false)
        })
        .catch(err => onApiError(err, enqueueSnackbar))
    }

    const handleSubmit = async () => {
        if(!isValid()) return;
        const content = {
            // category: category.title,
            header: header.title,
            text,
            orderNo,
            media,
            // @ts-ignore
            contentRoles: [...new Set(media.flatMap(cur => cur.mediaRoles))]
        }
        const onSuccess = (msg: string) => {
            enqueueSnackbar(msg, {variant: 'success'})
            onUseEffect()
            setHeader({title: ''})
            setText('')
            setMedia([])
            setContentId('')
            setMediaTbeIndex(-1)
        }

        if(contentId.length > 2) {
            await updateEduAcademyContent(Object.assign({}, content, {_id: contentId}))
            .then(() => onSuccess('Successfully updated'))
            .catch(err => onApiError(err, enqueueSnackbar))
            return;
        }

        await addEduAcademyContent(content)
        .then(() => onSuccess('Successfully added'))
        .catch(err => onApiError(err, enqueueSnackbar))
    }

    const renderInput = (key: string, value: any, setter: any, maxLength?: number, multiline?: boolean) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={classes.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <TextField
                placeholder={key}
                value={value}
                inputProps={{ maxLength: maxLength ? maxLength : 100 }}
                multiline={multiline}
                onChange={(e) => setter(e.target.value)}
                />
            </FormControl>
            </Grid>
        </Grid>
    )

    const renderInfo = (key: string, value: any) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={classes.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Box>{value}</Box>
            </FormControl>
            </Grid>
        </Grid>
    )

    const renderDropdown = (key: string, value: any, list: string[], setter: any) => (
        <Grid container>
			<Grid item xs={12} md={4}>
				<FormControl fullWidth margin="normal">
					<Box className={classes.label}>{key}</Box>
				</FormControl>
			</Grid>

			<Grid item xs={12} md={8}>
				<FormControl fullWidth margin="normal">
					<Select
						value={value}
						onChange={(e: any) => setter(e.target.value)}
					>
						<MenuItem value="">Select</MenuItem>
						{list.map((item: any, index: any) => (
							<MenuItem value={item} key={index}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
    )

    const renderButton = (name: string, cb: any) => (
        <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item>
                <Button variant="contained" color="primary" size="medium"
                    onClick={cb}
                >
                    {name}
                </Button>
            </Grid>
        </Grid>
    )

    return (
        <>
        {openModal && <AddMediaModal
        onClose={() => {
            setMediaTbeIndex(-1)
            setOpenModal(false)
        }}
        openModal={openModal}
        stateProps={{media, setMedia}}
        mediaTbeIndex={mediaTbeIndex}
        renderInfo={renderInfo}
        renderButton={renderButton}
        renderDropdown={renderDropdown}
        renderInput={renderInput}
        classes={classes}
        />}
        {openReorder && DragDropReorderAcademy(
            openReorder, 
            () => {
                setOpenReorder(false)
                onUseEffect()
            },
            contents,
            setContents,
            classes,
            renderButton,
            handleReorder,
            handleDeleteAcademyContent
        )}
        {openDeleteConfirmationModal && <ConfirmationModal
            header="Delete Academy Content"
            helperText="Are you sure you want to delete?"
            openModal={openDeleteConfirmationModal}
            onClose={() => {
                setContentIdToDelete('')
                setIndexToDelete(0)
                setOpenDeleteConfirmationModal(false)
            }}
            handleDelete={confirmDelete}
        />}
        <CssBaseline />
        <MiniDrawer>
        <Container maxWidth="lg">
            <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="5px"
            color='#fff'
            >
            <Grid item container>
            <Grid item sm={8}>
                <Box style={{height: '100%'}}>
                    <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                    >
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            Edumatica Academy
                        </Typography>
                        <Typography>
                            Add or Edit Edumatica Academy Content 
                        </Typography>
                    </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            </Box>

            <Box
				bgcolor="#ffffff"
				borderRadius="5px"
				padding="12px 25px"
				marginTop='25px'
			>
                <Grid container justify='center'>
                    <Grid item container xs={12} lg={8} style={{paddingTop: '5px', marginBottom: '8px'}}>
                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth margin="normal">
                                    <Box className={classes.label}>Enter Header</Box>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Autocomplete
                                    value={header}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            setHeader({
                                                title: newValue,
                                            });
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            setHeader({
                                                title: newValue.inputValue,
                                            });
                                            setContentId('')
                                            setMedia([])
                                            setText('')
                                            setOrderNo(headers.length + 1)
                                        } else {
                                            if(newValue && newValue._id) {
                                                setHeader(newValue);
                                                setContentId(newValue._id)
                                                if(newValue._id) getAcademyInfo(newValue._id)
                                            }
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        // Suggest the creation of a new value
                                        if (params.inputValue !== '') {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            title: `Add "${params.inputValue}"`,
                                        });
                                        }

                                        return filtered;
                                    }}
                                    disableClearable
                                    selectOnFocus
                                    autoSelect
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={headers}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                        return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                        return option.inputValue;
                                        }
                                        // Regular option
                                        return option.title;
                                    }}
                                    renderOption={(option) => option.title}
                                    style={{ width: 300 }}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label='Enter Header' variant="outlined" />
                                    )}
                                />                            
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={4}>
                            <FormControl fullWidth margin="normal">
                                <Box className={classes.label}>Order No.</Box>
                            </FormControl>
                            </Grid>
                    
                            <Grid item xs={8} md={6}>
                            <FormControl fullWidth margin="normal">
                                <Box>{orderNo}</Box>
                            </FormControl>
                            </Grid>

                            <Grid item xs={4} md={2}>
                                {/* <FormControl margin="normal"> */}
                                <Button variant="outlined" color="primary" size="medium"
                                    onClick={reOrderAcademyContents}
                                >
                                    Reorder Contents
                                </Button>
                                {/* </FormControl> */}
                            </Grid>
                        </Grid>
                        {renderInput('Enter Header Description', text, setText, 5000, true)}
                        <br /><br />

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={10}>
                                <FormControl fullWidth margin="normal">
                                    <Box className={classes.label}>Medias Added</Box>
                                </FormControl>
                                </Grid>
                        
                                <Grid item xs={2}>
                                {/* <FormControl margin="normal"> */}
                                <Button variant="outlined" color="primary" size="medium"
                                    onClick={handleAddMedia}
                                >
                                    Add Media
                                </Button>
                                {/* </FormControl> */}
                                </Grid>
                            </Grid>
                            {media.length > 0 && <>
                            {DragDropReorderMedia(media, classes, setMedia, setMediaTbeIndex, setOpenModal)}
                            <br />
                            </>}
                        </Grid>

                        {/* <Card className={classes.mediaCard}>
                                <CardActionArea>
                                    <CardContent>
                                        {renderInfo('Name', cur.name)}
                                        {renderInfo('Category order no', cur.categoryOrderNo)}
                                        {renderInfo('Type', cur.type)}
                                        {renderInfo('Link', cur.link)}
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <IconButton onClick={() => {
                                        const arr = [...media]
                                        arr.splice(index, 1)
                                        setMedia(arr)
                                    }}>
                                        <Delete />
                                    </IconButton>
                                </CardActions>
                            </Card> */}
                        
                        <br /><br />
                        {renderButton('Submit', handleSubmit)}
                    </Grid>
                </Grid>
            </Box>
        </Container>
        </MiniDrawer>
        </>
    )
}

export default AddEduAcademyContent