import React, { useState, useEffect, FunctionComponent, } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
	Box, Container, Grid, Input, Typography, CssBaseline, Card, CardContent, CardHeader, IconButton, Select, MenuItem, Button, TextField, Checkbox, ListItemText
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
// import { isValidPhoneNumber } from 'libphonenumber-js';

import MiniDrawer from "../../../common/components/SideDrawer"
import useStyles from './styles';
import { CustomUser, } from '../../../common/contracts/dashboard';
import { RootState } from '../../../redux/store';
import { connect, useDispatch } from 'react-redux';
import { MASTER_PERMISSIONS, USER_ROLES, USER_TYPES, USER_TYPE_VALUES } from '../../../../utilities/constants';
import { getUser, updateUseLogIn, updateUserPreferences } from '../../../../api/dashboard';
import { ArrowBack } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { roles } from '../../../edumaticaAcademy/enums';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';


interface Props extends RouteComponentProps {
	user: CustomUser
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const UpdateLogInUserId: React.FunctionComponent<Props>= ({ user, history }) => {

	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	
	const [emailId, setEmailId] = useState<string>('')
	const [emailIdArr, setEmailIdArr] = useState<string[]>([])
	const [customContactNumber, setCustomContactNumber] = useState<string>('')
	const [customContactNumberArr, setCustomContactNumberArr] = useState<string[]>([])
    const [mobileNo, setMobileNo] = useState(user.mobileNo ? user.mobileNo : '');
    const [oldMobileNo, setOldMobileNo] = useState(user.mobileNo ? user.mobileNo : '');
    const [emailD, setEmailD] = useState(user.emailId ? user.emailId : '');
    const [OldEmailD, setOldEmailD] = useState(user.emailId ? user.emailId : '');
	const [newPassword, setNewPassword] = useState('');
	
	const [error, setError] = useState('');




    //user.name ? user.name : ''
    const [name, setName] = useState(user.name ? user.name : '');

    console.log("user",user)
     




	const [attendanceType, setAttendanceType] = useState('')
	const styles = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		getLoggedInUserData();
	}, []);

	const getLoggedInUserData = async () => {
		// setLoading(true);
		// try {
		// 	const adminUserData = await getAdminData();
		// 	dispatch(setLoginUserData(JSON.stringify(adminUserData)));
		// 	setLoading(false);
		// 	if (adminUserData.isItDefaultPassword) {
		// 		localStorage.setItem('isItDefaultPassword', JSON.stringify(adminUserData.isItDefaultPassword));
		// 		setRedirectTo('/set-password');
		// 	}
		// } catch (error) {
		// 	setLoading(false);
		// 	enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
		// }
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		console.log("loginUserData",loginUserData)
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.getUser);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		} else {
			getUserData();
		}
	}

	const getUserData = async () => {
		setLoading(true);

		var userType = USER_TYPE_VALUES.TUTOR;
		switch (user.userType) {
			case USER_TYPE_VALUES.TUTOR:
				userType = USER_TYPE_VALUES.TUTOR;
				break;
			case USER_TYPE_VALUES.ORG:
				userType = USER_TYPE_VALUES.ORG;
				break;
			case USER_TYPE_VALUES.STUDENT:
				userType = USER_TYPE_VALUES.STUDENT;
				break;
			case USER_TYPE_VALUES.PARENT:
				userType = USER_TYPE_VALUES.PARENT;
				break;
			case USER_TYPE_VALUES.ORG_TUTOR:
				userType = USER_TYPE_VALUES.ORG_TUTOR;
				break;
			default:
				userType = USER_TYPE_VALUES.TUTOR;
				break;
		}

		try {
			const data = await getUser(userType, user._id);
		
			console.log("data",data)
			setEmailIdArr(data?.CRMMailIds)
			setCustomContactNumberArr(data?.customContactNumbers)
			setLoading(false);
			setAttendanceType(data.attendanceType ? data.attendanceType : 'CourseWise')
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const saveDetails = async () => {
        console.log("new mobileNo--",mobileNo)
        console.log("old mobile number--",oldMobileNo)
        console.log("emaild old",OldEmailD)
        console.log("new email",emailD)
		console.log("new password",newPassword)

		// let errControl = 0;
		

		// const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
		// if (passwordRegex.test(newPassword)) {
		// 	  errControl=1;
		// 	}else{
		// 	setError('Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character.');
		// 	}

		if (newPassword) {
			const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
			if (!passwordRegex.test(newPassword)) {
				setError('Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character.');
				setLoading(false);
				return;
			}
		}
	
		setLoading(true);

		// if (!newPassword) {
		// 	setError('Please enter a new password');
		// 	return;
		//   }

		if (user && user.ownerId) {

			try {
					let data = {};
					if(newPassword.length > 0){
						console.log("***came in IF block of const saveDetails***")
					data = await updateUseLogIn({
						userType: user.userType, 
						ownerId:user.ownerId, 
						userName:user.ownerName, 
						userId: user._id, 
						oldMobileNo: oldMobileNo, 
						newMobileNo:mobileNo, 
						oldEmailId:OldEmailD, 
						newEmailId:emailD,
						newPassword: newPassword
					}
					);
				}else{
					console.log("***came on ELSE block of const saveDetails***")
					data = await updateUseLogIn({
						userType: user.userType, 
						ownerId:user.ownerId, 
						userName:user.ownerName, 
						userId: user._id, 
						oldMobileNo: oldMobileNo, 
						newMobileNo:mobileNo, 
						oldEmailId:OldEmailD, 
						newEmailId:emailD,
					}
					);
			}
                console.log("data",data)
				setLoading(false);

				// errControl>0 ? enqueueSnackbar('Updated successfully', { variant: 'success' }) : enqueueSnackbar("not valid ",{ variant: 'warning' })
				// errControl>0 && history.goBack()
				enqueueSnackbar('Updated successfully', { variant: 'success' });
				history.goBack(); 
			} catch (error) {
				setLoading(false);
				enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
				if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
					setRedirectTo('/login');
				}
			}
		} else {
			enqueueSnackbar('Something went wrong', { variant: 'warning' });
		}
	}
	const handleAddEmailArr = () => {
		const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (emailId === '') {
			return enqueueSnackbar('Email Cannot Be Empty', {
				variant: 'warning'
			});
		}
		if (!EMAIL_PATTERN.test(emailId)) {
			return enqueueSnackbar('Please enter Valid Email', {
				variant: 'warning'
			});
		}
		if (emailIdArr.includes(emailId)) {
			return enqueueSnackbar(
				`${emailId} already exists `,
				{
					variant: 'warning'
				}
			);
		}
		setEmailIdArr([...emailIdArr, emailId.toLocaleLowerCase()]);
		setEmailId('');
	};
	

    const handleOldMobileNoChange = (event:any) => {
        setOldMobileNo(event.target.value); // Update mobile number state when input value changes
    };

    const handleNewMobileNoChange = (event:any) => {
        setMobileNo(event.target.value); // Update mobile number state when input value changes
    };

    const handleOldEmailId = (event:any) => {
        setOldEmailD(event.target.value); // Update mobile number state when input value changes
    };

    const handleNewEmailId = (event:any) => {
        setEmailD(event.target.value); // Update mobile number state when input value changes
    };

	const handleNewPassword = (e:any) => {

		setNewPassword(e.target.value)
	
      
    };
// console.log("NewPassword",newPassword)

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Customer Management</Typography>
							</Grid> */}
							<Grid item className={styles.header}>
								<IconButton onClick={() => history.goBack()}>
									<ArrowBack />
								</IconButton>
								<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
							</Grid>
							<Card className={styles.card} variant='outlined'>
								<CardHeader title='View Customer' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>

									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Old Email</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input

                                                    value={OldEmailD}
                                                    onChange={handleOldEmailId}
												/>
											</FormControl>
										</Grid>
									</Grid>

                                    <Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>New Email</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input

                                                    value={emailD}
                                                    onChange={handleNewEmailId}
												/>
											</FormControl>
										</Grid>
									</Grid>



									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Old Mobile Number</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													
                                                    value={oldMobileNo}
                                                    onChange={handleOldMobileNoChange}
												/>
											</FormControl>
										</Grid>
									</Grid>

                                    <Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>New Mobile Number</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													
                                                    value={mobileNo}
                                                    onChange={handleNewMobileNoChange}
												/>
											</FormControl>
										</Grid>
									</Grid>

									

									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>New Password</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													
													placeholder="Enter your new password if needed"
                                                    value={newPassword}
                                                    onChange={handleNewPassword}
													
												/>
												<Typography variant="body2" color="error">
        											{error}
    											</Typography>
											</FormControl>

										</Grid>
									</Grid>

                                   

{
										(user.userType == USER_TYPE_VALUES.TUTOR || user.userType == USER_TYPE_VALUES.ORG || 
											user.userType == USER_TYPE_VALUES.STUDENT || user.userType == USER_TYPE_VALUES.PARENT 
											|| user.userType == USER_TYPE_VALUES.ORG_TUTOR ) &&
										<>																																																																	
											<Grid item xs={12}>
												<span style={{ float: 'right', paddingRight: '7%', paddingTop: '25px' }} >
													<Button style={{ marginLeft: '15px' }} color="primary" disableElevation variant="contained" onClick={() => history.goBack()}>
														Cancel
													</Button>
												</span>
											</Grid>
										</>}

									{
										(user.userType == USER_TYPE_VALUES.TUTOR || user.userType == USER_TYPE_VALUES.ORG || 
											user.userType == USER_TYPE_VALUES.STUDENT || user.userType == USER_TYPE_VALUES.PARENT 
											|| user.userType == USER_TYPE_VALUES.ORG_TUTOR ) &&
										<>																																																																	
											<Grid item xs={12}>
												<span style={{ float: 'right', paddingRight: '1%', paddingTop: '25px' }} >
													<Button style={{ marginLeft: '15px' }} color="primary" disableElevation variant="contained" onClick={saveDetails}>
														Save
													</Button>
												</span>
											</Grid>
										</>}

								</CardContent>
							</Card>

						</Grid>
					</Container>
				</div >
			</MiniDrawer >
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	user: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(UpdateLogInUserId);