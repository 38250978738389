export enum mediaTypes {
    Image = 'img',
    Video = 'video',
    YouTube = 'youtube'
}

export enum roles {
    tutor = "ROLE_TUTOR",
    orgTutor = "ROLE_ORGANIZATION_TUTOR",
    org = "ROLE_ORGANIZATION",
    student = "ROLE_STUDENT",
    parent = "ROLE_PARENT",
    orgAdmin = "ROLE_ORG_ADMIN",
    admin = "ROLE_ADMIN",
}