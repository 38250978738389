import React, { FunctionComponent, useState, useEffect } from 'react';
import { Box, Container, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme, darken } from '@material-ui/core/styles';
import MiniDrawer from '../../common/components/SideDrawer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSnackbar } from "notistack";
import Button from '../../common/components/form_elements/button';
import { fontOptions } from '../../common/theme';
import { createMetaTags,fetchCourseListMarketPlace,getAllMetaTags,getMetaTag } from '../helper/api';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import Datagrids, { datagridCellExpand } from '../../dashboard/components/dataGrid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from '../../common/components/confirmation_modal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
        fontSize: fontOptions.size.large
    },
    addAssi: {
        '& button': {
          color: '#4C8BF5',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: darken('#FFFFFF', 0.1),
          }
        },
        paddingTop: '12px'
    },
  }),
);

interface MetaData{
tagId:String,
address:String,
addressFriendlyName:String,
description:String,
keyWords:String[]
link: String[]
scripts: String[]
scriptHtml: String[]
title: String
}

interface Props extends RouteComponentProps {}

const SeoManager: FunctionComponent<Props> = ({history, location})  => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [metaData, setMetaData] = useState<MetaData[]>([]);
    const [courseMarketPlace, setCourseMarketPlace] = useState<any[]>([]);

    useEffect(() => {
        initData()  
    }, [location.search])

    const initData = async() => {
        let arr:MetaData[] = []
        const metaTags = await getAllMetaTags();
        const data = await fetchCourseListMarketPlace('')
        let constObj = [{
            tagId:"",
            address:`/edumaticamarketplace/viewcourse`,
            addressFriendlyName:'View Course',
            description:"",
            keyWords:[],
            link:[],
            scripts: [],
            scriptHtml: [],
            title: ""
        },
        {
            tagId:"",
            address:`/edumaticamarketplace/tutorsearch`,
            addressFriendlyName:'Tutor Search',
            description:"",
            keyWords:[],
            link:[],
            scripts: [],
            scriptHtml: [],
            title: ""
        }
    
    ]   
        await data?.map((item)=>{
           let obj = viewCourse(item)
           //@ts-ignore
           const result = metaTags?.find((el ) => el.address === item.address);
            if(result===undefined){
                arr.push(obj as MetaData)
            }
        })
        let array:MetaData[] = [...metaData,...arr]
        constObj?.map((item)=>{
            //@ts-ignore
           const result = array?.find((el ) => el.address === item.address);
           if(result===undefined){
            array.push(item)
           }
        })
        setMetaData(array)
    }
    const viewCourse = (pc:any) => {
        let arr:any[] = []
        let obj;
		const thetitle = pc.title
		if(thetitle) {
			const kebabtitle = thetitle.trim().replace(/\s+/g,'-') + '-' + new Date(pc.createdon).getTime()
			const params = new URLSearchParams(location.search);
			const referrals = params.get('referrals'); 
			const searchParam = (referrals && referrals.length > 0) ? `?referrals=${referrals}` : ``
            console.log('%cpurchase_web_list.tsx line:341 searchParam', 'color: #007acc;', searchParam);
            obj = {
                tagId:"",
                address:`/edumaticamarketplace/viewcourse/${kebabtitle}`,
                addressFriendlyName:`${kebabtitle}`,
                description:"",
                keyWords:[],
                link:[],
                scripts: [],
                scriptHtml: [],
                title: thetitle
            }
            arr.push(obj)
			// history.push({ pathname: `/edumaticamarketplace/viewcourse/${kebabtitle}`, search: searchParam });
		}
        return obj;
	}

    const editCert = (row:any) => {
      history.push({pathname:"/createTags", state: {data:row}});

    }

    const initdeleteCert = (row:any) => {
        
    }

    const deleteCT = async () => {
        
      }

    const buttonData = [
        {
          title: 'Edit',
          action: editCert,
          icon: <EditIcon />
        },
        // {
        //   title: 'Delete',
        //   action: initdeleteCert,
        //   icon: <DeleteIcon />
        // }
    ];
    

    const gridColumns: GridColDef[] = [
        { field: 'id', headerName: 'Sl No.', flex: 0.7 },
        { field: 'addressName', headerName: 'Address Name', flex: 1, renderCell: datagridCellExpand },
        { field: 'address', headerName: 'Address', flex: 1, renderCell: datagridCellExpand },
        { field: 'title', headerName: 'Title', flex: 1, renderCell: datagridCellExpand },
        // { field: 'width', headerName: 'Width', flex: 1, renderCell: datagridCellExpand },
        { field: 'action', headerName: 'Action', flex: 1.3, disableClickEventBubbling: true, 
            renderCell: (params: GridCellParams) => {
                const selectedRow = {
                    id: params.getValue("id") as number,
                  }
          
                  const selectedRowDetails = metaData?.find((row, index) => {
                    return (index === (selectedRow.id - 1))
                  })
                const buttonSet = buttonData.map((button, index) => {
                    return (
                        <Tooltip key={index} title={button.title}>
                            <IconButton
                                onClick={() => {
                                    button.action(selectedRowDetails);
                                }}
                                size="small"
                            >
                                {button.icon}
                            </IconButton>
                        </Tooltip>
                    );
                })
    
                return <div>{buttonSet}</div>;
            }
        }
    ];
    
    const gridRows = metaData?.map((row, index) => {
        return ({
          id: (index + 1),
          addressName: row.addressFriendlyName,
          address: row.address,
          title: row.title,
          description: row.description,
          keyWords: row.keyWords,
          link: row.link,
          scripts: row.scripts,
          scriptHtml: row.scriptHtml,
        })
    })

    return (
        <div>
            <MiniDrawer>
            <Container maxWidth="lg" style={{padding: '30px 2.5%'}}>
                <Box
                    bgcolor="#4C8BF5"
                    padding="20px 30px"
                    marginBottom="30px"
                    position="relative"
                    borderRadius="14px"
                    color='#fff'
                >
                    <Grid item container>
                        <Grid item sm={12}>
                            <Box style={{height: '100%'}}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justify="center"
                                    style={{ height: '100%' }}
                                >
                                    <Grid item xs={12}>
                                        <Typography className={classes.title}>
                                            Meta Tag List
                                        </Typography>
                                        <Typography>
                                            View Uploaded Meta
                                        </Typography>
                                        <Box className={classes.addAssi}>
                                            <Button variant="contained" disableElevation onClick={() => history.push('/createTags')}>
                                                New Meta Tags
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>


                <Box
                    bgcolor="#ffffff"
                    borderRadius="14px"
                    padding="25px"
                    marginTop='25px'
                >
                    <Grid container>
                        <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
                            <Typography style={{fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, color: '#3D3D3D'}}>
                                Meta Links
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{marginBottom: '10px'}}>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: '15px'}}>
                            <Datagrids gridRows={gridRows} gridColumns={gridColumns} disableCheckbox={true} />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            </MiniDrawer>
            <ConfirmationModal
				header="Delete Meta Tags"
                helperText="Are you sure you want to delete?"
                openModal={openConfirmationModal}
                onClose={() => {setOpenConfirmationModal(false)}}
                handleDelete={deleteCT}
			/>
        </div>
    );
}

export default withRouter(SeoManager)
