import { ReactType } from "react"
import routesList from "./routesList"
interface RouteDesc {
    path: string;
    component: ReactType;
    exact?: boolean;
    rolesExcluded : string[] 
  }
export const routes: RouteDesc[]  = 
    routesList.map((route)=>{
        return {
            path: route.redirectTo,
            component: route.component,
            rolesExcluded : route.rolesExcluded
        }
    })

 