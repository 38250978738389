import React, { FunctionComponent, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  Input,
  Typography,
  Button,
  Select,
  MenuItem
} from '@material-ui/core';

// import { jobApplicationRes } from '../interfaces';
import Modal from '../../common/components/modal';
import useStyles from '../../leadsManagement/styles';
import { updateLeads } from '../api';
import { leadStatus } from '../interfaces';
import { onApiError } from '../../common/helpers';
import { useSnackbar } from 'notistack';

interface Props {
    openModal: boolean;
    onClose: () => any;
    lead: any;
}

const UpdateLeadModal: FunctionComponent<Props> = ({
    openModal,
    onClose,
    lead
}) => {
    const styles = useStyles();

    const {name, mobileNo, query, emailId, postedOn, remarks, status, leadId} = lead

    const [newRemark, setNewRemark] = useState(remarks ? remarks : '')
    const [newStatus, setNewStatus] = useState(status ? status : '')

    const {enqueueSnackbar} = useSnackbar()

    const saveChanges = async () => {
        const updateReq = [{
            leadId,
            status: newStatus,
            remarks: newRemark
        }]
        await updateLeads(updateReq).then(() => {
            onClose()
        })
        .catch(err => onApiError(err, enqueueSnackbar))
    }

    const renderInfo = (key: string, value: string) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Box>{value}</Box>
            </FormControl>
            </Grid>
        </Grid>
    )

    const renderInput = (key: string, value: any, setter: any) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Input
                placeholder={key}
                value={value}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => setter(e.target.value)}
                />
            </FormControl>
            </Grid>
        </Grid>
    )

    const renderDropdown = (key: string, list: string[], value: string, setter: any) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Select>
                    <MenuItem value={value}>Select</MenuItem>
                    {list.map((item: any, index: any) => (
                        <MenuItem value={item} key={index}	onClick={() => setter(item)}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            </Grid>
        </Grid>
    )

    const renderButton = (name: string, cb: any) => (
        <Button 
        variant="contained" 
        color="primary" 
        size="medium"
        style={{ margin: 10 }}
        onClick={cb}
        >
			{name}
		</Button>
    )

    return (
        <Modal
            open={openModal}
            handleClose={onClose}
            header={
                <Box>
                    <Typography component="span" color="inherit">
                    <Box component="h3">
                        Lead Information
                    </Box>
                    </Typography>
                </Box>
            }
        >
            {renderInfo('Applicant Name', name)}
            {renderInfo('Mobile', mobileNo)}
            {renderInfo('Email', emailId)}
            {query && renderInfo('Query', query)}
            {renderInfo('Posted on', postedOn)}
            {renderInput('Remarks', newRemark, setNewRemark)}
            {renderDropdown('Status', Object.values(leadStatus), newStatus, setNewStatus)}
            {renderButton('Save Changes', saveChanges)}
        </Modal>
    )
}

export default UpdateLeadModal