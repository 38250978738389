export const BoardID = {
    OTHER:0,
    CBSE: 1,
    ICSE: 2,
    IITJEE: 3,
    NEET: 4,
    RAILWAY: 5,
  };
  
  export const getBoardDesc = (code: number) => {
    switch (code) {
      case BoardID.OTHER:
        return "Other Board";
      case BoardID.CBSE:
        return "Central Board of Secondary Education";
      case BoardID.ICSE:
        return "Indian Certificate of Secondary Education";
      case BoardID.IITJEE:
        return "IIT-Joint Entrance Examination";
      case BoardID.NEET:
        return "National Eligibility cum Entrance Test";
      case BoardID.RAILWAY:
        return "Railway";
    }
  };
  