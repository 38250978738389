import { debounce } from "lodash";
import React, { useState, useCallback, useRef, useEffect } from "react";

export function useDebounce(callback: any, delay: number) {
  const memoizedCallback = useCallback(callback, []);
  const debouncedFn = useRef(debounce(memoizedCallback, delay));

  useEffect(() => {
    debouncedFn.current = debounce(memoizedCallback, delay);
  }, [memoizedCallback, debouncedFn, delay]);

  return debouncedFn.current;
}