import React, { FunctionComponent, useState } from 'react';
import {
	Box,
	FormControl,
	FormHelperText,
	Grid,
	Input,
	Link,
	Theme,
	Typography
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import * as yup from 'yup';
import { useSnackbar } from "notistack"
import { useDispatch } from 'react-redux';
// This is hme  
import Button from '../../common/components/form_elements/button';
import LoginImg from '../../../assets/images/login.jpg';
import { fontOptions } from "../../common/theme"
import { EMAIL_PATTERN, } from '../../common/validations/patterns';
import { setForgotPasswordEmailId } from '../../redux/actions/authActions';
import { getOtp, verifyOtp } from '../../../api/auth';

const styles = (theme: Theme) =>
	createStyles({
		navLink: {
			fontFamily: fontOptions.family,
			fontSize: fontOptions.size.small,
			lineHeight: '134.69%',
			letterSpacing: '0.0125em',
			textDecorationLine: 'underline'
		},
		error: {
			"&:not(.Mui-disabled)::before": {
				borderColor: "#F44E42"
			}
		},
		loginGrid: {
			height: '100vH',
			backgroundColor: '#FFFFFF'
		},
		loginHead: {
			fontSize: fontOptions.size.mediumPlus,
			fontWeight: fontOptions.weight.bold,
			color: '#3D3D3D',
			paddingTop: '3px'
		},
		loginBtn: {
			paddingTop: '50px',
			'& button': {
				padding: '12px 60px 12px 60px',
				fontWeight: fontOptions.weight.bold,
				fontSize: fontOptions.size.small,
				color: '#FFFFFF',
				borderRadius: '3px',
			},
		},
	});

const ValidationSchema = yup.object().shape({
	email: yup
		.string()
		.required('email is a required field')
		.matches(EMAIL_PATTERN, 'email is invalid'),
});

interface Props extends RouteComponentProps<{ usertype?: string; }> { }
interface Props extends WithStyles<typeof styles> { }

interface FormData {
	email: string;
	// mobileNumber: string;
	serverError: string;
}


const ForgotPassword: FunctionComponent<Props> = ({ classes }) => {
	const { handleSubmit, register, errors, setError, clearError } = useForm<FormData>({
		mode: 'onBlur',
		validationSchema: ValidationSchema
	});

	const { enqueueSnackbar } = useSnackbar()
	const [redirectTo, setRedirectTo] = useState('');
	const [otp, setOtp] = useState('');
	const [validatedEmail, setValidatedEmail] = useState('');
	const [showOtpField, setShowOtpField] = useState(false);
	const dispatch = useDispatch();

	const handleResetPassword = async ({ email }: FormData) => {
		clearError('serverError');
		setShowOtpField(true);
		try {
			const otp = await getOtp(email);

			// setOtp(otp.toString());
			setValidatedEmail(email)
		} catch (error) {
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
		}
	}

	const handleValidateOTP = async () => {
		try {
			await verifyOtp(validatedEmail, otp);
			dispatch(setForgotPasswordEmailId(validatedEmail));
			setRedirectTo('/set-password');
		} catch (error) {
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<div>
			<Grid container>
				<Grid item xs={12} md={6} >
					<Box height="100%">
						<Box style={{ height: '100%' }}>
							<Grid item xs={12} >
								<img src={LoginImg} alt="ForgotPassword" style={{ height: '100vh' }} />
							</Grid>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md={6} className={classes.loginGrid}>
					<Box paddingY="30px" paddingX="100px" height="100%">
						<Box style={{ height: '100%' }}>
							<Grid
								container
								direction="row"
								alignItems="center"
								justify="center"
								style={{ height: '95%' }}
							>
								<Grid item xs={12}>
									<Typography className={classes.loginHead}>Forgot Password</Typography>
									<form onSubmit={handleSubmit(handleResetPassword)}>
										<FormControl fullWidth margin="normal">
											<Input
												name="email"
												inputProps={{ inputMode: 'email', maxLength: 50 }}
												placeholder="Enter Registered Email"
												// endAdornment={
												// 	<InputAdornment position="end">
												// 		<IconButton disabled size="small">
												// 			<MobileFriendlyIcon />
												// 		</IconButton>
												// 	</InputAdornment>
												// }
												inputRef={register}
												className={errors.email ? classes.error : ''}
												disabled={otp ? true : false}
											/>
											{errors.email && (
												<FormHelperText error>
													{errors.email.message}
												</FormHelperText>
											)}
										</FormControl>
										<Box
											textAlign="right"
											marginBottom="5px"
											className={classes.navLink}
										>
											<Link
												color="primary"
												to="/login"
												component={RouterLink}
											>
												Login
                      </Link>
										</Box>

										{
											showOtpField &&
											<FormControl fullWidth margin="normal">
												<Input
													name="otp"
													placeholder="Enter OTP"
													type='number'
													inputProps={{ inputMode: 'numeric', maxLength: 6 }}
													value={otp}
													onChange={(e) => setOtp(e.target.value)}
												/>
											</FormControl>
										}

										{!showOtpField ?
											<Box className={classes.loginBtn}>
												<Button
													disableElevation
													variant="contained"
													color="primary"
													size="large"
													type="submit"
												// disabled={isLoginButtonDisabled}
												>
													Reset My Password
                      </Button>
											</Box> :
											<Box className={classes.loginBtn}>
												<Button
													disableElevation
													variant="contained"
													color="primary"
													size="large"
													// type="submit"
													onClick={handleValidateOTP}
													disabled={otp.length == 6 ? false : true}
												>
													Validate
                      </Button>
											</Box>
										}
										{errors.serverError && (
											<FormHelperText error>
												{errors.serverError.message}
											</FormHelperText>
										)}
									</form>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Grid>
				<Box
					position="absolute"
					bottom="20px"
					left="20px"
					color="#666666"
					fontFamily={fontOptions.family}
					fontSize={fontOptions.size.small}
				>
					V 1.0
        </Box>
			</Grid>
		</div>
	)
};

export default withStyles(styles)(withRouter(ForgotPassword));
