import React, { FunctionComponent, useState, useEffect } from 'react';
import Modal from '../../common/components/modal';
import { Box, Container, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSnackbar } from 'notistack';
import Scrollbars from 'react-custom-scrollbars';
import { fontOptions } from '../../../theme';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '../../common/components/form_elements/button';
import { AutocompleteOption, QuestionBank } from '../contracts/qb_interface';

interface AllChapter {
  chaptername: string,
  subtoptics: string[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hover: {
      '&:hover': {
        cursor: 'pointer',
      }
    },
  }),
);

const filter = createFilterOptions<AutocompleteOption>();

interface Props {
  openModal: boolean;
  onClose: () => any;
  allChapter: AllChapter[];
  setAllChapter: React.Dispatch<React.SetStateAction<AllChapter[]>>;
  structuredQB: QuestionBank[];
  setStructuredQB: React.Dispatch<React.SetStateAction<QuestionBank[]>>
}

const ConfirmModal: FunctionComponent<Props> = ({openModal, onClose, allChapter, setAllChapter, structuredQB, setStructuredQB }) => {
  
  const [autocomplChap, setAutocomplChap] = useState<AutocompleteOption[]>([])
  const [autocomplSubTopic, setAutocomplSubTopic] = useState<AutocompleteOption[]>([])
  const [complChap, setComplChap] = useState<AutocompleteOption | null>();
  const [newsubtopic, setNewsubtopic] = useState('')
  const [trigger, setTrigger] = useState<number | string>(0)
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  console.log(complChap)
  useEffect(() => {
    if(typeof(trigger) === 'string'){
      setComplChap({
        title: trigger,
        value: trigger
      });
    } else{
      setComplChap(undefined)
    }
    setNewsubtopic('')
    const autoChap: AutocompleteOption[] = allChapter.map(ac => {
      return {
        title: ac.chaptername,
        value: ac.chaptername
      }
    })
    setAutocomplSubTopic([])
    setAutocomplChap(autoChap)
  }, [openModal, trigger]);

  const addChapter = (name: string) => {
    if(allChapter.some(ac => ac.chaptername === name)) {
      enqueueSnackbar('Chapter Name already Added', {variant: 'warning'});
    } else {
      const thisChapterSet = [...allChapter]
      thisChapterSet.push({chaptername: name, subtoptics: ['SubTopic 1']})
      setAllChapter(thisChapterSet)
      setTrigger(name)
      loadSubTopics(name, thisChapterSet)
    }
  }

  const removeChapter = (name: string) => {
    if(allChapter.length < 2) {
      enqueueSnackbar('There should be atleast one Chapter in the List', {variant: 'warning'});
    } else {
      const thisChapterSet = [...allChapter].filter(ac => ac.chaptername !== name)
      setComplChap(undefined)
      setAllChapter(thisChapterSet)
      setTrigger(Math.random())
      setAutocomplSubTopic([])
      const newSQB = [...structuredQB].filter(sqb => sqb.chaptername !== name)
      setStructuredQB(newSQB)
    }
  }

  const loadSubTopics = (name: string, chapList: AllChapter[]) => {
    if(name === '') {
      setAutocomplSubTopic([])
    } else {
      const thissub = chapList.find(ac => ac.chaptername === name)
      if(thissub) {
        const autosub: AutocompleteOption[] = thissub.subtoptics.map(subt => {
          return {
            title: subt,
            value: subt
          }
        })
        setAutocomplSubTopic(autosub)
      } else {
        setAutocomplSubTopic([])
      }
    }
  }

  const removeSubtopic = (name: string) => {
    const thischap = allChapter.findIndex(ac => ac.chaptername === complChap?.title)
    if(thischap > -1) {
      const thissub = allChapter[thischap].subtoptics
      if(thissub.length < 2) {
        enqueueSnackbar('There should be atleast one Subtopic in the List', {variant: 'warning'});
      } else {
        const updatedsubt = allChapter[thischap].subtoptics.filter(fl => fl !== name)
        const updatedChap = [...allChapter]
        updatedChap[thischap].subtoptics = updatedsubt
        setAllChapter(updatedChap)
        setAutocomplSubTopic(updatedsubt.map(list => {
          return {
            title: list,
            value: list
          }
        }))

        const newSQB = [...structuredQB].filter(sqb => sqb.chaptername !== allChapter[thischap].chaptername && sqb.subTopic !== name)
        setStructuredQB(newSQB)
      }
    }
  }

  const addNewSubtopic = () => {
    const currentChapter = complChap
    if(newsubtopic.length === 0) {
      enqueueSnackbar('Please Enter a Subtopic', {variant: 'warning'});
    } else {
      const thischap = allChapter.findIndex(ac => ac.chaptername === complChap?.title)
      if(thischap > -1) {
        const thissub = allChapter[thischap].subtoptics
        if(thissub.some(val => val === newsubtopic)) {
          enqueueSnackbar('This Subtopic is already added For the chapter', {variant: 'warning'});
        } else {
          thissub.push(newsubtopic)
          const updatedChap = [...allChapter]
          updatedChap[thischap].subtoptics = thissub
          setAllChapter(updatedChap)
          setAutocomplSubTopic(thissub.map(list => {
            return {
              title: list,
              value: list
            }
          }))
          setNewsubtopic('')
        }
      }
    }
  }

  return (
    <Modal
      open={openModal}
      handleClose={onClose}
      header={
        <Box width="700px" display="flex" alignItems="center">
          <Box marginLeft="15px">
            <Typography component="span" color="secondary">
              <Box component="h3" color="white" fontWeight="400" margin="0">
                Add Chapter and Subtopics
              </Box>
            </Typography>
          </Box>
        </Box>
      }
    >
      <Container>
        <Box width="100%">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                key={complChap === null ? 'true' : 'false'}
                options={autocomplChap}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.value) {
                    return option.value;
                  }
                  return option.title;
                }}
                freeSolo
                autoComplete
                includeInputInList
                onChange={(event, node) => {
                  if (typeof node === 'string') {
                    setComplChap({
                      title: node,
                      value: node
                    });
                    addChapter(node)
                  } else if (node && node.value) {
                  } else {
                    setComplChap({
                      title: '',
                      value: ''
                    });
                    loadSubTopics('', allChapter)
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (params.inputValue !== '') {
                    filtered.push({
                      value: params.inputValue,
                      title: `Add "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                renderOption={(option) => (
                  <React.Fragment>
                    <Grid container>
                      <Grid item xs={11}
                        onClick={() => {
                          setComplChap({
                            title: option.value,
                            value: option.value
                          });
                          if(option.value !== option.title) {
                            addChapter(option.value)
                          }
                          if(option.value === option.title) {
                            loadSubTopics(option.value, allChapter)
                          }
                        }}
                      >
                        {option.title}
                      </Grid>
                      {(option.value === option.title) &&
                        <Grid item xs={1}>
                          <IconButton size="small"
                            onClick={() => {
                              removeChapter(option.value)
                              loadSubTopics('', allChapter)
                              setComplChap(null)
                            }}
                          >
                            <Tooltip title="Remove">
                              <CancelIcon />
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      }
                    </Grid>
                  </React.Fragment>
                )}
                value={complChap}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Chapter List"
                    variant="outlined"
                    onChange={(e) => {
                      setComplChap({
                        title: e.target.value,
                        value: e.target.value
                      });
                    }}   
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Box style={{background: '#ffffff', border: '2px solid #EBF2FF', padding: '12px', borderRadius: '5px', height: '300px'}}>
                <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, marginBottom: '20px', marginLeft: '15px'}}>Subtopics</Typography>
                <Scrollbars autoHeight autoHeightMax="220px" autoHeightMin="220px" hideTracksWhenNotNeeded>
                  <div>
                    {autocomplSubTopic.map(subt => {
                      return (
                        <React.Fragment>
                          <Grid container style={{width: '100%', marginBottom: '5px', paddingLeft: '15px', paddingRight: '15px'}}>
                            <Grid item xs={11}>
                              <Typography style={{color: '#3D3D3D'}}>{subt.value}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton size="small"
                                onClick={() => {
                                  removeSubtopic(subt.value)
                                }}
                              >
                                <Tooltip title="Remove">
                                  <CancelIcon />
                                </Tooltip>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      )
                    })}
                    {complChap &&
                      <React.Fragment>
                        <Grid container style={{width: '100%', marginTop: '5px', marginBottom: '5px', paddingLeft: '15px', paddingRight: '15px'}}>
                          <Grid item xs={12}>
                            <TextField style={{width: '100%'}} placeholder="Enter Subtopic" value={newsubtopic} 
                              onChange={(e) => {
                                setNewsubtopic(e.target.value)
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} style={{marginTop: '10px'}}>
                            <Button color="primary" variant="outlined" disableElevation style={{width: '100%'}}
                              onClick={addNewSubtopic}
                            >
                              Add this Subtopic
                            </Button>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  </div>
                </Scrollbars>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Modal>
  );
}

export default ConfirmModal