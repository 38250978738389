import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography, IconButton, Box, ListItem, ListItemIcon, ListItemText, FormControl, Input, InputAdornment, FormHelperText, Button, Divider } from '@material-ui/core';
import ModalTwo from '../../common/components/modal2';
import { makeStyles, Theme, createStyles, darken } from '@material-ui/core/styles';
import { fontOptions } from '../../../theme';
import { Cancel as CancelIcon } from '@material-ui/icons';
import {
    AllInclusive as AllInclusiveIcon,
    CheckCircle as CheckCircleIcon
} from '@material-ui/icons';
import PasswordVisibilityButton from '../../common/components/password_visibility_button';
import * as yup from 'yup';
import { PASSWORD_LENGTH, PASSWORD_PATTERN } from '../../common/validations/patterns';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    small: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    otherAcc: {
      background: '#F5F5F5',
    },
    roleslt: {
      backgroundColor: '#FFFFFF',
      padding: '15px',
      borderBottom: '1px solid #DADCE0',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#DADCE0'
      },
    },
    edumaticaLogo: {
      width: '50x',
      height: '50px',
    },
    error: {
        "&:not(.Mui-disabled)::before": {
          borderColor: "#F44E42"
        }
    },
    unselected: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: darken('#FFFFFF', 0.1)
        },
    },
    selected: {
        cursor: 'pointer',
        backgroundColor: darken('#FFFFFF', 0.1)
    }
  })
);

interface Data {
    id: string;
    name: string;
    ownerId: string;
    mobileNo: string;
    emailId: string;
    userType: string;
}


interface FormData {
  password: string;
  passwordConfirmation: string;
  serverError: string;
}

const ValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required()
    .matches(
      PASSWORD_PATTERN,
      'Password must contain at least one uppercase, lowercase, alphanumeric & special character'
    )
    .matches(
      PASSWORD_LENGTH,
      'Password length must be between 8-20 characters'
    ),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'Both passwords did not match. Try again.')
});

interface Props {
    openModal: boolean;
    onClose: () => any;
    userTBS: Data | undefined;
    handleRegister: ({ password }: FormData) => Promise<void>;
    mobileSignUp: boolean
}

const SignupModal: FunctionComponent<Props> = ({
    openModal,
    onClose,
    userTBS,
    handleRegister,
    mobileSignUp
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { errors, handleSubmit, register, setError } = useForm<FormData>({
    mode: 'onBlur',
    validationSchema: ValidationSchema
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);

  return (
    <ModalTwo
      open={openModal}
      handleClose={() => {
        onClose()
      }}
      remHead={true}
      remXPad={true}
      maxW={'65vW'}
    >
        {userTBS && 
            <Grid container>
                <Grid item xs={11} style={{paddingLeft: '15px', paddingBottom: '15px', borderBottom: '1px solid #DADCE0'}}>
                    <Typography style={{color: '#5F6368', marginTop: '3px', fontWeight: fontOptions.weight.bold}}>{`Sign up ${userTBS.name ? userTBS.name : 'Parent'} using ${mobileSignUp ? userTBS.mobileNo : userTBS.emailId}`}</Typography>
                </Grid>
                <Grid item xs={1} style={{float: 'right', paddingBottom: '15px', borderBottom: '1px solid #DADCE0'}}>
                    <IconButton
                        size="small"
                        onClick={onClose}
                    >
                        <CancelIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={12} style={{paddingLeft: '25px', paddingRight: '25px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography style={{marginTop: '40px', fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, color: '#3D3D3D'}}>Set a Password</Typography>
                            <form onSubmit={handleSubmit(handleRegister)}>
                                <FormControl fullWidth margin="normal">
                                    <Input
                                        name="password"
                                        placeholder="Enter the password"
                                        inputProps={{ maxLength: 20 }}
                                        type={isPasswordVisible ? 'text' : 'password'}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <PasswordVisibilityButton
                                                isVisible={isPasswordVisible}
                                                handleChange={(isVisible) =>
                                                    setIsPasswordVisible(isVisible)
                                                }
                                            />
                                        </InputAdornment>
                                        }
                                        inputRef={register}
                                        className={errors.password ? classes.error : ''}
                                    />
                                    {errors.password && (
                                        <FormHelperText error>
                                        {errors.password.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth margin="normal">
                                    <Input
                                        name="passwordConfirmation"
                                        placeholder="Enter the password again"
                                        inputProps={{ maxLength: 20 }}
                                        type={isPasswordConfirmationVisible ? 'text' : 'password'}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <PasswordVisibilityButton
                                            isVisible={isPasswordConfirmationVisible}
                                            handleChange={(isVisible) =>
                                                setIsPasswordConfirmationVisible(isVisible)
                                            }
                                            />
                                        </InputAdornment>
                                        }
                                        inputRef={register}
                                        className={errors.passwordConfirmation ? classes.error : ''}
                                    />
                                    {errors.passwordConfirmation && (
                                        <FormHelperText error>
                                        {errors.passwordConfirmation.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth margin="normal">
                                    <Button
                                        disableElevation
                                        color="primary"
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                    >
                                        Signup User
                                    </Button>
                                    {errors.serverError && (
                                        <FormHelperText error>
                                        {errors.serverError.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </form>
                        </Grid>
                        <Grid item xs={6}>
                            <Box >
                                <ListItem dense>
                                    <ListItemIcon>
                                    <AllInclusiveIcon color="primary" />
                                    </ListItemIcon>

                                    <ListItemText>
                                    <Box component="h2">Please ensure that the Password should :</Box>
                                    </ListItemText>
                                </ListItem>

                                <ListItem dense>
                                    <ListItemIcon>
                                    <CheckCircleIcon color="primary" />
                                    </ListItemIcon>

                                    <ListItemText primary="Be at least 8 characters in length" />
                                </ListItem>

                                <ListItem dense>
                                    <ListItemIcon>
                                    <CheckCircleIcon color="primary" />
                                    </ListItemIcon>

                                    <ListItemText primary="Contains at least one uppercase and lowercase alphabetic characters (e.g. A-Z,a-z)" />
                                </ListItem>

                                <ListItem dense>
                                    <ListItemIcon>
                                    <CheckCircleIcon color="primary" />
                                    </ListItemIcon>

                                    <ListItemText primary="Have at least one numerical character (e.g. 0-9)" />
                                </ListItem>

                                <ListItem dense>
                                    <ListItemIcon>
                                    <CheckCircleIcon color="primary" />
                                    </ListItemIcon>

                                    <ListItemText primary="Have at least one special character (e.g. ~!@#$%^&*()_-+=)" />
                                </ListItem>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        }
    </ModalTwo>
  );
};

export default SignupModal;
