import axios from "axios"
import { getFilenameResponse, UploadDataResponse } from "../contracts/masterQuestion_interface"
import { CertificateObj } from "../interfaces"
import { DELETE_BY_FILENAME, GET_FILENAMES, UPLOAD_CERT, UPLOAD_DATA, MASTER_FLAGS } from "./route"

export const dataupload =async  (formData: FormData) =>{
    const response = await axios.post<UploadDataResponse>(UPLOAD_DATA, formData)
    return response.data.excelData
}

export const certupload =async  (data: CertificateObj) =>{
    const response = await axios.post(UPLOAD_CERT, data)
    return response.data
}

export const getcertupload =async () =>{
    const response = await axios.get<{ certificate: CertificateObj[]}>(UPLOAD_CERT)
    return response.data.certificate
}

export const delcertupload = async (certId: string) => {
    await axios.delete(UPLOAD_CERT, {params: {certId: certId}})
}
  
export const getcertuploadbyid  = async (id: string) => {
    const response = await axios.get<{ certificate: CertificateObj }>(`${UPLOAD_CERT}/${id}`)
    return response.data.certificate
}
  
export const putcertupload = async (certificate: CertificateObj) => {
    return axios.put(UPLOAD_CERT, certificate)
}

export const getFilename = async () =>{
    const response = await axios.get<getFilenameResponse>(GET_FILENAMES)
    return response.data.filenames
}


export const deleteByFilename = async (filename:string) =>{
    const response = await axios.delete(DELETE_BY_FILENAME,{params:{filename}})
    return response
}

export const getMasterFlag = async () =>{
    const response = await axios.get(MASTER_FLAGS)
    return response.data
}

export const updateMasterFlag = async (data: any) => {
    const response = await axios.post(MASTER_FLAGS,{...data})
    return response.data
}
