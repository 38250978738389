import axios from 'axios'
import { questionUpdateReq } from './interfaces'

const BASE_URL = process.env.REACT_APP_API + "admin/"
const GET_CUSTOM_TEMPLATE = BASE_URL + 'customTemplate'
const NOTIFICATION_TEMPLATE_TYPES = BASE_URL + 'masterNotificationTemplateTypes'
const SMS_TEMPLATE = BASE_URL + 'customSmsTemplate'

export const fetchNotificationTemplateTypes = async () => {
    const response = await axios.get<{
        data: {
            smsTypes: string[],
            emailTypes: string[]
        }
    }>(NOTIFICATION_TEMPLATE_TYPES)
    return response.data.data
}

export const getCustomTemplate = async (ownerId: String, templateType:String) =>{
    const response = await axios.get(GET_CUSTOM_TEMPLATE, { params: { ownerId, templateType }
})
    return response.data
}

export const updateCustomTemplate = async (ownerId: String, templateType: String, template: String) => {
    const response = await axios.post(GET_CUSTOM_TEMPLATE, { ownerId, templateType, template })
    return response.data
}

export const getCustomSmsTemplate = async (ownerId: String, templateType:String) =>{
    const response = await axios.get(SMS_TEMPLATE, { params: { ownerId, templateType }
})
    return response.data
}

export const updateCustomSmsTemplate = async (ownerId: String, templateType: String, template: String) => {
    const response = await axios.post(SMS_TEMPLATE, { ownerId, templateType, template })
    return response.data
}
