import { createStyles, makeStyles } from "@material-ui/core";
import { fontOptions } from "../../../../theme";

const useStyles = makeStyles((theme) =>

createStyles({
		radioButtons: {
		backgroundColor: "#C4C4C4",
		borderWidth: "6",
		padding: 10,
		marginTop: 20
		},
		paddClass: {
			// padding: '0px 30px'
		},
		label: {
			fontWeight: fontOptions.weight.bold,
			fontSize: fontOptions.size.small,
			width: '100%'
		},
		error: {
			"&:not(.Mui-disabled)::before": {
				borderColor: "#F44E42"
			}
		},
		margin: {
			margin: theme.spacing(1), width: '100%'
		},
		header: {
			backgroundColor: '#C4C4C4',
			width: '100%',
			padding: '10px',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center'
		},
		card: {
			// margin: '20px',
			paddingBottom: '60px',
			width: '100%'
		},
		cardContent: {
			width: '60%'
		},
		test: {
			// backgroundColor: 'red'
		},
		previousLabel: {
			fontWeight: fontOptions.weight.normal,
			fontSize: fontOptions.size.small,
			padding: '0px 20px',
			color: '#000000',
			opacity: 0.4
		},
		gridList: {
			width: 500,
			height: 450,
			// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
			transform: 'translateZ(0)',
		},
		webCard: {
			width: '100%',
		},
		submitBtn: { margin: '20px 0px' },
		parallerButtons: {
			display: "flex",
			alignItems: 'center',
			width: '100%',
		}
	})
);

export default useStyles;