import React, { FunctionComponent, useState, useEffect } from 'react';
import {
	DataGrid,
	GridCellParams,
	GridColumns,
	GridToolbar,
} from '@material-ui/data-grid';
import { Box, Container, makeStyles } from '@material-ui/core';
import * as dateFns from 'date-fns'
import Datagrids, { datagridCellExpand } from '../../dashboard/components/dataGrid';

interface RowData {
	orderId: string,
	receipt: string,
	userName: string,
	mobileNo: string,
	emailId: string,
	currency: string,
	amount_paid: number,
	gatewayServiceCharge: number,
	serviceChargeTax: number,
	settlementAmount: number,
	convenience_fee: number,
	convenience_fee_tax: number,
	edumatica_earning: number,
	edumatica_earning_tax: number,
	tds: number,
	studentPayableAmt: number,
	netAmountToBene: number,
	paymentMode: string,
	created_at: string,
	txStatus: string,
	settlementStatus: string,
	settledOn: string,
	settlementUTR: string,
	isRefunded: boolean,
}

interface Props {
	gridData: RowData[];
}

const useStyles = makeStyles({
	root: {
		'& .super-app-theme--cell': {
			backgroundColor: 'rgba(224, 183, 60, 0.55)',
			color: '#1a3e72',
			fontWeight: '600'
		},
		'& .super-app.negative': {
			backgroundColor: '#d47483',
			color: '#1a3e72',
			fontWeight: '600'
		},
		'& .super-app.positive': {
			backgroundColor: 'rgba(157, 255, 118, 0.49)',
			color: '#1a3e72',
			fontWeight: '600'
		}
	},
	paginate: {
		display: "flex"
	}
});

export const TxDataTable: FunctionComponent<Props> = ({
	gridData
}) => {
	const classes = useStyles();

	const columns: GridColumns = [
		{ field: 'id', headerName: 'S.No', width: 100 },
		{ field: 'orderId', headerName: 'Order ID', type: 'string', width: 300, renderCell: datagridCellExpand },
		{ field: 'receipt', headerName: 'Reference ID', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'userName', headerName: 'Customer Name', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'mobileNo', headerName: 'Mobile No.', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'emailId', headerName: 'Email ID', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'currency', headerName: 'Currency', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'amount_paid', headerName: 'Net Amount', width: 150, renderCell: datagridCellExpand },
		{ field: 'gatewayServiceCharge', headerName: 'Service Charge', width: 150, renderCell: datagridCellExpand },
		{ field: 'serviceChargeTax', headerName: 'ST/GST', width: 150, renderCell: datagridCellExpand },
		{ field: 'settlementAmount', headerName: 'Settlement Amount', width: 150, renderCell: datagridCellExpand },
		{ field: 'convenience_fee', headerName: 'Convenience Fee', width: 150, renderCell: datagridCellExpand },
		{ field: 'convenience_fee_tax', headerName: 'Tax on Conv. Fee', width: 150, renderCell: datagridCellExpand },
		{ field: 'edumatica_earning', headerName: 'Earning on Conv. Fee', width: 150, renderCell: datagridCellExpand },
		{ field: 'edumatica_earning_tax', headerName: 'Earning tax on Conv. Fee', width: 150, renderCell: datagridCellExpand },
		{ field: 'tds', headerName: 'TDS', width: 150, renderCell: datagridCellExpand },
		{ field: 'studentPayableAmt', headerName: 'Student Payable Amount', width: 150, renderCell: datagridCellExpand },
		{ field: 'netAmountToBene', headerName: 'Net Amount To Bene', width: 150, renderCell: datagridCellExpand },
		{ field: 'paymentMode', headerName: 'Payment Mode', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'created_at', headerName: 'Tx Time', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'txStatus', headerName: 'Tx Status', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'settlementStatus', headerName: 'Settlement Status', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'settledOn', headerName: 'Settled On', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'settlementUTR', headerName: 'Settlement UTR', type: 'string', width: 200, renderCell: datagridCellExpand },
		{ field: 'isRefunded', headerName: 'Refunded', type: 'string', width: 150, renderCell: datagridCellExpand },
	];

	const [rows, setRows] = useState<RowData[]>([])

	useEffect(() => {
		setRows(() => {
			return gridData?.map((data, index) => {
				return {
					id: index + 1,
					orderId: data.orderId,
					receipt: data.receipt,
					userName: data.userName,
					mobileNo: data.mobileNo,
					emailId: data.emailId,
					currency: data.currency,
					amount_paid: data.amount_paid,
					gatewayServiceCharge: data.gatewayServiceCharge,
					serviceChargeTax: data.serviceChargeTax,
					settlementAmount: data.settlementAmount,
					convenience_fee: data.convenience_fee,
					convenience_fee_tax: data.convenience_fee_tax,
					edumatica_earning: data.edumatica_earning,
					edumatica_earning_tax: data.edumatica_earning_tax,
					tds: data.tds,
					studentPayableAmt: data.studentPayableAmt,
					netAmountToBene: data.netAmountToBene,
					paymentMode: data.paymentMode,
					created_at: dateFns.parseISO(data.created_at).toLocaleDateString("en-GB"),
					txStatus: data.txStatus,
					settlementStatus: data.settlementStatus,
					settledOn: data.settledOn,
					settlementUTR: data.settlementUTR,
					isRefunded: data.isRefunded
				} as RowData
			}) as RowData[]
		})
	}, [gridData])

	return (
		<Box
			className={classes.root}
			style={{
				width: '100%',
				height: '700px',
				marginTop: '10px',
				display: 'flex'
			}}
		>
			<Datagrids gridRows={rows} gridColumns={columns} showToolBar={true} />
		</Box>
	);
};
