import React, { FunctionComponent, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  Input,
  Typography,
  Button,
  Select,
  MenuItem
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { compareAsc } from 'date-fns'
import { useSnackbar } from 'notistack';

import { jobApplicationRes, jobPostingRes, jobPostingRow, jobPostingStatus, updateJobPostingReq } from '../interfaces';
import Modal from '../../common/components/modal';
import useStyles from '../../leadsManagement/styles';
import { fetchDownloadUrlForCv, updateApplicant, updateApplicants, updateJob } from '../api';
import { convertDateString } from '../../common/helpers';

interface Props {
    openModal: boolean;
    onClose: () => any;
    job: jobPostingRow;
}

const EditJobPostingModal: FunctionComponent<Props> = ({
    openModal,
    onClose,
    job
}) => {
    const {_id, name, jobId, brief, department, endDate, fullJD, startDate, status} = job
    const [newName, setNewName] = useState(name)
    const [newBrief, setNewBrief] = useState(brief)
    const [newStartDate, setNewStartDate] = useState(convertDateString(startDate))
    const [newEndDate, setNewEndDate] = useState(convertDateString(endDate))
    const [newFullJD, setNewFullJD] = useState(fullJD)
    const [newStatus, setNewStatus] = useState(status)

    const {enqueueSnackbar} = useSnackbar()

    const styles = useStyles()

    const handleDateChange = (d: Date, dateType: string) => {
        if(dateType === 'start') {
            d.setHours(0,0,0,0)
            setNewStartDate(d)
        }
        if(dateType === 'end') {
            d.setHours(23,59,0,0)
            setNewEndDate(d)
        }
    }

    const checkValidation = () => {
        if (compareAsc(newStartDate, newEndDate) == 1) {
			enqueueSnackbar("End date should be after start date", { variant: "warning" })
			return false
		}
        if(newName.length < 4) {
            enqueueSnackbar('Job title should be more than 4 characters', { variant: 'warning' });
			return false;
        }
        if(newBrief.length < 4) {
            enqueueSnackbar('Brief should be more than 4 characters', { variant: 'warning' });
			return false;
        }
        if (!newStartDate) {
			enqueueSnackbar('Enter start date', { variant: 'warning' });
			return false;
		}
        if (!newEndDate) {
			enqueueSnackbar('Enter end date', { variant: 'warning' });
			return false;
		}
        if (newFullJD.length < 5) {
			enqueueSnackbar('Job description should be more than 5 characters', { variant: 'warning' });
			return false;
		}
        return true
    }

    const saveChanges = async () => {
        if (!checkValidation()) return;
        const toUpdate: updateJobPostingReq = {_id}
        let updateCount = 0
        if(newName !== name) {
            toUpdate.name = newName
            updateCount++
        }
        if(newBrief !== brief) {
            toUpdate.brief = newBrief
            updateCount++
        }
        if(newFullJD !== fullJD) {
            toUpdate.fullJD = newFullJD
            updateCount++
        }
        if(newStartDate.getTime() !== new Date(convertDateString(startDate).setHours(0,0,0,0)).getTime()) {
            toUpdate.startDate = newStartDate
            updateCount++
        }
        if(newEndDate.getTime() !== new Date(convertDateString(endDate).setHours(0,0,0,0)).getTime()) {
            toUpdate.endDate = newEndDate
            updateCount++
        }

        if(updateCount === 0) {
            enqueueSnackbar('No changes made', {variant: "warning"})
            return;
        }

        await updateJob(toUpdate)
        .then(() => {
            enqueueSnackbar('Successfully updated', { variant: "success" })
            onClose()
        })
        .catch((e) => {
            enqueueSnackbar('Something went wrong', {variant: 'error'})
            console.log(e)
        })
    }

    const renderInfo = (key: string, value: string) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Box>{value}</Box>
            </FormControl>
            </Grid>
        </Grid>
    )

    const renderInput = (key: string, value: any, setter: any, maxLength?: number, multiline?: boolean) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Input
                placeholder={key}
                value={value}
                inputProps={{ maxLength: maxLength ? maxLength : 100 }}
                multiline={multiline}
                onChange={(e) => setter(e.target.value)}
                />
            </FormControl>
            </Grid>
        </Grid>
    )

    const renderDropdown = (key: string, list: string[], value: string, setter: any) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Select
                value={value}
                onChange={(e: any) => setter(e.target.value)}
                >
                    <MenuItem value="">Select</MenuItem>
                    {list.map((item: any, index: any) => (
                        <MenuItem value={item} key={index}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            </Grid>
        </Grid>
    )
    
    const renderDateInput = (key: string, value: Date, setter: any, dateType: string) => (
        <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
                <Box className={styles.label}>
                    {key}
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl fullWidth margin="normal">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={key}
                            value={value}
                            onChange={(d) => setter(d, dateType)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
            </Grid>
        </Grid>
    )
    
    const renderButton = (name: string, cb: any) => (
        <Button 
        variant="contained" 
        color="primary" 
        size="medium"
        style={{ margin: 10 }}
        onClick={cb}
        >
            {name}
        </Button>
    )

    return (
        <>
        <Modal
            open={openModal}
            handleClose={onClose}
            header={
                <Box>
                    <Typography component="span" color="inherit">
                    <Box component="h3">
                        Edit Job Posting
                    </Box>
                    </Typography>
                </Box>
            }
        >
            {renderInput('Job Title', newName, setNewName)}
            {renderInfo('Department', department)}
            {renderInfo('Job Id', jobId)}
            {renderInput('Brief', newBrief, setNewBrief)}
            {renderDropdown('Status', Object.values(jobPostingStatus), newStatus, setNewStatus)}
            {renderDateInput('Start Date', newStartDate, handleDateChange, 'start')}
            {renderDateInput('End Date', newEndDate, handleDateChange, 'end')}
            {renderInput('Job Description', newFullJD, setNewFullJD, 1000, true)}
            {renderButton('Save Changes', saveChanges)}
        </Modal>
        </>
    )
}

export default EditJobPostingModal