import React, {FunctionComponent, useState} from 'react'

import Modal from '../../common/components/modal';

interface Props {
    openBlockModal: boolean,
    handleClose: () => void,
    handleConfirmBlock: (blockReason: string) => void,
    renderInput: (...args : any[]) => JSX.Element,
    renderButton: (...args : any[]) => JSX.Element
}

const BlockTutorModal: FunctionComponent<Props> = ({
    openBlockModal,
    handleClose,
    handleConfirmBlock,
    renderInput,
    renderButton
}) => {
    const [blockReason, setBlockReason] = useState('')

    return (
        <>
        <Modal
        open={openBlockModal}
        handleClose={handleClose}
        header="Block Tutor"
        >
            {renderInput('Enter Reason for Blocking', blockReason, setBlockReason)}
            {renderButton('Block Tutor', () => handleConfirmBlock(blockReason))}
        </Modal>
        </>
    )
}

export default BlockTutorModal