import React, {FunctionComponent, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, FormControl, Grid, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useStyles } from './styles';
import { EditableMathField, StaticMathField } from 'react-mathquill';
import { mathSymbol } from '../../contracts/qb_interface';
import Button from '../../../common/components/form_elements/button';
import { fontOptions } from '../../../../theme';

interface Props {
    open: boolean,
    onClose: () => void,
    mathSymbols: mathSymbol[],
    handleSubmit: (x: mathSymbol) => void
}

const SymbolsDialog: FunctionComponent<Props> = ({open, mathSymbols, handleSubmit, onClose}) => {
    const classes = useStyles()
    const [selectedSymbol, setSelectedSymbol] = useState<mathSymbol>()
    const [selectedClassification, setSelectedClassification] = useState('')

    const classifications: string[] = []
    mathSymbols.forEach(cur => {
        const toPush = cur.classification.filter(cur => !classifications.includes(cur))
        classifications.push(...toPush)
    })

    const classifiedSymbols: mathSymbol[] = mathSymbols.filter(cur => selectedClassification === '' || cur.classification.includes(selectedClassification))

    const renderDropdown = (key: string, list: string[], value: string, setter: any) => (
        <Grid container>
            <Grid item xs={12}>
                <FormControl variant="outlined" style={{width: '100%'}}>
                    <InputLabel id="demo-simple-select-outlined-label">{key}</InputLabel>
                    <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={value}
                    onChange={(e: any) => setter(e.target.value)}
                    label={key}
                    >
                        <MenuItem value="">Select</MenuItem>
                        {list.map((item: any, index: any) => (
                            <MenuItem value={item} key={index}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )

    return (
        <>
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
            <DialogTitle id="form-dialog-title">
                <Box display="flex">
                    <Box flexGrow={1}>
                        Symbols
                    </Box>
                    <Box>
                        <Button onClick={onClose} color="primary">
                            Close
                        </Button>
                    </Box>
                </Box>            
            </DialogTitle>
            <DialogContent>
                {renderDropdown('Classifications', classifications, selectedClassification, setSelectedClassification)}

                <br />

                <Grid container>
                    {classifiedSymbols.map((cur) => {
                        return (
                            <Grid item xs={1}>
                                <Button style={{height: '50px', width: '50px'}} disableElevation onClick={() => handleSubmit(cur)}>
                                    <StaticMathField style={{cursor: 'pointer', color: '#3D3D3D', textTransform: 'none', fontWeight: fontOptions.weight.bold, fontFamily: 'Roboto', fontStyle: 'normal'}}>

                                        {cur.symbolicon}
                                    </StaticMathField>
                                </Button>
                            </Grid>
                        )
                    })} 
                </Grid>
            </DialogContent>
            <DialogActions>
            {/* <Button onClick={handleSubmit} color="primary">
                Ok
            </Button> */}
            </DialogActions>
        </Dialog>
        </>
    );
}

export default SymbolsDialog