import React, { useState, useEffect, FunctionComponent, } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
	Box, Container, Grid, Input, Typography, CssBaseline, Card, CardContent, CardHeader, IconButton, Select, MenuItem, Button
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"

import MiniDrawer from "../../../common/components/SideDrawer"
import useStyles from './styles';
import { CustomUser, } from '../../../common/contracts/dashboard';
import { RootState } from '../../../redux/store';
import { connect, useDispatch } from 'react-redux';
import { MASTER_PERMISSIONS, USER_ROLES, USER_TYPES, USER_TYPE_VALUES } from '../../../../utilities/constants';
import { getUser, updateUserPreferences } from '../../../../api/dashboard';
import { ArrowBack } from '@material-ui/icons';
import { getConsolidatedReport } from '../../api';
import { onApiError } from '../../../common/helpers';
import { fontOptions } from "../../../../theme";
import Datagrids from '../../components/dataGrid';
import { GridColDef } from '@material-ui/data-grid';
import { consolidatedReport } from '../../interfaces';
import { UserDataTable } from '../../components/UserDataTable';

interface Props extends RouteComponentProps {}

const ViewUsersOfEntity: FunctionComponent<Props> = ({history, location}) => {
    const [reportType, setReportType] = useState('student')

    const [gridRows, setGridRows] = useState<consolidatedReport[]>([])
    const [gridColumns, setGridColumns] = useState<GridColDef[]>([])

    const [redirectTo, setRedirectTo] = useState('')
    const {enqueueSnackbar} = useSnackbar()
    const styles = useStyles()

    // @ts-ignore
    const {userType, userId, username} = location.state
    if(!userType || !userId || !username) setRedirectTo('/dashboard')

    if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

    const isValid = () => {
        if(reportType.length === 0) {
            enqueueSnackbar('Please select a Report Type', {variant: 'warning'})
            return false
        }
        return true
    }

    const createDataGrid = (list: consolidatedReport[]) => {
        setGridRows(list.map((el, i) => Object.assign({}, el, {id: i+1})))
    }

    const handleFetchConsolidatedReport = async () => {
        try {
            if(!isValid()) return
            const report = await getConsolidatedReport({
                userType,
                modelId: userId,
                reportType: reportType.toLowerCase()
            })
            createDataGrid(report)
        } catch (error) {
            onApiError(error, enqueueSnackbar, setRedirectTo)
        }
    }

    return (
        <>
        <CssBaseline />
        <MiniDrawer>
            <div style={{ height: '80vh', width: '100%' }}>
                <Container style={{ width: '100%' }}>
                    <Grid item className={styles.header}>
                        <IconButton onClick={() => history.goBack()}>
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h5" >{'View Customer\'s Users'}</Typography>
                    </Grid>
                    
                    <Box
                        bgcolor="#FFFFFF"
                        padding="20px 30px"
                        marginBottom="30px"
                        position="relative"
                        borderRadius="5px"
                    >
                        <Grid container>
                            <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
                                <Typography style={{fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, color: '#3D3D3D'}}>
                                    {`${userType === 'org' ? 'Institute' : 'Tutor'} - ${username}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{marginBottom: '10px'}}>
                                <Box display="flex" flexDirection="row-reverse">
                                    <Box p={1}>
                                        <Select
                                            style={{width: '110px'}}
                                            value={reportType}
                                            onChange={(e) => setReportType(e.target.value as string)}
                                        >
                                            {userType === 'org' && <MenuItem value="tutor">Tutors</MenuItem>}
                                            <MenuItem value="student">Students</MenuItem>
                                        </Select>
                                        <Button variant="contained" color="primary" size="medium" style={{ margin: 10 }}
										onClick={handleFetchConsolidatedReport}
                                        >
                                            Fetch Report
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>

                            {gridRows.length > 0 && <Grid item xs={12} style={{marginTop: '15px'}}>
                                <UserDataTable key={reportType} gridData={gridRows} noOwnerDetails={true} userType={userType} />
                            </Grid>}
                        </Grid>
                    </Box>
                </Container>
            </div >
        </MiniDrawer>
        </>
    )
}

export default ViewUsersOfEntity