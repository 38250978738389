import axios from "axios";

const BASE_URL = process.env.REACT_APP_API + "appLogs/";
const NATIVE_SECRET_KEY =
  process.env.REACT_APP_REACT_NATIVE_APP_EXCEPTION_LOG_KEY;

const GET_ALL_APP_LOGS = BASE_URL + "getAllAppLogs";
const DELETE_ALL_APP_LOGS = BASE_URL + "deleteAllAppLogs";

export const getAllAppLogs = async () => {
  const response = await axios.get(GET_ALL_APP_LOGS, {
    headers: {
      nativeSecretKey: NATIVE_SECRET_KEY,
    },
  });
  return response.data;
};

export const deleteAllAppLogs = async () => {
  const response = await axios.delete(DELETE_ALL_APP_LOGS, {
    headers: {
      nativeSecretKey: NATIVE_SECRET_KEY,
    },
  });
  return response.data;
};
