import React, { useState, useEffect, FunctionComponent } from "react";
import { Redirect } from 'react-router'
import {
	Box,
	Container,
	CssBaseline,
	darken,
	FormControl,
	Grid,
	makeStyles,
	MenuItem,
	Select,
	Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import Button from "../../../common/components/form_elements/button";
import { GridColDef } from "@material-ui/data-grid";
import MiniDrawer from "../../../common/components/SideDrawer";
import { fontOptions } from "../../../../theme";
import { MASTER_PERMISSIONS } from "../../../../utilities/constants";
import Datagrids, { datagridCellExpand } from "../../components/dataGrid";
import { getLoggedInUserData, onApiError} from '../../../common/helpers';
import { fetchAcademicDetails } from "../../api";
import { academicResponse, academicRow } from "../../interfaces";

const useStyles = makeStyles({
	typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121',
	  },
      label: {
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.small,
      },
});

const ViewAcademicReports: FunctionComponent = () => {
    const classes = useStyles();

    const [userType, setUserType] = useState('Organization')
    const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().setHours(0,0,0,0)))
    const [endDate, setEndDate] = useState<Date | null>(new Date(new Date().setHours(23,59,59,999)))

    const [loading, setLoading] = useState(false)
    const [showDataGrid, setShowDataGrid] = useState(false)
    const [gridRows, setGridRows] = useState<academicRow[]>([])
    const [gridColumns, setGridColumns] = useState<GridColDef[]>([])

    const [redirectTo, setRedirectTo] = useState<string>('')
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        getLoggedInUserData(setRedirectTo, [MASTER_PERMISSIONS.liveClasses], enqueueSnackbar)
    }, [])

    if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

    const handleDateChange = (d: Date, dateType: string) => {
        if(dateType === 'start' && d) {
            d.setHours(0,0,0,0)
            setStartDate(d)
        }
        if(dateType === 'end' && d) {
            d.setHours(23,59,59,999)
            setEndDate(d)
        }
    }

    const handleFetchAcademicReports = async () => {
        setLoading(true)
        if(!startDate || !endDate) {
            enqueueSnackbar('Select valid Dates', {variant: 'warning'})
            setLoading(false)
            return;
        }
        if(startDate.getTime() > endDate.getTime()) {
            enqueueSnackbar('Start Date has to be before End Date', {variant: 'warning'})
            setLoading(false)
            return;
        }
        if(userType.length < 3) {
            enqueueSnackbar('Select an User Type', {variant: 'warning'})
            setLoading(false)
            return;
        }
        await fetchAcademicDetails({startDate, endDate, userType: userType === 'Tutor' ? 'tutor' : 'org'})
        .then(val => {
            createDataGrid(val)
            setShowDataGrid(true)
        })
        .catch(err => onApiError(err, enqueueSnackbar, setRedirectTo))
        .finally(() => setLoading(false))
    }

    const createDataGrid = (list: academicResponse[]) => {
		function createData(academicRes: academicResponse, i: number) {
			const { assignedStudents, attemptedAssessments, contentSize, evaluatedStudents, organizationName, totalAssessments, totalAssignments, totalQb, totalStudentWs, totalStudents, totalTutors, tutorName } = academicRes
            const userName = organizationName ? organizationName : tutorName
			return {
				id: i+1, assignedStudents, attemptedAssessments, contentSize: Math.round(contentSize/1024/1024/1024).toFixed(2), evaluatedStudents, organizationName, totalAssessments, totalAssignments, totalQb, totalStudentWs, totalStudents, totalTutors, tutorName, userName
			}
		}

		const rows: academicRow[] = list.map((cur, i) => createData(cur, i))

		setGridRows(rows)

		const columns = [
			{ field: 'id', headerName: 'S.No', flex: 0.5 },
			{ field: 'userName', headerName: 'Name', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'totalStudents', headerName: 'Students', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'contentSize', headerName: 'Content Size(GB)', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'totalAssessments', headerName: 'Assessments', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'attemptedAssessments', headerName: 'Attempted Assessments', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'totalQb', headerName: 'Question Banks', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'totalStudentWs', headerName: 'Student Worksheets', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'totalAssignments', headerName: 'Assignments', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'assignedStudents', headerName: 'Assigned Students', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'evaluatedStudents', headerName: 'Evaluated Students', flex: 1, width: 100, renderCell: datagridCellExpand },
		]
        if(userType === 'Organization') columns.splice(2, 0, { field: 'totalTutors', headerName: 'Tutors', flex: 1, width: 100, renderCell: datagridCellExpand })
		setGridColumns(columns)
	}

    const renderDateInput = (key: string, value: any, setter: any, dateType: string) => (
        <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
                <Box className={classes.label}>
                    {key}
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl fullWidth margin="normal">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={key}
                            value={value}
                            onChange={(d) => setter(d, dateType)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
            </Grid>
        </Grid>
    )

    const renderDropdown = (key: string, value: any, list: string[], setter: any) => (
        <Grid container>
			<Grid item xs={12} md={4}>
				<FormControl fullWidth margin="normal">
					<Box className={classes.label}>{key}</Box>
				</FormControl>
			</Grid>

			<Grid item xs={12} md={8}>
				<FormControl fullWidth margin="normal">
					<Select
						value={value}
						onChange={(e: any) => setter(e.target.value)}
					>
						<MenuItem value="">Select</MenuItem>
						{list.map((item: any, index: any) => (
							<MenuItem value={item} key={index}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
    )

    const renderButton = (name: string, cb: any, isLoading: boolean) => (
        <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item>
                {isLoading ? <p>Loading...</p> : <Button variant="contained" color="primary" size="medium"
                    onClick={cb}
                >
                    {name}
                </Button>}
            </Grid>
        </Grid>
    )

    return(
        <>
        <CssBaseline />
        <MiniDrawer>
        <Container maxWidth="lg">
            <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="5px"
            color='#fff'
            >
            <Grid item container>
                <Grid item sm={8}>
                <Box style={{height: '100%'}}>
                    <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                    >
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            View User Engagement Reports
                        </Typography>
                        <Typography>
                            View User Engagement Reports between two Dates
                        </Typography>
                    </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            </Box>

            <Box
				bgcolor="#ffffff"
				borderRadius="5px"
				padding="12px 25px"
				marginTop='25px'
			>
                <Grid container justify='center'>
                    <Grid item xs={12} lg={6} style={{paddingTop: '5px', marginBottom: '8px'}}>
                        {renderDropdown('User Type', userType, ['Tutor', 'Organization'], setUserType)}
                        {renderDateInput('Start Date', startDate, handleDateChange, 'start')}
                        {renderDateInput('End Date', endDate, handleDateChange, 'end')}
                        {renderButton('Fetch Reports', handleFetchAcademicReports, loading)}
                    </Grid>
                    <Grid item xs={12}>
                        {showDataGrid && <Datagrids key={'key' + userType + startDate + endDate} gridRows={gridRows} gridColumns={gridColumns} disableCheckbox />}
                    </Grid>
                </Grid>
            </Box>   
        </Container>
        </MiniDrawer>
        </>
    )
}

export default ViewAcademicReports;