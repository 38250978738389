import React, { FunctionComponent } from 'react';
import { Modal as BaseModal, Box, IconButton, Theme, useMediaQuery } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const mediaQueries = {
	mobile: '(min-width:400px)',
	tablet: '(min-width:600px)',
	desktop: '(min-width:1025px)',
};
const styles = (theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
			// [theme.breakpoints.down('sm')]: {
      //   display: 'block',
      //   height: '100%',
      //   overflow: 'scroll',
      //   position: 'absolute',
      //   left: '10%',
      //   top: '10%'
      // }
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        overflow: 'scroll',
				alignItems:'center',
				margin:'10% 0'
      }
    },
    colorTheme : {
      backgroundColor : theme.palette.primary.main
    },
    colorWhite : {
      backgroundColor : '#fff'
    },

    modalContainer: {
      borderRadius: '5px',
      outline: 'none',
      overflow: 'hidden'
    },

    modalBody: {
      [theme.breakpoints.up('sm')]: {
        maxHeight: '85vH',
        minWidth: '40vW',
        'overflow-y': 'auto'
      }
    },

    modalBody2: {
      [theme.breakpoints.up('sm')]: {
        maxHeight: '80vH',
        minWidth: '45vW',
        'overflow-y': 'auto'
      }
    },

    closeButton: {
      color: '#FFF'
    }
  });

interface Props extends WithStyles<typeof styles> {
  header?: React.ReactNode;
  open: boolean;
  handleClose: () => any;
  mWidth?: 'small' | 'large'
  remHead?: boolean
  remXPad?: boolean
  maxW?: string
  whiteHead?: boolean
}

const Modal: FunctionComponent<Props> = ({
  children,
  classes,
  handleClose,
  header,
  open,
  mWidth,
  remHead,
  remXPad,
  maxW,
  whiteHead
}) => {
  const removeHead = (remHead && remHead === true) ? true : false;
  const removeXPad = (remXPad && remXPad === true) ? true : false;
  const whiteHd = (whiteHead && whiteHead === true) ? true : false;
	const hasMoreWidth = useMediaQuery(mediaQueries.mobile);

  return (
    <BaseModal className={classes.modal} open={open} onClose={handleClose}>
      <Box className={classes.modalContainer}>
        {!removeHead &&
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            padding="20px 20px 20px 40px"
            className={clsx(whiteHd ? classes.colorWhite : classes.colorTheme)}
          >
            {header && <Box marginRight="20px">{header}</Box>}

            <Box marginLeft="auto">
              <IconButton
                size="small"
                onClick={handleClose}
                className={classes.closeButton}
              >
                <CancelIcon  style={whiteHd ? {color: "#666666"} : {color: "#FFFFFF"}}  />
              </IconButton>
            </Box>
          </Box>
        }

        <Box
          className={(!mWidth || (mWidth === 'large')) ? classes.modalBody : classes.modalBody2}
          style={maxW && hasMoreWidth? {maxWidth: maxW} : {}}
          padding={removeXPad ? "20px 0px 30px 0px" : "20px 40px 30px 40px"}
          bgcolor="white"
        >
          {children}
        </Box>
      </Box>
    </BaseModal>
  );
};

export default withStyles(styles)(Modal);
