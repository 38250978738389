import axios from 'axios'
// import { questionUpdateReq } from './interface'

const BASE_URL = process.env.REACT_APP_API + "admin/"
const BASE= process.env.REACT_APP_API + "meta/"
const META_TAGS = BASE_URL + 'createMetaTag'
const GET_ALL_TAGS = BASE + 'getAllMetaTags'
const GET_TAG = BASE + 'getMetaTag'
const EDUMATICA_MARKET_PLACE = process.env.REACT_APP_API + 'edumaticamarketplace'
export const GET_COURSE_MARKETPLACE_LIST = EDUMATICA_MARKET_PLACE + '/coursesearch';


export const createMetaTags = async (keyWords:String[], link:String[],scripts:String[],h1Tags:String[],h2Tags:String[],
    h3Tags:String[],h4Tags:String[],h5Tags:String[],h6Tags:String[],
    addressFriendlyName:String,address:string,title:string,description:string) => {
    const response = await axios.post(META_TAGS, { keyWords,link,scripts,h1Tags,h2Tags,
        h3Tags,h4Tags,h5Tags,h6Tags,addressFriendlyName,address,title,description })
    return response.data
}

export const getAllMetaTags = async ()=>{
    const response = await axios.get(GET_ALL_TAGS)
    return response.data.data
  }
  export const getMetaTag = async (address:string)=>{
    const response = await axios.get(GET_TAG,{params:{address:address}})
    return response.data.data
  } 
  export const fetchCourseListMarketPlace = async (search: string) => {
    const response = await axios.get<{ publiccourses: any[] }>(GET_COURSE_MARKETPLACE_LIST, { params: { search: search } });
    return response.data.publiccourses;
  }
