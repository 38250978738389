import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link, Redirect, RouteComponentProps } from 'react-router-dom'
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../utilities/constants';
import * as XLSX from 'xlsx';
import MiniDrawer from "../../common/components/SideDrawer"
import { Box, Button, Container, CssBaseline, darken, FormControl, FormHelperText, Grid, makeStyles, MenuItem, Typography, Select, CircularProgress, Input } from '@material-ui/core';
import { fontOptions } from '../../../theme';
import DownloadIcon from '../../../assets/images/download-icon.png';
import UploadIcon from '../../../assets/images/upload-icon.png';
import { MasterChapter } from '../contracts/masterChapter_interface';
import { MasterChaptersDataTable } from '../components/MasterChaptersDataTable';
import { BoardID, getBoardDesc } from '../utils/boardDescIdpair';
import { getClassDesc } from '../utils/classIdpair';
import { getSubDesc, SubjectID } from '../utils/subjectIdpair';
import { MasterCityPin } from '../contracts/masterCityPin_interface';
import { MasterSchool } from '../contracts/masterSchool_interface';
import { MasterCityPinDataTable } from '../components/MasterCityPinDataTable';
import { MasterSchoolDataTable } from '../components/MasterSchoolDataTable';
import { uploadMasterChapters, uploadMasterCities, uploadMasterSchools, uploadMasterQuestions, fetchMasterCities, fetchMasterSchools, fetchMasterChapters, fetchMasterQuestions } from '../api';
import { MasterQualification } from '../contracts/masterQualifiation_interfae';
import { MasterQualifiationsDataTable } from '../components/MasterQualifiationsDataTable';
import { getMasterFlag, updateMasterFlag } from "../helpers/api"
import "./style.css"
const useStyles = makeStyles({
	typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		// fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.small,
		letterSpacing: '1px',
		color: '#212121',
	  },
});

function masterChapters(
	boardID: number,
    boardName: string, 
    boardDescriptions: string,
    classID: number,
    className: string, 
    subjectID: number,
	subjectName: string, 
	chapter: {
        name: string,
        topic: [string]
    },
  ) {
	return {boardID, boardName, boardDescriptions, classID, className, subjectID, subjectName, chapter};
}
      
interface Props extends RouteComponentProps {

}

const MasterUploads: FunctionComponent<Props> = ({ location }) => {
	const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [activeIndex, setActiveIndex] = useState(-1);
	const [activeUploadOption, setActiveUploadOption] = useState<string | null>(null)
	const [viewData, setViewData] = useState<any[] | null>(null)
	const [masterChapterData, setMasterChapterData] = useState<MasterChapter[] | null>(null)
	const [masterCityData, setMasterCityData] = useState<MasterCityPin[] | null>(null)
	const [masterSchool, setMasterSchool] = useState<MasterSchool[] | null>(null)
	const [masterQualification, setMasterQualification] = useState<MasterQualification[] | null>(null)
	const [loading, setLoading] = useState(false)
	const [keyName,setKeyName] = useState("");
	const [keyDescription,setKeyDescription] = useState("");
	const [keyValue,setKeyValue] = useState("");
	const [masterFlags, setMasterFlags] = useState<any[]>([])
	const uploadOptions = [
		"City/Pin Code Master",
		"School Master",
		"Subject,Topic Master",
		"Qualifiations Master",
		"Master Flags"
	]

	const fetchMasterData = async()=>{
		try {
			let res = await getMasterFlag()
			setMasterFlags(res.data)
			console.log('response', res)
		} catch (error) {
			console.log("error", error)
		}
	}
    useEffect(() => {
		getLoggedInUserData()
		fetchMasterData()
	}, []);


	const uploadMasterFlag = async ()=>{
		try {
			let data = {
				keyName,
				keyValue,
				keyDescription
			}
			let res = await updateMasterFlag(data)
			console.log("response", res)
			fetchMasterData()
		} catch (error) {
			console.log("error", error)
		}
	}

	useEffect(() => {
		if(activeIndex > -1) {
			setActiveUploadOption(uploadOptions[activeIndex])
			setViewData(null)
		}else {
			setActiveUploadOption(null)
			setViewData(null)
		}
		
	}, [activeIndex])

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		const requiredPermissions = [MASTER_PERMISSIONS.uploadCities]
		var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

    const readExcel = (file: File | null) => {
		setLoading(true)
		const promise = new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			if (file) {
				fileReader.readAsArrayBuffer(file);
				fileReader.onload = (e) => {
					const bufferArray = e.target ? e.target.result : '';
					const wb = XLSX.read(bufferArray, { type: 'buffer' });
					const wsname = wb.SheetNames[0];
					const ws = wb.Sheets[wsname];

					/* Convert array to json*/
					const jsonData = XLSX.utils.sheet_to_json(ws);
					resolve(jsonData);
				};
				fileReader.onerror = (error) => {
					reject(error);
				};
			}
		});
		promise.then(async (dataArr: any) => {
			// console.log(dataArr)
			const uploadReq: any = await Promise.all(
				dataArr &&
				dataArr.map(async (data: any) => {
					if(activeUploadOption === "City/Pin Code Master"){
						return {
							cityName: data["City"],
							pinCode: data["Pin Code"],
							stateName: data["State"],
						}
					}
					if(activeUploadOption === "School Master"){
						return {
							cityName: data["City"],
							schoolName: data["Name of Institution"],
							schoolAddress: data["Postal Address"],
						}
					}
					if(activeUploadOption === "Subject,Topic Master"){
						return {
							boardName: data["Board"],
							classID: data["Class"],
							subjectName: data["Subject"],
							topic: data["Topic"],
							subTopic: data["Sub_Topic"]
						};
					}
					if(activeUploadOption === "Qualifiations Master"){
						return {
							degree: data["Degree"],
    						subjectName: data["Subject"],
							// subjectName: "",
						};
					}
				})
			);
			if(activeUploadOption === "City/Pin Code Master"){
				setMasterCityData(uploadReq)
				setViewData(uploadReq)
			}
			if(activeUploadOption === "School Master"){
				setMasterSchool(uploadReq)
				setViewData(uploadReq)
			}
			if(activeUploadOption === "Subject,Topic Master"){
				let toUploadMasterChpterData:MasterChapter[] = [];
				// @ts-ignore
				uploadReq.map((el, index) => {
					const boardId = el.boardName === "CBSE" ? BoardID.CBSE : el.boardName === "ICSE" ? BoardID.ICSE : el.boardName === "IITJEE" ? BoardID.IITJEE : el.boardName === "NEET" ? BoardID.NEET : BoardID.OTHER
					const boardDesc = getBoardDesc(boardId)
					const classDesc = getClassDesc(el.classID)
					// const subjectId = Object.values(SubjectID).find((val,i)=>{
					// 	return getSubDesc(val)==="Other"?0:getSubDesc(val)
					// })
					const getSubjectId = async () =>{
						const Id = Object.values(SubjectID).find((val)=>{
						  return getSubDesc(val) === el.subjectName
					  	})
						return Id===undefined?0:Id
					}
					getSubjectId().then(subjectId => {
						const length = toUploadMasterChpterData.length
						const lastElem = toUploadMasterChpterData[length-1]
						console.log(lastElem)
						if(lastElem && el.classID === lastElem.classID && subjectId === lastElem.subjectID && lastElem.chapter.name === el.topic) {
							toUploadMasterChpterData[length-1].chapter.topic.push(el.subTopic)
						} else{
							const chapter = {
								name: el.topic,
								topic: [el.subTopic]
							}
							toUploadMasterChpterData.push(
								// @ts-ignore
								masterChapters(boardId, el.boardName, boardDesc, el.classID, classDesc, subjectId, el.subjectName, chapter)
							)
						}
					})
				// if(index === uploadReq.length -1)
				// console.log(toUploadMasterChpterData)
				})
				// console.log(toUploadMasterChpterData)
				setMasterChapterData(toUploadMasterChpterData)
				setViewData(uploadReq)
			}
			if(activeUploadOption === "Qualifiations Master"){
				setMasterQualification(uploadReq)
				setViewData(uploadReq)
			}
			setLoading(false)
		});
	};

	const onSuccess = (setter: any) => {
		enqueueSnackbar('Successfully uploaded', {variant: 'success'})
		setViewData(null)
		setter(null)
		setLoading(false)
	}

	const onError = () => {
		enqueueSnackbar('Something went wrong !!', {variant: 'warning'})
		setLoading(false)
	}

	const uploadData = async () => {
		setLoading(true)
		if(activeUploadOption === "City/Pin Code Master") {
			await uploadMasterCities(masterCityData)
			.then(() => onSuccess(setMasterCityData))
			.catch(() => onError())
		}
		if(activeUploadOption === "School Master") {
			await uploadMasterSchools(masterSchool)
			.then(() => onSuccess(setMasterSchool))
			.catch(() => onError())
		}
		if(activeUploadOption === "Subject,Topic Master") {
			await uploadMasterChapters(masterChapterData)
			.then(() => onSuccess(setMasterChapterData))
			.catch(() => onError())
		}
		if(activeUploadOption === "Qualifiations Master") {
			await uploadMasterQuestions(masterQualification)
			.then(() => onSuccess(setMasterQualification))
			.catch(() => onError())
		}
	}

	const fetchData = async () => {
		setLoading(true)
		if(activeUploadOption === "City/Pin Code Master") {
			await fetchMasterCities()
			.then((data) => {
				setLoading(false)
				// @ts-ignore
				setViewData(data.data.data)
			})
			.catch(() => onError())
		}
		if(activeUploadOption === "School Master") {
			await fetchMasterSchools()
			.then((data) => {
				setLoading(false)
				// @ts-ignore
				setViewData(data.data.data)
			})
			.catch(() => onError())
		}
		if(activeUploadOption === "Subject,Topic Master") {
			await fetchMasterChapters()
			.then((data) => {
				setLoading(false)
				// @ts-ignore
				setViewData(data.data.data)
			})
			.catch(() => onError())
		}
		if(activeUploadOption === "Qualifiations Master") {
			await fetchMasterQuestions()
			.then((data) => {
				setLoading(false)
				// @ts-ignore
				setViewData(data.data.data)
			})
			.catch(() => onError())
		}
	}

	if(loading) return <Box height="100vh" display="flex" justifyContent="center" alignItems="center"><CircularProgress /></Box>

	const handelSelectCards = (item: any) =>{
		// console.log("handelSelectCards",item)
		setKeyDescription(item?.keyDescription)
		setKeyName(item?.keyName)
		setKeyValue(item?.keyValue)
	}

	

	const masterDataCard = (item: any)=>{
		console.log("item", item)
		return <div className='zoom' onClick={()=>handelSelectCards(item)} style={{ border: '2px solid #99aac4', borderRadius: '8px', width: "300px",padding: "10px", margin:"10px"}}>
			<Box width="100%" justifyContent="flex-start" alignItems="center">
				<Typography style={{ fontSize: fontOptions.size.small, fontWeight: fontOptions.weight.bold, color: '#3D3D3D', marginRight: "18px" }}>
					Key Name: {item.keyName}
				</Typography>
				<Typography style={{ fontSize: fontOptions.size.small, fontWeight: fontOptions.weight.bold, color: '#3D3D3D', marginRight: "18px" }}>
					Key Value: {item.keyValue}
				</Typography>
				<Typography style={{ fontSize: fontOptions.size.small, fontWeight: fontOptions.weight.bold, color: '#3D3D3D', marginRight: "18px" }}>
					Key Description: {item.keyDescription}
				</Typography>
			</Box>
		</div>
	}
    return (
        <>
            <CssBaseline />
            <MiniDrawer>
			<Container maxWidth="lg">
				<Box
				bgcolor="#4C8BF5"
				padding="20px 30px"
				marginBottom="30px"
				position="relative"
				borderRadius="5px"
				color='#fff'
				>
				<Grid item container>
					<Grid item sm={8}>
					<Box style={{height: '100%'}}>
						<Grid
						container
						direction="row"
						alignItems="center"
						justify="center"
						style={{ height: '100%' }}
						>
						<Grid item xs={12}>
							<Typography className={classes.title}>
							Master Data Upload
							</Typography>
							<Typography>
							Upload Master Questions, Master Chapters, Master Templates etc
							</Typography>
						</Grid>
						</Grid>
					</Box>
					</Grid>
				</Grid>
				</Box>

				<Box
				bgcolor="#ffffff"
				borderRadius="5px"
				padding="12px 25px"
				marginTop='25px'
				>
					<Grid container>
						<Grid item xs={12} style={{paddingTop: '5px', marginBottom: '8px'}}>
							<Box width="100%" display="flex" justifyContent="flex-start" alignItems="center">
								<Typography style={{fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, color: '#3D3D3D', marginRight:"18px"}}>
									Select Upload Type
								</Typography>
								{
								!uploadOptions ? '' :
								// <Box padding="0 8px" bgcolor="#fff">
									<FormControl className={classes.formControl}>
									<Select
										style={{minWidth:"100px"}}
										value={activeIndex}
										onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
										setActiveIndex(e.target.value as number)
										}
									>
										<MenuItem value="-1">Select upload Type</MenuItem>
										{uploadOptions.map((each, index) => (
										<MenuItem key={index} value={index}>
											{each}
										</MenuItem>
										))}
									</Select>
									{/* <FormHelperText>Upload Options</FormHelperText> */}
									</FormControl>
								// </Box>
								}
							</Box>
						</Grid>
						<div style={{display:"flex"}}>
							<div>
									{
										activeIndex == 4 && <> <Grid container>
											<Grid item xs={12} md={6}>
												<Box padding="20px 30px" display="flex" alignItems="center">
													<Box marginLeft="15px">
														<Typography component="span" color="secondary">
															<Box component="h6" className={classes.heading}>
																Key Name
																<FormControl fullWidth margin="normal">
																	<Input
																		name="keyName"
																		placeholder="Enter Key Name"
																		type='text'
																		value={keyName}
																		onChange={(e) => setKeyName(e.target.value)}
																	/>
																</FormControl>
															</Box>
														</Typography>
													</Box>
												</Box>
											</Grid>

											<Grid item xs={12} md={6}>
												<Box padding="20px 30px" display="flex" alignItems="center">
													<Box marginLeft="15px">
														<Typography component="span" color="secondary">
															<Box component="h6" className={classes.heading}>
																Key Value
																<FormControl fullWidth margin="normal">
																	<Input
																		name="keyValue"
																		placeholder="Enter Key Value"
																		type='text'
																		value={keyValue}
																		onChange={(e) => setKeyValue(e.target.value)}
																	/>
																</FormControl>
															</Box>
														</Typography>
													</Box>
												</Box>
											</Grid>

											<Grid item xs={12} md={6}>
												<Box padding="20px 30px" display="flex" alignItems="center">
													<Box marginLeft="15px">
														<Typography component="span" color="secondary">
															<Box component="h6" className={classes.heading}>
																Key Description
																<FormControl fullWidth margin="normal">
																	<Input
																		name="keyDescription"
																		placeholder="Enter Key Description"
																		type='text'
																		value={keyDescription}
																		onChange={(e) => setKeyDescription(e.target.value)}
																	/>
																</FormControl>
															</Box>
														</Typography>
													</Box>
												</Box>
											</Grid>
											
										</Grid>
											<Grid item xs={12} md={6} style={{ padding: "10px" }}>
												<Button
													disableElevation
													variant="contained"
													color="primary"
													size="large"
													// type="submit"
													onClick={uploadMasterFlag}
												// disabled={otp.length == 6 ? false : true}
												>
													Update
												</Button>
											</Grid>

										</>
									}	
							</div>
								{masterFlags && activeIndex == 4 && <div style={{ backgroundColor: "white", border: '2px solid #45474a', padding: '10px', borderRadius: '8px' }}>
									<Typography style={{ fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, color: '#3D3D3D', marginRight: "18px",padding:"10px",margin:"10px" }}>
										Previously added Master Keys
									</Typography>
									{masterFlags &&  masterFlags.map((item)=>{
									 return masterDataCard(item)
								})}
								</div>}
						</div>
						{
							activeIndex > 0 && activeIndex < 4 && <Box width="100%" borderBottom="1px solid rgba(224, 224, 224, 0.5)">
								<Grid container>
								<Grid item xs={12} md={6}>
									<Box padding="20px 30px" display="flex" alignItems="center">
									<Box marginLeft="15px">
										<Typography component="span" color="secondary">
										<Box component="h3" className={classes.heading}>
											{activeUploadOption} 
											<Button onClick={fetchData} variant="text" color="primary">
												View Existing
											</Button>
										</Box>
										</Typography>
									</Box>
									</Box>
								</Grid>

								<Grid item xs={12} md={6}>
									<Box
									display="flex"
									alignItems="center"
									justifyContent="flex-end"
									padding="20px 30px"
									>
									<Box marginRight="10px">
										<div>
										<input
											accept=".xls, .xlsx"
											style={{ display: 'none' }}
											id="contained-button-file"
											type="file"
											onChange={(e) => {
											readExcel(e.target.files && e.target.files[0]);
											e.target.value = '';
											}}
										/>
										<label htmlFor="contained-button-file">
											<Button
											component="span"
											className={classes.helperText}
											>
											Upload {activeUploadOption}
											<Box
												display="flex"
												alignItems="center"
												marginLeft="15px"
											>
												<img src={UploadIcon} alt="Upload Data" />
											</Box>
											</Button>
										</label>
										</div>
									</Box>

									<Box>
										<Button>
										<Link
											className={classes.helperText}
											style={{ textDecoration: 'none' }}
											to="/files/students.xlsx"
											target="_blank"
											download
										>
											Download Template
										</Link>
										<Box display="flex" alignItems="center" marginLeft="15px">
											<img src={DownloadIcon} alt="Download Sample Template" />
										</Box>
										</Button>
									</Box>
									</Box>
								</Grid>
								</Grid>
							</Box>
						}
					</Grid>
					{
						!viewData ? '' :
						<Grid container>
							<Grid item xs={12} style={{paddingTop: '5px', marginBottom: '8px'}}>
								{
									activeUploadOption && activeUploadOption === "City/Pin Code Master" ? <MasterCityPinDataTable gridData={viewData}/> : activeUploadOption && activeUploadOption === "School Master" ? <MasterSchoolDataTable gridData={viewData}/> : activeUploadOption && activeUploadOption === "Subject,Topic Master" ? <MasterChaptersDataTable gridData={viewData}/> : activeUploadOption && activeUploadOption === "Qualifiations Master" ? <MasterQualifiationsDataTable gridData={viewData}/> : ''
								}
							</Grid>
							<Grid item xs={12} style={{paddingTop: '5px', marginBottom: '8px'}}>
								<Button onClick={uploadData} disableElevation color="primary" variant="contained">
									Confirm Upload
								</Button>
							</Grid>
						</Grid>
					}
				</Box>
			</Container>
            </MiniDrawer>
        </>   
    )
}

export default MasterUploads
