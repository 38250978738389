import { ThemeOptions } from '@material-ui/core';

export const options: ThemeOptions = {
  palette: {
    primary: {
      dark: '#4C8BF5',
      light: '#4C8BF5',
      main: '#4C8BF5',
      contrastText: '#ffffff'
    },
    secondary: {
      dark: '#4285F4',
      light: '#4285F4',
      main: '#4285F4'
    },
    background: {
      default: '#F5F7FA'
    }
  }
};


export const fontOptions = {
  family: 'Roboto',
  size: {
    xxSmall: '7px',//.4375em
    xSmall: '10px',
    small: '15px',//1em
    smallPlus: '18px',
    medium: '20px',//1.23em
    mediumPlus: '26px',
    large: '35px',//2em
    xLarge: '45px', //3em
  },
  weight: {
    lighter: 200,
    light: 300,
    normal: 400,
    bold: 500,
    bolder: 600,
  }
}