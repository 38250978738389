import axios from 'axios';

import {webResource} from "./interfaces/webResource"

const BASE_URL = process.env.REACT_APP_API_2 + "homecontent/"
const CREATE_CONTENT = BASE_URL + "createContent"
const UPDATE_CONTENT = BASE_URL + "updateContent"
const DELETE_CONTENT = BASE_URL + "deleteContent"
const GET_CONTENTS = BASE_URL + "getContentsForBO"
const GET_UPLOAD_URL_FOR_HOMECONTENT = BASE_URL + "fetchUploadUrlForHomeContent"
const GET_DOWNLOAD_URL_FOR_HOMECONTENT = BASE_URL + "fetchDownloadUrlForHomeContent"
const GET_FEEDBACKS = process.env.REACT_APP_API_2 + "customerfeedback/getFeedbacksForBO"
const UPDATE_FEEDBACK = process.env.REACT_APP_API_2 + "customerfeedback/updateFeedback"
const DELETE_FEEDBACK = process.env.REACT_APP_API_2 + "customerfeedback/deleteFeedback"

interface uploadUrlResp {
    signedUrl: string,
    newuuid: string
}

interface uploadUrlReq {
    contentType: string
}

interface downloadUrlReq {
    uuid: string
}

export const addWebResource = async (data: webResource) => {
    await axios.post(CREATE_CONTENT, data)
}

export const fetchUploadUrlForHomeContent = async (params: uploadUrlReq) => {
    const response = await axios.get<{data: uploadUrlResp}>(GET_UPLOAD_URL_FOR_HOMECONTENT, {params})
    return response.data.data
}

export const uploadResource = async(url: string, file: File) => {
    await axios.put(url, file, {headers: {"Content-Type": file.type}})
}

export const fetchDownloadUrlForHomeContent = async (params: downloadUrlReq) => {
    const response = await axios.get<{data: string}>(GET_DOWNLOAD_URL_FOR_HOMECONTENT, {params})
    return response.data.data
}

export const getHomeContents = async (type: string) => {
    const response = await axios.get<{data: webResource[]}>(GET_CONTENTS, {params: {type}})
    return response.data.data
}

export const updateContent = async (data: Object) => {
    await axios.post(UPDATE_CONTENT, data)
}

export const deleteContent = async (_id: string) => {
    await axios.post(DELETE_CONTENT, {_id})
}

export const getFeedbacks = async () => {
    const response = await axios.get(GET_FEEDBACKS)
    return response.data.data
}

export const updateFeedback = async (data: Object) => {
    await axios.post(UPDATE_FEEDBACK, data)
}

export const deleteFeedback = async (_id: string) => {
    await axios.post(DELETE_FEEDBACK, {_id})
}