import React, { FunctionComponent, useState, useEffect } from 'react';
import Modal from '../../common/components/modal';
import { Box, Checkbox, Container, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@material-ui/core';

import { fontOptions } from '../../../theme';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
//@ts-ignore
import { parse, HtmlGenerator } from 'latex.js'
import { ChoiceQuesType, MatchQuesType, NumericQuesType, QuestionBank } from '../contracts/qb_interface';
import ReactPlayer from 'react-player';
import { URL_PATTERN, YOUTUBE_LINK_PATTERN } from '../../common/validations/patterns';

interface AllChapter {
  chaptername: string,
  subtoptics: string[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hover: {
      '&:hover': {
        cursor: 'pointer',
      }
    },
    latex: {
      '& p': {
        marginTop: '0px',
        maxWidth: '100%', 
        wordWrap: 'break-word', 
      },
    },
    previewImg: {
      height: '140px',
      borderRadius: '5px'
    }
  }),
);


interface Props {
  openModal: boolean;
  onClose: () => any;
  question: ChoiceQuesType | NumericQuesType | MatchQuesType | null 
}

const PreviewModal: FunctionComponent<Props> = ({openModal, onClose, question}) => {
  const classes = useStyles();

  const latexParser =  (latexString:String) =>{
    let generator = new HtmlGenerator({ hyphenate: false })
    let doc = parse(latexString, { generator: generator }).htmlDocument()
    return doc.documentElement.outerHTML
  }

  return (
    <Modal
      open={openModal}
      handleClose={onClose}
      header={
        <Box width="700px" display="flex" alignItems="center">
          <Box marginLeft="15px">
            <Typography component="span" color="secondary">
              <Box component="h3" color="white" fontWeight="400" margin="0">
                Preview Question
              </Box>
            </Typography>
          </Box>
        </Box>
      }
    >
      <Container>
        <Box width="100%">
          <Grid container style={{maxWidth: '100%'}}>
            <Grid item xs={12}>
              <Typography
                style={{marginLeft: '10px', color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}
              >
                Question
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {question && question.question &&
                <Typography
                  style={{marginLeft: '10px', marginTop: '5px', wordWrap: "break-word", color: '#3D3D3D'}}
                >
                  <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(question.question as string)}}/>
                </Typography>
              }
            </Grid>

            {question && question.imageLinks && question.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 'q') &&
              <Grid item xs={12}>
                <img className={classes.previewImg} src={question.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 'q')?.encoding} alt="questionImg" />
              </Grid>
            }

            {
              //@ts-ignore
              (question && question.list1 && question.list2) &&
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    {
                      //@ts-ignore
                      (question?.list1).map(ls1 => {
                        return (
                          <Box border="1px dashed #4C8BF5" borderRadius="5px" width="100%" marginBottom="12px" 
                            paddingTop="10px" textAlign="center"
                          >
                            <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(ls1 as string)}}/>
                          </Box>
                        )
                      })
                    }
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {
                      //@ts-ignore
                      (question?.list2).map(ls2 => {
                        return (
                          <Box border="1px dashed #4C8BF5" borderRadius="5px" width="100%" marginBottom="12px" 
                            paddingTop="10px" textAlign="center"
                          >
                            <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(ls2 as string)}}/>
                          </Box>
                        )
                      })
                    }
                  </Grid>
                </Grid>
              </Grid>
            }

            {
              //@ts-ignore
              (question && question.option) &&
              <Grid item xs={12}>
                {
                  //@ts-ignore
                  (question?.option).map((ls1, lsind) => {
                    return (
                      <div>
                        <Checkbox style={{display: 'inline-block'}} checked={question && question.answer.includes(String(lsind + 1))} />
                        <Typography style={{display: 'inline-block', wordWrap: "break-word", color: '#3D3D3D'}}>
                          <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(ls1 as string)}}/>
                        </Typography>
                      </div>
                    )
                  })
                }
              </Grid>
            }

            {
              //@ts-ignore
              (question && !question.option) &&
              <Grid item xs={12}>
                <Grid container>
                <Grid item xs={12}>
                  <Typography style={{marginLeft: '10px',color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}>Answer</Typography>
                </Grid>
                <Grid item xs={12}>
                  {question && question.answer &&
                    <Typography
                      style={{marginLeft: '10px', marginBottom: '10px', wordWrap: "break-word", color: '#3D3D3D' }}
                    >
                      {question.answer[0]}
                    </Typography>
                  }
                </Grid>
                </Grid>
              </Grid>
            }

            {question && question.solution !== undefined && question.solution && (question.solution.length > 0) &&
              <React.Fragment>
                <Grid item xs={12}>
                  <Typography style={{marginLeft: '10px', color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}>Solution Description</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                      style={{marginLeft: '10px', wordWrap: "break-word", color: '#3D3D3D'}}
                    >
                      <div className={classes.latex} dangerouslySetInnerHTML={{ __html: latexParser(question.solution as string)}}/>
                    </Typography>
                </Grid>
              </React.Fragment>
            }
            {question && question.imageLinks && question.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 's') &&
              <Grid item xs={12}>
                <img className={classes.previewImg} src={question.imageLinks.find(sqbi => sqbi.filename.charAt(0) === 's')?.encoding} alt="solutionImg" />
              </Grid>
            }

            {question && question.solutionlink && question.solutionlink.length > 0 && question.solutionlink.match(YOUTUBE_LINK_PATTERN) &&
              <React.Fragment>
                <Grid item xs={12}>
                  <Typography style={{marginLeft: '10px', color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}>Solution Link</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ReactPlayer
                    url={question.solutionlink}
                    controls
                    light
                    style={{width: '100%', marginLeft: '10px', marginTop: '10px'}}
                    width={'100%'}
                  />
                </Grid>
              </React.Fragment>
            }

            {question && question.solutionlink && question.solutionlink.length > 0 && !question.solutionlink.match(YOUTUBE_LINK_PATTERN) && question.solutionlink.match(URL_PATTERN) &&
              <React.Fragment>
                <Grid item xs={12}>
                  <Typography style={{marginLeft: '10px', color: '#3D3D3D', fontWeight: fontOptions.weight.bold, fontSize: fontOptions.size.medium }}>Solution Link</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{color: '#4C8BF5',  marginLeft: '10px', cursor: 'pointer', marginTop: '10px', textDecoration: 'underline'}}
                    onClick={() => {
                      window.open(question.solutionlink)
                    }}
                  >
                    {question.solutionlink}
                  </Typography>
                </Grid>
              </React.Fragment>
            }
          </Grid>
        </Box>
      </Container>
    </Modal>
  );
}

export default PreviewModal