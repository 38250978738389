import * as React from 'react';
import { FunctionComponent } from 'react'
import { DataGrid, GridColDef, GridSelectionModelChangeParams, GridToolbar, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';

import { IconButton, Paper, Popper, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GridCellParams, isOverflown } from "@material-ui/data-grid";
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { union, unionWith } from 'lodash';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

interface CellExpandProps {
  value: string;
  width: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      lineHeight: "24px",
      width: "100%",
      height: "100%",
      position: "relative",
      display: "flex",
      "& .cellValue": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    },
    root1: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    divRoot: {
      "& .MuiDataGrid-colCellTitle": {
        color: theme.palette.primary.main
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        color: theme.palette.primary.main
      },
      "& .MuiDataGrid-window": {
        overflowX: 'hidden'
      }
    },
    textField: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      margin: theme.spacing(1, 0.5, 1.5),
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    myWrap: {
      wordWrap: 'break-word'
    },
    zIn: {
      zIndex: theme.zIndex.modal + 1
    }
  })
);

const CellExpand = React.memo(function CellExpand(props: CellExpandProps) {
  const { width, value } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div
      ref={wrapper}
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0
        }}
      />
      <div ref={cellValue} className="cellValue">
        {value}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl != null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
          className={classes.zIn}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }} className={classes.myWrap}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </div>
  );
});

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  const classes = useStyles();

  return (
    <div className={classes.root1}>
      <div>
        <GridToolbar />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

export function datagridCellExpand(params: GridCellParams) {
  return (
    <CellExpand
      value={params.value ? params.value.toString() : ""}
      width={params.colDef.width}
    />
  );
}

interface Props {
  gridColumns: GridColDef[];
  gridRows: any[];
  setSelection?: (selected: any) => void;
  disableCheckbox?: boolean;
  selectionModel? : any,
  searchBool? : boolean
}

const Datagrids: FunctionComponent<Props> = ({gridColumns, gridRows, setSelection, disableCheckbox,selectionModel, searchBool}) => {
  const classes = useStyles();
  const checkboxSelection = disableCheckbox ? disableCheckbox : false;
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');
  let [rows,setRows] = React.useState<any[]>(gridRows)
  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = gridRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {

        return row[field] ? searchRegex.test(row[field].toString()) : false;
      }); 
    });
    // if(page!=0){
    //   setPage(0)
    // }
    
    setRows(filteredRows);
  };

  React.useEffect(() => {
    // if(page!=0){
    //   setPage(0)
    // }
    if(searchBool){
      setRows(gridRows);
    }
    
  }, [gridRows]);


  React.useEffect(()=>{
    if(searchText.length>0){
      requestSearch(searchText)
    }
    
  },[selectionModel])
  return (
    <div style={{ height: 450, width: '100%' }}>
      <DataGrid rows={searchBool ? rows : gridRows} columns={gridColumns} pageSize={10} 
        page={page} onPageChange={(params) => {
          setPage(params.page);
        }} 
        pagination
        onPageSizeChange={(params) => {
          if(params.page >= params.pageCount) {
            setPage(params.pageCount - 1)
          }
        }}
        // state={{
        //   keyboard: {
        //     cell: null,
        //     columnHeader: null,
        //     isMultipleKeyPressed: false,
        //   }
        // }}
        components={{
          Toolbar: searchBool ? QuickSearchToolbar : GridToolbar,
        }}
        selectionModel ={selectionModel?  selectionModel : undefined}
        rowsPerPageOptions={[10, 20, 30, 40]} density="comfortable"
        onSelectionModelChange={setSelection ? (selectionMod: any ) =>{
          
          setSelection(selectionMod)
          
        } : () => {}} 
        checkboxSelection={!checkboxSelection}
        className={classes.divRoot}
        disableSelectionOnClick={checkboxSelection}
        componentsProps={searchBool ? {
          toolbar: {
            value: searchText,
            onChange: (event: any) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(''),
          },
        } : undefined}

      />
    </div>
  );
}

export default Datagrids