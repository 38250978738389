import React, { FunctionComponent, useState, useEffect } from 'react';
import Modal from '../../common/components/modal';
import { Box, Container, Grid, TextField, Typography } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { fontOptions } from '../../../theme';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '../../common/components/form_elements/button';
import { QB_PATTERN } from '../../common/validations/patterns';

interface AllChapter {
  chaptername: string,
  subtoptics: string[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hover: {
      '&:hover': {
        cursor: 'pointer',
      }
    },
  }),
);


interface Props {
  openModal: boolean;
  onClose: () => any;
  type: string;
  prev: string;
  editChapterCompl: (newname: string) => void
  editSubtopicCompl: (newsubname: string) => void
}

const EditChapterSubtpoicModal: FunctionComponent<Props> = ({openModal, onClose, type, prev, editChapterCompl, editSubtopicCompl }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [newVal, setNewVal] = useState<string>('')

  useEffect(() => {
    setNewVal('')
  },[openModal])

  return (
    <Modal
      open={openModal}
      handleClose={onClose}
      header={
        <Box width="400px" display="flex" alignItems="center">
          <Box marginLeft="15px">
            <Typography component="span" color="secondary">
              <Box component="h3" color="white" fontWeight="400" margin="0">
                Edit {type}
              </Box>
            </Typography>
          </Box>
        </Box>
      }
    >
      <Container>
        <Box width="100%">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                
              </Typography>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center'}}>
              <Typography style={{color: '#3D3D3D', marginTop: '5px', display: 'inline-block', fontWeight: fontOptions.weight.bold}} >Change {type} name - {prev} to</Typography>
              <TextField style={{marginLeft: '10px', color: '#3D3D3D', display: 'inline-block'}} value={newVal} onChange={(e) => setNewVal(e.target.value)} />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" marginTop="10px">
                <Box marginRight="10px">
                  <Button variant="contained" color="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                </Box>

                <Button variant="contained" color="primary" onClick={() => {
                    const remSpace = newVal.replace(/ /g, "")
                    if(newVal.length === 0) {
                      enqueueSnackbar('Name cannot be empty', {variant: 'warning'});
                      return
                    } else if (remSpace.length < 3) {
                      enqueueSnackbar('Name cannot be less than 3 character excluding spaces', {variant: 'warning'});
                      return
                    }else if (newVal.length > 50) {
                      enqueueSnackbar('Nameame should not exceed 50 characters', {variant: 'warning'});
                      return
                    }else if (newVal && newVal.trim() && !QB_PATTERN.test(newVal.trim())) {
                      enqueueSnackbar('Invalid Name', {variant: 'warning'});
                      return;
                    } else {
                      if(type === 'Chapter') {
                        editChapterCompl(newVal)
                        setNewVal('')
                      } else {
                        editSubtopicCompl(newVal)
                        setNewVal('')
                      }
                    }
                  }}
                >
                  Ok
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Modal>
  );
}

export default EditChapterSubtpoicModal