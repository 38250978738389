import React, { FunctionComponent, useState, useEffect } from 'react';
import {
	DataGrid,
	GridCellParams,
	GridColumns,
	GridToolbar,
} from '@material-ui/data-grid';
import { Box, Container, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import Datagrids, { datagridCellExpand } from './dataGrid';
import { USER_TYPE_VALUES } from '../../../utilities/constants';

interface RowData {
	name: string,
	emailId: string,
	mobileNo: string,
	ownerId: string,
	ownerName: string,
	address: string,
	city: string,
	pinCode: string,
	platformStartDate: string,
	studentCount?: number,
	batchCount?: number,
	roleStatus: string,
}

interface Props {
	gridData: any[];
	noOwnerDetails?: boolean,
	userType: string,
	reportType?: string,
	actions?: any,
	buttonData?: any
}

const useStyles = makeStyles({
	root: {
		'& .super-app-theme--cell': {
			backgroundColor: 'rgba(224, 183, 60, 0.55)',
			color: '#1a3e72',
			fontWeight: '600'
		},
		'& .super-app.negative': {
			backgroundColor: '#d47483',
			color: '#1a3e72',
			fontWeight: '600'
		},
		'& .super-app.positive': {
			backgroundColor: 'rgba(157, 255, 118, 0.49)',
			color: '#1a3e72',
			fontWeight: '600'
		}
	},
	paginate: {
		display: "flex"
	}
});

export const UserDataTable: FunctionComponent<Props> = ({
	gridData,
	userType,
	noOwnerDetails,
	reportType,
	actions,
	buttonData
}) => {
	const classes = useStyles();


	let columns :GridColumns = []
	columns = []

	if(userType == USER_TYPE_VALUES.ORG||userType == USER_TYPE_VALUES.TUTOR){
		columns = [
			{ field: 'id', headerName: 'S.No' },
			{ field: 'name', headerName: 'Name', type: 'string', renderCell: datagridCellExpand },
			{ field: 'emailId', headerName: 'Email ID', type: 'string', renderCell: datagridCellExpand },
			{ field: 'mobileNo', headerName: 'Mobile No', type: 'string', renderCell: datagridCellExpand },
			{ field: 'ownerId', headerName: 'Owner Id', type: 'string', renderCell: datagridCellExpand },
			{ field: 'ownerName', headerName: 'Owner', type: 'string', renderCell: datagridCellExpand },
			{ field: 'address', headerName: 'Address', type: 'string', renderCell: datagridCellExpand },
			{ field: 'city', headerName: 'City', type: 'string', renderCell: datagridCellExpand },
			{ field: 'pinCode', headerName: 'PinCode', type: 'string', renderCell: datagridCellExpand },
			{ field: 'platformStartDate', headerName: 'Platform Start Date', type: 'string', renderCell: datagridCellExpand },
			{ field: 'studentCount', headerName: 'Student Count', renderCell: datagridCellExpand },
			{ field: 'batchCount', headerName: 'Batch Count', renderCell: datagridCellExpand },
			{ field: 'smsString', headerName: 'SMS Count', renderCell: datagridCellExpand },
			{ field: 'emailString', headerName: 'Email Count', renderCell: datagridCellExpand },
			{ field: 'roleStatus', headerName: 'Role Status', type: 'string', renderCell: datagridCellExpand },
		];
	}
	else{
		columns = [
			{ field: 'id', headerName: 'S.No' },
			{ field: 'name', headerName: 'Name', type: 'string', renderCell: datagridCellExpand },
			{ field: 'emailId', headerName: 'Email ID', type: 'string', renderCell: datagridCellExpand },
			{ field: 'mobileNo', headerName: 'Mobile No', type: 'string', renderCell: datagridCellExpand },
			{ field: 'ownerId', headerName: 'Owner Id', type: 'string', renderCell: datagridCellExpand },
			{ field: 'ownerName', headerName: 'Owner', type: 'string', renderCell: datagridCellExpand },
			{ field: 'address', headerName: 'Address', type: 'string', renderCell: datagridCellExpand },
			{ field: 'city', headerName: 'City', type: 'string', renderCell: datagridCellExpand },
			{ field: 'pinCode', headerName: 'PinCode', type: 'string', renderCell: datagridCellExpand },
			{ field: 'platformStartDate', headerName: 'Platform Start Date', type: 'string', renderCell: datagridCellExpand },
			{ field: 'studentCount', headerName: 'Student Count', renderCell: datagridCellExpand },
			{ field: 'batchCount', headerName: 'Batch Count', renderCell: datagridCellExpand },
			{ field: 'roleStatus', headerName: 'Role Status', type: 'string', renderCell: datagridCellExpand },
		];
	}

	if(noOwnerDetails) {
		const ownerIdIndex = columns.findIndex(el => el.field === 'ownerId')
		if(ownerIdIndex > -1) columns.splice(ownerIdIndex, 1)
		const ownerNameIndex = columns.findIndex(el => el.field === 'ownerName')
		if(ownerNameIndex > -1) columns.splice(ownerNameIndex, 1)
	}

	// if(reportType === 'student') {
	// 	const studentCountIndex = columns.findIndex(el => el.field === 'studentCount')
	// 	if(studentCountIndex > -1) columns.splice(studentCountIndex, 1)
	// 	const batchCountIndex = columns.findIndex(el => el.field === 'batchCount')
	// 	if(batchCountIndex > -1) columns.splice(batchCountIndex, 1)
	// }

	if(buttonData) {   
		columns.push({
			//width increased to 300 as all action icons are not visible with 200, btw there are 6-7 action icons are there
			field: 'action', headerName: 'Actions', width: 400,
			flex:1,
			resizable: true,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				const selectedRow = {
					id: params.getValue("id") as number,
					emailId: params.getValue("emailId") as string,
					name: params.getValue("name") as string
				}

				const selectedRowDetails = rows.find((row, index) => {
					console.log(row)
					return (row.emailId === selectedRow.emailId && row.name === selectedRow.name && index === (selectedRow.id - 1))
				})
				// @ts-ignore
				const buttonSet = buttonData.map((button, index) => {
					return (
						<Tooltip key={index} title={button.title}>
							<IconButton
								onClick={() => {
									button.action(selectedRowDetails as any);
								}}
								size="small"
							>
								{button.icon}
							</IconButton>
						</Tooltip>
					);
				})

				return <div>{buttonSet}</div>;
			}
		})
	}

	const [rows, setRows] = useState<RowData[]>([])

	useEffect(() => {
		setRows(() => {
			return gridData?.map((data, index) => {
				return { id: index + 1, emailId: data.emailId, mobileNo: data.mobileNo,emailString : (userType == USER_TYPE_VALUES.ORG||userType == USER_TYPE_VALUES.TUTOR) ? data.emailString : undefined,smsString : (userType == USER_TYPE_VALUES.ORG||userType == USER_TYPE_VALUES.TUTOR) ? data.smsString : undefined,  ownerId: data.ownerId, address: data.address, city: data.city, pinCode: data.pinCode, platformStartDate: new Date(data.platformStartDate).toLocaleDateString("en-GB"), studentCount: data?.studentCount, batchCount: data?.batchCount, roleStatus: data.roleStatus, name: data.name, ownerName: data.ownerName, _id: data._id, userType } as RowData
			}) as RowData[]
		})
	}, [gridData,userType])

	return (
		<Box
			className={classes.root}
			style={{
				width: '100%',
				height: '100%',
				marginTop: '10px',
				display: 'flex'
			}}
		>
			{/* <DataGrid
          loading={gridData.length === 0}
        //   pagination
          components={{
            Toolbar: GridToolbar
          }}
        //   autoPageSize
          rows={rows}
          columns={columns}
        /> */}
			<Datagrids gridRows={rows} gridColumns={columns} showToolBar={true} disableCheckbox={true} />
		</Box>
	);
};
