import React, { useState, useEffect, FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme, lighten } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
	Box, Container, Grid, Button, MenuItem, IconButton, CssBaseline, Card, CardContent, CardHeader, Tooltip,
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
import * as XLSX from 'xlsx';

import MiniDrawer from "../../common/components/SideDrawer"
import { Visibility } from '@material-ui/icons';
import useStyles from '../styles';
import Datagrids, { datagridCellExpand } from '../../dashboard/components/dataGrid';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { MASTER_PERMISSIONS } from '../../../utilities/constants';
import Datepickers from '../../dashboard/components/datepickers';
import { getLeads, updateLeads } from '../api';
import UpdateLeadModal from '../components/updateLeadModal';
import { compareAsc } from 'date-fns';
import { leadStatus, leadUpdateReq } from '../interfaces';
import { getLoggedInUserData, onApiError } from '../../common/helpers';

interface Props extends RouteComponentProps {

}

const LeadsEnquiry: FunctionComponent<Props> = ({ history }) => {

	const { enqueueSnackbar } = useSnackbar()
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [status, setStatus] = useState('')

	const [showDataGrid, setShowDataGrid] = useState(false)
	const [gridRows, setGridRows] = useState<any[]>([])
	const [gridColumns, setGridColumns] = useState<GridColDef[]>([])

	const [openModal, setOpenModal] = useState(false)
	const [selectedLead, setSelectedLead] = useState<any>()

	const styles = useStyles();

	const demoOnly = window.location.pathname.includes('demo') ? true : false

	useEffect(() => {
		getLoggedInUserData(setRedirectTo, [MASTER_PERMISSIONS.getLeads, MASTER_PERMISSIONS.updateLeads], enqueueSnackbar, null)
	}, []);

	const readExcel = (file: File | null) => {
		const promise = new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			if (file) {
				fileReader.readAsArrayBuffer(file);
				fileReader.onload = (e) => {
					const bufferArray = e.target ? e.target.result : '';
					const wb = XLSX.read(bufferArray, { type: 'buffer' });
					const wsname = wb.SheetNames[0];
					const ws = wb.Sheets[wsname];

					/* Convert array to json*/
					const jsonData = XLSX.utils.sheet_to_json(ws);
					resolve(jsonData);
				};
				fileReader.onerror = (error) => {
					reject(error);
				};
			}
		});
		promise.then(async (leadsArr: any) => {
			const leadsUpdateReq: leadUpdateReq[] = await Promise.all(
				leadsArr &&
				leadsArr.map(async (applicant: any) => {

					return {
						leadId: applicant["Lead Id"],
						status: applicant["Status"],
						remarks: applicant["Remarks"]
					};
				})
			);
			if(!validateLeadUpdates(leadsUpdateReq)) return;
			await updateLeads(leadsUpdateReq)
			.then(() => {
				enqueueSnackbar('Successfully Updated', {variant: 'success'})
			})
			.catch((e) => {
				enqueueSnackbar('Something went wrong !!', {variant: 'error'})
				console.log(e)
			})
		});
	};

	const validateLeadUpdates = (leadsUpdateReq: leadUpdateReq[]) => {
		return leadsUpdateReq.every(cur => {
			const {leadId, status, remarks} = cur
			if(!leadId) {
				enqueueSnackbar('Invalid spreadsheet file', {variant: 'error'})
				return false;
			}
			if(!Object.values(leadStatus).includes(status)) {
				enqueueSnackbar(`Status has to be "Fresh" | "Closed" | "Deferred" for ${leadId}`, {variant: 'warning'})
				return false;
			}
			if(!(remarks && remarks.length > 3)) {
				enqueueSnackbar(`Min 3 characters needed for ${leadId} remarks`, {variant: 'warning'})
				return false;
			}
			return true
		})
	}

	const viewUser = (row: any) => {
		setSelectedLead(row)
		setOpenModal(true)
	}

	const buttonData = [
		{
			title: 'view lead',
			action: viewUser,
			icon: <Visibility />
		},
	];

	const createDataGrid = (list: any) => {
		function createData(lead: any, i: number) {
			const { emailId, postedOn, mobileNo, name, query, remarkedon, remarks, status, leadId, roleType, instituteName } = lead
			return {
				emailId, postedOn, mobileNo, name, query, remarkedon, remarks, status, roleType, id: i + 1, leadId, instituteName
			}
		}

		const rows = list.map((cur: any, i: any) => createData(cur, i))

		setGridRows(rows.map((row: any, index: any) => {
			const { emailId, postedOn, mobileNo, name, query, remarkedon, remarks, status, leadId, roleType, id, instituteName } = row
			return ({
				emailId, postedOn, mobileNo, name, query, remarkedon, remarks, status, leadId, roleType, id, instituteName
			})
		}))

		const columns = [
			{ field: 'id', headerName: 'S.No', flex: 0.5 },
			{ field: 'name', headerName: 'Name', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'mobileNo', headerName: 'Mobile', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'emailId', headerName: 'Email', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'query', headerName: 'Query', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'status', headerName: 'Status', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'postedOn', headerName: 'Posted On', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'remarks', headerName: 'Remarks', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'remarkedon', headerName: 'Remark Date', flex: 1, width: 100, renderCell: datagridCellExpand },
			{
				field: 'action', headerName: 'Action', flex: 1,
				disableClickEventBubbling: true,
				renderCell: (params: GridCellParams) => {
					const selectedRow = {
						id: params.getValue("id") as number,
						emailId: params.getValue("emailId") as string,
						name: params.getValue("name") as string
					}

					const selectedRowDetails = rows.find((row: any, index: any) => {
						return (row.emailId === selectedRow.emailId && row.name === selectedRow.name && index === (selectedRow.id - 1))
					})

					const buttonSet = buttonData.map((button: any, index: any) => {
						return (
							<Tooltip key={index} title={button.title}>
								<IconButton
									onClick={() => {
										button.action(selectedRowDetails as any);
									}}
									size="small"
								>
									{button.icon}
								</IconButton>
							</Tooltip>
						);
					})

					return <div>{buttonSet}</div>;
				}
			}
		]
		if(demoOnly) {
			const instNameField = { field: 'instituteName', headerName: 'Institute Name', flex: 1, width: 100, renderCell: datagridCellExpand }
			columns.splice(2, 0, instNameField)
		}
		setGridColumns(columns)
	}

	const downloadLeads = async () => {
		if (!status) {
			enqueueSnackbar('Please select status', { variant: 'warning' });
			return;
		}
		if (endDate && startDate) {
			if (compareAsc(startDate, endDate) == 1) {
				enqueueSnackbar("End date should be after start date", { variant: "error" })
				return;
			}
			await getLeads({
				dataType: 'file',
				startDate: new Date(startDate.setHours(0,0,0,0)),
				endDate: new Date(endDate.setHours(23,59,0,0)),
				status,
				demoOnly
			})
            .catch(err => onApiError(err, enqueueSnackbar, setRedirectTo))
		}
	}

	const showTable = (val: any) => {
		createDataGrid(val)
		setShowDataGrid(true)
	}

	const viewLeads = async () => {
		if (!status) {
			enqueueSnackbar('Please select status', { variant: 'warning' });
			return;
		}
		if (endDate && startDate) {
			if (compareAsc(startDate, endDate) == 1) {
				enqueueSnackbar("End date should be after start date", { variant: "error" })
				return;
			}
			await getLeads({
				dataType: 'table',
				startDate: new Date(startDate.setHours(0,0,0,0)),
				endDate: new Date(endDate.setHours(23,59,0,0)),
				status,
				demoOnly
			}).then(val => showTable(val))
            .catch(err => onApiError(err, enqueueSnackbar, setRedirectTo))
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				{selectedLead && <UpdateLeadModal
					openModal={openModal}
					onClose={() => {
						setOpenModal(false)
						setSelectedLead(undefined)
						viewLeads()
					}}
					// saveUser={savePersonalInformation}
					lead={selectedLead}
				/>}
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Search Customers</Typography>
							</Grid> */}

							<Card className={styles.card} variant='outlined' style={{ width: '100%' }}>
								<CardHeader title={demoOnly ? 'Demo Enquiry Details' : 'Lead Enquiry Details'} titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>
									<Grid container>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>From Date</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Datepickers selectedDate={startDate} handleDateChange={setStartDate}
												/>
											</FormControl>
											{/* {dateError.dob &&
												<Typography className={classes.dateError}>{dateError.dob}</Typography>
											} */}
										</Grid>
									</Grid>

									<Grid container>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>End Date</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Datepickers selectedDate={endDate} handleDateChange={setEndDate}

												/>
											</FormControl>
											{/* {dateError.dob &&
												<Typography className={classes.dateError}>{dateError.dob}</Typography>
											} */}
										</Grid>
									</Grid>


									<Grid container >
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>{demoOnly ? 'Demo Status' : 'Lead Status'}</Box>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Select>
													{/* <MenuItem value="Select">Select</MenuItem> */}
													{['All', ...Object.values(leadStatus)].map((item, index) => (
														<MenuItem value={item} key={index} onClick={() => setStatus(item)}>
															{item}
														</MenuItem>
													))}
												</Select>
												{/* {errors.role && (
													<FormHelperText error>
														{errors.role.message}
													</FormHelperText>
												)} */}
											</FormControl>
										</Grid>
									</Grid>

									<br />

									<Grid container spacing={3} justify="center" alignItems="center">
										<Grid item>
											<Button variant="contained" color="primary" size="medium"
												onClick={viewLeads}
											>
												View
											</Button>
										</Grid>

										<Grid item>
											<Button variant="contained" color="primary" size="medium"
												onClick={downloadLeads}
											>
												Download XLS
											</Button>
										</Grid>

										<Grid item>
											<input
												accept=".xls, .xlsx"
												style={{ display: 'none' }}
												id="contained-button-file"
												type="file"
												onChange={(e) => {
													readExcel(e.target.files && e.target.files[0]);
													e.target.value = '';
												}}
											/>
											<label htmlFor="contained-button-file">
												<Button
													component="span" variant="contained" color="primary" size="medium"
												>
													Bulk Update
												</Button>
											</label>
										</Grid>
									</Grid>

								</CardContent>
							</Card>

							{showDataGrid && <Datagrids gridRows={gridRows} gridColumns={gridColumns} />}

						</Grid>
					</Container>
				</div>
			</MiniDrawer>
		</>
	);
}

export default LeadsEnquiry;
