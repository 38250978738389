import React, { FunctionComponent, useState } from 'react'
import {
  Box,
  FormControl,
  Grid,
  Input,
  Typography,
  Button,
  Select,
  MenuItem
} from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { compareAsc } from 'date-fns'
import { useSnackbar } from "notistack"


import { webResourceRes, resourceType } from '../interfaces/webResource'
import Modal from '../../common/components/modal'
import useStyles from '../../dashboard/containers/customerManagement/styles'
import { uploadResource, fetchUploadUrlForHomeContent, updateContent, fetchDownloadUrlForHomeContent } from '../api'
import Dropzone from '../../common/components/dropzone/dropzone'
import UploadedContent from '../../common/components/dropzone/previewers/uploadedContent'
import { convertDateString, convertToBase64, onApiError } from '../../common/helpers'

interface Props {
    openModal: boolean;
    onClose: () => any;
    resource: webResourceRes;
} 

const EditWebResourceModal: FunctionComponent<Props> = ({
    onClose, openModal, resource
}) => {
    const {type, endDate, startDate, title, thumbnail, url, uuid, _id} = resource
    const [newTitle, setNewTitle] = useState(title)
    const [newStartDate, setNewStartDate] = useState(convertDateString(startDate))
    const [newEndDate, setNewEndDate] = useState(convertDateString(endDate))
    const [newUrl, setNewUrl] = useState(url)

    const [droppedFiles, setDroppedFiles] = useState<File[]>([])
	const [thumbnailFiles, setThumbnailFiles] = useState<File[]>([])

    const { enqueueSnackbar } = useSnackbar()

    const styles = useStyles()

    const handleDateChange = (d: Date, dateType: string) => {
        if(dateType === 'start') {
            d.setHours(0,0,0,0)
            setNewStartDate(d)
        }
        if(dateType === 'end') {
            d.setHours(23,59,0,0)
            setNewEndDate(d)
        }
    }

    const viewFile = async () => {
        try {
            if(uuid) window.open(await fetchDownloadUrlForHomeContent({uuid}))
        } catch (err) {
			onApiError(err, enqueueSnackbar)
        }
    }

    const viewThumbnail = () => {
        if(!thumbnail) return
        var newTab = window.open();
        newTab!.document.body.innerHTML = `<img src="${thumbnail}" width="100px" height="100px">`;
    }

    const addingFile = (files: File[], setter: any) => {
		if (files.length < 1) return;
		setter(files)
	}

	const removingFile = (setter: any) => setter([])

    const getFileTypes = (type: string) => {
		if (type === resourceType.blog) return ['text/html']
		if (type === resourceType.ebook) return ['application/pdf']
        if (type === 'image') return ['image/jpeg', 'image/png']
		return []
	}

    const checkValidation = () => {
		if (compareAsc(newStartDate, newEndDate) == 1) {
			enqueueSnackbar("End date should be after start date", { variant: "error" })
			return false
		}

		if (newTitle.length < 3) {
			enqueueSnackbar("Title should have minimum 3 characters", { variant: "error" })
			return false
		}

		if (type === resourceType.video) {
			if (newUrl && newUrl.length < 5) {
				enqueueSnackbar("Invalid url", { variant: "error" })
				return false
			}
		}
		return true
	}

    const uploadFiles = async (file: File) => {
		try {
			const response = await fetchUploadUrlForHomeContent({ contentType: file.type })
			await uploadResource(response.signedUrl, file)
			return response.newuuid
		} catch (err) {
			onApiError(err, enqueueSnackbar)
			return ''
		}
	}

    const saveChanges = async () => {
        if (!checkValidation()) return;
        let newUuid = '', newThumbnail = ''
        try {
            if (droppedFiles.length > 0) {
                newUuid = await uploadFiles(droppedFiles[0])
            }
            if (thumbnailFiles.length > 0) {
                newThumbnail = await convertToBase64(thumbnailFiles[0])
            }

            const resourceUpdate: any = { _id }
            let updateCount = 0
            if(newTitle !== title) {
                resourceUpdate.title = newTitle
                updateCount++
            }
            if(newUuid.length > 3) {
                resourceUpdate.uuid = newUuid
                updateCount++
            }
            if(newThumbnail.length > 3) {
                resourceUpdate.thumbnail = newThumbnail
                updateCount++
            }
            if(url) {
                if(newUrl !== url) {
                    resourceUpdate.url = newUrl
                    updateCount++
                }
            }
            if(newStartDate.getTime() !== new Date(convertDateString(startDate).setHours(0,0,0,0)).getTime()) {
                resourceUpdate.startDate = newStartDate
                updateCount++
            }
            if(newEndDate.getTime() !== new Date(convertDateString(endDate).setHours(0,0,0,0)).getTime()) {
                resourceUpdate.endDate = newEndDate
                updateCount++
            }

            if(updateCount === 0) {
                enqueueSnackbar('No changes made', {variant: "warning"})
                return;
            }

            await updateContent(resourceUpdate)
            .then(() => {
                enqueueSnackbar('Successfully updated', { variant: "success" })
                setDroppedFiles([])
                setThumbnailFiles([])
                onClose()
            })
        } catch (err) {
            onApiError(err, enqueueSnackbar)
        }
    }

    const renderDropzone = (key: string, value: any[], maxFileSize: number, fileType: string, setter: any) => (
        <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="normal">
                    <Box className={styles.label}>
                        {key}
                    </Box>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
                <Dropzone
                    key={0}
                    acceptedFiles={getFileTypes(fileType)}
                    multipleFiles={false}
                    maxFileSize={maxFileSize}
                    files={value}
                    onChange={(files) => addingFile(files, setter)}
                />
                {value.length > 0 && <UploadedContent
                    files={value}
                    onRemoveItem={() => removingFile(setter)}
                />}
            </Grid>
        </Grid>
    )

    const renderInfoWithButtons = (key: string, buttons: any[]) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>

            {buttons.map(btn => (
                <Grid item xs={6} md={4}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    size="medium"
                    onClick={btn.cb}
                >
                    {btn.name}
                </Button>
                </Grid>
            ))}

        </Grid>
    )
    
    const renderInput = (key: string, value: any, setter: any) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Input
                placeholder={key}
                value={value}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => setter(e.target.value)}
                />
            </FormControl>
            </Grid>
        </Grid>
    )
    
    const renderDropdown = (key: string, list: string[], value: string, setter: any) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Select>
                    <MenuItem value={value}>Select</MenuItem>
                    {list.map((item: any, index: any) => (
                        <MenuItem value={item} key={index}	onClick={() => setter(item)}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            </Grid>
        </Grid>
    )
    
    const renderDateInput = (key: string, value: Date, setter: any, dateType: string) => (
        <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
                <Box className={styles.label}>
                    {key}
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl fullWidth margin="normal">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={key}
                            value={value}
                            onChange={(d) => setter(d, dateType)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
            </Grid>
        </Grid>
    )
    
    const renderButton = (name: string, cb: any) => (
        <Button 
        variant="contained" 
        color="primary" 
        size="medium"
        style={{ margin: 10 }}
        onClick={cb}
        >
            {name}
        </Button>
    )

    return (
        <Modal
            open={openModal}
            handleClose={onClose}
            header={
                <Box>
                    <Typography component="span" color="inherit">
                    <Box component="h3">
                        {`Edit ${type}`}
                    </Box>
                    </Typography>
                </Box>
            }
        >
            {renderInput(`${type} Title`, newTitle, setNewTitle)}
            {renderDateInput('Start Date', newStartDate, handleDateChange, 'start')}
            {renderDateInput('End Date', newEndDate, handleDateChange, 'end')}
            {newUrl && renderInput('Enter video embed URL', newUrl, setNewUrl)}
            {uuid && thumbnail && renderInfoWithButtons('View Uploaded Files', [{name: type, cb: viewFile}, {name: 'Thumbnail', cb: viewThumbnail}])}
            {type === resourceType.video && thumbnail && renderInfoWithButtons('View thumbnail file', [{name: 'Thumbnail', cb: viewThumbnail}])}
            {uuid && renderDropzone(`Upload New ${type} file`, droppedFiles, 104857600, type, setDroppedFiles)}
            <br />
            {thumbnail && renderDropzone(`Upload New ${type} thumbnail`, thumbnailFiles, 1048576, 'image', setThumbnailFiles)}
            {renderButton('Save Changes', saveChanges)}
        </Modal>
    )
}

export default EditWebResourceModal