import React, {FunctionComponent, useState} from 'react'
import { Box, Button, Container, CssBaseline, darken, FormControl, Grid, makeStyles, Typography } from '@material-ui/core'
import { GridColDef } from "@material-ui/data-grid";
import { useSnackbar } from "notistack";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Redirect } from "react-router-dom";

import { fontOptions } from "../../../theme";
import MiniDrawer from "../../common/components/SideDrawer";
import { onApiError } from "../../common/helpers";
import Datagrids, { datagridCellExpand } from "../../dashboard/components/dataGrid";
import { fetchStudentInquiries } from '../api';
import { studentPublicProfile } from '../interface';

const useStyles = makeStyles({
    typography_1: {
        color: '#4285F4'
    },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
    },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
          '& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
    },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
          fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121',
    },
    label: {
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.small,
    }
});

const ViewStudentInquiry: FunctionComponent = () => {
    const classes = useStyles()
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    const [gridRows, setGridRows] = useState<any[]>([])
	const [gridColumns, setGridColumns] = useState<GridColDef[]>([])

    const {enqueueSnackbar} = useSnackbar()
    const [redirectTo, setRedirectTo] = useState('')

    if(redirectTo.length > 1) return <Redirect to={redirectTo} />

    const handleFetchStudentInquiries = async () => {
        await fetchStudentInquiries({
            startDate: new Date(startDate.setHours(0,0,0,0)),
            endDate: new Date(endDate.setHours(23,59,59,0))
        }).then((val) => {
            createDataGrid(val)
            if(val.length === 0) enqueueSnackbar('No Inquiries found', {variant: 'warning'})
        })
        .catch((err) => onApiError(err, enqueueSnackbar, setRedirectTo))
    }

    const createDataGrid = (datas: studentPublicProfile[]) => {

		setGridRows(datas.map((cur, i) => Object.assign(cur, {id: i+1})))

		setGridColumns([
			{ field: 'id', headerName: 'S.No', flex: 0.25 },
			{ field: 'studentName', headerName: 'Name', flex: 1, renderCell: datagridCellExpand },
			{ field: 'mobileNo', headerName: 'Mobile', flex: 1, renderCell: datagridCellExpand },
			{ field: 'emailId', headerName: 'Email', flex: 1, renderCell: datagridCellExpand },
			{ field: 'boardName', headerName: 'Board', flex: 1, renderCell: datagridCellExpand },
			{ field: 'className', headerName: 'Class', flex: 1, renderCell: datagridCellExpand }
		])
	}

    const renderDateInput = (key: string, value: any, setter: any) => (
        <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
                <Box className={classes.label}>
                    {key}
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl fullWidth margin="normal">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={key}
                            value={value}
                            onChange={(d) => setter(d)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
            </Grid>
        </Grid>
    )
    
    const renderButton = (name: string, cb: any) => (
        <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item>
                <Button variant="contained" color="primary" size="medium"
                    onClick={cb}
                >
                    {name}
                </Button>
            </Grid>
        </Grid>
    )

    return(
        <>
        <CssBaseline />
        <MiniDrawer>
        <Container maxWidth="lg">
            <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="5px"
            color='#fff'
            >
            <Grid item container>
            <Grid item sm={8}>
                <Box style={{height: '100%'}}>
                    <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                    >
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            Student Inquiries on Edumatica
                        </Typography>
                        <Typography>
                            Get Student Inquiries between two Dates 
                        </Typography>
                    </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            </Box>

            <Box
				bgcolor="#ffffff"
				borderRadius="5px"
				padding="12px 25px"
				marginTop='25px'
			>
                <Grid container justify='center'>
                    <Grid item container xs={12} lg={8} style={{paddingTop: '5px', marginBottom: '8px'}}>
                        {renderDateInput('Enter Start Date', startDate, setStartDate)}
                        {renderDateInput('Enter End Date', endDate, setEndDate)}
                        {renderButton('Search', handleFetchStudentInquiries)}
                    </Grid>
                    <Grid item xs={12}>
                        {gridRows.length > 0 && 
                        <>
                        <Datagrids disableCheckbox gridRows={gridRows} gridColumns={gridColumns}/>
                        </>}
                    </Grid>
                </Grid>
            </Box>
        </Container>
        </MiniDrawer>
        </>
    )
}

export default ViewStudentInquiry