import React, { useState, useEffect, FunctionComponent, } from 'react';
import {
	Box, Container, Grid, Input, Typography, CssBaseline, Card, CardContent, CardHeader, IconButton, Select, MenuItem, Button, TextField, Checkbox, ListItemText, darken, makeStyles, InputAdornment, lighten, createStyles, Theme
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
// import { isValidPhoneNumber } from 'libphonenumber-js';

import MiniDrawer from "../../../common/components/SideDrawer"
import { CustomUser, } from '../../../common/contracts/dashboard';
import { RootState } from '../../../redux/store';
import { connect, useDispatch } from 'react-redux';
import { createCollegeGroup, getOrgList, getUser, updateCollegeGroup, updateUserPreferences } from '../../../../api/dashboard';
import { ArrowBack } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { fontOptions } from '../../../../theme';
import CancelIcon from '@material-ui/icons/Cancel';

interface Props extends RouteComponentProps {
	user: CustomUser
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const useStyles = makeStyles((theme: Theme)=>
createStyles({
	typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121',
	  },
      label: {
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.small,
      },
      root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        height: "100%",
      },
      highlight:
        theme.palette.type === "light"
          ? {
              color: theme.palette.secondary.main,
              backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
          : {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.secondary.dark,
            },
}
));

const ViewCollegeGroup: FunctionComponent<Props> = ({ user, history,location }) => {
    const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
    const [collegeGroupName,setCollegeGroupName] = useState("")
    const [collegesList,setCollegesList] = useState<any>([])
    const [selectedColegeList,setSelectedCollegeList] = useState<any>([])
    const State: any = location?.state;
    let data = State?.data

    useEffect(()=>{
        getCollegeList()  
    },[])

    useEffect(()=>{
        if(data){
            setCollegeGroupName(data?.groupName)
            let tempCollegeList:any = collegesList?.filter((college:any)=>data?.collegeOwnerIdsArr?.includes(college?.ownerId))
            setSelectedCollegeList(tempCollegeList)
        }
    },[collegesList])

    const getCollegeList = async()=>{
        try {
            let collegeList =await getOrgList()
            setCollegesList(collegeList)
        } catch (error) {
            console.log("error",error);
        }   
    }
	
	return (
		<>
			<CssBaseline />
			<MiniDrawer>
					<Container style={{ width: '100%' }}>
                        <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="5px"
            color='#fff'
            >
            <Grid item container>
                <Grid item sm={8}>
                <Box style={{height: '100%'}}>
                    <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                    >
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                        View College Group
                        </Typography>
                        <Typography>View college group & college list.</Typography>
                        <Box style={{display:"flex"}}>
                            <Button style={{textTransform:"capitalize",backgroundColor:"#fff",color:"#4C8BF5",marginTop:10,marginRight:"10px"}} disableElevation variant="contained" size="small"  onClick={()=>{history.goBack()}} >Back</Button>
                            <Button style={{textTransform:"capitalize",backgroundColor:"#fff",color:"#4C8BF5",marginTop:10}} disableElevation variant="contained" size="small"  onClick={()=>{history.push("/create-group",{data:data})}} >Edit</Button>
                        </Box>
                    </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            </Box>
					
                    <Box style={{backgroundColor:"#fff",borderRadius:8,padding:24,paddingLeft:32}}>
                        <Grid xs={12} sm={12} md={8} lg={6}>
                            <Typography style={{fontWeight:"bold",marginBottom:8}}>College Group Name</Typography>
                            <TextField id="outlined-basic" placeholder='Enter College Group Name' variant="outlined" fullWidth value={collegeGroupName} onChange={(e)=>setCollegeGroupName(e.target.value)} disabled/>
                        </Grid>
                        <Grid  xs={12} style={{ marginTop: '20px' }}>
                            <hr style={{ borderTop: '2px dashed #B0B1B3', width: '100%', height: '0px', borderLeft: '0', borderRight: '0', borderBottom: '0' }}></hr>
                        </Grid>
                        <Typography style={{fontWeight:"bold",fontSize:"24px",marginTop:"10px",marginBottom:"10px"}}>Added Colleges</Typography>
                        <Grid container md={11} item justify='space-between' wrap='wrap' style={{marginLeft:"12px",marginRight:"12px"}}>
                            {
                                selectedColegeList?.map((college:any,index:number)=>{
                                    return(
                                        <Grid item sm={12} md={5} style={{marginBottom:"8px"}}>
                                            <Box style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                            <Typography style={{fontWeight:"bold",marginRight:"10px"}}>{`${index+1}. `}</Typography>
                                            <TextField id="outlined-basic" placeholder='Enter College Group Name' variant="outlined" fullWidth value={college?.organizationName} disabled/>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                    </Container>
			</MiniDrawer >
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	user: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(ViewCollegeGroup);