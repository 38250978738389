import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link, Redirect, RouteComponentProps } from 'react-router-dom'
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../utilities/constants';
import * as XLSX from 'xlsx';
import MiniDrawer from "../../common/components/SideDrawer"
import { Box, Button, Container, CssBaseline, darken, FormControl, FormHelperText, Grid, makeStyles, MenuItem, Typography, Select, IconButton, Menu } from '@material-ui/core';
import { fontOptions } from '../../../theme';
import DownloadIcon from '../../../assets/images/download-icon.png';
import UploadIcon from '../../../assets/images/upload-icon.png';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PaymentModeDataTable } from '../components/PaymentModeDataTable';
import { PaymentMode } from '../contracts/paymentMode_interface';

const useStyles = makeStyles({
	typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121'
	  },
});

const uploadOptions = [
    "Payment Mode",
]

interface Props extends RouteComponentProps {

}

const FinanceEdumacData: FunctionComponent<Props> = ({ location }) => {
	const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [activeIndex, setActiveIndex] = useState(-1);
	const [activeUploadOption, setActiveUploadOption] = useState<string | null>(null)
	const [viewData, setViewData] = useState<any[] | null>(null)
	const [masterPaymentModeData, setMasterPaymentModeData] = useState<PaymentMode[] | null>(null)

    useEffect(() => {
		getLoggedInUserData()
	}, []);

	useEffect(() => {
		if(activeIndex > -1) {
			setActiveUploadOption(uploadOptions[activeIndex])
			setViewData(null)
		}else {
			setActiveUploadOption(null)
			setViewData(null)
		}
		
	}, [activeIndex])

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		const requiredPermissions = [MASTER_PERMISSIONS.payorder]
		var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

    const readExcel = (file: File | null) => {
		const promise = new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			if (file) {
				fileReader.readAsArrayBuffer(file);
				fileReader.onload = (e) => {
					const bufferArray = e.target ? e.target.result : '';
					const wb = XLSX.read(bufferArray, { type: 'buffer' });
					const wsname = wb.SheetNames[0];
					const ws = wb.Sheets[wsname];

					/* Convert array to json*/
					const jsonData = XLSX.utils.sheet_to_json(ws);
					resolve(jsonData);
				};
				fileReader.onerror = (error) => {
					reject(error);
				};
			}
		});
		promise.then(async (dataArr: any) => {
			// console.log(dataArr)
			const uploadReq: any = await Promise.all(
				dataArr &&
				dataArr.map(async (data: any) => {
					if(activeUploadOption === "Payment Mode"){
						return {
							edumacPaymentMode: data["EdumaticaPaymentMode"],
                            partnerPaymentMode: data["PaymentPartnerPaymentMode"],
                            modeFriendlyName: data["Display Name"],
                            minMdr: data["MinMDR"],
                            maxMdr: data["MaxMDR"],
                            minFixed: data["Min Fixed"],
                            maxFixed: data["Max Fixed"]
						};
					}
				})
			);
			if(activeUploadOption === "Payment Mode"){
				setMasterPaymentModeData(uploadReq)
				setViewData(uploadReq)
			}
		});
	};

	const uploadData = () => {
		// console.log(masterChapterData)
	}


    return (
        <>
            <CssBaseline />
            <MiniDrawer>
			<Container maxWidth="lg">
				<Box
				bgcolor="#4C8BF5"
				padding="20px 30px"
				marginBottom="30px"
				position="relative"
				borderRadius="5px"
				color='#fff'
				>
				<Grid item container>
					<Grid item sm={8}>
					<Box style={{height: '100%'}}>
						<Grid
						container
						direction="row"
						alignItems="center"
						justify="center"
						style={{ height: '100%' }}
						>
						<Grid item xs={12}>
							<Typography className={classes.title}>
							Edumatica Finance Data
							</Typography>
							<Typography>
							Upload Payment mode data
							</Typography>
						</Grid>
						</Grid>
					</Box>
					</Grid>
				</Grid>
				</Box>

				<Box
				bgcolor="#ffffff"
				borderRadius="5px"
				padding="12px 25px"
				marginTop='25px'
				>
					<Grid container>
						<Grid item xs={12} style={{paddingTop: '5px', marginBottom: '8px'}}>
							<Box width="100%" display="flex" justifyContent="flex-start" alignItems="center">
								<Typography style={{fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, color: '#3D3D3D', marginRight:"18px"}}>
									Select Upload Type
								</Typography>
								{
								!uploadOptions ? '' :
								// <Box padding="0 8px" bgcolor="#fff">
									<FormControl className={classes.formControl}>
                                        <Select
                                            style={{minWidth:"100px"}}
                                            value={activeIndex}
                                            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                                            setActiveIndex(e.target.value as number)
                                            }
                                        >
                                            <MenuItem value="-1">Select upload Type</MenuItem>
                                            {uploadOptions.map((each, index) => (
                                            <MenuItem key={index} value={index}>
                                                {each}
                                            </MenuItem>
                                            ))}
                                        </Select>
                                        {/* <FormHelperText>Upload Options</FormHelperText> */}
									</FormControl>
								// </Box>
								}
							</Box>
						</Grid>
						{
							activeIndex < 0 ? '' :
							<Box width="100%" borderBottom="1px solid rgba(224, 224, 224, 0.5)">
								<Grid container>
								<Grid item xs={12} md={6}>
									<Box padding="20px 30px" display="flex" alignItems="center">
									<Box marginLeft="15px">
										<Typography component="span" color="secondary">
										<Box component="h3" className={classes.heading}>
											{activeUploadOption}
										</Box>
										</Typography>
									</Box>
									</Box>
								</Grid>

								<Grid item xs={12} md={6}>
									<Box
									display="flex"
									alignItems="center"
									justifyContent="flex-end"
									padding="20px 30px"
									>
									<Box marginRight="10px">
										<div>
										<input
											accept=".xls, .xlsx"
											style={{ display: 'none' }}
											id="contained-button-file"
											type="file"
											onChange={(e) => {
											readExcel(e.target.files && e.target.files[0]);
											e.target.value = '';
											}}
										/>
										<label htmlFor="contained-button-file">
											<Button
											component="span"
											className={classes.helperText}
											>
											Upload {activeUploadOption}
											<Box
												display="flex"
												alignItems="center"
												marginLeft="15px"
											>
												<img src={UploadIcon} alt="Upload Data" />
											</Box>
											</Button>
										</label>
										</div>
									</Box>

									<Box>
										<Button>
										<Link
											className={classes.helperText}
											style={{ textDecoration: 'none' }}
											to="/files/students.xlsx"
											target="_blank"
											download
										>
											Download Template
										</Link>
										<Box display="flex" alignItems="center" marginLeft="15px">
											<img src={DownloadIcon} alt="Download Sample Template" />
										</Box>
										</Button>
									</Box>
									</Box>
								</Grid>
								</Grid>
							</Box>
						}
					</Grid>
					{
						!viewData ? '' :
						<Grid container>
							<Grid item xs={12} style={{paddingTop: '5px', marginBottom: '8px'}}>
								{
									activeUploadOption && activeUploadOption === "Payment Mode" ? <PaymentModeDataTable gridData={viewData}/> : ''
								}
							</Grid>
							<Grid item xs={12} style={{paddingTop: '5px', marginBottom: '8px'}}>
								<Button onClick={uploadData} disableElevation color="primary" variant="contained">
									Confirm Upload
								</Button>
							</Grid>
						</Grid>
					}
				</Box>
			</Container>
            </MiniDrawer>
        </>   
    )
}

export default FinanceEdumacData
