import { createStyles, makeStyles } from "@material-ui/core";
import { fontOptions } from "../../theme";

const useStyles = makeStyles((theme) =>
	createStyles({
		paddClass: {
			// padding: '0px 30px'
		},
		label: {
			fontWeight: fontOptions.weight.bold,
			fontSize: fontOptions.size.small,
		},
		error: {
			"&:not(.Mui-disabled)::before": {
				borderColor: "#F44E42"
			}
		},
		margin: {
			margin: theme.spacing(1), width: '100%'
		},
		header: {
			backgroundColor: '#C4C4C4',
			width: '100%',
			padding: '10px',
		},
		card: {
			// margin: '20px',
			padding: '20px',
			width: '100%'
		},
		test: {
			// backgroundColor: 'red'
		},
		previousLabel: {
			fontWeight: fontOptions.weight.normal,
			fontSize: fontOptions.size.small,
			padding: '0px 20px',
			color: '#000000',
			opacity: 0.4
		},
		gridList: {
			width: 500,
			height: 450,
			// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
			transform: 'translateZ(0)',
		},
		submitBtn: { margin: '20px 0px' },
	})
);

export default useStyles;