import React, { useState, useEffect, FunctionComponent, FormEvent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
	Box, Container, Grid, Checkbox, Button, TextField, Input, MenuItem, Menu,
	Typography, FormControlLabel, Switch, FormHelperText, OutlinedInput, IconButton, CssBaseline, Card, CardContent, CardHeader
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';

import MiniDrawer from "../../../common/components/SideDrawer"
import { fontOptions } from '../../../common/theme';
import CustomTextField from '../../components/customTextField';
import { MU_EMAIL_PATTERN, PHONE_PATTERN } from '../../../common/validations/patterns';
import { editAdminUser, getRoles } from '../../../../api/dashboard';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import useStyles from './styles';
import { Admin, Role, EditAdmin } from '../../../common/contracts/dashboard';
import { RootState } from '../../../redux/store';
import { connect } from 'react-redux';

interface ModalState {
	_id: string,
	name: string,
	status: string
}

interface Props extends RouteComponentProps {
	admin: Admin
}

interface FormData {
	firstName: string,
	lastName: string,
	emailId: string,
	mobileNumber: string,
	role: string
}

const ValidationSchema = yup.object().shape({
	firstName: yup.string().trim().required('First name is required'),
	// .validate(val=>val.trim().length>0)
	// .min(5, 'cannot be less than 5 characters'),
	lastName: yup.string().required('Last name is required'),
	emailId: yup.string().required('Email is required')
		.matches(MU_EMAIL_PATTERN, 'Email is invalid'),
	mobileNumber: yup.string().required('Mobile number is required')
		.matches(PHONE_PATTERN, 'Mobile number is invalid'),
	// role: yup.string().notOneOf(['Select']).required('Role is required')
});

const InvoiceDetails: FunctionComponent<Props> = ({ admin }) => {
	const { errors, setError, clearError, handleSubmit, register, reset, control, setValue } = useForm<FormData>({
		mode: 'onBlur',
		validationSchema: ValidationSchema
	});

	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const [roles, setRoles] = useState<Role[]>([]);
	const styles = useStyles();

	// useEffect(() => {
	// 	// getUserRoles();
	// }, []);

	// const getUserRoles = async () => {
	// 	setLoading(true);
	// 	try {
	// 		const roles = await getRoles();
	// 		setRoles(roles);
	// 		setLoading(false);
	// 	} catch (error) {
	// 		setLoading(false);
	// 		enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
	// 		if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
	// 			setRedirectTo('/login');
	// 		}
	// 	}
	// }

	const handleInvoiceDetails = async (data: FormData) => {
		var { firstName, lastName, emailId, mobileNumber, role } = data;
		// clearError('serverError');

		if (firstName.replace(/ /g, "").length < 5) {
			setError('firstName', 'Invalid Data', 'First name cannot be less than 5 character excluding spaces');
			return;
		} else {
			clearError('firstName');
		}

		if (lastName.replace(/ /g, "").length < 5) {
			setError('lastName', 'Invalid Data', 'Last name cannot be less than 5 character excluding spaces');
			return;
		} else {
			clearError('lastName');
		}

		var obj: any = {};

		if (firstName != admin.firstName)
			obj['firstName'] = firstName;
		if (lastName != admin.lastName)
			obj['lastName'] = lastName;
		if (mobileNumber != admin.mobileNo)
			obj['mobileNo'] = mobileNumber;
		if (emailId != admin.emailId) {
			obj['emailId'] = emailId;
		}
		obj['oldEmailId'] = admin.emailId;
		obj['_id'] = admin._id;

		try {
			await editAdminUser(obj);
			enqueueSnackbar('Updated successfully', { variant: 'success' });
			setRedirectTo('/search-admin');
		} catch (error) {
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							<Grid item className={styles.header}>
								<Typography variant="h5" >Manage Users</Typography>
							</Grid>

							<Card className={styles.card} variant='outlined'>
								<CardHeader title='Edit User' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>

									<form onSubmit={handleSubmit(handleInvoiceDetails)} >
										<Grid container justify='center' alignItems='center' className={styles.test} >
											<Grid item >
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>First Name</Box>
												</FormControl>
											</Grid>
											<Grid item >
												<CustomTextField margin="normal"
													name="firstName"
													placeholder="Enter First Name"
													inputProps={{ maxLength: 50 }}
													className={errors.firstName ? styles.error : ''}
													inputRef={register}
													defaultValue={admin ? admin.firstName : ''}
												/>
												{errors.firstName && (
													<FormHelperText error>
														{errors.firstName.message}
													</FormHelperText>
												)}
											</Grid>

											<Grid item >
												<FormControl fullWidth margin="normal">
													<Box className={styles.previousLabel}>Previous: {admin.firstName ? admin.firstName : ''}</Box>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container justify='center' alignItems='center' className={styles.test} >
											<Grid item >
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Last Name</Box>
												</FormControl>
											</Grid>
											<Grid item >
												<CustomTextField margin="normal"
													name="lastName"
													placeholder="Enter Last Name"
													inputProps={{ maxLength: 50 }}
													className={errors.lastName ? styles.error : ''}
													inputRef={register}
													defaultValue={admin ? admin.lastName : ''}
												/>
												{errors.lastName && (
													<FormHelperText error>
														{errors.lastName.message}
													</FormHelperText>
												)}
											</Grid>
											<Grid item >
												<FormControl fullWidth margin="normal">
													<Box className={styles.previousLabel}>Previous: {admin.lastName ? admin.lastName : ''}</Box>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container justify='center' alignItems='center' className={styles.test} >
											<Grid item >
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Email Id</Box>
												</FormControl>
											</Grid>
											<Grid item >
												<CustomTextField
													name="emailId"
													placeholder="Enter Email Id"
													inputProps={{ maxLength: 50 }}
													className={styles.margin}
													inputRef={register}
													defaultValue={admin ? admin.emailId : ''}
												/>
												{errors.emailId && (
													<FormHelperText error>
														{errors.emailId.message}
													</FormHelperText>
												)}
											</Grid>
											<Grid item >
												<FormControl fullWidth margin="normal">
													<Box className={styles.previousLabel}>Previous: {admin.emailId ? admin.emailId : ''}</Box>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container justify='center' alignItems='center' className={styles.test} >
											<Grid >
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Mobile Number</Box>
												</FormControl>
											</Grid>
											<Grid item>
												<CustomTextField
													name="mobileNumber"
													placeholder="Enter Mobile Number"
													inputProps={{ inputMode: 'numeric', maxLength: 10 }}
													className={styles.margin}
													inputRef={register}
													defaultValue={admin ? admin.mobileNo : ''}
												/>
												{errors.mobileNumber && (
													<FormHelperText error>
														{errors.mobileNumber.message}
													</FormHelperText>
												)}
											</Grid>
											<Grid item >
												<FormControl fullWidth margin="normal">
													<Box className={styles.previousLabel}>Previous: {admin.mobileNo ? admin.mobileNo : ''}</Box>
												</FormControl>
											</Grid>
										</Grid>

										{/* <Grid container justify='center' alignItems='center' className={styles.test} >
											<Grid >
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Role</Box>
												</FormControl>
											</Grid>
											<Grid item>
												<FormControl fullWidth margin="normal">
													<Controller
														as={
															<Select>
																<MenuItem value="Select">Select</MenuItem>
																{roles.length > 0 && roles.map((item, index) => (
																	<MenuItem value={item.name} key={index}	>
																		{item.name}
																	</MenuItem>
																))}
															</Select>
														}
														name="role"
														inputRef={register}
														control={control}
														setValue={setValue}
														defaultValue="Select"
														displayEmpty
													/>
													{errors.role && (
														<FormHelperText error>
															{errors.role.message}
														</FormHelperText>
													)}
												</FormControl>
											</Grid>
										</Grid> */}

										<Box >
											<Button
												disableElevation
												variant="contained"
												color="primary"
												size="large"
												type="submit"
											// disabled={true}
											>
												Save user
											</Button>
										</Box>

									</form>
								</CardContent>
							</Card>

						</Grid>
					</Container>
				</div >
			</MiniDrawer >
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	admin: state.adminReducer.admin as Admin,
});

export default connect(mapStateToProps)(InvoiceDetails);