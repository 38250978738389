import React, { useState, useEffect, FunctionComponent, } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
	Box, Container, Grid, Button, Input, MenuItem, Typography, FormControlLabel, FormHelperText,
	CssBaseline, Card, CardContent, CardHeader, Radio,
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"

import MiniDrawer from "../../../common/components/SideDrawer"
import { EMAIL_PATTERN, PHONE_PATTERN } from '../../../common/validations/patterns';
import { getKycStatusList, getKycData, updateKycStatus, fetchDownloadUrlForKycDocument, fetchDownloadUrlForKycVideo, } from '../../../../api/dashboard';
import useStyles from './styles';
import { USER_TYPES, USER_TYPE_VALUES, MASTER_PERMISSIONS, USER_ROLES, KYC_STATUS } from '../../../../utilities/constants';

interface Props extends RouteComponentProps {

}
const ManageKYC: FunctionComponent<Props> = ({ location }) => {

	const { enqueueSnackbar } = useSnackbar()
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const [kycStatusList, setKYCStatusList] = useState<any[]>([]);	//crosscheck
	const [searchText, setSearchText] = useState<string>('');
	const [searchBySelection, setSearchBySelection] = useState('');
	const [searchForSelection, setSearchForSelection] = useState(USER_TYPES.TUTOR);
	const [selectedUserType, setSelectedUserType] = useState(USER_TYPE_VALUES.TUTOR);
	const [selectedPendingKyc, setSelectedPendingKyc] = useState<any>();
	const [kycData, setKycData] = useState<any>({});
	const [actionReason, setActionReason] = useState('');
	const [actionReasonError, setActionReasonError] = useState('');
	const styles = useStyles();

	var allowedPermissions: string[] = [];
	var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
	var loginUserType = '';
	if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
		allowedPermissions = loginUserData.adminRole.permissions;
		loginUserType = loginUserData.adminRole.name;
	}


	useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {
		// setLoading(true);
		// try {
		// 	const adminUserData = await getAdminData();
		// 	dispatch(setLoginUserData(JSON.stringify(adminUserData)));
		// 	setLoading(false);
		// 	if (adminUserData.isItDefaultPassword) {
		// 		localStorage.setItem('isItDefaultPassword', JSON.stringify(adminUserData.isItDefaultPassword));
		// 		setRedirectTo('/set-password');
		// 	}
		// } catch (error) {
		// 	setLoading(false);
		// 	enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
		// }
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}

		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.makerKyc)
			|| allowedPermissions.includes(MASTER_PERMISSIONS.checkerKyc);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const handleSearchForSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchForSelection(event.target.value);

		switch (event.target.value) {
			case USER_TYPES.TUTOR:
				setSelectedUserType(USER_TYPE_VALUES.TUTOR);
				break;
			case USER_TYPES.ORG:
				setSelectedUserType(USER_TYPE_VALUES.ORG);
				break;
			case 'bothTutorOrg':
				setSelectedUserType('bothTutorOrg');
				break;
			default:
				setSelectedUserType(USER_TYPE_VALUES.TUTOR);
				break;
		}
		setSearchText('');
	};

	const handleSearchBySelection = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchBySelection(event.target.value);
		setSearchText('');
	};

	const handleSearchAll = () => {
		setActionReason('');
		if (allowedPermissions.includes(MASTER_PERMISSIONS.fetchKycStatus) || loginUserType == USER_ROLES.super) {
			search();
		} else {
			enqueueSnackbar("You don't have access to this action", { variant: 'warning' });
		}
	}

	const search = async () => {
		setKycData({});
		setLoading(true);
		try {
			var data = [];
			var obj: any = {};
			if (searchBySelection == 'mobile' && PHONE_PATTERN.test(searchText)) {
				obj = getObj();
				data = await getKycStatusList(obj);
			} else if (searchBySelection == 'email' && EMAIL_PATTERN.test(searchText)) {
				obj = getObj();
				data = await getKycStatusList(obj);
			} else if (searchBySelection == 'name' && searchText != '') {
				obj = getObj();
				data = await getKycStatusList(obj);
			} else {
				if (searchBySelection) {
					switch (searchBySelection) {
						case 'mobile':
							enqueueSnackbar('Please enter valid mobile number', { variant: 'warning' });
							return;
						default:
							enqueueSnackbar('Please enter valid ' + searchBySelection, { variant: 'warning' });
							return;
					}
				} else {
					obj = { 'userType': selectedUserType }
					var mObj = serialize(obj);
					data = await getKycStatusList(mObj);
				}
			}
			setKYCStatusList(data);
			enqueueSnackbar(data.length + ' record(s) fetched!', { variant: 'success' });
			if (data && data.length == 1) {
				setSelectedPendingKyc(data[0])
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	const getObj = () => {
		var obj: any = {
			userType: selectedUserType,
		}
		var commonObj = {
			"$regex": searchText,
			"$options": "i"
		}
		var query: any = {};
		if (searchBySelection == 'mobile') {
			query['mobileNo'] = commonObj;
		} else if (searchBySelection == 'name') {
			switch (searchForSelection) {
				case USER_TYPES.TUTOR:
					query[USER_TYPES.TUTOR] = commonObj;
					break;
				case USER_TYPES.ORG:
					query[USER_TYPES.ORG] = commonObj;
					break;
				case USER_TYPES.STUDENT:
					query[USER_TYPES.STUDENT] = commonObj;
					break;
				case USER_TYPES.PARENT:
					query[USER_TYPES.PARENT] = commonObj;
					break;
				default:
					query[USER_TYPES.TUTOR] = commonObj;
					break;
			}
		} else if (searchBySelection == 'email') {
			query['emailId'] = commonObj;
		}
		obj['query'] = query;
		var mObj = serialize(obj)
		return mObj;
	}

	const serialize = (obj: any, prefix?: any) => {
		var str: any = [],
			p;
		for (p in obj) {
			if (obj.hasOwnProperty(p)) {
				var k = prefix ? prefix + "[" + p + "]" : p,
					v = obj[p];
				str.push((v !== null && typeof v === "object") ?
					serialize(v, k) :
					encodeURIComponent(k) + "=" + encodeURIComponent(v));
			}
		}
		return str.join("&");
	}

	const handlePendingKyc = (event: React.ChangeEvent<{ value: unknown }>) => {
		let etv = event.target.value;
		setKycData({});
		setActionReason('');
		if (etv == 'Select') {
			enqueueSnackbar('Please select valid user', { variant: 'warning' });
			setSelectedPendingKyc({});
		}
		kycStatusList.forEach((item) => {
			if (item.tutorName == etv || item.organizationName == etv) {
				setSelectedPendingKyc(item);
				if (item.tutorName) {
					setSelectedUserType('tutor');
				} else if (item.organizationName) {
					setSelectedUserType('org');
				} else {
					setSelectedUserType('tutor');
				}
				return;
			}
		});
	}

	const handleFetchKyc = async () => {
		if (allowedPermissions.includes(MASTER_PERMISSIONS.fetchKycData) || loginUserType == USER_ROLES.super) {
			if (selectedPendingKyc && selectedPendingKyc._id) {
				var userType = selectedUserType;
				if (selectedUserType == 'bothTutorOrg') {
					if (selectedPendingKyc.tutorName) {
						userType = USER_TYPE_VALUES.TUTOR
					} else {
						userType = USER_TYPE_VALUES.ORG
					}
				}

				try {
					const data = await getKycData(userType, selectedPendingKyc._id);
					setKycData(data);
				} catch (error) {
					enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
					if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
						setRedirectTo('/login');
					}
				}
			} else {
				enqueueSnackbar('Please Select a user', { variant: 'info' });
			}
		} else {
			enqueueSnackbar("You don't have access to this action", { variant: 'warning' });
		}
	}

	const handleApproveAsMaker = () => {
		updateKyc(KYC_STATUS.makerApproved, 'makerKycReason');
	}
	const handleApproveAsChecker = () => {
		updateKyc(KYC_STATUS.checkerApproved, 'checkerKycReason');
	}
	const handleRejectAsMaker = () => {
		updateKyc(KYC_STATUS.makerRejected, 'makerKycReason');
	}
	const handleRejectAsChecker = () => {
		updateKyc(KYC_STATUS.checkerRejected, 'checkerKycReason');
	}

	const updateKyc = async (status: string, userKycReason: string) => {
		if (!actionReason) {
			setActionReasonError('Please enter action')
		} else {
			try {
				var obj: any = {
					userType: selectedUserType,
					userId: selectedPendingKyc._id,
					allKycStatus: status,
				}
				obj[userKycReason] = actionReason

				const data = await updateKycStatus(obj)
				setKycData({});
				setActionReason('');
				enqueueSnackbar('Updated Successfully', { variant: 'success' });
			} catch (error) {
				enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
				if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
					setRedirectTo('/login');
				}
			}
		}
	}

	const handleViewDocument = async (item: any) => {
		if (kycData && kycData.ownerId && item.uuid) {
			try {
				let response = await fetchDownloadUrlForKycDocument(item.uuid, kycData.ownerId);
				setTimeout(() => {
					window.open(response.url);
				}, 100);
			} catch (error) {
				enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
				if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
					setRedirectTo('/login');
				}
			}
		}
		else {
			enqueueSnackbar('Something went wrong', { variant: 'warning' });
		}
	}

	const handleViewVideo = async (uuid: string) => {
		if (kycData && kycData.ownerId && uuid) {
			try {
				let response = await fetchDownloadUrlForKycVideo(uuid, kycData.ownerId);
				setTimeout(() => {
					window.open(response.data);
				}, 100);
			} catch (error) {
				enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
				if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
					setRedirectTo('/login');
				}
			}
		}
		else {
			enqueueSnackbar('Something went wrong', { variant: 'warning' });
		}
	}

	const getDocName = (docType: string) => {
		switch (docType) {
			case 'aadhar':
				return 'Aadhaar'
			case 'businessPan':
				return 'Business Pan';
			case 'ownerPan':
				return 'Director Pan';
			default:
				return docType;
		}
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Manage KYC</Typography>
							</Grid> */}
							<Grid item className={styles.header}>
								<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
							</Grid>
							<Card className={styles.card} variant='outlined' style={{ width: '100%' }}>
								<CardHeader title='Search for' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>
									<FormControlLabel
										value="end"
										control={
											<Radio
												checked={searchForSelection === USER_TYPES.TUTOR}
												onChange={handleSearchForSelection}
												value={USER_TYPES.TUTOR}
												name={USER_TYPES.TUTOR}
												color='default'
											/>
										}
										label="Tutor"
									/>

									<FormControlLabel
										value="end"
										control={
											<Radio
												checked={searchForSelection === USER_TYPES.ORG}
												onChange={handleSearchForSelection}
												value={USER_TYPES.ORG}
												name={USER_TYPES.ORG}
												color='default'
											/>
										}
										label="Coaching Class"
									/>

									<FormControlLabel
										value="end"
										control={
											<Radio
												checked={searchForSelection === 'bothTutorOrg'}
												onChange={handleSearchForSelection}
												value='bothTutorOrg'
												name='bothTutorOrg'
												color='default'
											/>
										}
										label="All Pending"
									/>

									<Button variant="contained" color="primary" size="medium" onClick={handleSearchAll}>Search All
									</Button>
								</CardContent>
							</Card>

							<Card className={styles.card} variant='outlined' style={{ width: '100%' }}>
								<CardHeader title='Search by' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>

									<FormControlLabel
										value="end"
										control={
											<Radio
												checked={searchBySelection === 'mobile'}
												onChange={handleSearchBySelection}
												value="mobile"
												name="mobile"
												color='default'
											/>
										}
										label="Mobile"
									/>
									<FormControlLabel
										value="end"
										control={
											<Radio
												checked={searchBySelection === 'name'}
												onChange={handleSearchBySelection}
												value="name"
												name="name"
												color='default'
											/>
										}
										label="Name"
									/>
									<FormControlLabel
										value="end"
										control={
											<Radio
												checked={searchBySelection === 'email'}
												onChange={handleSearchBySelection}
												value="email"
												name="email"
												color='default'
											/>
										}
										label="Email"
									/>


									<Button variant="contained" color="primary" size="medium"
										style={{ margin: 10 }}
										onClick={() => { setSearchBySelection(''); setSearchText('') }}>Clear
									</Button>

									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Input
													name='search'
													placeholder='Search'
													onChange={(e) => {
														if (searchBySelection) {
															setSearchText(e.target.value)
														} else {
															enqueueSnackbar('Please Select search by value', { variant: 'warning' });
														}
													}}
													value={searchText}
													inputProps={{ maxLength: searchBySelection == 'mobile' ? 10 : 50 }}
												/>

											</FormControl>
										</Grid>
										<Button variant="contained" color="primary" size="medium"
											style={{ margin: 10 }}
											onClick={handleSearchAll}
											disabled={searchBySelection ? false : true}
										>
											Search
										</Button>
									</Grid>
								</CardContent>
							</Card>

							<Card className={styles.card} variant='outlined' style={{ width: '100%' }}>
								<CardHeader title='Select KYC' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>
									<Grid container  >
										<Grid item xs={12} md={3}  >
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Select Pending KYC</Box>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={3} >
											<FormControl fullWidth margin="normal">
												<Select
													onChange={handlePendingKyc}
													defaultValue='Select'
													value={selectedPendingKyc ? (selectedPendingKyc.tutorName || selectedPendingKyc.organizationName) : ''}
												>
													<MenuItem value="Select">Select</MenuItem>
													{kycStatusList.length > 0 && kycStatusList.map((item, index) => (
														<MenuItem value={item.tutorName || item.organizationName} key={index}	>
															{item.tutorName || item.organizationName}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>

										<Button variant="contained" color="primary" size="medium" onClick={handleFetchKyc} style={{ margin: 10 }}>
											Fetch KYC
										</Button>
									</Grid>

								</CardContent>
							</Card>

							{kycData && kycData._id &&
								<>
									<Card className={styles.card} variant='outlined' style={{ width: '100%' }}>
										<CardHeader title='KYC STATUS' titleTypographyProps={{ varaint: 'h5' }} />
										<CardContent>

											<Grid item xs={12}>
												<Grid container justify="center" spacing={3}>
													<Grid item xs={12} md={3}>
														<Grid item direction='row'>
															<Typography variant='h6'>Name</Typography>
															<Typography variant='h6' color='textSecondary'>{kycData.tutorName || kycData.organizationName}</Typography>
														</Grid>
													</Grid>

													<Grid item xs={12} md={3}>
														<Grid item direction='row'>
															<Typography variant='h6'>Bank Account</Typography>
															{kycData && kycData.makerBankChange ?
																<Typography variant='h6' color='textSecondary'>{kycData.makerBankChange.bankAccount || 'Not Found'}</Typography> :
																<Typography variant='h6' color='textSecondary'>{kycData.bankAccount || 'Not Found'}</Typography>
															}
														</Grid>
													</Grid>

													<Grid item xs={12} md={3}>
														<Grid item direction='row'>
															<Typography variant='h6'>IFSC</Typography>
															{kycData && kycData.makerBankChange ?
																<Typography variant='h6' color='textSecondary'>{kycData.makerBankChange.bankIfsc || 'Not Found'}</Typography> :
																<Typography variant='h6' color='textSecondary'>{kycData.bankIfsc || 'Not Found'}</Typography>
															}
														</Grid>
													</Grid>

													<Grid item xs={12} md={3}>
														<Grid item direction='row'>
															<Typography variant='h6'>Aadhaar</Typography>
															<Typography variant='h6' color='textSecondary'>{kycData.aadhaar || 'Not Found'}</Typography>
														</Grid>
													</Grid>

													{selectedUserType == 'tutor' ?
														<Grid item xs={12} md={3}>
															<Grid item direction='row'>
																<Typography variant='h6'>PAN</Typography>
																<Typography variant='h6' color='textSecondary'>{kycData.pan || 'Not Found'}</Typography>
															</Grid>
														</Grid> :
														<>
															<Grid item xs={12} md={3}>
																<Grid item direction='row'>
																	<Typography variant='h6'> Business PAN</Typography>
																	<Typography variant='h6' color='textSecondary'>{kycData.businessPAN || 'Not Found'}</Typography>
																</Grid>
															</Grid>
															<Grid item xs={12} md={3}>
																<Grid item direction='row'>
																	<Typography variant='h6'>Director PAN</Typography>
																	<Typography variant='h6' color='textSecondary'>{kycData.ownerPAN || 'Not Found'}</Typography>
																</Grid>
															</Grid>
														</>
													}

													<Grid item xs={12} md={3}>
														<Grid item direction='row'>
															<Typography variant='h6'>KYC video code</Typography>
															<Typography variant='h6' color='textSecondary'>{kycData.videoKycToken || 'Not Found'}</Typography>
														</Grid>
													</Grid>

													<Grid item xs={12} md={3}>
														<Grid item direction='row'>
															<Typography variant='h6'> KYC Status</Typography>
															<Typography variant='h6' color='textSecondary'>{kycData.allKycStatus}	</Typography>
														</Grid>
													</Grid>

													<Grid item xs={12} md={3}>
														<Grid item direction='row'>
														</Grid>
													</Grid>

												</Grid>

												<Grid container spacing={3}>
													{
														kycData && kycData.makerBankChange && kycData.makerBankChange.kycDetails ?
															kycData.makerBankChange.kycDetails.map((item: any, index: number) => (
																<Grid item xs={12} md={3}>
																	<Button key={index} variant='contained' size='small'
																		onClick={() => handleViewDocument(item)}
																	> View {getDocName(item.kycDocType)}</Button>
																</Grid>)
															) :
															kycData && kycData.kycDetails ? kycData.kycDetails.map((item: any, index: number) => (
																<Grid item xs={12} md={3}>
																	<Button key={index} variant='contained' size='small'
																		onClick={() => handleViewDocument(item)}
																	> View {getDocName(item.kycDocType)}</Button>
																</Grid>)
															) :
																null
													}
												</Grid>


												<Grid container spacing={3} style={{ marginTop: '10px' }}>
													{
														kycData && kycData.makerBankChange && kycData.makerBankChange.videoKycUuid ?
															<Grid item xs={12} md={3}>
																<Button variant='contained' size='small'
																	onClick={() => handleViewVideo(kycData.makerBankChange.videoKycUuid)}
																> View Video KYC</Button>
															</Grid>
															:
															kycData && kycData.videoKycUuid ?
																<Grid item xs={12} md={3}>
																	<Button variant='contained' size='small'
																		onClick={() => handleViewVideo(kycData.videoKycUuid)}
																	> View Video KYC</Button>
																</Grid>
																:
																null
													}
												</Grid>

												{kycData.kycDetails && kycData.kycDetails.length > 0 &&
													<Grid container style={{ height: '100px', width: '600px', marginTop: '10px' }} >
														<Grid item xs={12} md={4}>
															<FormControl fullWidth margin='normal'>
																<Box className={styles.label}>Action Reason</Box>
															</FormControl>
														</Grid>

														<Grid item xs={12} md={4}>
															<FormControl fullWidth margin="normal">
																<Input
																	name='actionReason'
																	inputProps={{ maxLength: 100 }}
																	value={actionReason}
																	onChange={(e) => {
																		setActionReasonError('')
																		setActionReason(e.target.value)
																	}}
																	multiline
																/>
															</FormControl>
															{actionReasonError &&
																<FormHelperText error>
																	{actionReasonError}
																</FormHelperText>
															}
														</Grid>
													</Grid>}

											</Grid>
										</CardContent>
									</Card>
									<Grid>
										{kycData.kycDetails && kycData.kycDetails.length > 0 &&
											<>
												{(kycData.allKycStatus != KYC_STATUS.makerApproved && kycData.allKycStatus != KYC_STATUS.makerRejected
													&& kycData.allKycStatus != KYC_STATUS.checkerApproved && kycData.allKycStatus != KYC_STATUS.checkerRejected) &&
													<>
														{(allowedPermissions.includes(MASTER_PERMISSIONS.makerKyc) || loginUserType == USER_ROLES.super) &&
															<Button variant="contained" color="primary" size="medium"
																style={{ margin: 10 }}
																onClick={handleApproveAsMaker}
															>	Approve as Maker
														</Button>
														}
														{(allowedPermissions.includes(MASTER_PERMISSIONS.makerKyc) || loginUserType == USER_ROLES.super) &&
															<Button variant="contained" color="primary" size="medium"
																style={{ margin: 10 }}
																onClick={handleRejectAsMaker}
															>	Reject as Maker
														</Button>
														}
													</>}
												{(kycData.allKycStatus != KYC_STATUS.checkerApproved && kycData.allKycStatus != KYC_STATUS.checkerRejected) &&
													<>
														{(allowedPermissions.includes(MASTER_PERMISSIONS.checkerKyc) || loginUserType == USER_ROLES.super) &&
															<Button variant="contained" color="primary" size="medium"
																style={{ margin: 10 }}
																onClick={handleApproveAsChecker}
															>	Approve as Checker
														</Button>
														}

														{(allowedPermissions.includes(MASTER_PERMISSIONS.checkerKyc) || loginUserType == USER_ROLES.super)
															&& <Button variant="contained" color="primary" size="medium"
																style={{ margin: 10 }}
																onClick={handleRejectAsChecker}
															>	Reject as Checker
														</Button>
														}
													</>}
											</>
										}
									</Grid>
								</>
							}
						</Grid>
					</Container>
				</div>
			</MiniDrawer>
		</>
	);
}

export default ManageKYC;
