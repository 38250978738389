import { Button, Grid, makeStyles } from "@material-ui/core";
import React, {FunctionComponent, useState, useEffect} from "react";

export const useStyles = makeStyles({
    symbolIconButton: {
        height: '32px',
        width: '32px',
        textTransform: 'none'
    },
    label: {
        fontFamily: 'roboto',
        fontWeight: 500,
        fontSize: '15px',
    },
    mathField: {
        width: '100%',
        height: '75px'
    }
})