import axios from "axios"
import { UPLOAD_DATA,GET_FILENAMES ,GET_MANIFEST,DELETE_BY_FILENAME,GET_COURSE_NAME,GET_COURSE_DATA, DOCX_DATA,GET_UPLOAD_URL, GET_API_SET, GET_STUDENTENROLLED} from "./routes"
import {CourseBundle} from "./contracts"
import { ApiRequest } from "../containers/uploadContentFile"


export const uploadFileOnUrl = async (url: string, file: File, handlePercentageComplete?:(percentage:number)=>any ) => {

    // formData.append("Content-Type", file.type);
    // Object.entries(url.fields).forEach(([k, v]) => {
    //   formData.append(k, v);
    // });
    var config = {
      onUploadProgress: function(progressEvent:any) {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        console.log(percentCompleted)
        handlePercentageComplete && handlePercentageComplete(percentCompleted as number)
      }
    };
  
    const response = await axios.put(url, file, {headers:{'Content-Type':file.type} , ...(handlePercentageComplete?config:{})});
  
    return response.data;
  };

  interface CourseBundleResponse {
    jsonData : CourseBundle
}

export const getUploadUrlApi  =async (type:string, filename: string , uuid:string , contenttype? : string) =>{
    const response = await axios.get<{data:{dataUrl:string, timestamp:string}}>(GET_UPLOAD_URL, {params:{type, filename, uuid , contenttype }})
    return response.data.data
}

export const apiDataProcess  =async (requestStack  : ApiRequest[]) =>{
    const response = await axios.post(GET_API_SET,{apiData: requestStack})
    return response.data
}


export interface APISubject {
    _id: string,
    title : string,
    deliveryLang : string[]
    batches : {_id:string}[]
}
export interface publicCourse  {
    _id:string,
    board :string,
    class : string,
    title: string,
    subtitle :string,
    includedCourses : APISubject[]
}

interface courseBundleResponse {
    pcs : publicCourse[]
}

export const getCourseBundles = async () =>{
    const response  = await axios.get<courseBundleResponse>(GET_COURSE_NAME)
    return response.data.pcs
}

export const getManifest = async (courseBundleId:string) =>{
    const response = await axios.get<CourseBundleResponse>(GET_MANIFEST, {params:{courseBundleId }})
    return response.data.jsonData
}

export const isStudentEnrolled = async (courseBundleId:string) =>{
    const response = await axios.get<{ status: boolean }>(GET_STUDENTENROLLED, {params:{courseBundleId }})
    return response.data.status
}






export interface  ModuleInterface { 
    "_id": string,
    "ownerId": string,
    "type": "Quiz" | "Video" | "Assignment" | "Recording" | "PDF" |"Image",
    "title": string,
    "subtitle": string,
    "optional": boolean,
    "orderNo": number,
}

export interface StudentData {
    _id:string,
    studentName : string
}

export interface ModuleStatusInfo {
    "completionStatus" : "completed" | "onHold" | "notAttempted",
    "passed" : boolean,
    moduleId : string,
    _id: string
}

interface StatusData {
    studentData: StudentData,
    trackingData : ModuleStatusInfo[]
}

interface ObjectData {
    chaptername :string,
    moduleElements : ModuleInterface[]
}
export interface DataApiResponse {
    status : StatusData[],
    obj : ObjectData[]
}


export const getReportData = async (ownerId : string , courseId : string , batchId : string) =>{
    const response = await axios.get<DataApiResponse>(GET_COURSE_DATA,{params:{ownerId:ownerId, id:courseId, batchId}})
    return response.data
}


