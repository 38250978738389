import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { routes } from "./routes"
import { useSnackbar } from "notistack"
import { Redirect } from 'react-router'
import { Bootstrap } from './bootstrap';
import withClearCache from "./ClearCache";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
	return <ClearCacheComponent />;
}

function MainApp() { 
	Bootstrap.init(); // Configure the application on the first page render.
	const { enqueueSnackbar } = useSnackbar()
	return (
		<Router>
			<Switch>
				{routes.map((route) =>
					route.rolesExcluded.length === 0 ? (
						<Route
							key={route.path}
							path={route.path}
							render={(props) => <route.component {...props} />}
						/>
					) : (
						<Route
							key={route.path}
							path={route.path}
							render={(props) => {
								if (
									!(route.rolesExcluded?.includes(
										localStorage.getItem('authUserRole') as string
									))
								) {
									return <route.component {...props} />;
								} else {
									enqueueSnackbar(
										'Route not accessible , redirecting to dashboard',
										{ variant: 'warning' }
									);
									return (
										<Redirect
											to={{
												pathname: '/profile/dashboard',
												state: { from: props.location }
											}}
										/>
									);
								}
							}}
						/>
					)
				)}
			</Switch>
		</Router>
	);
}

export default App;