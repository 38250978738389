import React, {FunctionComponent, useState} from 'react'
import { Avatar, Box, Button, Container, CssBaseline, darken, FormControl, Grid, IconButton, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core'
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import { useSnackbar } from "notistack";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Redirect } from "react-router-dom";

import { fontOptions } from "../../../theme";
import MiniDrawer from "../../common/components/SideDrawer";
import { onApiError } from "../../common/helpers";
import Datagrids, { datagridCellExpand } from "../../dashboard/components/dataGrid";
import { blockTutorProfile, fetchTutorPublicProfiles } from '../api';
import { tutorPublicProfile } from '../interface';
import { Rating } from '@material-ui/lab';
import { Block, Visibility } from '@material-ui/icons';
import BlockTutorModal from '../components/blockTutorModal';

const useStyles = makeStyles({
    typography_1: {
        color: '#4285F4'
    },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
    },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
          '& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
    },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
          fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121',
    },
    label: {
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.small,
    }
});

const ViewTutorPublicProfiles: FunctionComponent = () => {
    const classes = useStyles()
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    const [gridRows, setGridRows] = useState<any[]>([])
	const [gridColumns, setGridColumns] = useState<GridColDef[]>([])

    const [openBlockModal, setOpenBlockModal] = useState(false)
    const [entityId, setEntityId] = useState('')

    const {enqueueSnackbar} = useSnackbar()
    const [redirectTo, setRedirectTo] = useState('')

    if(redirectTo.length > 1) return <Redirect to={redirectTo} />

    const handleFetchTutorPublicProfiles = async () => {
        if(startDate.getTime() > endDate.getTime()) {
            enqueueSnackbar('Start Date should not be after End Date', {variant: 'warning'})
            return;
        }
        await fetchTutorPublicProfiles({
            startDate: new Date(startDate.setHours(0,0,0,0)),
            endDate: new Date(endDate.setHours(23,59,59,0))
        }).then((val) => {
            if(val.length === 0) enqueueSnackbar('No Profiles found', {variant: 'warning'})
            createDataGrid(val)
        })
        .catch((err) => onApiError(err, enqueueSnackbar, setRedirectTo))
    }

    function renderLogo(params: any) {
        return (
          <Avatar src={params.value} />
        )
    }
    
    function renderRating(params: any) {
        return (
          <Rating name="size-small" value={params.value} readOnly size="small" />
        )
    }

    const handleViewProfile = (tutor: tutorPublicProfile) => {
        window.open(`${process.env.REACT_APP_URL}edumaticamarketplace/tutorprofile?publicProfileId=${tutor._id}`, '_blank')
    }

    const handleBlockProfile = (tutor: tutorPublicProfile) => {
        setOpenBlockModal(true)
        setEntityId(tutor.entityId)
    }

    const handleConfirmBlock = async (blockReason: string) => {
        if(blockReason.length < 4) {
            enqueueSnackbar('Block Reason should have minimum 4 characters', {variant: 'warning'})
            return;
        } 
        await blockTutorProfile({entityId, blockReason})
        .then(() => {
            enqueueSnackbar('Blocked Successfully', {variant: 'success'})
            setEntityId('')
            setOpenBlockModal(false)
            handleFetchTutorPublicProfiles()
        })
        .catch((err) => onApiError(err, enqueueSnackbar, setRedirectTo))
    }

    const createDataGrid = (datas: tutorPublicProfile[]) => {

        const rows = datas.map((cur, i) => Object.assign(cur, {
            id: i+1,
            courseDetails: cur.courseDetails.map((course) => {
                return `${course.board} ${course.className} ${course.subject}`
            }).join(', ')
        }))

		setGridRows(rows)

		setGridColumns([
			{ field: 'id', headerName: 'Sl No.', flex: 0.7 },
            { field: 'logo', headerName: 'Profile Pic.', flex: 1, renderCell: renderLogo },
            { field: 'displayName', headerName: 'Name', flex: 1, renderCell: datagridCellExpand },
            { field: 'aboutMe', headerName: 'About Me', flex: 2, renderCell: datagridCellExpand },
            { field: 'qualifications', headerName: 'Qualification', flex: 1, renderCell: datagridCellExpand },
            { field: 'courseDetails', headerName: 'Courses', flex: 1, renderCell: datagridCellExpand },
            { field: 'hourlyRate', headerName: 'Hourly Rate', flex: 1, renderCell: datagridCellExpand },
            { field: 'rating', headerName: 'Rating', flex: 1, renderCell: renderRating },
            // window.open('http://stackoverflow.com', '_blank');
            { field: 'action', headerName: 'Action', flex: 1,
                disableClickEventBubbling: true,
                renderCell: (params: GridCellParams) => {
                    const selectedRow = {
                    id: params.getValue("id") as number,
                    mobileNo: params.getValue("mobileNo") as string,
                    displayName: params.getValue("displayName") as string
                    }

                    const selectedRowDetails = rows.find((row, index) => {
                    return (row.mobileNo === selectedRow.mobileNo && row.displayName === selectedRow.displayName && index === (selectedRow.id - 1))
                    })

                    const buttonData = [
                        {
                          title: 'View Profile',
                          action: handleViewProfile,
                          icon: <Visibility />
                        },
                        {
                          title: 'Block Tutor',
                          action: handleBlockProfile,
                          icon: <Block />
                        }
                    ];

                    const buttonSet = buttonData.map((button, index) => {
                    return (
                        <Tooltip key={index} title={button.title}>
                        <IconButton
                            onClick={() => {
                            button.action(selectedRowDetails as tutorPublicProfile);
                            }}
                            size="small"
                        >
                            {button.icon}
                        </IconButton>
                        </Tooltip>
                    );
                    })
            
                    return <div>{buttonSet}</div>;
                }
            }
		])
	}

    const renderInput = (key: string, value: any, setter: any, maxLength?: number, multiline?: boolean) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={classes.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <TextField
                placeholder={key}
                value={value}
                inputProps={{ maxLength: maxLength ? maxLength : 100 }}
                multiline={multiline}
                onChange={(e) => setter(e.target.value)}
                />
            </FormControl>
            </Grid>
        </Grid>
    )

    const renderDateInput = (key: string, value: any, setter: any) => (
        <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
                <Box className={classes.label}>
                    {key}
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl fullWidth margin="normal">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={key}
                            value={value}
                            onChange={(d) => setter(d)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
            </Grid>
        </Grid>
    )
    
    const renderButton = (name: string, cb: any) => (
        <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item>
                <Button variant="contained" color="primary" size="medium"
                    onClick={cb}
                >
                    {name}
                </Button>
            </Grid>
        </Grid>
    )

    return(
        <>
        {openBlockModal && 
        <BlockTutorModal
            openBlockModal={openBlockModal}
            handleClose={() => setOpenBlockModal(false)}
            handleConfirmBlock={handleConfirmBlock}
            renderButton={renderButton}
            renderInput={renderInput}
        />}
        <CssBaseline />
        <MiniDrawer>
        <Container maxWidth="lg">
            <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="5px"
            color='#fff'
            >
            <Grid item container>
            <Grid item sm={8}>
                <Box style={{height: '100%'}}>
                    <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                    >
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            Tutor Public Profiles
                        </Typography>
                        <Typography>
                            Get Tutor Public Profiles between two Dates 
                        </Typography>
                    </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            </Box>

            <Box
				bgcolor="#ffffff"
				borderRadius="5px"
				padding="12px 25px"
				marginTop='25px'
			>
                <Grid container justify='center'>
                    <Grid item container xs={12} lg={8} style={{paddingTop: '5px', marginBottom: '8px'}}>
                        {renderDateInput('Enter Start Date', startDate, setStartDate)}
                        {renderDateInput('Enter End Date', endDate, setEndDate)}
                        {renderButton('Search', handleFetchTutorPublicProfiles)}
                    </Grid>
                    <Grid item xs={12}>
                        {gridRows.length > 0 && 
                        <>
                        <Datagrids disableCheckbox gridRows={gridRows} gridColumns={gridColumns}/>
                        </>}
                    </Grid>
                </Grid>
            </Box>
        </Container>
        </MiniDrawer>
        </>
    )
}

export default ViewTutorPublicProfiles