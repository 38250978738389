import React, { useState, useEffect, FunctionComponent, FormEvent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
	Box, Container, Grid, Checkbox, Button, TextField, Input, MenuItem, Menu,
	Typography, FormControlLabel, Switch, FormHelperText, OutlinedInput, IconButton, CssBaseline, Card, CardContent, CardHeader
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';

import MiniDrawer from "../../../common/components/SideDrawer"
import { fontOptions } from '../../../common/theme';
import CustomTextField from '../../components/customTextField';
import { editAdminUser, getAdminData, getRoles, getUser, updateUserStatus } from '../../../../api/dashboard';
import { ArrowBack, Visibility, VisibilityOff } from '@material-ui/icons';
import useStyles from './styles';
import { Admin, Role, EditAdmin, CustomUser } from '../../../common/contracts/dashboard';
import { RootState } from '../../../redux/store';
import { connect, useDispatch } from 'react-redux';
import { CUSTOMER_TYPES, BLOCK_ACTION_TYPES, USER_TYPE_VALUES, USER_TYPES, USER_ROLES, MASTER_PERMISSIONS } from '../../../../utilities/constants';
import { setLoginUserData } from '../../../redux/actions/adminActions';

interface Props extends RouteComponentProps {
	user: CustomUser
}

interface FormData {
	customerType: string,
	actionType: string,
	actionReason: string
}

const ValidationSchema = yup.object().shape({
	customerType: yup.string().notOneOf(['Select']).required('Customer Type is required'),
	actionType: yup.string().notOneOf(['Select']).required('Action Type is required'),
	actionReason: yup.string().required('Action reason is required'),

});

const BlockUnblockUser: FunctionComponent<Props> = ({ user, history }) => {
	const { errors, setError, clearError, handleSubmit, register, reset, control, setValue } = useForm<FormData>({
		mode: 'onBlur',
		validationSchema: ValidationSchema
	});

	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const [userDetails, setUserDetails] = useState<any>({}); //crosscheck
	const styles = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		getLoggedInUserData();
	}, []);


	const getLoggedInUserData = async () => {
		// setLoading(true);
		// try {
		// 	const adminUserData = await getAdminData();
		// 	dispatch(setLoginUserData(JSON.stringify(adminUserData)));
		// 	setLoading(false);
		// 	if (adminUserData.isItDefaultPassword) {
		// 		localStorage.setItem('isItDefaultPassword', JSON.stringify(adminUserData.isItDefaultPassword));
		// 		setRedirectTo('/set-password');
		// 	}
		// } catch (error) {
		// 	setLoading(false);
		// 	enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
		// }
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.updateUserStatus);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		} else {
			getUserData();
		}
	}
	const getUserData = async () => {
		setLoading(true);

		console.log(user);

		var userType = USER_TYPE_VALUES.TUTOR;
		if (user.userType) {
			userType = user.userType;
		} else {
			switch (user.name) {
				case USER_TYPES.TUTOR:
					userType = USER_TYPE_VALUES.TUTOR;
					break;
				case USER_TYPES.ORG:
					userType = USER_TYPE_VALUES.ORG;
					break;
				case USER_TYPES.STUDENT:
					userType = USER_TYPE_VALUES.STUDENT;
					break;
				case USER_TYPES.PARENT:
					userType = USER_TYPE_VALUES.PARENT;
					break;
				default:
					userType = USER_TYPE_VALUES.TUTOR;
					break;
			}
		}

		try {
			const data = await getUser(userType, user._id);
			setUserDetails(data);
			setValue('actionType', data.roleStatus)
			setLoading(false);
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	const handleBlockUnblockUser = async (data: FormData) => {
		var { customerType, actionType, actionReason } = data;
		setLoading(true);
		try {
			await updateUserStatus(customerType, user._id, actionType, actionReason);
			setLoading(false);
			enqueueSnackbar('Updated successfully', { variant: 'success' });
			setRedirectTo('/search-customer');
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							<Grid item className={styles.header}>
								<IconButton onClick={() => history.goBack()}>
									<ArrowBack />
								</IconButton>
								<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
							</Grid>
							<Card className={styles.card} variant='outlined'>
								<CardHeader title='Block/Unblock User' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent >

									<form onSubmit={handleSubmit(handleBlockUnblockUser)} >

										<Grid container>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Customer Name</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														value={user.name ? user.name : ''}
														disabled
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Email</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														value={user.emailId ? user.emailId : ''}
														disabled
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Mobile</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														value={user.mobileNo ? user.mobileNo : ''}
														disabled
													/>
												</FormControl>
											</Grid>
										</Grid>


										<Grid container>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Customer Type</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Controller
														as={
															<Select disabled={user.userType ? true : false}>
																<MenuItem value="Select">Select</MenuItem>
																{CUSTOMER_TYPES.map((item) => (
																	<MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>
																))}
															</Select>

														}
														name='customerType'
														inputRef={register}
														defaultValue={user.userType ? user.userType : ''}
														control={control}
													/>
													{errors.customerType && (
														<FormHelperText error>
															{errors.customerType.message}
														</FormHelperText>
													)}
												</FormControl>
											</Grid>
										</Grid>


										<Grid container>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Last Login Date</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														value=''
														disabled
													/>
												</FormControl>
											</Grid>
										</Grid>


										<Grid container>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Action</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Controller
														as={
															<Select>
																<MenuItem value="Select">Select</MenuItem>
																{BLOCK_ACTION_TYPES.map((item) => (
																	<MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>
																))}
															</Select>
														}
														name='actionType'
														inputRef={register}
														control={control}
														defaultValue={userDetails.roleStatus ? userDetails.roleStatus : ''}
													/>
													{errors.actionType && (
														<FormHelperText error>
															{errors.actionType.message}
														</FormHelperText>
													)}
												</FormControl>
											</Grid>
										</Grid>


										<Grid container>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Action Reason</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														name='actionReason'
														inputRef={register}
														inputProps={{ maxLength: 100 }}
														multiline
													/>
												</FormControl>
												{errors.actionReason && (
													<FormHelperText error>
														{errors.actionReason.message}
													</FormHelperText>
												)}
											</Grid>
										</Grid>

										<Grid container justify='center' className={styles.submitBtn}>
											<Grid item xs={12} md={6} >
												<Button
													disableElevation
													variant="contained"
													color="primary"
													size="large"
													type="submit"
													disabled={loading}
												>
													Save
													</Button>
											</Grid>
										</Grid>

									</form>
								</CardContent>
							</Card>

						</Grid>
					</Container>
				</div >
			</MiniDrawer >
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	user: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(BlockUnblockUser);