import React, { useState, useEffect, FunctionComponent, } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
	Box, Container, Grid, Button, Input, MenuItem, Typography, CssBaseline, Card, CardContent, CardHeader,
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"

import MiniDrawer from "../../../common/components/SideDrawer"
import useStyles from '../manageUsers/styles';
import { Package } from '../../../common/contracts/dashboard';
import { MASTER_PERMISSIONS, USER_ROLES, } from '../../../../utilities/constants';
import Datepickers from '../../../dashboard/components/datepickers';
import { getEdumaticaPackages } from '../../../../api/dashboard';

interface Props extends RouteComponentProps {

}

const PreviousPackages: FunctionComponent<Props> = ({ history }) => {

	const { enqueueSnackbar } = useSnackbar()
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [packagesList, setPackagesList] = useState<Package[]>([]);	//crosscheck
	const [fromDate, setFromDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [selectedpackage, setSelectedPackage] = useState<Package>();
	const styles = useStyles();

	useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.packageapproval);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	const handleFetchPackages = async () => {
		var obj: any = {
			avtivePackagesOnly: false
		}
		if (fromDate && endDate) {
			obj['fromDate'] = fromDate;
			obj['toDate'] = endDate;
		}
		try {
			const data = await getEdumaticaPackages(obj);
			var list = data.data;
			setPackagesList(list);
			enqueueSnackbar(list.length + ' record(s) fetched', { variant: 'success' });
		} catch (error) {
			// setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const handlePackage = (event: React.ChangeEvent<{ value: unknown }>) => {
		let etv = event.target.value;
		packagesList.forEach((item) => {
			if (item.name == etv) {
				setSelectedPackage(item);
				return;
			}
		});
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Search Customers</Typography>
							</Grid> */}

							<Card className={styles.card} variant='outlined' style={{ width: '100%' }}>
								<CardHeader title='Historical Packages' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>

									<Grid container>
										<Grid item xs={12} md={3}>
											<FormControl fullWidth margin="normal">
												<Box >From Date</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={3}>
											<FormControl fullWidth margin="normal">
												<Datepickers selectedDate={fromDate} handleDateChange={(date) => setFromDate(date)}
												/>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={3}>
											<FormControl fullWidth margin="normal">
												<Box >End Date</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={3}>
											<FormControl fullWidth margin="normal">
												<Datepickers selectedDate={endDate} handleDateChange={(date) => setEndDate(date)}
												/>
											</FormControl>
										</Grid>
									</Grid>

									<Grid container  >
										<Grid item xs={12} md={3}  >
											<FormControl fullWidth margin="normal">
												<Box >Select Package name</Box>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={3} >
											<FormControl fullWidth margin="normal">
												<Select
													onChange={handlePackage}
													defaultValue='Select'
												>
													<MenuItem value="Select">Select</MenuItem>
													{packagesList.length > 0 && packagesList.map((item, index) => (
														<MenuItem value={item.name} key={index}	>
															{item.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={6}>
											<Button color='primary' variant='contained'
												onClick={handleFetchPackages}>Fetch Packages</Button>
										</Grid>
									</Grid>


								</CardContent>
							</Card>

						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={12} md={3}>
								<Grid item style={{ height: '60px' }}>
									<Typography>Package</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Total students allowed</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Total No. of batches allowed</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Live Class hrs/day</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Recording hrs/lecture</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Plan Type</Typography>
								</Grid>

								<Grid item style={{ height: '60px' }}>
									<Typography>Payment Cycle</Typography>
								</Grid>

								<Grid item style={{ height: '60px' }}>
									<Typography>Per student per sub per month fee</Typography>
								</Grid>

								<Grid item style={{ height: '60px' }}>
									<Typography>Commision% per course fee</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Min fee from tutor</Typography>
								</Grid>
								{/* <Grid item style={{ height: '60px' }}>
									<Typography>Min quarterly fee from tutor</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Min half yearly fee from tutor</Typography>
								</Grid>
								<Grid item style={{ height: '60px' }}>
									<Typography>Min annual fee from tutor</Typography>
								</Grid> */}
								<Grid item style={{ height: '60px' }}>
									<Typography>Grace Period</Typography>
								</Grid>

								<Grid item style={{ height: '60px' }}>
									<Typography>Max cumulative content space allowed</Typography>
								</Grid>

							</Grid>

							<>
								{
									selectedpackage &&
									<Grid item xs={12} md={3}>

										<Grid item style={{ height: '60px' }}>
											<Typography>{selectedpackage.planId}</Typography>
										</Grid>

										<Grid item style={{ height: '60px' }}>
											<Input
												name="studentCount"
												value={selectedpackage.studentCount}
												disabled={true}
											/>
										</Grid>
										<Grid item style={{ height: '60px' }}>
											<Input
												name="batchCount"
												value={selectedpackage.studentCount}
												disabled={true}
											/>
										</Grid>
										<Grid item style={{ height: '60px' }}>
											<Input
												name="liveClassQuota"
												value={selectedpackage.studentCount}
												disabled={true}
											/>
										</Grid>
										<Grid item style={{ height: '60px' }}>
											<Input
												name="recordingQuota"
												value={selectedpackage.recordingQuota}
												disabled={true}
											/>
										</Grid>


										<Grid item style={{ height: '60px' }}>
											<Input
												name="paymentPlan"
												value={selectedpackage.paymentPlans && selectedpackage.paymentPlans.length ? selectedpackage.paymentPlans[0].paymentplan : ''}
												disabled={true}
											/>
										</Grid>
										<Grid item style={{ height: '60px' }}>
											<Input
												name="paymentCycle"
												value={selectedpackage.paymentPlans && selectedpackage.paymentPlans.length ? selectedpackage.paymentPlans[0].paymentcycle : ''}
												disabled={true}
											/>
										</Grid>

										<Grid item style={{ height: '60px' }}>
											<Input
												name="perstudentcost"
												value={selectedpackage.perstudentcost}
												disabled={true}
											/>
										</Grid>
										<Grid item style={{ height: '60px' }}>
											<Input
												name="convenienceFee"
												value={selectedpackage.convenienceFee}
												disabled={true}
											/>
										</Grid>
										<Grid item style={{ height: '60px' }}>
											<Input
												value={selectedpackage.paymentPlans && selectedpackage.paymentPlans.length ? selectedpackage.paymentPlans[0].totalprice : ''}
												disabled={true}
											/>
										</Grid>
										<Grid item style={{ height: '60px' }}>
											<Input
												name="graceperiod"
												value={selectedpackage.graceperiod}
												disabled={true}
											/>
										</Grid>
										<Grid item style={{ height: '60px' }}>
											<Input
												name="contentSize"
												value={selectedpackage.contentSize}
												disabled={true}
											/>
										</Grid>
									</Grid>
								}
							</>

						</Grid>
					</Container>
				</div>
			</MiniDrawer >
		</>
	);
}

export default PreviousPackages;
