import axios from 'axios';
import { GET_PAYMENT_ORDER } from './routes';

interface GetPaymentOrderReq {
    orderId?: string, 
    mobileNo?: string, 
    emailId?: string, 
    userName?: string,
	fromDate?: Date,
	toDate?: Date
}

export const getPaymentOrder = async (data: GetPaymentOrderReq) => {
	const response = await axios.get<any>(GET_PAYMENT_ORDER, { params: data }); //<Package[]>
	return response.data;
}