import React, { FunctionComponent } from 'react';
import { Grid, Box, Typography, Tooltip, makeStyles, Theme, lighten,Button, createStyles } from '@material-ui/core';

import { FileWithPath } from 'react-dropzone';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { truncate } from 'lodash';

interface Props {
  files: File[];
  onRemoveItem: (file: File, fileIndex: number) => any;
  setDroppedFiles?:  React.Dispatch<React.SetStateAction<FileWithPath[]>>
  isContent?:boolean
}

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    gridStyle : {
      color: lighten(theme.palette.primary.main,0.5),
      height :'100%'
    },
    gridText : {
      color: theme.palette.primary.main,
      textAlign:'center'
    }
  })
);


const GridPreview: FunctionComponent<Props> = ({files = [], onRemoveItem, setDroppedFiles, isContent}) => {
  const classes = useStyles()
  const reorder = (list:any, startIndex:any, endIndex:any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (draggableStyle:any) => ({
    userSelect: 'none',
    ...draggableStyle,
  });

  const getListStyle = () => ({
    display: 'flex',
    overflow: 'auto',
  });

  const onDragEnd = (result:DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      files,
      result.source.index,
      result.destination.index
    ) as File[];
        setDroppedFiles && setDroppedFiles(items)

  }

  if(files.length < 1) {
    return null
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <Grid container spacing={3}
              ref={provided.innerRef}
              style={getListStyle()}
              {...provided.droppableProps}
            >
              {files.map((file, index) => (
                <Grid key={String(index)} item xs={12} sm={isContent?12:6} md={isContent?6:3}>
                  <Draggable draggableId={String(index)} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          provided.draggableProps.style
                        )}
                      >
                        <Box
                          bgcolor="#E0ECFF"
                          borderRadius="5px"
                          display="flex"
                          padding="15px"
                          marginBottom="20px"
                          height="200px"                        
                        >
                          <Grid container direction="row" alignItems="center" justify="center" className= {classes.gridStyle}>
                            <Grid item xs={12} className={classes.gridText}>
                              <Tooltip title={file.name} disableHoverListener={file.name.length <= 20}>
                                <Typography>{truncate(file.name, {length: 20})}</Typography>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={12} style={{position: 'relative', bottom: '1px', textAlign: 'center'}}>
                              <Button style={{color: 'crimson'}} onClick={() => {onRemoveItem(file, index)}}>
                                Remove
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    )}
                  </Draggable>
                </Grid>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>


      {/* <Grid container spacing={3}>
        {files.map((file, index) => {
          return (
            <Grid key={String(index)} item xs={12} sm={6} md={3}>
              <Box
                bgcolor="#E0ECFF"
                borderRadius="5px"
                display="flex"
                padding="15px"
                marginBottom="20px"
                height="200px"
              >
                <Grid container direction="row" alignItems="center" justify="center" style={{ height: '100%' }}>
                  <Grid item xs={12} style={{color: '#4C8BF5', textAlign: 'center'}}>
                    <Typography>{file.name}</Typography>
                  </Grid>
                  <Grid item xs={12} style={{position: 'relative', bottom: '1px', color: 'crimson', textAlign: 'center'}}>
                    <Typography style={{cursor: 'pointer'}}>Remove</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )
        })}
      </Grid> */}
    </div>
  );
}

export default GridPreview