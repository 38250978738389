import React, { useState, useEffect, FunctionComponent } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Button, Container, CssBaseline, Switch, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { DropzoneArea } from 'material-ui-dropzone';
import { RouteComponentProps } from 'react-router-dom';
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../utilities/constants';
import MiniDrawer from "../../common/components/SideDrawer"
import { saveMsgTemplate, savePushTemplate, switchMsgTemplate, switchPushTemplate } from '../api';

interface Props extends RouteComponentProps {

}

const MasterNotificationTemplates: FunctionComponent<Props> = ({ location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [redirectTo, setRedirectTo] = useState('');
  const [type, setType] = useState('');
  const [template, setTemplate] = useState('');
  const [icon, setIcon] = useState('');

  const [typePush, setTypePush] = useState('');
  const [templatePush, setTemplatePush] = useState('');
  const [iconPush, setIconPush] = useState('');

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  // console.log(state)

  useEffect(() => {
    getLoggedInUserData()
  }, []);

  const getLoggedInUserData = async () => {
    if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
        setRedirectTo('/set-password')
    }
    var allowedPermissions: string[] = [];
    var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
    var loginUserType = '';
    if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
        allowedPermissions = loginUserData.adminRole.permissions;
        loginUserType = loginUserData.adminRole.name;
    }
    const requiredPermissions = [MASTER_PERMISSIONS.msgtemplate]
    var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
    if (!hasEligibility) {
        enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
        setRedirectTo('/dashboard');
    }
}

  if (redirectTo.length > 0) {
    return <Redirect to={redirectTo} />;
  }

  // const SnackbarAction = (key: number) => {
  //   return (
  //     <React.Fragment>
  //       <Button
  //         onClick={() => {
  //           closeSnackbar(key);
  //         }}
  //       >
  //         Confirm
  //       </Button>
  //       <Button
  //         onClick={() => {
  //           closeSnackbar(key);
  //         }}
  //       >
  //         Cancel
  //       </Button>
  //     </React.Fragment>
  //   );
  // };

  const imagesetter = (fileimage: File) => {
    if (!fileimage) return
    const fsize = fileimage.size;
      const file = Math.round(fsize / 1024);
      if (file >= 100) {
        enqueueSnackbar('File too Big, please select a file less than 100kb', {
          variant: 'error'
        });
        return;
      }
      const img = new Image();
      img.onload = async () => {
        // Create canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        // Set width and height
        canvas.width = img.width;
        canvas.height = img.height;
        // Draw the image
        if (ctx instanceof CanvasRenderingContext2D) {
          ctx.drawImage(img, 0, 0);
        }
        const image = canvas.toDataURL('image/jpeg', 0.1)
        setIcon(image)
      };
      img.src = URL.createObjectURL(fileimage);
  }

  const saveTemplate = () => {
    // console.log("working")
    // console.log('',type,'\n',template,'\n',icon)
    if(type === '' || template === '') {
      enqueueSnackbar('Invalid Input', {
        variant: 'error'
      });
      return
    }
    saveMsgTemplate(type, template, icon).then(res => {
      if(res.data.code === 0) {
        enqueueSnackbar('Template saved successfully', {
          variant: 'success'
        });
      }
    }).catch(err => {
      enqueueSnackbar('Template save unsuccessful', {
        variant: 'error'
      });
      console.log(err)
    })
    setType('')
    setTemplate('')
    setIcon('')
  }

  const imagesetterPush = (fileimage: File) => {
    if (!fileimage) return
    const fsize = fileimage.size;
      const file = Math.round(fsize / 1024);
      if (file >= 100) {
        enqueueSnackbar('File too Big, please select a file less than 100kb', {
          variant: 'error'
        });
        return;
      }
      const img = new Image();
      img.onload = async () => {
        // Create canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        // Set width and height
        canvas.width = img.width;
        canvas.height = img.height;
        // Draw the image
        if (ctx instanceof CanvasRenderingContext2D) {
          ctx.drawImage(img, 0, 0);
        }
        const image = canvas.toDataURL('image/jpeg', 0.1)
        setIconPush(image)
      };
      img.src = URL.createObjectURL(fileimage);
  }

  const saveTemplatePush = () => {
    // console.log("working")
    // console.log('',type,'\n',template,'\n',icon)
    if(typePush === '' || templatePush === '') {
      enqueueSnackbar('Invalid Input', {
        variant: 'error'
      });
      return
    }
    savePushTemplate(typePush, templatePush, iconPush).then(res => {
      if(res.data.code === 0) {
        enqueueSnackbar('Template saved successfully', {
          variant: 'success'
        });
      }
    }).catch(err => {
      enqueueSnackbar('Template save unsuccessful', {
        variant: 'error'
      });
      console.log(err)
    })
    setTypePush('')
    setTemplatePush('')
    setIconPush('')
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const switchTemplate = () => {
    switchMsgTemplate(type, state.checkedA).then(response => {
      if(response.data.message === "Success") {
        enqueueSnackbar('Msg Template switched', {
          variant: 'success'
        });
      } else {
        enqueueSnackbar('Unsuccessful try again', {
          variant: 'error'
        });
      }
    })
  }

  const switchTemplatePush = () => {
    switchPushTemplate(typePush, state.checkedB).then(response => {
      if(response.data.message === "Success") {
        enqueueSnackbar('Push Template switched', {
          variant: 'success'
        });
      } else {
        enqueueSnackbar('Unsuccessful try again', {
          variant: 'error'
        });
      }
    })
  }

  return (
    <div>
    <CssBaseline />
    <MiniDrawer>
      <Container style={{margin:"18px auto", padding:"12px 120px"}}>
        <Box width="100%" height="50vH" bgcolor="#fff" margin="auto" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <h1>Upload Messages Templates</h1>
          <form noValidate autoComplete="off">
            <Box display="flex" justifyContent="space-between">
              <Box marginRight="12px" display="flex" flexDirection="column" width="100%">
                <TextField value={type} onChange={(e) => setType(e.target.value)} label="Type" variant="outlined" />
                <TextField value={template} multiline
                  rows={9} style={{marginTop:"8px"}} onChange={(e) => setTemplate(e.target.value)} label="Template" variant="outlined" />
              </Box>
              <DropzoneArea
                  maxFileSize={100000} // 10 MB
                  filesLimit={1}
                  dropzoneParagraphClass="Please upload Image Folder - ZIP Only"
                  // onChange={(e:React.ChangeEvent<HTMLInputElement>) => imagesetter(e)}
                  onChange={(files: File[]) => {
                    imagesetter(files[0]);
                  }}
                />
            </Box>
            <Box width="100%" display="flex" justifyContent="space-evenly" alignItems="center">
            <Button onClick={() => saveTemplate()} variant="outlined" color="primary" style={{margin:"8px auto",}}>Submit</Button>
            <Switch
              checked={state.checkedA}
              onChange={handleChange}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            {
              (!type || state.checkedA===true) ? '' : <Button onClick={() => switchTemplate()} variant="outlined" color="secondary" style={{margin:"8px auto",}}>Turn off</Button>
            }
            {
              (!type || state.checkedA===false) ? '' : <Button onClick={() => switchTemplate()} variant="outlined" color="primary" style={{margin:"8px auto",}}>Turn on</Button>
            }
            </Box>
          </form>
        </Box>

        <Box width="100%" height="50vH" bgcolor="#fff" margin="auto" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <h1>Upload Push Notification Templates</h1>
          <form noValidate autoComplete="off">
            <Box display="flex" justifyContent="space-between">
              <Box marginRight="12px" display="flex" flexDirection="column" width="100%">
                <TextField value={typePush} onChange={(e) => setTypePush(e.target.value)} label="Type" variant="outlined" />
                <TextField value={templatePush} multiline
                  rows={9} style={{marginTop:"8px"}} onChange={(e) => setTemplatePush(e.target.value)} label="Template" variant="outlined" />
              </Box>
              <DropzoneArea
                  maxFileSize={100000} // 10 MB
                  filesLimit={1}
                  dropzoneParagraphClass="Please upload Image Folder - ZIP Only"
                  // onChange={(e:React.ChangeEvent<HTMLInputElement>) => imagesetter(e)}
                  onChange={(files: File[]) => {
                    imagesetterPush(files[0]);
                  }}
                />
            </Box>
            <Box width="100%" display="flex" justifyContent="space-evenly" alignItems="center">
            <Button onClick={() => saveTemplatePush()} variant="outlined" color="primary" style={{margin:"8px auto",}}>Submit</Button>
            <Switch
              checked={state.checkedB}
              onChange={handleChange}
              name="checkedB"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            {
              (!typePush || state.checkedB===true) ? '' : <Button onClick={() => switchTemplatePush()} variant="outlined" color="secondary" style={{margin:"8px auto",}}>Turn off</Button>
            }
            {
              (!typePush || state.checkedB===false) ? '' : <Button onClick={() => switchTemplatePush()} variant="outlined" color="primary" style={{margin:"8px auto",}}>Turn on</Button>
            }
            </Box>        
          </form>
        </Box>
      </Container>
      </MiniDrawer>
    </div>
  )
}

export default MasterNotificationTemplates;
