import React, { FunctionComponent, useState, useEffect } from 'react';
import {
	DataGrid,
	GridCellParams,
	GridColumns,
	GridToolbar,
} from '@material-ui/data-grid';
import { Box, Container, makeStyles } from '@material-ui/core';
import * as dateFns from 'date-fns'
import Datagrids, { datagridCellExpand } from '../../dashboard/components/dataGrid';

interface RowData {
	orderId: string,
	receipt: string,
	userName: string,
	mobileNo: string,
	emailId: string,
	notes: string,
	currency: string,
	amount_paid: number,
	gatewayServiceCharge: number,
	serviceChargeTax: number,
	settlementAmount: number,
	paymentMode: string,
	bankName: string,
	cardScheme: string,
	cardNo: string,
	cardCountry: string,
	txTime: string,
	txStatus: string,
	settlementStatus: string,
	settledOn: string,
	settlementUTR: string,
	isRefunded: boolean
}

interface Props {
	gridData: RowData[];
}

const useStyles = makeStyles({
	root: {
		'& .super-app-theme--cell': {
			backgroundColor: 'rgba(224, 183, 60, 0.55)',
			color: '#1a3e72',
			fontWeight: '600'
		},
		'& .super-app.negative': {
			backgroundColor: '#d47483',
			color: '#1a3e72',
			fontWeight: '600'
		},
		'& .super-app.positive': {
			backgroundColor: 'rgba(157, 255, 118, 0.49)',
			color: '#1a3e72',
			fontWeight: '600'
		}
	},
	paginate: {
		display: "flex"
	}
});

export const MprDataTable: FunctionComponent<Props> = ({
	gridData
}) => {
	const classes = useStyles();

	const columns: GridColumns = [
		{ field: 'id', headerName: 'S.No', width: 100 },
		{ field: 'orderId', headerName: 'Order ID', type: 'string', width: 200, renderCell: datagridCellExpand },
		{ field: 'receipt', headerName: 'Reference ID', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'userName', headerName: 'Customer Name', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'mobileNo', headerName: 'Mobile No.', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'emailId', headerName: 'Email ID', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'notes', headerName: 'Order Notes', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'currency', headerName: 'Currency', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'amount_paid', headerName: 'Amount', width: 150, renderCell: datagridCellExpand },
		{ field: 'gatewayServiceCharge', headerName: 'Service Charge', width: 150, renderCell: datagridCellExpand },
		{ field: 'serviceChargeTax', headerName: 'ST/GST', width: 150, renderCell: datagridCellExpand },
		{ field: 'settlementAmount', headerName: 'Settlement Amount', width: 150, renderCell: datagridCellExpand },
		{ field: 'paymentMode', headerName: 'Payment Mode', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'bankName', headerName: 'Bank Name', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'cardScheme', headerName: 'Card Scheme', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'cardNo', headerName: 'Card No', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'cardCountry', headerName: 'Card Country', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'txTime', headerName: 'Tx Time', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'txStatus', headerName: 'Tx Status', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'settlementStatus', headerName: 'Settlement Status', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'settledOn', headerName: 'Settled On', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'settlementUTR', headerName: 'Settlement UTR', type: 'string', width: 150, renderCell: datagridCellExpand },
		{ field: 'isRefunded', headerName: 'Refunded', type: 'string', width: 150, renderCell: datagridCellExpand },
	];

	const [rows, setRows] = useState<RowData[]>([])

	useEffect(() => {
		setRows(() => {
			return gridData?.map((data, index) => {
				return {
					id: index + 1,
					orderId: data.orderId,
					receipt: data.receipt,
					userName: data.userName,
					mobileNo: data.mobileNo,
					emailId: data.emailId,
					notes: data.notes,
					currency: data.currency,
					amount_paid: data.amount_paid,
					gatewayServiceCharge: data.gatewayServiceCharge,
					serviceChargeTax: data.serviceChargeTax,
					settlementAmount: data.settlementAmount,
					paymentMode: data.paymentMode,
					bankName: data.bankName,
					cardScheme: data.cardScheme,
					cardNo: data.cardNo,
					cardCountry: data.cardCountry,
					txTime: data.txTime.toString(),
					txStatus: data.txStatus,
					settlementStatus: data.settlementStatus,
					settledOn: data.settledOn.toString(),
					settlementUTR: data.settlementUTR,
					isRefunded: data.isRefunded
				} as RowData
			}) as RowData[]
		})
	}, [gridData])

	return (
		<Box
			className={classes.root}
			style={{
				width: '100%',
				height: '700px',
				marginTop: '10px',
				display: 'flex'
			}}
		>
			<Datagrids gridRows={rows} gridColumns={columns} showToolBar={true} />
		</Box>
	);
};
