import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DropzoneArea, DropzoneAreaProps, AlertType } from 'material-ui-dropzone';
//import { Box, Typography } from '@material-ui/core';
// import FileChipPreview from './previewers/file_chip_preview';
import { Typography } from '@material-ui/core';
import { fontOptions } from '../../../theme';

const useStyles = makeStyles(() =>
  createStyles({
    dropzoneRoot: {
      borderRadius: '500px',
      width: '100%',
      minHeight: 'auto',
      outline: 'none',
    },

    dropzoneTextContainerRoot: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 6px 0 15px',
    },

    dropzoneText: {
      fontFamily:fontOptions.family,fontSize: fontOptions.size.small,
      fontWeight: fontOptions.weight.bold,
      color: "#666666",
      margin: '20px 0',
    },

    dropzoneIcon: {
      background:
        'linear-gradient(90deg, rgb(6, 88, 224) 2.53%, rgb(66, 133, 244) 100%)',
      borderRadius: '100%',
      color: '#FFF',
      padding: '10px',
    },

    contentHeading: {
      margin: '13px 0px',
      color: '#666666',
      fontFamily:fontOptions.family,fontSize: fontOptions.size.small
    },

    secondHead: {
      color: '#666666',
      fontFamily:fontOptions.family,fontSize: fontOptions.size.small,
      margin: '0px 5px'
    },
    yMargin: {
      margin: '0px 5px'
    }
  })
);

interface Props extends DropzoneAreaProps {
  files?: File[];
  contenttype?: string;
  multipleFiles?: boolean;
  xtraClasses?: any;
  dropzoneText?: string;
  dropzoneSecondaryText?: string;
  showresp?: boolean | AlertType[];
}

const Dropzone: FunctionComponent<Props> = ({
  files = [],
  contenttype,
  multipleFiles,
  xtraClasses,
  dropzoneText,
  dropzoneSecondaryText,
  showresp,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div>
      <DropzoneArea
        {...props}
        showPreviews={false}
        showAlerts={showresp ? showresp : props.showAlerts}
        showPreviewsInDropzone={false}   
        filesLimit={multipleFiles ? 100 : 1}
        dropzoneText={dropzoneText ? dropzoneText : 'Drag and drop a file here or click'}
        classes={xtraClasses ? xtraClasses : {
          root: classes.dropzoneRoot,
          textContainer: classes.dropzoneTextContainerRoot,
          text: classes.dropzoneText,
          icon: classes.dropzoneIcon,
        }}
      />
    </div>
  );
};

export default Dropzone;
