import React, { FunctionComponent, useState, useEffect } from "react";
import {
    Box,
	Container,
	CssBaseline,
	Grid,
	IconButton,
	makeStyles,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import MiniDrawer from "../../../common/components/SideDrawer";
import { fontOptions } from "../../../../theme";
import { RouteComponentProps, withRouter } from "react-router";
import { getSaleDetails } from "../../../../api/dashboard";
import { FetchPublicSaleDetail } from "../../interfaces";

const useStyles = makeStyles({
	title: {
		fontSize: fontOptions.size.large
	},
});

interface Props extends RouteComponentProps {
   
}

const CMPStudent: FunctionComponent<Props> = ({location, history}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar()

    const [fetchdata, setfetchdata] = useState<FetchPublicSaleDetail>()

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const courseId = params.get('courseId');
        const batchId = params.get('batchId');
        const studentId = params.get('studentId');
        if(courseId && batchId && studentId) {
            fetchStudentDetail(courseId, batchId, studentId)
        }
    }, []);

    const fetchStudentDetail = async (courseId: string, batchId: string, studentId: string) => {
        const saleDet = await getSaleDetails(courseId, batchId, studentId)
        setfetchdata(saleDet[0])
    }

    return(
        <div>
            <CssBaseline />
            <MiniDrawer>
                <Container maxWidth="lg">
                    <Box
                        bgcolor="#4C8BF5"
                        padding="20px 30px"
                        marginBottom="30px"
                        position="relative"
                        borderRadius="5px"
                        color='#fff'
                    >
                        <Grid item container>
                            <Grid item sm={8}>
                                <Box style={{height: '100%'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography className={classes.title}>
                                                Course Marketplace Student Detail
                                            </Typography>
                                            <Typography>
                                                View Student Detail
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    {fetchdata &&
                        <Box
                            bgcolor="#F1F6FE"
                            borderRadius="14px"
                            padding="25px"
                            marginTop='25px'
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>Student</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{fetchdata.studentId}</Typography>
                                </Grid>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>Email ID</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{fetchdata.emailId}</Typography>
                                </Grid>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>Mobile Number</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{fetchdata.mobileNo}</Typography>
                                </Grid>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>Course Enrolled On</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{new Date(fetchdata.createdon).toLocaleString('en-GB')}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    }

                    {(fetchdata && fetchdata.orderId) &&
                        <Box
                            bgcolor="#F1F6FE"
                            borderRadius="14px"
                            padding="25px"
                            marginTop='25px'
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>Currency</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{fetchdata.currency}</Typography>
                                </Grid>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>User Native Currency</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{fetchdata.userNativeCurrency}</Typography>
                                </Grid>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>Payment Mode</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{fetchdata.paymentMode}</Typography>
                                </Grid>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>Receipt</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{fetchdata.receipt}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    }

                    {(fetchdata && fetchdata.orderId) &&
                        <Box
                            bgcolor="#F1F6FE"
                            borderRadius="14px"
                            padding="25px"
                            marginTop='25px'
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>Amount</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{fetchdata.amount}</Typography>
                                </Grid>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>Amount Paid</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{fetchdata.amount_paid}</Typography>
                                </Grid>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>Course Charge</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{fetchdata.courseCharge}</Typography>
                                </Grid>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>MarkUp Sur Charge</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{fetchdata.markUpSurCharge}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    }

                    {(fetchdata && !fetchdata.orderId) &&
                        <Box
                            marginTop='25px'
                        >
                            <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>There are No Payment Details, since Payable Course Fee was Zero</Typography>
                        </Box>
                    }


                    {(fetchdata && !fetchdata.discount) &&
                        <Box
                            marginTop='25px'
                        >
                            <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>Referral Code not Applied</Typography>
                        </Box>
                    }

                    {(fetchdata && fetchdata.discount) &&
                        <Box
                            bgcolor="#F1F6FE"
                            borderRadius="14px"
                            padding="25px"
                            marginTop='25px'
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>Referral Code</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{fetchdata.code}</Typography>
                                </Grid>
                                <Grid item xs={12} md>
                                <Typography style={{color: '#3D3D3D', fontWeight: fontOptions.weight.bold}}>Discount</Typography>
                                <Typography style={{color: '#4C8BF5', marginTop: '10px'}}>{fetchdata.discount}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </Container>
            </MiniDrawer>
        </div>
    )
}

export default withRouter(CMPStudent);