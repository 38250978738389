export const menuOptions = [
    'Search Transaction',
    'Upload MPR, GST/TDS, UTR ref',
	'Download Batch Transfer, Tx Report',
];

export const searchTxRadioOptions = [
	'By Order ID',
	'By Mobile No',
	'By Email ID',
	'By Name'
]

export const uploadOptions = [
	"MPR",
	"GST/TDS",
	"UTR ref"
]

export const downloadOptions = [
	"Batch Transfer",
	"GST/TDS",
]