import React, { FunctionComponent, useState, useEffect } from 'react'
import { Box, FormControl, Select, Grid, MenuItem, CssBaseline, Card, Typography, CardContent, Tooltip, IconButton } from '@material-ui/core'
import { GridCellParams, GridColDef } from '@material-ui/data-grid'
import { Delete, Edit } from '@material-ui/icons'
import { useSnackbar } from "notistack"
import { Redirect } from 'react-router'

import MiniDrawer from "../../common/components/SideDrawer"
import Datagrids, { datagridCellExpand } from '../../dashboard/components/dataGrid'
import useStyles from '../../dashboard/containers/customerManagement/styles';
import Datepickers from '../../dashboard/components/datepickers';
import Dropzone from '../../common/components/dropzone/dropzone';
import { createJob, deleteJob, getJobId, getJobs } from '../api';
import { compareAsc } from 'date-fns';
import { jobPostingRes, jobPostingRow } from '../interfaces'
import EditJobPostingModal from '../components/editJobPostingModal'
import ConfirmationModal from '../../common/components/confirmation_modal'
import { convertDate, onApiError } from '../../common/helpers'
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../utilities/constants'

const EditJobPosting: FunctionComponent = () => {
	const styles = useStyles()

	const [showDataGrid, setShowDataGrid] = useState(false)
	const [gridRows, setGridRows] = useState<jobPostingRow[]>([])
	const [gridColumns, setGridColumns] = useState<GridColDef[]>([])

	const [selectedJob, setSelectedJob] = useState<jobPostingRow>()
	const [selectedJobId, setSelectedJobId] = useState('')

	const [openModal, setOpenModal] = useState(false)
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
	const [redirectTo, setRedirectTo] = useState<string>('')
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		const requiredPermissions = [MASTER_PERMISSIONS.getJobsForBO]
		var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		} else {
			handleGetJobs()
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const handleGetJobs = async () => {
		// const jobs = await getJobs()
		// createDataGrid(jobs)
		// setShowDataGrid(true)
		await getJobs().then(val => {
			createDataGrid(val)
			setShowDataGrid(true)
		}).catch(err => onApiError(err, enqueueSnackbar, setRedirectTo))
	}

	const handleDeleteJob = async () => {
		await deleteJob(selectedJobId)
			.then(() => {
				onCloseConfModal()
			})
			.catch(err => onApiError(err, enqueueSnackbar, setRedirectTo))
	}

	const onCloseModal = () => {
		setOpenModal(false)
		setSelectedJob(undefined)
		handleGetJobs()
	}

	const onCloseConfModal = () => {
		setOpenConfirmationModal(false)
		setSelectedJobId('')
		handleGetJobs()
	}

	const createDataGrid = (jobs: jobPostingRes[]) => {
		const rows = jobs.map((job, i) => {
			return ({
				id: i + 1,
				_id: job._id,
				brief: job.brief,
				department: job.department,
				startDate: convertDate(new Date(job.startDate)),
				endDate: convertDate(new Date(job.endDate)),
				fullJD: job.fullJD,
				jobId: job.jobId,
				name: job.name,
				status: job.status
			})
		})
		setGridRows(rows)

		setGridColumns([
			{ field: 'id', headerName: 'S.No', flex: 0.25 },
			{ field: 'name', headerName: 'Title', flex: 1.1, renderCell: datagridCellExpand },
			{ field: 'department', headerName: 'Department', flex: 0.8, renderCell: datagridCellExpand },
			{ field: 'endDate', headerName: 'End Date', flex: 0.8, renderCell: datagridCellExpand },
			{ field: 'status', headerName: 'Status', flex: 0.8, renderCell: datagridCellExpand },
			{
				field: 'action', headerName: 'Action', flex: 0.8,
				disableClickEventBubbling: true,
				renderCell: (params: GridCellParams) => {
					const selectedRow = {
						id: params.getValue("id") as number,
						name: params.getValue("name") as string
					}

					const selectedRowDetails = rows.find((row, index) => {
						return (row.name === selectedRow.name && index === (selectedRow.id - 1))
					})

					const editJob = (row: jobPostingRow) => {
						setSelectedJob(row)
						setOpenModal(true)
					}

					const handleDeleteJob = (row: jobPostingRow) => {
						setSelectedJobId(row._id)
						setOpenConfirmationModal(true)
					}

					const buttonData = [
						{
							title: 'Edit job',
							action: editJob,
							icon: <Edit />
						},
						{
							title: 'Delete job',
							action: handleDeleteJob,
							icon: <Delete />
						}
					];

					const buttonSet = buttonData.map((button, index) => {
						return (
							<Tooltip key={index} title={button.title}>
								<IconButton
									onClick={() => {
										button.action(selectedRowDetails as jobPostingRow);
									}}
									size="small"
								>
									{button.icon}
								</IconButton>
							</Tooltip>
						);
					})

					return <div>{buttonSet}</div>;
				}
			}
		])
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				{selectedJob && <EditJobPostingModal
					openModal={openModal}
					onClose={onCloseModal}
					job={selectedJob}
				/>}
				{openConfirmationModal && <ConfirmationModal
					header="Delete Job Posting"
					helperText="Are you sure you want to delete?"
					openModal={openConfirmationModal}
					onClose={onCloseConfModal}
					handleDelete={handleDeleteJob}
				/>}
				<Grid container>
					<Grid item className={styles.header}>
						<Typography variant="h5">
							Edit Job Postings
                </Typography>
					</Grid>
					<Card className={styles.webCard} variant='outlined'>
						<CardContent className={styles.webCard}>
							{showDataGrid && <Datagrids gridRows={gridRows} gridColumns={gridColumns} disableCheckbox />}
						</CardContent>
					</Card>
				</Grid>
			</MiniDrawer>
		</>
	)
}

export default EditJobPosting