import React, { FunctionComponent, useState } from 'react'
import {
  Box,
  FormControl,
  Grid,
  Input,
  Typography,
  Button,
  Select,
  MenuItem
} from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { compareAsc } from 'date-fns'
import { useSnackbar } from "notistack"

import { resourceType, feedbackRes } from '../interfaces/webResource'
import Modal from '../../common/components/modal'
import useStyles from '../../dashboard/containers/customerManagement/styles'
import { uploadResource, fetchUploadUrlForHomeContent, updateContent, fetchDownloadUrlForHomeContent, updateFeedback } from '../api'
import Dropzone from '../../common/components/dropzone/dropzone'
import UploadedContent from '../../common/components/dropzone/previewers/uploadedContent'
import { convertToBase64, onApiError } from '../../common/helpers'

interface Props {
    openModal: boolean;
    onClose: () => any;
    feedbackRes: feedbackRes;
}

const convertDateString = (dateString: string) => {
    var dateParts: any = dateString.split("/");
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
} 

const EditFeedbackModal: FunctionComponent<Props> = ({
    onClose, openModal, feedbackRes
}) => {
    const {name, endDate, startDate, title, customerPic, institute, feedback, _id} = feedbackRes
    const [newName, setNewName] = useState(name)
    const [newInstitute, setNewInstitute] = useState(institute)
    const [newTitle, setNewTitle] = useState(title)
    const [newStartDate, setNewStartDate] = useState(convertDateString(startDate))
    const [newEndDate, setNewEndDate] = useState(convertDateString(endDate))
    const [newFeedback, setNewFeedback] = useState(feedback)

    const [droppedFiles, setDroppedFiles] = useState<File[]>([])

    const { enqueueSnackbar } = useSnackbar()

    const styles = useStyles()

    const handleDateChange = (d: Date, dateType: string) => {
        if(dateType === 'start') {
            d.setHours(0,0,0,0)
            setNewStartDate(d)
        }
        if(dateType === 'end') {
            d.setHours(23,59,0,0)
            setNewEndDate(d)
        }
    }

    const viewCustomerPic = () => {
        if(!customerPic) return
        var newTab = window.open();
        newTab!.document.body.innerHTML = `<img src="${customerPic}" width="100px" height="100px">`;
    }

    const addingFile = (files: File[], setter: any) => {
		if (files.length < 1) return;
		setter(files)
	}

	const removingFile = (setter: any) => setter([])

    const getFileTypes = (type: string) => {
		if (type === resourceType.blog) return ['text/html']
		if (type === resourceType.ebook) return ['application/pdf']
        if (type === 'image') return ['image/jpeg', 'image/png']
		return []
	}

    const checkValidation = () => {
		if (compareAsc(newStartDate, newEndDate) == 1) {
			enqueueSnackbar("End date should be after start date", { variant: "error" })
			return false
		}

		if (newTitle.length < 3) {
			enqueueSnackbar("Title should have minimum 3 characters", { variant: "error" })
			return false
		}

        if (newName.length < 3) {
            enqueueSnackbar("Customer Name should have minimum 3 characters", { variant: "error" })
			return false
        }

        if(newInstitute.length < 3) {
            enqueueSnackbar("Institute should have minimum 3 characters", { variant: "error" })
			return false
        }

        if (newFeedback.length < 20) {
            enqueueSnackbar("Feedback should have minimum 20 characters", { variant: "error" })
			return false
        }

		return true
	}

    const saveChanges = async () => {
        if (!checkValidation()) return;
        let newCustomerPic = ''
        try {
            if (droppedFiles.length > 0) {
                newCustomerPic = await convertToBase64(droppedFiles[0])
            }

            const resourceUpdate: any = { _id }
            let updateCount = 0
            if(newTitle !== title) {
                resourceUpdate.title = newTitle
                updateCount++
            }            
            if(newStartDate.getTime() !== new Date(convertDateString(startDate).setHours(0,0,0,0)).getTime()) {
                resourceUpdate.startDate = newStartDate
                updateCount++
            }
            if(newEndDate.getTime() !== new Date(convertDateString(endDate).setHours(0,0,0,0)).getTime()) {
                resourceUpdate.endDate = newEndDate
                updateCount++
            }
            if(newCustomerPic !== customerPic) {
                resourceUpdate.customerPic = newCustomerPic
                updateCount++
            }
            if(newFeedback !== feedback) {
                resourceUpdate.feedback = newFeedback
                updateCount++
            }
            if(newInstitute !== institute) {
                resourceUpdate.institute = newInstitute
                updateCount++
            }
            if(newName !== name) {
                resourceUpdate.name = newName
                updateCount++
            }

            if(updateCount === 0) {
                enqueueSnackbar('No changes made', {variant: "warning"})
                return;
            }

            await updateFeedback(resourceUpdate)
            .then(() => {
                enqueueSnackbar('Successfully uploaded', { variant: "success" })
                setDroppedFiles([])
                onClose()
            })
        } catch (err) {
			onApiError(err, enqueueSnackbar)
        }
    }

    const renderInfoWithButtons = (key: string, buttons: {name: string, cb: () => void}[]) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>

            {buttons.map(btn => (
                <Grid item xs={6} md={4}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    size="medium"
                    onClick={btn.cb}
                >
                    {btn.name}
                </Button>
                </Grid>
            ))}

        </Grid>
    )
    
    const renderInput = (key: string, value: any, setter: any, maxLength?: number) => (
        <Grid container>
            <Grid item xs={12} md={4}>
            <FormControl fullWidth margin="normal">
                <Box className={styles.label}>{key}</Box>
            </FormControl>
            </Grid>
    
            <Grid item xs={12} md={8}>
            <FormControl fullWidth margin="normal">
                <Input
                placeholder={key}
                value={value}
                inputProps={{ maxLength: maxLength ? maxLength : 100 }}
                onChange={(e) => setter(e.target.value)}
                />
            </FormControl>
            </Grid>
        </Grid>
    )

    const renderDropzone = (key: string, value: File[], maxFileSize: number, fileType: string, setter: any) => (
        <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
                <FormControl fullWidth margin="normal">
                    <Box className={styles.label}>
                        {key}
                    </Box>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
                <Dropzone
                    key={0}
                    acceptedFiles={getFileTypes(fileType)}
                    multipleFiles={false}
                    maxFileSize={maxFileSize} // 100 MB
                    files={value}
                    onChange={(files) => addingFile(files, setter)}
                />
                {value.length > 0 && <UploadedContent
                    files={value}
                    onRemoveItem={() => removingFile(setter)}
                />}
            </Grid>
        </Grid>
    )
    
    const renderDateInput = (key: string, value: Date, setter: any, dateType: string) => (
        <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
                <Box className={styles.label}>
                    {key}
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl fullWidth margin="normal">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={key}
                            value={value}
                            onChange={(d) => setter(d, dateType)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
            </Grid>
        </Grid>
    )
    
    const renderButton = (name: string, cb: any) => (
        <Button 
        variant="contained" 
        color="primary" 
        size="medium"
        style={{ margin: 10 }}
        onClick={cb}
        >
            {name}
        </Button>
    )

    return (
        <Modal
            open={openModal}
            handleClose={onClose}
            header={
                <Box>
                    <Typography component="span" color="inherit">
                    <Box component="h3">
                        {`Edit Feedback`}
                    </Box>
                    </Typography>
                </Box>
            }
        >
            {renderInput('Customer name', newName, setNewName)}
            {renderInput(`Customer title`, newTitle, setNewTitle)}
            {renderInput(`Institute`, newInstitute, setNewInstitute)}
            {renderDateInput('Start Date', newStartDate, handleDateChange, 'start')}
            {renderDateInput('End Date', newEndDate, handleDateChange, 'end')}
            {renderInput(`Customer feedback`, newFeedback, setNewFeedback, 1000)}
            {renderInfoWithButtons('View customer pic', [{name: 'Customer pic', cb: viewCustomerPic}])}
            {renderDropzone('Upload new customer pic', droppedFiles, 1048576, 'image', setDroppedFiles)}
            {renderButton('Save Changes', saveChanges)}
        </Modal>
    )
}

export default EditFeedbackModal