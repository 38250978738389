import React, { FunctionComponent, useState, useEffect } from "react";
import {
    Box,
	Container,
	CssBaseline,
	Grid,
	IconButton,
	makeStyles,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import MiniDrawer from "../../../common/components/SideDrawer";
import { fontOptions } from "../../../../theme";
import { fetchUsers, registerUser, validateuser } from "../../../../api/dashboard";
import { FetchedUsers } from "../../interfaces";
import { GridCellParams, GridColDef } from "@material-ui/data-grid";
import Datagrids, { datagridCellExpand } from "../../components/dataGrid";
import SignupModal from "../../components/signupModal";
import AddRoleModal from "../../components/addRoleModal";
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

const useStyles = makeStyles({
	title: {
		fontSize: fontOptions.size.large
	},
});

interface Data {
    id: string;
    name: string;
    ownerId: string;
    mobileNo: string;
    emailId: string;
    userType: string;
    tutorgname: string;
}
  
function createData(
    id: string,
    name: string,
    ownerId: string,
    mobileNo: string,
    emailId: string,
    userType: string,
    tutorgname: string
): Data {
    return { id, name, ownerId, mobileNo, emailId, userType, tutorgname };
}

interface FormData {
    password: string;
    passwordConfirmation: string;
    serverError: string;
}

const SignUpUsers: FunctionComponent = () => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar()

    const [userType, setUserType] = useState<'tutor' | 'student' | 'parent' | 'orgadmin'>('tutor')
    const [users, setUsers] = useState<FetchedUsers[]>([])
    const [userTBS, setUserTBS] = useState<Data>()
    const [openModal, setOpenModal] = useState(false);
    const [openRoleModal, setOpenRoleModal] = useState(false);
    const [mobileSignUp, setMobileSignUp] = useState(true)

    useEffect(() => {
        selectUserType('tutor')
    }, []);

    const selectUserType = async(type: 'tutor' | 'student' | 'parent' | 'orgadmin') => {
        const allUsers = await fetchUsers(type)
        setUsers(allUsers)
        setUserType(type)
    }

    const handleRegister = async ({ password }: FormData) => {
        if(userTBS) {
            if(password.length > 20) {
              enqueueSnackbar('Password should not be more than 20 character', {
                variant: 'error'
              });
              return
            }
            if(password.includes(" ")) {
              enqueueSnackbar('Password should not contain spaces', {
                variant: 'error'
              });
              return
            }

            try {
                const result = await registerUser(
                    userTBS.ownerId as string,
                    password,
                    userTBS.userType,
                    userTBS.mobileNo as string,
                    userTBS.emailId,
                    userTBS.name,
                    userTBS.tutorgname,
                    mobileSignUp
                );
                enqueueSnackbar('Signed Up User', { variant: 'success' });
                setOpenModal(false)
                setOpenRoleModal(false)
                selectUserType(userType)
                setMobileSignUp(true)
            } catch (error) {
                enqueueSnackbar('Error in Sign Up', { variant: 'warning' });
            }
        }
    };

    const signup = async(selectedRowDetails: Data, type: string) => {
        if(type === 'mobileNo' && (!selectedRowDetails.mobileNo || (selectedRowDetails.mobileNo && selectedRowDetails.mobileNo.length === 0))) {
            enqueueSnackbar('Mobile No is not added for this Account', { variant: 'warning' });
        } else if(type === 'emailId' && (!selectedRowDetails.emailId || (selectedRowDetails.emailId && selectedRowDetails.emailId.length === 0))) {
            enqueueSnackbar('Email ID is not added for this Account', { variant: 'warning' });
        } else {
            setMobileSignUp(type === 'mobileNo' ? true : false)
            try {
                const response = await validateuser(selectedRowDetails.ownerId, selectedRowDetails.mobileNo, selectedRowDetails.emailId, selectedRowDetails.userType, (type === 'mobileNo' ? true : false))
                if(response.data.existingAC.length > 0) {
                    enqueueSnackbar('existingAC present', { variant: 'warning' });
                } else {
                    setUserTBS(selectedRowDetails)
                    setOpenModal(true)
                }
            } catch (error) {
                if((error.response?.status === 422) && (error.response?.data.code === 129)) {
                    if(error.response && error.response?.data.existingAC.length > 0) {
                        enqueueSnackbar('existingAC present + Add Role', { variant: 'warning' });
                    } else {
                        setUserTBS(selectedRowDetails)
                        setOpenRoleModal(true)
                    }
                }
            }
        }
    }

    const buttonData = [
        { title: 'Sign up using Mobile Number',
          action: signup,
          icon: <PhoneAndroidIcon />,
          type: 'mobileNo'
        },
        { title: 'Sign up using Email ID',
          action: signup,
          icon: <AlternateEmailIcon />,
          type: 'emailId'
        },
    ];

    const rows = users.map((us) => {
        return(
          createData(
            us._id,
            (us.tutorName ? us.tutorName as string : (us.studentName ? us.studentName as string : (us.parentName ? us.parentName as string : us.adminName as string))),
            us.ownerId,
            us.mobileNo,
            us.emailId,
            (us.tutorName ? 'ROLE_TUTOR' : (us.studentName ? 'ROLE_STUDENT': (us.adminName ? 'ROLE_ORG_ADMIN' : 'ROLE_PARENT'))),
            us.tutorgname
          )
        )
    });
    
    const gridColumns: GridColDef[] = [
        { field: 'id', headerName: 'Sl No.', flex: 0.7 },
        { field: 'name', headerName: 'Name', flex: 1, renderCell: datagridCellExpand },
        { field: 'ownerId', headerName: 'Owner ID', flex: 1, renderCell: datagridCellExpand },
        { field: 'tutorgname', headerName: 'Owner Name', flex: 1, renderCell: datagridCellExpand },
        { field: 'mobileNo', headerName: 'Mobile No', flex: 0.7, renderCell: datagridCellExpand },
        { field: 'emailId', headerName: 'Email ID', flex: 1, renderCell: datagridCellExpand },
        { field: 'action', headerName: 'Action', flex: 1.3,
          disableClickEventBubbling: true,
          renderCell: (params: GridCellParams) => {
            const selectedRow = {
              id: params.getValue("id") as number,
              mobileNo: params.getValue("mobileNo") as string,
              ownerId: params.getValue("ownerId") as string
            }
    
            const selectedRowDetails = rows.find((row, index) => {
              return (row.mobileNo === selectedRow.mobileNo && row.ownerId === selectedRow.ownerId && index === (selectedRow.id - 1))
            })
    
            const buttonSet = buttonData.map((button, index) => {
              return (
                <Tooltip key={index} title={button.title}>
                  <IconButton
                    onClick={() => {
                      button.action(selectedRowDetails as Data, button.type);
                    }}
                    size="small"
                  >
                    {button.icon}
                  </IconButton>
                </Tooltip>
              );
            })
      
            return <div>{buttonSet}</div>;
          }
        }
    ];
    
    const gridRows = rows.map((row, index) => {
        return ({
          id: (index + 1),
          name: row.name,
          ownerId: row.ownerId,
          tutorgname: row.tutorgname,
          mobileNo: row.mobileNo,
          emailId: row.emailId,
        })
    })

    return(
        <div>
            <CssBaseline />
            <MiniDrawer>
                <Container maxWidth="lg">
                    <Box
                        bgcolor="#4C8BF5"
                        padding="20px 30px"
                        marginBottom="30px"
                        position="relative"
                        borderRadius="5px"
                        color='#fff'
                    >
                        <Grid item container>
                            <Grid item sm={8}>
                                <Box style={{height: '100%'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography className={classes.title}>
                                                Sign Up Users
                                            </Typography>
                                            <Typography>
                                                Sign Up Dependant Users | Set Password
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        bgcolor="#FFFFFF"
                        padding="20px 30px"
                        marginBottom="30px"
                        position="relative"
                        borderRadius="5px"
                    >
                        <Grid container>
                            <Grid item xs={6} style={{paddingTop: '10px', marginBottom: '10px'}}>
                                <Typography style={{fontSize: fontOptions.size.medium, fontWeight: fontOptions.weight.bold, color: '#3D3D3D'}}>
                                    Unregistered Users
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{marginBottom: '10px'}}>
                                <Box display="flex" flexDirection="row-reverse">
                                    <Box p={1}>
                                        <Select
                                            style={{width: '110px'}}
                                            value={userType}
                                            onChange={(
                                            e: React.ChangeEvent<{ value: unknown}>
                                            ) => selectUserType(e.target.value as 'tutor' | 'student' | 'parent' | 'orgadmin')}
                                        >
                                            <MenuItem value="tutor">Org Tutors</MenuItem>
                                            <MenuItem value="student">Students</MenuItem>
                                            <MenuItem value="parent">Parents</MenuItem>
                                            <MenuItem value="orgadmin">Org Admins</MenuItem>
                                        </Select>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} style={{marginTop: '15px'}}>
                                <Datagrids gridRows={gridRows} gridColumns={gridColumns} disableCheckbox={true} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </MiniDrawer>
            <SignupModal
                openModal={openModal}
                onClose={() => {
                    setOpenModal(false)
                    setUserTBS(undefined)
                    setMobileSignUp(true)
                }}
                userTBS={userTBS}
                handleRegister={handleRegister}
                mobileSignUp={mobileSignUp}
            />
            <AddRoleModal
                openModal={openRoleModal}
                onClose={() => {
                    setOpenRoleModal(false)
                    setUserTBS(undefined)
                    setMobileSignUp(true)
                }}
                userTBS={userTBS}
                handleRegister={handleRegister}
                mobileSignUp={mobileSignUp}
            />
        </div>
    )
}

export default SignUpUsers;