const BASE_ROUTE =  process.env.REACT_APP_API

export const UPLOAD_DATA = BASE_ROUTE +"admin/uploadData";

export const GET_FILENAMES = BASE_ROUTE + 'admin/filenames';

export const GET_MANIFEST = BASE_ROUTE + 'admin/getManifestJson';
export const GET_STUDENTENROLLED = BASE_ROUTE + 'admin/studentEnrolled';


export const GET_UPLOAD_URL = BASE_ROUTE + 'admin/getUploadUrl';

export const GET_API_SET = BASE_ROUTE + 'admin/uploadApiSet';

export const GET_COURSE_NAME  = BASE_ROUTE + 'admin/getFileSet'

export const DELETE_BY_FILENAME = BASE_ROUTE + 'admin/deletebyfilename';

export const DOCX_DATA = BASE_ROUTE +"admin/uploadDocxData";

export const GET_COURSE_DATA  = BASE_ROUTE +'admin/publicDataOpen'