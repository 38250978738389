import React, { useState, useEffect, FunctionComponent } from "react";
import { Redirect } from 'react-router'
import {
	Box,
	Container,
	CssBaseline,
	darken,
	FormControl,
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import Button from "../../../common/components/form_elements/button";
import { GridColDef } from "@material-ui/data-grid";
import MiniDrawer from "../../../common/components/SideDrawer";
import { fontOptions } from "../../../../theme";
import { MASTER_PERMISSIONS } from "../../../../utilities/constants";
import Datagrids, { datagridCellExpand } from "../../components/dataGrid";
import { getLoggedInUserData, onApiError} from '../../../common/helpers';
import { fetchOtps } from "../../api";
import { otpResponse, otpRow } from "../../interfaces";

const useStyles = makeStyles({
	typography_1: {
		color: '#4285F4'
	  },
	  typography_2: {
		color: 'red',
		paddingTop: '160px'
	  },
	  title: {
		fontSize: fontOptions.size.large
	  },
	  addAssi: {
		'& button': {
		  color: '#4C8BF5',
		  backgroundColor: '#FFFFFF',
		  '&:hover': {
			backgroundColor: darken('#FFFFFF', 0.1),
		  }
		},
		paddingTop: '12px'
	  },
	  formControl: {
		margin: "5px",
		minWidth: 120,
	  },
	  helperText: {
		fontSize: fontOptions.size.small,
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)'
	  },
	  heading: {
		margin: '0',
		fontWeight: fontOptions.weight.bold,
		fontSize: fontOptions.size.medium,
		letterSpacing: '1px',
		color: '#212121',
	  },
      label: {
        fontWeight: fontOptions.weight.bold,
        fontSize: fontOptions.size.small,
      },
});

const ViewOtps: FunctionComponent = () => {
    const classes = useStyles();

    const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().setHours(0,0,0,0)))
    const [endDate, setEndDate] = useState<Date | null>(new Date(new Date().setHours(23,59,59,999)))

    const [showDataGrid, setShowDataGrid] = useState(false)
    const [gridRows, setGridRows] = useState<otpRow[]>([])
    const [gridColumns, setGridColumns] = useState<GridColDef[]>([])

    const [redirectTo, setRedirectTo] = useState<string>('')
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        getLoggedInUserData(setRedirectTo, [MASTER_PERMISSIONS.liveClasses], enqueueSnackbar)
    }, [])

    if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

    const handleDateChange = (d: Date, dateType: string) => {
        if(dateType === 'start' && d) {
            d.setHours(0,0,0,0)
            setStartDate(d)
        }
        if(dateType === 'end' && d) {
            d.setHours(23,59,59,999)
            setEndDate(d)
        }
    }

    const handleFetchOtps = async () => {
        if(!startDate || !endDate) {
            enqueueSnackbar('Select valid Dates', {variant: 'warning'})
            return;
        }
        if(startDate.getTime() > endDate.getTime()) {
            enqueueSnackbar('Start Date has to be before End Date', {variant: 'warning'})
            return;
        }
        try {
            const otps = await fetchOtps({startDate, endDate})
            otps.sort((a, b) => new Date(b.generatedOn).getTime() - new Date(a.generatedOn).getTime())
            createDataGrid(otps)
            setShowDataGrid(true)
        }
        catch (err) {
            onApiError(err, enqueueSnackbar, setRedirectTo)
        }
    }

    const createDataGrid = (list: otpResponse[]) => {
		function createData(otpRow: otpResponse, i: number) {
			const { emailId, mobileNo, failedCount, generatedOn, otp, sentCount } = otpRow
            let mobileEmail = mobileNo ? mobileNo : ''
            if(emailId) mobileEmail += emailId
			return {
				id: i+1, emailId, mobileNo, failedCount,
                generatedOn: new Date(generatedOn).toLocaleDateString('en-IN'),
                generatedTime: new Date(generatedOn).toLocaleTimeString('en-IN'),
                otp, sentCount, mobileEmail
			}
		}

		const rows: otpRow[] = list.map((cur: otpResponse, i: number) => createData(cur, i))

		setGridRows(rows)

		const columns = [
			{ field: 'id', headerName: 'S.No', flex: 0.5 },
			{ field: 'generatedOn', headerName: 'Date', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'generatedTime', headerName: 'Time', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'mobileEmail', headerName: 'Mobile/Email', flex: 1.5, width: 100, renderCell: datagridCellExpand },
            { field: 'otp', headerName: 'OTP', flex: 1, width: 100, renderCell: datagridCellExpand },
			{ field: 'sentCount', headerName: 'Sent Count', flex: 1, width: 100, renderCell: datagridCellExpand },
            { field: 'failedCount', headerName: 'Failed Count', flex: 1, width: 100, renderCell: datagridCellExpand }
		]
		setGridColumns(columns)
	}

    const renderDateInput = (key: string, value: any, setter: any, dateType: string) => (
        <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
                <Box className={classes.label}>
                    {key}
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl fullWidth margin="normal">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={key}
                            value={value}
                            onChange={(d) => setter(d, dateType)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
            </Grid>
        </Grid>
    )

    const renderButton = (name: string, cb: any) => (
        <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item>
                <Button variant="contained" color="primary" size="medium"
                    onClick={cb}
                >
                    {name}
                </Button>
            </Grid>
        </Grid>
    )

    return(
        <>
        <CssBaseline />
        <MiniDrawer>
        <Container maxWidth="lg">
            <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="5px"
            color='#fff'
            >
            <Grid item container>
                <Grid item sm={8}>
                <Box style={{height: '100%'}}>
                    <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                    >
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            View OTPs
                        </Typography>
                        <Typography>
                            View OTPs report between two Dates
                        </Typography>
                    </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            </Box>

            <Box
				bgcolor="#ffffff"
				borderRadius="5px"
				padding="12px 25px"
				marginTop='25px'
			>
                <Grid container justify='center'>
                    <Grid item xs={12} lg={6} style={{paddingTop: '5px', marginBottom: '8px'}}>
                        {renderDateInput('Start Date', startDate, handleDateChange, 'start')}
                        {renderDateInput('End Date', endDate, handleDateChange, 'end')}
                        {renderButton('Fetch OTPs', handleFetchOtps)}
                    </Grid>
                    <Grid item xs={12}>
                        {showDataGrid && <Datagrids key={'key' + startDate + endDate} gridRows={gridRows} gridColumns={gridColumns} disableCheckbox />}
                    </Grid>
                </Grid>
            </Box>   
        </Container>
        </MiniDrawer>
        </>
    )
}

export default ViewOtps;