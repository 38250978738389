import React, { FunctionComponent, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Container, Box, Grid, Typography, FormControl, Input, Select, MenuItem,  FormHelperText, useMediaQuery, FormControlLabel, Checkbox, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles, Theme, darken } from '@material-ui/core/styles';
import { fontOptions } from '../../../theme';
import { useForm } from 'react-hook-form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import Button from '../../common/components/form_elements/button';
import _, { result, uniq } from 'lodash'

import * as XLSX from 'xlsx';
import { QuestionBank, ImageObject, Course } from '../contracts/qb_interface';
import JSZip from 'jszip';
import { QB_PATTERN, QB_PATTERN2, URL_PATTERN } from '../../common/validations/patterns';
//@ts-ignore
import { parse, HtmlGenerator } from 'latex.js'
import HelpIcon from '@material-ui/icons/Help';
import BulkUploadHelpModal from '../components/bulkUploadHelpModal';
import { courseDetails } from '../../../utilities/constants';
import { postQuestionBank } from '../helpers/api';
import MiniDrawer from '../../common/components/SideDrawer';
import ErrModal from '../components/ErrModal';
import Dropzone from '../../common/components/dropzone';
import GridPreview from '../../common/components/dropzone/previewers/gridViewUploadContent';

const latexParserCheck =  (latexString:String) =>{
  let generator = new HtmlGenerator({ hyphenate: false })
  let doc = parse(latexString, { generator: generator }).htmlDocument()
  return doc.documentElement.outerHTML
}

const ifValidLatex = (latexString:string) => {
  let result;
  try {
    const parser = latexParserCheck(latexString);
    result = true
  } catch (error) {
    result = false
  }
  return result
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: fontOptions.size.large
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    error: {
      "&:not(.Mui-disabled)::before": {
        borderColor: "#F44E42"
      }
    },
    formInput: {
      '& input::placeholder': {
        fontWeight: fontOptions.weight.normal,
        fontSize: fontOptions.size.small,
        lineHeight: '18px',
        color: 'rgba(0, 0, 0, 0.54)'
      }
    },
    spError: {
      fontSize: fontOptions.size.small,
      color: '#F44E41'
    },
    addAssi: {
      '& button': {
        padding: '10px 30px 10px 30px',
      },
    },
    addAssi2: {
      '& button': {
        padding: '15px 30px 15px 30px',
        color: '#F9BD33',
        backgroundColor: '#FFFFFF',
        float: 'right',
        '&:hover': {
          backgroundColor: darken('#FFFFFF', 0.1),
        }
      },
    },
    dropzoneRoot: {
      width: '100%',
      minHeight: 'auto',
      border: '2.5px dashed rgba(76, 139, 245, 0.5)',
      boxSizing: 'border-box',
      borderRadius: '5px',
      paddingBottom: '15px'
    },
    dropzoneTextContainerRoot: {
     paddingTop: '3%' 
    },
    dropzoneText: {
      fontFamily:fontOptions.family,
      fontSize: fontOptions.size.small,
      fontWeight: fontOptions.weight.bold,
      color: '#4C8BF5',
      margin: '20px 0',
    },
    dropzoneIcon: {
      color: '#4C8BF5'
    },
  }),
);

interface FormData {
  qbName: string;
  description: string;
  boardName: string;
  className: string;
  subject: string;
  availStudent: boolean;
}

interface Props extends RouteComponentProps {}

interface SpError {
  droppedFilesSheet: string | boolean;
  droppedFilesImage: string | boolean;
}

interface ErrArrInterface {
  rowNo: number | null,
  message: string,
}

const BulkQB: FunctionComponent<Props> = ({location, history}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { errors, setError, clearError } = useForm<FormData>();

  const [qbName, setQbName] = useState('');
  const [description, setDescription] = useState('');
  const [allCourses, setAllCourses] = useState<Course[]>([])
  const [boardName, setBoardName] = useState('');
  const [className, setClassName] = useState('');
  const [subject, setSubject] = useState('');
  const [availStudent, setAvailStudent] = useState(true);
  const [isapiLoad, setisapiLoad] = useState(false)

  const [boardList, setBoardList] = useState<string[]>([])
  const [classList, setClassList] = useState<string[]>([])
  const [subjectList, setSubjectList] = useState<string[]>([])

  const [redirectTo, setRedirectTo] = useState('');
  const [uploading, setUploading] = useState(false)
  const [questi, setQuesti] = useState<QuestionBank[]>([])
  const [droppedFilesSheet, setDroppedFilesSheet] = useState<File[]>([]);
  const [droppedFilesImage, setDroppedFilesImage] = useState<File[]>([]);

  const [openErrModal, setOpenErrModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<any[]>([])
  const [helpModalOpen,setHelpModalOpen]= React.useState(false);

  const [spError, setSpError] = useState<SpError>({droppedFilesSheet: false, droppedFilesImage: false})

  useEffect(() => {
    (async () => {
      try {
          setisapiLoad(true)

          const courses = courseDetails
          setAllCourses(courses)
          if(courses.length > 0) {
            setBoardList(uniq(courses.map(bo => bo.board)))
          }

          setisapiLoad(false)
      } catch (error) {
        setRedirectTo('/login');
      }
    })();
  }, [location.search])

  const setBoardAndFetchClasses = async (board: string) => {
    try {
      setClassName('');
      setSubject('');
      setClassList([]);
      setSubjectList([]);
      setBoardName(board);
      clearError('boardName');
      if (board.length > 0) {
        if(allCourses.length > 0) {
          setClassList(uniq(allCourses.filter(bo => bo.board === board).map(bo => bo.className)))
        }
      } else {
        setClassList([]);
      }
    } catch (error) {
        setRedirectTo('/login');
    }
  };

  const setClassAndFetchSubjects = (data: string) => {
    setSubject('');
    setSubjectList([]);
    setClassName(data)
    clearError('className');
    if (data.length > 0) {
      if(allCourses.length > 0) {
        setSubjectList(uniq(allCourses.filter(bo => bo.board === boardName && bo.className === data).map(bo => bo.subject)))
      }
    } else {
      setSubjectList([]);
    }
  }

  const addingFile = (file: File[]) => {
    if(file.length > 0) {
      validateExcel(file[0], (result: boolean, reason: string) => {
        if(result) {
          setDroppedFilesSheet(file)
          setSpError(prev => {return {...prev, ...{droppedFilesSheet: false}}})
          enqueueSnackbar('Sheet Imported Successfully', {variant: 'success'});
        } else {
          setDroppedFilesSheet([])
          enqueueSnackbar(reason, {variant: 'error'});
        }
      })
    }
  }

  const removingFile = (file: File, index: number) => {
    setDroppedFilesSheet([])
  }

  const addingFile2 = (file: File[]) => {
    if(file.length > 0) {
      setDroppedFilesImage(file)
      setSpError(prev => {return {...prev, ...{droppedFilesImage: false}}})
      enqueueSnackbar('Image Zip Imported Successfully', {variant: 'success'});
    }
  }

  const removingFile2 = (file: File, index: number) => {
    setDroppedFilesImage([])
  }

  const validateExcel = (file: File | null, callback: any) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          const bufferArray = e.target ? e.target.result : '';
          const wb = XLSX.read(bufferArray, { type: 'buffer' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          var headers = [];

          //@ts-ignore
          var range = XLSX.utils.decode_range(ws['!ref']);
          var C, R = range.s.r;
         
          for(C = range.s.c; C <= range.e.c; ++C) {
            var cell = ws[XLSX.utils.encode_cell({c:C, r:R})] 

            if(cell && cell.t) {
              const hdr = XLSX.utils.format_cell(cell); 
              headers.push(hdr);
            }
          }
          
          const jsonData = XLSX.utils.sheet_to_json(ws);
          resolve({jsonData, headers});
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      }
    });
    promise.then(async (tutorArr: any) => {
      const dataFormat = tutorArr.jsonData[0]
      const headers = tutorArr.headers;

      if(!dataFormat) {
        callback(false, 'Please upload excel with Data')
      } else {
        if(['S_No', 'ChapterName', 'Sub_Topic', 'Type', 'Question_Description', 'Complexity'].some(sm => !headers.includes(sm))) {
          callback(false, 'Uploaded data format is incorrect')
        } else {
          callback(true, 'True')
        }
      }
    });
  }

  const initCreateQB = async () => {
    if (!qbName || (qbName && qbName.length === 0)) {
      setError(
        'qbName',
        'Invalid Data',
        'Name cannot be Empty'
      );
      return;
    } else {
      clearError('qbName');
    }

    if (qbName && (qbName.replace(/ /g, "").length < 5)) {
      setError(
        'qbName',
        'Invalid Data',
        'Name should have atleast 5 characters excluding spaces'
      );
      return;
    } else {
      clearError('qbName');
    }

    if (qbName && (qbName.length > 50)) {
      setError(
        'qbName',
        'Invalid Data',
        'Name should not have more than 50 characters'
      );
      return;
    } else {
      clearError('qbName');
    }

    if (qbName && qbName.trim() && (!QB_PATTERN.test(qbName.trim()))) {
      setError(
        'qbName',
        'Invalid Data',
        'Name is Invalid'
      );
      return;
    } else {
      clearError('qbName');
    }

    if (!description || (description && description.length === 0)) {
      setError(
        'description',
        'Invalid Data',
        'Description cannot be Empty'
      );
      return;
    } else {
      clearError('description');
    }

    if (description && (description.replace(/ /g, "").length < 10)) {
      setError(
        'description',
        'Invalid Data',
        'Description should have atleast 10 characters excluding spaces'
      );
      return;
    } else {
      clearError('description');
    }

    if (description && (description.length > 1000)) {
      setError(
        'description',
        'Invalid Data',
        'Description should not have more than 1000 characters'
      );
      return;
    } else {
      clearError('description');
    }

    if (description && description.trim() && (!QB_PATTERN2.test(description.trim()))) {
      setError(
        'description',
        'Invalid Data',
        'Description is Invalid'
      );
      return;
    } else {
      clearError('description');
    }

    if (!boardName || (boardName && boardName.length === 0)) {
      setError(
        'boardName',
        'Invalid Data',
        'Board cannot be Empty'
      );
      return;
    } else {
      clearError('boardName');
    }

    if (!className || (className && className.length === 0)) {
      setError(
        'className',
        'Invalid Data',
        'Class cannot be Empty'
      );
      return;
    } else {
      clearError('className');
    }

    if (!subject || (subject && subject.length === 0)) {
      setError(
        'subject',
        'Invalid Data',
        'Subject cannot be Empty'
      );
      return;
    } else {
      clearError('subject');
    }

    if (!subject || (subject && subject.length === 0)) {
      setError(
        'subject',
        'Invalid Data',
        'Subject cannot be Empty'
      );
      return;
    } else {
      clearError('subject');
    }

    if(droppedFilesSheet.length === 0) {
      setSpError(prev => {return {...prev, ...{droppedFilesSheet: 'Please Upload Sheet'}}})
      return
    } else {
      setSpError(prev => {return {...prev, ...{droppedFilesSheet: false}}})
    } 

    const structutredImg: ImageObject[] = [];
    const filenames: string[] = []
    if(droppedFilesImage.length !== 0) {
      const jsZip = new JSZip()
      const zip = await jsZip.loadAsync(droppedFilesImage[0])
      for (let i =0; i < Object.keys(zip.files).length; i++) {
        const filename = Object.keys(zip.files)[i];
        const base64 = await zip.files[filename].async('base64')
        let eachfile:ImageObject
        let eachname: string
        const firstLetter = zip.files[filename].name.charAt(0);
        const last3 = zip.files[filename].name.substring(zip.files[filename].name.length - 3)
        const last4 = zip.files[filename].name.substring(zip.files[filename].name.length - 4)
        const firstletterCheck = (firstLetter === '1' || firstLetter === '2' || firstLetter === '3' || firstLetter === '4' || firstLetter === '5' || firstLetter === '6' || firstLetter === 'q' || firstLetter === 's') ? true : false;
        const extentionCheck = (last3 === 'png' || last3 === 'jpg' || last4 === 'jpeg') ? true : false
        if(firstletterCheck && extentionCheck) {
          eachfile = {
            filename: zip.files[filename].name,
            encoding: 'data:image/jpeg;base64,' + base64
          }
          eachname = zip.files[filename].name
        } else {
          eachfile = {
            filename: 'false',
            encoding: 'false'
          }
          eachname = 'false'
          enqueueSnackbar('Images in Zip File are not named as defined in excel file.Please check for extensions too.', {variant: 'warning'});
          return;
        }
        structutredImg.push(eachfile)
        filenames.push(eachname)
      }
    }

    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (droppedFilesSheet[0]) {
        fileReader.readAsArrayBuffer(droppedFilesSheet[0]);
        fileReader.onload = (e) => {
          const bufferArray = e.target ? e.target.result : '';
          const wb = XLSX.read(bufferArray, { type: 'buffer' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          /* Convert array to json*/
          const jsonData = XLSX.utils.sheet_to_json(ws);
          resolve(jsonData);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      }
    });

    promise.then(async (quesArr: any) => {
      const structutredQB: QuestionBank[] = [];
      if(quesArr) {
        let errArr:ErrArrInterface[] = [];

        for (let i =0; i < quesArr.length; i++) {
          const quest = quesArr[i];
          const imL:ImageObject[] = []
          if(quest.Image_Names) {
            const imArr = quest.Image_Names.split(',')
            const ispres = imArr.every((el: string) => filenames.includes(el))
            if(!ispres) {
              enqueueSnackbar('Please Upload Images Mentioned in the Sheet', {variant: 'warning'});
              return;
            }

            imArr.forEach((im: string) => {
              const thisfilname = structutredImg.find(stim => stim.filename === im)
              imL.push(thisfilname as ImageObject)
            })
          }

          const newQuest: QuestionBank = {
            srno: Number(quest.S_No),
            questionBankFriendlyName: qbName,
            description: description,
            boardname: boardName,
            classname: className,
            subjectname: subject,
            chaptername: quest.ChapterName,
            subTopic: quest.Sub_Topic,
            type: quest.Type,
            complexity: quest.Complexity,
            availableForMockTest: availStudent,
            commonQuestionPart: (quest.CommonQuestion || quest.CommonQuestion === false) ? String(quest.CommonQuestion).replaceAll('&nbsp;', ' ') : undefined ,
            questionDescription: String(quest.Question_Description).replaceAll('&nbsp;', ' '),
            option1: (quest.Option1 || quest.Option1 === false) ? String(quest.Option1).replaceAll('&nbsp;', ' ') : '',
            option2: (quest.Option2 || quest.Option2 === false) ? String(quest.Option2).replaceAll('&nbsp;', ' ') : '',
            option3: (quest.Option3 || quest.Option3 === false) ? String(quest.Option3).replaceAll('&nbsp;', ' ') : '',
            option4: (quest.Option4 || quest.Option4 === false) ? String(quest.Option4).replaceAll('&nbsp;', ' ') : '',
            option5: (quest.Option5 || quest.Option5 === false) ? String(quest.Option5).replaceAll('&nbsp;', ' ') : '',
            option6: (quest.Option6 || quest.Option6 === false) ? String(quest.Option6).replaceAll('&nbsp;', ' ') : '',
            list1: quest.List1 ? String(quest.List1).replaceAll('&nbsp;', ' ').split('|') : undefined,
            list2: quest.List2 ? String(quest.List2).replaceAll('&nbsp;', ' ').split('|') : undefined, 
            answer: String(quest.Answer_option_number)?.split(','),
            answerDescription: (quest.Answer_Description || quest.Answer_Description === false) ? String(quest.Answer_Description).replaceAll('&nbsp;', ' ') : '',
            solnVideoRef: quest.SolutionVideo ? quest.SolutionVideo : undefined,
            imageLinks: quest.Image_Names ? imL : undefined
          }

          if(newQuest.option3 === '' && newQuest.imageLinks && newQuest.imageLinks.filter(ln => ln.filename.charAt(0) === '3').length > 0) {
            newQuest.imageLinks = newQuest.imageLinks.filter(ln => ln.filename.charAt(0) !== '3')
          }

          if(newQuest.option4 === '' && newQuest.imageLinks && newQuest.imageLinks.filter(ln => ln.filename.charAt(0) === '4').length > 0) {
            newQuest.imageLinks = newQuest.imageLinks.filter(ln => ln.filename.charAt(0) !== '4')
          }

          if(newQuest.option5 === '' && newQuest.imageLinks && newQuest.imageLinks.filter(ln => ln.filename.charAt(0) === '5').length > 0) {
            newQuest.imageLinks = newQuest.imageLinks.filter(ln => ln.filename.charAt(0) !== '5')
          }

          if(newQuest.option6 === '' && newQuest.imageLinks && newQuest.imageLinks.filter(ln => ln.filename.charAt(0) === '6').length > 0) {
            newQuest.imageLinks = newQuest.imageLinks.filter(ln => ln.filename.charAt(0) !== '6')
          }

          if(newQuest.answerDescription === '' && newQuest.imageLinks && newQuest.imageLinks.filter(ln => ln.filename.charAt(0) === 's').length > 0) {
            newQuest.imageLinks = newQuest.imageLinks.filter(ln => ln.filename.charAt(0) !== 's')
          }

          const errArrPart = validateQB(newQuest, i)
          errArr = [...errArr, ...errArrPart]
          structutredQB.push(newQuest)
        }

        if(errArr.length > 0) {
          setOpenErrModal(true)
          setModalData(errArr)
        } else {
          await postQuestionBank(structutredQB)
          history.push('/edumaticaQB');
        }
      }
    })
  }

  const validateQB = (quesb: QuestionBank, index: number) => {
    let uploadError = '';
    let errArr:ErrArrInterface[] = [];

    if(quesb.srno === undefined) {
      uploadError = `S_No in the Sheet is empty`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(isNaN(quesb.srno)) {
      uploadError = `S_No in the Sheet is not a Number`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.chaptername === undefined) {
      uploadError = `Chapter Name in the Sheet is empty`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.subTopic === undefined) {
      uploadError = `Sub_Topic in the Sheet is empty`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.type === undefined) {
      uploadError = `Type in the Sheet is empty`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.type && quesb.type.length > 0 && (quesb.type !== 'single' && quesb.type !== 'multiple' && quesb.type !== 'numeric' && quesb.type !== 'match')) {
      uploadError = `Type in the Sheet should be either single, multiple, numeric or match. It is Case Sensitive`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.complexity === undefined) {
      uploadError = `Complexity in the Sheet is empty`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.complexity && quesb.complexity.length > 0 && (quesb.complexity !== 'easy' && quesb.complexity !== 'high' && quesb.complexity !== 'medium')) {
      uploadError = `Complexity in the Sheet should be either easy, medium or high. It is Case Sensitive`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.questionDescription === undefined || quesb.questionDescription === 'undefined' || !quesb.questionDescription) {
      uploadError = `Question_Description in the Sheet is empty`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.solnVideoRef !== undefined && quesb.solnVideoRef.length > 0 && !quesb.solnVideoRef.match(URL_PATTERN)) {
      uploadError = `SolutionVideo in the Sheet is has invalid URL`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if((quesb.questionDescription !== undefined) && quesb.questionDescription && !ifValidLatex(quesb.questionDescription)) {
      uploadError = `Latex format for question description is not valid`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if((quesb.answerDescription !== undefined) && (quesb.answerDescription && quesb.answerDescription.length > 0) && !ifValidLatex(quesb.answerDescription)) {
      uploadError = `Latex format for answer description is not valid`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.option1 && !ifValidLatex(quesb.option1)) {
      uploadError = `Latex format for Option1 is not valid`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.option2 && !ifValidLatex(quesb.option2)) {
      uploadError = `Latex format for Option2 is not valid`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.option3 && !ifValidLatex(quesb.option3)) {
      uploadError = `Latex format for Option3 is not valid`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.option4 && !ifValidLatex(quesb.option4)) {
      uploadError = `Latex format for Option4 is not valid`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.option5 && !ifValidLatex(quesb.option5)) {
      uploadError = `Latex format for Option5 is not valid`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.option6 && !ifValidLatex(quesb.option6)) {
      uploadError = `Latex format for Option6 is not valid`;
      errArr = [...errArr, {rowNo: index+1, message: uploadError}]
    }

    if(quesb.list1){
      quesb.list1.map((el)=>{
        if(!ifValidLatex(el)) {
          uploadError = `Latex format for one of the list 1 element is not valid`;
          errArr = [...errArr, {rowNo: index+1, message: uploadError}]
        }
      })
    }

    if(quesb.list2){
      quesb.list2.map((el)=>{
        if(!ifValidLatex(el)) {
          uploadError = `Latex format for one of the list 2 element is not valid`;
          errArr = [...errArr, {rowNo: index+1, message: uploadError}]
        }
      })
    }

    if(quesb.type && quesb.type === 'single') {
      if(quesb.option1 === undefined || quesb.option2 === undefined || !quesb.option1 || !quesb.option2) {
        uploadError = `Type single Question Should contain Option1 and Option2 in Sheet`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      var optionArr = [quesb.option1,quesb.option2,quesb.option3,quesb.option4,quesb.option5,quesb.option6].filter(el=>el!==undefined && el.length!==0)
      if(optionArr.filter((item, index) => optionArr.indexOf(item) != index).length>0){
        uploadError = `Duplicate options found`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.list1 || quesb.list2) {
        uploadError = `Type single Question Should NOT contain List1 and List2 in Sheet`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.answer && quesb.answer.length > 1) {
        uploadError = `Type single Question Should NOT contain more than one Answer Options`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.answer && quesb.answer.length === 1 && quesb.answer[0] === "undefined") {
        uploadError = `Answer_option_number in the Sheet is empty`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.answer && quesb.answer.length === 1 && quesb.answer[0] !== "undefined" && (quesb.answer[0] !== "A" && quesb.answer[0] !== "B" && quesb.answer[0] !== "C" && quesb.answer[0] !== "D" && quesb.answer[0] !== "E" && quesb.answer[0] !== "F" && quesb.answer[0] !== "1" && quesb.answer[0] !== "2" && quesb.answer[0] !== "3" && quesb.answer[0] !== "4" && quesb.answer[0] !== "5" && quesb.answer[0] !== "6")) {
        uploadError = `Answer_option_number in the Sheet is Invalid`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }
    } else if(quesb.type && quesb.type === 'multiple') {
      if(quesb.option1 === undefined || quesb.option2 === undefined || !quesb.option1 || !quesb.option2) {
        uploadError = `Type multiple Question Should contain Option1 and Option2 in Sheet`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      var optionArr = [quesb.option1,quesb.option2,quesb.option3,quesb.option4,quesb.option5,quesb.option6].filter(el=>el!==undefined && el.length!==0)
      if(optionArr.filter((item, index) => optionArr.indexOf(item) != index).length>0){
        uploadError = `Duplicate options found`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.list1 || quesb.list2) {
        uploadError = `Type multiple Question Should NOT contain List1 and List2 in Sheet`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }
      
      if(quesb.answer && quesb.answer.length === 1) {
        uploadError = `Type multiple Question Should contain more than one Answer Options`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.answer && quesb.answer.length > 1 && quesb.answer.some(awr => awr === "undefined")) {
        uploadError = `Some Answer_option_number in the Sheet is empty`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.answer && quesb.answer.length > 1 && quesb.answer.some(awr => awr !== 'undefined' && awr !== "A" && awr !== "B" && awr !== "C" && awr !== "D" && awr !== "E" && awr !== "F" && awr !== "1" && awr !== "2" && awr !== "3" && awr !== "4" && awr !== "5" && awr !== "6")) {
        uploadError = `Answer_option_number in the Sheet is Invalid`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }
    } else if(quesb.type && quesb.type === 'numeric') {
      if(quesb.option1 || quesb.option2 || quesb.option3 || quesb.option4 || quesb.option5 || quesb.option6) {
        uploadError = `Type numeric Question Should NOT contain Options in Sheet`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.list1 || quesb.list2) {
        uploadError = `Type numeric Question Should NOT contain List1 and List2 in Sheet`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.answer && quesb.answer.length > 1) {
        uploadError = `Type numeric Question Should NOT contain more than one answer`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.answer && quesb.answer.length === 1 && quesb.answer[0] === "undefined") {
        uploadError = `Answer_option_number in the Sheet is empty`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.answer && quesb.answer.length === 1 && isNaN(Number(quesb.answer[0]))) {
        uploadError = `For Type numeric Answer_option_number in the Sheet should be a Number`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }
    } else if(quesb.type && quesb.type === 'match') {
      if(quesb.option1 === undefined || quesb.option2 === undefined || !quesb.option1 || !quesb.option2) {
        uploadError = `Type match Question Should contain Option1 and Option2 in Sheet`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      var optionArr = [quesb.option1,quesb.option2,quesb.option3,quesb.option4,quesb.option5,quesb.option6].filter(el=>el!==undefined && el.length!==0)
      if(optionArr.filter((item, index) => optionArr.indexOf(item) != index).length>0){
        uploadError = `Duplicate options found`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.list1 === undefined || quesb.list2 === undefined) {
        uploadError = `Type match Question Should contain List1 and List2 in Sheet`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.list1?.length as number <2 || quesb.list2?.length as number <2 ) {
        uploadError = `Type match Question Should contain at least 2 elements in both list. Use '|' as seperator`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.list1?.length as number >6 || quesb.list2?.length as number >6 ) {
        uploadError = `Type match Question Should not contain more than 6 elements in either of the lists. Use '|' as seperator`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.answer && quesb.answer.length > 1) {
        uploadError = `Type match Question Should NOT contain more than one Answer Options`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.answer && quesb.answer.length === 1 && quesb.answer[0] === "undefined") {
        uploadError = `Answer_option_number in the Sheet is empty`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }

      if(quesb.answer && quesb.answer.length === 1 && quesb.answer[0] !== "undefined" && (quesb.answer[0] !== "A" && quesb.answer[0] !== "B" && quesb.answer[0] !== "C" && quesb.answer[0] !== "D" && quesb.answer[0] !== "E" && quesb.answer[0] !== "F" && quesb.answer[0] !== "1" && quesb.answer[0] !== "2" && quesb.answer[0] !== "3" && quesb.answer[0] !== "4" && quesb.answer[0] !== "5" && quesb.answer[0] !== "6")) {
        uploadError = `Answer_option_number in the Sheet is Invalid`;
        errArr = [...errArr, {rowNo: index+1, message: uploadError}]
      }
    }

    return errArr
  }

  if (redirectTo.length > 0) {
    return <Redirect to={redirectTo} />;
  }

  if(isapiLoad) {
    return <div>
        Loading ...
    </div>
  }

  return (
    <div>
      <MiniDrawer>
      <Container maxWidth="lg" style={{padding: '30px 2.5%'}}>
        <Box
          bgcolor="#F9BD33"
          padding="35px 30px"
          marginBottom="30px"
          position="relative"
          borderRadius="14px"
          color='#fff'
        >
          <Grid item container>
            <Grid item sm={12} md={6}>
              <Box style={{height: '100%'}}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                  style={{ height: '100%' }}
                >
                  <Grid item xs={12}>
                    <Typography className={classes.title}>
                      Import your Question Bank
                    </Typography>
                    <Typography>
                      Upload Question Bank from your computer. Download the template, add data into the template and upload it back here
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item sm={12} md={2}></Grid>
            <Grid item sm={12} md={4}>
              <Box style={{height: '100%'}}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                  style={{ height: '100%' }}
                >
                  <Grid item xs={12}>
                    <Box className={classes.addAssi2}>
                      <Link
                        style={{ textDecoration: 'none', color: '#F9BD33' }}
                        to="/files/questionBank.xlsx"
                        target="_blank"
                        download
                      >
                        <Button variant="contained" disableElevation>
                          Download Template
                        </Button>
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          bgcolor="#ffffff"
          borderRadius="14px"
          padding="25px"
          marginTop='25px'
        >
          <Grid container spacing={1}>
            <Grid item xs={12} style={{marginBottom: '25px'}}>
              <Box display="flex" flexDirection="row">
                <Box>
                  <Typography style={{color: '#3D3D3D', fontSize: fontOptions.size.medium}}>
                    Question Bank Detail
                  </Typography>
                </Box>
                <Box marginLeft="10px" marginTop="3px">
                  <Tooltip title="Help"><HelpIcon style={{color: '#4C8BF5', cursor: 'pointer'}} onClick={() => setHelpModalOpen(true)} /></Tooltip>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <Box>Name</Box>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={8}>
                  <FormControl style={{width: '75%'}} margin="normal">
                    <Input
                      placeholder="Enter Name"
                      value={qbName}
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => {
                        setQbName(e.target.value)
                        clearError('qbName');
                      }}
                      className={errors.qbName ? `${classes.error} ${classes.formInput}` : classes.formInput}
                    />
                  </FormControl>
                  {errors.qbName && (
                    <FormHelperText error>
                      {errors.qbName.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <Box>Description</Box>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={8}>
                  <FormControl style={{width: '75%'}} margin="normal">
                    <Input
                      placeholder="Enter Description"
                      value={description}
                      multiline
                      rows={4}
                      onChange={(e) => {
                        setDescription(e.target.value)
                        clearError('description');
                      }}
                      className={errors.description ? `${classes.error} ${classes.formInput}` : classes.formInput}
                    />
                  </FormControl>
                  {errors.description && (
                    <FormHelperText error>
                      {errors.description.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <Box>Board</Box>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={8}>
                  <FormControl style={{width: '75%'}} margin="normal">
                    <Select
                      value={boardName}
                      onChange={(
                        e: React.ChangeEvent<{ value: unknown }>
                      ) =>
                        setBoardAndFetchClasses(e.target.value as string)
                      }
                      displayEmpty
                      className={errors.boardName ? `${classes.error} ${classes.formInput}` : classes.formInput}
                    >
                      <MenuItem value="">Select board</MenuItem>
                      {boardList.length > 0 &&
                        boardList.map((item) => (
                        <MenuItem
                          value={item}
                          key={item}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.boardName && (
                    <FormHelperText error>
                      {errors.boardName.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <Box>Class</Box>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={8}>
                  <FormControl style={{width: '75%'}} margin="normal">
                    <Select
                      value={className}
                      onChange={(
                        e: React.ChangeEvent<{ value: unknown }>
                      ) => setClassAndFetchSubjects(e.target.value as string)}
                      displayEmpty
                      className={errors.className ? `${classes.error} ${classes.formInput}` : classes.formInput}
                    >
                      <MenuItem value="">Select class</MenuItem>
                      {classList.length > 0 &&
                        classList.map((standard) => (
                          <MenuItem
                            value={standard}
                            key={standard}
                          >
                            {standard}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.className && (
                    <FormHelperText error>
                      {errors.className.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={4}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <Box>Subject</Box>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={8}>
                  <FormControl style={{width: '75%'}} margin="normal">
                    <Select
                      value={subject}
                      onChange={(
                        e: React.ChangeEvent<{ value: unknown }>
                      ) => {
                        setSubject(e.target.value as string)
                        clearError('subject');
                      }}
                      displayEmpty
                      className={errors.subject ? `${classes.error} ${classes.formInput}` : classes.formInput}
                    >
                      <MenuItem value="">Select subject</MenuItem>
                      {subjectList.length > 0 &&
                        subjectList.map((sub) => (
                          <MenuItem
                            value={sub}
                            key={sub}
                          >
                            {sub}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.subject && (
                    <FormHelperText error>
                      {errors.subject.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={availStudent}
                          onChange={() => setAvailStudent(prev => !prev)}
                          color="primary"
                        />
                      }
                      label="Allow for Mock Test Series"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={2}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <Box>Upload Sheet</Box>
                    <Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>Support Format: XLSX</Typography>
                    <Typography style={{fontSize: fontOptions.size.small, color: '#666666', marginTop: '10px'}}>Mandatory.</Typography>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={10}>
                  <FormControl style={{width: '91%'}} margin="normal">
                    {uploading &&
                      <Typography style={{fontSize: fontOptions.size.small, color: '#4C8BF5', marginBottom: '15px'}}>Uploading...</Typography>
                    }
                    <GridPreview
                      files={droppedFilesSheet}
                      onRemoveItem={(file, index) => removingFile(file, index)}
                      setDroppedFiles={setDroppedFilesSheet}
                    />
                    <Dropzone
                      acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                      showresp={['error', 'info']}
                      maxFileSize={104857600} // 100 MB
                      files={droppedFilesSheet}
                      onChange={(files) => {
                        addingFile(files)
                      }}
                      xtraClasses={{
                        root: classes.dropzoneRoot,
                        textContainer: classes.dropzoneTextContainerRoot,
                        text: classes.dropzoneText,
                        icon: classes.dropzoneIcon
                      }}
                      dropzoneText="Upload your file here"
                    />
                  </FormControl>
                  {spError.droppedFilesSheet &&
                    <Typography className={classes.spError}>{spError.droppedFilesSheet}</Typography>
                  }
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={2}>
                  <FormControl style={{width: '75%', paddingTop: '7px'}} margin="normal">
                    <Box>Upload Zip</Box>
                    <Typography style={{fontSize: fontOptions.size.small, color: '#666666'}}>Support Format: ZIP</Typography>
                    <Typography style={{fontSize: fontOptions.size.small, color: '#666666', marginTop: '10px'}}>If the sheet contains Image name then should upload Zip file which has all Images.</Typography>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={10}>
                  <FormControl style={{width: '91%'}} margin="normal">
                    <GridPreview 
                      files={droppedFilesImage}
                      onRemoveItem={(file, index) => removingFile2(file, index)}
                      setDroppedFiles={setDroppedFilesImage}
                    />
                    <Dropzone
                      acceptedFiles={['.zip']}
                      showresp={['error', 'info']}
                      maxFileSize={104857600} // 100 MB
                      files={droppedFilesImage}
                      onDrop={(files) => {
                        addingFile2(files)
                      }}
                      xtraClasses={{
                        root: classes.dropzoneRoot,
                        textContainer: classes.dropzoneTextContainerRoot,
                        text: classes.dropzoneText,
                        icon: classes.dropzoneIcon
                      }}
                      dropzoneText="Upload your file here"
                    />
                  </FormControl>
                  {spError.droppedFilesImage &&
                    <Typography className={classes.spError}>{spError.droppedFilesImage}</Typography>
                  }
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <span style={{float: 'right', paddingRight: '7%', paddingTop: '25px'}} className={classes.addAssi}>
                <Button style={{marginLeft: '15px', marginBottom:'10px'}} color="primary" disableElevation variant="contained" onClick={initCreateQB}>
                  Generate
                </Button>
              </span>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <ErrModal
        openModal={openErrModal}
        onClose={() => {setModalData([]); setOpenErrModal(false)}}
        data={modalData}
      />
      <BulkUploadHelpModal 
        openModal={helpModalOpen} 
        onClose={()=>setHelpModalOpen(false)}
      />
      </MiniDrawer>
    </div>
  );
}

export default (withRouter(BulkQB));
