// src/DataTable.js
import React, { FunctionComponent, Props, useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { Box, Button, Card, CardContent, CardHeader, Container, CssBaseline, Grid, IconButton, Theme, Tooltip, Typography, createStyles, darken, lighten, makeStyles } from '@material-ui/core';
import MiniDrawer from '../../../common/components/SideDrawer';
import { Delete, Edit, Visibility } from '@material-ui/icons';
// import useStyles from './styles';
import { fetchAnnouncements, fetchAssets, fetchAssignedAssignmentNew, fetchCurriculum, fetchEBook, fetchMasterCourse, fetchSignedContents, getChapterContent, getCourseBundles, getExamData, getGdFormBy_Id } from '../../../../api/dashboard';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setRowData, setViewData } from '../../../redux/actions/adminActions';
import { RouteComponentProps } from 'react-router-dom';
import { fontOptions } from '../../../../theme';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		typography_1: {
			color: '#4285F4'
		},
		typography_2: {
			color: 'red',
			paddingTop: '160px'
		},
		title: {
			fontSize: fontOptions.size.large
		},
		addAssi: {
			'& button': {
				color: '#4C8BF5',
				backgroundColor: '#FFFFFF',
				'&:hover': {
					backgroundColor: darken('#FFFFFF', 0.1),
				}
			},
			paddingTop: '12px'
		},
		formControl: {
			margin: "5px",
			minWidth: 120,
		},
		helperText: {
			fontSize: fontOptions.size.small,
			letterSpacing: '0.15px',
			color: 'rgba(0, 0, 0, 0.6)'
		},
		heading: {
			margin: '0',
			fontWeight: fontOptions.weight.bold,
			fontSize: fontOptions.size.medium,
			letterSpacing: '1px',
			color: '#212121',
		},
		label: {
			fontWeight: fontOptions.weight.bold,
			fontSize: fontOptions.size.small,
		},
		root: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(1),
			height: "100%",
		},
		highlight:
			theme.palette.type === "light"
				? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
				}
				: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
				},
	}
	));



const FileStorage: FunctionComponent<RouteComponentProps> = ({
	history,

}) => {
	const styles = useStyles();
	const dispatch = useDispatch();

	const classes = useStyles();


	const [ELibraryArray, setELibraryArray] = useState<any>();
	const [ELibrarySize, setELibrarySize] = useState<any>();

	const [degreeMasterArray, setDegreeMasterArray] = useState<any>();
	const [degreeMasterSize, setDegreeMasterSize] = useState<any>();

	const [gdArray, setGdArray] = useState<any>();
	const [gdSize, setGdSize] = useState<any>();

	const [assignArray, setAssignArray] = useState<any>();
	const [assignSize, setAssignSize] = useState<any>();
	const [ansSize, setAnsSize] = useState<any>();

	const [assetArray, setAssetArray] = useState<any>();
	const [assetSize, setAssetSize] = useState<any>();

	const [anounceArray, setAnounceArray] = useState<any>();
	const [anounceSize, setAnounceSize] = useState<any>();

	const [courseBundleArray, setcourseBundleArray] = useState<any>();
	const [courseBundleSize, setcourseBundleSize] = useState<any>();

	const [onScreenArray, setOnScreenArray] = useState<any>();
	const [onScreenSize, setOnScreenSize] = useState<any>();

	const [contentArray, setContentArray] = useState<any>();
	const [contentSize, setContentSize] = useState<any>();

	const [courseContArray, setCourseContArray] = useState<any>();
	const [courseContSize, setcourseContSize] = useState<any>();

	const data = useSelector((state: RootState) => state.adminReducer.data);
	console.log("data for ownerID: ", data)

	const fetchStorage = async () => {
		try {
			const masterCResp: any = await fetchMasterCourse(data[0].ownerId)
			console.log("masterCResp---", masterCResp)
			setDegreeMasterArray(masterCResp)

		} catch (error) {
			console.log("error in fetch tutors")
			if (
				error.response?.status === 401 &&
				error.response?.data.message !== 'TokenExpiredError'
			) {
				localStorage.clear();
			}
		}
	};

	useEffect(() => {
		fetchStorage();
	}, [])


	const getData = async () => {
		const degreeResp: any = await fetchEBook(data[0].ownerId)
		console.log("degreeResp---", degreeResp)
		setELibraryArray(degreeResp)

		const res = await getGdFormBy_Id(data[0].ownerId);
		const datagd = res?.dashboardData;
		console.log('data: for gd--', datagd);
		setGdArray(datagd)

		const assignedAssignmentResp = await fetchAssignedAssignmentNew(data[0].ownerId)
		console.log("assignedAssignmentResp---", assignedAssignmentResp)
		setAssignArray(assignedAssignmentResp)

		let assets: any = await fetchAssets(data[0].ownerId);
		setAssetArray(assets)
		console.log("resAsset---", assets)

		const announcementReq = await fetchAnnouncements(data[0].ownerId);
		console.log("announcementReq---", announcementReq);
		setAnounceArray(announcementReq);

		const courseBundlesData = await getCourseBundles();
		setcourseBundleArray(courseBundlesData);
		console.log('courseBundlesData:', courseBundlesData);

		const allExam = await getExamData(data[0].ownerId);
		setOnScreenArray(allExam?.examList);
		console.log('fetchAllExamData res:', allExam.examList);

		const ccmResp = await fetchCurriculum(data[0].ownerId)
		setContentArray(ccmResp)
		console.log('fetchCurriculum res:', ccmResp);

		const CourseCont: any = await fetchSignedContents(data[0].ownerId)
		setCourseContArray(CourseCont)
		console.log('CourseCont res:', CourseCont);

	}
	console.log("ELibraryArray--", ELibraryArray)
	console.log("courseContArray 1st one--->", courseContArray)


	useEffect(() => {
		if (ELibraryArray && ELibraryArray.length > 0) {
			let totalFileSize = 0;
			ELibraryArray.forEach((item: any) => {
				if (item?.file?.fileSize) {
					totalFileSize += item.file.fileSize;
				}
			});
			setELibrarySize(Math.round((totalFileSize) / (1024 * 1024)));
			console.log(`Total File Size library: ${totalFileSize} bytes`);
		} else {
			console.log("ELibraryArray is empty or undefined.");
		}

		console.log("courseContArray--->", courseContArray)

		if (courseContArray && courseContArray.length > 0) {
			let totalFileSize = 0;
			courseContArray.forEach((item: any) => {
				if (item?.contentlength) {
					totalFileSize += item?.contentlength;
				}
			});
			setcourseContSize(Math.round((totalFileSize) / (1024 * 1024)));
			console.log(`Total File Size course content: ${(totalFileSize) / (1024 * 1024)} MB`);
		} else {
			console.log("course content is empty or undefined.");
		}

		if (anounceArray && anounceArray.length > 0) {
			let totalFileSize = 0;
			anounceArray.forEach((item: any) => {
				if (item?.fileSize) {
					totalFileSize += item?.fileSize;
				}
			});
			setAnounceSize(Math.round((totalFileSize) / (1024 * 1024)));
			console.log(`Total File Size anouncement: ${totalFileSize} bytes`);
		} else {
			console.log("anounceArray is empty or undefined.");
		}

		if (degreeMasterArray && degreeMasterArray.length > 0) {
			let totalFileSize = 0;
			degreeMasterArray.forEach((item: any) => {
				if (item?.syllabusDocUUID?.fileSize) {
					totalFileSize += item.syllabusDocUUID.fileSize;
				}
			});
			const newSize = Math.round(totalFileSize / (1024 * 1024));
			setDegreeMasterSize(newSize);
			console.log(`Total File Size for degree: ${newSize} MB`);
		} else {
			console.log("degreeMasterArray is empty or undefined.");
		}

		if (gdArray && gdArray.length > 0) {
			let totalFileSize = 0;
			gdArray.forEach((item: any) => {
				item?.uploadedFiles?.map((file: any) => {
					if (file?.fileSize) {
						totalFileSize += file?.fileSize;
					}
				})
			});
			const newSize = Math.round(totalFileSize / (1024 * 1024));
			setGdSize(newSize);
			console.log(`Total File Size for GD: ${newSize} MB`);
		} else {
			console.log("gdArray is empty or undefined.");
		}

		if (contentArray && contentArray.length > 0) {
			let totalFileSize = 0;
			contentArray.forEach((item: any) => {
				item?.courses?.map((file: any) => {
					if (file?.courseId?.syllabusDocUUID?.fileSize) {
						totalFileSize += file?.courseId?.syllabusDocUUID?.fileSize;
					}
				})
			});
			const newSize = Math.round(totalFileSize / (1024 * 1024));
			setContentSize(newSize);
			console.log(`Total File Size for content: ${newSize} MB`);
		} else {
			console.log("contentArray is empty or undefined.");
		}

		if (assignArray && assignArray.length > 0) {
			let totalFileSize = 0;
			assignArray.forEach((item: any) => {
				item?.assignment?.taskDocs?.map((file: any) => {
					if (file?.fileSize) {
						totalFileSize += file?.fileSize;
					}
				})
			});

			let totalFileAnsSize = 0;

			assignArray.forEach((item: any) => {
				item?.students?.map((el: any) => {
					el.uploadedAnswerDocs?.map((file: any) => {
						if (file?.fileSize) {
							totalFileAnsSize += file?.fileSize;
						}
					})
				})
			});

			const newSize = Math.round(totalFileSize / (1024 * 1024));
			const newAnsSize = Math.round(totalFileAnsSize / (1024 * 1024));
			const totalAssignmentSize = newSize + newAnsSize;
			setAssignSize(totalAssignmentSize);
			setAnsSize(newAnsSize)
			console.log(`Total File Size for assign: ${totalAssignmentSize} MB`);
		} else {
			console.log("assignArray is empty or undefined.");
		}

		if (assetArray && assetArray.length > 0) {
			let docFileSize = 0;
			assetArray.forEach((item: any) => {
				if (item?.documentData?.fileSize) {
					docFileSize += item.documentData.fileSize;
				}
			});

			let imgFileSize = 0;
			assetArray.forEach((item: any) => {
				if (item?.imageData?.fileSize) {
					imgFileSize += item.imageData.fileSize;
				}
			});

			let videoData = 0;
			assetArray.forEach((item: any) => {
				if (item?.videoData?.fileSize) {
					videoData += item.videoData.fileSize;
				}
			});


			const totalAssetSize = Math.round((docFileSize + imgFileSize + videoData) / (1024 * 1024));
			setAssetSize(totalAssetSize);

			console.log(`Total File Size for asset: ${totalAssetSize} MB`);
		} else {
			console.log("asset is empty or undefined.");
		}

		if (onScreenArray && onScreenArray.length > 0) {
			let modelAnsFileSize = 0;
			onScreenArray.forEach((item: any) => {
				if (item?.modelAnswerPaper?.fileSize) {
					modelAnsFileSize += item?.modelAnswerPaper?.fileSize;
				}
			});

			let questionFileSize = 0;
			onScreenArray.forEach((item: any) => {
				if (item?.questionPaper?.fileSize) {
					questionFileSize += item?.questionPaper?.fileSize;
				}
			});

			let uploadQueFile = 0;
			onScreenArray.forEach((item: any) => {
				if (item?.uploadedQuestionPaperFormat?.fileSize) {
					uploadQueFile += item?.uploadedQuestionPaperFormat?.fileSize;
				}
			});

			let uploadAnsFile = 0;
			onScreenArray.forEach((item: any) => {
				item?.uploadedAnswerSheets?.map((file: any) => {
					if (item?.file?.fileSize) {
						uploadAnsFile += item?.file?.fileSize;
					}
				})
			});

			const totalEvaluationFileSize = Math.round((modelAnsFileSize + questionFileSize + uploadQueFile + uploadAnsFile) / (1024 * 1024));
			setOnScreenSize(totalEvaluationFileSize);

			console.log(`Total File Size for evaluation: ${totalEvaluationFileSize} MB`);
		} else {
			console.log("asset is empty or undefined.");
		}

		if (courseBundleArray && courseBundleArray.length > 0) {
			let totalModuleSize = 0;
			courseBundleArray.forEach((item: any) => {
				item?.includedCourses?.map((include: any) => {
					include?.modules?.map((module: any) => {
						if (module?.fileSizeChapterSummary && module?.fileSizeContentSummary && module?.fileSizeMindMap) {
							totalModuleSize = module?.fileSizeChapterSummary + module?.fileSizeContentSummary + module?.fileSizeMindMap;
						}
					})
				})
			});

			let totalModuleElemSize = 0;
			courseBundleArray.forEach((item: any) => {
				item?.includedCourses?.map((include: any) => {
					include?.modules?.map((module: any) => {
						module?.moduleElements?.map((elem: any) => {
							if (elem?.uuidData?.fileSize) {
								totalModuleElemSize += elem?.uuidData?.fileSize;
							}

						})

					})
				})
			});

			let totalSyllabusSize = 0;
			courseBundleArray.forEach((item: any) => {
				item?.includedCourses?.map((include: any) => {
					if (include?.syllabus?.fileSize) {
						totalSyllabusSize += include?.syllabus?.fileSize;
					}
				})
			});

			console.log("totalModuleSize-->>", (totalModuleSize) / (1024 * 1024))
			console.log("totalSyllabusSize-->>", (totalSyllabusSize) / (1024 * 1024))
			console.log("totalModuleElemSize-->>", (totalModuleElemSize) / (1024 * 1024))

			let totalCourseSize = 0;
			totalCourseSize = Math.round((totalModuleSize + totalSyllabusSize + totalModuleElemSize) / (1024 * 1024));
			setcourseBundleSize(totalCourseSize)
			console.log(`Total File Size course : ${totalCourseSize} MB`);
		} else {
			console.log("course bundle is empty or undefined.");
		}
	}, [ELibraryArray, degreeMasterArray, gdArray, assignArray, assetArray, anounceArray, courseBundleArray, onScreenArray, contentArray, courseContArray]);
	console.log("ansSize---", ansSize)


	const handleView = (row: any) => {
		console.log('View:', row);
		dispatch(setViewData(row));
		if (row?.id == 1) {
			dispatch(setRowData(degreeMasterArray));
			history.push("/View-file-storage", {})
		} else if (row?.id == 2) {
			dispatch(setRowData(contentArray));			
			history.push("/Degree-curriculum", {})
		} else if (row?.id == 3) {
			dispatch(setRowData(gdArray));			
			history.push("/Group-Discussion-Size", {})
		} else if (row?.id == 4) {
			dispatch(setRowData(onScreenArray));			
			history.push("/On-ScreenSize", {})
		} else if (row?.id == 5) {
			dispatch(setRowData(courseContArray));
			history.push("/course-content-size", {})
		} else if (row?.id == 6) {
			dispatch(setRowData(courseBundleArray));			
			history.push("/College-Based-Size", {})
		} else if (row?.id == 7) {
			dispatch(setRowData(assignArray));			
			history.push("/Assignment-Size", {})
		} else if (row?.id == 8) {
			dispatch(setRowData(ELibraryArray));			
			history.push("/Digital-Library-Size", {})
		} else if (row?.id == 9) {
			dispatch(setRowData(assetArray));			
			history.push("/Asset-Manage-Size", {})
		} else if (row?.id == 10) {
			dispatch(setRowData(anounceArray));			
			history.push("/Announcement-Size", {})
		} else if (row?.id == 11) {
			dispatch(setRowData(contentArray));			
			history.push("/Lesson-Plan-Size", {})
		}
	};


	const rows = [
		{ id: 1, name: 'Degree Courses-Master Course', contentSize: `${degreeMasterSize} MB` },
		{ id: 2, name: 'Degree Courses-Master Content', contentSize: `${contentSize} MB` },
		{ id: 3, name: 'Group Discussion', contentSize: `${gdSize} MB` },
		{ id: 4, name: 'Onscreen Evaluation', contentSize: `${onScreenSize} MB` },
		{ id: 5, name: 'Course Content', contentSize: `${courseContSize} MB` },
		{ id: 6, name: 'College Based Course', contentSize: `${courseBundleSize} MB` },
		{ id: 7, name: 'Assignment', contentSize: `${assignSize} MB` },
		{ id: 8, name: 'Digital Library', contentSize: `${ELibrarySize} MB` },
		{ id: 9, name: 'Asset Management', contentSize: `${assetSize} MB` },
		{ id: 10, name: 'Announcement', contentSize: `${anounceSize} MB` },
		{ id: 11, name: 'Lesson Plan- Master Content', contentSize: `${contentSize} MB` },
	];

	const totalFileSize = degreeMasterSize + contentSize + gdSize + onScreenSize + courseBundleSize + assignSize + ELibrarySize + assetSize + anounceSize + contentSize + courseContSize;


	const columns = [
		{ field: 'id', headerName: 'Sr.No.', width: 150 },
		{ field: 'name', headerName: 'Module Name', width: 400 },
		{ field: 'contentSize', headerName: 'Content Size', width: 300 },

		{
			field: 'action',
			headerName: 'Action',
			width: 200,
			renderCell: (params: any) => (
				<>
					<Tooltip
						title="View File Storage"
					>
						<IconButton
							color="primary"
							onClick={() => handleView(params.row)}
							style={{ padding: '4px', margin: '0' }}
						>
							<Visibility />
						</IconButton>
					</Tooltip>

				</>
			)
		},
	];
	useEffect(() => {
		getData()

	}, []);

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: "80vh", width: "100%" }}>
					<Container style={{ width: "100%" }}>
						<div style={{ height: 400, width: '100%' }}>

							<Box
								bgcolor="#4C8BF5"
								padding="20px 30px"
								marginBottom="30px"
								position="relative"
								borderRadius="5px"
								color='#fff'
							>
								<Grid item container>
									<Grid item sm={8}>
										<Box style={{ height: '100%' }}>
											<Grid
												container
												direction="row"
												alignItems="center"
												justify="center"
												style={{ height: '100%' }}
											>
												<Grid item xs={12}>
													<Typography className={classes.title}>
														My Storage Usage
													</Typography>
													{degreeMasterSize && contentSize && gdSize && onScreenSize && courseBundleSize && assignSize && ELibrarySize && assetSize && anounceSize && contentSize && <Typography variant="h6" >Total Storage (MB) : {totalFileSize} MB </Typography>}
													<Box style={{ display: "flex" }}>
														<Button style={{ textTransform: "capitalize", backgroundColor: "#fff", color: "#4C8BF5", marginTop: 10, marginRight: "10px" }} disableElevation variant="contained" size="small" onClick={() => { history.goBack() }} >Back</Button>

													</Box>
												</Grid>
											</Grid>
										</Box>
									</Grid>
								</Grid>
							</Box>

							{/* <Card
								className={styles.card}
								variant="outlined"
								style={{ width: "100%" }}
							>
								<CardHeader
									title="My Storage Usage"
									titleTypographyProps={{ varaint: "h1" }}
								/>
								<CardContent>
									{degreeMasterSize && contentSize && gdSize &&onScreenSize && courseBundleSize && assignSize && ELibrarySize && assetSize && anounceSize && contentSize && <Typography variant="h6" >Total Storage in MB : {totalFileSize} MB </Typography>}
								</CardContent>
							</Card> */}

							<DataGrid
								rows={rows}
								columns={columns}
								pagination
								components={{
									Toolbar: GridToolbar
								}}
								autoPageSize={false}
								checkboxSelection
								disableColumnMenu
								disableSelectionOnClick
								pageSize={5} />
						</div>
					</Container>
				</div>
			</MiniDrawer>

		</>

	);
};
const mapStateToProps = (state: RootState) => ({
	RowData: state.adminReducer.rowData as any,
});
export default connect(mapStateToProps)(FileStorage);


function enqueueSnackbar(arg0: string, arg1: { variant: string; }) {
	throw new Error('Function not implemented.');
}
// function enqueueSnackbar(message: any, arg1: { variant: string; }) {
// 	throw new Error('Function not implemented.');
// }

