import React, { useState, useEffect, FunctionComponent, } from 'react';
import {
  Box, Container, Grid, Input, Typography, CssBaseline, Card, CardContent, CardHeader, IconButton, Select, MenuItem, Button, TextField, Checkbox, ListItemText, darken, makeStyles, InputAdornment, lighten, createStyles, Theme, Tooltip
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
// import { isValidPhoneNumber } from 'libphonenumber-js';

import MiniDrawer from "../../../common/components/SideDrawer"
import { CustomUser, } from '../../../common/contracts/dashboard';

import { RootState } from '../../../redux/store';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createCollegeGroup, deleteCollegeBasedDoc, fetchAnnouncements, fetchAssets, fetchAssignedAssignmentNew, fetchCurriculum, fetchEBook, fetchMasterCourse, getCourseBundles, getExamData, getGdFormBy_Id, getOrgList, getUser, updateCollegeGroup, updateUserPreferences } from '../../../../api/dashboard';
import { ArrowBack, Delete, Visibility } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { fontOptions } from '../../../../theme';
import CancelIcon from '@material-ui/icons/Cancel';
import { log } from 'console';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import ConfirmationModal from '../../../common/components/confirmation_modal';

interface Props extends RouteComponentProps {
  user: CustomUser
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography_1: {
      color: '#4285F4'
    },
    typography_2: {
      color: 'red',
      paddingTop: '160px'
    },
    title: {
      fontSize: fontOptions.size.large
    },
    addAssi: {
      '& button': {
        color: '#4C8BF5',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          backgroundColor: darken('#FFFFFF', 0.1),
        }
      },
      paddingTop: '12px'
    },
    formControl: {
      margin: "5px",
      minWidth: 120,
    },
    helperText: {
      fontSize: fontOptions.size.small,
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.6)'
    },
    heading: {
      margin: '0',
      fontWeight: fontOptions.weight.bold,
      fontSize: fontOptions.size.medium,
      letterSpacing: '1px',
      color: '#212121',
    },
    label: {
      fontWeight: fontOptions.weight.bold,
      fontSize: fontOptions.size.small,
    },
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      height: "100%",
    },
    highlight:
      theme.palette.type === "light"
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  }
  ));

const CollegeBasedSize: FunctionComponent<Props> = ({ user, history, location }) => {
  const styles = useStyles();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [redirectTo, setRedirectTo] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false);
  const [collegeGroupName, setCollegeGroupName] = useState("")
  const [collegesList, setCollegesList] = useState<any>([])
  const [selectedColegeList, setSelectedCollegeList] = useState<any>([])
  const [ELibraryArray, setELibraryArray] = useState<any>();
  const [degreeMasterArray, setDegreeMasterArray] = useState<any>();
	const [gdArray, setGdArray] = useState<any>();
	const [assignArray, setAssignArray] = useState<any>();
	const [assetArray, setAssetArray] = useState<any>();
	const [anounceArray, setAnounceArray] = useState<any>();
	const [courseBundleArray, setcourseBundleArray] = useState<any>();
	const [onScreenArray, setOnScreenArray] = useState<any>();
  const [contentArray, setContentArray] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [selectedDoc, setSelectedDoc] = useState<any>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);


  const State: any = location?.state;
  let data = State?.data

  const rowData = useSelector((state: RootState) => state.adminReducer.rowData);
  console.log("rowData group--", rowData)

  const viewData = useSelector((state: RootState) => state.adminReducer.viewData);
  console.log("viewData GROP--", viewData)

  const orgData1 = useSelector((state: RootState) => state.adminReducer.data);



  useEffect(() => {
    console.log("orgData1--", orgData1)
    console.log("rowData--", rowData)


    if (data) {
      setCollegeGroupName(data?.groupName)
      let tempCollegeList: any = collegesList?.filter((college: any) => data?.collegeOwnerIdsArr?.includes(college?.ownerId))
      setSelectedCollegeList(tempCollegeList)
    }
  }, [collegesList])

  const handleDelete = (row: any) => {
    console.log("row for row gd---",row)
    setOpenConfirmationModal(true)
    console.log("selectedRow deleteId college based--", row?.docType)
    console.log("setSelectedDoc deleteId college based--", row?.docUuid)

    setSelectedRow(row?.docType)
    setSelectedDoc(row?.docUuid)
}
  console.log("selectedRow college based--", selectedRow)
  console.log("selectedDoc college based--", selectedDoc)

  

  const handleRemoveFile = async () => {
    try {
        var res = deleteCollegeBasedDoc(selectedDoc, selectedRow ,orgData1[0].ownerId);
        setOpenConfirmationModal(false)
        enqueueSnackbar("File Storage Deleted successfully", { variant: "success" })
        setLoading(false)
      console.log('delete response', res);

    } catch (error) {
      console.log('error in delete document', error);
    }
  };
  useEffect(() => {
		getData()
	}, []);

  const getData = async () => {

		const courseBundlesData = await getCourseBundles();
		setcourseBundleArray(courseBundlesData);
		console.log('courseBundlesData:', courseBundlesData);

		const allExam = await getExamData(orgData1[0].ownerId);
		setOnScreenArray(allExam?.examList);
		console.log('fetchAllExamData res:', allExam.examList);

		const ccmResp = await fetchCurriculum(orgData1[0].ownerId)
		setContentArray(ccmResp)
		console.log('fetchCurriculum res:', ccmResp);

  }

    const createRows = (rowData: any) => {
    if (!Array.isArray(rowData)) return [];
  
    let idCounter = 1;
  
    return rowData.reduce((acc, element) => {
      const { syllabus, modules } = element;
  
        console.log("syllabus--", element, "modules---", acc)
        let courses = element?.includedCourses?.map((course: any) => (course?.syllabus))
        console.log("courses---", courses)
        
        let module = element?.includedCourses?.map((course: any) => (course?.modules[0]))
        console.log("module below--",module)

        // let moduleElement = element?.includedCourses?.map((course: any) => (course?.modules.map((ele:any)=>(ele?.moduleElements)).flat()))
        let moduleElement = element?.includedCourses?.map((course: any) => 
          course?.modules.map((ele: any) => ele?.moduleElements)).flat();
      console.log("moduleElement below--", moduleElement)


        let firstElement = moduleElement[0];
      console.log("1st moduleElement below--", firstElement)
        
        let modElem = firstElement?.map((el: any) => (el?.uuidData?.fileSize))
        console.log("modElem--",modElem)

        if (courses[0].docLocation && courses[0].fileSize) {
            const docLocationParts = courses[0].docLocation.split('/');
          const docName = docLocationParts[docLocationParts.length - 1];
          const docUuid = courses[0].uuid;
          const docType = courses[0].docType;

            console.log("docName--",docName)
        acc.push({
          id: idCounter++,
          name: docName,
          contentSize: `${Math.round(courses[0].fileSize / (1024 * 1024))} MB`,
          docUuid,
          docType
        });
        }
        
        if (firstElement) {
            console.log("inside firstElement-----");
            firstElement.forEach((el: any) => {
                if (el?.uuidData.docLocation && el?.uuidData.fileSize) {
                  console.log("inside second if-----");
                    const docLocationParts = el.uuidData.docLocation.split('/');
                  const docName = docLocationParts[docLocationParts.length - 1];
                  const docUuid = el?.uuidData?.uuid;
                  const docType = el?.uuidData?.docType;


                    console.log("docName module--", docName);
                    
                    acc.push({
                        id: idCounter++,
                        name: docName,
                      contentSize: `${Math.round(el.uuidData.fileSize / (1024 * 1024))} MB`,
                      docUuid,
                      docType
                    });
                }
            });
        }
  
    //   if (Array.isArray(modules)) {
    //     modules[0].forEach((module:any) => {
    //       if (module.fileSizeChapterSummary) {
    //         acc.push({
    //           id: idCounter++,
    //           name: 'fileSizeChapterSummary',
    //           contentSize: `${Math.round(module.fileSizeChapterSummary / (1024 * 1024))} MB`
    //         });
    //       }
    //       if (module.fileSizeContentSummary) {
    //         acc.push({
    //           id: idCounter++,
    //           name: 'fileSizeContentSummary',
    //           contentSize: `${Math.round(module.fileSizeContentSummary / (1024 * 1024))} MB`
    //         });
    //       }
    //       if (module.fileSizeMindMap) {
    //         acc.push({
    //           id: idCounter++,
    //           name: 'fileSizeMindMap',
    //           contentSize: `${Math.round(module.fileSizeMindMap / (1024 * 1024))} MB`
    //         });
    //       }
    //       if (Array.isArray(module.moduleElements)) {
    //         module.moduleElements.forEach((moduleElement:any) => {
    //           if (moduleElement.uuidData.docLocation && moduleElement.fileSize) {
    //             acc.push({
    //               id: idCounter++,
    //               name: moduleElement.uuidData.docLocation,
    //               contentSize: `${Math.round(moduleElement.uuidData.fileSize / (1024 * 1024))} MB`
    //             });
    //           }
    //         });
    //       }
    //     });
    //   }
  
      return acc;
    }, []);
  };
  
  
    const rows = createRows(rowData);
  
  console.log("rows------", rows);
  
  
  

  const columns = [
    { field: 'id', headerName: 'Sr.No.', width: 150 },
    { field: 'name', headerName: 'File Name', width: 400 },
    { field: 'contentSize', headerName: 'File Size', width: 300 },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params: any) => (
        <>
          <Tooltip title="Delete File">
          <IconButton
            color="primary"
            onClick={() => handleDelete(params.row)}
          >
            <Delete />
            </IconButton>
          </Tooltip>

        </>
      )
    },
  ];


  return (
    <>
      <CssBaseline />
      <MiniDrawer>
        <Container style={{ width: '100%' }}>
          <Box
            bgcolor="#4C8BF5"
            padding="20px 30px"
            marginBottom="30px"
            position="relative"
            borderRadius="5px"
            color='#fff'
          >
            <Grid item container>
              <Grid item sm={8}>
                <Box style={{ height: '100%' }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ height: '100%' }}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.title}>
                        View File Storage
                      </Typography>
                      <Box style={{ display: "flex" }}>
                        <Button style={{ textTransform: "capitalize", backgroundColor: "#fff", color: "#4C8BF5", marginTop: 10, marginRight: "10px" }} disableElevation variant="contained" size="small" onClick={() => { history.goBack() }} >Back</Button>

                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box style={{ backgroundColor: "#fff", borderRadius: 8, padding: 24, paddingLeft: 32 }}>
            <Grid xs={12} sm={12} md={8} lg={6}>
              <Typography style={{ fontWeight: "bold", marginBottom: 8, fontSize: "20px" }}>Module Name :  {viewData?.name}</Typography>

              <Typography style={{ fontWeight: "bold", marginBottom: 8, fontSize: "20px" }}>File Storage : {viewData?.contentSize}</Typography>

            </Grid>
            <Grid xs={12} style={{ marginTop: '20px' }}>
              <hr style={{ borderTop: '2px dashed #B0B1B3', width: '100%', height: '0px', borderLeft: '0', borderRight: '0', borderBottom: '0' }}></hr>
            </Grid>

            <div style={{ height: "80vh", width: "100%" }}>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pagination
                  components={{
                    Toolbar: GridToolbar
                  }}
                  autoPageSize={false}
                  checkboxSelection
                  disableColumnMenu
                  disableSelectionOnClick
                  pageSize={5}
                />
              </div>
            </div>
            
            <ConfirmationModal
              header="Delete File storage"
                helperText="Are you sure you want to delete?"
                openModal={openConfirmationModal}
                onClose={() => {setOpenConfirmationModal(false)}}
                handleDelete={handleRemoveFile}
			/>
          </Box>
        </Container>
      </MiniDrawer >
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  user: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(CollegeBasedSize);


