import React, { FunctionComponent, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { 
  List, ListItem, ListItemAvatar, Avatar, IconButton, ListItemText, ListItemSecondaryAction,
  Typography, Box, Accordion, AccordionDetails, AccordionSummary
} from '@material-ui/core';
import { DropzoneAreaProps } from 'material-ui-dropzone';
//import { Box, Typography } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ImageIcon from '@material-ui/icons/Image';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import YouTubeIcon from '@material-ui/icons/YouTube';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { fontOptions } from '../../../../../theme';

import { uniqBy } from 'lodash' 

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzoneRoot: {
      borderRadius: '500px',
      width: '100%',
      minHeight: 'auto',
      outline: 'none',
    },

    dropzoneTextContainerRoot: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 6px 0 15px',
    },

    dropzoneText: {
      fontFamily:fontOptions.family,fontSize: fontOptions.size.medium,
      margin: '20px 0',
    },

    dropzoneIcon: {
      background:
        'linear-gradient(90deg, rgb(6, 88, 224) 2.53%, rgb(66, 133, 244) 100%)',
      borderRadius: '100%',
      color: '#FFF',
      padding: '10px',
    },

    contentHeading: {
      margin: '13px 0px',
      color: '#666666',
      fontFamily:fontOptions.family,fontSize: fontOptions.size.small
    },
    heading: {
      fontFamily:fontOptions.family,fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontFamily:fontOptions.family,fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    secondHead: {
      color: '#666666',
      fontFamily:fontOptions.family,fontSize: fontOptions.size.small,
      margin: '0px 5px'
    },
    yMargin: {
      margin: '0px 5px',
      width: '100%'
    }
  })
);

interface SelectProp {
  label: string | undefined;
  value: string | undefined;
}

interface Props extends DropzoneAreaProps {
  files?: File[];
  onRemoveItem?: (file: File, fileIndex: number) => any;
  contenttype?: string;
  fileStructure?: SelectProp[];
}

const UploadedContent: FunctionComponent<Props> = ({
  files = [],
  onRemoveItem,
  contenttype,
  fileStructure,
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState<string | false>(false);

  useEffect(() => {
    setExpanded(false)
  }, [fileStructure, contenttype]);

  const handleChange = (panel: string | undefined) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? (panel ? panel : false) : false);
  };

  const contentIcon = [
    {contentname: 'image', icon: <ImageIcon />},
    {contentname: 'pdf', icon: <PictureAsPdfIcon />},
    {contentname: 'document', icon: <DescriptionIcon />},
    {contentname: 'video', icon: <OndemandVideoIcon />},
    {contentname: 'embed-link', icon: <YouTubeIcon />},
    {contentname: 'quiz', icon: <AssignmentIcon />},
    {contenttype: 'other', icon: <AttachFileIcon />}
  ]

  return (
    <div>
      <Box 
      margin="20px 0 10px"
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      >
        <Box
          component="h3"
          fontFamily={fontOptions.family}
          fontWeight={fontOptions.weight.bold}
          fontSize={fontOptions.size.small}
          line-height="21px"
          color="#666666"
          margin="20px 0"          
        >
          Contents
        </Box>

        {(!fileStructure || fileStructure.length === 0) && (files.length === 0) &&
          <Typography className={classes.secondHead}>
            No Content
          </Typography>
        }

        {(!fileStructure || fileStructure.length === 0) && (files.length > 0) &&
          <List dense={false} className={classes.yMargin}>
            {
              files.map((file, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar>
                        {contentIcon.find(content => content.contentname === contenttype)?.icon}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={file.name}
                      secondary={null}
                    />
                    <ListItemSecondaryAction>
                      {
                        onRemoveItem &&
                        <IconButton edge="end" aria-label="delete">
                        <DeleteIcon onClick={() => onRemoveItem && onRemoveItem(file, index)} />
                      </IconButton>
                      }
                      
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
            }
          </List>
        }

        {(fileStructure && fileStructure.length !== 0) &&
          <div> 
            {uniqBy(fileStructure, 'label').filter(accord => accord.label).map(element => {return(
              <Accordion key={element.value} expanded={expanded === element.value} onChange={handleChange(element.value)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>{element.label}</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  
                </AccordionDetails>
              </Accordion>
            )})}
          </div>
        }
      </Box>
    </div>
  );
};

export default UploadedContent;