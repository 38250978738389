import React, { useState, useEffect, FunctionComponent } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  lighten,
} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import {
  Box,
  Container,
  Grid,
  Button,
  Input,
  Typography,
  FormControlLabel,
  IconButton,
  CssBaseline,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  InputAdornment,
  Toolbar,
  Radio,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { RouteComponentProps, Redirect } from "react-router";
import { connect, useDispatch, useSelector } from "react-redux";

import MiniDrawer from "../../../common/components/SideDrawer";
import { fontOptions } from "../../../common/theme";
import {
  EMAIL_PATTERN,
  PHONE_PATTERN,
} from "../../../common/validations/patterns";
import {
  createAdminUser,
  getAdminData,
  getAdmins,
  getRoles,
  getRolesSecand,
  getUserDetails,
  getUserDetailsForRole,
  getUsers,
} from "../../../../api/dashboard";
import {
  AccountBalance,
  ColorLens,
  Block,
  Clear,
  CreditCard,
  Delete,
  Edit,
  Receipt,
  Search,
  Visibility,
  VisibilityOff,
  ViewList,
  LockOpen,
} from "@material-ui/icons";
import useStyles from "./styles";
import { User, CustomUser } from "../../../common/contracts/dashboard";
import Datagrids, { datagridCellExpand } from "../../components/dataGrid";
import { GridCellParams, GridColDef, GridColumns } from "@material-ui/data-grid";
import {
  setCurrentUser,
  setCustomersList,
} from "../../../redux/actions/adminActions";
import { USER_TYPES, USER_TYPE_VALUES } from "../../../../utilities/constants";
import { RootState } from "../../../redux/store";
import {
  MASTER_PERMISSIONS,
  USER_ROLES,
} from "../../../../utilities/constants";
import Datepickers from "../../components/datepickers";
import ReviewModal from "../../../finance/components/modals/reviewModal";
import { UserDataTable } from "../../components/UserDataTable";
import { compareAsc } from "date-fns";
import AdbIcon from "@material-ui/icons/Adb";

interface Props extends RouteComponentProps {
  customersList: CustomUser[];
  User: CustomUser;
}
interface EnhancedTableToolbarProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setFocused: React.Dispatch<React.SetStateAction<boolean>>;
  maxLength: number;
  placeholder: string;
}

const useToolbarStyles:any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      height: "100%",
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

const EnhancedTableToolbar: FunctionComponent<EnhancedTableToolbarProps> = ({
  searchText,
  setSearchText,
  setFocused,
  maxLength,
  placeholder,
}) => {
  const classes = useToolbarStyles();
  const data = useSelector((state:RootState) => state.adminReducer.data);
		console.log("data for ownerID: for clg ",data)


  return (
    <Toolbar>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft="auto"
      >
        <Box margin="15px 10px 10px 25px">
          <FormControl fullWidth margin="normal">
            <Input
              name="search"
              inputProps={{ inputMode: "search", maxLength: maxLength }}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              placeholder={placeholder}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              endAdornment={
                searchText.trim() !== "" ? (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setSearchText("")}>
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <IconButton disabled size="small">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </FormControl>
          {searchText.trim() !== "" && (
            <Typography
              style={{
                marginTop: "5px",
                fontSize: fontOptions.size.small,
                color: "#666666",
              }}
            >
              Filtered Table using Keyword '{searchText}'
            </Typography>
          )}
        </Box>
      </Box>
    </Toolbar>
  );
};

const EditCustomerRole: FunctionComponent<Props> = ({
  history,
  customersList,
  User,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [redirectTo, setRedirectTo] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  // const [usersList, setUsersList] = useState<any[]>([]);	//crosscheck
  const [selected, setSelected] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [focused, setFocused] = useState(false);
  const [searchBySelection, setSearchBySelection] = useState("mobile");
  const [searchForSelection, setSearchForSelection] = useState(
    USER_TYPES.TUTOR
  );
  const [selectedUserType, setSelectedUserType] = useState(
    USER_TYPE_VALUES.TUTOR
  );
  const [fromDate, setFromDate] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [toDate, setToDate] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 0, 0))
  );
  const [fromNotiDate, setFromNotiDate] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [toNotiDate, setToNotiDate] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 0, 0))
  );

  const [modalType, setModalType] = useState<string | null>(null);
  const [modalData, setModalData] = useState<any[]>(customersList);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [roleList, setRoleList] = useState<any>([]);
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [userData,setUserData]= useState<any[]>([]);




  const styles = useStyles();
  const dispatch = useDispatch();

  var allowedPermissions: string[] = [];
  var loginUserData = JSON.parse(localStorage.getItem("loginUserData") || "{}");
  var loginUserType = "";
  if (
    loginUserData &&
    loginUserData.adminRole &&
    loginUserData.adminRole.permissions
  ) {
    allowedPermissions = loginUserData.adminRole.permissions;
    loginUserType = loginUserData.adminRole.name;
  }

  useEffect(() => {
   

    getRoles()
  }, []);

  let columns :GridColumns  = [
    { field: 'id', headerName: 'S.No' ,flex: 0.7},
    
    { field: 'email', headerName: 'Email ID', type: 'string', renderCell: datagridCellExpand,flex: 1, },
    { field: 'mobile', headerName: 'Mobile No', type: 'string', renderCell: datagridCellExpand,flex: 1, },
    { field: 'statuss', headerName: 'Status', type: 'string', renderCell: datagridCellExpand,flex: 1, },
    {
			//width increased to 300 as all action icons are not visible with 200, btw there are 6-7 action icons are there
			field: 'action', headerName: 'Actions', width: 300,
			flex:1,
			resizable: true,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				const selectedRow = {
					id: params.getValue("id") as number
				}

				const selectedRowDetails = rowsData.find((row, index) => {
					console.log(row)
					return ( index === (selectedRow.id - 1))
				})
				// @ts-ignore
				const buttonSet = buttonData.map((button, index) => {
					return (
						<Tooltip key={index} title={button.title}>
							<IconButton
								onClick={() => {
									button.action(selectedRowDetails as any);
								}}
								size="small"
							>
								{button.icon}
							</IconButton>
						</Tooltip>
					);
				})

				return <div>{buttonSet}</div>;
			}
		}
  ];

const getRoles = async()=>{
 
  try {
    let data = await getRolesSecand()
  console.log("datagetRolesSecand",data); 
  setRoleList([...data])
  } catch (error) {
    console.log("error",error);
    
  }
  
}

  



 
  const handleSearchAll = async () => {
    setLoading(true);
    try {
      var data = [];
      var obj: any = {};
      if (searchBySelection == "mobile" ) {
        obj = getObj();
        data = await getUserDetailsForRole(obj);
      } else if (
        searchBySelection == "email" &&
        EMAIL_PATTERN.test(searchText)
      ) {
        obj = getObj();
        data = await getUserDetailsForRole(obj);
      } 
      
      if(data?.length > 0){
        setUserData([...data])
        let dummyRowArray :any[] = []
        data.map((elem:any,index:any)=>{
          dummyRowArray.push({
            id:index+1,
            mobile:elem?.mobile?elem?.mobile : '-',
            email:elem?.email?elem?.email:'-',
            _id:elem?._id,
            statuss:elem?.status==1?"Active":"In Active",
            ...elem,

          })
        })
        setRowsData([...dummyRowArray])
      }
      enqueueSnackbar(data.length + " record(s) fetched", {
        variant: "success",
      });
      // setUsersList(data);
      // dispatch(setCustomersList(data));
      setModalType("userDetails");
      setModalData(data);
      setOpenModal(true);
      setLoading(false);
    } catch (error) {
      console.log("error",error)
      enqueueSnackbar(error.response?.data.message, { variant: "warning" });
      setLoading(false);
      if (
        error.response?.status === 401 &&
        error.response?.data.message !== "TokenExpiredError"
      ) {
        // setRedirectTo("/login");
      }
    }
  };

  const getObj = () => {
    var obj: any = {
      userType:
        selectedUserType === USER_TYPE_VALUES.ORG_TUTOR
          ? USER_TYPE_VALUES.TUTOR
          : selectedUserType,
    };
    var commonObj = {
      $regex: searchText,
      $options: "i",
    };
    var query: any = {};
    if (searchBySelection == "mobile") {
      query["mobile"] = commonObj;
    } else if (searchBySelection == "name") {
      // switch (selectedUserType) {
      //   case USER_TYPE_VALUES.TUTOR:
      //     query[USER_TYPES.TUTOR] = commonObj;
      //     break;
      //   case USER_TYPE_VALUES.ORG_TUTOR:
      //     query[USER_TYPES.TUTOR] = commonObj;
      //     break;
      //   case USER_TYPE_VALUES.ORG:
      //     query[USER_TYPES.ORG] = commonObj;
      //     break;
      //   case USER_TYPE_VALUES.STUDENT:
      //     query[USER_TYPES.STUDENT] = commonObj;
      //     break;
      //   case USER_TYPE_VALUES.PARENT:
      //     query[USER_TYPES.PARENT] = commonObj;
      //     break;
      //   default:
      //     query[USER_TYPES.TUTOR] = commonObj;
      //     break;
      // }
      query["name"] = commonObj;
    } else {
      query["email"] = commonObj;
    }
    obj["query"] = query;

    var mObj = serialize(obj);
    console.log("obj",obj);
    
    return mObj;
  };

  const serialize = (obj: any, prefix?: any) => {
    var str: any = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === "object"
            ? serialize(v, k)
            : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
    return str.join("&");
  };

  function createData(user: any) {
    let name = user[USER_TYPES.TUTOR];
    if (customersList.length > 0) {
      let fRecord: User = customersList[0];
      if (fRecord["tutorName"]) {
        name = user[USER_TYPES.TUTOR];
      } else if (fRecord["organizationName"]) {
        name = user[USER_TYPES.ORG];
      } else if (fRecord["studentName"]) {
        name = user[USER_TYPES.STUDENT];
      } else if (fRecord["parentName"]) {
        name = user[USER_TYPES.PARENT];
      }
    }
    return {
      // name: (user[searchForSelection] || user[USER_TYPES.ORG] || user[USER_TYPES.STUDENT] || user[USER_TYPES.PARENT]),
      name: name,
      mobileNo: user.mobileNo,
      emailId: user.emailId,
      _id: user._id,
      userType:
        selectedUserType === USER_TYPE_VALUES.ORG_TUTOR
          ? USER_TYPE_VALUES.TUTOR
          : selectedUserType,
      ownerId: user.ownerId,
    };
  }

  const rows = customersList.map((user) => createData(user));



 





  const handleViewDetails = (row: CustomUser) => {
    console.log("handleViewDetails",row);
    // if (allowedPermissions.includes(MASTER_PERMISSIONS.updateUserStatus) || loginUserType == USER_ROLES.super) {
    // dispatch(setCurrentUser(row));
    history.push("/edit-customer",{data:row});
    // }
    // else {
    // 	enqueueSnackbar("You don't have permission on this action", { variant: 'info' });
    // }
  };

 

 

  
 

  const getButtonDatas = (userType: string) => {
    const buttonDatas = [
      
      {
        title: "View/Edit Details",
        action: handleViewDetails,
        icon: <Visibility />,
      }
    ];
   
    return buttonDatas;
  };

  const buttonData = getButtonDatas(selectedUserType);

  const setSelection = (selected: any) => {
    const selectedRow = selected.selectionModel.map((index: string) => {
      const row = rows[Number(index) - 1];
      return row.emailId;
    });

    setSelected(selectedRow);
  };

  const gridColumns: GridColDef[] = [
    { field: "id", headerName: "Sl No.", flex: 0.5 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: datagridCellExpand,
    },
    {
      field: "mobileNo",
      headerName: "Mobile",
      flex: 1,
      renderCell: datagridCellExpand,
    },
    {
      field: "emailId",
      headerName: "Email",
      flex: 1,
      renderCell: datagridCellExpand,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params: GridCellParams) => {
        const selectedRow = {
          id: params.getValue("id") as number
        };

        const selectedRowDetails = rowsData.find((row, index) => {
          return (
          
            index === selectedRow.id - 1
          );
        });
console.log("selectedRowDetails",selectedRowDetails)
        const buttonSet = buttonData.map((button, index) => {
          return (
            <Tooltip key={index} title={button.title}>
              <IconButton
                onClick={() => {
                  button.action(selectedRowDetails as any);
                }}
                size="small"
              >
                {button.icon}
              </IconButton>
            </Tooltip>
          );
        });

        return <div>{buttonSet}</div>;
      },
    },
  ];

  if (redirectTo.length > 0) {
    return <Redirect to={redirectTo} />;
  }

  const handleSearchForSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchForSelection(event.target.value);
    switch (event.target.value) {
      case USER_TYPES.TUTOR:
        setSelectedUserType(USER_TYPE_VALUES.TUTOR);
        break;
      case USER_TYPES.ORG:
        setSelectedUserType(USER_TYPE_VALUES.ORG);
        break;
      case USER_TYPES.STUDENT:
        setSelectedUserType(USER_TYPE_VALUES.STUDENT);
        break;
      case USER_TYPES.PARENT:
        setSelectedUserType(USER_TYPE_VALUES.PARENT);
        break;
      case USER_TYPE_VALUES.ORG_TUTOR:
        setSelectedUserType(USER_TYPE_VALUES.ORG_TUTOR);
        break;
      default:
        setSelectedUserType(USER_TYPE_VALUES.TUTOR);
        break;
    }
    setSearchText("");
  };

  const handleSearchBySelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchBySelection(event.target.value);
    setSearchText("");
  };

 
  const getRoleSecand = (userType:any) => {
    if (userType === "ROLE_TUTOR") return "TUTOR";
    if (userType === "ROLE_STUDENT") return "STUDENT";
    if (userType === "ROLE_ADMIN") return "ADMIN";
    if (userType === "ROLE_ORGANIZATION") return "ORGANIZATION";
    if (userType === "ROLE_PARENT") return "PARENT";
    if (userType === "ROLE_ORGANIZATION_TUTOR") return "ORGANIZATION TUTOR";
    if (userType === "ROLE_ORG_ADMIN") return "ORGANIZATION ADMIN";
    return null;
  };
  return (
    <>
      <CssBaseline />
      <MiniDrawer>
        <div style={{ height: "80vh", width: "100%" }}>
          <Container style={{ width: "100%" }}>
            <Grid container>
              {/* <Grid item className={styles.header}>
								<Typography variant="h5" >Search Customers</Typography>
							</Grid> */}
              <Grid item className={styles.header}>
                <Typography variant="h5">
                  {localStorage.getItem("welcomeMsg") || ""}
                </Typography>
              </Grid>
              <Card
                className={styles.card}
                variant="outlined"
                style={{ width: "100%" }}
              >
                <CardHeader
                  title="Search for"
                  titleTypographyProps={{ varaint: "h5" }}
                />
                <CardContent>
                  {roleList?.length > 0 && roleList.map((elem:any)=>  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchForSelection === elem?.name}
                        onChange={handleSearchForSelection}
                        value={elem?.name}
                        name={elem?.name}
                        color="default"
                      />
                    }
                    label={getRoleSecand(elem?.name)}
                  />
                  )
                }
                 

                  {/* <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={
                          searchForSelection === USER_TYPE_VALUES.ORG_TUTOR
                        }
                        onChange={handleSearchForSelection}
                        value={USER_TYPE_VALUES.ORG_TUTOR}
                        name={USER_TYPE_VALUES.ORG_TUTOR}
                        color="default"
                      />
                    }
                    label="Org Tutor"
                  />

                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchForSelection === USER_TYPES.ORG}
                        onChange={handleSearchForSelection}
                        value={USER_TYPES.ORG}
                        name={USER_TYPES.ORG}
                        color="default"
                      />
                    }
                    label="Institute"
                  />
                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchForSelection === USER_TYPES.STUDENT}
                        onChange={handleSearchForSelection}
                        value={USER_TYPES.STUDENT}
                        name={USER_TYPES.STUDENT}
                        color="default"
                      />
                    }
                    label="Students"
                  />
                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchForSelection === USER_TYPES.PARENT}
                        onChange={handleSearchForSelection}
                        value={USER_TYPES.PARENT}
                        name={USER_TYPES.PARENT}
                        color="default"
                      />
                    }
                    label="Guardians"
                  /> */}
                </CardContent>
              </Card>

              <Card
                className={styles.card}
                variant="outlined"
                style={{ width: "100%" }}
              >
                <CardHeader
                  title="Search by"
                  titleTypographyProps={{ varaint: "h5" }}
                />
                <CardContent>
                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchBySelection === "mobile"}
                        onChange={handleSearchBySelection}
                        value="mobile"
                        name="mobile"
                        color="default"
                      />
                    }
                    label="Mobile"
                  />
                  {/* <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchBySelection === "name"}
                        onChange={handleSearchBySelection}
                        value="name"
                        name="name"
                        color="default"
                      />
                    }
                    label="Name"
                  /> */}
                  <FormControlLabel
                    value="end"
                    control={
                      <Radio
                        checked={searchBySelection === "email"}
                        onChange={handleSearchBySelection}
                        value="email"
                        name="email"
                        color="default"
                      />
                    }
                    label="Email"
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{ margin: 10 }}
                    onClick={handleSearchAll}
                    disabled={searchBySelection ? false : true}
                  >
                    Search
                  </Button>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{ margin: 10 }}
                    onClick={handleSearchAllDetails}
                    disabled={searchBySelection ? false : true}
                  >
                    Search All
                  </Button> */}

                  <Grid item xs={12} justify="flex-start">
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="flex-start"
                      padding="0px"
                    >
                      <EnhancedTableToolbar
                        searchText={searchText}
                        setSearchText={setSearchText}
                        setFocused={setFocused}
                        maxLength={searchBySelection == "mobile" ? 15 : 50}
                        placeholder={
                          searchBySelection == "mobile"
                            ? "No special characters allowed"
                            : "Search"
                        }
                      />
                    </Box>
                  </Grid>

                  
                </CardContent>
              </Card>

              {customersList && customersList.length > 0 && (
                <Grid item xs={12} md={12} style={{ margin: "10px 0px" }}>
                  <Typography variant="h6" align="right">
                    Recent search results
                  </Typography>
                </Grid>
              )}
              {/* <Box bgcolor='white' width='100%'>  */}
              {/* {
								!modalType ?
									<Datagrids key={searchText} gridRows={gridRows} gridColumns={gridColumns} setSelection={setSelection} /> :
									modalData ? <UserDataTable key={'key' + selectedUserType + fromDate + toDate} gridData={modalData} reportType={selectedUserType} buttonData={buttonData} /> : null
							} */}
              {/* <UserDataTable
                key={"key" + selectedUserType + fromDate + toDate}
                gridData={modalData}
                reportType={selectedUserType}
                buttonData={buttonData}
                userType={
                  selectedUserType === USER_TYPE_VALUES.ORG_TUTOR
                    ? USER_TYPE_VALUES.TUTOR
                    : selectedUserType
                }
              /> */}
              <Datagrids gridRows={rowsData} gridColumns={columns} showToolBar={true} disableCheckbox={true} />
              {/* </Box> */}
            </Grid>
          </Container>
        </div>
        {/* {
					!modalType ? '' :
						<ReviewModal
							openModal={openModal}
							onClose={() => { setOpenModal(false); setModalType(null) }}
							viewData={modalData}
							activeType={modalType}
						/>
				} */}
      </MiniDrawer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  customersList: state.adminReducer.customersList as CustomUser[],
  User: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(EditCustomerRole);
