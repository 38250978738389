export interface CurrencyType {
    code: string;
    expansion: string;
    country: string[];
}
  
export const currencies: CurrencyType[] = [
    {code: "AED", expansion:"United Arab Emirates Dirham", country: ["AE"]},
    {code: "AFN", expansion:"Afghan Afghani", country: ["AF"]},
    {code: "ALL", expansion:"Albanian Lek", country: ["AL"]},
    {code: "AMD", expansion:"Armenian Dram", country: ["AM"]},
    {code: "AOA", expansion:"Angolan Kwanza", country: ["AO"]},
    {code: "ARS", expansion:"Argentine Peso", country: ["AR"]},
    {code: "AUD", expansion:"Australian Dollar", country: ["AU"]},
    {code: "AWG", expansion:"Aruban Florin", country: ["AW"]},
    {code: "BDT", expansion:"Bangladeshi Taka", country: ["BD"]},
    {code: "BHD", expansion:"Bahraini Dinar", country: ["BH"]},
    {code: "BTN", expansion:"Bhutanese Ngultrum", country: ["BT"]},
    {code: "CAD", expansion:"Canadian Dollar", country: ["CA"]},
    {code: "CHF", expansion:"Swiss Franc", country: ["CH"]},
    {code: "EUR", expansion:"Euro", country: ["AT", "BE", "CY", "EE", "FI", "FR", "DE", "GR", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PT", "SK", "SI", "ES"]},
    {code: "GBP", expansion:"British Pound Sterling", country: ["GB"]},
    {code: "IDR", expansion:"Indonesian Rupiah", country: ["ID"]},
    {code: "INR", expansion:"Indian Rupee", country: ["IN"]},
    {code: "JPY", expansion:"Japanese Yen", country: ["JP"]},
    {code: "KWD", expansion:"Kuwaiti Dinar", country: ["KW"]},
    {code: "KZT", expansion:"Kazakhstani Tenge", country: ["KZ"]},
    {code: "LKR", expansion:"Sri Lankan Rupee", country: ["LK"]},
    {code: "MMK", expansion:"Myanma Kyat", country: ["MM"]},
    {code: "MNT", expansion:"Mongolian Tugrik", country: ["MN"]},
    {code: "MYR", expansion:"Malaysian Ringgit", country: ["MY"]},
    {code: "NOK", expansion:"Norwegian Krone", country: ["NO"]},
    {code: "NPR", expansion:"Nepalese Rupee", country: ["NP"]},
    {code: "NZD", expansion:"New Zealand Dollar", country: ["NZ"]},
    {code: "OMR", expansion:"Omani Rial", country: ["OM"]},
    {code: "PHP", expansion:"Philippine Peso", country: ["PH"]},
    {code: "PKR", expansion:"Pakistani Rupee", country: ["PK"]},
    {code: "QAR", expansion:"Qatari Rial", country: ["QA"]},
    {code: "SAR", expansion:"Saudi Riyal", country: ["SA"]},
    {code: "SEK", expansion:"Swedish Krona", country: ["SE"]},
    {code: "SGD", expansion:"Singapore Dollar", country: ["SG"]},
    {code: "THB", expansion:"Thai Baht", country: ["TH"]},
    {code: "TJS", expansion:"Tajikistani Somoni", country: ["TJ"]},
    {code: "USD", expansion:"United States Dollar", country: ["US"]},
    {code: "UZS", expansion:"Uzbekistan Som", country: ["UZ"]},
    {code: "VND", expansion:"Vietnamese Dong", country: ["VN"]},
    {code: "ZAR", expansion:"South African Rand", country: ["ZA"]},
];