import { ReactType } from "react"
import { OrgMgmt, TutorMgmt } from "./modules/orgMgmt/containers/index"
import SupervisedUserCircleRoundedIcon from '@material-ui/icons/SupervisedUserCircleRounded';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import Login from "./modules/auth/containers/login";
import Dashboard from "./modules/dashboard/containers/dashboard";
import CreateUser from "./modules/dashboard/containers/manageUsers/createUser";
import ForgotPassword from "./modules/auth/containers/forgotPassword";
import CreateRole from "./modules/dashboard/containers/rolesAndPermissions/createRole";
import ResetPassword from "./modules/dashboard/containers/manageUsers/resetPassword";
import EditUser from "./modules/dashboard/containers/manageUsers/editUser";
import SetPassword from "./modules/auth/containers/setPassword";
import AssignRole from "./modules/dashboard/containers/manageUsers/assignRole";
import ViewEditPermissions from "./modules/dashboard/containers/rolesAndPermissions/ViewEditPermissions";
import ViewSearchAdmin from "./modules/dashboard/containers/manageUsers/viewSearchAdmin";
import SearchCustomer from "./modules/dashboard/containers/customerManagement/searchCustomer";
import EditCustomerRole from "./modules/dashboard/containers/customerManagement/editCutomersrole";

import ManageKYC from "./modules/dashboard/containers/customerManagement/manageKYC";
import ChangeBankAccount from "./modules/dashboard/containers/customerManagement/changeBankAccount";
import ViewCustomer from "./modules/dashboard/containers/customerManagement/viewCustomer";
import EditCusRole from "./modules/dashboard/containers/customerManagement/editCusRole";



import BlockUnblockUser from "./modules/dashboard/containers/customerManagement/blockUnblockUser";
import InvoiceDetails from "./modules/dashboard/containers/customerManagement/InvoiceDetails";
import JobPosting from "./modules/career/containers/jobPosting";
import UploadResource from "./modules/webResources/containers/uploadResource";
import LeadsEnquiry from "./modules/leadsManagement/containers/leadsEnquiry";
import Feedback from "./modules/feedback/containers/feedback";
import CreateJob from "./modules/career/containers/createJob";
import FeePackages from "./modules/dashboard/containers/feePackages/feePackages";
import UploadContentFile from "./modules/uploadContent/containers/uploadContentFile";
import Person from './assets/svgs/person1.svg';
import Permission from './assets/svgs/permission.svg';
import Statistics from './assets/svgs/statistics.svg';
import ManageAccounts from './assets/svgs/manage_accounts1.svg';
import Group from './assets/svgs/group.svg';
import ContactPhone from './assets/svgs/contact_phone.svg';
import Website from "./assets/svgs/website1.svg";
import Rupee from "./assets/svgs/rupee.svg";
import PreviousPackages from "./modules/dashboard/containers/feePackages/previousPackages";
import MasterUploads from "./modules/masterData/containers/master_uploads";
import viewPackage from "./modules/dashboard/containers/customerManagement/viewPackage";
import FinanceOperation from "./modules/finance/containers/finance_operations";
import FinanceEdumacData from "./modules/finance/containers/finance_edumacData";
import MasterNotificationTemplates from "./modules/masterData/containers/master_notification_templates";
import MasterQuestions from "./modules/masterData/containers/master_questions";
import EditResources from "./modules/webResources/containers/editResources";
import EditJobPosting from "./modules/career/containers/editJobPosting";
import ImageSorter from "./modules/masterData/containers/image_sorter";
import CertificateUpload from "./modules/masterData/containers/certificates"
import UploadCertificate from "./modules/masterData/containers/uploadcertificate"
import EditMasterQuestions from "./modules/masterData/containers/edit_master_questions"
import ViewLiveClasses from "./modules/liveClasses/containers/viewLiveClasses";
import ViewNotificationTemplates from "./modules/masterData/containers/view_master_notification_templates";
import SendEmail from "./modules/dashboard/containers/customerManagement/sendEmail";
import AddEduAcademyContent from "./modules/edumaticaAcademy/containers/addEduAcademyContent";
// import EditEduAcademyContent from "./modules/edumaticaAcademy/containers/editEduAcademyContent";
import EdumaticaStandardPage from "./modules/edumaticaStandards/containers/edumatica_standards";
import EdumacQuestionBank from "./modules/questionbank/containers/EdumacQuestionBank";
import QBCreate from "./modules/questionbank/containers/QBCreate";
import QBList from "./modules/questionbank/containers/QBList";
import QBQuestions from "./modules/questionbank/containers/QBQuestions";
import QBBulk from "./modules/questionbank/containers/QBBulk";
import ViewExceptionTrackers from "./modules/exceptionTracker/container/viewExceptionTrackers";
import ViewStudentInquiries from "./modules/publicProfiles/containers/viewStudentInquiries";
import ViewTutorPublicProfiles from "./modules/publicProfiles/containers/viewTutorPublicProfiles";
import ViewOtps from "./modules/dashboard/containers/customerManagement/viewOtps";
import ViewAcademicReports from "./modules/dashboard/containers/customerManagement/viewAcademicReports";
import SignUpUsers from "./modules/dashboard/containers/customerManagement/signUpUsers";
import CourseMarketplace from "./modules/dashboard/containers/customerManagement/courseMarketplace";
import CMPBatches from "./modules/dashboard/containers/customerManagement/cmpBatches";
import CMPStudents from "./modules/dashboard/containers/customerManagement/cmpStudents";
import CMPStudent from "./modules/dashboard/containers/customerManagement/cmpStudent";
import CMPFeeUpdate from "./modules/dashboard/containers/customerManagement/cmpFeeUpdate";
import ViewUsersOfEntity from "./modules/dashboard/containers/customerManagement/viewUsersOfEntity";
import updateCustomizations from "./modules/dashboard/containers/customerManagement/updateCustomizations";
import TemplateHome from "./modules/customTemplates/container/templatesHome"
import AddDevApi from "./modules/dashboard/containers/customerManagement/addDevApi";
import ReceiptTemp from "./modules/receiptTemplate/container/receiptTemplate"
import seoManager from "./modules/seoManagement/container/seoManager";
import seoForPages from "./modules/seoManagement/container/seoForPages";
import addPermission from "./modules/dashboard/containers/customerManagement/addPermission";
import ViewAppLogs from "./modules/appLogs/containers/viewAppLogs";
import collegeGroupsDashboard from "./modules/dashboard/containers/customerManagement/collegeGroupsDashboard";
import createGroup from "./modules/dashboard/containers/customerManagement/createGroup";
import viewCollegeGroup from "./modules/dashboard/containers/customerManagement/viewCollegeGroup";
import UpdateLogInUserId from "./modules/dashboard/containers/customerManagement/updateLogInUserId";
import FileStorage from "./modules/dashboard/containers/customerManagement/FileStorage";
import FileStorageView from "./modules/dashboard/containers/customerManagement/FileStorageView";
import DegreeCourseSize from "./modules/dashboard/containers/customerManagement/DegreeCourseSize";
import GroupDiscussionSize from "./modules/dashboard/containers/customerManagement/GroupDiscussionSize";
import OnScreenSize from "./modules/dashboard/containers/customerManagement/OnScreenSize";
import CollegeBasedSize from "./modules/dashboard/containers/customerManagement/CollegeBasedSize";
import AssignmentSize from "./modules/dashboard/containers/customerManagement/AssignmentSize";
import DigitalLibrarySize from "./modules/dashboard/containers/customerManagement/DigitalLibrarySize";
import AssetManageSize from "./modules/dashboard/containers/customerManagement/AssetManageSize";
import LessonPlanSize from "./modules/dashboard/containers/customerManagement/LessonPlanSize";
import AnnouncementSize from "./modules/dashboard/containers/customerManagement/AnnouncementSize";
import CourseConSize from "./modules/dashboard/containers/customerManagement/CourseConSize";



interface RouteElement {
	redirectTo: string,
	component: ReactType
	rolesExcluded: string[],
	includedInNavbar: boolean
	name?: string,
	icon?: any,
	permission: string,
}

const routesList: RouteElement[] = [
	{
		redirectTo: '/login',
		component: Login,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Login',
		// icon: AccountBalanceRoundedIcon,
		permission: ''
	},
	{
		redirectTo: '/forgot-password',
		component: ForgotPassword,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Forgot Password',
		// icon: AccountBalanceRoundedIcon,
		permission: ''
	},
	{
		redirectTo: '/set-password',
		component: SetPassword,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Set Password',
		// icon: AccountBalanceRoundedIcon,
		permission: ''
	},
	{
		redirectTo: '/dashboard',
		component: Dashboard,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Dashboard',
		// icon: AccountBalanceRoundedIcon,
		permission: ''
	},

	
	{
		redirectTo: '/create-user',
		component: CreateUser,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Create User',
		icon: Person,
		permission: 'createAdmin'
	},
	{
		redirectTo: '/search-admin',
		component: ViewSearchAdmin,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Search/Edit User',
		icon: Person,
		permission: 'getAdmin'
	},
	{
		redirectTo: '/edit-user',
		component: EditUser,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Edit User',
		// icon: AccountBalanceRoundedIcon,
		permission: 'editAdmin'
	},
	{
		redirectTo: '/reset-password',
		component: ResetPassword,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Reset Password',
		// icon: AccountBalanceRoundedIcon,
		permission: 'createAdmin'
	},
	{
		redirectTo: '/assign-role',
		component: AssignRole,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Assign Role',
		// icon: AccountBalanceRoundedIcon,
		permission: 'createAdmin'
	},
	{
		redirectTo: '/create-role',
		component: CreateRole,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Create Role',
		icon: Permission,
		permission: 'createRole'
	},
	{
		redirectTo: '/view-permissions',
		component: ViewEditPermissions,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'View/Edit Permisisons',
		icon: Permission,
		permission: 'editRole'
	},
	{
		redirectTo: '/search-customer',
		component: SearchCustomer,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Search Customer',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/college-groups',
		component: collegeGroupsDashboard,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'College Groups',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/editCustomerRrole',
		component: EditCustomerRole,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Edit Customer Role',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/update-bank-account',
		component: ChangeBankAccount,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Change Bank Account',
		permission: ''
	},
	{
		redirectTo: '/update-customization',
		component: updateCustomizations,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Change Customization',
		permission: ''
	},
	{
		redirectTo: '/view-customer',
		component: ViewCustomer,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'View Customer',
		permission: ''
	},
	{
		redirectTo: '/create-group',
		component: createGroup,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Create Group',
		permission: ''
	},
	{
		redirectTo: '/View-group',
		component: viewCollegeGroup,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'View Group',
		permission: ''
	},
	{
		redirectTo: '/View-file-storage',
		component: FileStorageView,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'View FileStorage',
		permission: ''
	},
	{
		redirectTo: '/Degree-curriculum',
		component: DegreeCourseSize,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Degree Curriculum',
		permission: ''
	},
	{
		redirectTo: '/Group-Discussion-Size',
		component: GroupDiscussionSize,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Group Discussion Size',
		permission: ''
	},
	{
		redirectTo: '/On-ScreenSize',
		component: OnScreenSize,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'On Screen Size',
		permission: ''
	},
	{
		redirectTo: '/College-Based-Size',
		component: CollegeBasedSize, 
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'College Based Size',
		permission: ''
	},
	{
		redirectTo: '/Assignment-Size',
		component: AssignmentSize, 
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Assignment Size',
		permission: ''
	},
	{
		redirectTo: '/Digital-Library-Size',
		component: DigitalLibrarySize, 
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Digital Library Size',
		permission: ''
	},
	{
		redirectTo: '/Asset-Manage-Size',
		component: AssetManageSize, 
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Asset Manage Size',
		permission: ''
	},
	{
		redirectTo: '/Lesson-Plan-Size',
		component: LessonPlanSize, 
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Lesson Plan Size',
		permission: ''
	},
	{
		redirectTo: '/Announcement-Size',
		component: AnnouncementSize, 
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Announcement Size',
		permission: ''
	},
	{
		redirectTo: '/course-content-Size',
		component: CourseConSize,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Course Con Size',
		permission: ''
	}, 

	{
		redirectTo: '/edit-customer',
		component: EditCusRole,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Edit Customer Role',
		permission: ''
	},
	{
		redirectTo: '/addDevApi',
		component: AddDevApi,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Add Dev Api',
		permission: ''
	},
	{
		redirectTo: '/addPermission',
		component: addPermission,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Handle Permission',
		permission: ''
	},

	{
		redirectTo: '/viewUsers',
		component: ViewUsersOfEntity,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'View Users',
		permission: ''
	},
	{
		redirectTo: '/view-package',
		component: viewPackage,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'View Package',
		permission: ''
	},
	{
		redirectTo: '/block-user',
		component: BlockUnblockUser,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Block/Unblock',
		permission: ''
	},
	{
		redirectTo: '/log-in-user',
		component: UpdateLogInUserId,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'LogInUser',
		permission: ''
	},
	{
		redirectTo: '/file-storage',
		component: FileStorage,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'FileStorage',
		permission: ''
	},
	{
		redirectTo: '/invoice',
		component: InvoiceDetails,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Invoice',
		permission: ''
	},
	{
		redirectTo: '/manageKYC',
		component: ManageKYC,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Manage KYC',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/sendEmail',
		component: SendEmail,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Send Email',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/otps',
		component: ViewOtps,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'View OTPs',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/academicReports',
		component: ViewAcademicReports,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Academic Reports',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/cmpreports/feeupdate',
		component: CMPFeeUpdate,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'CMP FeeUpdate',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/cmpreports/studentdetail',
		component: CMPStudent,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'CMP Student',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/cmpreports/students',
		component: CMPStudents,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'CMP Students',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/cmpreports/batches',
		component: CMPBatches,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'CMP Batches',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/cmpreports',
		component: CourseMarketplace,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Course Marketplace',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/usersignup',
		component: SignUpUsers,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Sign up Users',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/tutorPublicProfiles',
		component: ViewTutorPublicProfiles,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Tutor Public Profiles',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/studentInquiries',
		component: ViewStudentInquiries,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Student Inquiries',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/exceptionTrackers',
		component: ViewExceptionTrackers,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Search Customers',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/customtemplates',
		component: TemplateHome,
		rolesExcluded: [],
		includedInNavbar: true,
		icon: Group,
		// icon: AccountBalanceRoundedIcon,
		permission: ''
	}, 
	{
		redirectTo: '/liveClasses',
		component: ViewLiveClasses,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'View Live Classes',
		icon: Group,
		permission: ''
	},
	{
		redirectTo: '/jobposting',
		component: JobPosting,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Career Enquiry',
		icon: ContactPhone,
		permission: ''
	},
	{
		redirectTo: '/create-job',
		component: CreateJob,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Create Job Posting',
		icon: ContactPhone,
		permission: ''
	},
	{
		redirectTo: '/edit-jobposting',
		component: EditJobPosting,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Edit Job Posting',
		icon: ContactPhone,
		permission: ''
	},
	{
		redirectTo: '/leads-Enquiry',
		component: LeadsEnquiry,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Leads Enquiry',
		icon: ManageAccounts,
		permission: ''
	},
	{
		redirectTo: '/demo-enquiry',
		component: LeadsEnquiry,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Demo Enquiry',
		icon: ManageAccounts,
		permission: ''
	},
	{
		redirectTo: '/fee-packages',
		component: FeePackages,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Fee Packages',
		icon: Rupee,
		permission: ''
	},
	{
		redirectTo: '/historical-packages',
		component: PreviousPackages,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Historical Packages',
		icon: Rupee,
		permission: ''
	},
	{
		redirectTo: '/upload-blogs',
		component: UploadResource,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Blogs Upload',
		icon: Website,
		permission: ''
	},
	{
		redirectTo: '/upload-e-book',
		component: UploadResource,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'E-books Upload',
		icon: Website,
		permission: ''
	},
	{
		redirectTo: '/upload-video',
		component: UploadResource,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Video Upload',
		icon: Website,
		permission: ''
	},
	{
		redirectTo: '/feedback',
		component: Feedback,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Feedback',
		icon: Website,
		permission: ''
	},
	{
		redirectTo: '/edit-resources',
		component: EditResources,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Edit Resources',
		permission: ''
	},
	{
		redirectTo: '/edumaticaQB/bulk',
		component: QBBulk,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Add Edumatica QB',
		permission: ''
	},
	{
		redirectTo: '/edumaticaQB/create',
		component: QBCreate,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Add Edumatica QB',
		permission: ''
	},
	{
		redirectTo: '/edumaticaQB/qlist',
		component: QBList,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Add Edumatica QB',
		permission: ''
	},
	{
		redirectTo: '/edumaticaQB/questions',
		component: QBQuestions,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Add Edumatica QB',
		permission: ''
	},
	{
		redirectTo: '/edumaticaQB',
		component: EdumacQuestionBank,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Add Edumatica QB',
		permission: ''
	},
	{
		redirectTo: '/addEdumaticaAcademyContent',
		component: AddEduAcademyContent,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Edit Resources',
		permission: ''
	},
	// {
	// 	redirectTo: '/editEdumaticaAcademyContent',
	// 	component: EditEduAcademyContent,
	// 	rolesExcluded: [],
	// 	includedInNavbar: true,
	// 	name: 'Edit Resources',
	// 	permission: ''
	// },
	{
		redirectTo: '/masteruploads',
		component: MasterUploads,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Master Uploads',
		permission: ''
	},
	{
		redirectTo: '/view_notification_templates',
		component: ViewNotificationTemplates,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Notification Templates',
		permission: ''
	},
	{
		redirectTo: '/uploadContentFile',
		component: UploadContentFile,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Content Data',
		// icon: AccountBalanceRoundedIcon,
		permission: ''
	},
	{
		redirectTo: '/notification_templates',
		component: MasterNotificationTemplates,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Notification Templates',
		permission: ''
	},
	{
		redirectTo: '/masterQuestions',
		component: MasterQuestions,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Master Questions',
		permission: ''
	},
	{
		redirectTo: '/edumaticaStandards',
		component: EdumaticaStandardPage,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Edumatica Standard Identifiers',
		permission: ''
	},
	{
		redirectTo: '/imagesorter',
		component: ImageSorter,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Master Questions',
		permission: ''
	},
	{
		redirectTo: '/certificateupload/create',
		component: UploadCertificate,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Upload Certificate',
		permission: ''
	},
	{
		redirectTo: '/certificateupload',
		component: CertificateUpload,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Certificate Upload',
		permission: ''
	},
	{
		redirectTo: '/receiptTemp',
		component: ReceiptTemp,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Receipt Template',
		permission: ''
	},
	{
		redirectTo: '/editMasterQuestions',
		component: EditMasterQuestions,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Edit Master Questions',
		permission: ''
	},
	{
		redirectTo: '/financeoperations',
		component: FinanceOperation,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Operations',
		permission: ''
	},
	{
		redirectTo: '/financeedumacdata',
		component: FinanceEdumacData,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Financial Data',
		permission: ''
	},
	{
		redirectTo: '/seoManager',
		component: seoManager,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Meta Data',
		permission: ''
	},
	{
		redirectTo: '/viewAppLogs',
		component: ViewAppLogs,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'View Logs',
		permission: ''
	},
	{
		redirectTo: '/createTags',
		component: seoForPages,
		rolesExcluded: [],
		includedInNavbar: true,
		name: 'Create Meta Data',
		permission: ''
	},
	// {
	// 	redirectTo: '/orgMgmt',
	// 	component: OrgMgmt,
	// 	rolesExcluded: [],
	// 	includedInNavbar: true,
	// 	name: 'Org Management',
	// 	icon: AccountBalanceRoundedIcon,
	// },
	// {
	// 	redirectTo: '/tutorMgmt',
	// 	component: OrgMgmt,
	// 	rolesExcluded: [],
	// 	includedInNavbar: true,
	// 	name: 'Tutor Management',
	// 	icon: SupervisedUserCircleRoundedIcon
	// },
	{
		redirectTo: '/',
		component: Login,
		rolesExcluded: [],
		includedInNavbar: false,
		name: 'Dashboard',
		// icon: AccountBalanceRoundedIcon,
		permission: ''
	}
	
]

export default routesList