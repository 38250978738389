import React, { useState, useEffect, FunctionComponent, FormEvent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
	Box, Container, Grid, Checkbox, Button, TextField, Input, MenuItem, Menu,
	Typography, FormControlLabel, Switch, FormHelperText, OutlinedInput, IconButton, CssBaseline, Card, CardContent, CardHeader, InputAdornment
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';

import MiniDrawer from "../../../common/components/SideDrawer"
import { fontOptions } from '../../../common/theme';
import CustomTextField from '../../components/customTextField';
import { IFSC_PATTERN, ACCOUNT_NO_PATTERN } from '../../../common/validations/patterns';
import { changeBankAccount, fetchDownloadUrlForKycDocument, fetchUploadUrlForKycDocument, getAdminData, getKycData, uploadFileOnUrl } from '../../../../api/dashboard';
import { ArrowBack, Visibility, VisibilityOff } from '@material-ui/icons';
import useStyles from './styles';
import { Admin, Role, EditAdmin, CustomUser, BankChangeRequest } from '../../../common/contracts/dashboard';
import { RootState } from '../../../redux/store';
import { connect, useDispatch } from 'react-redux';
import { CUSTOMER_TYPES, BLOCK_ACTION_TYPES, MASTER_PERMISSIONS, USER_ROLES } from '../../../../utilities/constants';
import PasswordVisibilityButton from '../../../common/components/password_visibility_button';
import Dropzone from '../../../common/components/dropzone';
import { setLoginUserData } from '../../../redux/actions/adminActions';

interface Props extends RouteComponentProps {
	user: CustomUser
}

interface FormData {
	customerType: string,
	bankAccount: string,
	confirmBankAccount: string,
	ifscCode: string,
	// checkImage:string,
	reason: string
}

const ValidationSchema = yup.object().shape({
	customerType: yup.string().notOneOf(['Select']).required('Customer Type is required'),
	// bankAccount: yup.string().required('Bank Account is required')
	// 	.matches(ACCOUNT_NO_PATTERN, 'Bank Account is invalid'),
	// confirmBankAccount: yup.string().required('Please confirm bank account')
	// 	.matches(ACCOUNT_NO_PATTERN, 'Bank Account is invalid')
	// 	.oneOf([yup.ref('bankAccount'), null], "Bank Accounts don't match."),
	// ifscCode: yup.string().required('IFSC code is required')
	// 	.matches(IFSC_PATTERN, 'IFSC code is invalid'),
	reason: yup.string().required('Reason is required'),
});

const ChangeBankAccount: FunctionComponent<Props> = ({ user, history }) => {
	const { errors, setError, clearError, handleSubmit, register, reset, control, setValue } = useForm<FormData>({
		mode: 'onBlur',
		validationSchema: ValidationSchema
	});

	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const [isBankACVisible, setIsBankACVisible] = useState(false);
	const [isConfirmBankAcVisible, setIsConfirmBankAcVisible] = useState(false);
	const [checkImage, setcheckImage] = useState<File[]>([]);
	const [kycData, setKycData] = useState<any>({});
	const [previewImage, setPreviewImage] = useState('');
	const dispatch = useDispatch();

	var allowedPermissions: string[] = [];
	var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
	var loginUserType = '';
	if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
		allowedPermissions = loginUserData.adminRole.permissions;
		loginUserType = loginUserData.adminRole.name;
	}

	const styles = useStyles();

	useEffect(() => {
		getLoggedInUserData();
	}, []);

	const getLoggedInUserData = async () => {
		// setLoading(true);
		// try {
		// 	const adminUserData = await getAdminData();
		// 	dispatch(setLoginUserData(JSON.stringify(adminUserData)));
		// 	setLoading(false);
		// 	if (adminUserData.isItDefaultPassword) {
		// 		localStorage.setItem('isItDefaultPassword', JSON.stringify(adminUserData.isItDefaultPassword));
		// 		setRedirectTo('/set-password');
		// 	}
		// } catch (error) {
		// 	setLoading(false);
		// 	enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
		// }
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.makerBankChange)
			|| allowedPermissions.includes(MASTER_PERMISSIONS.checkerBankChange);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		} else {
			getKycDetails();
		}
	}

	const getKycDetails = async () => {
		if (user && user.userType) {
			try {
				const kycData = await getKycData(user.userType, user._id)
				setKycData(kycData);
				setValue('bankAccount', kycData && kycData.makerBankChange ? kycData.makerBankChange.bankAccount : kycData.bankAccount)
				setValue('confirmBankAccount', kycData && kycData.makerBankChange ? kycData.makerBankChange.bankAccount : kycData.bankAccount)
				setValue('ifscCode', kycData && kycData.makerBankChange ? kycData.makerBankChange.bankIfsc : kycData.bankIfsc)
			} catch (error) {
				enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
				if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
					setRedirectTo('/login');
				}
			}
		}
	}


	const handleChangeBankAccount = async (status: string, data: FormData, isApproved: boolean, userKycReason: string) => {
		var { customerType, reason, bankAccount, ifscCode, confirmBankAccount } = data;
		var kycDetails = [];

		if (!kycData.ownerId) {
			enqueueSnackbar('Something went wrong', { variant: 'warning' });
			return;
		}
		if (isApproved) {
			if(bankAccount.length === 0) {
				enqueueSnackbar('Bank A/C Number cannot be empty', {variant: 'warning'})
				return;
			}
			if (!ACCOUNT_NO_PATTERN.test(bankAccount)) {
				enqueueSnackbar('Bank Account is invalid', { variant: 'warning' });
				return;
			}
			if (bankAccount != confirmBankAccount) {
				enqueueSnackbar("Bank Accounts don't match", { variant: 'warning' });
				return;
			}
			if(ifscCode.length === 0) {
				enqueueSnackbar("IFSC code cannot be empty", {variant: 'warning'})
				return;
			}
			if (!IFSC_PATTERN.test(ifscCode)) {
				enqueueSnackbar("IFSC code is invalid", { variant: 'warning' });
				return;
			}
		}
		if (!reason) {
			enqueueSnackbar("Please enter reason", { variant: 'warning' });
			return;
		}

		if (checkImage[0]) {
			if (checkImage[0].size !== 0) {
				const file = checkImage[0];
				const formData = new FormData();
				formData.append('document', file);

				try {
					const awsBucket = await fetchUploadUrlForKycDocument({
						fileName: file.name,
						contentType: file.type,
						contentLength: file.size,
						ownerId: kycData.ownerId
					});

					const data = await uploadFileOnUrl(awsBucket.url, file);


					kycDetails = kycData.kycDetails;

					let index = kycData.kycDetails.findIndex((item: any) => item.kycDocType == 'bank');
					if (index >= 0) {
						kycDetails[index].kycDocFormat = file.type;
						kycDetails[index].kycDocLocation = file.name;
						kycDetails[index].uuid = awsBucket.uuid;
					} else {
						kycDetails.push({
							kycDocFormat: file.type,
							kycDocLocation: file.name,
							uuid: awsBucket.uuid,
							kycDocType: "bank",
						});
					}
				} catch (error) {
					enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
					if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
						setRedirectTo('/login');
					}
				}
			}
		} else if (kycData && kycData.makerBankChange && kycData.makerBankChange.kycDetails) {
			kycDetails = kycData.makerBankChange.kycDetails;
		} else {
			enqueueSnackbar('Please upload check image', { variant: 'warning' });
			return;
		}

		// if (kycDetails.length > 0) {
		try {
			var obj: any = {
				userType: customerType,
				userId: user._id,
				adminType: status,
				[userKycReason]: reason,
				ownerId: kycData.ownerId,
				isApproved: isApproved
			}

			if (isApproved) {
				obj['bankAccount'] = bankAccount;
				obj['bankIfsc'] = ifscCode;
				obj['kycDetails'] = kycDetails;
			}

			const cba = await changeBankAccount(obj)

			enqueueSnackbar('Updated successfully', { variant: 'success' });
			setRedirectTo('/search-customer');
		} catch (error) {
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
		// } else {
		// 	enqueueSnackbar('Something went wrong', { variant: 'warning' });
		// }

	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const handleApproveAsMaker = (data: FormData) => {
		handleChangeBankAccount('maker', data, true, 'makerBankChangeReason');
	}
	const handleApproveAsChecker = (data: FormData) => {
		handleChangeBankAccount('checker', data, true, 'checkerBankChangeReason');
	}
	const handleRejectAsMaker = (data: FormData) => {
		handleChangeBankAccount('maker', data, false, 'makerBankChangeReason');
	}
	const handleRejectAsChecker = (data: FormData) => {
		handleChangeBankAccount('checker', data, false, 'checkerBankChangeReason');
	}

	const handleViewDocument = async (item: any) => {
		if (kycData && kycData.ownerId && item.uuid) {
			try {
				let response = await fetchDownloadUrlForKycDocument(item.uuid, kycData.ownerId);
				setTimeout(() => {
					window.open(response.url);
				}, 100);
			} catch (error) {
				enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
				if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
					setRedirectTo('/login');
				}
			}
		}
		else {
			enqueueSnackbar('Something went wrong', { variant: 'warning' });
		}
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							<Grid item className={styles.header}>
								<IconButton onClick={() => history.goBack()}>
									<ArrowBack />
								</IconButton>
								<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
							</Grid>
							<Card className={styles.card} variant='outlined'>
								<CardHeader title='Change Bank Account' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>

									<form onSubmit={handleSubmit(() => { })} >

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Customer Name</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														value={user.name ? user.name : ''}
														disabled
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Email</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														value={user.emailId ? user.emailId : ''}
														disabled
													/>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Mobile</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														value={user.mobileNo ? user.mobileNo : ''}
														disabled
													/>
												</FormControl>
											</Grid>
										</Grid>


										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Customer Type</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Controller
														as={
															<Select disabled={user.userType ? true : false}>
																<MenuItem value="Select">Select</MenuItem>
																{CUSTOMER_TYPES.map((item) => (
																	<MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>
																))}
															</Select>

														}
														name='customerType'
														inputRef={register}
														control={control}
														defaultValue={user.userType ? user.userType : ''}
													/>
													{errors.customerType && (
														<FormHelperText error>
															{errors.customerType.message}
														</FormHelperText>
													)}
												</FormControl>
											</Grid>
										</Grid>


										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Bank A/C Number</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														name='bankAccount'
														inputRef={register}
														inputProps={{ maxLength: 25 }}
														type={isBankACVisible ? 'text' : 'password'}
														endAdornment={
															<InputAdornment position="end">
																<PasswordVisibilityButton
																	isVisible={isBankACVisible}
																	handleChange={(isVisible) =>
																		setIsBankACVisible(isVisible)
																	}
																/>
															</InputAdornment>
														}
													/>
													{errors.bankAccount && (
														<FormHelperText error>
															{errors.bankAccount.message}
														</FormHelperText>
													)}
												</FormControl>
											</Grid>
										</Grid>


										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Re enter Bank A/C Number</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														name='confirmBankAccount'
														inputRef={register}
														inputProps={{ maxLength: 25 }}
														type={isConfirmBankAcVisible ? 'numeric' : 'password'}
														endAdornment={
															<InputAdornment position="end">
																<PasswordVisibilityButton
																	isVisible={isConfirmBankAcVisible}
																	handleChange={(isVisible) =>
																		setIsConfirmBankAcVisible(isVisible)
																	}
																/>
															</InputAdornment>
														}
													/>
													{errors.confirmBankAccount && (
														<FormHelperText error>
															{errors.confirmBankAccount.message}
														</FormHelperText>
													)}
												</FormControl>
											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>IFSC Code</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														name='ifscCode'
														placeholder='IFSC Code'
														inputRef={register}
														inputProps={{ maxLength: 15 }}
													/>
												</FormControl>
												{errors.ifscCode && (
													<FormHelperText error>
														{errors.ifscCode.message}
													</FormHelperText>
												)}
											</Grid>
										</Grid>


										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Cheque Image</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<Dropzone
													key={0}
													onChange={(files) => {
														if (files.length > 0) {
															setcheckImage(files)
															setPreviewImage(URL.createObjectURL(files[0]))
														}
													}}
													files={checkImage}
													acceptedFiles={['image/jpeg', 'image/png']}
													maxFileSize={104857600} // 100 MB
													contenttype='image'
												/>
												{kycData && kycData.makerBankChange && kycData.makerBankChange.kycDetails ?
													kycData.makerBankChange.kycDetails.map((item: any) => {
														return item.kycDocType == 'bank' ?
															<Button
																onClick={() => handleViewDocument(item)}
															>View Image</Button> :
															null
													}) :
													kycData && kycData.kycDetails ? kycData.kycDetails.map((item: any) => {
														return item.kycDocType == 'bank' ?
															<Button
																onClick={() => handleViewDocument(item)}
															>View Initial Image</Button> :
															null
													}) :
														null
												}
												{/* {
													kycData && kycData.kycDetails && kycData.kycDetails.map((item: any) => {
														return item.kycDocType == 'bank' ?
															<Button
																onClick={() => handleViewDocument(item)}
															>View Initial Image</Button> :
															null
													})
												} */}
												{previewImage &&
													<Button onClick={() => window.open(previewImage)}
													>Preview Uploaded Image</Button>}

											</Grid>
										</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Approve/Rejection Reason</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<FormControl fullWidth margin="normal">
													<Input
														name='reason'
														placeholder='Approve/Rejection reason text'
														inputRef={register}
														inputProps={{ maxLength: 100 }}
														multiline
													/>
												</FormControl>
												{errors.reason && (
													<FormHelperText error>
														{errors.reason.message}
													</FormHelperText>
												)}
											</Grid>
										</Grid>

										<>
											{(allowedPermissions.includes(MASTER_PERMISSIONS.makerBankChange) || loginUserType == USER_ROLES.super) &&
												<Button variant="contained" color="primary" size="medium"
													style={{ margin: 10 }}
													onClick={handleSubmit(handleApproveAsMaker)}
												>	Approve as Maker
										</Button>}

											{(allowedPermissions.includes(MASTER_PERMISSIONS.checkerBankChange) || loginUserType == USER_ROLES.super)
												&& <Button variant="contained" color="primary" size="medium"
													style={{ margin: 10 }}
													onClick={handleSubmit(handleApproveAsChecker)}
												>	Approve as Checker
										</Button>}

											{(allowedPermissions.includes(MASTER_PERMISSIONS.makerBankChange) || loginUserType == USER_ROLES.super) &&
												<Button variant="contained" color="primary" size="medium"
													style={{ margin: 10 }}
													onClick={handleSubmit(handleRejectAsMaker)}
												>	Reject as Maker
										</Button>}

											{(allowedPermissions.includes(MASTER_PERMISSIONS.checkerBankChange) || loginUserType == USER_ROLES.super)
												&& <Button variant="contained" color="primary" size="medium"
													style={{ margin: 10 }}
													onClick={handleSubmit(handleRejectAsChecker)}
												>	Reject as Checker
										</Button>}
										</>

									</form>
								</CardContent>
							</Card>

						</Grid>
					</Container>
				</div >
			</MiniDrawer >
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	user: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(ChangeBankAccount);