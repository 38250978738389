import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridToolbar,
} from '@material-ui/data-grid';
import { Box, Container, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface RowData {
    cityName: string,
    schoolAddress: number,
    schoolName: string,
}

interface Props {
  gridData: RowData[];
}

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600'
    },
    '& .super-app.negative': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600'
    },
    '& .super-app.positive': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600'
    }
  },
  paginate:{
    display:"flex"
  }
});

export const MasterSchoolDataTable: FunctionComponent<Props> = ({
  gridData
}) => {
  const classes = useStyles();

  const columns: GridColumns = [
    { field: 'id', headerName: 'S.No', flex:0.4 },
    { field: 'cityName', headerName: 'City Name', type: 'string', flex:0.8 },
    { field: 'schoolName', headerName: 'School Name', type: 'string', flex:1.2 },
    { field: 'schoolAddress', headerName: 'School Address', type: 'string', flex:2 },
  ];

  const [rows,setRows] = useState<RowData[]>([])

   useEffect(()=>{
      setRows(()=>{
          return gridData?.map((data,index)=>{
              return {id:index+1, cityName:data.cityName, schoolAddress:data.schoolAddress, schoolName:data.schoolName} as RowData
          }) as RowData[]
      })
    },[gridData])

  return (
    <React.Fragment>
      <Box
        className={classes.root}
        style={{
          width: '100%',
          height: '420px',
          marginTop: '10px',
          display: 'flex'
        }}
      >
        <DataGrid
          loading={gridData.length === 0}
          // pagination
          components={{
            Toolbar: GridToolbar
          }}
          // autoPageSize
          rows={rows}
          columns={columns}
        />
      </Box>
    </React.Fragment>
  );
};
