import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	Input,
	Typography
} from '@material-ui/core';
import { Link as Redirect, RouteComponentProps } from 'react-router-dom';
import { useSnackbar } from "notistack"

import MiniDrawer from '../../../common/components/SideDrawer';
import Button from '../../../common/components/form_elements/button';
import { createRole, getPermissions } from '../../../../api/dashboard';
import { FilteredPermission, Permission, PermissionObj } from '../../../common/contracts/dashboard';
import useStyles from './styles';
import { NAME_PATTERN } from '../../../common/validations/patterns';
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../../utilities/constants';


interface Props extends RouteComponentProps<{ usertype?: string; }> { }


const CreateRole: FunctionComponent<Props> = ({ }) => {

	const { enqueueSnackbar } = useSnackbar()
	const [redirectTo, setRedirectTo] = useState('');
	const [masterPermissions, setMasterPermissions] = useState<Permission[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [filteredPermissions, setFilteredPermissions] = useState<FilteredPermission[]>();
	const [role, setRole] = useState('');
	const [allChecked, setAllChecked] = useState<boolean>(false);
	const styles = useStyles();

	useEffect(() => {
		getLoggedInUserData();
	}, []);

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		const requiredPermissions = [MASTER_PERMISSIONS.createRole, MASTER_PERMISSIONS.getRoles]
		var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		} else {
			getAllPermissions()
		}
	}

	const getAllPermissions = async () => {
		setLoading(true);
		try {
			const masterPermissions = await getPermissions();
			masterPermissions.sort((a, b) => (a._id < b._id) ? -1 : 1);
			setMasterPermissions(masterPermissions);
			masterPermissions.forEach((item) => {
				item.permissions.forEach((permissionItem: PermissionObj) => {
					permissionItem['checked'] = false;
				});
			})

			setFilteredPermissions(masterPermissions);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}

	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, recordIndex: number, permissionIndex: number) => {
		if (filteredPermissions && filteredPermissions.length > 0) {
			filteredPermissions[recordIndex].permissions[permissionIndex].checked = event.target.checked;
			let clone = [...filteredPermissions];
			setFilteredPermissions(clone);
		}
	};

	const handleCreate = async () => {

		if (!role) {
			enqueueSnackbar('Please enter role name', { variant: 'warning' });
			return;
		}
		if (role.replace(/ /g, "").length < 4) {
			enqueueSnackbar('Please enter role name with minimum 4 characters', { variant: 'warning' });
			return;
		}
		if (!NAME_PATTERN.test(role)) {
			enqueueSnackbar('Name should not contain special characters or numbers', { variant: 'warning' });
			return;
		}

		var permissions: string[] = [];

		filteredPermissions && filteredPermissions.forEach((record) => {
			record.permissions.forEach((permission) => {
				if (permission.checked) {
					permissions.push(permission._id)
				}
			});
		});

		if (permissions.length == 0) {
			enqueueSnackbar('Please select permissions', { variant: 'warning' });
			return
		}

		setLoading(true);

		try {
			const data = await createRole(role, permissions);
			setLoading(false);
			enqueueSnackbar('Role created successfully', { variant: 'success' });
			setRole('');
			if (filteredPermissions) {
				filteredPermissions.forEach((record) => {
					record.permissions.forEach((permission) => {
						permission.checked = false;
					});
				});
				let clone = [...filteredPermissions];
				setFilteredPermissions(clone);
			}
			setAllChecked(false);
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {

		if (filteredPermissions && filteredPermissions.length > 0) {
			setAllChecked(event.target.checked);
			filteredPermissions.forEach((record) => {
				record.permissions.forEach((permission) => {
					permission.checked = event.target.checked;
				});
			});
			let clone = [...filteredPermissions];
			setFilteredPermissions(clone);
		}
	}

	return (
		<MiniDrawer>
			<div style={{ height: '80vh', width: '100%' }}>
				<Grid container	>
					{/* <Grid item lg={4} xl={3} md={4}>
						<Typography variant="h4" >{'Roles & Permissions'}</Typography>
					</Grid> */}
					<Grid item className={styles.header}>
						<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
					</Grid>
					<Card className={styles.card} variant='outlined' >
						<CardHeader title='Create Role' titleTypographyProps={{ varaint: 'h5' }} />
						<CardContent >

							<Grid item xs={12} md={6}>
								<Input
									name="role"
									placeholder="Enter role name"
									inputProps={{ maxLength: 50 }}
									onChange={(e) => setRole(e.target.value)}
									value={role}
								/>
							</Grid>

							<Grid item>
								<FormControlLabel
									control={
										<Checkbox checked={allChecked} name='Check All'
											onChange={(e) => handleCheckAll(e)}
											color="primary" />
									}
									label='All Permissions'
								/>
							</Grid>

							<Grid container>
								<FormControl component="fieldset" className={styles.formControl}>
									<FormLabel component="legend">Permissions List</FormLabel>
									<FormGroup row>
										{
											filteredPermissions && filteredPermissions.map((record, recordIndex) => {
												return (
													<Grid item xs={4}>
														<h1>{record._id}</h1>
														{
															record.permissions.map((permission, permissionIndex) => (
																<FormControlLabel
																	control={
																		<Checkbox checked={permission.checked} name={permission.name}
																			onChange={(e) => handleChange(e, recordIndex, permissionIndex)}
																			color="primary" />
																	}
																	label={permission.displayName}
																/>
															))
														}
													</Grid>
												)
											})
										}
									</FormGroup>
								</FormControl>
							</Grid>

							<Box className={styles.submitBtn}>
								<Button
									disableElevation
									variant="contained"
									color="primary"
									size="large"
									type="submit"
									onClick={handleCreate}
									disabled={loading}
								>Create</Button>
							</Box>
						</CardContent>
					</Card>

				</Grid>
			</div>
		</MiniDrawer>
	);
};

export default CreateRole;
