import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	MenuItem,
	Select,
	Typography
} from '@material-ui/core';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useSnackbar } from "notistack"

import MiniDrawer from '../../../common/components/SideDrawer';
import Button from '../../../common/components/form_elements/button';
import { editRole, getPermissions, getRoles } from '../../../../api/dashboard';
import { FilteredPermission, Permission, PermissionObj, Role } from '../../../common/contracts/dashboard';
import useStyles from './styles';
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../../utilities/constants';

interface Props extends RouteComponentProps<{ usertype?: string; }> { }

const ViewEditPermissions: FunctionComponent<Props> = ({ }) => {

	const { enqueueSnackbar } = useSnackbar()
	const [redirectTo, setRedirectTo] = useState('');
	const [masterPermissions, setMasterPermissions] = useState<Permission[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [filteredPermissions, setFilteredPermissions] = useState<FilteredPermission[]>();
	const [masterRoles, setMasterRoles] = useState<Role[]>([]);
	const [selectedRole, setSelectedRole] = useState('Select');
	const [allMPermissions, setAllMPermissions] = useState<string[]>([]);
	const [disabled, setDisabled] = useState<boolean>(true);
	const [allChecked, setAllChecked] = useState<boolean>(false);

	const styles = useStyles();

	useEffect(() => {
		getLoggedInUserData();
	}, []);

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		const requiredPermissions = [MASTER_PERMISSIONS.editRole, MASTER_PERMISSIONS.getRoles]
		var hasEligibility = loginUserType == USER_ROLES.super || requiredPermissions.every((perm) => allowedPermissions.includes(perm))
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		} else {
			getMasterRoles();
			getMasterPermissions();
		}
	}

	const getMasterRoles = async () => {
		try {
			const masterRoles = await getRoles();
			setMasterRoles(masterRoles);
			var allMPermissions: string[] = [];
			masterRoles.forEach((item) => {
				allMPermissions = allMPermissions.concat(item.permissions);
			});
			setAllMPermissions(allMPermissions);
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	const getMasterPermissions = async () => {
		try {
			const masterPermissions = await getPermissions();
			masterPermissions.forEach((item: Permission) => {
				item.permissions.forEach((permissionItem: PermissionObj) => {
					permissionItem['checked'] = false;
				});
			});
			masterPermissions.sort((a, b) => (a._id < b._id) ? -1 : 1);
			setMasterPermissions(masterPermissions);
			setFilteredPermissions(masterPermissions);
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	const handleChangePermissions = (event: React.ChangeEvent<HTMLInputElement>, recordIndex: number, permissionIndex: number) => {
		if (filteredPermissions && filteredPermissions.length > 0) {
			filteredPermissions[recordIndex].permissions[permissionIndex].checked = event.target.checked;
			let clone = [...filteredPermissions];
			setFilteredPermissions(clone);
		}
	};

	const handleSave = async () => {
		if (selectedRole == 'super') {
			enqueueSnackbar('Super user permissions cannot be changed', { variant: 'warning' });
			return;
		}
		if (selectedRole == 'Select') {
			enqueueSnackbar('Invalid role', { variant: 'warning' });
			return;
		}
		var permissions: string[] = [];

		filteredPermissions && filteredPermissions.forEach((record) => {
			record.permissions.forEach((permission) => {
				if (permission.checked) {
					permissions.push(permission._id)
				}
			});
		});

		if (permissions.length == 0) {
			enqueueSnackbar('Permissions cannot be empty', { variant: 'warning' });
			return;
		}

		try {
			const data = await editRole(selectedRole, permissions);
			enqueueSnackbar('Role updated successfully', { variant: 'success' });
			if (filteredPermissions) {
				getMasterRoles();
				filteredPermissions.forEach((item) => {
					item.permissions.forEach((permission) => {
						permission.checked = false;
					});
				});
				setSelectedRole('');
				setDisabled(true);
				setAllChecked(false);
				// masterRoles.forEach((item) => {
				// 	if (item.name == selectedRole) {
				// 		item.permissions = permissions;
				// 		return
				// 	}
				// })
			}
		} catch (error) {
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const handleChangeRole = (event: React.ChangeEvent<{ value: unknown }>) => {
		let value = event.target.value;
		setDisabled(true);
		if (value == 'super') {
			setSelectedRole('Select');
			enqueueSnackbar('Super user permissions cannot be changed', { variant: 'warning' });
			return;
		}
		setSelectedRole(value as string);
		if (filteredPermissions) {
			filteredPermissions.forEach((item) => {
				item.permissions.forEach((permission) => {
					permission.checked = false;
				});
			});

			let index = masterRoles.findIndex((role) => role.name == value)
			var selectedPermissions: string[] = [];
			if (index >= 0) {
				selectedPermissions = masterRoles[index].permissions
			}

			filteredPermissions.forEach((item) => {
				item.permissions.forEach((permission) => {
					if (selectedPermissions.includes(permission.name)) {
						permission.checked = true;
						return;
					}
					return;
				})
			});
			let clone = [...filteredPermissions];
			setFilteredPermissions(clone);

		}
	};

	const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {

		if (filteredPermissions && filteredPermissions.length > 0) {
			setAllChecked(event.target.checked);
			filteredPermissions.forEach((record) => {
				record.permissions.forEach((permission) => {
					permission.checked = event.target.checked;
				});
			});
			let clone = [...filteredPermissions];
			setFilteredPermissions(clone);
		}
	}


	return (
		<MiniDrawer>
			<div style={{ height: '80vh', width: '100%' }}>
				<Grid container				>
					{/* <Grid item lg={4} xl={3} md={4}>
						<Typography variant="h4" >View/Edit Permissions</Typography>
					</Grid> */}
					<Grid item className={styles.header}>
						<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
					</Grid>
					<Card className={styles.card} variant='outlined' >
						<CardHeader title='Edit Role' titleTypographyProps={{ varaint: 'h5' }} />
						<CardContent >

							<Grid item xs={12} md={4}>
								<FormControl fullWidth margin="normal">
									<Select onChange={handleChangeRole} value={selectedRole} defaultValue='Select'>
										<MenuItem value="Select">Select Role</MenuItem>
										{masterRoles.length > 0 && masterRoles.map((item, index) => (
											<MenuItem value={item.name} key={index}	>
												{item.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>


							<Grid item>
								<FormControlLabel
									control={
										<Checkbox checked={allChecked} name='Check All'
											onChange={(e) => handleCheckAll(e)}
											color="primary" />
									}
									label='All Permissions'
									disabled={selectedRole && selectedRole != 'Select' ? false : true}
								/>
							</Grid>

							<Button
								variant="contained"
								color="primary"
								size="large"
								type="submit"
								onClick={() => setDisabled(!disabled)}
								disabled={selectedRole && selectedRole != 'Select' ? false : true}
							>Edit</Button>

							<Grid container>
								<FormControl component="fieldset" className={styles.formControl}>
									{/* <FormLabel component="legend">Permissions List</FormLabel> */}
									<FormGroup row>
										{
											filteredPermissions && filteredPermissions.map((record, recordIndex) => {
												return (
													<Grid item xs={4}>
														<h1 key={recordIndex}>{record._id}</h1>
														{
															record.permissions.map((permission, permissionIndex) => (
																<FormControlLabel key={permissionIndex}
																	control={
																		<Checkbox checked={permission.checked} name={permission.name}
																			onChange={(e) => handleChangePermissions(e, recordIndex, permissionIndex)}
																			color="primary" disabled={disabled} />
																	}
																	label={permission.displayName}
																/>
															))
														}
													</Grid>
												)
											})
										}
									</FormGroup>
								</FormControl>
							</Grid>

							<Box className={styles.submitBtn}>
								<Button
									disableElevation
									variant="contained"
									color="primary"
									size="large"
									type="submit"
									onClick={handleSave}
									disabled={disabled}
								>Save</Button>
							</Box>

						</CardContent>
					</Card>
				</Grid>
			</div>
		</MiniDrawer >
	);
};

export default ViewEditPermissions;
