import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API + "admin/"
const TUTOR_PUBLIC_PROFILES = BASE_URL + "tutorPublicProfiles"
const STUDENT_INQUIRIES = BASE_URL + "studentInquiries"
const TUTOR_PUBLIC_PROFILE = BASE_URL + "tutorPublicProfile"

export const fetchTutorPublicProfiles = async (params: {startDate: Date, endDate: Date}) => {
    const response = await axios.get(TUTOR_PUBLIC_PROFILES, {params})
    return response.data.data
}

export const fetchStudentInquiries = async (params: {startDate: Date, endDate: Date}) => {
    const response = await axios.get(STUDENT_INQUIRIES, {params})
    return response.data.data
}

export const blockTutorProfile = async (blockReq: {
    entityId: string,
    blockReason: string
}) => {
    await axios.patch(TUTOR_PUBLIC_PROFILE, blockReq)
}