import { Box, Button, Card, CardContent, CardHeader, CssBaseline, FormControl, Grid, Input, Typography } from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
	KeyboardDatePicker,
} from '@material-ui/pickers';

import { useSnackbar } from "notistack"
import { Redirect } from 'react-router';

import MiniDrawer from "../../common/components/SideDrawer"
import useStyles from '../../dashboard/containers/customerManagement/styles'
import Dropzone from '../../common/components/dropzone/dropzone'
import UploadedContent from "../../common/components/dropzone/previewers/uploadedContent"
import { resourceType, webResource } from '../interfaces/webResource';
import { addWebResource, fetchUploadUrlForHomeContent, uploadResource } from '../api';
import { compareAsc, } from 'date-fns';
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../utilities/constants';
import { convertToBase64, onApiError } from '../../common/helpers';

interface Props {
	type: resourceType
}

const WebResourceUploader: FunctionComponent<Props> = ({ type }) => {
	const styles = useStyles()
	const [title, setTitle] = useState('')
	const [startDate, setStartDate] = useState(new Date())
	const [endDate, setEndDate] = useState(new Date())
	const [url, setUrl] = useState('')

	const [dropzoneKey, setDropzoneKey] = useState(0)
	const [droppedFiles, setDroppedFiles] = useState<File[]>([])
	const [thumbnailFiles, setThumbnailFiles] = useState<File[]>([])
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState(false)

	const { enqueueSnackbar } = useSnackbar()


	useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.createContent);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const addingFile = (files: File[], setter: any) => {
		if (files.length < 1) return;
		setter(files)
	}

	const removingFile = (setter: any) => setter([])

	const handleDateChange = (date: Date | null, setter: any) => {
		setter(date)
	}

	const getFileTypes = (type: resourceType) => {
		if (type === resourceType.blog) return ['text/html']
		if (type === resourceType.ebook) return ['application/pdf']
		return []
	}

	const checkValidation = () => {

		if (compareAsc(startDate, endDate) == 1) {
			enqueueSnackbar("End date should be after start date", { variant: "error" })
			return false
		}

		if (startDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
			enqueueSnackbar("Start date should be today or after today", { variant: "error" })
			return false
		}

		if (endDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
			enqueueSnackbar("End date should be today or after today", { variant: "error" })
			return false
		}

		if (title.length < 3) {
			enqueueSnackbar("Title should have minimum 3 characters", { variant: "error" })
			return false
		}
		if (type !== resourceType.video) {
			if (droppedFiles.length < 1) {
				enqueueSnackbar("No file uploaded", { variant: "error" })
				return false
			}
			if (thumbnailFiles.length < 1) {
				enqueueSnackbar("No thumbnail uploaded", { variant: "error" })
				return false
			}
		} else {
			if (url.length < 5) {
				enqueueSnackbar("Invalid url", { variant: "error" })
				return false
			}
		}
		return true
	}

	const uploadFiles = async (file: File) => {
		try {
			const response = await fetchUploadUrlForHomeContent({ contentType: file.type })
			await uploadResource(response.signedUrl, file)
			return response.newuuid
		} catch (e) {
			onApiError(e, enqueueSnackbar, setRedirectTo)
			return ''
		}
	}

	const createWebResource = async () => {
		setLoading(true)
		if (!checkValidation()) {
			setLoading(false)
			return;
		}
		let uuid = '', thumbnail = ''
		if (droppedFiles.length > 0) {
			uuid = await uploadFiles(droppedFiles[0])
		}
		if (thumbnailFiles.length > 0) {
			thumbnail = await convertToBase64(thumbnailFiles[0])
		}
		const resource: webResource = {
			type,
			title,
			startDate: new Date(startDate.setHours(0, 0, 0, 0)),
			endDate: new Date(endDate.setHours(23, 59, 0, 0)),
		}
		if (type !== resourceType.video) {
			resource.uuid = uuid
			resource.contentType = droppedFiles[0].type
			resource.thumbnail = thumbnail
		} else resource.url = url
		await addWebResource(resource)
			.then(() => {
				enqueueSnackbar('Successfully uploaded', { variant: "success" })
				setTitle('')
				setUrl('')
				setDroppedFiles([])
				setThumbnailFiles([])
			})
			.catch(err => onApiError(err, enqueueSnackbar, setRedirectTo))
			.finally(() => setLoading(false))
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<Grid container>
					<Grid item className={styles.header}>
						<Typography variant="h5">
							{`${type} upload`}
						</Typography>
					</Grid>
					<Card className={styles.webCard} variant='outlined'>
						<CardHeader title={`${type} Details`} titleTypographyProps={{ varaint: 'h5' }} />
						<CardContent className={styles.cardContent}>
							<Grid container alignItems="center" alignContent="center">
								<Grid item xs={12} md={4}>
									<FormControl fullWidth margin="normal">
										<Box className={styles.label}>
											{`${type} Title`}
										</Box>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={8}>
									<FormControl fullWidth margin="normal">
										<Input
											placeholder={`${type} Title`}
											onChange={(e) => {
												setTitle(e.target.value)
											}}
											value={title}
										/>
									</FormControl>
								</Grid>

								<Grid item xs={12} md={4}>
									<Box className={styles.label}>
										{`${type} Start Date`}
									</Box>
								</Grid>
								<Grid item xs={12} md={8}>
									<FormControl fullWidth margin="normal">
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												disableToolbar
												variant="inline"
												format="dd/MM/yyyy"
												margin="normal"
												id="date-picker-inline"
												label="Start date"
												value={startDate}
												onChange={(d) => handleDateChange(d, setStartDate)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
												minDate={new Date()}
											/>
										</MuiPickersUtilsProvider>
									</FormControl>
								</Grid>

								<Grid item xs={12} md={4}>
									<Box className={styles.label}>
										{`${type} End Date`}
									</Box>
								</Grid>
								<Grid item xs={12} md={8}>
									<FormControl fullWidth margin="normal">
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												disableToolbar
												variant="inline"
												format="dd/MM/yyyy"
												margin="normal"
												id="date-picker-inline"
												label="End date"
												value={endDate}
												onChange={(d) => handleDateChange(d, setEndDate)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
												minDate={new Date()}
											/>
										</MuiPickersUtilsProvider>
									</FormControl>
								</Grid>

								{(type !== resourceType.video) ?
									<>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>
													{`Upload ${type}`}
												</Box>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={8}>
											<Dropzone
												key={dropzoneKey}
												acceptedFiles={getFileTypes(type)}
												multipleFiles={false}
												maxFileSize={104857600} // 100 MB
												files={droppedFiles}
												onChange={(files) => addingFile(files, setDroppedFiles)}
											/>
											<UploadedContent
												files={droppedFiles}
												onRemoveItem={() => removingFile(setDroppedFiles)}
											/>
										</Grid>

										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>
													{`Upload ${type} thumbnail`}
												</Box>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={8}>
											<Dropzone
												key={dropzoneKey}
												acceptedFiles={['image/jpeg', 'image/png']}
												multipleFiles={false}
												maxFileSize={1048576} // 1 mb
												files={thumbnailFiles}
												onChange={(files) => addingFile(files, setThumbnailFiles)}
											/>
											<UploadedContent
												files={thumbnailFiles}
												onRemoveItem={() => removingFile(setThumbnailFiles)}
											/>
										</Grid>
									</> :
									<>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>
													{`Enter URL present in the embed video tag`}
												</Box>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													placeholder={`Eg: https://www.youtube.com/embed/rons6rPj1yY`}
													onChange={(e) => {
														setUrl(e.target.value)
													}}
													value={url}
												/>
											</FormControl>
										</Grid>
									</>
								}
								<Grid item container xs={12} justify="flex-end">
									{loading ? <p>Loading...</p> :
										<Button
											disableElevation
											variant="contained"
											color="primary"
											size="large"
											type="submit"
											onClick={createWebResource}
										>
											Create
                                </Button>}
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</MiniDrawer>
		</>
	)
}

export default WebResourceUploader