import React, { useState, useEffect, FunctionComponent, } from 'react';
import { makeStyles, createStyles, Theme, lighten } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {
	Box, Container, Grid, Button, Input, Typography, FormControlLabel,
	IconButton, CssBaseline, Card, CardContent, CardHeader, Tooltip, InputAdornment, Toolbar, Radio
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
import { connect, useDispatch } from 'react-redux';

import MiniDrawer from "../../../common/components/SideDrawer"
import { fontOptions } from '../../../common/theme';
import { EMAIL_PATTERN, PHONE_PATTERN } from '../../../common/validations/patterns';
import { deleteAdmin, getAdmins, } from '../../../../api/dashboard';
import { Clear, Search, } from '@material-ui/icons';
import useStyles from './styles';
import { Admin, } from '../../../common/contracts/dashboard';
import Datagrids, { datagridCellExpand, datagridCellExpand2 } from '../../components/dataGrid';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { setAdminsList, setCurrentAdmin, } from '../../../redux/actions/adminActions';
import ConfirmationModal from '../../../common/components/confirmation_modal';
import { RootState } from '../../../redux/store';
import { MASTER_PERMISSIONS, USER_ROLES } from '../../../../utilities/constants';
import Edit from '../../../../assets/svgs/edit.svg';
import Trash from '../../../../assets/svgs/trash.svg';
import RoleIcon from '../../../../assets/svgs/role.svg';
import Reset from '../../../../assets/svgs/reset.svg';

interface Props extends RouteComponentProps {
	adminsList: Admin[]
}
interface EnhancedTableToolbarProps {
	// numSelected: number;
	searchText: string;
	setSearchText: React.Dispatch<React.SetStateAction<string>>
	setFocused: React.Dispatch<React.SetStateAction<boolean>>
}


const useToolbarStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(1),
			height: '100%'
		},
		highlight:
			theme.palette.type === 'light'
				? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
				}
				: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
				},
		title: {
			flex: '1 1 100%'
		}
	})
);

const EnhancedTableToolbar: FunctionComponent<EnhancedTableToolbarProps> = ({
	searchText,
	setSearchText,
	setFocused
}) => {
	const classes = useToolbarStyles();

	return (
		<Toolbar>
			<Box display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginLeft="auto"
			>
				<Box margin="15px 10px 10px 25px">
					<FormControl fullWidth margin="normal">
						<Input
							name="search"
							inputProps={{ inputMode: 'search' }}
							onFocus={() => setFocused(true)}
							onBlur={() => setFocused(false)}
							placeholder="Search"
							value={searchText}
							onChange={(e) => {
								setSearchText(e.target.value);
							}}
							endAdornment={
								searchText.trim() !== '' ? (
									<InputAdornment position="end">
										<IconButton size="small" onClick={() => setSearchText('')}>
											<Clear />
										</IconButton>
									</InputAdornment>
								) : (
									<InputAdornment position="end">
										<IconButton disabled size="small">
											<Search />
										</IconButton>
									</InputAdornment>
								)
							}
						/>
					</FormControl>
					{(searchText.trim() !== '') &&
						<Typography style={{ marginTop: '5px', fontSize: fontOptions.size.small, color: '#666666' }}>
							Filtered Table using Keyword '{searchText}'
            </Typography>
					}
				</Box>
			</Box>
		</Toolbar>
	);
};


const ViewSearchAdmin: FunctionComponent<Props> = ({ history, adminsList }) => {

	const { enqueueSnackbar } = useSnackbar()
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const [selected, setSelected] = useState<string[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [focused, setFocused] = useState(false);
	const [searchBySelection, setSearchBySelection] = useState('mobile');
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const styles = useStyles();
	const dispatch = useDispatch();

	var allowedPermissions: string[] = [];
	var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
	var loginUserType = '';
	if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
		allowedPermissions = loginUserData.adminRole.permissions;
		loginUserType = loginUserData.adminRole.name;
	}

	useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.getAdmins);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		}
	}

	function createData(admin: Admin) {
		return {
			firstName: admin.firstName,
			lastName: admin.lastName,
			mobileNo: admin.mobileNo,
			emailId: admin.emailId,
			adminRole: admin.adminRole,
			// adminRole: admin.adminRole && admin.adminRole.name ? admin.adminRole.name : admin.adminRole,
			_id: admin._id
		}
	}

	const rows = adminsList.map(admin => createData(admin))


	const gridRows = rows.map((row, index) => {

		return ({
			id: (index + 1),
			firstName: row.firstName,
			lastName: row.lastName,
			mobileNo: row.mobileNo,
			emailId: row.emailId,
			adminRole: row.adminRole.name,
		})
	});

	const handleResetPassword = (row: Admin) => {
		if (allowedPermissions.includes(MASTER_PERMISSIONS.resetPassword) || loginUserType == USER_ROLES.super) {
			dispatch(setCurrentAdmin(row));
			history.push('/reset-password');
		} else {
			enqueueSnackbar("You don't have permission on this action", { variant: 'info' });
		}
	}

	const handleDeleteUser = (row: Admin) => {
		if (allowedPermissions.includes(MASTER_PERMISSIONS.deleteAdmin) || loginUserType == USER_ROLES.super) {
			const index = rows.findIndex(data => {
				return (data.firstName === row.firstName && data.mobileNo === row.mobileNo)
			})
			setSelection({ selectionModel: [index + 1] })
			setOpenConfirmationModal(true);
		} else {
			enqueueSnackbar("You don't have permission on this action", { variant: 'info' });
		}
	}

	const handleEditUser = (row: Admin) => {
		if (allowedPermissions.includes(MASTER_PERMISSIONS.editAdmin) || loginUserType == USER_ROLES.super) {
			dispatch(setCurrentAdmin(row));
			history.push('/edit-user');
		} else {
			enqueueSnackbar("You don't have permission on this action", { variant: 'info' });
		}
	}

	const handleAssignRole = (row: Admin) => {
		if (allowedPermissions.includes(MASTER_PERMISSIONS.editAdmin) || loginUserType == USER_ROLES.super) {
			dispatch(setCurrentAdmin(row));
			history.push('/assign-role');
		} else {
			enqueueSnackbar("You don't have permission on this action", { variant: 'info' });
		}
	}
	const buttonData = [
		{
			title: 'Edit Details',
			action: handleEditUser,
			icon: Edit
		},
		{
			title: 'Reset Password',
			action: handleResetPassword,
			icon: Reset
		},
		{
			title: 'Delete Details',
			action: handleDeleteUser,
			icon: Trash
		},
		{
			title: 'Assign Role',
			action: handleAssignRole,
			icon: RoleIcon
		},
	];

	const setSelection = (selected: any) => {
		const selectedRow = selected.selectionModel.map((index: string) => {
			const row = rows[(Number(index) - 1)]
			return row.emailId
		})

		setSelected(selectedRow);
	}

	const gridColumns: GridColDef[] = [
		{ field: 'id', headerName: 'Sl No.', flex: 0.5 },
		{ field: 'firstName', headerName: 'Name', flex: 1, renderCell: datagridCellExpand2 },
		{ field: 'mobileNo', headerName: 'Mobile', flex: 1, renderCell: datagridCellExpand },
		{ field: 'emailId', headerName: 'Email', flex: 1, renderCell: datagridCellExpand },
		{ field: 'adminRole', headerName: 'Role', flex: 1, renderCell: datagridCellExpand },
		{
			field: 'action', headerName: 'Actions', flex: 1,
			disableClickEventBubbling: true,
			renderCell: (params: GridCellParams) => {
				const selectedRow = {
					id: params.getValue("id") as number,
					emailId: params.getValue("emailId") as string,
					firstName: params.getValue("firstName") as string
				}

				const selectedRowDetails = rows.find((row, index) => {
					return (row.emailId === selectedRow.emailId && row.firstName === selectedRow.firstName && index === (selectedRow.id - 1))
				})

				const buttonSet = buttonData.map((button, index) => {
					return (
						<Tooltip key={index} title={button.title}>
							<img src={button.icon}
								onClick={() => {
									button.action(selectedRowDetails as Admin);
								}}
								style={{ height: '24px', width: '24px', margin: '0px 2px' }} />
						</Tooltip>
					);
				})

				return <div>{buttonSet}</div>;
			}
		}
	];

	const removeUser = async () => {
		if (selected.length > 1) {
			enqueueSnackbar('Please select only one', { variant: 'info' });
			return;
		}
		setLoading(true);
		setOpenConfirmationModal(false);
		try {
			const updatedAdmins = adminsList.filter((el) => !selected.includes(el.emailId.toString()));
			await deleteAdmin(selected[0]);
			setLoading(false);
			enqueueSnackbar('Deleted Successfully', { variant: 'success' });
			dispatch(setAdminsList(updatedAdmins));
			setSelected([]);
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const handleSearchSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchBySelection(event.target.value);
		setSearchText('');
	};

	const serialize = (obj: any, prefix?: any) => {
		var str: any = [],
			p;
		for (p in obj) {
			if (obj.hasOwnProperty(p)) {
				var k = prefix ? prefix + "[" + p + "]" : p,
					v = obj[p];
				str.push((v !== null && typeof v === "object") ?
					serialize(v, k) :
					encodeURIComponent(k) + "=" + encodeURIComponent(v));
			}
		}
		return str.join("&");
	}

	const getObj = (obj: any) => {
		var commonObj = {
			"$regex": searchText,
			"$options": "i"
		}
		var query: any = {};

		if (searchBySelection == 'mobile') {
			query['mobileNo'] = commonObj;
		} else if (searchBySelection == 'name') {
			var or: any = [
				{ "firstName": commonObj },
				{ "lastName": commonObj }
			]
			query['$or'] = or;
		} else {
			query['emailId'] = commonObj;
		}
		obj['query'] = query;
		var mObj = serialize(obj)
		return mObj;
	}

	const handleSearchbySelection = () => {
		var obj: any = {}
		if (searchBySelection == 'mobile' && PHONE_PATTERN.test(searchText)) {
			obj = getObj(obj);
			getAdminsList(obj);
		} else if (searchBySelection == 'email' && EMAIL_PATTERN.test(searchText)) {
			obj = getObj(obj);
			getAdminsList(obj);
		} else if (searchBySelection == 'name' && searchText != '') {
			obj = getObj(obj);
			getAdminsList(obj);
		} else {
			if (searchBySelection) {
				switch (searchBySelection) {
					case 'mobile':
						enqueueSnackbar('Please enter valid mobile number', { variant: 'warning' });
						return;
					default:
						enqueueSnackbar('Please enter valid ' + searchBySelection, { variant: 'warning' });
						return;
				}
			}
		}
	}

	const handleSearchAll = () => {
		setSearchText('');
		getAdminsList({});
	}

	const getAdminsList = async (obj: any) => {
		setLoading(true);
		try {
			var adminsList = await getAdmins(obj);
			dispatch(setAdminsList(adminsList));
			enqueueSnackbar(adminsList.length + ' record(s) fetched', { variant: 'success' });
			setLoading(false);
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Search Users</Typography>
							</Grid> */}
							<Grid item className={styles.header}>
								<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
							</Grid>
							<Card className={styles.card} variant='outlined' >
								<CardHeader title='Search by' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>

									<FormControlLabel
										value="end"
										control={
											<Radio
												checked={searchBySelection === 'mobile'}
												onChange={handleSearchSelection}
												value="mobile"
												name="mobile"
												color='default'
											/>
										}
										label="Mobile"
									/>

									<FormControlLabel
										value="end"
										control={
											<Radio
												checked={searchBySelection === 'name'}
												onChange={handleSearchSelection}
												value="name"
												name="name"
												color='default'
											/>
										}
										label="Name"
									/>


									<FormControlLabel
										value="end"
										control={
											<Radio
												checked={searchBySelection === 'email'}
												onChange={handleSearchSelection}
												value="email"
												name="email"
												color='default'
											/>
										}
										label="Email"
									/>
									<Button variant="contained" color="primary" size="medium"
										style={{ margin: 10 }}
										onClick={handleSearchbySelection}
										disabled={searchBySelection ? false : true}
									>
										Search
										</Button>


									<Button variant="contained" color="primary" size="medium"
										style={{ margin: 10 }}
										onClick={handleSearchAll}
									>
										Search All
										</Button>

									<Grid item xs={12} md={5}>
										<EnhancedTableToolbar
											searchText={searchText}
											setSearchText={setSearchText}
											setFocused={setFocused}
										/>
									</Grid>


								</CardContent>
							</Card>

							{adminsList && adminsList.length > 0 &&
								<Grid item xs={12} md={12} style={{ margin: '10px 0px' }}>
									<Typography variant='h6' align='right'>Recent search results</Typography>
								</Grid>
							}
							<Datagrids key={searchText} gridRows={gridRows} gridColumns={gridColumns} setSelection={setSelection} />

						</Grid>
					</Container>
				</div>
			</MiniDrawer>
			<ConfirmationModal
				header="Delete User"
				helperText="Are you sure you want to delete?"
				openModal={openConfirmationModal}
				onClose={() => { setOpenConfirmationModal(false); setSelected([]) }}
				handleDelete={() => !loading && removeUser()}
			/>
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	adminsList: state.adminReducer.adminsList as Admin[],
});

export default connect(mapStateToProps)(ViewSearchAdmin);
