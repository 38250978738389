import 'date-fns';
import React, { FunctionComponent } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';

interface Props {
	selectedDate: Date | null;
	handleDateChange: (date: Date | null) => void;
	minDate?: Date | boolean;
	maxDate?: Date | boolean;
	disabled?: boolean;
}

const Datepickers: FunctionComponent<Props> = ({ selectedDate, handleDateChange, minDate, maxDate, disabled }) => {
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				format="dd/MM/yyyy"
				id="date-picker-inline"
				value={selectedDate}
				onChange={handleDateChange}
				minDate={minDate ? minDate as Date : undefined}
				maxDate={maxDate ? maxDate as Date : undefined}
				placeholder="dd/mm/yyyy"
				disabled={disabled ? disabled : false}
			/>
		</MuiPickersUtilsProvider>
	);
}

export default Datepickers